import { Injectable, inject } from '@angular/core';
import { DashboardModel, WidgetDataProvider, WidgetProviderLoadHistoryOptions, WidgetProviderSaveOptions, WidgetStructureModel, WidgetsHistoryEntry } from '@infront/ngx-dashboards-fx';
import { Observable, of } from 'rxjs';

import { LogService } from '@vwd/ngx-logging';
import { convertToWidgetStructureModel } from '../../util/dashboard-framework';
import { OrderCategories } from '../../widgets/portfolio-orders/portfolio-orders.model';
import { TradingClassifications } from '../../widgets/portfolio-positions/portfolio-positions.model';
import { createPortfolioEntitiesConfig } from '../template';
import { PORTFOLIO_DASHBOARD_ID } from './portfolio-dashboards';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EMPTY_ARRAY = Object.freeze([]) as readonly any[];
const EMPTY_STRUCTURE = Object.freeze({ widgets: EMPTY_ARRAY }) as WidgetStructureModel;
const EMPTY_HISTORY = EMPTY_ARRAY as readonly WidgetsHistoryEntry[];

const entities = createPortfolioEntitiesConfig(TradingClassifications, OrderCategories);
const widgetStructureModel = convertToWidgetStructureModel(entities);
const PORTFOLIO_DASHBOARD_DATA = Object.freeze(widgetStructureModel) as WidgetStructureModel;

@Injectable({ providedIn: 'root' })
export class WTBuiltInWidgetDataProvider extends WidgetDataProvider {
  private readonly logger = inject(LogService).openLogger('services/dashboards/builtin/widgets');

  loadHistory(_dashboard: DashboardModel): Observable<readonly WidgetsHistoryEntry[]> {
    return of(EMPTY_HISTORY);
  }

  loadByDashboard(dashboard: DashboardModel): Observable<WidgetStructureModel> {
    if (dashboard.id === PORTFOLIO_DASHBOARD_ID) {
      return of(PORTFOLIO_DASHBOARD_DATA);
    }
    this.logger.error(`Cannot find dashboard ${dashboard.id}`);
    return of(EMPTY_STRUCTURE);
  }

  loadByHistory(_options: WidgetProviderLoadHistoryOptions): Observable<WidgetStructureModel> {
    this.logger.error('Built-in dashboards do not support history.');
    return of(EMPTY_STRUCTURE);
  }

  save(_saveOptions: WidgetProviderSaveOptions): Observable<void> {
    throw new Error('Built-in dashboards are read-only.');
  }

  revertDraft(_dashboard: DashboardModel): Observable<void> {
    throw new Error('Built-in dashboards are read-only.');
  }

}
