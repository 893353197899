import { Infront, InfrontSDK } from '@infront/sdk';

import { CountryFlagMap, CountryFlagMapValues } from '@infront/wtk/utils/country-flags';
import { isSymbolData } from './symbol';

const allCountries = Infront.Countries.allCountries();

export const getCountryName = (isoAlpha: string): string => {
  return (
    allCountries.find(
      (country) => (country.alpha2 != undefined && country.alpha2 === isoAlpha) || (country.alpha3 != undefined && country.alpha3 === isoAlpha)
    )?.name ?? isoAlpha
  );
};

export const getCountryForCurrency = (currency: string): string => Infront.currencyToCountry(currency);

export const getCountryForSymbol = (
  data:
    | InfrontSDK.SymbolData
    | {
      [InfrontSDK.SymbolField.CountryOfIncorporation]?: string;
      [InfrontSDK.SymbolField.Country]?: string;
      [InfrontSDK.SymbolField.FeedCountry]?: string;
    }
): string => {
  if (isSymbolData(data)) {
    const symbol = data as InfrontSDK.SymbolData;
    return (
      symbol.get(InfrontSDK.SymbolField.CountryOfIncorporation) ||
      symbol.get(InfrontSDK.SymbolField.Country) ||
      symbol.get(InfrontSDK.SymbolField.FeedCountry)
    );
  }
  if (data[InfrontSDK.SymbolField.CountryOfIncorporation]) {
    return data[InfrontSDK.SymbolField.CountryOfIncorporation] as string;
  }
  if (data[InfrontSDK.SymbolField.Country]) {
    return data[InfrontSDK.SymbolField.Country] as string;
  }
  if (data[InfrontSDK.SymbolField.FeedCountry]) {
    return data[InfrontSDK.SymbolField.FeedCountry] as string;
  }
  return data as string;
};

const COUNTRY_CODE_PREFIX: Readonly<string> = 'CODE_';
export const getCountryFileNameForFlag = (
  country: string | number | undefined,
  allowFallbackFlag = true,
  fallbackFlag: string = CountryFlagMap.GLOBAL
): string | undefined => {
  let fileName;

  if (typeof country === 'string') {
    if (CountryFlagMapValues.includes(country)) {
      fileName = country;
    } else {
      const replaceWhitespaceWithUnderscore = new RegExp(/\s/, 'g');
      const removeSpecialCharacters = new RegExp(/[^\W]/, 'g'); // A-Za-z0-9_
      fileName = (
        CountryFlagMap[country]
        || CountryFlagMap[country.toUpperCase().replace(replaceWhitespaceWithUnderscore, '_').replace(removeSpecialCharacters, '')]
      ) as string;
    }
  } else if (typeof country === 'number') {
    fileName = CountryFlagMap[COUNTRY_CODE_PREFIX + String(country)] as string;
  }

  if (!fileName && allowFallbackFlag) {
    fileName = fallbackFlag;
  }

  return fileName;
};
