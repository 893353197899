import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { MatListOption, MatSelectionListChange } from '@angular/material/list';

import { ColumnCategory } from '../grid/columns.model';

@Component({
  selector: 'wt-multi-select-expansion',
  templateUrl: './multi-select-expansion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectExpansionComponent {
  private _columnCategory: ColumnCategory;

  @Input() isDisabled: boolean;
  @Input() set columnCategory(value: ColumnCategory) {
    this.numberSelected = { value: value.columns.filter((col) => col.selected).length.toString() };
    this._columnCategory = value;
  }
  get columnCategory(): ColumnCategory {
    return this._columnCategory;
  }

  @Output() selectionChanged = new EventEmitter<MatListOption>();

  panelOpenState = false;
  numberSelected: { value: string };

  onSelectionChanged(data: { list: QueryList<MatListOption>; event: MatSelectionListChange }): void {
    this.selectionChanged.emit(data.event.options[0]);
    this.numberSelected = { value: data.list.filter((col) => col.selected).length.toString() };
  }
}
