import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DashboardWindow } from '../../../state-model/window.model';
import { Dashboard } from '../../../state-model/dashboard.model';

@Component({
  selector: 'wt-title-only-header',
  templateUrl: './title-only-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitleOnlyHeaderComponent {
  @Input() dashboard: Dashboard;
  @Input() window: DashboardWindow;
}
