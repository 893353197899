import { WidgetGridsMap } from '../../state-model/grid.model';

export const RepresentedClassifications = ['Stock', 'ETF', 'Fund', 'Future', 'Derivative', 'Bond', 'Certificate'] as const;
export const TradingClassifications = [...RepresentedClassifications, 'Other', 'Unmapped'] as const;

export type TradingClassification = typeof TradingClassifications[number];

export type PortfolioGridType = typeof WidgetGridsMap['portfolioPositionsGrid'][number];

export const PortfolioPositionsTabs = ['Default', 'Performance'] as const;
