import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';

import { PipesModule } from '../../pipes/pipes.module';
import { AlertLogListModule } from '../../shared/alerts-list/alert-log-list/alert-log-list.module';
import { DirectivesModule } from './../../directives/directives.module';
import { AlertLogComponent } from './alert-log.component';

@NgModule({
  declarations: [AlertLogComponent],
  imports: [CommonModule, AlertLogListModule, MatIconModule, MatBadgeModule, PipesModule, DirectivesModule],
  exports: [AlertLogComponent],
})
export class AlertLogModule {}
