import { Injectable } from '@angular/core';

import * as InfrontLibChartIndicator from '@vwd/lib_chart_indicator';

import * as Highcharts from 'highcharts/highstock';
import * as HighchartsIndicatorsAll from 'highcharts/indicators/indicators-all';
import * as HighchartsAnnotationsAdvanced from 'highcharts/modules/annotations-advanced';
import * as HighchartsDragPanes from 'highcharts/modules/drag-panes';
import * as HighchartsStockTools from 'highcharts/modules/stock-tools';
import * as HighchartsHeikinashi from 'highcharts/modules/heikinashi';
import * as HighchartsHollowCandlestick from 'highcharts/modules/hollowcandlestick';

type ExtWindow = (globalThis.Window & typeof globalThis) & { InfrontLibChartIndicator: unknown };
type HighchartsFunction = (h: unknown) => void;

@Injectable({
  providedIn: 'root',
})
export class HighchartsService {

  constructor() {
    this.init();
  }

  init(): void {
    // HACK: set window.Highcharts from imported Highcharts, else SDK Chart will not find it!
    window.Highcharts = Highcharts;
    // HACK: set window.InfrontLibChartIndicator from imported InfrontLibChartIndicator, else SDK Chart will not find it!
    (window as ExtWindow).InfrontLibChartIndicator = InfrontLibChartIndicator;
    // HACK: for country flags to be accessible on dev. env.!
    // Infront.LibraryConstants.kBuildType = 'release';

    // init the extra Highcharts modules!!
    (HighchartsIndicatorsAll as unknown as HighchartsFunction)(Highcharts);
    (HighchartsDragPanes as unknown as HighchartsFunction)(Highcharts);
    (HighchartsAnnotationsAdvanced as unknown as HighchartsFunction)(Highcharts);
    (HighchartsStockTools as unknown as HighchartsFunction)(Highcharts);
    (HighchartsHeikinashi as unknown as HighchartsFunction)(Highcharts);
    (HighchartsHollowCandlestick as unknown as HighchartsFunction)(Highcharts);
  }

  isHighchartsAvailable(): boolean {
    return window.Highcharts != undefined;
  }

}
