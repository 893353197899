<ng-container *ngIf="url">
  <!-- <div>{{ url }}</div> --><!-- debug only -->
  <div class="wt-flex-row wt-flex-align-middle">
    <div class="wt-flex-column" *ngIf="!!title">
      <h2>{{ ('IFRAME_OVERLAY_COMPONENT.' + title) | translate }}</h2>
    </div>

    <div class="wt-flex-column wt-flex-shrink wt-flex-collapse">
      <button type="button" class="wt-iframe-overlay-content-close" (click)="closeOverlay()">
        <mat-icon fontIcon="close"></mat-icon>
      </button>
    </div>
  </div>

  <iframe
    class="wt-iframe-overlay-content"
    sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts"
    [src]="url | safe"></iframe>
</ng-container>
<ng-container *ngIf="backdropClick$ | async"></ng-container>