import { MarketOverviewTemplate } from '../market-overview.model';

export const TemplateDenmark: MarketOverviewTemplate[] = [
  {
    tab: 'Indices',
    instrumentList: [
      { feed: 17665, ticker: 'OMXC20' },
      { feed: 17665, ticker: 'OMXC25' },
      { feed: 17921, ticker: 'OMXS30' },
      { feed: 100, ticker: 'OMXH25' },
      { feed: 18009, ticker: 'OMXN40' },
      { feed: 18177, ticker: 'OBX' },
      { feed: 2088, ticker: 'SPE350' },
      { feed: 2098, ticker: 'DAX' },
      { feed: 2302, ticker: 'BUK100P' },
      { feed: 2089, ticker: 'PX1' },
      { feed: 2161, ticker: 'SX5E' },
      { feed: 20, ticker: 'DJI' },
      { feed: 2087, ticker: 'COMP' },
      { feed: 2087, ticker: 'NDX' },
      { feed: 2088, ticker: 'SP500' },
      { feed: 21, ticker: 'NQFUT1' },
      { feed: 21, ticker: 'ESFUT1' },
      { feed: 6330, ticker: 'VIX' },
      { feed: 2089, ticker: 'N100' },
      { feed: 6150, ticker: 'N225' },
      { feed: 2198, ticker: 'HSI' },
      { feed: 2088, ticker: 'SPASIA50' },
    ],
  },
  {
    tab: 'Forex',
    instrumentList: [
      { feed: 12, ticker: 'USDDKK' },
      { feed: 12, ticker: 'EURDKK' },
      { feed: 12, ticker: 'CHFDKK' },
      { feed: 12, ticker: 'SEKDKK' },
      { feed: 12, ticker: 'NOKDKK' },
      { feed: 12, ticker: 'GBPDKK' },
      { feed: 12, ticker: 'JPYDKK' },
      { feed: 12, ticker: 'TRYDKK' },
      { feed: 12, ticker: 'GBPUSD' },
      { feed: 12, ticker: 'EURUSD' },
    ],
  },
  {
    tab: 'FixedIncome',
    instrumentList: [
      { feed: 17686, ticker: 'CIBOR-3M' },
      { feed: 17686, ticker: 'CIBOR-6M' },
      { feed: 2227, ticker: 'TMBMKDK-10Y' },
      { feed: 2227, ticker: 'TMBMKDE-30Y' },
      { feed: 2227, ticker: 'TMUBMUSD30Y' },
      { feed: 2158, ticker: 'BMDE-10Y' },
      { feed: 2158, ticker: 'BMDE-30Y' },
      { feed: 2158, ticker: 'BMUS-10Y' },
      { feed: 2158, ticker: 'BMUS-30Y' },
      { feed: 2158, ticker: 'BMGB-10Y' },
    ],
  },
  {
    tab: 'Commodities',
    instrumentList: [
      { feed: 2229, ticker: 'BRT' },
      { feed: 2229, ticker: 'WTI' },
      { feed: 2148, ticker: 'AH3M' },
      { feed: 2148, ticker: 'CA3M' },
      { feed: 2148, ticker: 'ZS3M' },
      { feed: 2159, ticker: 'SLVSPOT' },
      { feed: 2159, ticker: 'GLDSPOT' },
    ],
  }
];
