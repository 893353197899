import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WTKWidgetWrapperModule } from '../../wrappers/wtk-widget-wrapper/wtk-widget-wrapper.module';
import { EsgComponent } from './esg.component';

@NgModule({
  declarations: [EsgComponent],
  imports: [CommonModule, WTKWidgetWrapperModule],
})
export class EsgModule { }
