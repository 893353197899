import { Period, PeriodType } from './period.model';

// Workaround circular reference for type safety
// DO NOT EXPORT THIS CONST, ONLY EXTEND WITH NEW DEFAULT PERIODS
const DefaultPeriodsTypeSafe = {
  'MINUS1D': { from: { periodType: PeriodType.DAY } }, // yesterday
  'MINUS1D_PLUS1W': { from: { periodType: PeriodType.DAY }, to: { periodType: PeriodType.WEEK } }, // yesterday + next week
  'MINUS1M': { from: { periodType: PeriodType.MONTH } }, // last month
  'MINUS1W': { from: { periodType: PeriodType.WEEK }, }, // last week
  'MINUS1W_PLUS1M': { from: { periodType: PeriodType.WEEK }, to: { periodType: PeriodType.MONTH } }, // last week + next month
  'PLUS1W': { to: { periodType: PeriodType.WEEK } }, // next week
  'PLUS1M': { to: { periodType: PeriodType.MONTH } }, // next month
  'PLUS3M': { to: { periodType: PeriodType.MONTH, periodCount: 3 } }, // next 3 months
  'PLUS6M': { to: { periodType: PeriodType.MONTH, periodCount: 6 } }, // next 6 months
  'PLUS1Y': { to: { periodType: PeriodType.YEAR } } // next year
};

type DefaultPeriodKeys = keyof typeof DefaultPeriodsTypeSafe;

// Any Period relate type error will be displayed on the exported const below
export const DefaultPeriods: { [key in DefaultPeriodKeys]: Period; } = DefaultPeriodsTypeSafe;
