import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { CompanyInformationComponent } from './company-information.component';
import { LabelListModule } from '../../shared/label-list/label-list.module';
import { SymbolHeaderInfoModule } from '../../shared/symbol-header-info/symbol-header-info.module';
import { InstrumentFocusModule } from '../../shared/instrument-focus/instrument-focus.module';

@NgModule({
  declarations: [CompanyInformationComponent],
  imports: [
    CommonModule,
    SymbolHeaderInfoModule,
    InstrumentFocusModule,
    LabelListModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [],
  providers: [],
})
export class CompanyInformationModule { }
