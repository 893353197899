import { Injectable, inject } from '@angular/core';
import { DashboardFolderLevel, DashboardItemRef, DashboardService, DashboardType, isDashboardItemRef, untilChildrenLoaded } from '@infront/ngx-dashboards-fx';
import { ResourceService } from '@vwd/ngx-i18n/translate';
import { Observable, map, of, shareReplay, switchMap, tap } from 'rxjs';
import { TenantSettingsService } from '../services/tenant-settings.service';
import { LogService } from '@vwd/ngx-logging';

export const EMPTY_DEFAULT_DASHBOARD_ID = '#empty';

const EmptyDefaultDashboard: DashboardItemRef = {
  model: {
    id: EMPTY_DEFAULT_DASHBOARD_ID,
    type: DashboardType.DASHBOARD,
    name: 'DASHBOARD.DEFAULT_DASHBOARDS.NAME',
    attributes: {},
    level: DashboardFolderLevel.PERSONAL,
    hidden: false,
    isDataDraft: false,
    isLink: false,
    security: { canDelete: false, canEdit: false, canEditWidgets: false, canMove: false },
    sharing: { canShare: false, isShared: false, isSharedWithMe: false },
    get dataCreatedAt() { return new Date(); },
  },
  displayName: 'DASHBOARD.DEFAULT_DASHBOARDS.NAME',
  isLink: false,
  link: undefined,

  get displayName$() { return of(this.displayName); },
  get link$() { return of(this.link); },
  get model$() { return of(this.model); },

  provider: undefined!,
  parent: undefined,
  parent$: of(undefined),

  delete: notSupported,
  update: notSupported,
};

function notSupported<T>(): T { throw new Error('Not Supported'); }

@Injectable({ providedIn: 'root' })
export class DefaultDashboardsService {
  private readonly tenantService = inject(TenantSettingsService);
  private readonly dashboardService = inject(DashboardService);
  private readonly translateService = inject(ResourceService);
  private readonly logger = inject(LogService).openLogger('services/default-dashboards');

  emptyDashboardItem(): DashboardItemRef {
    return {
      ...EmptyDefaultDashboard,
      displayName: this.translateService.get(EmptyDefaultDashboard.displayName) as string,
      model: {
        ...EmptyDefaultDashboard.model,
        name: this.translateService.get(EmptyDefaultDashboard.model.name) as string,
      }
    };
  }

  canEdit(realm: string): boolean {
    return this.tenantService.canEdit(realm);
  }

  getDashboards(realm?: string): Observable<readonly DashboardItemRef[]> {
    return this.tenantService.getValue$('defaultDashboards', realm).pipe(
      switchMap((dashboardIds) => {
        if (!dashboardIds?.length) {
          return of([]);
        }
        return this.dashboardService.root$.pipe(
          // FIXME? root$ will report childrenLoadState === 'loaded' even if child folders are still loading
          switchMap((root) => root.childFolders$),
          untilChildrenLoaded(),
          map(() => dashboardIds.map((id) => id === EMPTY_DEFAULT_DASHBOARD_ID
            ? this.emptyDashboardItem()
            : this.dashboardService.getRef(id)).filter(isDashboardItemRef)
          ),
          tap((defaultDashboards) => this.logger.log('Default dashboards', defaultDashboards)),
        );
      }),
      shareReplay(1),
    );
  }

  setDashboards(realm: string, dashboards: readonly DashboardItemRef[]): Observable<void> {
    return this.tenantService.setValue('defaultDashboards', dashboards.map((ref) => ref.model.id), realm);
  }
}
