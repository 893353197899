import { ChangeDetectionStrategy, Component, Input, OnDestroy, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { map, take } from 'rxjs';
import { StoreService } from '../../../services/store.service';
import { Dashboard } from '../../../state-model/dashboard.model';
import { ChainsWidget } from '../../../state-model/widget.model';
import { ChainsWindow } from '../../../state-model/window.model';
import { translator } from '../../../util/locale';
import { filterUndefined } from '../../../util/rxjs';
import { RenameChainsTitleDialogComponent, RenameChainsTitleDialogResult } from './rename-chains-title-dialog/rename-chains-title-dialog.component';

@Component({
  selector: 'wt-chains-header',
  templateUrl: './chains-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChainsHeaderComponent implements OnDestroy {
  private readonly xlat = translator();
  private readonly storeService = inject(StoreService);
  private readonly dialog = inject(MatDialog);

  private readonly widgetAction = new LastValueSubject<ChainsWidget>();

  @Input() dashboard: Dashboard;
  @Input() window: ChainsWindow;

  @Input()
  get widget(): ChainsWidget {
    return this.widgetAction.value!;
  }
  set widget(widget: ChainsWidget) {
    this.widgetAction.next(widget);
  }

  get canEditName() { return !this.dashboard.locked && (this.widget?.settings?.adminMode ?? true); }

  isPreEditMode = false;

  readonly vm$ = this.widgetAction.pipe(
    map((widget) => ({
      widget,
      title: this.getTitle(widget),
    }))
  );

  ngOnDestroy(): void {
    this.widgetAction.complete();
  }

  private getTitle(widget?: ChainsWidget): string {
    return widget?.settings?.name || this.xlat('DASHBOARD_WINDOW.HEADER.WIDGET_LABEL.Chains'); // NOSONAR use ||, as we have to catch empty string, too!
  }

  onEdit(): void {
    this.dialog.open(RenameChainsTitleDialogComponent, {
      maxWidth: '400px',
      data: { name: this.getTitle(this.widget) },
    }).afterClosed().pipe(
      take(1),
      filterUndefined(),
    ).subscribe((name: RenameChainsTitleDialogResult) => {
      const widget = this.widget;
      if (widget) {
        this.storeService.updateWidget(widget, { settings: { ...widget.settings, name } });
      }
      this.isPreEditMode = false;
    });
  }
}
