import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'wt-screener-dialog',
  templateUrl: './screener-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenerDialogComponent {

  constructor(
  ) {
  }
}
