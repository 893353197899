import { Injectable } from '@angular/core';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { NEVER, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { filterUndefinedArray } from '../util/rxjs';
import { getInstrumentFromSymbolLike } from '../util/sdk';
import { RepresentedClassifications, TradingClassification } from '../widgets/portfolio-positions/portfolio-positions.model';
import { SdkRequestsService } from './../services/sdk-requests.service';
import { TradingService } from './../services/trading.service';

@Injectable({
  providedIn: 'root',
})
export class TradingPositionsService {
  constructor(private sdkRequestsService: SdkRequestsService, private tradingService: TradingService) { }

  portfolioByClassification$ = (label: string) =>
    this.portfolioTradingSymbols$(label).pipe(
      switchMap((symbols) => {
        if (!symbols.length) {
          return of([]); // FIXME empty need to be catched to display empty table or similar!!
        }
        return this.sdkRequestsService
          .addSymbolDataToList$({
            entityList: symbols,
            fieldList: [InfrontSDK.SymbolField.SymbolClassification],
            entityIdMatch: (symbol) => {
              return { feed: symbol.symbolId?.feed, ticker: symbol.symbolId?.ticker };
            }
          }).pipe(
            map((symbolsWithClassifications) => {
              const unmappedSymbols = symbols.filter((s) => !s.symbolId).map((s) => ({ ...s, symbolClassification: 'Unmapped' }));
              return [...symbolsWithClassifications, ...unmappedSymbols];
            })
          );
      }),
      map((symbols: (InfrontSDK.Trading.PortfolioItem & { symbolClassification: TradingClassification })[]) => {
        const grouped = symbols.reduce((acc, symbol) => {
          let classification = symbol.symbolClassification ? symbol.symbolClassification : 'Unmapped';
          if (![...RepresentedClassifications, 'Unmapped'].includes(classification)) {
            classification = 'Other';
          }
          acc[classification] ??= [];
          acc[classification].push(symbol);
          return acc;
        }, {} as { [key in TradingClassification]: InfrontSDK.Trading.PortfolioItem[] });
        return grouped;
      }),
    );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private portfolioTradingSymbols$ = (label: string): Observable<InfrontSDK.Trading.PortfolioItem[]> =>
  // label used for debugging
  {
    return this.tradingService.portfolioData$.pipe(
      switchMap((portfolioData: InfrontSDK.Trading.PortfolioData) => {
        if (!portfolioData) {
          return NEVER;
        }
        const filter = {
          excludeMarkets: 'Loan',
        };
        const obsArray = portfolioData.positions(filter) as InfrontUtil.ObservableArray<InfrontSDK.Trading.PortfolioItem>; // correct return type lacking in SDK
        // obsArray.data.forEach((item) => console.log('portfolio.positions item inspect:', (item.get as any)(null), 'metadata:', item.metadata())); // debug

        return this.sdkRequestsService.sdkObservableArrayAdapter$<InfrontSDK.Trading.PortfolioItem>(obsArray, InfrontUtil.InitialUpdate.None);
      })
    );
  };

  portfolioInstruments$ = this.portfolioTradingSymbols$('portfolioInstruments').pipe(map((symbols) => symbols.map((s) => getInstrumentFromSymbolLike(s))), filterUndefinedArray());

}
