import { forwardRef, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DashboardTranslateService } from '../../i18n';
import type { FolderType } from './http-dashboard.models';

/** Minimal ID info for a top level dashboard folder of type GLOBAL, USER, or SHARED. */
export interface HttpDashboardBaseFolderID {
  readonly role: 'GLOBAL' | 'USER' | 'SHARED';
}

/** Minimal ID info for a top level dashboard folder of type COMPANY. */
export interface HttpDashboardCompanyFolderID {
  readonly role: 'COMPANY';
  /** The company's realm; currently, only the user's realm. */
  readonly realm: string;
}

/** Minimal ID info for a top level subtenant dashboard folder. */
export interface HttpDashboardSubTenantFolderID {
  readonly role: 'SUBTENANT';
  /** The realm for the subtenant */
  readonly realm: string;
  /** The subtenant ID */
  readonly subTenantId: string;
  /** The display name of the subtenant, when known */
  readonly subTenantName?: string;
}

/** Minimal ID info for a top level dashboard folder. */
export type HttpDashboardFolderID = HttpDashboardBaseFolderID | HttpDashboardCompanyFolderID | HttpDashboardSubTenantFolderID

/**
 * HTTP dashboard configuration options.
 */
export interface HttpDashboardConfiguration {
  /** The maximum number of widgets allowed per dashboard. */
  readonly maxWidgetsPerDashboard: number;

  /** Which HTTP top level folders to load */
  readonly availableFolders: readonly FolderType[];

  /** Which realms to also access for dashboards (god mode only) */
  readonly additionalRealms: readonly string[];

  /** The frequency (in ms) at which the folder tree should be re-fetched from the back-end. */
  readonly dashboardRefreshInterval: number;

  /**
   * The display name for a top level folder. It is required to return a name.
   * Use the {@link DefaultHttpDashboardConfigurationProvider} to get default
   * values.
   */
  getDisplayName(model: HttpDashboardFolderID): Observable<string>;

  /**
   * Indicates whether a certain subtenant folder should be enabled.
   *
   * Used to opt-out of folders (e.g., test subtenants inside the vwd realm).
   *
   * Note that the infrastructure will first ask for the entire company
   * (with a {@link HttpDashboardCompanyFolderID}), and then, once the
   * list of subtenants is loaded, per subtenant ({@link HttpDashboardSubTenantFolderID}).
   */
  isSubTenantFolderEnabled(model: HttpDashboardFolderID): boolean;

  // TODO: Should we also support alternative folder orders? No clear use case yet...
}

/**
 * Abstract injection token to provide the HTTP dashboard services configuration
 * options.
 *
 * You can provide an alternative implementation by overriding this provider.
 * The default provider is {@link DefaultHttpDashboardConfigurationProvider},
 * which opens up everything, and bare minimal provides default names.
 *
 * When implementing this service yourself, you can inject
 * {@link DefaultHttpDashboardConfigurationProvider}, to gain access to its
 * defaults (such as the names).
 */
@Injectable({ providedIn: 'root', useExisting: forwardRef(() => DefaultHttpDashboardConfigurationProvider) })
export abstract class HttpDashboardConfigurationProvider {
  /** Load the configuration options. */
  public abstract load(): Observable<HttpDashboardConfiguration>;
}

const DISPLAYNAMES_GLOBAL = {
  // TODO: IM will need to translate with 'WIDGET_DASHBOARDS.FOLDERS.VWD',
  '*': 'Global dashboards',
  de: 'Globale Dashboards',
  fr: 'Tableaux de bord globaux',
};

const DISPLAYNAMES_USER = {
  // TODO: IM will need to translate with 'WIDGET_DASHBOARDS.FOLDERS.PERSONAL',
  '*': 'Personal dashboards',
  de: 'Meine Dashboards',
  fr: 'Tableaux de bord personnels',
  it: 'Dashboard personale',
  nl: 'Persoonlijke dashboards',
  no: 'Personlige dashbord',
};

const DISPLAYNAMES_SHARED = {
  // TODO: IM will need to translate with 'WIDGET_DASHBOARDS.FOLDERS.SHARED',
  '*': 'Shared dashboards',
  de: 'Geteilte Dashboards',
  fr: 'Tableaux de bord partagés',
  it: 'Dashboard condivisi',
  nl: 'Gedeelde dashboards',
  no: 'Delte dashbord',
};

/**
 * Default implementation for {@link HttpDashboardConfigurationProvider},
 * that imposes no limits. It also provides default names for the top level
 * folders.
 *
 * You should not inherit from this class, but instead inject it into
 * your own implementation to gain access to defaults. This way, you
 * won't depend on the constructor arguments of this implementation.
 */
@Injectable({ providedIn: 'root' })
export class DefaultHttpDashboardConfigurationProvider implements HttpDashboardConfigurationProvider {

  /** @deprecated Do not inherit from this class. */
  constructor(
    private readonly dashboardTranslateService: DashboardTranslateService,
  ) { }

  public readonly defaults: HttpDashboardConfiguration = {
    maxWidgetsPerDashboard: Number.MAX_SAFE_INTEGER,
    availableFolders: ['GLOBAL', 'COMPANY', 'SUBTENANT', 'SHARED', 'USER'],
    additionalRealms: [],
    dashboardRefreshInterval: 60_000,

    getDisplayName: (folder) => {
      const role = folder.role;

      switch (role) {
        case 'GLOBAL':
          return this.dashboardTranslateService.create('dashboards', 'displayNames', 'global', DISPLAYNAMES_GLOBAL);
        case 'COMPANY':
          return this.dashboardTranslateService.create('dashboards', 'displayNames', 'company', { '*': `${folder.realm}` });
        case 'SUBTENANT':
          return this.dashboardTranslateService.create('dashboards', 'displayNames', 'subTenant', { '*': folder.subTenantName ?? `Subtenant ${folder.subTenantId}` });
        case 'USER':
          return this.dashboardTranslateService.create('dashboards', 'displayNames', 'user', DISPLAYNAMES_USER);
        case 'SHARED':
          return this.dashboardTranslateService.create('dashboards', 'displayNames', 'shared', DISPLAYNAMES_SHARED);
        default: // This should not happen!
          throw new TypeError(`Unknown role "${role}".`);
      }
    },

    isSubTenantFolderEnabled(folder) {
      return this.availableFolders.includes(folder.role);
    }
  };

  public load(): Observable<HttpDashboardConfiguration> {
    return of(this.defaults);
  }
}
