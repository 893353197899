<div class="wt-alert-dialog">
  <div class="wt-flex-row wt-flex-align-middle wt-alert-dialog__title">
    <!-- new instrument alert -->
    <ng-container *ngIf="!params.alert && params.alertType === 'instrument'">
      <div class="wt-flex-column wt-flex-collapse wt-flex-shrink wt-alert-dialog-icon">
        <mat-icon fontIcon="notification_add"></mat-icon>
      </div>

      <div class="wt-flex-column">
        <span>{{ "GLOBAL.ALERT.ADD_ALERT" | translate }}</span>
      </div>
    </ng-container>

    <!-- modify instrument alert -->
    <ng-container *ngIf="params.alert && params.alertType === 'instrument'">
      <div class="wt-flex-column wt-flex-collapse wt-flex-shrink wt-alert-dialog-icon">
        <mat-icon fontIcon="edit_notifications"></mat-icon>
      </div>

      <div class="wt-flex-column">
        <span>{{ params.instrument?.ticker }}</span>
        <span>&nbsp;- {{ "ALERTS_NEW_EDIT_DIALOG.MODIFY_ALERT" | translate }}</span>
      </div>
    </ng-container>

    <!-- new watchlist alert -->
    <ng-container *ngIf="!params.alert && params.alertType === 'watchlist'">
      <div class="wt-flex-column wt-flex-collapse wt-flex-shrink wt-alert-dialog-icon">
        <mat-icon svgIcon="visibility"></mat-icon>
      </div>

      <div class="wt-flex-column">
        <span>{{ params.watchlist }}</span>
        <span>&nbsp;- {{ "GLOBAL.ALERT.ADD_ALERT" | translate }}</span>
      </div>
    </ng-container>

    <!-- modify watchlist alert -->
    <ng-container *ngIf="params.alert && params.alertType === 'watchlist'">
      <div class="wt-flex-column wt-flex-collapse wt-flex-shrink wt-alert-dialog-icon">
        <mat-icon fontIcon="visibility"></mat-icon>
      </div>

      <div class="wt-flex-column">
        <span>{{ params.alert["watchlist"] }}</span>
        <span>&nbsp;- {{ "ALERTS_NEW_EDIT_DIALOG.MODIFY_ALERT" | translate }}</span>
      </div>
    </ng-container>

    <div class="wt-flex-column wt-flex-shrink wt-flex-collapse wt-alert-dialog-icon-close">
      <mat-icon (click)="onCancel()" fontIcon="close"></mat-icon>
    </div>
  </div>

  <div class="wt-alert-dialog__search"
    *ngIf="!params.alert && !params.instrument && params.alertType === 'instrument'">
    <wt-search-compact
      class="wt-search__compact-inline"
      [searchConfig]="{
        feedScoreFactorItems: DefaultFeedScoreFactorItems,
        searchType: { symbol: true },
        history: false
      }"
      [placeholder]="'ALERTS_NEW_EDIT_DIALOG.SEARCH_INSTRUMENT' | translate"
      [panelWidth]="370"
      [showFlag]="false"
      [focusOnShowEnabled]="false"
      (itemClicked)="onItemClicked($event)"></wt-search-compact>
  </div>

  <ng-container *ngIf="symbolInfo$ | async as symbolInfo">
    <div class="wt-flex-row wt-flex-collapse wt-alert-dialog__header">
      <div class="wt-flex-column">
        <div class="wt-flex-row wt-flex-collapse wt-flex-dir-column">
          <div class="wt-flex-column">
            <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
              <div class="wt-flex-column wt-flex-shrink wt-alert-dialog__header-name">
                {{ symbolInfo.FullName | alternativeValue }}
              </div>

              <div class="wt-flex-column wt-flex-shrink wt-alert-dialog__header-currency wt-outdated-value">
                {{ symbolInfo.Currency | alternativeValue }}
              </div>

              <div class="wt-flex-column wt-flex-shrink">
                <wt-symbol-status [instrument]="instrument"
                  [hideSymbolStatus]="['undefined', 'open']"></wt-symbol-status>
              </div>
            </div>
          </div>

          <div class="wt-flex-column wt-alert-dialog__header-ticker">
            <div class="wt-flex-row wt-flex-align-middle wt-flex-collapse">
              <div class="wt-flex-column wt-flex-shrink">
                <ng-container [ngSwitch]="symbolInfo.SymbolClassification">
                  <wt-country-flag
                    *ngSwitchDefault
                    class="wt-alert-dialog__header-ticker-flag"
                    [country]="symbolInfo.CountryFlag"
                    [size]="32"></wt-country-flag>
                </ng-container>
              </div>

              <div class="wt-flex-column">
                <div class="wt-alert-dialog__header-ticker-value">
                  {{ symbolInfo.Ticker | alternativeValue }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wt-flex-column wt-flex-shrink wt-alert-dialog__header-ticker-focus">
        <wt-instrument-focus
          [data]="{
            open: symbolInfo.Open,
            high: symbolInfo.High,
            low: symbolInfo.Low,
            close: symbolInfo.LastTradedAt,
            time: symbolInfo.PreDisplayTime,
            lastTradedAt: symbolInfo.LastTradedAt,
            change: symbolInfo.Change,
            changePercent: symbolInfo.ChangePercent,
            decimals: symbolInfo.Decimals
          }"></wt-instrument-focus>
      </div>
    </div>

    <wt-alert-form
      (cancelClick)="onCancel()"
      (submitClick)="submitAction.next($event)"
      [alert]="params.alert"
      [alertType]="'instrument'"></wt-alert-form>
  </ng-container>

  <wt-alert-form
    *ngIf="this.params.alertType === 'watchlist'"
    (cancelClick)="onCancel()"
    (submitClick)="submitAction.next($event)"
    [alert]="params.alert"
    [alertType]="'watchlist'"></wt-alert-form>

  <ng-container *ngIf="submitListener$ | async"></ng-container>
</div>
