import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TopShareholdersComponent } from './top-shareholders.component';

@NgModule({
  declarations: [TopShareholdersComponent],
  imports: [
    CommonModule,
  ],
  exports: [],
  providers: [],
})
export class TopShareholdersModule { }
