import { registerLocaleData } from '@angular/common';
import { applyFormatOverrides, buildSyntheticEnLocale } from './app/util/locale';

import localeEnGb from '@angular/common/locales/en-GB';
import localeEnDe from '@angular/common/locales/en-DE';
import localeDe from '@angular/common/locales/de'; // also de-DE
import localeIt from '@angular/common/locales/it'; // also it-IT
import localeNl from '@angular/common/locales/nl'; // also nl-NL
import localeFr from '@angular/common/locales/fr'; // also fr-FR
import localeNo from '@angular/common/locales/no'; // also no-NO

// import localeDeAt from '@angular/common/locales/de-AT';
// import localeDeCh from '@angular/common/locales/de-CH';
// import localeNlBe from '@angular/common/locales/nl-BE';
// import localeFrBe from '@angular/common/locales/fr-BE';

// Angular doesn't provide an `en-NO` (nor `en-NB`/`en-NN`), unlike `en-DE`,
// `en-SE`, and `en-NL`. So, we synthesize it, by taking `no`, but replacing
// date-related texts with the `en-GB` equivalents.
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const localeEnNo = buildSyntheticEnLocale('en-NO', localeNo);

registerLocaleData(applyFormatOverrides(localeEnGb), 'en-GB');
registerLocaleData(applyFormatOverrides(localeEnDe), 'en-DE');
registerLocaleData(applyFormatOverrides(localeDe), 'de');
registerLocaleData(applyFormatOverrides(localeIt), 'it');
registerLocaleData(applyFormatOverrides(localeNl), 'nl');
registerLocaleData(applyFormatOverrides(localeFr), 'fr');
registerLocaleData(applyFormatOverrides(localeNo), 'no');
registerLocaleData(applyFormatOverrides(localeEnNo), 'en-NO');

// registerLocaleData(localeDeAt, 'de-AT');
// registerLocaleData(localeDeCh, 'de-CH');
// registerLocaleData(localeNlBe, 'nl-BE');
// registerLocaleData(localeFrBe, 'fr-BE');

// -------------------------------------------------------------------
// Custom formatting registrations
// -------------------------------------------------------------------

import { registerCustomLocaleData } from '@vwd/ngx-i18n';

import customLocaleEnGb from '@vwd/ngx-i18n/locales/en-GB';
import customLocaleEnDe from '@vwd/ngx-i18n/locales/en-DE';
import customLocaleEn from '@vwd/ngx-i18n/locales/en';
import customLocaleDe from '@vwd/ngx-i18n/locales/de';
import customLocaleIt from '@vwd/ngx-i18n/locales/it';
import customLocaleNl from '@vwd/ngx-i18n/locales/nl';
import customLocaleFr from '@vwd/ngx-i18n/locales/fr';
import customLocaleNo from '@vwd/ngx-i18n/locales/no';
import customLocaleEnNo from '@vwd/ngx-i18n/locales/en-NO';

// import customLocaleDeAt from '@vwd/ngx-i18n/locales/de-AT';
// import customLocaleDeCh from '@vwd/ngx-i18n/locales/de-CH';
// import customLocaleNlBe from '@vwd/ngx-i18n/locales/nl-BE';
// import customLocaleFrBe from '@vwd/ngx-i18n/locales/fr-BE';

const bigNumberShortNo = [
  [1e05, '000k'], // 100,000
  [1e06, '0m'], // 1,000,000
];
// WTKAPI-411 patch formats for WT5 / PM requirements, cause IPT has the same!
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const iptCustomBigNumberFormats = (customLocale: any, shortDef: (number | string)[][]): any => {
  return {
    ...(customLocale ?? {}),
    ...{
      ['bigNumberFormats']: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        ['number']: {
          ...(customLocale?.bigNumberFormats?.number ?? {}),
          short: shortDef,
        },
      },
    },
  };
};

registerCustomLocaleData(iptCustomBigNumberFormats(customLocaleEnGb, bigNumberShortNo), 'en-GB');
registerCustomLocaleData(iptCustomBigNumberFormats(customLocaleEnDe, bigNumberShortNo), 'en-DE');
registerCustomLocaleData(iptCustomBigNumberFormats(customLocaleEn, bigNumberShortNo), 'en');
registerCustomLocaleData(iptCustomBigNumberFormats(customLocaleDe, bigNumberShortNo), 'de');
registerCustomLocaleData(iptCustomBigNumberFormats(customLocaleIt, bigNumberShortNo), 'it');
registerCustomLocaleData(iptCustomBigNumberFormats(customLocaleNl, bigNumberShortNo), 'nl');
registerCustomLocaleData(iptCustomBigNumberFormats(customLocaleFr, bigNumberShortNo), 'fr');
registerCustomLocaleData(iptCustomBigNumberFormats(customLocaleNo, bigNumberShortNo), 'no');
registerCustomLocaleData(iptCustomBigNumberFormats(customLocaleEnNo, bigNumberShortNo), 'en-NO');
