import { InfrontSDK } from '@infront/sdk';
import { Observable, Subscriber } from 'rxjs';
import { finalize } from 'rxjs/operators';

type CacheItem<T> = { item: T; unbinder?: () => void };

/**
 * Deprecated (Use observe-symbols.service instead)
 * if streaming n number of fields for a single symbol is needed use streamingMappedSymbolData$,
 * or streamingMappedSymbolDataByWidget$ in sdk.requests.service
 */
export class ObservablesCache<T> {
  private cache: { [uuid: string]: { [key: string]: CacheItem<unknown> } } = {};

  private createItem<T>(symbol: InfrontSDK.SymbolData, fieldsMap: { [key in keyof T]: InfrontSDK.SymbolField }): T {
    const item = Object.entries(fieldsMap).reduce((acc, [key]) => {
      acc[key as keyof T] = symbol.get(fieldsMap[key as keyof T]);
      return acc;
    }, {} as T);
    return item;
  }

  private addItemToCache = <T>(uuid: string, key: string, item: T) => {
    this.cache[uuid] ??= {};
    (this.cache[uuid][key] as { item?: T }) ??= {};
    this.cache[uuid][key].item = item;
  };

  private addUnbinderToCache = (uuid: string, key: string, unbinder: () => void) => {
    this.cache[uuid] ??= {};
    (this.cache[uuid][key] as { item?: T }) ??= {};
    this.cache[uuid][key].unbinder = unbinder;
  };

  private requestedSymbols = (uuid: string): T[] =>
    Object.values(this.cache[uuid])
      .map((cacheItem) => cacheItem?.item as T)
      .filter((item) => item);

  /**
   * Get RxJs observable from SDK symbol observe push notification on a list of symbols and fields
   * @param list - list of symbols to observe
   * @param observeField - field to listen to notifications on - only one for now
   * @param fieldsMap - Map the object property names used in the component/service to InfrontSDK.SymbolFields - all values in the map
   * @param uuid - universally unique id which instance is using this cache so that unbinds can be made per instance (e.g. a widget)
   * will be updated when the observeField gets an update notification
   * */
  observeList$ = (
    list: InfrontSDK.SymbolData[],
    observeField: InfrontSDK.SymbolField,
    fieldsMap: { [key: string]: InfrontSDK.SymbolField },
    uuid: string
  ): Observable<T[]> =>
    new Observable((obs: Subscriber<T[]>) => {
      if (!this.cache[uuid]) {
        this.cache[uuid] = {};
      }
      list.forEach((symbol: InfrontSDK.SymbolData) => {
        if (!symbol?.observe) { return; }
        const key = symbol.get(InfrontSDK.SymbolField.Ticker);
        if (!this.cache[uuid][key]) {
          const observer = symbol.observe(
            observeField,
            () => {
              const item = this.createItem(symbol, fieldsMap);
              this.addItemToCache(uuid, key, item);
              obs.next(this.requestedSymbols(uuid));
            },
            undefined,
            InfrontSDK.InitialUpdate.Always
          );
          this.addUnbinderToCache(uuid, key, observer);
        }
      });
    }).pipe(
      finalize(() => {
        Object.values(this.cache[uuid])
          .map((cacheItem) => cacheItem.unbinder)
          .forEach((unbind) => unbind?.());
        delete this.cache[uuid];
      })
    );
}
