
import { InfrontSDK } from '@infront/sdk';
import { Column } from '../../shared/grid/columns.model';
import { SymbolWithTradingSymbol } from '../../shared/models/symbol-data.model';
import { ClassificationMap } from '../../shared/models/trading.model';
import { TradingOrdersColumns, TradingPrefix } from '../portfolio-orders/portfolio-orders.columns';



export const PortfolioTradesCols = {
  tradesCountryFlagTicker: {
    ...TradingOrdersColumns.tradingOrdersCountryFlagTicker,
    colId: 'tradesCountryFlagTicker'
  },
  tradesMarket: {
    ...TradingOrdersColumns.tradingOrdersMarket,
    colId: 'tradesMarket'
  },
  tradesBuyOrSell: {
    ...TradingOrdersColumns.tradingOrdersBuyOrSell,
    colId: 'tradesBuyOrSell'
  },
  tradesVolume: {
    ...TradingOrdersColumns.tradingOrdersVolume,
    colId: 'tradesVolume'
  },
  tradeId: {
    colId: 'tradeId',
    headerName: 'Trade Id',
    field: `${TradingPrefix}${InfrontSDK.TradingField.TradeId}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    width: 85,
  },
  orderId: {
    colId: 'orderId',
    headerName: 'Order Id',
    field: `${TradingPrefix}${InfrontSDK.TradingField.OrderId}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    width: 85,
  },
  price: {
    colId: 'price',
    headerName: 'Price',
    field: `${TradingPrefix}${InfrontSDK.TradingField.Price}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
    width: 114,
  },
  tradesValueWithCurrency: {
    ...TradingOrdersColumns.tradingOrdersValueWithCurrency,
    colId: 'tradesValueWithCurrency',
    headerName: 'Value',
    field: `${TradingPrefix}${InfrontSDK.TradingField.Price}` as InfrontSDK.TradingField,
    valueGetter: (params: { data: { symbol: SymbolWithTradingSymbol } }) => {
      const currency = params.data?.symbol?.tradingSymbol?.get(InfrontSDK.TradingField.Currency) as string ?? '';
      const price = params.data?.[`${TradingPrefix}${InfrontSDK.TradingField.Price}`] as number;
      const volume = params.data?.[`${TradingPrefix}${InfrontSDK.TradingField.Volume}`] as number;
      return (price == undefined) || (volume == undefined)
        // eslint-disable-next-line no-sparse-arrays
        ? JSON.stringify([, currency]) // NOSONAR
        : JSON.stringify([price * volume, currency]);
    },
    valueFormatter: 'sdkDecimalsCurrency',
    type: 'rightAligned',
    width: 114,
  },
  tradeType: {
    colId: 'tradeType',
    headerName: 'Type',
    field: InfrontSDK.SymbolField.SymbolClassification,
    type: 'rightAligned',
    width: 114,
    cellRenderer: (params: { value: string }) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return params.value == undefined ? '-' : ClassificationMap[params.value] ?? 'Other';
    }
  },
  portfolio: {
    colId: 'portfolio',
    headerName: 'Portfolio',
    field: `${TradingPrefix}${InfrontSDK.TradingField.Portfolio}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    width: 85,
  },
  tradeTime: {
    ...TradingOrdersColumns.tradingOrdersPlaced,
    colId: 'tradesTradeTime',
    headerName: 'Executed',
  }
};


export const PortfolioTradesColumns: Column[] = [
  PortfolioTradesCols.tradesCountryFlagTicker,
  PortfolioTradesCols.tradesMarket,
  PortfolioTradesCols.tradesBuyOrSell,
  PortfolioTradesCols.tradesVolume,
  PortfolioTradesCols.price,
  PortfolioTradesCols.tradesValueWithCurrency,
  PortfolioTradesCols.tradeType,
  PortfolioTradesCols.tradeId,
  PortfolioTradesCols.orderId,
  PortfolioTradesCols.portfolio,
  PortfolioTradesCols.tradeTime
];


