import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { AgGridModule } from 'ag-grid-angular';
import { ContextMenuModule } from '../dashboard-window/ui/context-menu/context-menu.module';
import { SearchModule } from '../search/search.module';
import { GridModule } from '../shared/grid/grid.module';
import { SymbolsGridModule } from '../wrappers/grid-wrappers/symbols-grid/symbols-grid.module';
import { AdminDialogComponent } from './admin-dialog/admin-dialog.component';
import { DefaultDashboardsPanelComponent } from './default-dashboards-panel/default-dashboards-panel.component';
import { RealmAdminPanelComponent } from './realm-admin-panel/realm-admin-panel.component';
import { SidebarInstrumentListEditorComponent } from './sidebar/sidebar-instrument-list-editor.component';
import { SidebarListEditPanelComponent } from './sidebar/sidebar-instrument-lists-panel.component';
import { GeneralSettingsModule } from '../shared/general-settings';
import { NewAdminItemModalComponent } from './new-admin-item-modal/new-admin-item-modal.component';
import { BrandingPanelComponent } from './branding-panel/branding-panel.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTabsModule,
    GridModule,
    AgGridModule,
    ContextMenuModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
    SearchModule,
    SymbolsGridModule,
    GeneralSettingsModule,
  ],
  declarations: [
    NewAdminItemModalComponent,
    AdminDialogComponent,
    SidebarListEditPanelComponent,
    SidebarInstrumentListEditorComponent,
    RealmAdminPanelComponent,
    DefaultDashboardsPanelComponent,
    BrandingPanelComponent,
  ],
  exports: [
    AdminDialogComponent,
    NewAdminItemModalComponent,
  ]
})
export class AdminModule { }
