<div class="wt-dialog">
  <div class="wt-flex-row wt-flex-align-middle wt-flex-collapse wt-dialog__title">
    <div class="wt-flex-column wt-flex-shrink wt-dialog-icon">
      <mat-icon fontIcon="refresh"></mat-icon>
    </div>

    <div class="wt-flex-column">
      <h1 class="wt-h1">{{ "RESET_OPTIONAL_DIALOG.RESET_OPTIONAL_DIALOGS" | translate }}</h1>
    </div>
  </div>

  <div mat-dialog-content>
    <p>{{ "RESET_OPTIONAL_DIALOG.DO_YOU_WANT_RESET" | translate }}</p>
  </div>

  <div class="wt-action-buttons">
    <div class="wt-flex-row wt-flex-collapse wt-flex-align-right">
      <div class="wt-flex-column wt-flex-shrink">
        <button class="wt-button wt-button--cancel" type="button" (click)="onClose(false)">{{
          "GLOBAL.BUTTON.CANCEL" | translate }}</button>
      </div>

      <div class="wt-flex-column wt-flex-shrink">
        <button class="wt-button wt-button--primary" type="button" (click)="onClose(true)">{{
          "GLOBAL.BUTTON.RESET" | translate }}</button>
      </div>
    </div>
  </div>
</div>