import { Infront } from '@infront/sdk';

// Taken from IWT4
export const OrderStackWidgetHiddenInactiveDeletedOrders: Infront.TradingOrderStatus[] = [
  Infront.TradingOrderStatus.Active, // New, not from IWT4
  Infront.TradingOrderStatus.Exchange,
  Infront.TradingOrderStatus.Pending,
  Infront.TradingOrderStatus.PendingInsert,
  Infront.TradingOrderStatus.Offline,
  Infront.TradingOrderStatus.Request,
  Infront.TradingOrderStatus.Monitor,
  Infront.TradingOrderStatus.Working,
  Infront.TradingOrderStatus.Executed,
  Infront.TradingOrderStatus.DeletedRefused,
  Infront.TradingOrderStatus.DeletedExecuted,
  // Infront.TradingOrderStatus.Deleted,
  // Infront.TradingOrderStatus.Inactive,
  // Infront.TradingOrderStatus.DeletedModify,
];

// Taken from IWT4
const ProviderIdsWithoutAvgPrice: Readonly<number[]> = [
  277,
];

export const hasAvgPrice = (providerId: number): boolean => {
  return !ProviderIdsWithoutAvgPrice.includes(providerId);
};

export const PositionsWidgetInstrumentTypes = [
  'STOCK',
  'BOND',
  'EURO_OPTION',
  'FUTURES',
  'OPTION',
  'FUND',
  // 'US_OPTION',
  // 'CERTIFICATE',
  // 'COMBO'
];

export const DEFAULT_ORDER_STACK_HEIGHT_PX = 30; // container-label 20px + margin-bottom 10px
export const ORDER_STACK_PLACEHOLDER_HEIGHT_PX = 15;

export const DEFAULT_POSITIONS_HEIGHT_PX = 20; // container-label 20px
export const POSITIONS_PLACEHOLDER_HEIGHT_PX = 15;

export const VISIBLE_Y_SCROLLBAR_CSS_CLASS = 'wt-scroll-y';
