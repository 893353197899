import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchDataParams, SearchDataSource } from '../../../search.model';
import { SearchItemMarket } from './search-item-market.model';

@Component({
  selector: 'wt-search-item-market',
  templateUrl: './search-item-market.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchItemMarketComponent {
  @Input() searchItemMarket: SearchItemMarket;
  @Input() showFlag = true;
  @Input() hasFeedClickEvent = true;
  @Input() isCompact: boolean;
  @Input() searchDataSource: SearchDataSource;

  @Output() feedClick = new EventEmitter<SearchDataParams>();
  @Output() closeDropdown = new EventEmitter<void>();
}
