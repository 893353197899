import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { arraysAreEqual } from '../internal/utils/equality';
import type { DashboardFolderModel, DashboardLinkCreate, DashboardModel, DashboardModelCreate, DashboardModelUpdate } from '../models';
import type { DashboardService } from './dashboard.service';
import type { DashboardFolderRef } from '../state-refs';
import type { ReadonlyArrayMap } from './utils/array-map';

export interface DashboardGetChildrenContext {
  dashboardService: DashboardService
  nodeRef: DashboardFolderRef,
  node: DashboardFolderModel,
  models$: Observable<ReadonlyArrayMap<string, DashboardModel>>;
  comparer: (x: DashboardModel, y: DashboardModel) => number;
}

export abstract class DashboardProvider {

  public abstract getDashboards(): Observable<readonly DashboardModel[]>;

  public abstract create(parent: DashboardFolderModel, data: DashboardModelCreate): Observable<DashboardModel>;

  public createLink(parent: DashboardFolderModel, data: DashboardLinkCreate): Observable<DashboardModel> {
    throw new TypeError('Provider doesn\'t support links.');
  }

  public abstract update(id: string, changes: DashboardModelUpdate): Observable<DashboardModel>;

  public abstract delete(id: string): Observable<void>;

  public canMoveTo(node: DashboardModel, target: DashboardFolderModel): boolean {
    return false;
  }

  public canLinkTo(parent: DashboardFolderModel, node: DashboardModel): boolean {
    return false;
  }

  public refresh(): void { } // NOSONAR

  /**
   * Returns a live filter of child nodes of a folder.
   */
  public getChildren(context: DashboardGetChildrenContext): Observable<readonly DashboardModel[]> {
    return context.models$.pipe(
      map(models => models.filter(m => m.parentId === context.node.id && m.id !== context.node.id)),
      distinctUntilChanged(arraysAreEqual),
      map(models => models.sorted(context.comparer)),
    );
  }
}
