<!-- Widget Title Bar (only display title) -->
<ng-container *ngIf="window.dragEnabled === false && window.layerIndex !== 2">
  <div class="widget-title-bar__title gridster-item-content">
    <!-- Title label -->
    <div class="widget-title-bar-label gridster-item-content">
      {{ ('MARKET_OVERVIEW_WIDGET.TEMPLATES.HEADER.' + (window.settings.template || 'NA')) | translate }}
    </div>
  </div>
</ng-container>

<!-- Widget Title Bar (display search and buttons) -->
<ng-container *ngIf="window.dragEnabled !== false || window.layerIndex === 2">
  <!-- Widget Title Bar Left (__title)-->
  <div class="widget-title-bar__title gridster-item-content wt-draggable">
    <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
      <!-- Dropdown and Title label-->
      <div class="wt-flex-column wt-flex-shrink">
        <wt-dropdown
          class="wt-market-overview__header-dropdown"
          [matDropdownIcon]="'travel_explore'"
          [dropdownLabel]="('MARKET_OVERVIEW_WIDGET.TEMPLATES.HEADER.' + (window.settings.template || 'NA')) | translate"
          [items]="availableTemplates"
          (itemSelected)="onItemSelected($event)"></wt-dropdown>
      </div>

      <!-- Widget Title Bar - empty middle -->
      <div class="wt-flex-column">&nbsp;</div>

      <div class="wt-flex-column wt-flex-shrink">
        <!-- Widget Title Bar Right (__buttons)-->
        <div class="widget-title-bar__buttons gridster-item-content">
          <ul class="wt-list-inline">
            <li class="wt-list-inline__item gridster-item-content">
              <wt-link-channel-menu [window]="window" [dashboard]="dashboard"></wt-link-channel-menu>
            </li>
            <li class="wt-list-inline__item gridster-item-content">
              <wt-context-menu class="gridster-item-content" [context]="{ widget, dashboard }"></wt-context-menu>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-container>
