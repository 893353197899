import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { AgGridModule } from 'ag-grid-angular';

import { MatDialogModule } from '@angular/material/dialog';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { SearchModule } from '../search/search.module';
import { DropdownPortfolioModule } from '../shared/dropdown/dropdown-portfolio/dropdown-portfolio.module';
import { DropdownWatchlistModule } from '../shared/dropdown/dropdown-watchlist/dropdown-watchlist.module';
import { DropdownModule } from '../shared/dropdown/dropdown.module';
import { ConfirmDialogModule } from '../util/components/confirm-dialog/confirm-dialog.module';
import { NewDialogModule } from '../util/components/new-dialog/new-dialog.module';
import { DashboardWindowComponent } from './dashboard-window.component';
import { AlertLogHeaderComponent } from './headers/alert-log-header/alert-log-header.component';
import { CalendarHeaderComponent } from './headers/calendar-header/calendar-header.component';
import { ChainsHeaderComponent } from './headers/chains-header/chains-header.component';
import { RenameChainsTitleDialogComponent } from './headers/chains-header/rename-chains-title-dialog/rename-chains-title-dialog.component';
import { InstrumentHeaderComponent } from './headers/instrument-header/instrument-header.component';
import { MarketHeaderComponent } from './headers/market-header/market-header.component';
import { MarketOverviewHeaderComponent } from './headers/market-overview-header/market-overview-header.component';
import { NewsHeaderComponent } from './headers/news-header/news-header.component';
import {
  TitleAndContextMenuOnlyHeaderComponent,
} from './headers/title-and-context-menu-only-header/title-and-context-menu-only-header.component';
import { TitleOnlyHeaderComponent } from './headers/title-only-header/title-only-header.component';
import { RenameUserlistDialogComponent } from './headers/userlist-header/rename-userlist-dialog/rename-userlist-dialog.component';
import { UserlistHeaderComponent } from './headers/userlist-header/userlist-header.component';
import { WatchlistHeaderComponent } from './headers/watchlist-header/watchlist-header.component';
import { ContextMenuModule } from './ui/context-menu/context-menu.module';
import { LinkChannelMenuComponent } from './ui/link-channel-menu/link-channel-menu.component';
import { LinkDisplayPipe } from './ui/link-channel-menu/link-display.pipe';
import { SelectedLinkChannelComponent } from './ui/link-channel-menu/selected-link-channel.component';
import { MarketOverviewDropdownModule } from './ui/market-overview-dropdown/market-overview-dropdown.module';
import { MatDropdownCountryModule } from './ui/mat-dropdown/mat-dropdown-country/mat-dropdown-country.module';
import { LazyCompDirective } from './util/lazy-comp.directive';
import { WidgetLabelPipe } from './widget-label.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatFormFieldModule,
    MatTabsModule,
    MatListModule,
    MatTableModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    DirectivesModule,
    AgGridModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    ReactiveFormsModule,
    SearchModule,
    ContextMenuModule,
    ConfirmDialogModule,
    NewDialogModule,
    DropdownModule,
    DropdownWatchlistModule,
    DropdownPortfolioModule,
    MatDropdownCountryModule,
    MarketOverviewDropdownModule,
    PipesModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [DashboardWindowComponent],
  declarations: [
    DashboardWindowComponent,
    LazyCompDirective,
    InstrumentHeaderComponent,
    AlertLogHeaderComponent,
    MarketHeaderComponent,
    MarketOverviewHeaderComponent,
    LinkChannelMenuComponent,
    SelectedLinkChannelComponent,
    LinkDisplayPipe,
    WatchlistHeaderComponent,
    UserlistHeaderComponent,
    RenameUserlistDialogComponent,
    NewsHeaderComponent,
    CalendarHeaderComponent,
    WidgetLabelPipe,
    TitleAndContextMenuOnlyHeaderComponent,
    TitleOnlyHeaderComponent,
    ChainsHeaderComponent,
    RenameChainsTitleDialogComponent,
  ],
  providers: [],
})
export class DashboardWindowModule { }
