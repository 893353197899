import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { CellClassParams, ColSpanParams, ITooltipParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';

import type { UserSettingsService } from '../../services/user-settings.service';
import type { SymbolDataItem } from '../../shared/models/symbol-data.model';
import { getCountryForSymbol } from '../../util/country';
import { isSymbolData } from '../../util/symbol';
import type { NewsHeadline } from '../../widgets/news/news.model';
import { GRID_OPEN_INSTRUMENT_DASHBOARD_DEFAULT } from '../models/settings.model';
import type { Column } from './columns.model';

// list of available column definitions
//todo i18n

export const symbolStatusColor = (params: CellClassParams, field: InfrontSDK.SymbolField) => {
  let symbolStatusColorClass = '';

  if (isSymbolData(params?.data?.symbol) && field) {
    symbolStatusColorClass = (params.data.symbol as InfrontSDK.SymbolData).get?.(field) === 5 ? 'ag-cell--auction' : '';
  }

  return symbolStatusColorClass;
};

export const upDownColor = (params: { value: number | undefined }, lowerBound = 0, upperBound = 0) => {
  if (params.value == undefined) {
    return ['grid__cell'];
  }
  if (params.value < lowerBound) {
    return ['grid__cell', 'grid__cell--negative'];
  }
  return params.value > upperBound
    ? ['grid__cell', 'grid__cell--positive']
    : ['grid__cell'];
};

export const downColor = (params: { value: number }, lowerBound = 0) => {
  return params.value < lowerBound //
    ? ['grid__cell', 'grid__cell--negative']
    : ['grid__cell'];
};

export const timeOrDateOutdated = (
  { value, isTodayClassList, isNotTodayClassList }: { value?: Date; isTodayClassList?: string[]; isNotTodayClassList?: string[]; }
) => {
  if (value == undefined) {
    return [];
  }
  if (!(value instanceof Date)) {
    value = new Date(value);
  }
  if (isNaN(value.getTime())) {
    return [];
  }

  return InfrontUtil.isToday(value)
    ? isTodayClassList ?? []
    : isNotTodayClassList ?? [];
};

export const symbolListFormatter = (params: ValueFormatterParams | ITooltipParams): string => {
  const tickers = (params.data.symbols as InfrontSDK.SymbolId[]).map((symbol) => {
    return symbol?.ticker;
  }) as string[];
  return tickers.join(',');
};

export const isSubHeaderRow = (params: ColSpanParams): boolean => {
  return params.data.section === 'subHeader';
};

export const getData = (params: ValueGetterParams | ValueFormatterParams, field: InfrontSDK.SymbolField, fallback?: string): string => {
  if (isSymbolData(params?.data) && field) {
    return (params.data as InfrontSDK.SymbolData).get(field) as string;
  }
  return fallback ? params.data[fallback] as string : '';
};

export const getUnderlyingData = (params: ValueGetterParams | ValueFormatterParams, field: InfrontSDK.SymbolField): string => {
  return isSymbolData(params?.data?.symbol) && field //
    ? (params.data.symbol as InfrontSDK.SymbolData).get?.(field) as string
    : '';
};

export const getCountry = (params: ValueGetterParams | ValueFormatterParams | string): string => {
  const data = (params as ValueGetterParams | ValueFormatterParams)?.data as InfrontSDK.SymbolData;
  return getCountryForSymbol(data);
};

// set `--clickable` class to ag-grid "ticker"-alike cells, in case it is allowed
// for the current cell to open the instrument-dashboard by clicking the ticker/symbol.
export const tickerClickableCellClass = (params: CellClassParams) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const context = params?.context;
  if (!context) { return ''; }

  // condition corresponds to grid.service -> handleGridCellClick -> canOpenInstrumentDashboard
  if (!!context.forceOpenInstrumentDashboard || ((context.userSettingsService as UserSettingsService)?.getValue('enableGridOpenInstrumentDashboard') ?? GRID_OPEN_INSTRUMENT_DASHBOARD_DEFAULT)) {
    return 'wt-grid--clickable';
  }
  return '';
};

export const LongHeaderNamesMap: { [colId: string]: string } = {
  // used in ColumnPicker but not in grid headers
  priceArrow: 'Last Arrow',
  hitterTaker: 'Buyer/Seller',
  displayName: 'Trading Symbol',
  isin: 'Trading ISIN',
  tradingCurrency: 'Trading Currency',
  listsCurrency: 'Currency',
  currency: 'Currency',
  avgPrice: 'Average Price',
  ATR30: 'Average True Range 30 Days',
  averageDailyVolume: 'Average Daily Volume',
  realTimeAverageDailyVolume: 'Realtime ADV',
  realTimeAverageDailyVolumeFactor: 'Realtime ADV Factor',
  flagAndFullName: 'Flag + Name',
  totalReturnChange1W: 'Total Return 1W',
  totalReturnChange1M: 'Total Return 1M',
  totalReturnChange3M: 'Total Return 3M',
  totalReturnChange6M: 'Total Return 6M',
  totalReturnChangeYTD: 'Total Return YTD',
  totalReturnChange1Y: 'Total Return 1Y',
  totalReturnChange2Y: 'Total Return 2Y',
  totalReturnChange3Y: 'Total Return 3Y',
  totalReturnChange5Y: 'Total Return 5Y',
  totalReturnPctChange1W: 'Total Return 1W %',
  totalReturnPctChange1M: 'Total Return 1M %',
  totalReturnPctChange3M: 'Total Return 3M %',
  totalReturnPctChange6M: 'Total Return 6M %',
  totalReturnPctChangeYTD: 'Total Return YTD %',
  totalReturnPctChange1Y: 'Total Return 1Y %',
  totalReturnPctChange2Y: 'Total Return 2Y %',
  totalReturnPctChange3Y: 'Total Return 3Y %',
  totalReturnPctChange5Y: 'Total Return 5Y %',
  countryFlagTicker: 'Flag + Symbol',
  listsSymClass: 'Symbol Classification',
  esgTotalScore: 'ESG Total Score',
  esgEnvironmentalScore: 'Environmental Score',
  esgSocialScore: 'Social Score',
  esgGovernanceScore: 'Corporate Governance Score',
  sharpRatio1Y: 'Sharpe ratio 1Y',
  sharpRatio3Y: 'Sharpe ratio 3Y',
  sharpRatio5Y: 'Sharpe ratio 5Y',
  trackingError12M: 'Tracking Error 1Y',
  minInitInvestment: 'Min init investment',
  fundPriceEarnings: 'Fund P/E',
  fundPriceBook: 'Fund P/B',
  prospectiveDividendYield: 'Fund Div. Yield',
  topHoldingDate: 'Top H Date',
  topHoldingDays: 'Top H Days',
  displaySymbolStatus: 'Symbol Status',
  advancedSymbolStatus: 'Advanced Symbol Status',
  averageDailyVolumeFactor: 'ADV Factor',
};

export const ColumnRegistry: { [key: string]: Column } = {
  // History TimeSeries
  date: {
    colId: 'date',
    headerName: 'Date',
    field: 'date',
    valueFormatter: 'dateFormatter',
    type: 'rightAligned',
    width: 65,
  },
  close: {
    colId: 'last',
    headerName: 'Close',
    field: 'last',
    valueFormatter: 'sdkDecimals',
    type: 'rightAligned'
  },
  historyChange: {
    colId: 'historyChange',
    headerName: '±',
    field: 'change',
    cellClass: (params: { value: number }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    // type used only for cell-header when using custom cellClass
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
  },
  historyChangePct: {
    colId: 'historyChangePct',
    headerName: '± %',
    field: 'changePercent',
    cellClass: (params: { value: number }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    // type used only for cell-header when using custom cellClass
    type: 'rightAligned',
    valueFormatter: 'twoDecimalsPercent',
  },
  volume: { colId: 'volume', headerName: 'Volume', field: 'volume', type: 'rightAligned', cellRenderer: 'bigNumberCellComponent' },
  turnover: { colId: 'turnover', headerName: 'Turnover', field: 'turnover', type: 'rightAligned', cellRenderer: 'bigNumberCellComponent' },
  open: { colId: 'open', headerName: 'Open', field: 'open', valueFormatter: 'sdkDecimals', type: 'rightAligned' },
  high: { colId: 'high', headerName: 'High', field: 'high', valueFormatter: 'sdkDecimals', type: 'rightAligned' },
  low: { colId: 'low', headerName: 'Low', field: 'low', valueFormatter: 'sdkDecimals', type: 'rightAligned' },
  bid: {
    colId: 'bid',
    headerName: 'Bid',
    field: 'bid',
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
    width: 74,
    cellRenderer: 'cellFlashComponent',
    cellClass: (params: CellClassParams) => {
      const defaultClassList = ['ag-right-aligned-cell'];
      // if (params?.context?.tradingState?.isConnected && params?.data?.symbol?.isTradable) {
      //   defaultClassList.push('wt-grid--clickable')
      // }
      return [...defaultClassList];
    },
  },
  ask: {
    colId: 'ask',
    headerName: 'Ask',
    field: 'ask',
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
    width: 74,
    cellRenderer: 'cellFlashComponent',
    cellClass: (params: CellClassParams) => {
      const defaultClassList = ['ag-right-aligned-cell'];
      // if (params?.context?.tradingState?.isConnected && params?.data?.symbol?.isTradable) {
      //   defaultClassList.push('wt-grid--clickable')
      // }
      return [...defaultClassList];
    },
  },
  mid: { colId: 'mid', headerName: 'Mid', field: 'mid', valueFormatter: 'sdkDecimals', type: 'rightAligned' },
  VWAP: { colId: 'VWAP', headerName: 'VWAP', field: 'vwap', valueFormatter: 'sdkDecimals', type: 'rightAligned' },
  onExchTurnover: {
    colId: 'onExchTurnover',
    headerName: 'On Exchange Turnover',
    field: 'onExchTurnover',
    cellRenderer: 'bigNumberCellComponent',
    type: 'rightAligned',
  },
  onFloorVolume: { colId: 'onFloorVolume', headerName: 'On Floor Volume', field: 'onFloorVolume', type: 'rightAligned', cellRenderer: 'bigNumberCellComponent' },

  // Funds
  exposureWeight: {
    colId: 'exposureWeight',
    headerName: 'Weight',
    field: 'weight',
    valueFormatter: 'twoDecimalsPercent',
    type: 'rightAligned',
    suppressMovable: true,
    resizable: true,
    sortable: false,
    wrapText: false,
    minWidth: 70,
  },
  distributionBar: {
    colId: 'distributionBar',
    headerName: '',
    field: 'name',
    cellRenderer: 'distributionBarCellComponent',
    suppressMovable: true,
    resizable: true,
    sortable: false,
    wrapText: false,
    width: 400,
  },
  countryFlag: {
    colId: 'countryFlag',
    headerName: '',
    // do not use "field: something" here!
    // a proper valueGetter is required to detect changed data, for cellRenderer to refresh!
    valueGetter: (params: ValueGetterParams) => getCountry(params),
    cellRenderer: 'countryFlagCellComponent',
    suppressMovable: true,
    suppressSizeToFit: true,
    suppressAutoSize: true,
    resizable: false,
    sortable: false,
    wrapText: false,
    width: 24,
  },
  countryFlagTicker: {
    colId: 'countryFlagTicker',
    headerName: 'Symbol',
    cellClass: tickerClickableCellClass,
    //field: InfrontSDK.SymbolField.CountryOfIncorporation,
    // do not use "field: something" here, else sorting will not work!
    //valueGetter: (params: ValueGetterParams) => getData(params, InfrontSDK.SymbolField.Ticker),

    cellRenderer: 'countryFlagTickerCellComponent',
    suppressMovable: false,
    resizable: true,
    sortable: true,
    wrapText: false,
    minWidth: 94,
  },

  //Trades
  dateTimeTime: {
    colId: 'dateTimeTime',
    headerName: 'Time',
    field: 'dateTime',
    valueFormatter: 'timeFormatter',
    width: 75,
    type: 'rightAligned',
  },
  dateTimeDate: {
    colId: 'dateTimeDate',
    headerName: 'Date',
    field: 'dateTime',
    valueFormatter: 'dateFormatter',
    width: 75,
    type: 'rightAligned',
  },
  price: {
    colId: 'price',
    headerName: 'Price',
    field: 'price',
    valueFormatter: 'sdkDecimals',
    type: 'rightAligned',
  },
  priceArrow: {
    colId: 'priceArrow',
    headerName: 'L',
    field: 'change',
    cellClass: (params: { value: number }) => [...upDownColor(params), 'grid__cell--centered'],
    cellRenderer: 'priceArrowComponent',
    headerTooltip: 'Price Arrow',
    headerClass: 'text-center',
  },
  type: { colId: 'type', headerName: 'Type', field: 'type' },
  hitterTaker: {
    colId: 'hitterTaker',
    headerName: 'B/S',
    field: 'hitterTaker',
    valueFormatter: (params: ValueFormatterParams) => {
      const bsMap = { Taker: 'S', Hitter: 'B' };
      return bsMap[params.data['hitterTaker'] as keyof typeof bsMap] ?? '-';
    },
    cellClass: (params: { value: string }) => {
      if (params.value === 'Hitter') {
        return ['grid__cell', 'grid__cell--positive', 'grid__cell--centered'];
      }
      return params.value === 'Taker'
        ? ['grid__cell', 'grid__cell--negative', 'grid__cell--centered']
        : ['grid__cell--centered'];
    },
    tooltipValueGetter: (params) => {
      const bsMap = { Taker: 'Seller initiated', Hitter: 'Buyer initiated' };
      return bsMap[params.data['hitterTaker'] as keyof typeof bsMap] ?? '';
    },
    headerTooltip: 'Buyer/Seller',
    headerClass: 'text-center',
  },
  esmaTypes: {
    colId: 'esmaTypes',
    headerName: 'ESMA',
    field: 'esmaTypes',
    tooltipValueGetter: (params: ITooltipParams) => {
      const trade = params.data as InfrontSDK.Trade;
      if (!trade.esmaTypesDescription) {
        return '';
      }
      const text = trade.esmaTypesDescription
        .map((desc: string, i: number) => {
          const esmaType = trade.esmaTypes[i];
          if (esmaType && typeof esmaType === 'string') {
            return `${esmaType} = ${desc}`;
          }
          return '';
        })
        .join(',\n');
      return text;
    },
  },
  buyer: { colId: 'buyer', headerName: 'Buyer', field: 'buyer', type: 'rightAligned' },
  seller: { colId: 'seller', headerName: 'Seller', field: 'seller' },
  spread: {
    colId: 'spread',
    headerName: 'Spread %',
    field: 'bid',
    valueGetter: (params: ValueGetterParams) => {
      const trade = params.data as InfrontSDK.Trade;
      if (!trade.bid || !trade.ask || !trade.price) {
        return '-';
      }
      const spreadPercent = ((trade.ask - trade.bid) / trade.price) * 100;
      return spreadPercent;
    },
    valueFormatter: 'twoDecimalsPercent',
    type: 'rightAligned',
  },
  market: { colId: 'market', headerName: 'Venue', field: 'market' },
  value: {
    colId: 'value',
    headerName: 'Value',
    field: 'value',
    type: 'rightAligned',
    valueGetter: (params: ValueGetterParams) => {
      const trade = params.data as InfrontSDK.Trade;
      return trade.volume;
    },
    valueFormatter: 'twoDecimals',
  },

  //Dividends
  amount: { colId: 'amount', headerName: 'Dividend', field: 'amount', valueFormatter: 'twoDecimals', type: 'rightAligned' },
  currency: { colId: 'currency', headerName: 'Currency', field: 'currency', type: 'rightAligned' },

  //splits
  factor: { colId: 'factor', headerName: 'Factor', field: 'factor', valueFormatter: 'twoDecimals', type: 'rightAligned' },

  // Calendar
  calendarDate: {
    colId: 'calendarDate',
    headerName: 'Date',
    field: 'dateTime',
    valueFormatter: 'dateFormatter',
    suppressMovable: true,
    resizable: false,
    sortable: false,
    width: 76,
  },
  calendarEventDescription: {
    colId: 'calendarEventDescription',
    headerName: 'Headline',
    field: 'description',
    suppressMovable: true,
    resizable: false,
    sortable: false,
    // IMPORTANT: autoHeight set to true can cause 100% CPU if used with ag-grid v26/27!
    // The reason is somewhere within ag-grid colum/row events, but could not be fixed or worked around!
    autoHeight: false,
    wrapText: true,
    flex: 1,
  },
  // News
  newsTime: {
    colId: 'newsTime',
    headerName: 'Time',
    field: 'dateTime',
    valueFormatter: 'timeOrDateFormatter',
    suppressMovable: true,
    resizable: false,
    sortable: false,
    width: 76,
  },
  headline: {
    colId: 'headline',
    headerName: 'Headline',
    field: 'cellHeadline',
    suppressMovable: true,
    resizable: false,
    sortable: false,
    // IMPORTANT: autoHeight set to true can cause 100% CPU if used with ag-grid v26/27!
    // The reason is somewhere within ag-grid colum/row events, but could not be fixed or worked around!
    autoHeight: false,
    cellRenderer: 'headlineCellComponent',
    cellClass: 'grid__cell--ellipsis',
    tooltipValueGetter: (params: ITooltipParams) => {
      return (params.data as NewsHeadline).headline;
    },
  },
  source: {
    colId: 'source',
    headerName: 'Source',
    field: 'feedShortName',
    suppressMovable: true,
    resizable: false,
    sortable: false,
    maxWidth: 84,
    type: 'rightAligned',
    filter: 'feedFilterComponent',
    tooltipValueGetter: (params: ITooltipParams) => {
      return (params.data as NewsHeadline).feedLongName;
    },
  },
  // lists all tickers of the params.data.symbols separated by a ','
  tickerList: {
    colId: 'tickerList',
    headerName: 'Symbol',
    field: 'tickerList',
    suppressMovable: true,
    resizable: false,
    sortable: false,
    maxWidth: 84,
    type: 'rightAligned',
    valueFormatter: symbolListFormatter,
    tooltipValueGetter: symbolListFormatter,
  },

  //lists
  ticker: {
    colId: 'ticker',
    headerName: 'Symbol',
    field: InfrontSDK.SymbolField.PreDisplayTicker,
    cellClass: tickerClickableCellClass,
    // TODO: translation on ticker, whats that?
    //valueGetter: (params: ValueGetterParams) => params.data.translation ?? getData(params, InfrontSDK.SymbolField.PreDisplayTicker, 'ticker'),
    //type: 'leftAligned',
    maxWidth: 100,
    minWidth: 40,
  },
  fullName: {
    colId: 'fullName',
    headerName: 'Name',
    field: InfrontSDK.SymbolField.FullName,
    maxWidth: 250,
    minWidth: 40,
  },

  timeSeries30days: {
    colId: 'timeSeries30days',
    headerName: '30D Chart',
    sortable: false,
    resizable: false,
    field: InfrontSDK.SymbolField.PreLastTradedAt,
    cellRenderer: 'timeSeriesComponent',
    width: 130,
  },
  lastValid: {
    colId: 'lastValid',
    headerName: 'Last',
    field: InfrontSDK.SymbolField.PreLastTradedAt,
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
    width: 74,
    cellRenderer: 'cellFlashComponent',
    cellClass: (params: CellClassParams) => {
      const defaultClassList = ['ag-right-aligned-cell'];
      if (params?.context?.tradingState?.isConnected && params?.data?.symbol?.isTradable) {
        defaultClassList.push('wt-grid--clickable');
      }
      const preDisplayTime = (params?.data as SymbolDataItem)?.symbol?.get(InfrontSDK.SymbolField.PreDisplayTime) as Date;
      if (!preDisplayTime) {
        return defaultClassList;
      }
      const data = {
        value: preDisplayTime,
        isNotTodayClassList: ['grid__cell--outdated'],
      };
      return [...timeOrDateOutdated(data), ...defaultClassList];
    },
  },
  listsVWAP: {
    colId: 'listsVWAP',
    headerName: 'VWAP',
    field: InfrontSDK.SymbolField.VWAP,
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
  },
  listsChange: {
    colId: 'listsChange',
    headerName: '±',
    field: InfrontSDK.SymbolField.Change,
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
    cellClass: (params: { value: number }) => {
      return [...upDownColor(params), 'ag-right-aligned-cell'];
    },
  },
  listsPctChange: {
    colId: 'listsPctChange',
    headerName: '%',
    field: InfrontSDK.SymbolField.ChangePercent,
    type: 'rightAligned',
    valueFormatter: 'twoDecimalsPercent',
    cellClass: (params: { value: number }) => [...upDownColor(params), 'ag-right-aligned-cell'],
  },
  listsHigh: {
    colId: 'listsHigh',
    headerName: 'High',
    field: InfrontSDK.SymbolField.High,

    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
  },
  listsLow: {
    colId: 'listsLow',
    headerName: 'Low',
    field: InfrontSDK.SymbolField.Low,

    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
  },
  listsBid: {
    colId: 'listsBid',
    headerName: 'Bid',
    field: InfrontSDK.SymbolField.Bid,
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
    width: 74,
    cellRenderer: (params: { valueFormatted: string, context: unknown }) => {
      return params.valueFormatted ?? '-';
    },
    cellClass: (params: CellClassParams) => {
      const defaultClassList = ['ag-right-aligned-cell'];
      if (params?.context?.tradingState?.isConnected && params?.data?.symbol?.isTradable) {
        defaultClassList.push('wt-grid--clickable');
      }
      return [symbolStatusColor(params, InfrontSDK.SymbolField.DisplaySymbolStatusCode), ...defaultClassList];
    },
  },
  listsAsk: {
    colId: 'listsAsk',
    headerName: 'Ask',
    field: InfrontSDK.SymbolField.Ask,
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
    width: 74,
    cellRenderer: (params: { valueFormatted: string }) => {
      return params.valueFormatted ?? '-';
    },
    cellClass: (params: CellClassParams) => {
      const defaultClassList = ['ag-right-aligned-cell'];
      if (params?.context?.tradingState?.isConnected && params?.data?.symbol?.isTradable) {
        defaultClassList.push('wt-grid--clickable');
      }
      return [symbolStatusColor(params, InfrontSDK.SymbolField.DisplaySymbolStatusCode), ...defaultClassList];
    },
  },
  listsVolume: {
    colId: 'listsVolume',
    headerName: 'Volume',
    field: InfrontSDK.SymbolField.AccumulatedVolume,

    type: 'rightAligned',
    cellRenderer: 'bigNumberCellComponent',
  },
  listsTurnover: {
    colId: 'listsTurnover',
    headerName: 'Turnover',
    field: InfrontSDK.SymbolField.Turnover,
    type: 'rightAligned',
    cellRenderer: 'bigNumberCellComponent',
  },
  listsOpen: {
    colId: 'listsOpen',
    headerName: 'Open',
    field: InfrontSDK.SymbolField.Open,
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
  },
  time: {
    colId: 'time',
    headerName: 'Time',
    field: InfrontSDK.SymbolField.PreDisplayTime,
    cellClass: (params: { value: Date }) => [
      ...timeOrDateOutdated({ value: params.value, isNotTodayClassList: ['grid__cell--outdated'] }),
      'ag-right-aligned-cell',
    ],
    // type used only for cell-header when using custom cellClass
    type: 'rightAligned',
    valueFormatter: 'timeOrDateFormatter',
    width: 76,
  },
  prvClose: {
    colId: 'prvClose',
    headerName: 'Prv Close',
    field: InfrontSDK.SymbolField.PreDisplayTime,
    type: 'rightAligned',
    valueFormatter: 'timeOrDateFormatter',
    width: 76,
  },
  shareCap: {
    colId: 'shareCap',
    headerName: 'Share Cap',
    field: InfrontSDK.SymbolField.ShareCap,
    type: 'rightAligned',
    cellRenderer: 'bigNumberCellComponent',
  },
  shareCapChange: {
    colId: 'shareCapChange',
    headerName: 'Share Cap %',
    field: InfrontSDK.SymbolField.ShareCapChange,
    type: 'rightAligned',
    cellRenderer: 'bigNumberCellComponent',
    cellClass: (params: { value: number }) => [...upDownColor(params), 'ag-right-aligned-cell'],
  },

  //lists static

  ISIN: {
    colId: 'ISIN',
    headerName: 'ISIN',
    field: InfrontSDK.SymbolField.ISIN,
  },
  listsCurrency: {
    colId: 'listsCurrency',
    headerName: 'Currency',
    field: InfrontSDK.SymbolField.Currency,
  },
  infrontSector: {
    colId: 'infrontSector',
    headerName: 'Sector',
    field: InfrontSDK.SymbolField.InfrontSectorName,
  },
  listsType: {
    colId: 'listsType',
    headerName: 'Type',
    field: InfrontSDK.SymbolField.SymbolType,
  },
  listsSubType: {
    colId: 'listsSubType',
    headerName: 'subType',
    field: InfrontSDK.SymbolField.SymbolSubType,
  },
  ESMAType: {
    colId: 'ESMAType',
    headerName: 'ESMA',
    field: InfrontSDK.SymbolField.ESMAType,
  },

  // lists Performance

  change1W: {
    colId: 'change1W',
    headerName: 'Change 1W',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChange1W,
    type: 'rightAligned',
    // sortable: false,
  },
  change1M: {
    colId: 'change1M',
    headerName: 'Change 1M',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChange1M,
    type: 'rightAligned',
  },
  change3M: {
    colId: 'change3M',
    headerName: 'Change 3M',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChange3M,
    type: 'rightAligned',
  },
  change6M: {
    colId: 'change6M',
    headerName: 'Change 6M',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChange6M,
    type: 'rightAligned',
  },
  changeYTD: {
    colId: 'changeYTD',
    headerName: 'Change YTD',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChangeYearToDate,
    type: 'rightAligned',
  },
  change1Y: {
    colId: 'change1Y',
    headerName: 'Change 1Y',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChange1Y,
    type: 'rightAligned',
  },
  change2Y: {
    colId: 'change2Y',
    headerName: 'Change 2Y',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChange2Y,
    type: 'rightAligned',
  },
  change3Y: {
    colId: 'change3Y',
    headerName: 'Change 3Y',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChange3Y,
    type: 'rightAligned',
  },
  change5Y: {
    colId: 'change5Y',
    headerName: 'Change 5Y',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreChange5Y,
    type: 'rightAligned',
  },

  pctChange1W: {
    colId: 'pctChange1W',
    headerName: '1W %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercent1W,
    type: 'rightAligned',
  },
  pctChange1M: {
    colId: 'pctChange1M',
    headerName: '1M %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercent1M,
    type: 'rightAligned',
  },
  pctChange3M: {
    colId: 'pctChange3M',
    headerName: '3M %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercent3M,
    type: 'rightAligned',
  },
  pctChange6M: {
    colId: 'pctChange6M',
    headerName: '6M %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercent6M,
    type: 'rightAligned',
  },
  pctChangeYTD: {
    colId: 'pctChangeYTD',
    headerName: 'YTD %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercentYearToDate,
    type: 'rightAligned',
  },
  pctChange1Y: {
    colId: 'pctChange1Y',
    headerName: '1Y %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercent1Y,
    type: 'rightAligned',
  },
  pctChange2Y: {
    colId: 'pctChange2Y',
    headerName: '2Y %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercent2Y,
    type: 'rightAligned',
  },
  pctChange3Y: {
    colId: 'pctChange3Y',
    headerName: '3Y %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercent3Y,
    type: 'rightAligned',
  },
  pctChange5Y: {
    colId: 'pctChange5Y',
    headerName: '5Y %',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreChangePercent5Y,
    type: 'rightAligned',
  },

  totalReturnChange1W: {
    colId: 'totalReturnChange1W',
    headerName: 'TR 1W', //'Total Return 1W %',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreTotalReturnChange1W,
    type: 'rightAligned',
  },
  totalReturnChange1M: {
    colId: 'totalReturnChange1M',
    headerName: 'TR 1M',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreTotalReturnChange1M,
    type: 'rightAligned',
  },
  totalReturnChange3M: {
    colId: 'totalReturnChange3M',
    headerName: 'TR 3M',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreTotalReturnChange3M,
    type: 'rightAligned',
  },
  totalReturnChange6M: {
    colId: 'totalReturnChange6M',
    headerName: 'TR 6M',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreTotalReturnChange6M,
    type: 'rightAligned',
  },
  totalReturnChangeYTD: {
    colId: 'totalReturnChangeYTD',
    headerName: 'TR YTD',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreTotalReturnChangeYTD,
    type: 'rightAligned',
  },
  totalReturnChange1Y: {
    colId: 'totalReturnChange1Y',
    headerName: 'TR 1Y',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreTotalReturnChange1Y,
    type: 'rightAligned',
  },
  totalReturnChange2Y: {
    colId: 'totalReturnChange2Y',
    headerName: 'TR 2Y',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreTotalReturnChange2Y,
    type: 'rightAligned',
  },
  totalReturnChange3Y: {
    colId: 'totalReturnChange3Y',
    headerName: 'TR 3Y',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreTotalReturnChange3Y,
    type: 'rightAligned',
  },
  totalReturnChange5Y: {
    colId: 'totalReturnChange5Y',
    headerName: 'TR 5Y',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimals',
    field: InfrontSDK.SymbolField.PreTotalReturnChange5Y,
    type: 'rightAligned',
  },
  totalReturnPctChange1W: {
    colId: 'totalReturnPctChange1W',
    headerName: 'TR 1W %',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePct1W,
    type: 'rightAligned',
  },
  totalReturnPctChange1M: {
    colId: 'totalReturnPctChange1M',
    headerName: 'TR 1M %',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePct1M,
    type: 'rightAligned',
  },
  totalReturnPctChange3M: {
    colId: 'totalReturnPctChange3M',
    headerName: 'TR 3M %',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePct3M,
    type: 'rightAligned',
  },
  totalReturnPctChange6M: {
    colId: 'totalReturnPctChange6M',
    headerName: 'TR 6M %',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePct6M,
    type: 'rightAligned',
  },
  totalReturnPctChangeYTD: {
    colId: 'totalReturnPctChangeYTD',
    headerName: 'TR YTD %',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePctYTD,
    type: 'rightAligned',
  },
  totalReturnPctChange1Y: {
    colId: 'totalReturnPctChange1Y',
    headerName: 'TR 1Y %',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePct1Y,
    type: 'rightAligned',
  },
  totalReturnPctChange2Y: {
    colId: 'totalReturnPctChange2Y',
    headerName: 'TR 2Y %',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePct2Y,
    type: 'rightAligned',
  },
  totalReturnPctChange3Y: {
    colId: 'totalReturnPctChange3Y',
    headerName: 'TR 3Y %',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePct3Y,
    type: 'rightAligned',
  },
  totalReturnPctChange5Y: {
    colId: 'totalReturnPctChange5Y',
    headerName: 'TR 5Y %',
    headerTooltip: 'Total return columns include dividends.',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreTotalReturnChangePct5Y,
    type: 'rightAligned',
  },
  listsSpread: {
    colId: 'listsSpread',
    headerName: 'Spread',
    headerTooltip: 'Formula: Ask - Bid',
    valueFormatter: 'sdkDecimalsPercent',
    field: InfrontSDK.SymbolField.PreSpread,
    type: 'rightAligned',
  },
  listsSpreadPct: {
    colId: 'listsSpreadPct',
    headerName: 'Spread %',
    headerTooltip: 'Formula: Ask - Bid',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreSpreadPct,
    type: 'rightAligned',
  },
  gap: {
    colId: 'gap',
    headerName: 'Gap',
    headerTooltip: 'Formula: Open - PrevClose',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.PreGap,
    type: 'rightAligned',
  },

  gapPct: {
    colId: 'gapPct',
    headerName: 'Gap %',
    headerTooltip: 'Formula: (Open – PrevClose) / PrevClose',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreGapPct,
    type: 'rightAligned',
  },
  offHighPct: {
    colId: 'offHighPct',
    headerName: 'Off High %',
    headerTooltip: 'Formula: (High – Last) / Last',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreOffHighPct,
    type: 'rightAligned',
  },
  offLowPct: {
    colId: 'offLowPct',
    headerName: 'Off Low %',
    headerTooltip: 'Formula: (Last – Low) / Last',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreOffLowPct,
    type: 'rightAligned',
  },

  range: {
    colId: 'range',
    headerName: 'Range',
    headerTooltip: 'Formula: High - Low',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.PreRange,
    type: 'rightAligned',
  },
  rangePct: {
    colId: 'rangePct',
    headerName: 'Range %',
    headerTooltip: '(High – Low) / Low',
    valueFormatter: 'twoDecimalsPercent',
    field: InfrontSDK.SymbolField.PreRangePct,
    type: 'rightAligned',
  },
  ATR30: {
    colId: 'ATR30',
    headerName: 'ATR30',
    headerTooltip: 'Average True Range 30 days',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.AverageTrueRange,
    type: 'rightAligned',
  },
  averageDailyVolume: {
    colId: 'averageDailyVolume',
    headerName: 'ADV',
    headerTooltip: 'ADV shows the average daily volume for the instrument for the last 30 days.',
    field: InfrontSDK.SymbolField.AverageDailyVolume,
    type: 'rightAligned',
  },
  averageDailyVolumeFactor: {
    colId: 'averageDailyVolumeFactor',
    headerName: 'ADV Factor',
    headerTooltip: 'ADV Factor compares the intraday volume today against the average daily volume.',
    field: InfrontSDK.SymbolField.AverageDailyVolumeFactor,
    type: 'rightAligned',
  },
  realTimeAverageDailyVolumeFactor: {
    colId: 'realTimeAverageDailyVolumeFactor',
    headerName: 'RT ADV Factor',
    headerTooltip:
      'Realtime ADV Factor compares the intraday volume today with the Realtime ADV. If over 1.0x it means there is higher volume than normal based on the last 30 days.',
    field: InfrontSDK.SymbolField.RealtimeAverageDailyVolumeFactor,
    type: 'rightAligned',
  },


  //Technicals
  movingAverage50: {
    colId: 'movingAverage50',
    headerName: 'MA 50',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.MovingAverage50,
    type: 'rightAligned',
  },
  movingAverage100: {
    colId: 'movingAverage100',
    headerName: 'MA 100',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.MovingAverage100,
    type: 'rightAligned',
  },
  movingAverage200: {
    colId: 'movingAverage200',
    headerName: 'MA 200',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.MovingAverage200,
    type: 'rightAligned',
  },
  rsi14: {
    colId: 'rsi14',
    headerName: 'RSI 14',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.RSI14,
    type: 'rightAligned',
  },
  VWAP30: {
    colId: 'VWAP30',
    headerName: 'VWAP30',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.VolumeWeightedAveragePrice30,
    type: 'rightAligned',
  },
  VWAP60: {
    colId: 'VWAP60',
    headerName: 'VWAP60',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.VolumeWeightedAveragePrice60,
    type: 'rightAligned',
  },
  VWAP90: {
    colId: 'VWAP90',
    headerName: 'VWAP90',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.VolumeWeightedAveragePrice90,
    type: 'rightAligned',
  },

  //Derivatives
  expireDate: {
    colId: 'expireDate',
    headerName: 'Expire Date',
    valueFormatter: 'dateFormatter',
    field: InfrontSDK.SymbolField.Expiry,
    type: 'rightAligned',
  },
  strikePrice: {
    colId: 'strikePrice',
    headerName: 'Strike Price',
    valueFormatter: 'sdkDecimals',
    field: InfrontSDK.SymbolField.StrikePrice,
    type: 'rightAligned',
  },

  // functional / special

  // "invisible" column required for adding subHeaders (via column definition)
  // must only be used once per table and always be first column!
  subHeaders: {
    colId: 'subHeaders',
    headerName: '',
    lockPosition: true,
    suppressMovable: true,
    suppressSizeToFit: true,
    suppressAutoSize: true,
    resizable: false,
    sortable: false,
    wrapText: false,
    maxWidth: 0,
    minWidth: 0,
    width: 0,
    valueGetter: (params: ValueGetterParams) => {
      if (isSubHeaderRow(params)) {
        return (params.data.translation ?? params.data.value ?? '') as string;
      }
      return '';
    },
    colSpan: (params) => {
      if (isSubHeaderRow(params)) {
        const colCount = params.api.getColumnDefs()?.length ?? 1;
        return colCount;
      }
      return 1;
    },
  },

  // debug columns

  internalId: { colId: 'internalId', headerName: 'internalId', field: 'internalId' },
  seq: { colId: 'seq', headerName: 'seq', field: 'sequenceNum' },

  // special columns
  dragHandle: {
    colId: 'dragHandle',
    headerName: '',
    width: 56,
    resizable: false,
    suppressAutoSize: true,
    suppressSizeToFit: true,
    lockPosition: true,
    suppressMovable: true,
    sortable: false,
  },
} as const;
