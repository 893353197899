import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, NEVER } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';
import { ConfirmDialogService } from './confirm-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {

  private readonly confirmDialogService: ConfirmDialogService = inject(ConfirmDialogService);
  private readonly inactivityPeriod = 10800; // 3 hours
  private userActivity$: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  private isPromptVisible = false;


  constructor() {
    this.setupActivityTracking();
  }

  private setupActivityTracking(): void {
    // listen for user activity events
    window.addEventListener('mousemove', () => this.userActivity$.next());
    window.addEventListener('keydown', () => this.userActivity$.next());

    this.userActivity$.pipe(debounceTime(this.inactivityPeriod * 1000),
      mergeMap(() => {
        if (this.isPromptVisible) {
          return NEVER;
        }
        this.isPromptVisible = true;
        return this.confirmDialogService.confirmDialog$(
          {
            header: 'GLOBAL.INACTIVITY_DIALOG.HEADER',
            message: 'GLOBAL.INACTIVITY_DIALOG.MESSAGE',
            icon: 'restart_alt',
            noCancelButton: true,
          });
      })).subscribe(() => {
        this.isPromptVisible = false;
        window.location.reload();
      });
  }
}



