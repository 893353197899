export type MatDropdownItem<T extends object> = MatDropdownSelectable<T> | MatDropdownFolder<T> | MatDropdownSelectableFolder<T>;

export type MatDropdownSelectable<T extends object> = T &
  ({
    type: MatDropdownItemType.SELECTABLE;
    itemLabel: string;
    translateItemLabel?: boolean;

    dropdownLabel?: string;
    translateDropdownLabel?: boolean;
    selectable?: boolean;
    selected?: boolean;
    checked?: MatDropdownFunction<T, boolean>;
    onClick?: MatDropdownFunction<T, void>;
    parentFolder?: MatDropdownFolder<T>;
  });

export type MatDropdownFolder<T extends object> = {
  type: MatDropdownItemType.FOLDER;
  itemLabel: string;
  translateItemLabel?: boolean;
  opened?: boolean;
  subItems?: MatDropdownItem<T>[];
  selectable?: boolean;
  selected?: boolean;
  onClick?: MatDropdownFunction<T, void>;
  parentFolder?: MatDropdownFolder<T>;
};

export type MatDropdownSelectableFolder<T extends object> = {
  type: MatDropdownItemType.SELECTABLE_FOLDER;
  itemLabel: string;
  translateItemLabel?: boolean;
  opened?: boolean;
  subItems?: MatDropdownItem<T>[];
  selectable?: boolean;
  selected?: boolean;
  checked?: MatDropdownFunction<T, boolean>;
  onClick?: MatDropdownFunction<T, void>;
  parentFolder?: MatDropdownFolder<T>;
};

export type MatDropdownColumn<T extends object> = {
  property: keyof T;
  label?: string;
  translateLabel?: boolean;
  title?: string;
  translateTitle?: boolean;
  size?: number;
  className?: string;
};

export type MatDropdownFunction<T extends object, RetType> = (item: MatDropdownItem<T>, element?: HTMLElement) => RetType;

export enum MatDropdownItemType {
  SELECTABLE = 1,
  SELECTABLE_FOLDER,
  FOLDER,
}
