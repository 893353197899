import { Injectable, inject } from '@angular/core';
import { MFEAuth } from '@vwd/microfrontend-core';
import { Observable, filter, firstValueFrom, map, shareReplay } from 'rxjs';
import { UserSettingsService } from '../../services/user-settings.service';
import { filterUndefined, mapToVoid } from '../../util/rxjs';
import { TenantSettingsService } from '../../services/tenant-settings.service';
import { injectRealmDisplayNameFactory } from '../../shared/realms';

export interface RealmInfo {
  realm: string;
  displayName$: Observable<string>;
  current: boolean;
  index: string; // needed for wt-grid
  canDelete: boolean;
}

@Injectable({ providedIn: 'root' })
export class DashboardRealmsService {
  private readonly getRealmDisplayName = injectRealmDisplayNameFactory();
  private userSettings = inject(UserSettingsService);
  private tenantSettings = inject(TenantSettingsService);
  private mfeAuth = inject(MFEAuth);

  canManageOtherRealms = this.tenantSettings.canEdit('*');

  readonly realms$: Observable<RealmInfo[]> = this.userSettings.getValue$('adminRealmsList').pipe(
    map((allowedRealms) => {
      const currentRealm = this.mfeAuth.realm!;
      const result: RealmInfo[] = [{
        realm: currentRealm,
        displayName$: this.getRealmDisplayName(currentRealm),
        current: true,
        index: currentRealm,
        canDelete: false,
      }];
      if (allowedRealms) {
        result.push(...allowedRealms
          .filter((realm) => realm !== currentRealm)
          .map((realm) => ({
            realm,
            displayName$: this.getRealmDisplayName(realm),
            current: false,
            index: realm,
            canDelete: true,
          }))
          // note that we don't sort by displayName (kinda tricky)
          .sort((x, y) => x.realm.localeCompare(y.realm))
        );
      }
      return result;
    }),
    shareReplay(1)
  );

  addRealm(realmName: string): Promise<RealmInfo> {
    const adminRealmsList = this.userSettings.getValue('adminRealmsList') ?? [];
    if (!adminRealmsList.includes(realmName)) {
      this.userSettings.setValue('adminRealmsList', [...adminRealmsList, realmName]);
    }
    return firstValueFrom(
      this.realms$.pipe(
        map((realms) => realms.find((info) => info.realm === realmName)),
        filterUndefined()
      )
    );
  }

  deleteRealm(realmName: string): Promise<void> {
    if (realmName === this.mfeAuth.realm) {
      return Promise.resolve();
    }

    const adminRealmsList = this.userSettings.getValue('adminRealmsList') ?? [];
    const index = adminRealmsList.indexOf(realmName);
    if (index !== -1) {
      const newList = [...adminRealmsList];
      newList.splice(index, 1);
      this.userSettings.setValue('adminRealmsList', newList);
      return firstValueFrom(
        this.realms$.pipe(
          filter((realms) => !realms.find((info) => info.realm === realmName)),
          mapToVoid(),
        )
      );
    }

    return Promise.resolve();
  }
}
