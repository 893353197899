import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchDataParams, SearchDataSource } from '../../../search.model';
import { InfrontSDK } from '@infront/sdk';
import { DefaultSymbolName, SearchItemSymbol, SymbolNameByClassification } from './search-item-symbol.model';

@Component({
  selector: 'wt-search-item-symbol',
  templateUrl: './search-item-symbol.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchItemSymbolComponent {
  @Input() searchItemSymbol: SearchItemSymbol;
  @Input() showFlag = true;
  @Input() hasTickerClickEvent = true;
  @Input() hasFeedClickEvent = true;
  @Input() isCompact: boolean;
  @Input() searchDataSource: SearchDataSource;

  @Output() tickerClick = new EventEmitter<SearchDataParams>();
  @Output() feedClick = new EventEmitter<SearchDataParams>();
  @Output() closeDropdown = new EventEmitter<void>();

  // Enums & Constants
  SearchDataSource = SearchDataSource;
  SymbolClassification = InfrontSDK.SymbolClassification;
  SymbolNameByClassification = SymbolNameByClassification;
  DefaultSymbolName = DefaultSymbolName;
}
