import { Observable, of } from 'rxjs';

/**
 * DI initializer function to create a function to translate realm names to their display name.
 *
 * Right now, it ensures title case for all-lowercase names. But it could also do translation
 * lookups (hence the inject-ready API: you can call `inject()` at the top level).
 */
export function injectRealmDisplayNameFactory(): (realm: string) => Observable<string> {
  return realm => {
    if (/^[a-z]+$/.test(realm)) {
      const recased: string = realm[0].toLocaleUpperCase() + realm.substring(1);
      return of(recased);
    }
    return of(realm);
  };
}
