import { CdkDragEnd, Point } from '@angular/cdk/drag-drop';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, ElementRef, OnDestroy, ViewChild, inject } from '@angular/core';
import { Infront, InfrontUtil } from '@infront/sdk';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, distinctUntilChanged, of } from 'rxjs';
import { map, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';


import { SearchBox, SearchBoxOptions } from '@infront/wtk/components/search';
import { WidgetPopupParent, WidgetState } from '@infront/wtk/src/InfrontUI';
import { AdvancedPaneMode, KidDefinition, OrderEntryManager, OrderEntryWidget, OrderEntryWidgetOptions } from '@infront/wtk/widgets/order-entry';
import { TradableService } from '../../services/tradable.service';
import { TradingOrderEntryService } from '../../services/trading-order-entry.service';
import { TradingService } from '../../services/trading.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { Instrument, isInstrument } from '../../state-model/window.model';
import { LOCALE_ID$ } from '../../util/locale';
import { instrumentsAreEqual } from '../../util/sdk';
import { WTKWidgetConstructorWithOptions } from '../../wrappers/wtk-widget-wrapper/wtk-widget-wrapper.model';
import {
  ORDER_ENTRY_WIDGET_AUTO_FILL_PRICE_DEFAULT,
  ORDER_ENTRY_WIDGET_CONFIRMATION_DEFAULT,
  ORDER_ENTRY_WIDGET_REMEMBER_VOLUME_DEFAULT
} from '../models/settings.model';
import { OrderEntryParams } from '../models/trading.model';
import { StoreService } from './../../services/store.service';

@Component({
  selector: 'wt-trading-order-entry',
  templateUrl: './trading-order-entry.component.html',
})
export class TradingOrderEntryComponent implements OnDestroy {
  private readonly userSettingsService: UserSettingsService = inject(UserSettingsService);
  private readonly tradingService: TradingService = inject(TradingService);
  private readonly tradingOrderEntryService: TradingOrderEntryService = inject(TradingOrderEntryService);
  private readonly tradableService: TradableService = inject(TradableService);
  private readonly storeService: StoreService = inject(StoreService);

  // reloadAction and localeId$ are used for reloading the order-entry widget and the orderbook-flyout on locale change!
  // They need their own reloadAction, as they are not part of dashboard!
  readonly reloadAction = new BehaviorSubject<boolean>(false);
  readonly localeId$ = inject(LOCALE_ID$).pipe(
    skip(1),
    tap((_locale: string) => {
      this.reloadAction.next(true);
      InfrontUtil.setZeroTimeout(() => this.reloadAction.next(false));
    }),
  );

  isFlyOutActive: boolean;
  readonly hasTradingFeature$: Observable<boolean> = this.tradingService.hasTradingFeature$;
  readonly connectionState$: Observable<{ name: string }> = this.tradingService.connectionState$;
  readonly tradingConnectedObj$: Observable<{ isConnected: boolean; }> = this.tradingService.tradingConnectedObj$;

  readonly showOrderEntry$ = this.tradingOrderEntryService.showOrderEntry$.pipe(
    tap((show) => !show && this.clearFlyout())
  );

  readonly orderEntryRef$ = this.tradingOrderEntryService.orderEntryRef$; // Not in use atm

  // Toolkit widget properties
  readonly orderEntryWidget$: Observable<WTKWidgetConstructorWithOptions<OrderEntryWidget, OrderEntryWidgetOptions>> = (
    this.tradingOrderEntryService.params$.pipe(
      distinctUntilChanged(), // don't reconstruct on subsequent undefined
      map((params) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const options = this.getWidgetOptions(params);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        return { widgetConstructor: OrderEntryWidget, options };
      }),
    )
  );

  // set up observer for resetting position of an overlay outside of viewport
  outsideObserver: IntersectionObserver;
  @ViewChild('orderEntryElement') set elementRef(element: ElementRef<HTMLElement>) {
    this.outsideObserver?.disconnect();
    if (this.tradingOrderEntryService.isOpen() && element) {
      this.outsideObserver = new IntersectionObserver(entries => {
        this.handleOverlayOutsideViewport(entries[0]);
        this.outsideObserver?.disconnect();
      }, {
        threshold: 0.75
      });

      this.outsideObserver.observe(element.nativeElement);
    }
  }

  // open to the right of the button
  readonly overlayPositions = [new ConnectionPositionPair(
    { originX: 'start', originY: 'bottom' },
    { overlayX: 'start', overlayY: 'top' },
    0,
    0,
  )];
  dragPosition = { x: 0, y: 0 } as Point;
  private readonly instrumentAction = new ReplaySubject<Instrument | undefined>(1);
  readonly instrument$ = this.instrumentAction.asObservable().pipe(
    distinctUntilChanged(instrumentsAreEqual),
  );

  readonly showTradingBuySellButton$ = this.storeService.dashboardInstrument$.pipe(
    switchMap((instrument) => instrument ? this.tradableService.isTradable$({ instrument }) : of(true)),
  );

  private readonly ngUnsubscribe = new Subject<void>();
  private readonly ngUnsubscribeWidgetRef = new Subject<void>();

  constructor() {
    const storedPosition = this.userSettingsService.getValue('orderEntryDragPosition');
    this.dragPosition = storedPosition ? { ...storedPosition } : this.dragPosition;

    this.instrument$.subscribe((instrument) => {
      this.isFlyOutActive = instrument ? true : false;
    });
  }

  ngOnDestroy(): void {
    this.outsideObserver?.disconnect();
    this.reloadAction.complete();
    this.instrumentAction.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribeWidgetRef.next();
    this.ngUnsubscribeWidgetRef.complete();
  }

  setOrderEntryRef(ref: OrderEntryWidget | undefined) {
    this.ngUnsubscribeWidgetRef.next();
    this.tradingOrderEntryService.setOrderEntryRef(ref);
  }

  // Temporary workaround for IWT-1241 & IWT-1248
  onSubscribeWidgetState(state: WidgetState) {
    if (state === WidgetState.Subscribed) {
      combineLatest([this.orderEntryRef$, this.tradingOrderEntryService.params$]).pipe(
        take(1),
        tap(([ref, params]) => {
          if (ref) {
            if ((ref['orderEntryManager'] as OrderEntryManager).isModifyMode()) {
              ref.modify(this.getWidgetOptions(params));
            }
            if (!ref['currentInstrument']) {
              ((ref['searchBox'] as SearchBox)?.['searchField'] as HTMLInputElement)?.focus();
            }
          }
        }),
        takeUntil(this.ngUnsubscribeWidgetRef),
        takeUntil(this.ngUnsubscribe),
      ).subscribe();
    }
  }

  toggleOverlay(): void {
    const wasOpen = this.tradingOrderEntryService.isOpen();
    this.tradingOrderEntryService.toggleOrderEntry();
    if (wasOpen) {
      this.clearFlyout();
    }
  }

  closeOverlay(): void {
    this.tradingOrderEntryService.closeOrderEntry();
  }

  cdkDragEnded(event: CdkDragEnd): void {
    const position = event?.source?.getFreeDragPosition();
    this.dragPosition.x = position?.x ?? this.dragPosition.x;
    this.dragPosition.y = position?.y ?? this.dragPosition.y;
    this.userSettingsService.setValue('orderEntryDragPosition', position);
  }

  private onInstrumentChanging = (instrument: Instrument | undefined): void => {
    this.instrumentAction.next(instrument);
  };

  private clearFlyout() {
    this.instrumentAction.next(undefined);
  }

  private readonly closeCallback = () => {
    this.closeOverlay();
  };

  // prevent overlay from being invisible due to position outside of current viewport
  // IWT-1327 solved by resetting position to 0,0 in such case!
  private readonly handleOverlayOutsideViewport = (entry: IntersectionObserverEntry): void => {
    if (!entry) {
      return;
    }

    // spare px to have visible part of header for dragging the modal back into the view
    const dragHandlePuffer = 30;

    // `isIntersecting` and `isVisible` of IntersectionObserverEntry are not working as required,
    // so we have to perform our own calculations!
    const isVerticalOutside = (
      // am I below the viewport's bottom?
      (entry.boundingClientRect.y + dragHandlePuffer > entry.rootBounds!.bottom)
      // am I above the viewport's top? (header must never be above top, so no puffer!)
      || (entry.boundingClientRect.y < entry.rootBounds!.top)
    );
    const isHorizontalOutside = (
      // am I outside of the viewport's right side?
      (entry.boundingClientRect.x + dragHandlePuffer > entry.rootBounds!.right)
      // am I outside of the viewport's left side?
      || (entry.boundingClientRect.x + entry.boundingClientRect.width - dragHandlePuffer < entry.rootBounds!.left)
    );
    const isVisibleInView = !isVerticalOutside && !isHorizontalOutside;

    if (!isVisibleInView) {
      this.dragPosition = { x: 0, y: 0 } as Point;
    }
  };

  private getWidgetOptions(params: OrderEntryParams | undefined): OrderEntryWidgetOptions {
    const instrument = params && isInstrument(params.instrument) ? new Infront.Instrument(params.instrument.feed, params.instrument.ticker) : undefined;
    const initialPrice = params && !isNaN(params.initialPrice as number) ? params.initialPrice : undefined;
    const initialVolume = params && !isNaN(params.initialVolume as number) ? params.initialVolume : undefined;
    const modifyOrderId = params && !isNaN(params.modifyOrderId as number) ? params.modifyOrderId : undefined;
    const modifyPortfolio = params && typeof params.modifyPortfolio === 'string' ? params.modifyPortfolio : undefined;

    const storedWidgetOptions = this.userSettingsService.getValue('orderEntryWidgetOptions');

    const options: Partial<OrderEntryWidgetOptions> = {
      // the following properties can be set via params to order-entry open and toggle.
      // Important: do not expose a undefined parameter, as "parameter: undefined" will confuse the toolkit widget!
      ...(instrument ? { instrument } : {}),
      ...(initialPrice ? { initialPrice } : {}),
      ...(initialVolume ? { initialVolume } : {}),
      ...(modifyOrderId ? { modifyOrderId } : {}),
      ...(modifyPortfolio ? { modifyPortfolio } : {}),
      // the following properties can be defined via user-settings (storage)
      confirmation: storedWidgetOptions?.confirmation ?? ORDER_ENTRY_WIDGET_CONFIRMATION_DEFAULT,
      autoFillPrice: storedWidgetOptions?.autoFillPrice ?? ORDER_ENTRY_WIDGET_AUTO_FILL_PRICE_DEFAULT,
      rememberVolume: storedWidgetOptions?.rememberVolume ?? ORDER_ENTRY_WIDGET_REMEMBER_VOLUME_DEFAULT,
      // the following properties are fix set by us!
      rememberCanceledLogin: false, // taken from WT4
      linkChannels: 11, // taken from WT4
      clickToLogin: true,
      advancedPaneMode: AdvancedPaneMode.toggle,
      keepInstrumentOnNew: false,
      searchBoxOptions: { fields: ['full_name', 'ticker', 'feed_code'] } as SearchBoxOptions,
      closeOnComplete: false,
      flexMode: false,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      popupContainer: WidgetPopupParent.FIRST_PARENT,
      onInstrumentChanging: this.onInstrumentChanging,
      closeCallback: this.closeCallback,

      // instrument: new Infront.Instrument(17921, 'AINO'), // for testing, if instrument search does not work
      // compactMode: true, // for testing
      // enableTickButtons: true, // TODO: taken from WT4, not sure if we want it
    };
    // TODO: next lines taken from WT4, not sure for what it is required?
    options.kidLinkDefinition = new KidDefinition();
    options.kidLinkDefinition.DisplayName = "KID&Cost";
    options.kidLinkDefinition.URL = '';

    return {
      ...new OrderEntryWidgetOptions(),
      ...options,
      // ... here we could merge in some user-setting from a storage!
    } as OrderEntryWidgetOptions;
  }

}
