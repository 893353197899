import { InfrontSDK } from '@infront/sdk';

export const OrderbookFields = [
  InfrontSDK.SymbolField.VWAP,
  InfrontSDK.SymbolField.AccumulatedVolume,
  InfrontSDK.SymbolField.PreLastTradedAt,
  InfrontSDK.SymbolField.PreDisplayTime,
  InfrontSDK.SymbolField.ChangePercent,
  InfrontSDK.SymbolField.Change,
  InfrontSDK.SymbolField.SymbolStatusText,
  InfrontSDK.SymbolField.SymbolStatus,
  InfrontSDK.SymbolField.DisplaySymbolStatus,
  InfrontSDK.SymbolField.DisplaySymbolStatusCode,
  InfrontSDK.SymbolField.PreSymbolStatusText,
];

export interface Level {
  askLevel?: InfrontSDK.OrderbookLevel;
  bidLevel?: InfrontSDK.OrderbookLevel;
}

export interface OrderbookUIData {
  levels: Level[];
  highestVolume: number;
  bidAskRatio: number;
  spread: number | undefined;
  decimals: number;
}
