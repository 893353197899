import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { LogService } from '@vwd/ngx-logging';
import { GridApi, GridOptions } from 'ag-grid-community';
import { Observable, Subject, combineLatest, fromEvent, of } from 'rxjs';
import { auditTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { Column } from '../../shared/grid/columns.model';
import { ProgressService } from '../../shared/progress';
import { NewsWidget } from '../../state-model/widget.model';
import { NewsType } from '../../state-model/window.model';
import { NewsChunkSize, NewsHeadline, Vm } from './news.model';
import { NewsService } from './news.service';
import { TradingService } from '../../services/trading.service';
import { StoreService } from '../../services/store.service';

@Component({
  selector: 'wt-news',
  templateUrl: './news.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NewsService],
})
export class NewsComponent implements OnInit, OnDestroy {
  private readonly newsService: NewsService = inject(NewsService);
  private readonly logService: LogService = inject(LogService);
  private readonly progress: ProgressService = inject(ProgressService);
  private readonly tradingService: TradingService = inject(TradingService);
  private readonly storeService: StoreService = inject(StoreService);
  private gridApi?: GridApi<unknown>;

  @ViewChild('textFilterInput', { static: true }) textFilterInput: ElementRef<HTMLInputElement>;
  private readonly onDestroy = new Subject<void>();
  @Input() widget: NewsWidget;
  @Input() isActive: boolean;
  vm$: Observable<Vm>;
  newsType$: Observable<NewsType>;
  columns$: Observable<Column[]>;
  readonly newsChunkSize = NewsChunkSize;

  gridOptions$: Observable<GridOptions>;
  gridOptions: GridOptions = {
    rowClassRules: {
      'cursor-pointer': (params) => {
        const hl = params.data as NewsHeadline;
        return !!(hl?.url || hl?.hasBody);
      },
      'cursor-not-allowed': (params) => {
        const hl = params.data as NewsHeadline;
        return !!hl?.isFlash;
      },
    },
    // overlayNoRowsTemplate: NoContentText[]
  };

  readonly tradingConnected$ = this.tradingService.tradingConnected$;

  private readonly logger = this.logService.openLogger('widget/news');
  private deselectedFeeds: number[] | undefined;

  ngOnInit(): void {
    this.newsService.resetScroll();
    this.columns$ = this.newsService.columns$(this.widget);
    this.newsType$ = this.newsService.newsType$(this.widget);
    this.gridOptions$ = this.newsType$.pipe(
      map((newsType) => {
        return { ...this.gridOptions, overlayNoRowsTemplate: `Please select ${newsType?.toLowerCase()} to populate the window` };
      })
    );

    const headlines$ = of(this.isActive).pipe(
      filter((isActive) => this.widget.lifespan !== 'window' || isActive),
      switchMap(() => this.newsService.filteredHeadlines$(this.widget, this.progress)),
    );

    this.storeService.widget$(this.widget).pipe(
      takeUntil(this.onDestroy),
    ).subscribe(widget => {
      this.deselectedFeeds = widget.settings.deselectedFeeds;
      this.setGridFilter();
    });

    this.vm$ = combineLatest([headlines$, this.columns$, this.newsType$, this.gridOptions$]).pipe(
      map(([headlines, columns, newsType, options]) => ({ headlines, columns, newsType, options })),
    );

    fromEvent<KeyboardEvent>(this.textFilterInput.nativeElement, 'keyup')
      .pipe(takeUntil(this.onDestroy), auditTime(600))
      .subscribe((event?: KeyboardEvent) => {
        const val = (event?.target as HTMLInputElement).value ?? '';
        this.newsService.textFilter(val, this.widget);
      });
  }

  ngOnDestroy(): void {
    this.gridApi = undefined;
    this.onDestroy.next();
  }

  openNewsStory(newsHeadline: NewsHeadline): void {
    this.newsService.openNewsStory(newsHeadline);
  }

  onShowMore(): void {
    this.newsService.showMore();
  }

  onClearTextFilter(): void {
    this.newsService.textFilter('', this.widget);
  }

  onShowNews(event: MatCheckboxChange): void {
    this.newsService.showNews(event.checked, this.widget);
  }
  onShowFlashNews(event: MatCheckboxChange): void {
    this.newsService.showFlashNews(event.checked, this.widget);
  }
  onShowResearchNews(event: MatCheckboxChange): void {
    this.newsService.showResearchNews(event.checked, this.widget);
  }

  onGridApi(gridApi: GridApi<unknown>): void {
    this.gridApi = gridApi;
    if (this.deselectedFeeds) {
      this.setGridFilter();
    }
  }

  private setGridFilter(): void {
    const { gridApi, deselectedFeeds } = this;
    if (gridApi) {
      // eslint-disable-next-line no-null/no-null, no-restricted-syntax
      void gridApi.setColumnFilterModel('source', deselectedFeeds ? { values: deselectedFeeds } : null).then(() => {
        gridApi.onFilterChanged();
      });
    }
  }
}
