<wt-mat-dropdown
  [matDropdownType]="MatDropdownType.MULTI"
  [showMultiSelectionAmount]="true"
  [translateMenuLabel]="true"
  [matIcon]="'flag'"
  [isSvgIcon]="true"
  [menuLabel]="'MAT_DROPDOWN.MENU_LABELS.COUNTRY_CALENDAR'"
  [menuClass]="'wt-mat-dropdown-country'"
  [useInputFilter]="true"
  [filterTarget]="'country'"
  [filterFlexSmallSize]="12"
  [items]="countryDropdownItems"
  (itemSelected)="selectCountryItem.emit($event)"></wt-mat-dropdown>