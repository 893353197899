import { Point } from '@angular/cdk/drag-drop';
import { Inject, Injectable } from '@angular/core';
import { MFE_PREFS_TOKEN, PreferencesMap, RootPreferencesMap } from '@vwd/microfrontend-core';

import type { Sidebar, WatchlistSelectedSubTabs } from '../dashboard/sidebar/sidebar-content/sidebar-content.model';
import type { SearchHistoryData } from '../search/search.model';
import type { Column } from '../shared/grid/columns.model';
import type { OrderEntryWidgetOptions } from '../shared/models/trading.model';
import type { FundSearchState } from '../shared/screener/screener.model';
import type { Instrument } from '../state-model/window.model';
import type { ColumnSetting } from '../state-model/grid.model';

export type UserRole = 'user' | 'subtenant' | 'tenant';

export type UserSettingsGridSettings = { [gridId: string]: ReadonlyArray<UserSettingsGridColumn> };

export type UserSettingsGridColumn = Pick<Column, 'colId' | 'sort' | 'width'>;

// best use flat properties, to be able to watch for individual changes per property/key
export type UserSettingsStorageData = {
  alertLogMarkAsViewed: string[];
  dashboardInstrumentHistory: Instrument[];
  instrumentDashboardId: string; // this is needed because it is regenerated on every refresh and we cant know if dashboardSelectedDashboardId was the deleted last instrumentDashboardId otherwise
  dashboardSelectedDashboardId: string;
  dashboardTabs?: readonly string[];
  gridSettings: UserSettingsGridSettings;
  searchHideSubmarkets?: boolean;

  // Sidebar
  sidebar: Sidebar;
  watchlistTabSelectedSubTabs: WatchlistSelectedSubTabs;
  tradingTabHideInactiveDeletedOrders: boolean;
  tradingTabShowOrderStackWidget: boolean;
  tradingTabShowPositionsWidget: boolean;

  instrumentDockItemList: (Instrument | undefined)[];
  tradingSelectedPortfolioId: string;

  // disable delete prompts
  disableWindowDeletePrompt: boolean;
  disableDashboardDeletePrompt: boolean;
  disableSinglePortfolioGridRestoreSettingsPrompt: boolean;
  disableAllPortfolioGridsRestoreSettingsPrompt: boolean;

  highlightUpDownChanges: boolean;
  enableGridOpenInstrumentDashboard: boolean;
  enableDashboardFolderEditing?: boolean;
  showAdminPanel?: boolean;
  adminRealmsList?: string[];
  dismissedPwaInstall?: boolean;

  // Order-entry
  orderEntryDragPosition: Point;
  orderEntryWidgetOptions: OrderEntryWidgetOptions;

  // Screener
  fundScreenerSearchState: FundSearchState;
};

// prompt keys are broken out so we can iterate over them when resetting all prompt settings
export const PromptKeys = ['disableWindowDeletePrompt', 'disableDashboardDeletePrompt', 'disableSinglePortfolioGridRestoreSettingsPrompt', 'disableAllPortfolioGridsRestoreSettingsPrompt', 'dismissedPwaInstall'] as const;

export const UserSettingsStorageKeys = [
  'alertLogMarkAsViewed',
  'dashboardInstrumentHistory',
  'dashboardTabs',
  'dashboardSelectedDashboardId',
  'gridSettings',
  'searchHideSubmarkets',

  ...PromptKeys,

  // Sidebar
  'sidebar',
  'watchlistTabSelectedSubTabs',
  'tradingTabHideInactiveDeletedOrders',
  'tradingTabShowOrderStackWidget',
  'tradingTabShowPositionsWidget',

  'instrumentDockItemList',
  'tradingSelectedPortfolioId',
  'instrumentDashboardId',
  'highlightUpDownChanges',
  'enableGridOpenInstrumentDashboard',

  'enableDashboardFolderEditing',
  'showAdminPanel',
  'adminRealmsList',
  'dismissedPwaInstall',

  // Order-entry
  'orderEntryDragPosition',
  'orderEntryWidgetOptions',

  // Screener
  'fundScreenerSearchState',
] as const;

export type UserSettingsStorageKey = typeof UserSettingsStorageKeys[number];

export interface SearchStorageData {
  history: SearchHistoryData;
}

export const searchStorageKeys = ['history'];

export interface BrandingData {
  email?: string;
  phone?: string;
  website?: string;
  titlePrefix?: string;
  supportPageTitle?: string;
  supportMenuTitle?: string;
}

export interface TenantSettingsStorageData {
  showInfrontDashboards?: boolean;
  defaultDashboards?: readonly string[];
  sidebarLists?: readonly SidebarListsStorageData[];
  branding?: BrandingData;

  // user defaults
  tradingTabHideInactiveDeletedOrders: boolean;
  highlightUpDownChanges: boolean;
  orderEntryWidgetOptions: OrderEntryWidgetOptions;
  enableGridOpenInstrumentDashboard: boolean;
}

export interface SidebarListsStorageData {
  id: string;
  name: { [language: string]: string; };
  symbols: readonly Instrument[];
  columns?: readonly ColumnSetting[];
}

export const TenantSettingsStorageKeys = [
  'showInfrontDashboards',
  'defaultDashboards',
  'sidebarLists',
  'tradingTabHideInactiveDeletedOrders',
  'highlightUpDownChanges',
  'orderEntryWidgetOptions',
  'enableGridOpenInstrumentDashboard',
  'branding'
] as readonly (keyof TenantSettingsStorageData)[];

@Injectable({ providedIn: 'root' })
export class StorageService {
  private storageObject: { [userRole: string]: { [scope: string]: PreferencesMap } } = {};

  constructor(@Inject(MFE_PREFS_TOKEN) private prefs: RootPreferencesMap) { }

  getUserSettingsStorage(userRole: UserRole = 'user'): PreferencesMap {
    return this.checkAvailabilityOfConfig(userRole, 'webtrader5:userSettings:');
  }

  getSearchStorage(userRole: UserRole = 'user'): PreferencesMap {
    return this.checkAvailabilityOfConfig(userRole, 'webtrader5:search:');
  }

  // If it is neccesary to use other features from the MFE preferences API
  getMainStorage(): PreferencesMap {
    return this.prefs;
  }

  // Returns the tenant-level settings map
  getTenantStorage(): PreferencesMap {
    return this.prefs.tenant;
  }

  // Add scope for userrole if no config is available
  private checkAvailabilityOfConfig(userRole: UserRole, scope: string): PreferencesMap {
    if (!this.storageObject[userRole]) {
      this.storageObject[userRole] = {};
    }
    if (!this.storageObject[userRole][scope]) {
      this.storageObject[userRole][scope] = (this.prefs[userRole] as PreferencesMap).scope(scope);
    }
    return this.storageObject[userRole][scope];
  }
}
