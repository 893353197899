import { inject, Injectable } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { neutralLocale } from '@vwd/ngx-i18n';
import { LogService } from '@vwd/ngx-logging';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { SdkRequestsService } from '../../services/sdk-requests.service';
import { TenantSettingsService } from '../../services/tenant-settings.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { ColumnRegistry } from '../../shared/grid/column-registry';
import { Column } from '../../shared/grid/columns.model';
import { SymbolDataItem } from '../../shared/models/symbol-data.model';
import { ColumnSetting, Grid } from '../../state-model/grid.model';
import { LOCALE_ID$ } from '../../util/locale';
import { addIndex } from '../../util/rxjs';
import { createUserSettingsGridRef } from '../../wrappers/grid-wrappers/grid-ref-user-settings';
import { GridRef } from '../../wrappers/grid-wrappers/gridref';
import { DefaultSidebarListColumns } from './sidebar-content/sidebar-content.defaults';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private readonly logger = inject(LogService).openLogger('services/sidebar');
  private readonly userSettingsService = inject(UserSettingsService);
  private readonly tenantSettingsService = inject(TenantSettingsService);
  private readonly localeId$ = inject(LOCALE_ID$);

  getGrids() {
    return combineLatest([
      this.tenantSettingsService.getValue$('sidebarLists'),
      this.localeId$,
    ]).pipe(
      map(([lists, localeId]) => lists?.map((list) => {

        const headerName = translate(list.name, localeId);
        const grid = {
          id: list.id,
          name: 'lists',
          settings: {
            selectedColumns: firstColumnIsName(list.columns?.length ? list.columns : DefaultSidebarListColumns, headerName),
          },
        } as Grid;

        const grid$ = of(grid);

        const gridRef = createUserSettingsGridRef(this.userSettingsService, grid);

        return { grid$, gridRef, list, headerName };
      }) ?? []),
      tap((grids) => {
        this.logger.log('Loaded sidebar grids', { grids });
      }),
    );
  }

  // FIXME: GridRefs not released (as `getGrids() does not re-emit, that should be okay?)
  grids$ = this.getGrids().pipe(
    switchMap((grids) => {
      const gridObservables: Observable<{ label: string; symbols: SymbolDataItem[]; grid: Grid, gridRef: GridRef }>[] = [];
      grids.forEach(({ list, gridRef, grid$, headerName }) => {
        const symbols$ = of(list.symbols as InfrontSDK.SymbolId[])
          .pipe(
            this.sdkRequestsService.symbolsFromIds({ gridRef, maintainOrder: true }),
            addIndex<SymbolDataItem>(),
            tap((symbols) => {
              this.logger.info(`Grid ${list.name.en}`, symbols.map(s => ({
                feed: s.get(InfrontSDK.SymbolField.Feed),
                ticker: s.get(InfrontSDK.SymbolField.Ticker),
              })));
            })
          );

        gridObservables.push(
          combineLatest([symbols$, grid$, of(translate(list.name, headerName))]).pipe(
            map(([symbols, grid, label]) => ({
              symbols,
              grid,
              label,
              gridRef,
            }))
          )
        );
      });
      return combineLatest([...gridObservables]).pipe(
        filter((res) => res.length === grids.length)
      );
    }),
  );

  constructor(
    private sdkRequestsService: SdkRequestsService,
  ) { }
}

function firstColumnIsName(columns: readonly ColumnSetting[], headerName: string): Column[] {
  return columns.map((col, index) => index === 0
    ? { ...ColumnRegistry[col.colId], ...col, headerName, maxWidth: undefined }
    : { ...ColumnRegistry[col.colId], ...col }
  );
}

function translate(name: { [language: string]: string; }, localeId: string) {
  return name[localeId] ?? name[neutralLocale(localeId)] ?? name.en;
}
