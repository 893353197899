<mat-icon class="wt-dashboard-tabs__item-context-icon" (click)="$event.stopPropagation()"
  [matMenuTriggerFor]="panel.menu"
  fontIcon="{{ history ? 'arrow_drop_down' : 'more_vert' }}"
  data-testid="dashboard-context-menu-icon">
</mat-icon>

<wt-menu-panel
  #panel
  [items]="(menuItems$ | async) || undefined"
  [context]="{dashboard}"
  [overlayPanelClass]="history ? 'wt-menu-panel--small' : ''"
  (itemClick)="onItemClick($event)">
</wt-menu-panel>