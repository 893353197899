<form class="wt-dialog" method="dialog" [formGroup]="addRealmFormGroup" #addRealmForm="ngForm">
  <div class="wt-flex-row wt-flex-align-middle wt-flex-collapse wt-dialog__title">
    <div class="wt-flex-column wt-flex-shrink wt-dialog-icon">
      <mat-icon fontIcon="{{ data.icon }}"></mat-icon>
    </div>

    <div class="wt-flex-column">
      <h1 class="wt-h1">{{ data.title | translate }}</h1>
    </div>
  </div>

  <div mat-dialog-content>
    <mat-form-field floatLabel="never">
      <input class="wt-dialog-input" type="text" matInput [(ngModel)]="data.input" formControlName="name" name="name"
        [maxlength]="maxRealmNameLength" [minlength]="minRealmNameLength" [placeholder]="'Add Realm'" required />

      <mat-error *ngIf="addRealmName.errors?.required">
        {{'ADMIN_DIALOG.REALMS_LIST.NAME_REQUIRED' | translate }}
      </mat-error>

      <mat-error *ngIf="addRealmName.errors?.maxlength">
        {{ 'ADMIN_DIALOG.REALMS_LIST.NAME_TOO_LONG' | translate: { maxLength:
        addRealmName.errors?.maxlength.requiredLength } }}
      </mat-error>

      <mat-error *ngIf="addRealmName.errors?.forbiddenName">
        {{ 'ADMIN_DIALOG.REALMS_LIST.NAME_CONTAINS_INVALID_CHARS' | translate }}
      </mat-error>
    </mat-form-field>
  </div>

  <div class="wt-action-buttons">
    <div class="wt-flex-row wt-flex-collapse wt-flex-align-right">
      <div class="wt-flex-column wt-flex-shrink">
        <button class="wt-button wt-button--cancel" type="button" (click)="onCancel()">
          {{ data.closeLabel | translate }}
        </button>
      </div>

      <div class="wt-flex-column wt-flex-shrink">
        <button class="wt-button wt-button--primary" type="submit" [mat-dialog-close]="data.input"
          [disabled]="addRealmName.errors">
          {{ data.submitLabel | translate }}
        </button>
      </div>
    </div>
  </div>
</form>