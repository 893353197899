import type { DashboardWindow } from '../../state-model/window.model';
import type { Widget } from '../../state-model/widget.model';
import type { Grid } from '../../state-model/grid.model';

export function validateTemplate(label: string, windows: readonly DashboardWindow[], widgets: readonly Widget[], grids?: readonly Grid[]): void {
  const windowIds = new Set<string>();

  for (const window of windows) {
    if (windowIds.has(window.id)) {
      throw new Error(`[${label}] Duplicate window ID ${window.id}.`);
    }
    windowIds.add(window.id);
  }

  const widgetIds = new Set<string>();
  const usedWindowIds = new Set<string>();

  for (const widget of widgets) {
    if (widgetIds.has(widget.id)) {
      throw new Error(`[${label}] Duplicate widget ID ${widget.id}.`);
    }
    widgetIds.add(widget.id);
    if (!windowIds.has(widget.windowId)) {
      throw new Error(`[${label}] Widget ID ${widget.id} references unknown windowId ${widget.windowId}.`);
    }
    usedWindowIds.add(widget.windowId);
  }

  for (const windowId of windowIds) {
    if (!usedWindowIds.has(windowId)) {
      throw new Error(`[${label}] Window ID ${windowId} was never referenced.`);
    }
  }

  if (grids) {
    const gridIds = new Set<string>();
    for (const grid of grids) {
      if (gridIds.has(grid.id)) {
        throw new Error(`[${label}] Duplicate grid ID ${grid.id}.`);
      }
      gridIds.add(grid.id);
      if (!grid.parentId) {
        throw new Error(`[${label}] Grid ID ${grid.id} does not have a parentId.`);
      } else if (!widgetIds.has(grid.parentId)) {
        throw new Error(`[${label}] Grid ID ${grid.id} references unknown parentId ${grid.parentId}.`);
      }
    }
  }
}
