import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { FeedListMenuComponent } from './feed-list-menu.component';

@NgModule({
  declarations: [FeedListMenuComponent],
  imports: [CommonModule, MatButtonModule, MatMenuModule, MatIconModule],
  exports: [FeedListMenuComponent],
})
export class FeedListMenuModule {}
