import { inject, Injectable } from '@angular/core';
import {
  allModelChanges,
  DashboardAttributes,
  DashboardFolderLevel,
  DashboardFolderRef,
  DashboardModelCreate,
  DashboardModelUpdate,
  DashboardNodeRef,
  DashboardRef,
  DashboardService as FxDashboardService,
  DashboardType as FxDashboardType,
  isDashboardNodeRef,
  KnownDashboardFolderIDs,
  untilChildrenLoaded,
  WidgetDataService,
  WidgetStructureModel
} from '@infront/ngx-dashboards-fx';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { LogService } from '@vwd/ngx-logging';
import { BehaviorSubject, combineLatest, defer, EMPTY, forkJoin, merge, Observable, of, throwError } from 'rxjs';
import { catchError, combineLatestWith, filter, finalize, map, mergeMap, shareReplay, startWith, switchMap, take, takeUntil, tap, toArray, withLatestFrom } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { DASHBOARD_TABS_FOLDER_ID, DashboardTabsService } from '../dashboard/providers/dashboard-tabs.provider';
import { INSTRUMENT_DASHBOARD_FOLDER_ID } from '../dashboard/providers/instrument-dashboard-template';
import { PORTFOLIO_FOLDER_ID } from '../dashboard/providers/portfolio-dashboards';
import { Dashboard, DashboardType } from '../state-model/dashboard.model';
import { Grid } from '../state-model/grid.model';
import { Widget } from '../state-model/widget.model';
import { DashboardWindow, Instrument, isInstrument } from '../state-model/window.model';
import { convertFromWidgetModels, convertToWidgetStructureModel } from '../util/dashboard-framework';
import { structuresAreEqual } from '../util/equality';
import { filterUndefined } from '../util/rxjs';
import { parseWtkWidgetId } from '../util/wtk';
import { NotificationService } from './notification.service';
import { State, StateService } from './state.service';
import { ToolkitStorage, ToolkitStorageData } from './toolkit-storage.component';
import { UserSettingsService } from './user-settings.service';

@Injectable({
  providedIn: 'root',
})
export class RemoteStorageService {
  private readonly logService: LogService = inject(LogService);
  private readonly fxDashboardService: FxDashboardService = inject(FxDashboardService);
  private readonly widgetDataService: WidgetDataService = inject(WidgetDataService);
  private readonly dashboardTabsService: DashboardTabsService = inject(DashboardTabsService);
  private readonly userSettings: UserSettingsService = inject(UserSettingsService);
  private readonly notificationService: NotificationService = inject(NotificationService);
  private readonly stateService: StateService = inject(StateService);

  private readonly logger = this.logService.openLogger('services/remote-storage');
  private readonly modelLogger = this.logService.openLogger('widgets/models');
  private userDashboardsFolderRef: DashboardFolderRef | undefined;
  private instrumentFolderRef: DashboardFolderRef | undefined;
  private dashboards$: Observable<Dashboard[]> | undefined;
  private fxDashboards$: Observable<readonly DashboardRef[]> | undefined;
  private dashboardsData$: Observable<Partial<State>> | undefined;
  private readonly saveLock = new BehaviorSubject(0);
  private readonly destroySubject = new LastValueSubject<void>();
  // when we create a prefilled dashboard, we do not want to trigger loading the widgets from the back-end again
  private readonly preloadedWidgetState = new Map<string, WidgetStructureModel>();

  private readonly canAddDashboardAction = new BehaviorSubject<{ enabled: boolean }>({ enabled: true });
  readonly canAddDashboard$ = this.canAddDashboardAction.asObservable();
  private readonly sharedDashboardEditingEnabled$ = this.userSettings.getValue$('enableDashboardFolderEditing').pipe(map(value => !!value));

  runLocked<T>(obs: () => Observable<T>): Observable<T> {
    this.logger.debug('runLocked (open)');
    this.saveLock.next(this.saveLock.value + 1);
    try {
      return obs().pipe(
        finalize(() => {
          if (this.saveLock.value <= 0) {
            this.logger.error('runLocked disposing save lock without owning a lock.');
            return;
          }
          this.logger.debug('runLocked (release/finalize)');
          this.saveLock.next(this.saveLock.value - 1);
        }),
      );
    } catch (e) {
      this.logger.debug('runLocked (release/error)', e);
      // release lock in emergency case
      this.saveLock.next(this.saveLock.value - 1);
      throw e;
    }
  }

  // Toolkit storage handlers
  updateToolkitStorage = (data: ToolkitStorageData): void => {
    this.toolkitStorageService?.updateStorage(data ?? {});
  };

  private toolkitRemoteSnapshot: ToolkitStorageData;
  private saveToolkitStorage = (data: ToolkitStorageData): void => {

    let shouldTriggerState = false;
    const widgets = this.stateService.state?.widgets?.slice() ?? [];

    Object.keys(data).forEach((key) => {
      const parsedWidgetId = parseWtkWidgetId(key);
      if (!parsedWidgetId) {
        return;
      }
      const { dashboardId, widgetId, wtkWidgetId } = parsedWidgetId;

      const widgetForKeyIndex = widgets.findIndex((w) => w.dashboardId === dashboardId && w.id === widgetId);
      const widgetForKey = widgetForKeyIndex >= 0 ? widgets[widgetForKeyIndex] : undefined;

      // cleanup orphaned wtk-settings for key
      if (!widgetForKey) {
        this.logger.debug('saveToolkitStorage: Delete toolkit storage, no widget for key', key, this.toolkitRemoteSnapshot[key], data[key]);
        delete this.toolkitRemoteSnapshot[key];
        return;
      }

      // cleanup empty wtk-settings for key
      if (Object.keys(data[key]).length === 0) {
        if (this.toolkitRemoteSnapshot?.[key] && Object.keys(this.toolkitRemoteSnapshot[key]).length > 0) {
          this.logger.debug('saveToolkitStorage: Delete toolkit storage', key, this.toolkitRemoteSnapshot[key], data[key]);
          delete this.toolkitRemoteSnapshot[key];

          const newWidget = { ...widgetForKey };
          delete newWidget.toolkitSettings;

          widgets[widgetForKeyIndex] = newWidget;
          shouldTriggerState = true;

          return;
        }
        this.logger.debug('saveToolkitStorage: No data to store for toolkit storage', key, data[key]);
        return;
      }

      // update changed wtk-settings for key
      if (!structuresAreEqual(this.toolkitRemoteSnapshot[key], data[key])) {
        this.logger.debug('saveToolkitStorage: Update toolkit storage', key, this.toolkitRemoteSnapshot[key], data[key]);
        this.toolkitRemoteSnapshot[key] = InfrontUtil.deepCopy(data[key]) as ToolkitStorageData;

        const newWidget: Widget = {
          ...widgetForKey,
          toolkitSettings: {
            ...widgetForKey.settings,
            [wtkWidgetId]: InfrontUtil.deepCopy(data[key]) as ToolkitStorageData
          },
        };

        widgets[widgetForKeyIndex] = newWidget;
        shouldTriggerState = true;
        return;
      }
      // no changes in wtk-settings for key
      this.logger.debug('saveToolkitStorage: No change toolkit storage', key, this.toolkitRemoteSnapshot[key], data[key]);
    });

    if (shouldTriggerState) {
      this.stateService.setState({ widgets }, 'Trigger');
    }
  };

  private widgetFoundInState = (toolkitWidgetId: string): boolean => {
    const parsedWidgetId = parseWtkWidgetId(toolkitWidgetId);
    if (!parsedWidgetId) {
      return false;
    }
    const { dashboardId, widgetId } = parsedWidgetId;
    return this.stateService.state.widgets.some((w) => w.dashboardId === dashboardId && w.id === widgetId);
  };

  private toolkitStorageService?: ToolkitStorage;

  toolkitStorage$: Observable<ToolkitStorage> = this.getDashboardsData().pipe(
    startWith({} as Partial<State>),
    map((dashboardsData) => {
      this.logger.debug('Data loaded from toolkit storage', dashboardsData);
      const storageData = dashboardsData.widgets?.reduce((acc, data) => {
        if (!data.toolkitSettings) {
          return acc;
        }
        for (const [k, v] of Object.entries(data.toolkitSettings)) {
          // backcompat for widgets already using widgetId~wtkWidgetName
          // (widgetId is redundant - we know which widget we are)
          if (k.includes('~')) {
            acc[`${data.dashboardId}~${k}`] = v;
          } else {
            acc[`${data.dashboardId}~${data.id}~${k}`] = v;
          }
        }
        return acc;
      }, {} as ToolkitStorageData);
      // Snapshot must be de-coupled with deepCopy!
      this.toolkitRemoteSnapshot = InfrontUtil.deepCopy(storageData ?? {}) as ToolkitStorageData;
      this.logger.debug('Creating ToolkitStorage', this.toolkitRemoteSnapshot);
      if (!this.toolkitStorageService) {
        this.toolkitStorageService = new ToolkitStorage({ ...storageData }, this.saveToolkitStorage, this.widgetFoundInState);
      } else {
        this.toolkitStorageService.updateStorage(storageData ?? {});
      }
      return this.toolkitStorageService;
    })
  );

  // Dashboards
  getFxDashboards(): Observable<readonly DashboardRef[]> {
    this.fxDashboards$ ??= combineLatest([
      DASHBOARD_TABS_FOLDER_ID,
      KnownDashboardFolderIDs.PERSONAL,
      INSTRUMENT_DASHBOARD_FOLDER_ID,
      PORTFOLIO_FOLDER_ID,
    ].map((id) => this.fxDashboardService.getFolder(id).pipe(untilChildrenLoaded()))
    ).pipe(
      tap((x) => this.logger.debug('getFxDashboards (received)', { dashboards: x, childrenLoadState: x.map(m => m.childrenLoadState) })),
      take(1),
      // remember the folderRef for later addDashboard() actions
      tap(([tabsFolderRef, userFolderRef, instrumentFolderRef, portfolioFolderRef]) => {
        this.userDashboardsFolderRef = userFolderRef;
        this.instrumentFolderRef = instrumentFolderRef;
      }),
      // stream all childNodes aka Dashboards (and Dashboard-folders)
      switchMap(([tabsFolderRef, userFolderRef, instrumentFolderRef, portfolioFolderRef]) =>
        combineLatest([tabsFolderRef.childNodes$, instrumentFolderRef.childNodes$, portfolioFolderRef.childNodes$]),
      ),
      map((dashboards) => dashboards.flat()),
      catchError(this.handleError('getFxDashboards', [] as DashboardNodeRef[])),
      tap((x) => this.logger.debug('getFxDashboards', x)),
      takeUntil(this.destroySubject),
      shareReplay(1),
    );
    return this.fxDashboards$;
  }

  getDashboards(): Observable<Dashboard[]> {
    this.dashboards$ ??= this.getFxDashboards().pipe(
      tap((x) => this.logger.debug('getDashboards - received provider dashboards', x)),
      allModelChanges(),
      tap((x) => this.logger.debug('getDashboards - received model updates', x)),
      combineLatestWith(this.sharedDashboardEditingEnabled$),
      tap(([dashboards, sharedDashboardEditingEnabled]) => this.logger.debug('getDashboards', { dashboards, sharedDashboardEditingEnabled })),
      // map the fxDashboardService Dashboards to WT5 Dashboards
      map(([dashboardRefs, sharedDashboardEditingEnabled]) => {
        const dashboards = dashboardRefs.map((ref, index) => {
          const instrument = ref.model.attributes?.instrument;
          const readonly =
            // unless the admin edit mode is turned on, global/tenant dashboards are read-only
            !sharedDashboardEditingEnabled && ref.model.level !== DashboardFolderLevel.PERSONAL
            // otherwise, dashboards are editable if you have the rights
            || !ref.model.security.canEdit || isDashboardNodeRef(ref) && !ref.model.security.canEditWidgets;
          return {
            id: ref.model.id,
            name: ref.model.name,
            index: index, // ref.model.index as number, // @FIXME optional prop, but mapped to type that requires prop
            type: (ref.model.attributes?.wtDashboardType as DashboardType) ?? DashboardType.dashboard,
            level: ref.model.level,
            parentId: ref.model.parentId,
            hidden: !!ref.model.hidden,
            instrument: isInstrument(instrument) ? instrument : undefined,
            locked: readonly,
            readonly: readonly,
            canClose: !(ref.model.parentId === PORTFOLIO_FOLDER_ID || ref.model.parentId === INSTRUMENT_DASHBOARD_FOLDER_ID),
            canDelete: ref.model.security.canDelete,
            canRename: ref.model.security.canEdit,
          } as Dashboard;
        });
        // sort dashboards by their index
        (dashboards ?? []).sort((a, b) => a.index - b.index);
        return dashboards;
      }),
      tap((x) => this.logger.debug('getDashboards - remote dashboards', x)),
      takeUntil(this.destroySubject),
      shareReplay(1),
    );
    return this.dashboards$;
  }

  addDashboard(dashboard: Dashboard, saveDashboardData = false, state?: State): Observable<DashboardRef> {
    this.logger.debug('addDashboard', { dashboard, saveDashboardData, state });
    if (!this.validateDashboard(dashboard)) return EMPTY;

    if (this.userDashboardsFolderRef) {
      this.canAddDashboardAction.next({ enabled: false });
      const data: DashboardModelCreate = {
        // id can be set by application since WAF-89, DASH-80!
        id: dashboard.id,
        hidden: !!dashboard.hidden,
        name: dashboard.name,
        attributes: {
          // HACK: wtDashboardType required to store WT5 dashboard types!
          wtDashboardType: dashboard.type,
        },
      };

      const parent = dashboard.parentId
        ? this.fxDashboardService.getFolderRef(dashboard.parentId)!
        : this.userDashboardsFolderRef;

      return this.runLocked(() =>
        parent.createChild(data).pipe(
          mergeMap(ref => {
            this.dashboardTabsService.addTab(ref.model.id);
            if (!saveDashboardData) {
              return of(ref);
            }
            return this.saveDashboardData(dashboard, state!, /* isPreload */ true).pipe(map(() => ref));
          }),
          tap((ref: DashboardNodeRef) => {
            this.canAddDashboardAction.next({ enabled: true });
            const newDashboardId = ref.model.id;
            if (newDashboardId !== dashboard.id) {
              this.logger.error('addDashboard: could not get correct ID of new dashboard!', ref, newDashboardId, dashboard.id);
            }
          }),
          catchError((error: Error) => {
            this.logger.error('addDashboard: could not add dashboard', dashboard);
            this.canAddDashboardAction.next({ enabled: true });
            return throwError(() => error);
          }),
        )
      );
    }
    this.logger.error('addDashboard: no folderRef!');
    return EMPTY;
  }

  addInstrumentDashboard(instrument: Instrument, classification?: InfrontSDK.SymbolClassification): Observable<DashboardRef> {
    this.logger.debug('addInstrumentDashboard', { instrument, classification });
    if (!this.instrumentFolderRef) {
      this.logger.error('addInstrumentDashboard: no folderRef!');
      return EMPTY;
    }

    return this.instrumentFolderRef.createChild({
      name: instrument.ticker,
      attributes: {
        instrument,
        classification,
      } as unknown as DashboardAttributes,
    });
  }

  updateDashboard(dashboard: Dashboard): Observable<DashboardRef> {
    this.logger.debug('updateDashboard', dashboard);
    if (!this.validateDashboard(dashboard)) return EMPTY;

    return this.fxDashboardService.get(dashboard.id).pipe(
      take(1),
      tap((ref: DashboardNodeRef) => {
        if (!(
          ref.model.name !== dashboard.name
          || ref.model.hidden !== !!dashboard.hidden
          || ref.model.attributes.wtDashboardType !== dashboard.type
        )) {
          // nothing to update
          return;
        }
        // TODO: if in the future there are more attributes, store attributes or ext. model and use spread!
        const data: DashboardModelUpdate = {
          name: dashboard.name,
          hidden: !!dashboard.hidden,
          attributes: {
            wtDashboardType: dashboard.type, // FIXME: this hack is required to store WT5 dashboard types!
          },
          // parentId update only required, if we can move dashboards/folders between folders!
          // parentId: dashboard.parentId,
        };

        ref?.update(data);
      })
    );
  }

  updateDashboards(dashboards: readonly Dashboard[]): Observable<readonly DashboardRef[]> {
    this.dashboardTabsService.setTabs(dashboards
      .filter((dashboard) => dashboard.parentId !== PORTFOLIO_FOLDER_ID && dashboard.parentId !== INSTRUMENT_DASHBOARD_FOLDER_ID)
      .map((dashboard) => dashboard.id)
    );
    return this.runLocked(() =>
      merge(
        // Create deferred update observables, so we can schedule them (FX observables are generally hot)
        ...dashboards.map(dashboard => defer(() => this.updateDashboard(dashboard))),
        5 // ... buf max. 5 dashboard patch calls at a time
      ).pipe(
        toArray(),
      ),
    );
  }

  deleteDashboard(dashboard: Dashboard, force = false): Observable<void> {
    this.logger.debug('deleteDashboard', dashboard);
    if (!force && !this.validateDashboard(dashboard)) return EMPTY;

    this.dashboardTabsService.removeTab(dashboard.id);

    return this.fxDashboardService.get(dashboard.id).pipe(
      take(1),
      mergeMap((ref: DashboardRef) => {
        return ref?.delete();
      })
    );
  }

  resetDashboards(): Observable<void> {
    this.logger.warn('Resetting dashboards to defaults');

    this.destroySubject.next(); // resetting stops the existing observables, so you need to reload

    return this.dashboardTabsService.setTabs([]);
  }

  // Dashboards Data = WT5 Windows, Widgets, Grids
  getDashboardsData(): Observable<Partial<State>> {
    this.dashboardsData$ ??= this.getFxDashboards().pipe(
      withLatestFrom(
        // Keep track of the loading state of widgets.
        defer(() => of(new Map<string, 'loaded' | 'loading'>())),
      ),
      // Lock for overlapping load & save
      switchMap((pipedData) => this.saveLock.pipe(
        tap((lockDepth) => {
          if (lockDepth !== 0) {
            this.logger.log('getDashboardsData (waiting for lock)', { lockDepth });
          }
        }),
        // Wait until the save lock is released
        filter((lockDepth) => lockDepth === 0),
        take(1),
        map(() => pipedData),
      )),
      tap(([dashboardRefs, fetchStates]) => this.logger.log('getDashboardsData (trigger)', { dashboardRefs, fetchStates })),
    ).pipe(
      map(([dashboardRefs, fetchStates]) => {
        // Given the list of dashboards we need to load,
        // check if they are already loaded (don't want to reload,
        // as that can wipe local state), still loading,
        // or we're requesting them for the first time.
        // This informs us what we need to do with a dashboard ID.
        const actions = new Map<string, 'keep' | 'remove' | 'load'>();
        // Quick check to see if we actually need to do anything.
        let hasMutationActions = false;

        for (const { model } of dashboardRefs) {
          if (fetchStates.has(model.id)) {
            // when already fetching/fetched - keep
            actions.set(model.id, 'keep');
          } else {
            // otherwise, mark as fetching signal a load action
            fetchStates.set(model.id, 'loading');
            actions.set(model.id, 'load');
            hasMutationActions = true;
          }
        }

        // Purge already loaded dashboards that we no longer want/need
        for (const id of fetchStates.keys()) {
          if (!actions.has(id)) {
            fetchStates.delete(id);
            actions.set(id, 'remove');
            hasMutationActions = true;
          }
        }

        return { dashboardRefs, actions, fetchStates, hasMutationActions };
      }),
      tap(({ dashboardRefs, actions, fetchStates, hasMutationActions }) => this.logger.debug(`getDashboardsData (load)`, { actions, hasMutationActions, dashboardRefs, fetchStates })),
      filter(({ hasMutationActions }) => hasMutationActions),
      mergeMap(({ dashboardRefs, actions, fetchStates }) => {
        // Handle all dashboards we want to load.
        const obsList = dashboardRefs
          .filter(ref => actions.get(ref.model.id) === 'load')
          .map(ref => {
            const preload = this.preloadedWidgetState.get(ref.model.id);
            if (preload) {
              this.logger.log(`Using preloaded widgets for ${ref.model.id}.`);
              this.preloadedWidgetState.delete(ref.model.id);
            }

            const loadObs = preload ? of(preload) : this.widgetDataService.loadByDashboard(ref);

            return loadObs.pipe(
              catchError(error => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                this.logger.fail(`Could not load widgets for ${ref.model.id}.`, { error });
                return of(undefined);
              }),
              map(data => {
                this.logger.debug(`getDashboardsData - loaded`, { dashboard: ref.model, data });

                // Although we try not to load a dashboard in parallel,
                // this is theoretically possible (add/remove/add).
                //
                // Also, you can remove a dashboard before its even loaded.
                //
                // So, if the current fetch state is still 'loading',
                // return the data and mark the dashboard as loaded.
                // Otherwise, update the action to 'keep' and therefore
                // don't touch it.

                if (fetchStates.get(ref.model.id) === 'loading') {
                  this.logger.debug(`getDashboardsData - marking "loading" dashboard as "loaded"`, { dashboard: ref.model });
                  fetchStates.set(ref.model.id, 'loaded');
                  return data?.widgets;
                } else {
                  this.logger.debug(`getDashboardsData - marking "${String(fetchStates.get(ref.model.id))}" dashboard as "keep"`, { dashboard: ref.model });
                  actions.set(ref.model.id, 'keep');
                  return [];
                }
              }),
              // Can't return undefined and filterUndefined() because
              // that will kill the forkJoin.
              map((data) => ({ dashboardId: ref.model.id, widgetModels: data ?? [] })),
            );
          });

        const updateObs = obsList.length ? forkJoin([...obsList]) : of([]);

        return updateObs.pipe(
          map((modelListList) => {
            this.logger.debug(`getDashboardsData - all dashboards loaded, generating state data`, { data: modelListList });
            const windows: DashboardWindow[] = [];
            const widgets: Widget[] = [];
            const grids: Grid[] = [];

            for (const { dashboardId, widgetModels } of modelListList) {
              const partialState = convertFromWidgetModels(dashboardId, widgetModels, this.modelLogger);
              windows.push(...(partialState.windows ?? []));
              widgets.push(...(partialState.widgets ?? []));
              grids.push(...(partialState.grids ?? []));
            }

            const store = this.stateService.state;

            // basically: loaded => keep, removed => drop, updated => drop + append
            const newState = {
              windows: [...store.windows?.filter(window => actions.get(window.dashboardId) === 'keep') ?? [], ...windows],
              widgets: [...store.widgets?.filter(widget => actions.get(widget.dashboardId) === 'keep') ?? [], ...widgets],
              grids: [...store.grids?.filter(grid => !grid.dashboardId || actions.get(grid.dashboardId) === 'keep') ?? [], ...grids],
            };

            this.logger.debug(`getDashboardsData - all dashboards loaded, state data ready`, { newState, oldState: store });

            return newState;
          }),
        );
      }),
      filterUndefined(),
      tap((x) => this.logger.debug('getDashboardsData: remote data', x)),
      takeUntil(this.destroySubject),
      shareReplay(1),
    );
    return this.dashboardsData$;
  }

  saveDashboardData(dashboard: Dashboard, state: State, isPreload = false): Observable<void> {
    this.logger.debug('saveDashboardData', { dashboard, state });

    const widgets = convertToWidgetStructureModel(state, dashboard);
    return this.fxDashboardService.get(dashboard.id).pipe(
      filter(ref => {
        if (ref.model.type !== FxDashboardType.FOLDER) {
          return true;
        } else {
          if (widgets.widgets.length) {
            this.logger.error(`Cannot store widgets for folder ${dashboard.id}.`);
          }
          return false;
        }
      }),
      take(1),
      catchError(this.handleError<DashboardRef>('saveDashboardData: fxDashboardService.get(dashboard.id)')),
      switchMap((dashboardRef: DashboardRef) => {
        if (dashboardRef) {
          const widgetSaveOptions = {
            dashboard: dashboardRef,
            widgets,
            markAsDraft: false,
          };

          return this.widgetDataService
            .save(widgetSaveOptions)
            .pipe(
              take(1),
              catchError(this.handleError<DashboardRef>('saveDashboardData: widgetDataService.save')),
              finalize(() => {
                if (isPreload) {
                  this.preloadedWidgetState.set(dashboard.id, widgets);
                }
              }),
            );
        }
        return of(undefined);
      })
    ) as Observable<void>;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: Error): Observable<T> => {
      this.logger.error(operation, error);
      if (environment.development) {
        this.notificationService.showError(`${operation} failed: ${error?.message}`);
      }
      return of(result).pipe(filterUndefined<T>());
    };
  }

  private validateDashboard(dashboard: Dashboard) {
    const isInstrumentDashboard = dashboard.type === DashboardType.instrument;
    const isPortfolioDashboard = dashboard.type === DashboardType.portfolio;
    const hasValidNameAndId = dashboard?.id != undefined && dashboard?.parentId != undefined && !!dashboard?.name;
    if (isInstrumentDashboard || isPortfolioDashboard) {
      this.logger.log('invalid dashboard (instrument or portfolio dashboard)', dashboard);
      return false;
    }
    if (!hasValidNameAndId) {
      this.logger.log('invalid dashboard (missing properties)', dashboard);
      return false;
    }

    return true;
  }

  // TODO: clarify if we still need the validator methods!

  // private validateWidget(widget: Widget) {
  //   const parentWindow = this.getParentWindow(widget);
  //   if (!parentWindow) {
  //     this.logger.log('invalid widget (no window)', widget, parentWindow);
  //     return false;
  //   }
  //   const parentDashboard = this.getParentDashboard(parentWindow);
  //   if (!parentDashboard || parentDashboard.type === DashboardType.instrument) {
  //     this.logger.log('invalid widget (no dashboard or instrument dashboard)', widget, parentWindow, parentDashboard);
  //     return false;
  //   }
  //   const hasValidNameAndId = widget?.id != undefined && widget.windowId != undefined;
  //   if (!hasValidNameAndId) {
  //     this.logger.log('invalid widget (id, name)', widget);
  //     return false;
  //   }
  //   return true;
  // }

  // private validateGrid(grid: Grid) {
  //   const hasValidId = grid?.id != undefined;
  //   if (!hasValidId) {
  //     this.logger.log('invalid grid (id)', grid);
  //     return false;
  //   }
  //   return true;
  // }

  // private validateWindow(window: DashboardWindow) {
  //   const parentDashboard = this.getParentDashboard(window);
  //   if (!parentDashboard || parentDashboard.type === DashboardType.instrument) {
  //     this.logger.log('invalid window (no dashboard or instrument dashboard)', window, parentDashboard);
  //     return false;
  //   }
  //   const hasValidNameAndId = window?.id != undefined && window?.dashboardId != undefined;
  //   if (!hasValidNameAndId) {
  //     this.logger.log('invalid window (name, id)', this.dashboardUrl);
  //     return false;
  //   }
  //   return true;
  // }

  // private getParentWindow(widget: Widget): DashboardWindow | undefined {
  //   return this.stateService.state.windows.find((w) => w.id == widget.windowId);
  // }

  // private getParentDashboard(window: DashboardWindow): Dashboard | undefined {
  //   return this.stateService.state.dashboards.find((d) => d.id === window.dashboardId);
  // }
}
