import { InfrontSDK } from '@infront/sdk';

import { upDownColor } from '../../shared/grid/column-registry';
import { Column } from '../../shared/grid/columns.model';
import { ColumnSetting } from '../../state-model/grid.model';
import { ListsCategories, ListsColumnDefs, ListsColumns } from '../lists/lists.columns';

export const TradingPrefix = 'Trading~';

export const TradingColumns = {
  tradingCurrency: {
    colId: 'currency',
    headerName: 'Ccy',
    headerTooltip: 'Trading Currency',
    field: `${TradingPrefix}${InfrontSDK.TradingField.Currency}` as InfrontSDK.TradingField,
    width: 84,
    type: 'rightAligned',
  },
  volume: {
    colId: 'volume',
    headerName: 'Volume',
    field: `${TradingPrefix}Volume` as InfrontSDK.TradingField,
    type: 'rightAligned',
    width: 83,
  },
  // last: {
  //   colId: 'last',
  //   headerName: 'Market Price',
  //   headerTooltip:
  //     'The instrument price we get from the trading gateway. Used as a fallback if we are not able to map the instrument to a market data feed.',
  //   field: `${TradingPrefix}Last` as InfrontSDK.TradingField,
  //   type: 'rightAligned',
  //   valueFormatter: 'sdkDecimals',
  //   width: 104,
  // },
  invested: {
    colId: 'invested',
    headerName: 'Invested',
    field: `${TradingPrefix}Invested` as InfrontSDK.TradingField,
    type: 'rightAligned',
    valueFormatter: 'twoDecimals',
    width: 87,
  },
  baseInvested: {
    colId: 'baseInvested',
    headerName: 'Invested',
    headerTooltip: 'Invested (Base Currency)',
    headerValueGetter: (params: { context: { baseCurrency: string } }) => {
      return `Invested ${params.context.baseCurrency ?? ''}`;
    },
    field: `${TradingPrefix}BaseInvested` as InfrontSDK.TradingField,
    type: 'rightAligned',
    valueFormatter: 'twoDecimals',
    width: 116,
  },
  marketValue: {
    colId: 'marketValue',
    headerName: 'Market Value',
    field: `${TradingPrefix}${InfrontSDK.TradingField.MarketValue}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    valueFormatter: 'twoDecimals',
    width: 107,
  },
  baseMarketValue: {
    colId: 'baseMarketValue',
    headerName: 'Market Value',
    headerTooltip: 'Market Value (Base Currency)',
    headerValueGetter: (params: { context: { baseCurrency: string } }) => {
      return `Market Value ${params.context.baseCurrency ?? ''}`;
    },
    field: `${TradingPrefix}${InfrontSDK.TradingField.BaseMarketValue}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    valueFormatter: 'twoDecimals',
    width: 137,
  },
  baseResult: {
    colId: 'baseResult',
    headerName: 'Result',
    headerTooltip: 'Result (Base Currency)',
    headerValueGetter: (params: { context: { baseCurrency: string } }) => {
      return `Result ${params.context.baseCurrency ?? ''}`;
    },
    field: `${TradingPrefix}${InfrontSDK.TradingField.BaseResult}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    valueFormatter: 'twoDecimals',
    width: 103,
    cellClass: (params: { value: number }) => [...upDownColor(params), 'ag-right-aligned-cell'],
  },
  baseResultPct: {
    colId: 'baseResultPct',
    headerName: 'Result %',
    headerTooltip: 'Result % (Base Currency)',
    headerValueGetter: (params: { context: { baseCurrency: string } }) => {
      return `Result % ${params.context.baseCurrency ?? ''}`;
    },
    field: `${TradingPrefix}${InfrontSDK.TradingField.BaseResultPct}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    valueFormatter: 'twoDecimalsPercent',
    width: 112, // 85 without currency injected
    cellClass: (params: { value: number }) => [...upDownColor(params), 'ag-right-aligned-cell'],
  },
  baseProfitLossToday: {
    colId: 'baseProfitLossToday',
    headerName: 'P/L Today',
    headerTooltip: 'Profit Loss Today (Base Currency)',
    headerValueGetter: (params: { context: { baseCurrency: string } }) => {
      return `P/L Today ${params.context.baseCurrency ?? ''}`;
    },
    field: `${TradingPrefix}${InfrontSDK.TradingField.BaseProfitLossToday}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    valueFormatter: 'twoDecimals',
    cellClass: (params: { value: number }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 142,
  },
  baseProfitLossTodayPct: {
    colId: 'baseProfitLossTodayPct',
    headerName: 'P/L Today %',
    headerTooltip: 'Profit Loss Today % (Base Currency)',
    headerValueGetter: (params: { context: { baseCurrency: string } }) => {
      return `P/L Today % ${params.context.baseCurrency ?? ''}`;
    },
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    field: `${TradingPrefix}${InfrontSDK.TradingField['BaseProfitLossTodayPct']}` as InfrontSDK.TradingField, // todo: no SDK field yet, add to SDK
    type: 'rightAligned',
    valueFormatter: 'twoDecimalsPercent',
    cellClass: (params: { value: number }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 142,
  },
  profitLossToday: {
    colId: 'profitLossToday',
    headerName: 'P/L Today',
    headerTooltip: 'Profit Loss Today in the instrument’s currency',
    field: `${TradingPrefix}${InfrontSDK.TradingField.ProfitLossToday}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    valueFormatter: 'twoDecimals',
    cellClass: (params: { value: number }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 142,
  },
  profitLossTodayPct: {
    colId: 'profitLossTodayPct',
    headerName: 'P/L Today %',
    headerTooltip: 'Profit Loss Today % in the instrument’s currency',
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    field: `${TradingPrefix}${InfrontSDK.TradingField['ProfitLossTodayPct']}` as InfrontSDK.TradingField, // todo: no SDK field yet, add to SDK
    type: 'rightAligned',
    valueFormatter: 'twoDecimalsPercent',
    cellClass: (params: { value: number }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 142,
  },
  baseCollateral: {
    colId: 'baseCollateral',
    headerName: 'Collateral',
    headerTooltip: 'Collateral (Base Currency)',
    headerValueGetter: (params: { context: { baseCurrency: string } }) => {
      return `Collateral ${params.context.baseCurrency ?? ''}`;
    },
    field: `${TradingPrefix}${InfrontSDK.TradingField.BaseCollateral}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    valueFormatter: 'twoDecimals',
    width: 142,
  },
  collateral: {
    colId: 'collateral',
    headerName: 'Collateral',
    field: `${TradingPrefix}${InfrontSDK.TradingField.Collateral}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    valueFormatter: 'twoDecimals',
    width: 142,
  },
  displayName: {
    colId: 'displayName',
    headerName: 'Symbol',
    headerTooltip: 'Trading Symbol, the symbol that we receive directly from the trading gateway.',
    field: `${TradingPrefix}${InfrontSDK.TradingField.DisplayName}` as InfrontSDK.TradingField,
    width: 184,
  },
  // contractSize: { // waiting for SDK implementation
  //   colId: 'contractSize',
  //   headerName: 'Symbol',
  //   field: `${TradingPrefix}${InfrontSDK.TradingField.DisplayName}` as InfrontSDK.TradingField,
  //   width: 184,
  // },
  result: {
    colId: 'result',
    headerName: 'Result',
    field: `${TradingPrefix}${InfrontSDK.TradingField.Result}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    valueFormatter: 'twoDecimals',
    cellClass: (params: { value: number }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 103,
  },
  resultPct: {
    colId: 'resultPct',
    headerName: 'Result %',
    field: `${TradingPrefix}${InfrontSDK.TradingField.ResultPct}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    valueFormatter: 'twoDecimalsPercent',
    cellClass: (params: { value: number }) => [...upDownColor(params), 'ag-right-aligned-cell'],
    width: 103,
  },
  avgPrice: {
    colId: 'avgPrice',
    headerName: 'Avg price',
    headerTooltip: 'Average Price',
    field: `${TradingPrefix}${InfrontSDK.TradingField.AveragePrice}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    valueFormatter: 'sdkDecimals',
    width: 114,
  },
  loanRatio: {
    colId: 'loanRatio',
    headerName: 'Loan Ratio',
    field: `${TradingPrefix}${InfrontSDK.TradingField.LoanToValueRatio}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    valueFormatter: 'twoDecimals',
    width: 184,
  },
  isin: {
    colId: 'isin',
    headerName: 'ISIN',
    headerTooltip: 'Trading ISIN, the ISIN that we receive directly from the trading gateway.',
    field: `${TradingPrefix}${InfrontSDK.TradingField.Isin}` as InfrontSDK.TradingField,
    width: 184,
  },
};

const StandardTradingCols: Column[] = Object.values(TradingColumns) as Column[];

const PerformanceColumns: Column[] = [
  ListsColumnDefs.listsPctChange,
  ListsColumnDefs.pctChange1W,
  ListsColumnDefs.pctChange1M,
  ListsColumnDefs.pctChange3M,
  ListsColumnDefs.pctChange6M,
  ListsColumnDefs.pctChangeYTD,
  ListsColumnDefs.pctChange1Y,
  ListsColumnDefs.pctChange2Y,
  ListsColumnDefs.pctChange3Y,
  ListsColumnDefs.pctChange5Y,
];

const CommonColumns: Column[] = [
  ListsColumnDefs.listsCurrency,
  TradingColumns.volume,
  TradingColumns.avgPrice,
  ListsColumnDefs.lastValid,
  ListsColumnDefs.listsChange,
  TradingColumns.baseInvested,
  TradingColumns.baseMarketValue,
  TradingColumns.baseResult,
  TradingColumns.baseResultPct,
];

const StandardAllCols = [...StandardTradingCols, ...ListsColumns];

const TradingCategory = {
  name: 'Trading',
  columns: StandardTradingCols,
};

export const TradingCategories = [TradingCategory, ...ListsCategories];

// Stocks
export const PortfolioStockDefaultColumns = StandardAllCols;
export const SelectedPortfolioStockDefaultColumns: ColumnSetting[] = [ListsColumnDefs.flagAndFullName, ...CommonColumns].map((col) => ({
  colId: col.colId,
}));
export const PortfolioStockPerformanceColumns = StandardAllCols;
export const SelectedPortfolioStockPerformanceColumns: ColumnSetting[] = [ListsColumnDefs.flagAndFullName, ...PerformanceColumns].map((col) => ({
  colId: col.colId,
}));

//ETFs
export const PortfolioETFDefaultColumns = StandardAllCols;
export const SelectedPortfolioETFDefaultColumns: ColumnSetting[] = [ListsColumnDefs.flagAndFullName, ...CommonColumns].map((col) => ({
  colId: col.colId,
}));
export const PortfolioETFPerformanceColumns = StandardAllCols;
export const SelectedPortfolioETFPerformanceColumns: ColumnSetting[] = [ListsColumnDefs.flagAndFullName, ...PerformanceColumns].map((col) => ({
  colId: col.colId,
}));

// Funds
export const PortfolioFundDefaultColumns = StandardAllCols;
export const SelectedPortfolioFundDefaultColumns: ColumnSetting[] = [ListsColumnDefs.fullName, ...CommonColumns, ListsColumnDefs.date].map((col) => ({
  colId: col.colId,
}));
export const PortfolioFundPerformanceColumns = StandardAllCols;
export const SelectedPortfolioFundPerformanceColumns: ColumnSetting[] = [ListsColumnDefs.fullName, ...PerformanceColumns, ListsColumnDefs.time].map(
  (col) => ({
    colId: col.colId,
  })
);

// Futures
export const PortfolioFutureDefaultColumns = StandardAllCols;
export const SelectedPortfolioFutureDefaultColumns: ColumnSetting[] = [
  ListsColumnDefs.fullName,
  ListsColumnDefs.listsCurrency,
  TradingColumns.volume,
  TradingColumns.avgPrice,
  ListsColumnDefs.lastValid,
  ListsColumnDefs.listsChange,
  TradingColumns.baseInvested,
  TradingColumns.baseMarketValue,
  TradingColumns.baseResult,
  TradingColumns.baseResultPct,
].map((col) => ({
  colId: col.colId,
}));
export const PortfolioFuturePerformanceColumns = StandardAllCols;
export const SelectedPortfolioFuturePerformanceColumns: ColumnSetting[] = [ListsColumnDefs.fullName, ...PerformanceColumns].map((col) => ({
  colId: col.colId,
}));

// Derivatives
export const PortfolioDerivativeDefaultColumns = StandardAllCols;
export const SelectedPortfolioDerivativeDefaultColumns: ColumnSetting[] = [
  ListsColumnDefs.fullName,
  ListsColumnDefs.listsCurrency,
  //TradingColumns.contractSize, //waiting for sdk
  TradingColumns.volume,
  TradingColumns.avgPrice,
  ListsColumnDefs.listsBid,
  ListsColumnDefs.listsAsk,
  ListsColumnDefs.listsSpreadPct,
  ListsColumnDefs.lastValid,
  ListsColumnDefs.listsChange,
  TradingColumns.baseInvested,
  TradingColumns.baseMarketValue,
  TradingColumns.baseResult,
  TradingColumns.baseResultPct,
].map((col) => {
  return {
    colId: col.colId,
  };
});

export const PortfolioDerivativePerformanceColumns = [ListsColumnDefs.fullName, ...PerformanceColumns];
export const SelectedPortfolioDerivativePerformanceColumns: ColumnSetting[] = PortfolioDerivativePerformanceColumns.map((col) => {
  return {
    colId: col.colId,
  };
});

// Certificates
export const PortfolioCertificateDefaultColumns = StandardAllCols;
export const SelectedPortfolioCertificateDefaultColumns: ColumnSetting[] = [...SelectedPortfolioFutureDefaultColumns];
export const PortfolioCertificatePerformanceColumns = StandardAllCols;
export const SelectedPortfolioCertificatePerformanceColumns: ColumnSetting[] = [ListsColumnDefs.fullName, ...PerformanceColumns].map((col) => ({
  colId: col.colId,
}));

// Bonds
export const PortfolioBondDefaultColumns = StandardAllCols;
export const SelectedPortfolioBondDefaultColumns: ColumnSetting[] = [ListsColumnDefs.fullName, ...CommonColumns].map((col) => ({
  colId: col.colId,
}));
export const PortfolioBondPerformanceColumns = StandardAllCols;
export const SelectedPortfolioBondPerformanceColumns: ColumnSetting[] = [ListsColumnDefs.fullName, ...PerformanceColumns].map((col) => ({
  colId: col.colId,
}));

// Others
export const PortfolioOtherDefaultColumns = StandardTradingCols;
export const SelectedPortfolioOtherDefaultColumns: ColumnSetting[] = [
  TradingColumns.displayName,
  ListsColumnDefs.listsCurrency,
  TradingColumns.volume,
  TradingColumns.avgPrice,
  ListsColumnDefs.lastValid,
  TradingColumns.baseInvested,
  TradingColumns.baseMarketValue,
  TradingColumns.baseResult,
  TradingColumns.baseResultPct,
].map((col) => {
  return {
    colId: col.colId,
  };
});
export const PortfolioOtherPerformanceColumns = StandardAllCols;
export const SelectedPortfolioOtherPerformanceColumns: ColumnSetting[] = [
  TradingColumns.displayName,
  ListsColumnDefs.listsCurrency,
  TradingColumns.volume,
  TradingColumns.avgPrice,
  ListsColumnDefs.lastValid,
  TradingColumns.baseInvested,
  TradingColumns.baseMarketValue,
  TradingColumns.baseResult,
  TradingColumns.baseResultPct,
].map((col) => {
  return {
    colId: col.colId,
  };
});

// Unmapped
export const PortfolioUnmappedDefaultColumns = StandardTradingCols;
export const SelectedPortfolioUnmappedDefaultColumns: ColumnSetting[] = [
  TradingColumns.displayName,
  TradingColumns.tradingCurrency,
  TradingColumns.volume,
  TradingColumns.isin,
].map((col) => ({
  colId: col.colId,
}));
