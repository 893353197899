import { Injectable, inject } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { NEVER } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { SdkRequestsService } from '../../../services/sdk-requests.service';
import { Widget } from '../../../state-model/widget.model';
import { Instrument } from '../../../state-model/window.model';

@Injectable({
  providedIn: 'root',
})
export class InstrumentHeaderService {
  private readonly sdkRequestsService: SdkRequestsService = inject(SdkRequestsService);

  symbolInfo$ = (symbolEntity: Widget | Instrument) => {
    if (!symbolEntity) {
      return NEVER;
    }
    return this.sdkRequestsService
      .snapshotSymbolData$({ symbolEntity, fields: [InfrontSDK.SymbolField.FeedExchange, InfrontSDK.SymbolField.PreDisplayTicker, InfrontSDK.SymbolField.FeedIsHidden] })
      .pipe(
        map((symbolData) => ({ feedExchange: symbolData.FeedExchange, preDisplayTicker: symbolData.PreDisplayTicker, feedIsHidden: symbolData.FeedIsHidden })),
        shareReplay(1)
      );
  };

}
