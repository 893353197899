<div *ngIf="sidebar$ | async as sidebar" class="wt-sidebar" [ngClass]="{ 'wt-sidebar--open': sidebar.isOpen }">
  <div class="wt-flex-row wt-flex-collapse wt-sidebar__header">
    <div class="wt-sidebar__toggle-btn" (click)="toggleSidebar(sidebar)">
      <mat-icon fontIcon="{{ sidebar.isOpen ? 'keyboard_arrow_right' : 'keyboard_arrow_left' }}"></mat-icon>
    </div>

    <div *ngIf="sidebar.isOpen && (sidebarTabs$ | async) as sidebarTabs"
      class="wt-flex wt-sidebar__tab-selection">
      <div *ngFor="let tab of sidebarTabs"
        class="wt-flex-column wt-flex-shrink wt-sidebar__selectable-tab"
        [ngClass]="{ 'wt-sidebar__selectable-tab--selected': tab === sidebar.selectedTab}"
        (click)="onSelectedSidebarTab(sidebar, tab)">
        {{ "SIDEBAR.TAB." + sidebarTabEnum[tab] + ".NAME" | translate }}
      </div>
    </div>
  </div>

  <div class="wt-flex-row wt-flex-expanded">
    <div class="wt-flex-column">
      <wt-sidebar-content *ngIf="sidebar.isOpen"
        [sidebar]="sidebar">
      </wt-sidebar-content>
    </div>
  </div>
</div>