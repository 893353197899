import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DashboardModel, WidgetsHistoryEntry, WidgetStructureModel } from '../../models';
import { WidgetDataProvider, WidgetProviderLoadHistoryOptions, WidgetProviderSaveOptions } from '../widget-data.provider';

const EMPTY_ARRAY = Object.freeze([]) as readonly any[];
const EMPTY_STRUCTURE = Object.freeze({ widgets: EMPTY_ARRAY }) as WidgetStructureModel;
const EMPTY_HISTORY = EMPTY_ARRAY as readonly WidgetsHistoryEntry[];

@Injectable({ providedIn: 'root' })
export class BuiltInWidgetDataProvider extends WidgetDataProvider {

  public loadHistory(dashboard: DashboardModel): Observable<readonly WidgetsHistoryEntry[]> {
    return of(EMPTY_HISTORY);
  }

  public loadByDashboard(dashboard: DashboardModel): Observable<WidgetStructureModel> {
    return of(EMPTY_STRUCTURE);
  }

  public loadByHistory(entry: WidgetProviderLoadHistoryOptions): Observable<WidgetStructureModel> {
    return of(EMPTY_STRUCTURE);
  }

  public save(saveOptions: WidgetProviderSaveOptions): Observable<void> {
    throw new Error('Built-in dashboards are read-only.');
  }

  public revertDraft(dashboard: DashboardModel): Observable<void> {
    throw new Error('Built-in dashboards are read-only.');
  }

}
