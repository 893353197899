import { DashboardFolderLevel } from '@infront/ngx-dashboards-fx';
import { Instrument } from './window.model';

export enum DashboardType {
  dashboard = 0,
  template = 1,
  folder = 2, // maybe required later
  instrument = 3,
  portfolio = 4,
}

export interface Dashboard {
  id: string;
  index: number; // dashboards will be sorted by index, after loading from storage
  name: string;
  type: DashboardType;
  level: DashboardFolderLevel;
  parentId?: string;
  hidden?: boolean;
  instrument?: Instrument;
  locked: boolean;
  readonly: boolean;
  canRename: boolean;
  canDelete: boolean;
  canClose: boolean;
}
