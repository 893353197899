<div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
  <div class="wt-flex-column" [ngClass]="{ 'wt-flex-shrink': !!displayName }">
    <wt-country-flag
      [ngClass]="'wt-country-flag-cell-image' + (!!displayName ? '' : '--center')"
      [country]="country"
      [size]="size"
      [title]="displayName"></wt-country-flag>
  </div>

  <div *ngIf="!!displayName" class="wt-flex-column wt-truncate wt-grid--clickable" [title]="displayName">{{ displayName
    }}</div>
</div>