<wt-grid
  class="wt-grid"
  [settings]="gridSettings"
  [gridOptions]="gridOptions"
  [columns]="columns"
  [data]="gridData"
  [context]="context"
  [suppressHorizontalScroll]="suppressHorizontalScroll"
  (api)="onGridApi($event)"
  (showMore)="showMore.emit()"
  (rowClickedEvent)="rowClickedEvent.emit($event)"
  (cellClickedEvent)="cellClickedEvent.emit($event)"
  (selectionChanged)="selectionChanged.emit($event)"
  (columnsChanged)="columnsChanged.emit($event)"></wt-grid>
