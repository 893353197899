/**
 * An array including an id map; extends {@link ReadonlyArray<T>},
 * allowing you to work with an ordered list/map-like object using both
 * regular array manipulation methods, and id lookups.
 *
 * You can create instances of this interface via {@link fromMap}
 * and {@link fromArray}.
 *
 * @remarks
 *
 * Implementation notes on why this is implemented via an internal class
 * {@link ArrayMapImpl}, and prototype swizzling on a real Array instance:
 *
 * * We cannot `extend ReadonlyArray<T>` because that's an interface.
 * * We cannot `extend Array<T>` because we don't want to expose.
 *   mutator methods that can mess up the id<->entry links (the fact that
 *   {@link reverse} and {@link sort} are exposed is a bit of a crutch);
 * * We also cannot implement a class that is array-like from scratch,
 *   as indexers cannot really be implemented, and we could run into
 *   unwanted disparity between `Array`/`ReadonlyArray` and this type,
 *   and no way to guarantee this stays in sync.
 *
 * We also don't want to return a {@link Map<K, T>}, because that
 * would mean we would miss out on all the niceties of {@link ReadonlyArray<T>},
 * like {@link Array.filter}, {@link Array.map}, etc., and array-like processing
 * is actually what we want to support first and foremost.
 */
export interface ArrayMap<K, T> extends ReadonlyArray<T> {
  /** Returns the value identified by the specified ID; or `undefined` if it does not exist. */
  get(id: K): T | undefined;

  /** Indicates whether the specified ID exists in this array. */
  has(id: K): boolean;

  /**
   * Deletes the element with the specified ID from the array.
   * Returns true when the element in the array existed and has been removed,
   * or `false` if the element does not exist.
   */
  delete(id: K): boolean;

  /**
   * Clears the array.
   */
  clear(): void;

  /**
   * Adds or updates an element in the array.
   *
   * If the key does not yet exist, the size of the array is changed,
   * and the element is added as the last element of the array.
   *
   * @param id The ID of the item to update.
   * @param value The value to add or replace the element with.
   */
  set(id: K, value: T): this;

  /**
   * Returns the IDs of the entries present in this array.
   */
  ids(): IterableIterator<K>;

  // Unfortunately, it's not possible to be compatible with both
  // `ReadonlyArray<T>` *and* `ReadonlyMap<K, T>`, as the JS standard
  // already dictates that `ReadonlyArray<T>` and `ReadonlyMap<number, T>`
  // are related via `entries()` and `keys()`.

  /**
   * Returns the entries of the array, including their IDs.
   */
  entries(): IterableIterator<readonly [number, T, K]>;
  entries(): IterableIterator<[number, T]>;

  // We cannot change the return type of `filter` and `map` methods direcetly
  // to return `ArrayMap<K, S>`, because the `ReadonlyArray<T>` methods return
  // a `Array<T>`, which is not compatible with `ArrayMap<K, T>`. So, we add
  // extra overloads, where the new overloads are more specific.

  /**
   * Returns the elements of an array that meet the condition specified in a callback function.
   *
   * @param predicate A function that accepts up to three arguments. The filter
   *   method calls the predicate function one time for each element in the array.
   * @param thisArg An object to which the this keyword can refer in the
   *   predicate function. If thisArg is omitted, undefined is used as the `this`
   *   value.
   */
  filter<S extends T>(predicate: (value: T, index: number, array: ArrayMap<K, T>) => value is S, thisArg?: any): ArrayMap<K, S>;
  /**
   * Returns the elements of an array that meet the condition specified in a callback function.
   *
   * @param predicate A function that accepts up to three arguments. The filter
   *   method calls the predicate function one time for each element in the array.
   * @param thisArg An object to which the this keyword can refer in the
   *   predicate function. If thisArg is omitted, undefined is used as the `this`
   *   value.
   */
  filter(predicate: (value: T, index: number, array: ArrayMap<K, T>) => unknown, thisArg?: any): ArrayMap<K, T>;
  /** @deprecated This overload of {@link filter} returns a mutable array, use an overload that returns a {@link ArrayMap<K, T>} */
  filter<S extends T>(predicate: (value: T, index: number, array: readonly T[]) => value is S, thisArg?: any): S[];
  /** @deprecated This overload of {@link filter} returns a mutable array, use an overload that returns a {@link ArrayMap<K, S>} */
  filter(predicate: (value: T, index: number, array: readonly T[]) => unknown, thisArg?: any): T[];

  // Deprecate this method, because it's confusing
  /** @deprecated Do not use {@link keys} to iterate over indexed. */
  keys(): IterableIterator<number>;

  /**
   * Calls a defined callback function on each element of an array, and returns an array that contains the results.
   *
   * @param callbackfn A function that accepts up to three arguments. The map
   *   method calls the callbackfn function one time for each element in the array.
   * @param thisArg An object to which the this keyword can refer in the
   *   callbackfn function. If thisArg is omitted, undefined is used as the
   *   `this` value.
   */
  map<U>(callbackfn: (value: T, index: number, array: ArrayMap<K, T>) => U, thisArg?: any): ArrayMap<K, U>;
  /** @deprecated This overload of {@link map} returns a mutable array, use an overload that returns a {@link ArrayMap<K, U>} */
  map<U>(callbackfn: (value: T, index: number, array: T[]) => U, thisArg?: any): U[];

  /**
   * Removes the last element from an array and returns it.
   * If the array is empty, undefined is returned and the array is not modified.
   */
  pop(): T | undefined;

  /**
   * Reverses the elements in an array in place.
   * This method mutates the array and returns a reference to the same array.
   */
  reverse(): this;

  /**
   * Removes the first element from an array and returns it.
   * If the array is empty, undefined is returned and the array is not modified.
   */
  shift(): T | undefined;

  /**
   * Returns a section of an array.
   *
   * @param start The beginning of the specified portion of the array.
   * @param end The end of the specified portion of the array. This is exclusive of the element at the index 'end'.
   */
  slice(start?: number, end?: number): ArrayMap<K, T>;
  /** @deprecated This overload of {@link slice} returns a mutable array, use an overload that returns a {@link ArrayMap<K, T>} */
  slice(start?: number, end?: number): T[];

  /**
   * Sorts an array in place.
   * This method mutates the array and returns a reference to the same array.
   *
   * @param compareFn Function used to determine the order of the elements. It is expected to return
   * a negative value if the first argument is less than the second argument, zero if they're equal, and a positive
   * value otherwise.
   */
  sort(compareFn: (a: T, b: T) => number): this;

  /**
   * Returns a sorted copy.
   *
   * @param compareFn Function used to determine the order of the elements. It is expected to return
   * a negative value if the first argument is less than the second argument, zero if they're equal, and a positive
   * value otherwise.
   */
  sorted(compareFn: (a: T, b: T) => number): T[];

  /** Returns a copy of the array map as an array */
  toArray(): T[];
}

/**
 * Read-only variant of {@link ArrayMap}.
 */
export interface ReadonlyArrayMap<K, T> extends ReadonlyArray<T> {
  /** Returns the value identified by the specified ID; or `undefined` if it does not exist. */
  get(id: K): T | undefined;

  /** Indicates whether the specified ID exists in this array. */
  has(id: K): boolean;

  /**
   * Returns the IDs of the values present in this array.
   */
  ids(): IterableIterator<K>;

  /**
   * Returns the entries of the array, including their IDs.
   */
  entries(): IterableIterator<readonly [number, T, K]>;
  entries(): IterableIterator<[number, T]>;

  /**
   * Creates a shallow copy of a portion of a given {@link ArrayMap}, filtered
   * down to just the elements from the given array that pass the test
   * implemented by the provided function.
   */
  filter<S extends T>(
    predicate: (value: T, index: number, array: ReadonlyArrayMap<K, T>) => value is S,
    thisArg?: any
  ): ReadonlyArrayMap<K, S>;
  /**
   * Creates a shallow copy of a portion of a given {@link ArrayMap}, filtered
   * down to just the elements from the given array that pass the test
   * implemented by the provided function.
   */
  filter(predicate: (value: T, index: number, array: ReadonlyArrayMap<K, T>) => unknown, thisArg?: any): ReadonlyArrayMap<K, T>;
  /** @deprecated This overload of {@link filter} returns a mutable array, use an overload that returns a {@link ReadonlyArrayMap<K, S>} */
  filter<S extends T>(predicate: (value: T, index: number, array: readonly T[]) => value is S, thisArg?: any): S[];
  /** @deprecated This overload of {@link filter} returns a mutable array, use an overload that returns a {@link ReadonlyArrayMap<K, T>} */
  filter(predicate: (value: T, index: number, array: readonly T[]) => unknown, thisArg?: any): T[];

  // Deprecate this method, because it's confusing
  /** @deprecated Do not use {@link keys} to iterate over indexed. */
  keys(): IterableIterator<number>;

  /**
   * creates a new {@link ArrayMap} populated with the results of calling a
   * provided function on every element in the calling array.
   */
  map<U>(callbackfn: (value: T, index: number, array: ReadonlyArrayMap<K, T>) => U, thisArg?: any): ReadonlyArrayMap<K, U>;
  /** @deprecated This overload of {@link map} returns a mutable array, use an overload that returns a {@link ReadonlyArrayMap<K, U>} */
  map<U>(callbackfn: (value: T, index: number, array: T[]) => U, thisArg?: any): U[];

  /**
   * Returns a section of an array.
   * @param start The beginning of the specified portion of the array.
   * @param end The end of the specified portion of the array. This is exclusive of the element at the index 'end'.
   */
  slice(start?: number, end?: number): ArrayMap<K, T>;
  /** @deprecated This overload of {@link slice} returns a mutable array, use an overload that returns a {@link ArrayMap<K, T>} */
  slice(start?: number, end?: number): T[];

  /**
   * Returns a sorted copy.
   *
   * @param compareFn Function used to determine the order of the elements. It is expected to return
   * a negative value if the first argument is less than the second argument, zero if they're equal, and a positive
   * value otherwise.
   */
  sorted(compareFn: (a: T, b: T) => number): T[];

  /** Returns a copy of the array map as an array */
  toArray(): T[];
}

/**
 * Symbol used to tag an array with a (hidden) {@link Map} containing the id->entry
 * mappings.
 */
const Symbol_ArrayMap = Symbol('ArrayMap id map');

/**
 * Implementation of {@link ArrayMap<K, T>}; will be swizzled into the
 * actual array given to {@link upgradeToArrayMap}.
 */
class ArrayMapImpl<K, T> {
  // Properties needed for implementing the instance methods without casting;
  // announced with a `!` because we don't want any trace on the prototype.
  [n: number]: T;
  [Symbol_ArrayMap]!: Map<K, number>; // will be set by upgradeToArrayMap
  [Symbol.iterator]!: () => IterableIterator<T>; // needed to support for-of below
  length!: number; // needed to check size below

  // Actual ArrayMap implementation

  clear(): void {
    this.length = 0;
    this[Symbol_ArrayMap].clear();
  }

  delete(id: K): boolean {
    const map = this[Symbol_ArrayMap];
    const itemIndex = map.get(id);
    if (itemIndex === undefined) {
      return false;
    }

    Array.prototype.splice.call(this, itemIndex, 1);

    for (const [key, index] of map) {
      if (index === itemIndex) {
        map.delete(key);
      } else if (index > itemIndex) {
        map.set(key, index - 1);
      }
    }

    return true;
  }

  set(id: K, value: T): this {
    const map = this[Symbol_ArrayMap];
    let itemIndex = map.get(id);
    if (itemIndex === undefined) {
      map.set(id, this.length);
      itemIndex = this.length;
    }
    this[itemIndex] = value;
    return this;
  }

  *entries(): IterableIterator<[number, T, K]> {
    const map = this[Symbol_ArrayMap];

    for (const [key, index] of map) {
      yield [index, this[index], key];
    }
  }

  has(id: K): boolean {
    return this[Symbol_ArrayMap].has(id);
  }

  get(id: K): T | undefined {
    const index = this[Symbol_ArrayMap].get(id);
    if (index != null) {
      return this[index];
    }
    return undefined;
  }

  ids(): IterableIterator<K> {
    return this[Symbol_ArrayMap].keys();
  }

  filter(predicate: (value: T, index: number, array: ArrayMapImpl<K, T>, thisArg?: any) => boolean, thisArg?: any): ArrayMap<K, T> {
    const map = new Map<K, number>();
    const array: T[] = [];
    for (const [id, index] of this[Symbol_ArrayMap]) {
      const value = this[index];
      if (predicate.call(thisArg, value, index, this)) {
        map.set(id, array.length);
        array.push(value);
      }
    }
    return upgradeToArrayMap(array, map);
  }

  map<U>(callbackfn: (value: T, index: number, array: ArrayMapImpl<K, T>) => U, thisArg?: any): ArrayMap<K, U> | Map<K, T> {
    const map = new Map<K, number>();
    const array: U[] = [];

    array.length = this.length; // pre-size

    for (const [id, index] of this[Symbol_ArrayMap].entries()) {
      const value = this[index];
      const mapped = callbackfn.call(thisArg, value, index, this);
      map.set(id, index);
      array[index] = mapped;
    }
    return upgradeToArrayMap(array, map);
  };

  reverse(): ArrayMap<K, T> {
    if (this.length <= 1) {
      return this as unknown as ArrayMap<K, T>;
    }

    Array.prototype.reverse.call(this);

    // to keep the map's iteration order in sync, rebuild the map
    const reversedEntries = Array.from(this[Symbol_ArrayMap]).reverse();
    this[Symbol_ArrayMap] = new Map(reversedEntries);

    return this as unknown as ArrayMap<K, T>;
  };

  sort(compareFn: (a: T, b: T) => number): ArrayMap<K, T> {
    if (this.length <= 1) {
      return this as unknown as ArrayMap<K, T>;
    }

    // sort() is a fairly heavy operation, because we try to
    // maintain the ids() and values() order of the array;
    // we do this by reverse lookup for value->key, which
    // might not be 100% safe if multiple "same" values
    // map to different keys. To work around this, we drop
    // mapped values after the reverse lookup. Theoretically
    // we might swap the keys for +0 and -0, but all keys
    // should be retained.

    const reverseIdLookup = new Map<T, K>();
    for (const [id, index] of this[Symbol_ArrayMap]) {
      const value = this[index];
      reverseIdLookup.set(value, id);
    }

    try {
      Array.prototype.sort.call(this, compareFn);
    } finally {
      // We do this in the finally, so that if sort or compareFn
      // throws, we don't end up with a broken map
      const map = this[Symbol_ArrayMap] = new Map<K, number>();

      let index = 0;
      for (const value of this) {
        const key = reverseIdLookup.get(value)!;
        map.set(key, index);
        index++;
        reverseIdLookup.delete(value);
      }
    }
    return this as unknown as ArrayMap<K, T>;
  };

  slice(start: number = 0, end = -1): ArrayMap<K, T> {
    const array = Array.prototype.slice.call(this, start, end);
    const map = new Map<K, number>();
    if (array.length) {
      const actualStart = start < -this.length ? 0 : start < 0 ? start + this.length : start; // NOSONAR nested ternary accepted
      for (const [key, index] of this[Symbol_ArrayMap]) {
        if (index >= actualStart) {
          map.set(key, index - actualStart);
          if (map.size >= array.length) {
            break;
          }
        }
      }
    }
    return upgradeToArrayMap(array, map);
  }

  pop(): T | undefined {
    const result = Array.prototype.pop.call(this);
    const map = this[Symbol_ArrayMap];
    if (this.length !== map.size) {
      for (const [id, index] of map) {
        if (index >= this.length) {
          map.delete(id);
        }
      }
    }
    return result;
  }

  shift(): T | undefined {
    const result = Array.prototype.shift.call(this);
    const map = this[Symbol_ArrayMap];
    if (this.length !== map.size) {
      for (const [id, index] of map) {
        if (index === 0) {
          map.delete(id);
        } else {
          map.set(id, index - 1);
        }
      }
    }
    return result;
  }

  sorted(compareFn: (a: T, b: T) => number): T[] {
    return Array.prototype.slice.call(this).sort(compareFn);
  }

  toArray(): T[] {
    return Array.prototype.slice.call(this);
  }

  // Unsupported array methods, which potentially mutate the length, and can
  // insert new elements where we don't know the id

  push = throwNotSupported('push');
  splice = throwNotSupported('splice');
  unshift = throwNotSupported('unshift');
}

function throwNotSupported(method: string): () => void {
  return () => { throw new TypeError(`ArrayMap does not support ${method}.`); };
}

Object.setPrototypeOf(ArrayMapImpl.prototype, Array.prototype);

/**
 * Combine the entries array with the ID map, by upgrading the array instance
 * and returning it.
 */
function upgradeToArrayMap<K, T>(array: ReadonlyArray<T>, map: Map<K, number>): ArrayMap<K, T> {
  Object.defineProperty(array, Symbol_ArrayMap, { value: map, writable: true });
  Object.setPrototypeOf(array, ArrayMapImpl.prototype);
  return array as ArrayMap<K, T>;
}

/**
 * Create a {@link ArrayMap<K, T>} from a map-like structure.
 *
 * Note that this method creates a copy of {@param source}.
 */
export function fromMap<T, K>(source: Iterable<readonly [K, T]>): ArrayMap<K, T> {
  // defensive copy, to ensure the map does not get out of sync with the projected array
  const map = new Map<K, number>();
  const array: T[] = [];

  for (const [key, value] of source) {
    map.set(key, array.length);
    array.push(value);
  }

  return upgradeToArrayMap(array, map);
}

/**
 * Create a {@link ArrayMap<K, T>} from an array-like structure, and an key generator callback.
 *
 * Note that this method creates a copy of {@param source}.
 */
export function fromArray<T, K>(source: Iterable<T>, callbackfn: (entry: T) => K, ignoreDuplicateKeys = false): ArrayMap<K, T> {
  const map = new Map<K, number>();
  const array: T[] = [];

  for (const entry of source) {
    const id = callbackfn(entry);
    if (map.has(id)) {
      if (!ignoreDuplicateKeys) {
        throw new Error(`Duplicate key "${id}" found.`);
      }
    } else {
      map.set(id, array.length);
      array.push(entry);
    }
  }

  return upgradeToArrayMap(array, map);
}
