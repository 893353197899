<!-- Widget Title Bar (display search and buttons) -->
<ng-container>
  <!-- Widget Title Bar Left (__title)-->
  <div class="widget-title-bar__title gridster-item-content"
    [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false">
    <div class="wt-flex-row wt-flex-collapse">
      <div class="wt-flex-column wt-flex-shrink">
        <mat-icon class="widget-title-bar-icon" fontIcon="notifications"></mat-icon>
      </div>

      <div class="wt-flex-column">
        <span class="widget-title-bar-label">
          {{ "DASHBOARD_WINDOW.HEADER.WINDOW_LABEL." + (window.name || "NA") | translate }}
        </span>
      </div>
    </div>
  </div>
</ng-container>