import { forwardRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StringOrTranslation } from '../models';
import { LocaleIdProvider } from './locale-id.provider';
import { translate$ } from './translate';

@Injectable({
  providedIn: 'root',
  useClass: forwardRef(() => DefaultDashboardTranslateService),
})
export abstract class DashboardTranslateService {
  public abstract create(scope: string, id: string, key: string, stringOrTranslation: StringOrTranslation): Observable<string>;
}

@Injectable({ providedIn: 'root' })
export class DefaultDashboardTranslateService implements DashboardTranslateService {

  constructor(
    private readonly localeIdProvider: LocaleIdProvider,
  ) { }

  public create(scope: string, component: string, key: string, stringOrTranslation: StringOrTranslation): Observable<string> {
    return translate$(stringOrTranslation, this.localeIdProvider.localeId$);
  }

}
