import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { PipesModule } from '../../pipes/pipes.module';
import { CountryFlagModule } from '../country-flag/country-flag.module';
import { DirectivesModule } from './../../directives/directives.module';
import { AlertLogListModule } from './alert-log-list/alert-log-list.module';
import { AlertsListComponent } from './alerts-list.component';
import { MyAlertsComponent } from './my-alerts/my-alerts.component';

@NgModule({
  declarations: [AlertsListComponent, MyAlertsComponent],
  imports: [
    CommonModule,
    AlertLogListModule,
    MatIconModule,
    MatBadgeModule,
    DirectivesModule,
    CountryFlagModule,
    MatTooltipModule,
    OverlayModule,
    PipesModule,
    MatButtonModule,
    PipesModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [AlertsListComponent],
})
export class AlertsListModule {}
