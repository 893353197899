import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wt-ranking-cell',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="wt-ranking-cell" *ngIf="widthPercent">
      <hr [style.width]="widthPercent.value + '%'" class="wt-ranking-cell__bar" [class]="isNegative ? 'negative' : 'positive'" />
    </div>
  `,
})
export class RankingCellComponent {
  widthPercent: { value: number };
  isNegative: boolean;

  private readonly cdRef = inject(ChangeDetectorRef);

  agInit(params: ICellRendererParams): void {
    this.isNegative = params.data.rankingPercent < 0;
    this.drawBar(params);
  }

  private drawBar(params: ICellRendererParams) {
    this.widthPercent = { value: params.value as number };
    this.cdRef.markForCheck();
  }

  refresh(params: ICellRendererParams): boolean {
    this.drawBar(params);
    return true;
  }
}
