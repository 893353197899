import { InfrontSDK } from '@infront/sdk';

export enum SymbolStatusLabelClass {
  'wt-symbol-status__label--undefined' = 0,
  'wt-symbol-status__label--suspended' = 1,
  'wt-symbol-status__label--attention' = 2,
  'wt-symbol-status__label--after-market' = 3,
  'wt-symbol-status__label--pre-market' = 4,
  'wt-symbol-status__label--auction' = 5,
  'wt-symbol-status__label--open' = 6,
  'wt-symbol-status__label--closed' = 7,
}

export enum DisplayStatusMap {
  'undefined' = 0,
  'suspended' = 1,
  'attention' = 2,
  'after-market' = 3,
  'pre-market' = 4,
  'auction' = 5,
  'open' = 6,
  'close' = 7,
}

export const fields = [
  InfrontSDK.SymbolField.PreSymbolStatusText,
  InfrontSDK.SymbolField.DisplaySymbolStatus,
  InfrontSDK.SymbolField.DisplaySymbolStatusCode,
];
