import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatListOption } from '@angular/material/list';

import { ColumnCategory } from '../grid/columns.model';

@Component({
  selector: 'wt-multi-select',
  templateUrl: './multi-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent {
  @Input() columnCategory: ColumnCategory;
  @Input() isDisabled: boolean;
  @Output() selectionChanged = new EventEmitter<MatListOption>();
}
