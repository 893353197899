import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WindowName } from '../../../../state-model/window.model';
import { SearchItemWindow } from './search-item-window.model';

@Component({
  selector: 'wt-search-item-window',
  templateUrl: './search-item-window.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchItemWindowComponent<TWindowName extends WindowName = WindowName> {
  @Input() searchItemWindow: SearchItemWindow<TWindowName>;
}
