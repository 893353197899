import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomDatePickerHeaderComponent } from './custom-date-picker-header.component';

@NgModule({
  declarations: [CustomDatePickerHeaderComponent],
  imports: [CommonModule],
  exports: [CustomDatePickerHeaderComponent],
})
export class CustomDatePickerHeaderModule {}
