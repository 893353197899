
export enum SidebarTab {
  GLOBAL = 1,
  WATCHLIST,
  TRADING,
}

export const SIDEBAR_CONTEXTS: SidebarTab[] = [
  SidebarTab.GLOBAL,
  SidebarTab.WATCHLIST,
  SidebarTab.TRADING
];
