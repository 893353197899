import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WTKWidgetWrapperComponent } from './wtk-widget-wrapper.component';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';


@NgModule({
  declarations: [WTKWidgetWrapperComponent],
  exports: [WTKWidgetWrapperComponent],
  imports: [
    CommonModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ]
})
export class WTKWidgetWrapperModule { }
