import { Logger } from '@vwd/ngx-logging';
import { DashboardDataEntity, DashboardEntity } from '../../internal/api/dashboards';
import { tryParseJsonObject } from '../../internal/utils/json';
import { isEmptyObject, removeUndefinedProperties, Writable } from '../../internal/utils/objects';
import { DashboardAttributes, DashboardFolderLevel, DashboardModel, DashboardType, WidgetDataModel, WidgetGroupsModel, WidgetLocationModel, WidgetModel, WidgetStructureModel } from '../../models';
import { FolderType, HttpDashboardModel, HttpWidgetHistoryModel } from './http-dashboard.models';
import { parentId } from './http-dashboard.templates';

/**
 * Widget storage model; this is basically the regular {@link WidgetModel},
 * but traditionally IM strips some of the properties if they are not "set"
 * (like the `groups` field).
 */
export interface HttpWidgetStorageModel {
  readonly type: string;
  readonly serializationVersion: number;
  readonly id: string;
  readonly data: WidgetDataModel;
  readonly location: WidgetLocationModel;
  readonly theme?: string;
  readonly groups?: WidgetGroupsModel;
}

export type HttpWidgetStructureStorageV0 = readonly HttpWidgetStorageModel[];

export interface HttpWidgetStructureStorageV1 {
  readonly version: 1;
  readonly widgets: readonly HttpWidgetStorageModel[];
}

export type HttpWidgetStructureStorage = HttpWidgetStructureStorageV0 | HttpWidgetStructureStorageV1;

type HttpDashboardStorageAttributes = Writable<DashboardAttributes & {
  index?: number;
  _hidden?: true;
  _link?: string;
}>;


export function storeToHistoryModel(dashboardId: string, dashboard_data: DashboardDataEntity | undefined): HttpWidgetHistoryModel {
  return {
    version: dashboard_data!.create_timestamp,
    meta: {
      source: 'http',
      id: dashboard_data!.id,
      dashboardId: dashboardId,
    }
  };
}

export function storageToStructureModel(structure: HttpWidgetStructureStorage): WidgetStructureModel {
  if (!structure) {
    return { widgets: [] };
  }
  // version = 0 (not specified)
  if (Array.isArray(structure)) {
    const v0Structure = structure as HttpWidgetStructureStorageV0;
    return { widgets: v0Structure?.map(storageToWidgetModel) ?? [] };
  }

  if (typeof structure === 'object' && 'version' in structure) {
    // version = 1
    if (structure.version === 1) {
      return {
        ...structure,
        widgets: structure.widgets?.map(storageToWidgetModel) ?? [],
      };
    }
    // version = unknown
    throw new Error(`Unsupported structure configuration version: ${structure.version}, expected 1.`);
  }

  throw new Error('Unsupported structure configuration.');
}

export function structureToStorage(structure: WidgetStructureModel): HttpWidgetStructureStorage {
  return {
    ...structure,
    version: 1,
    widgets: structure.widgets?.map(widgetToStorage) ?? [],
  };
}

function storageToWidgetModel(config: HttpWidgetStorageModel): WidgetModel {
  return {
    ...config,
    data: config.data,
    groups: config.groups ?? {},
  };
}

function widgetToStorage(model: WidgetModel): HttpWidgetStorageModel {
  let copy: Writable<HttpWidgetStorageModel> | undefined;
  // TODO: check if we need more fixups
  if (isEmptyObject(model.groups)) {
    copy = { ...model };
    delete copy.groups;
  }
  if (model.theme === 'inherit' || model.theme === 'default' || model.theme === null) {
    copy ??= { ...model };
    delete copy.theme;
  }
  return copy ?? model;
}

export function storeToModel(folderType: FolderType, data: DashboardEntity, logger: Logger): HttpDashboardModel {
  const attributes = tryParseJsonObject(data.attributes, logger) as HttpDashboardStorageAttributes ?? {};

  let index: number | undefined = undefined;
  let hidden = false;
  let linksTo: string | undefined = undefined;

  if ('index' in attributes) {
    if (typeof attributes.index === 'number') {
      index = attributes.index;
    }
    delete attributes.index;
  }

  if ('_hidden' in attributes) {
    hidden = attributes._hidden === true;
    delete attributes._hidden;
  }

  if ('_link' in attributes) {
    linksTo = attributes._link;
    delete attributes._link;
  }

  if (data.type === 'FOLDER') {
    return {
      id: data.id,
      attributes: attributes,
      hidden: hidden,
      name: data.name,
      index: index,
      parentId: data.parent_id ?? parentId(folderType, data.realm, data.sub_tenant_id, data.user_id),
      isLink: false,
      owner: {
        realm: data.realm,
        subTenantId: data.sub_tenant_id,
        userId: data.user_id,
      },
      security: {
        canAddChildren: data.can_add_children,
        canDelete: data.can_delete,
        canDeleteChildren: data.can_add_children,
        canEdit: data.can_edit,
        canMove: data.can_move,
      },
      type: DashboardType.FOLDER,
      meta: {
        source: 'http',
        role: folderType,
        virtual: false,
        raw: data,
      },
      level: folderLevelFor(folderType),
      // For any folder we definitely did load, we know subfolders are loaded too (no partial loads are possible)
      childrenLoadState: 'loaded',
    };
  }
  else {
    return {
      id: data.id,
      attributes: attributes,
      hidden: hidden,
      isDataDraft: data.is_data_draft ?? true,
      dataCreatedAt: data.data_create_timestamp,
      name: data.name,
      index: index,
      parentId: data.parent_id ?? parentId(folderType, data.realm, data.sub_tenant_id, data.user_id),
      isLink: !!linksTo,
      linksTo: linksTo,
      owner: {
        realm: data.realm,
        subTenantId: data.sub_tenant_id,
        userId: data.user_id,
      },
      security: {
        canDelete: data.can_delete,
        canEdit: data.can_edit,
        canEditWidgets: data.can_edit && !linksTo,
        canMove: data.can_move,
      },
      sharing: {
        canShare: data.can_share,
        isShared: data.is_shared,
        isSharedWithMe: data.is_shared_for_me,
      },
      type: data.type === 'TEMPLATE' ? DashboardType.TEMPLATE : DashboardType.DASHBOARD,
      meta: {
        source: 'http',
        role: folderType,
        virtual: false,
        raw: data,
      },
      level: folderLevelFor(folderType),
    };
  }
}

export type HttpDashboardModelChanges = Partial<Pick<DashboardModel, 'attributes' | 'index' | 'hidden'>>;

/**
 * Returns the JSON string containing the store attributes, returning
 * `undefined` when the attribute need not be touched.
 *
 * There are 2 invocations:
 * * `dashboard === null`: initial creation of a dashboard;
 *   `changes` will contain the initial attributes
 * * `dashboard !== null`: an update of a dashboard.
 *
 * Primary concern of this function is to map the `index` and `_hidden`
 * attributes correctly -- they are lifted from a dashboard entity to store
 * attributes, and lowered from store attributes to a dashboard entity.
 *
 * If there are no (relevant) changes, `undefined` is returned, which
 * allows the POST and PUT calls to omit the parameter.
 */
export function createStoreAttributes(dashboard: HttpDashboardModel | null, changes: HttpDashboardModelChanges, extraAttributes: DashboardAttributes | undefined): string | undefined {
  const hasChanges = 'attributes' in changes || 'index' in changes || 'hidden' in changes;

  if (hasChanges) {
    const newAttributes: HttpDashboardStorageAttributes = removeUndefinedProperties({
      ...dashboard?.attributes,
      ...changes.attributes,
      index: 'index' in changes ? changes.index : dashboard?.index,
      _hidden: ('hidden' in changes ? changes.hidden : dashboard?.hidden) || undefined,
      _link: dashboard?.linksTo,
      ...extraAttributes,
    });

    return JSON.stringify(newAttributes);
  } else if (extraAttributes) {
    return JSON.stringify({ ...dashboard?.attributes, ...extraAttributes });
  }

  return undefined;
}

function folderLevelFor(folderType: FolderType): DashboardFolderLevel {
  switch (folderType) {
    case 'GLOBAL':
      return DashboardFolderLevel.GLOBAL;
    case 'COMPANY':
      return DashboardFolderLevel.COMPANY;
    case 'SUBTENANT':
      return DashboardFolderLevel.SUBTENANT;
    case 'SHARED':
      return DashboardFolderLevel.SHARED;
    case 'USER':
      return DashboardFolderLevel.PERSONAL;
    default:
      return DashboardFolderLevel.OTHER;
  }
}
