import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Infront, InfrontSDK } from '@infront/sdk';
import { KeycloakAuthService } from '@vwd/keycloak-auth-angular';
import { LogService } from '@vwd/ngx-logging';
import { BehaviorSubject, Observable, combineLatest, from, iif, merge, of, throwError } from 'rxjs';
import { distinctUntilKeyChanged, map, share, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { SdkRequestsService } from '../../services/sdk-requests.service';
import { SdkService } from '../../services/sdk.service';
import { StoreService } from '../../services/store.service';
import { TradingPositionsService } from '../../services/trading-positions.service';
import { TradingService } from '../../services/trading.service';
import { Column } from '../../shared/grid/columns.model';
import { NewsStoryDialogComponent } from '../../shared/news-story-dialog/news-story-dialog.component';
import { ProgressService, trackProgress } from '../../shared/progress';
import { NewsWidget, Widget } from '../../state-model/widget.model';
import { DashboardWindow, NewsType, NewsWindow, isNewsTypeWindow, isWatchlistWindow } from '../../state-model/window.model';
import { structuresAreEqual } from '../../util/equality';
import { filterUndefined } from '../../util/rxjs';
import { multiInstrumentColumns, singleInstrumentColumns } from './news.columns';
import { HeadlineIcons, NewsChunkSize, NewsHeadline, NewsSource, NewsSourceType } from './news.model';
import { FeedFilterItem } from '../../typings/models/feed-filterable';

// eslint-disable-next-line no-null/no-null, no-restricted-syntax
const FAULTY_NEWS_STORY_BODY = [undefined, '</span>', null, ''];

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  private readonly sdkRequestsService: SdkRequestsService = inject(SdkRequestsService);
  private readonly sdkService: SdkService = inject(SdkService);
  private readonly storeService: StoreService = inject(StoreService);
  private readonly newsStoryDialog: MatDialog = inject(MatDialog);
  private readonly keycloak: KeycloakAuthService = inject(KeycloakAuthService);
  private readonly http: HttpClient = inject(HttpClient);
  private readonly logService: LogService = inject(LogService);
  private readonly tradingService: TradingService = inject(TradingService);
  private readonly tradingPositionsService: TradingPositionsService = inject(TradingPositionsService);

  private readonly itemsInScrollAction = new BehaviorSubject<number>(NewsChunkSize);
  private cache: NewsHeadline[] = [];
  private readonly feedFilterItemsAction = new BehaviorSubject<FeedFilterItem[]>([]);
  private readonly logger = this.logService.openLogger('services/news');
  readonly feedFilterItems$ = this.feedFilterItemsAction.asObservable();

  readonly columns$ = (widget: NewsWidget): Observable<Column[]> =>
    this.storeService
      .windowByWidget$(widget)
      .pipe(
        distinctUntilKeyChanged('settings', structuresAreEqual),
        map((window: DashboardWindow) =>
          // To be outsourced into component and evaluated stream based
          isWatchlistWindow(window) || (isNewsTypeWindow(window) && ['Watchlist', 'Portoflio'].includes(window.settings.newsType))
            ? multiInstrumentColumns
            : singleInstrumentColumns
        )
      );

  readonly filteredHeadlines$ = (widget: NewsWidget, progress?: ProgressService): Observable<NewsHeadline[]> =>
    combineLatest([this.newsHeadlinesCachedOrNew$(widget, progress), this.storeService.widget$(widget), this.itemsInScrollAction]).pipe(
      withLatestFrom(this.newsSource$(widget)),
      map(([[headlines, widget, itemsInScroll], source]) => {
        // filter news by filter-text or types
        const filteredHeadlines = this.filterHeadlines(widget, headlines, source.sourceType);
        this.feedFilterItemsAction.next(this.uniqueFeeds(headlines, filteredHeadlines));
        // ! removal of duplicates, should ideally be done by the sdk
        const result = filteredHeadlines.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
        // sort the news items by `dateTime` desc, newest items to start of array.
        // Without this sorting and in case we have more than `itemsInScroll` news items
        // all news items added later by streaming will not be part of the sliced result
        // and there will be no streaming updates visible in news-widget in such case!
        // Please note: sorting by `dateTime` is also done in ag-grid and also still necessary!
        result.sort((a, b) => (b.dateTime?.getTime() ?? -1) - (a.dateTime?.getTime() ?? -1));
        // return a slice of only the first `itemsInScroll` items
        return result.slice(0, itemsInScroll).map((item) => ({ index: item.id, ...item }));
      }),
      shareReplay(1)
    );

  readonly newsType$ = (widget: Widget): Observable<NewsType> =>
    this.storeService.windowByWidget$(widget).pipe(
      distinctUntilKeyChanged('settings', structuresAreEqual),
      map((w) => {
        if (w.name === 'WatchlistWindow') {
          return 'Watchlist'; // news widget in watchlist window always uses watchlist as source
        }
        return (w as NewsWindow).settings.newsType || 'Instrument';
      })
    );

  openNewsStory(newsHeadline: NewsHeadline): void {
    if (newsHeadline.url) {
      // Keycloak token needs to be used for displaying PDFs from docs.infrontservices.com without login dialog!
      if (newsHeadline.url.includes('https://docs.infrontservices.com/doc/get')) {
        from(this.keycloak.getToken()).pipe(
          switchMap((token) => {
            if (token == undefined) {
              return throwError(() => new Error('Received no authentication token, therefore can not display the news article!'));
            }
            return this.http.get(newsHeadline.url, {
              responseType: 'blob',
              headers: {
                'Content-type': 'application/pdf',
                'Authorization': `Bearer ${token}`,
              },
            });
          }),
          tap((blob) => {
            const blobUrl = window.URL.createObjectURL(blob);
            window.open(blobUrl, "_blank")?.focus();
          }),
          take(1),
        ).subscribe();
        return;
      }
      window.open(newsHeadline.url, '_blank')?.focus();
      return;
    }
    if (newsHeadline.isFlash) {
      return;
    }
    if (newsHeadline.hasBody) {
      const newsStoryOptions: InfrontSDK.NewsStoryOptions = {
        id: newsHeadline.id,
        feed: newsHeadline.feed as number,
        onData: (newsStory: InfrontSDK.NewsStory) => {
          if (!FAULTY_NEWS_STORY_BODY.includes(newsStory.body) && newsStory.headline?.headline != undefined) {
            this.openNewsStoryModal(newsStory); // opens the news stories dialog (component)
          }
        },
      };
      this.fetchNewsStory(newsStoryOptions);
      return; // NOSONAR
    }
  }

  private readonly newsSource$ = (widget: Widget): Observable<NewsSource> => {
    const sourceMap = {
      Instrument: this.sdkRequestsService.windowInstrument$(widget, { filterInvalid: false }).pipe(
        map((source) => ({ source: source ? [source] : [], sourceType: 'instrument' as const } as NewsSource))
      ),
      Watchlist: this.sdkRequestsService.selectedWatchlist$(widget).pipe(
        filterUndefined(),
        map(({ items: source }) => ({ source, sourceType: 'watchlist' as const } as NewsSource))
      ),
      Portfolio: this.tradingService.tradingConnected$.pipe(
        switchMap((connected) => connected ? this.tradingPositionsService.portfolioInstruments$ : of([])),
        map((source) => ({ source, sourceType: 'portfolio' as const }) as NewsSource)
      ), // placeholder
      Country: this.storeService
        .windowByWidget$(widget)
        .pipe(
          distinctUntilKeyChanged('settings', structuresAreEqual),
          map((window) => ({ source: (window as NewsWindow).settings.feeds, sourceType: 'feed' as const } as NewsSource))
        ),
    };

    return this.newsType$(widget).pipe(switchMap((type) => sourceMap[type]));
  };

  private readonly newsHeadlinesCachedOrNew$ = (widget: Widget, progress?: ProgressService): Observable<Array<NewsHeadline>> => merge(of(this.cache), this.newsHeadlines$(widget, progress));

  // get headlines then request feedMetaData for all feeds used in the headlines then map together as a single array of our own NewsHeadline objects
  private readonly newsHeadlines$ = (widget: Widget, progress?: ProgressService): Observable<Array<NewsHeadline>> =>
    this.newsSource$(widget).pipe(switchMap((s) => iif(() => s.source != undefined && s.source.length > 0, this.sdkRequestHeadlines$(s, progress), of([]))));

  readonly sdkRequestHeadlines$ = (newsSource: NewsSource, progress?: ProgressService): Observable<NewsHeadline[]> =>
    this.sdkService
      .getArray$(InfrontSDK.newsHeadlines, {
        source: newsSource.source,
        limit: 500,
      })
      .pipe(
        trackProgress({ label: 'sdkRequestHeadlines$ (sources)', optional: true, progress }),
        switchMap((headlines) =>
          this.sdkService
            .getArray$(InfrontSDK.feedInfo, {
              infoType: InfrontSDK.FeedInfoType.MetaData,
              feed: headlines.map((hl: Infront.HeadlineItem) => hl.feed),
            })
            .pipe(
              trackProgress({ label: 'sdkRequestHeadlines$ (headlines)', optional: true, progress }),
              map((feedMetadataList) =>
                headlines.map((hl: Infront.HeadlineItem, i) => {
                  const wtHeadlineItem: NewsHeadline = {
                    dateTime: hl.dateTime,
                    headline: hl.headline,
                    cellHeadline: this.getHeadlineIcon(hl) + hl.headline,
                    feed: hl.feed,
                    feedShortName: (feedMetadataList[i] as InfrontSDK.FeedInfo).feedCode,
                    feedLongName: (feedMetadataList[i] as InfrontSDK.FeedInfo).description,
                    isResearchNews: hl.isResearchNews,
                    isFlash: hl.isFlash,
                    id: hl.id,
                    symbols: this.headlineSymbols(hl.symbols, newsSource),
                    url: hl.url,
                    hasBody: hl.hasBody,
                  };
                  return wtHeadlineItem;
                })
              ),
              tap((result) => {
                if (result.length > 0) { // TODO: should we not also replace cache if result is empty, else cache contains wrong data?
                  this.cache = result;
                }
              })
            )
        ),
        share()
      );

  private getHeadlineIcon(hl: Infront.HeadlineItem): string {
    return hl.url ? HeadlineIcons.url : '';
  }

  private headlineSymbols(allSymbols: InfrontSDK.SymbolId[], newsSource: NewsSource): InfrontSDK.SymbolId[] {
    if (newsSource.sourceType === 'instrument') {
      return [];
    }
    if (newsSource.sourceType === 'instruments') {
      return allSymbols;
    }
    return allSymbols.filter((symbol) =>
      newsSource.source?.map((item: InfrontSDK.SymbolId | number) => {
        const sourceSymbol = item as InfrontSDK.SymbolId; // We know we're dealing with symbol type based on above check on sourceType
        return sourceSymbol.feed === symbol.feed && sourceSymbol.ticker === symbol.ticker;
      })
    );
  }

  textFilter(textFilter: string, widget: Widget): void {
    this.storeService.updateWidget(widget, { settings: { ...widget.settings, textFilter } });
  }

  showNews(showNews: boolean, widget: Widget): void {
    this.storeService.updateWidget(widget, { settings: { ...widget.settings, showNews } });
  }

  showResearchNews(showResearchNews: boolean, widget: Widget): void {
    this.storeService.updateWidget(widget, { settings: { ...widget.settings, showResearchNews } });
  }

  showFlashNews(showFlashNews: boolean, widget: Widget): void {
    this.storeService.updateWidget(widget, { settings: { ...widget.settings, showFlashNews } });
  }

  showMore(): void {
    this.itemsInScrollAction.next(this.itemsInScrollAction.getValue() + NewsChunkSize);
  }

  resetScroll(): void {
    this.itemsInScrollAction.next(NewsChunkSize);
  }

  private filterHeadlines(widget: Widget, headlines: NewsHeadline[], sourceType: NewsSourceType) {
    const settings = (widget as NewsWidget).settings;
    let filteredHeadlines = headlines;
    if (settings.textFilter) {
      filteredHeadlines = filteredHeadlines.filter((hl) => hl.headline.toLowerCase().includes(settings.textFilter.toLowerCase()));
    }
    if (!settings.showNews) {
      filteredHeadlines = filteredHeadlines.filter((hl) => !!hl.isResearchNews || !!hl.isFlash);
    }
    if (!settings.showFlashNews) {
      filteredHeadlines = filteredHeadlines.filter((hl) => !hl.isFlash);
    }
    if (!settings.showResearchNews) {
      filteredHeadlines = filteredHeadlines.filter((hl) => !hl.isResearchNews);
    }
    if (sourceType === 'watchlist') {
      filteredHeadlines = filteredHeadlines.filter((hl) => !!hl.symbols);
    }
    return filteredHeadlines;
  }

  private readonly uniqueFeeds = (headlines: NewsHeadline[], filteredHeadlines: NewsHeadline[]): FeedFilterItem[] => {
    const filtered = [...new Set(headlines.map((hl) => hl.feed))].map((feed: number) => {
      return {
        feed,
        shortName: headlines.find((item) => item.feed === feed)?.feedShortName ?? '',
        longName: headlines.find((item) => item.feed === feed)?.feedLongName ?? '',
        active: filteredHeadlines.map((hl) => hl.feed).includes(feed),
      };
    });
    return filtered;
  };

  fetchNewsStory(newsStoryOptions: InfrontSDK.NewsStoryOptions): void {
    this.sdkService.sdk$.subscribe((sdk: InfrontSDK.SDK) => sdk.get(InfrontSDK.Requests.newsStory(newsStoryOptions)));
  }

  openNewsStoryModal(newsStory: InfrontSDK.NewsStory): void {
    this.newsStoryDialog
      .open(NewsStoryDialogComponent, {
        data: {
          newsHeadline: newsStory.headline,
          body: newsStory.body,
        },
        height: '70%',
        width: '70%',
      })
      .addPanelClass('cdk-overlay-panel__news-story');
  }
}
