<div *ngIf="grids$ | async as grids" class="wt-global-tab">
  <div
    *ngFor="let gridData of grids"
    class="wt-global-tab__grid"
    [style.height.px]="gridData.symbols.length * GridHeightsPx.row + GridHeightsPx.header">
    <!-- I believe the answer to the TO DO! below is using display: flex; flex-direction: column; and by using wt-full-height on the parents -->

    <!-- TO DO! This table hight calculation needs to be reviewed. Currently there is no way to calculate it dynamically or relatively to parent container. Calculation contains number of columns, multiplied with actual row height + table header and table bottom margin -->
    <!-- Reason for this calculation is the AG grid. It requires to parent container to be 100% height in order to work, which leads to some problems displaying tables in the row, one by one -->
    <wt-symbols-grid
      [symbols]="gridData.symbols"
      [gridRef]="gridData.gridRef"
      [gridSettings]="gridSettings"
      [context]="{ forceOpenInstrumentDashboard: true }"
      [suppressHorizontalScroll]="true"
      (cellClickedEvent)="onGridCellClick($event)"></wt-symbols-grid>
  </div>
</div>