import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { PipesModule } from '../../pipes/pipes.module';
import { ColumnPickedListComponent } from './column-picked-list.component';

@NgModule({
  declarations: [ColumnPickedListComponent],
  imports: [CommonModule, DragDropModule, MatIconModule, PipesModule],
  exports: [ColumnPickedListComponent],
})
export class ColumnPickedListModule {}
