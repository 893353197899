<form class="wt-dialog wt-watchlist-dialog" method="dialog">
  <div class="wt-flex-row wt-flex-align-middle wt-flex-collapse wt-dialog__title">
    <div class="wt-flex-column wt-flex-shrink wt-dialog-icon">
      <mat-icon svgIcon="add_box"></mat-icon>
    </div>

    <div class="wt-flex-column">
      <h1 class="wt-h1">{{ 'NEW_DIALOG.ADD_WATCHLIST.TITLE' | translate}}</h1>
    </div>
  </div>

  <div mat-dialog-content>
    <mat-form-field floatLabel="never">
      <input class="wt-dialog-input" matInput [(ngModel)]="data.input.title" name="name" required minlength="4"
        maxlength="30"
        #name="ngModel" />
    </mat-form-field>

    <div *ngIf="providerReadAccess.length > 1">
      <label id="example-radio-group-label">{{ 'NEW_DIALOG.ADD_WATCHLIST.SELECT_PROVIDER' | translate }}</label>
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="wt-dialog-watchlist__radio-group"
        [(ngModel)]="data.input.provider"
        [ngModelOptions]="{standalone: true}">
        <mat-radio-button *ngFor="let provider of providerReadAccess"
          class="example-radio-button"
          [value]="provider.provider">
          {{provider.providerName}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="wt-action-buttons">
    <div class="wt-flex-row wt-flex-collapse wt-flex-align-right">
      <div class="wt-flex-column wt-flex-shrink">
        <button class="wt-button wt-button--cancel" type="button" (click)="onCancel()">
          {{ 'NEW_DIALOG.CLOSE' | translate}}
        </button>
      </div>

      <div class="wt-flex-column wt-flex-shrink">
        <button class="wt-button wt-button--primary" type="submit" [mat-dialog-close]="data.input">
          {{ 'NEW_DIALOG.SUBMIT' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>