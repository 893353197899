import { structuresAreEqual } from './equality';

export const filterDuplicates = <T>(array: T[], equals: (a: T, b: T) => boolean = (a, b) => a === b) => {
  return array.filter((b, i) => array.findIndex((a) => equals(a, b)) === i);
};

export const addIsNewProperty = <T extends { id: string }>(prev: T[], curr: T[]): T[] =>
  curr.map((item) => (prev.map((itm) => itm.id).includes(item.id) ? ({ ...item, isNew: false } as T) : item));

export const arrToStringId = (ids: string[]): string =>
  ids.reduce((acc, id, i) => {
    acc = i !== 0 ? acc + '#' + id : acc + id;
    return acc;
  }, '');

enum ArrayMoveType {
  FRONT_TO_BACK,
  BACK_TO_FRONT,
}

export const moveItem = (arr: unknown[], index: number, newIndex: number): void => {
  if (!(index in arr) || !(newIndex in arr) || index === newIndex) {
    return;
  }
  const moveItem = arr[index];
  let prevItem;
  let tmpItem;
  const arrayMoveType = index < newIndex ? ArrayMoveType.FRONT_TO_BACK : ArrayMoveType.BACK_TO_FRONT;

  // @TODO refactor with iteration function
  if (arrayMoveType === ArrayMoveType.FRONT_TO_BACK) {
    // item is moved from the front to the back
    for (let i = 0; i < arr.length; i++) {
      if (i === index) {
        const nextItemIndex = i + 1;
        // start
        arr[i] = arr[nextItemIndex];

        if (nextItemIndex === newIndex) {
          arr[nextItemIndex] = moveItem;
          return;
        }
      } else if (i === newIndex) {
        // end
        prevItem = arr[i];
        arr[i] = moveItem;
      } else if (i > newIndex) {
        tmpItem = arr[i];
        arr[i] = prevItem;
        prevItem = tmpItem;
      } else if (i > index) {
        // in between
        arr[i] = arr[i + 1];
      }
    }
  } else {
    // item is moved from the back to the front
    for (let i = 0; i < arr.length; i++) {
      if (i === newIndex) {
        // start
        prevItem = arr[i];
        arr[i] = moveItem;
      } else if (i === index) {
        // end
        arr[i] = prevItem;
        return;
      } else if (i > newIndex) {
        // in between
        tmpItem = arr[i];
        arr[i] = prevItem;
        prevItem = tmpItem;
      }
    }
  }
};

/** Ensures that all types of a union type are included in the allocated array */
export const arrayOfAll = <T>() => <U extends T[]>(
  array: U & ([T] extends [U[number]] ? unknown : 'Invalid')
) => array;

/**
 * Finds the last index in `value` of one of the specified values in
 * `valuesToMatch`, or `-1` if no match was found.
 *
 * @param value The string or array to search.
 * @param valuesToMatch Any matcher object that has an `includes` method, for
 *   example an array of values to search for, or a single string of characters
 *   to search for.
 */
export function lastIndexOfAny<T>(value: ArrayLike<T>, valuesToMatch: { includes(value: T): boolean }): number {
  for (let i = value.length - 1; i >= 0; i--) {
    if (valuesToMatch.includes(value[i])) {
      return i;
    }
  }
  return -1;
}

interface IsArrayFn {
  <T>(obj: T[] | T | null | undefined): obj is T[];
  <T>(obj: readonly T[] | T | null | undefined): obj is readonly T[];
  (obj: unknown): obj is unknown[];
}

export const isArray: IsArrayFn = Array.isArray;


export function arrayDifference<T>(arr1: T[], arr2: T[], idFields: string[]): T[] {
  const arr2Map = new Map<string, T>();

  arr2.forEach(obj => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const key = idFields.map(field => obj[field]).join("-");
    arr2Map.set(key, obj);
  });

  const difference = arr1.filter(obj1 => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const key = idFields.map(field => obj1[field]).join("-");
    const obj2 = arr2Map.get(key);
    return !structuresAreEqual(obj1, obj2);
  });

  return difference;
}

export type TupleOf<T, N extends number> = N extends N ? number extends N ? T[] : _TupleOf<T, N, []> : never;
type _TupleOf<T, N extends number, R extends unknown[]> = R['length'] extends N ? R : _TupleOf<T, N, [T, ...R]>;
