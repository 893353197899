

    <div *ngIf="{ value: isActive$ | async } as isActive" class="gridster-item-content context-menu__container" >

      <!-- trigger icon as replacement for real menu before the real menu is activated-->
      <mat-icon #matMenuTrigger class="gridster-item-content context-menu mat-icon-small context-menu-icon"
        fontIcon="more_vert" (click)="activateAction.next()" [ngClass]="{'context-menu-icon--inactive': isActive.value === true}" ></mat-icon>

      <!-- real menu only which is rendered after activation (for memory and performance as there can be many menu items in a list)-->
      <ng-container *ngIf="isActive.value">
        <ng-container *ngIf="menuItems$ | async as menuItems">
          <mat-icon #matMenuTrigger class="gridster-item-content context-menu mat-icon-small context-menu-trigger" [matMenuTriggerFor]="panel.menu"
            fontIcon="more_vert" ></mat-icon>
          <wt-menu-panel #panel [items]="menuItems" [context]="context" (itemClick)="onItemClick($event)"></wt-menu-panel>
        </ng-container>
      </ng-container>
    </div>
    <ng-container *ngIf="autoOpenMenuAfterActivation$ | async"></ng-container>




