import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wt-distribution-bar-cell',
  template: `
    <div
      *ngIf="showBar"
      class="wt-distribution-bar-cell__bar"
      [ngClass]="{ 'wt-distribution-bar-cell__symbol-link': hasSymbol }"
      title="{{ weight | number: '1.2' }}%" [style.width.%]="pct"
    >
      <span class="wt-distribution-bar-cell__bar-label">{{ name }}</span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DistributionBarCellComponent {
  name: string;
  weight: number;
  pct: number;
  hasSymbol: boolean;
  showBar = true;

  agInit(params: ICellRendererParams): void {
    if (params.data.name === 'SUMMARY_BAR') {
      this.showBar = false;
      return;
    }
    this.name = params.data.name as string;
    this.weight = params.data.weight as number;
    this.pct = params.data.maxWeight ? (params.data.weight / params.data.maxWeight) * 100 : 0;
    this.hasSymbol = params.data.symbol != undefined;
  }
}
