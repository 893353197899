import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { DashboardWindowModule } from '../../dashboard-window/dashboard-window.module';
import { NewsStoryDialogModule } from '../../shared/news-story-dialog/news-story-dialog.module';
import { InfiniteScrollModule } from '../../util/components/infinite-scroll/infinite-scroll.module';
import { StaticColumnsGridModule } from '../../wrappers/grid-wrappers/static-columns-grid/static-columns-grid.module';
import { FeedFilterComponent } from './feed-filter/feed-filter.component';
import { HeadlineCellComponent } from './headline-cell/headline-cell.component';
import { NewsComponent } from './news.component';
import { TextMarkerPipe } from './text-marker.pipe';
import { TradingLoginModule } from '../../shared/trading-login/trading-login.module';

@NgModule({
  imports: [
    CommonModule,
    InfiniteScrollModule,
    MatInputModule,
    MatIconModule,
    StaticColumnsGridModule,
    MatCheckboxModule,
    MatTooltipModule,
    FormsModule,
    DashboardWindowModule,
    MatFormFieldModule,
    NewsStoryDialogModule,
    TradingLoginModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [],
  declarations: [NewsComponent, FeedFilterComponent, HeadlineCellComponent, TextMarkerPipe],
})
export class NewsModule {
  static readonly components = {
    news: NewsComponent,
  };
}
