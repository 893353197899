import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { PricePipesModule } from '@vwd/ngx-price-pipes';

import { InstrumentFocusComponent } from './instrument-focus.component';
import { PerformanceBarModule } from '../performance-bar/performance-bar.module';
import { DirectivesModule } from '../../directives/directives.module';

@NgModule({
  declarations: [InstrumentFocusComponent],
  imports: [
    CommonModule,
    PerformanceBarModule,
    MatIconModule,
    DirectivesModule,
    PricePipesModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [InstrumentFocusComponent]
})
export class InstrumentFocusModule { }
