import { Injectable } from '@angular/core';
import { DashboardServicesProvider } from '@infront/ngx-dashboards-fx';

import { WTBuiltInDashboardProvider } from './builtin-dashboard.provider';
import { WTBuiltInWidgetDataProvider } from './builtin-widget-data.provider';

@Injectable({ providedIn: 'root' })
export class WTBuiltInDashboardServicesProvider implements DashboardServicesProvider {
  constructor(
    readonly dashboards: WTBuiltInDashboardProvider,
    readonly widgets: WTBuiltInWidgetDataProvider,
  ) { }
}
