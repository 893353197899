import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';

import { TradingLoginComponent } from './trading-login.component';

@NgModule({
  declarations: [TradingLoginComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [TradingLoginComponent],
})
export class TradingLoginModule { }
