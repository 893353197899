<div class="wt-flex-row wt-flex-collapse" *ngIf="myAlerts$ | async as myAlerts">
  <!-- todo: i18n -->

  <!-- todo: determine message text -->
  <div class="wt-flex-column" *ngIf="!listVisibility.show">
    <div class="wt-alerts-list__no-alerts">
      {{ "ALERTS_LIST.NO_ALERTS" | translate }}
    </div>
  </div>

  <div class="wt-flex-column" *ngIf="listVisibility.show">
    <ul class="wt-list">
      <li
        *ngFor="let alert of myAlerts; trackBy: alertIdentifier"
        class="wt-alerts-list__item"
        [ngClass]="{ 'wt-alerts-list__item--inactive': !alert.enabled }"
        [@alertDeleteAnimation]
        [@.disabled]="!alert.isDeleting"
        (click)="editAlert(alert)">

        <ng-container *ngIf="alert.type === AlertType.Instrument">
          <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
            <div class="wt-flex-column wt-flex-shrink">
              <div class="wt-alerts-list__item-flag">
                <wt-country-flag [country]="alert.countryFlag" [size]="16"></wt-country-flag>
              </div>
            </div>

            <div class="wt-flex-column wt-flex-shrink">
              <button type="button" class="wt-alerts-list__item-ticker" addDashboard
                [addDashboardInstrument]="{ feed: alert.feed, ticker: alert.ticker }"
                (click)="$event.stopPropagation()">{{ alert.ticker }}</button>
            </div>

            <div class="wt-flex-column">
              <span class="wt-alerts-list__item-faded">{{ alert.feedExchange }}</span>
            </div>

            <div class="wt-flex-column wt-flex-shrink">
              <span class="wt-alerts-list-type wt-alerts-list__item-faded">
                <ng-container *ngIf="!alert.enabled; else triggerType">
                  Inactive
                </ng-container>

                <ng-template #triggerType>
                  {{ triggerTypeMap[alert.triggerType] | translate : triggerTypeMap[alert.triggerType] | translate }}
                </ng-template>
              </span>
            </div>

            <div class="wt-flex-column wt-flex-shrink">
              <div class="wt-alerts-list__item-delete">
                <mat-icon (click)="onDelete(alert, $event)" fontIcon="delete"></mat-icon>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- first row watchlist alert -->
        <ng-container *ngIf="alert.type === AlertType.List">
          <div class="wt-flex-row wt-flex-collapse">
            <div class="wt-flex-column wt-flex-shrink">
              <mat-icon class="wt-alerts-list__item-icon" svgIcon="visibility"></mat-icon>
            </div>

            <div class="wt-flex-column">
              <span class="wt-alerts-list__item-label"
                addDashboard
                [addDashboardInstrument]="{ feed: alert.feed, ticker: alert.ticker }" [title]="alert.watchlist">
                {{ alert.watchlist }}
              </span>

              <span class="wt-alerts-list__item-faded">{{ alert.feedExchange }}</span>
            </div>

            <div class="wt-flex-column wt-flex-shrink">
              <span class="wt-alerts-list__item-faded">
                {{ triggerTypeMap[alert.triggerType] | translate }}
              </span>
            </div>

            <div class="wt-flex-column wt-flex-shrink">
              <div class="wt-alerts-list__item-delete">
                <mat-icon (click)="onDelete(alert, $event)" fontIcon="delete"></mat-icon>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- second row -->
        <div class="wt-flex-row wt-flex-align-spaced"
          [ngClass]="!alert.comment ? 'wt-alerts-list-extra-high-row' : 'wt-alerts-list-row'">
          <div class="wt-flex-column wt-alerts-list__item-description"
            [innerHTML]="alert.description | alertDescription: alert"></div>
        </div>

        <!-- third row -->
        <div class="wt-flex-row wt-flex-collapse wt-alerts-list__item-comment" *ngIf="alert.comment">
          <div class="wt-flex-column wt-flex-shrink">
            <mat-icon class="wt-alerts-list__item-comment-icon" fontIcon="chat"></mat-icon>
          </div>

          <div class="wt-flex-column">
            <span class="wt-alerts-list__item-comment-label">{{ alert.comment }}</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

<button type="button" class="wt-button wt-button--primary wt-alerts-list-add-alert" (click)="addAlert()">
  <mat-icon class="wt-alerts-list-add-alert-icon" fontIcon="notification_add"></mat-icon>
  {{ "GLOBAL.ALERT.ADD_ALERT" | translate }}
</button>