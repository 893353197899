import { InfrontUtil } from '@infront/sdk';

export const isLeapYear = (year: number): boolean => ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);

export const isToday = (someDate: Date | undefined): boolean => {
  if (someDate == undefined) {
    return false;
  }
  if (!(someDate instanceof Date)) {
    someDate = new Date(someDate);
  }
  return isNaN(someDate.getTime()) ? false : InfrontUtil.isToday(someDate);
};

export const datesAreOnSameDay = (first: Date, second: Date): boolean =>
  first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();

export const getDay = (date: Date): number | undefined => {
  if (typeof date.getMonth === 'function') {
    return date.getFullYear() + date.getMonth() + date.getDay();
  }
  return undefined;
};

export const daysDiff = (firstDate: Date, secondDate: Date): number => {
  const difference = firstDate.getTime() - secondDate.getTime();
  const daysDiff = Math.ceil(difference / (1000 * 3600 * 24));
  return daysDiff;
};

export const monthDiff = (first: Date, second: Date): number => {
  return first.getMonth() - second.getMonth() + 12 * (first.getFullYear() - second.getFullYear());
};

export const dateFromYYYYMMDD = (YYYYMMDD: number): Date | undefined => {
  const year = Math.floor(YYYYMMDD / 10000);
  const month = Math.floor((YYYYMMDD % 10000) / 100) - 1;
  const day = YYYYMMDD % 100;
  const date = new Date(year, month - 1, day);
  return date;
};

export const daysDiffFromYYYYMMDD = (date1: number, date2?: number): number => {
  // Extract year, month, and day from the input numbers
  const year1 = Math.floor(date1 / 10000);
  const month1 = Math.floor((date1 % 10000) / 100) - 1; // Adjust month to be zero-based
  const day1 = date1 % 100;

  // Create Date object for the first date
  const startDate = new Date(year1, month1, day1);

  let endDate = new Date();
  if (date2) {
    // Extract year, month, and day from the input numbers
    const year2 = Math.floor(date2 / 10000);
    const month2 = Math.floor((date2 % 10000) / 100) - 1; // Adjust month to be zero-based
    const day2 = date2 % 100;
    endDate = new Date(year2, month2, day2);
  }

  // Calculate the difference in milliseconds
  const timeDifference: number = endDate.getTime() - startDate.getTime();

  // Convert milliseconds to days
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
};

export const milliSecondsFromHHMMSS = (HHMMSS: string): number => {
  if (HHMMSS.length < 6) {
    HHMMSS = '0' + HHMMSS;
  }
  const parts = HHMMSS.split(/(..)/g).filter((s) => s);
  const milliSeconds = +parts[0] * 60 * 60 + (+parts[1] * 60 + +parts[2]) * 10000;
  return milliSeconds;
};

export const createDateTime = (date: Date, time: Date): Date | undefined => {
  if (!InfrontUtil.isDate(date)) { return undefined; }
  const dateStrings = date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/');
  const timeString = InfrontUtil.isDate(time) ? time.toLocaleTimeString('en-US', { hour12: false }) : '00:00:00';
  const timeZone = date.toTimeString().substring(12, 17);
  return new Date(`${dateStrings[2]}-${dateStrings[0]}-${dateStrings[1]}T${timeString}${timeZone}`);
};

export function getCurrentTime() {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

  return `${hours}:${minutes}:${seconds}:${milliseconds}`;
}
