import { InfrontSDK } from '@infront/sdk';

export const triggerTypeMap = {
  [InfrontSDK.AlertTriggerType.TriggerOnce]: 'ALERTS_LIST.ONCE',
  [InfrontSDK.AlertTriggerType.TriggerDaily]: 'ALERTS_LIST.DAILY',
};

export interface ServerAlert extends InfrontSDK.ServerAlert {
  countryFlag: string;
  feedExchange: string;
  ticker: string;
  feed: number;
  watchlist: string;
  isDeleting?: boolean; //mark for animation when inside a list
}

export interface AlertEvent extends InfrontSDK.AlertEvent {
  countryFlag: string;
  feedExchange: string;
  watchlist: string;
  key: string; // id + eventId required as unique id for AlertEvent
  isNew: boolean;
}
