import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'feedAccess' })
export class FeedAccessPipe implements PipeTransform {
  transform(access: string | undefined, type: 'short' | 'veryshort' = 'short'): string {
    return formatFeedAccess(access, type);
  }
}

const mapping = {
  'short': {
    'minute': 'min',
    'minutes': 'min',
    'hour': 'hour',
    'hours': 'hours',
    'day': 'day',
    'days': 'days',
  },
  'veryshort': {
    'minute': 'min',
    'minutes': 'min',
    'hour': 'hr',
    'hours': 'hrs',
    'day': 'day',
    'days': 'days',
  }
};

export const formatFeedAccess = (access: string | undefined, type: 'short' | 'veryshort' = 'short'): string => {
  if (!access) {
    return '';
  }

  let acc = access.toLowerCase();
  if (acc.includes('delayed')) {
    acc = acc.replace(/(^delayed)|(\s+)/g, '');
    if (acc.includes('1day')) {
      return 'EOD';
    }
    const match = acc.match(/(\d+)|(minute(s*)|hour(s*)|day(s*))/g) ?? [];
    const amount = match?.[0];
    const unit = mapping[type]?.[match?.[1] ?? ''] as string;
    if ((amount != undefined) && (unit != undefined)) {
      return `${amount} ${unit}`;
    }
  }
  return access;
};
