import { Injectable } from '@angular/core';
import { Infront, InfrontSDK, InfrontUtil } from '@infront/sdk';
import { LogService } from '@vwd/ngx-logging';
import { Observable, Subscriber } from 'rxjs';
import { filter, finalize, map, shareReplay, switchMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ToolkitService } from './toolkit.service';

@Injectable({
  providedIn: 'root',
})
export class SdkService {
  sdk$ = this.toolkitService.infrontUI$.pipe(
    map((infrontUI) => infrontUI.sdk),
    filter((sdk) => !!sdk),
    shareReplay()
  );

  private readonly logger = this.logService.openLogger('services/sdk');

  constructor(private readonly logService: LogService, private toolkitService: ToolkitService) { }

  // get the sdk onData payload as an observable array with type inference
  getArray$<TOptions extends { subscribe?: boolean }>(
    sdkMethod: (options: TOptions) => InfrontSDK.DataRequest,
    customOpts: Partial<TOptions> = {},
    initialUpdate?: InfrontUtil.InitialUpdate,
    caller?: string // optional debug
  ): Observable<Array<TInArray<TOptions>>> {
    let unsubscribeSDKGet: InfrontSDK.Unsubscribe;
    let unsubscribeSDKObserve: InfrontSDK.Unsubscribe;
    // arrayChanged$
    return this.sdk$.pipe(
      switchMap((sdk) =>
        new Observable((obs: Subscriber<Array<TInArray<TOptions>>>) => {
          const defaultOps = {
            subscribe: true,
            onData: (data: Array<TInArray<TOptions>> | Infront.ObservableArray<TInArray<TOptions>>) => {
              if (environment.development && caller) {
                // console.timeEnd(caller);
              }
              if (data instanceof Infront.ObservableArray) {
                unsubscribeSDKObserve = data.observe({
                  itemAdded: () => {
                    obs.next(data.data);
                  },
                  itemRemoved: () => {
                    obs.next(data.data);
                  },
                  reInit: () => {
                    obs.next(data.data);
                  },
                  itemChanged: () => {
                    if (!customOpts.subscribe) {
                      return;
                    }
                    obs.next(data.data);
                  },
                  itemMoved: () => {
                    obs.next(data.data);
                  },
                }, initialUpdate);
                obs.next(data.data);
              } else {
                obs.next(data);
              }
            },
            onError: (error: InfrontSDK.ErrorBase) => {
              if (environment.development) {
                this.logger.error('SDK Error getArray$:', error, 'caller:', caller, 'options:', JSON.stringify(options));
                // throw new Error(`SDK error:${error.title} ${error.parameters?.validation?.[0]}, caller: ${caller}`);
              }
            },
          };

          const options = {
            ...defaultOps,
            ...customOpts,
          } as unknown as TOptions;
          // debug
          if (environment.development && caller) {
            // this.logger.log(caller, 'options:', JSON.stringify(options));
            // console.time(caller);
          }
          try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
            unsubscribeSDKGet = sdk.get(sdkMethod(options));
          } catch (error) {
            this.logger.error('SDK Error sdk.get$:', error, 'caller:', caller, 'options:', JSON.stringify(options));
          }
        }).pipe(
          finalize(() => {
            this.logger.log('Unsubscribe getArray$ caller:', caller, customOpts);
            unsubscribeSDKGet?.();
            unsubscribeSDKObserve?.();
          })
        )
      )
    );
  }

  // get the sdk onData payload as an observable object or primitive with type inference
  // TOptions should extends InfrontSDK.DataRequestOptions
  getObject$<TOptions>(
    sdkMethod: (options: TOptions) => InfrontSDK.DataRequest,
    customOpts: Partial<TOptions> = {},
    caller?: string
  ): Observable<TSingle<TOptions>> {
    let unsubscribe: InfrontSDK.Unsubscribe;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.sdk$.pipe(
      switchMap((sdk) =>
        new Observable((obs: Subscriber<TSingle<TOptions>>) => {
          const defaultOpts = {
            subscribe: false,
            onData: (data: TSingle<TOptions>) => {
              if (environment.development && caller) {
                // console.timeEnd(caller);
              }
              obs.next(data);
            },
            onError: (error: InfrontSDK.ErrorBase) => {
              if (environment.development) {
                this.logger.log('Error getObject$:', error, 'caller:', caller, 'options:', JSON.stringify(options));
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                throw new Error(`SDK error: ${error.title} - ${error.parameters?.code}: ${error.parameters?.msg}`);
              }
            },
          };
          const options = { ...defaultOpts, ...customOpts } as unknown as TOptions;

          if (environment.development && caller) {
            // this.logger.log(caller, JSON.stringify(options));
            // console.time(caller);
          }
          unsubscribe = sdk.get(sdkMethod({ ...options }));
        }).pipe(
          finalize(() => {
            //this.logger.log('Unsubscribe getObject$ caller:', caller, customOpts);
            unsubscribe?.();
          })
        )
      )
    );
  }

  // private test1(): void {
  //   const opts = {
  //     action: InfrontSDK.WatchListContentAction.SaveWatchList,
  //     listName: 'whatever',
  //     symbolId: { feed: 18177, ticker: 'DNB' },
  //   };
  //   const retType = this.getObject$(InfrontSDK.watchListContent, opts);
  //   const retArr = this.getArray$(InfrontSDK.watchListContent, opts);
  // }

  // private test2(): void {
  //   const opts: Partial<InfrontSDK.SymbolDataOptions> = {
  //     id: { feed: 18177, ticker: 'DNB' },
  //     content: {
  //       Basic: true,
  //       CompanyMetaData: true,
  //     },
  //   };
  //   const retObj = this.getObject$(InfrontSDK.symbolData, opts);
  //   const retArr = this.getArray$(InfrontSDK.symbolData, opts); // return type should be 'never' here based on the id and the sdk conditional typing,
  //   // but sdk is an old version
  // }

  // private test3$(): void {
  //   const opts = {
  //     infoType: InfrontSDK.FeedInfoType.ExchangeCode,
  //     feed: 2343,
  //   };
  //   const retArr = this.getArray$(InfrontSDK.feedInfo, opts);
  //   const retArrCast = this.getArray$(InfrontSDK.feedInfo, opts) as Observable<string[]>; // simple cast as workaround
  //   const retObj = this.getObject$(InfrontSDK.feedInfo, opts);
  // }

  // private test4$(): void {
  //   const opts = {
  //     source: 34534,
  //   };
  //   const retArr = this.getArray$(InfrontSDK.newsHeadlines, opts);
  //   const retObj = this.getObject$(InfrontSDK.newsHeadlines, opts);
  // }

  // private test5$(): void {
  //   const opts = {};
  //   const retArr = this.getArray$(InfrontSDK.watchListTitles, opts);
  //   const retObj = this.getObject$(InfrontSDK.watchListTitles, opts);
  // }

  // private test6$(): void {
  //   const opts = { id: { feed: 18177, ticker: 'DNB' } };
  //   const retArr = this.getArray$(InfrontSDK.orderbook, opts);
  //   const retObj = this.getObject$(InfrontSDK.orderbook, opts);
  // }

  // private test7$(): void {
  //   const retArr = this.getArray$(InfrontSDK.sectors);
  //   const retObj = this.getObject$(InfrontSDK.sectors);
  // }

}

// type TReturnType<T> = T extends InfrontSDK.DataRequestOptions<infer RetType> ? RetType : any;

type TSingle<T> = T extends InfrontSDK.DataRequestOptions<infer RetType>
  ? RetType extends Infront.ObservableArray
  ? never
  : RetType extends Infront.SortedObservableArray
  ? never
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  : RetType extends Array<any>
  ? never
  : RetType
  : never;

type TInArray<T> = T extends InfrontSDK.DataRequestOptions<Array<infer RetType>>
  ? RetType
  : T extends InfrontSDK.DataRequestOptions<Infront.ObservableArray<infer RetType>>
  ? RetType
  : T extends InfrontSDK.DataRequestOptions<Infront.SortedObservableArray<infer RetType>>
  ? RetType
  : never;
