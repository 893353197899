import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';

import { InstrumentFocusModule } from '../../shared/instrument-focus/instrument-focus.module';
import { LabelListModule } from '../../shared/label-list/label-list.module';
import { PerformanceOverviewModule } from '../../shared/performance-overview/performance-overview.module';
import { ValuePairNumericModule } from '../../shared/value-pair-numeric/value-pair-numeric.module';
import { CountryFlagModule } from '../../shared/country-flag/country-flag.module';
import { FundRatingModule } from '../../shared/fund-rating/fund-rating.module';
import { SymbolHeaderInfoModule } from '../../shared/symbol-header-info/symbol-header-info.module';
import { SymbolStatusModule } from '../../shared/symbol-status/symbol-status.module';
import { InstrumentOverviewComponent } from './instrument-overview.component';

@NgModule({
  declarations: [InstrumentOverviewComponent],
  // todo: all dumb components that are to be reused in several places should be be imported as modules
  providers: [DatePipe],
  imports: [
    CommonModule,
    InstrumentFocusModule,
    ValuePairNumericModule,
    LabelListModule,
    PerformanceOverviewModule,
    CountryFlagModule,
    FundRatingModule,
    SymbolHeaderInfoModule,
    SymbolStatusModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [InstrumentOverviewComponent]
})
export class InstrumentOverviewModule { }
