<div [ngClass]="isCompact ? 'wt-search__compact-item' : 'wt-search__item'">

  <ng-container *ngIf="!isCompact; then symbolTemplate; else compactSymbolTemplate">
  </ng-container>

  <!-- START Search Item Symbol -->
  <ng-template #symbolTemplate>
    <div class="wt-flex-row wt-flex-collapse">
      <div *ngIf="showFlag" class="wt-flex-column wt-flex-shrink">
        <span class="wt-search__item-country-image">
          <wt-country-flag [country]="searchItemSymbol.isoCountry" [size]="16"></wt-country-flag>
        </span>
      </div>

      <div class="wt-flex-column wt-flex-shrink">
        <ng-container *ngIf="hasTickerClickEvent">
          <span
            class="wt-truncate wt-search__item-ticker wt-search__item-ticker--clickable"
            addDashboard
            [addDashboardInstrument]="{ feed: searchItemSymbol.feed, ticker: searchItemSymbol.ticker }"
            (addDashboardOnPreCallback)="tickerClick.emit()"
            (addDashboardOnProceedCallback)="closeDropdown.emit()">
            <ng-template [ngTemplateOutlet]="symbolNameTemplate"
              [ngTemplateOutletContext]="{ searchItemSymbol }"></ng-template>
          </span>
        </ng-container>
      </div>

      <div class="wt-flex-column">
        <span class="wt-search__item-currency">
          {{ searchItemSymbol.currency }}
        </span>
      </div>

      <div class="wt-flex-column wt-truncate" title="{{ searchItemSymbol.feedDescription }}">
        <span *ngIf="!hasFeedClickEvent || searchItemSymbol.feedIsHidden; else clickableFeed"
          class="wt-search__item-feed-description">
          {{ searchItemSymbol.feedDescription || '' }}
        </span>

        <ng-template #clickableFeed>
          <span
            [ngClass]="{'wt-search__item-feed-description--clickable': !searchItemSymbol.feedIsHidden, 'wt-search__item-feed-description': true}"
            addDashboardWindow
            [dashboardWindowToAdd]="searchItemSymbol.feed != undefined ? { name: 'MarketWindow', settings: { feed: searchItemSymbol.feed } } : undefined"
            (dashboardWindowOnPreCallback)="feedClick.emit()"
            (dashboardWindowOnProceedCallback)="closeDropdown.emit()">
            {{ searchItemSymbol.feedDescription || '' }}
          </span>
        </ng-template>
      </div>
    </div>

    <div class="wt-flex-row wt-flex-collapse">
      <div class="wt-flex-column wt-flex-shrink wt-truncate" title="{{ searchItemSymbol.fullName }}">
        <span class="wt-search__item-full-name">{{ searchItemSymbol.fullName }}</span>
      </div>

      <div class="wt-flex-column wt-truncate wt-search__item-state" title="{{ searchItemSymbol.access }}">
        <span *ngIf="searchItemSymbol.tradable" class="wt-search__item-tradable">
          {{ 'SEARCH.ITEM.SYMBOL.TRADABLE' | translate }}
        </span>
        <span class="wt-search__item-access">{{ searchItemSymbol.access | translate }}</span>
      </div>
    </div>
  </ng-template>
  <!-- END Search Item Symbol -->

  <!-- START Compact Search Item Symbol -->
  <ng-template #compactSymbolTemplate>
    <div class="wt-flex-row">
      <div class="wt-flex-column wt-truncate">
        <span *ngIf="showFlag" class="wt-search__compact-item-country-image"
          title="{{ searchItemSymbol.isoCountry }}">
          <wt-country-flag [country]="searchItemSymbol.isoCountry" [size]="16"></wt-country-flag>
        </span>

        <ng-template [ngTemplateOutlet]="symbolNameTemplate"
          [ngTemplateOutletContext]="{ searchItemSymbol }"></ng-template>

        <span class="wt-search__item-currency">
          {{ searchItemSymbol.currency }}
        </span>
      </div>

      <div class="wt-flex-column wt-truncate wt-flex-shrink" title="{{ searchItemSymbol.feedDescription }}">
        <span
          *ngIf="hasFeedClickEvent"
          class="wt-search__compact-item-market wt-search__compact-item-market--clickable"
          addDashboardWindow
          [dashboardWindowToAdd]="searchItemSymbol.feed != undefined ? { name: 'MarketWindow', settings: { feed: searchItemSymbol.feed } } : undefined"
          (dashboardWindowOnPreCallback)="feedClick.emit()"
          (dashboardWindowOnProceedCallback)="closeDropdown.emit()">
          {{ searchItemSymbol.feedExchange }}
        </span>
        <span *ngIf="!hasFeedClickEvent" class="wt-search__compact-item-market">{{ searchItemSymbol.feedExchange
          }}</span>
      </div>
    </div>

    <div class="wt-flex-row">
      <div class="wt-flex-column wt-truncate" title="{{ searchItemSymbol.fullName }}">
        <span class="wt-search__compact-item-name">{{ searchItemSymbol.fullName }}</span>
      </div>

      <div class="wt-flex-column wt-truncate wt-flex-shrink wt-search__compact-item-state"
        title="{{ searchItemSymbol.access | translate }}">
        <span class="wt-search__compact-item-state-access">{{ searchItemSymbol.access | translate }}</span>
      </div>
    </div>
  </ng-template>
  <!-- END Compact Search Item Symbol -->
</div>

<ng-template #symbolNameTemplate let-searchItemSymbol="searchItemSymbol">
  <span
    *ngIf="(SymbolNameByClassification[searchItemSymbol.symbolClassification!] || DefaultSymbolName) as symbolName"
    [class]="'wt-search__item-' + symbolName"
    [ngClass]="hasTickerClickEvent ? ('wt-search__item' + symbolName + '--clickable') : ''"
    title="{{searchItemSymbol[symbolName] }}"
    addDashboard
    [addDashboardInstrument]="{ feed: searchItemSymbol.feed, ticker: searchItemSymbol.ticker }"
    (addDashboardOnPreCallback)="tickerClick.emit()"
    (addDashboardOnProceedCallback)="closeDropdown.emit()">
    {{ searchItemSymbol[symbolName] }}
  </span>
</ng-template>