import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValuePairNumericComponent } from './value-pair-numeric.component';

@NgModule({
  declarations: [ValuePairNumericComponent],
  imports: [CommonModule],
  exports: [ValuePairNumericComponent],
})
export class ValuePairNumericModule { }
