<ng-container *ngIf="localeId$ | async"></ng-container>
<ng-container *ngIf="hasTradingFeature$ | async">
  <ng-container *ngIf="tradingConnectedObj$ | async as tradingConnectedObj">
    <div class="wt-trading-order-entry-button">
      <!-- KEEP Invisible origin of connected overlay -->
      <!-- <div cdkOverlayOrigin #button="cdkOverlayOrigin"></div> -->

      <!-- Buy/Sell button as origin of connected overlay -->
      <button type="button" class="wt-button wt-button--primary"
        [hidden]="(showTradingBuySellButton$ | async) !== true"
        (click)="toggleOverlay()"
        cdkOverlayOrigin #button="cdkOverlayOrigin">
        {{ 'TRADING_ORDER_ENTRY_COMPONENT.BUY_SELL' | translate }}
      </button>

      <!-- Overlay -->
      <ng-template
        *ngIf="connectionState$ | async as connectionState"
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="button"
        [cdkConnectedOverlayOpen]="!!(showOrderEntry$ | async)"
        [cdkConnectedOverlayPositions]="overlayPositions"
        [cdkConnectedOverlayPanelClass]="'wt-trading-order-entry-zero-height-pane'"
        [cdkConnectedOverlayHasBackdrop]="false">
        <div #orderEntryElement class="wt-trading-order-entry" cdkDrag
          [cdkDragFreeDragPosition]="dragPosition" (cdkDragEnded)="cdkDragEnded($event)">
          <div class="wt-trading-order-entry-wrapper">
            <!-- Extra header for the order entry widget with d&d and close button -->
            <div class="widget-title-bar__wrapper">
              <div class="widget-title-bar wt-draggable">
                <div class="widget-title-bar__title" cdkDragHandle>
                  <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
                    <div class="wt-flex-column">
                      <span class="wt-trading-order-entry-header">{{ connectionState.name }}</span>
                    </div>
                  </div>
                </div>

                <mat-icon class="widget-title-bar-close-icon wt-trading-order-entry-header-close-icon"
                  (click)="closeOverlay()"
                  fontIcon="close"></mat-icon>
              </div>
            </div>

            <!-- Order-Entry widget -->
            <ng-container *ngIf="(reloadAction | async) === false">
              <wt-wtk-widget-wrapper
                *ngIf="orderEntryWidget$ | async as orderEntryWidget"
                [widget]="orderEntryWidget"
                (widgetRefChange)="setOrderEntryRef($event)"
                (widgetStateChange)="onSubscribeWidgetState($event)"></wt-wtk-widget-wrapper>
            </ng-container>
          </div>

          <!-- Order-Entry flyout -->
          <div *ngIf="(reloadAction | async) === false"
            class=" wt-trading-order-flyout-wrapper"
            [ngClass]="{'wt-trading-order-flyout-wrapper--active': isFlyOutActive }">
            <wt-trading-order-flyout *ngIf="instrument$ | async as instrument" [instrument]="instrument">
            </wt-trading-order-flyout>
          </div>
        </div>
      </ng-template>
    </div>
    <!-- [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
        (backdropClick)="closeDropdown()" -->
  </ng-container>
</ng-container>