import { Column } from '../../shared/grid/columns.model';
import { ColumnSetting } from '../../state-model/grid.model';
import {
  PortfolioOrdersDefaultColumns,
  SelectedPortfolioOrdersDefaultColumns,
  PortfolioOrdersStopLossDefaultColumns,
  SelectedPortfolioOrdersStopLossDefaultColumns,
  PortfolioOrdersExecutedDefaultColumns,
  SelectedPortfolioOrdersExecutedDefaultColumns,
  TradingCategories,
} from '../../widgets/portfolio-orders/portfolio-orders.columns';

export const portfolioOrdersGridColumnsMap: PortfolioOrdersGridColumnsMap = {
  portfolioOrdersActive: {
    all: [...PortfolioOrdersDefaultColumns],
    defaultSelected: [...SelectedPortfolioOrdersDefaultColumns],
    // categories: TradingCategories,
  },
  portfolioOrdersAlgo: {
    all: [...PortfolioOrdersDefaultColumns],
    defaultSelected: [...SelectedPortfolioOrdersDefaultColumns],
    // categories: TradingCategories,
  },
  portfolioOrdersStopLoss: {
    all: [...PortfolioOrdersStopLossDefaultColumns],
    defaultSelected: [...SelectedPortfolioOrdersStopLossDefaultColumns],
    // categories: TradingCategories,
  },
  portfolioOrdersInactive: {
    all: [...PortfolioOrdersDefaultColumns],
    defaultSelected: [...SelectedPortfolioOrdersDefaultColumns],
    // categories: TradingCategories,
  },
  portfolioOrdersDeleted: {
    all: [...PortfolioOrdersDefaultColumns],
    defaultSelected: [...SelectedPortfolioOrdersDefaultColumns],
    // categories: TradingCategories,
  },
  portfolioOrdersExecuted: {
    all: [...PortfolioOrdersExecutedDefaultColumns],
    defaultSelected: [...SelectedPortfolioOrdersExecutedDefaultColumns],
    // categories: TradingCategories,
  },
  portfolioOrdersOther: {
    all: [...PortfolioOrdersDefaultColumns],
    defaultSelected: [...SelectedPortfolioOrdersDefaultColumns],
    // categories: TradingCategories,
  },
  portfolioOrdersUnmapped: {
    all: [...PortfolioOrdersDefaultColumns],
    defaultSelected: [...SelectedPortfolioOrdersDefaultColumns],
    // categories: TradingCategories,
  },
};

export const portfolioOrdersGrid = [
  'portfolioOrdersActive',
  'portfolioOrdersAlgo',
  'portfolioOrdersStopLoss',
  'portfolioOrdersInactive',
  'portfolioOrdersDeleted',
  'portfolioOrdersExecuted',
  'portfolioOrdersOther',
  'portfolioOrdersUnmapped',
] as const;
type PortfolioOrdersGridType = typeof portfolioOrdersGrid[number];

type PortfolioOrdersGridColumnsMap = {
  [pfGridName in PortfolioOrdersGridType]: {
    all: Readonly<Column>[];
    defaultSelected: ColumnSetting[];
    categories?: typeof TradingCategories;
  }
};
