import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PreferencesMap } from '@vwd/microfrontend-core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { StorageService, UserSettingsStorageData } from '../../services/storage.service';
import { StoreService } from '../../services/store.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogResult,
} from '../../shared/confirm-dialog/confirm-dialog.component';
import { ContextMenuItem } from '../../shared/models/context-menu.model';
import { Dashboard } from '../../state-model/dashboard.model';
import { Instrument } from '../../state-model/window.model';
import { filterUndefined } from '../../util/rxjs';
import { InstrumentDashboardService } from '../instrument-dashboard.service';
import { DashboardTabsService } from '../providers/dashboard-tabs.provider';
import { RenameDashboardDialogComponent, RenameDashboardDialogData } from '../rename-dashboard-dialog/rename-dashboard-dialog.component';
import { DashboardService } from '@infront/ngx-dashboards-fx';
import { translator } from '../../util/locale';

@Component({
  selector: 'wt-dashboard-context-menu',
  templateUrl: './dashboard-context-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardContextMenuComponent implements OnInit, OnDestroy {
  private readonly storageService: StorageService = inject(StorageService);
  private readonly instrumentDashboardService: InstrumentDashboardService = inject(InstrumentDashboardService);
  private readonly storeService: StoreService = inject(StoreService);
  private readonly dashboardTabsService: DashboardTabsService = inject(DashboardTabsService);
  private readonly fxDashboardService: DashboardService = inject(DashboardService);
  readonly dialog: MatDialog = inject(MatDialog);

  private readonly xlat = translator();

  private readonly dashboardSubject = new BehaviorSubject<Dashboard | undefined>(undefined);

  @Input()
  get dashboard(): Dashboard { return this.dashboardSubject.value!; }
  set dashboard(value: Dashboard) {
    if (value !== this.dashboardSubject.value) {
      this.dashboardSubject.next(value);
    }
  }

  @Input() history: boolean;

  menuItems$: Observable<ContextMenuItem[]> = this.dashboardSubject.pipe(
    map((dashboards) => Array.from(this.getContextMenuItems(dashboards))),
  );

  private readonly userSettingsStorage = this.storageService.getUserSettingsStorage() as PreferencesMap<UserSettingsStorageData>;
  private userSettingsWatchDisposable: ReturnType<typeof this.userSettingsStorage.watch>;

  ngOnInit(): void {
    if (this.history) {
      this.userSettingsWatchDisposable = this.userSettingsStorage.watch('dashboardInstrumentHistory', (instrumentHistory) => {
        // TODO: is there a better way to pipe into menuItems$
        this.menuItems$ = of(instrumentHistory).pipe(
          take(1),
          map((instrumentHistory) =>
            instrumentHistory?.map((instrument) => {
              return {
                title: instrument.ticker,
                id: `inst(${instrument.feed}:${instrument.feed})`,
                onClick: () => this.openInstrumentPage(instrument),
                closeOnClick: true,
              } as ContextMenuItem;
            })
          )
        );
      });
    }
  }

  ngOnDestroy(): void {
    this.userSettingsWatchDisposable?.dispose();
    this.dashboardSubject.complete();
  }

  *getContextMenuItems(dashboard: Dashboard | undefined): Iterable<ContextMenuItem> {
    if (!dashboard) return;

    if (dashboard.canRename) {
      yield {
        closeOnClick: true,
        id: 'renameDashboard',
        title: this.xlat('DASHBOARD.MENU.RENAME_DASHBOARD'),
        icon: 'edit',
        onClick: (params) => this.renameDashboard(params.dashboard),
      };
    }
    // WILL BE HIDDEN UNTIL FEATURE WILL BE FULLY FUNCTIONING
    // yield {
    //   closeOnClick: true,
    //   id: 'shareDashboard',
    //   title: this.xlat('DASHBOARD.MENU.SHARE_DASHBOARD'),
    //   icon: 'share',
    //   onClick: (params) => this.shareDashboard(params.dashboard),
    // };
    yield {
      closeOnClick: true,
      id: 'cloneDashboard',
      title: this.xlat('DASHBOARD.MENU.CLONE_DASHBOARD'),
      icon: 'copy',
      isSVGIcon: true,
      onClick: (params) => this.cloneDashboard(params.dashboard),
    };
    if (dashboard.canClose) {
      yield {
        closeOnClick: true,
        id: 'closeDashboard',
        title: this.xlat('DASHBOARD.MENU.CLOSE_DASHBOARD'),
        icon: 'close',
        isSVGIcon: false,
        onClick: (params) => this.closeDashboard(params.dashboard),
      };
    }
  }

  openInstrumentPage(instrument: Instrument): void {
    this.instrumentDashboardService.addInstrumentDashboardAsync(instrument);
  }

  onItemClick(item: ContextMenuItem): void {
    if (!item.onClick) return;
    item.onClick({ item, dashboard: this.dashboard });
  }

  deleteDashboard(dashboard?: Dashboard): void {
    if (dashboard?.id === undefined) return;
    const confirmDialog$ = () =>
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            header: this.xlat(`DASHBOARD.DELETE_DASHBOARD_PROMPT.DELETE_DASHBOARD`),
            message: this.xlat(`DASHBOARD.DELETE_DASHBOARD_PROMPT.ARE_YOU_SURE`)
          }
        })
        .afterClosed()
        .pipe(
          map((result) => result as ConfirmDialogResult),
          filter((result) => !!result)
        );

    const disableDashboardDeletePromptSetting = this.userSettingsStorage.get('disableDashboardDeletePrompt') ?? false;
    const obs = disableDashboardDeletePromptSetting ? of({ shouldDelete: true, disableDeletePrompt: true }) : confirmDialog$();
    obs.subscribe(result => {
      if (result.shouldDelete) { this.storeService.deleteDashboard(dashboard); }
      if (!disableDashboardDeletePromptSetting && result.disableDeletePrompt) {
        void this.userSettingsStorage.set('disableDashboardDeletePrompt', true);
      }
    });
  }

  closeDashboard(dashboard?: Dashboard): void {
    if (dashboard?.id === undefined) return;
    this.dashboardTabsService.removeTab(dashboard.id);
  }

  shareDashboard(dashboard?: Dashboard): void {
    throw new Error('Share dashboard not implemented yet');
  }

  cloneDashboard(dashboard?: Dashboard): void {
    if (dashboard?.id === undefined) return;
    this.storeService.cloneDashboard(dashboard);
  }

  renameDashboard(dashboard?: Dashboard): void {
    if (dashboard?.id == undefined) return;

    const ref = this.fxDashboardService.getRef(dashboard.id);
    if (!ref) return;

    this.dialog
      .open(RenameDashboardDialogComponent, {
        width: '300px',
        data: {
          name: dashboard.name,
          dashboard: ref,
        } as RenameDashboardDialogData,
      })
      .afterClosed()
      .pipe(filterUndefined<string>())
      .subscribe({
        next: (newName: string) => {
          this.storeService.updateDashboard({ ...dashboard, name: newName });
        },
      });
  }
}
