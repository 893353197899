<div class="wt-flex-row wt-column-picker">
  <div class="wt-flex-column wt-flex-collapse wt-column-picker__list-wrapper">
    <div class="wt-column-picker__list">
      <ng-container *ngIf="!!columnCategories && columnCategories.length > 1">
        <wt-multi-select-expansion
          *ngFor="let category of columnCategories; trackBy: columnCategoryCompareFn"
          [columnCategory]="category"
          (selectionChanged)="onSelectionChanged($event)"
          [isDisabled]="selectedColumns.length < 2"></wt-multi-select-expansion>
      </ng-container>

      <ng-container *ngIf="columnCategories?.length === 1">
        <wt-multi-select
          [columnCategory]="columnCategories[0]"
          (selectionChanged)="onSelectionChanged($event)"
          [isDisabled]="selectedColumns.length < 2"></wt-multi-select>
      </ng-container>
    </div>
  </div>

  <div class="wt-flex-column wt-flex-collapse wt-column-picker__selected-wrapper">
    <div class="wt-column-picker__selected">
      <wt-column-picked-list [columns]="selectedColumns" (columnsChanged)="onPickedColumnsChanged($event)">
      </wt-column-picked-list>
    </div>
  </div>
</div>