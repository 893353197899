import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { StoreService } from '../../../services/store.service';
import { Widget } from '../../../state-model/widget.model';
import { MarketOverviewWindow } from '../../../state-model/window.model';
import { MarketOverviewTemplateName } from '../../../widgets/market-overview/market-overview.model';
import { Dashboard } from '../../../state-model/dashboard.model';
import { DropdownItem } from '../../../shared/dropdown/dropdown.model';

export type MarketOverviewTemplateDropdownItem = DropdownItem<{ templateName: string; }>;

@Component({
  selector: 'wt-market-overview-header',
  templateUrl: './market-overview-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketOverviewHeaderComponent implements OnInit {
  @Input() dashboard: Dashboard;
  @Input() window: MarketOverviewWindow;
  @Input() widget: Widget;

  searchVisible = false;
  availableTemplates: MarketOverviewTemplateDropdownItem[];
  selectedTemplate: MarketOverviewTemplateName;

  constructor(private storeService: StoreService) { }

  ngOnInit(): void {
    this.availableTemplates = MarketOverviewTemplateName.map((name) => ({
      templateName: name,
      itemLabel: `MARKET_OVERVIEW_WIDGET.TEMPLATES.NAME.${name}`,
      translateItemLabel: true,
    }));
    this.selectedTemplate = this.window.settings?.template;
  }

  onItemSelected(item: MarketOverviewTemplateDropdownItem) {
    const templateName = item.templateName;
    if (MarketOverviewTemplateName.includes(templateName)) {
      this.storeService.updateWindow(this.window, {
        settings: { ...this.window.settings, template: templateName as MarketOverviewTemplateName },
      });
    }
  }
}
