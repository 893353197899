import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

import { MatDropdownItem, MatDropdownItemType, MatDropdownColumn } from './mat-dropdown-item.model';

@Component({
  selector: 'wt-mat-dropdown-item',
  templateUrl: './mat-dropdown-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatDropdownItemComponent<T extends object> {
  @Output() itemSelected = new EventEmitter<MatDropdownItem<T>>();

  @Input() item: MatDropdownItem<T>;
  @Input() columns: MatDropdownColumn<T>[];

  MatDropdownItemType = MatDropdownItemType;

  onItemSelected(item: MatDropdownItem<T>): void {
    item.selected = !item.selected;
    this.itemSelected.emit(item);
  }
}
