import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, OnInit, OnDestroy, inject } from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Directive({
  selector: '[clickOutside]',
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  private element = inject(ElementRef) as ElementRef<HTMLElement>;

  @Output() clickOutside = new EventEmitter();

  //Elements to ignore on click.
  //Example: On search, we don't want to close dropdown when Input element is clicked.
  @Input() ignoreElements: HTMLElement[];

  private subscription: Subscription;

  private mouseDownElement = new BehaviorSubject<HTMLElement | undefined>(undefined);
  private mouseUpElement = new BehaviorSubject<HTMLElement | undefined>(undefined);

  private sameElementClicked$ = combineLatest([this.mouseDownElement.asObservable(), this.mouseUpElement.asObservable()]).pipe(
    map(([downClickElem, upClickElem]) => downClickElem === upClickElem && downClickElem),
    filter((clickedElm) => !!clickedElm)
  );

  clickedOutside$ = this.sameElementClicked$.pipe(
    map((clickedElm) => {
      const isClicked = !(
        this.element.nativeElement === clickedElm ||
        (!!clickedElm && this.element.nativeElement?.contains(clickedElm)) ||
        this.ignoreElements?.some((elm) => !!clickedElm && elm?.contains(clickedElm))
      );
      return isClicked;
    })
  );

  @HostListener('document:mousedown', ['$event'])
  documentMouseDown(event: MouseEvent): void {
    this.mouseDownElement.next(event.target as HTMLElement);
  }

  @HostListener('document:mouseup', ['$event'])
  documentMouseUp(event: MouseEvent): void {
    this.mouseUpElement.next(event.target as HTMLElement);
  }

  ngOnInit(): void {
    this.subscription = this.clickedOutside$.subscribe((element) => {
      element && this.clickOutside.emit(element);
    });
  }

  ngOnDestroy(): void {
    this.mouseDownElement.complete();
    this.mouseUpElement.complete();
    this.subscription?.unsubscribe();
  }
}
