import { MatMenu } from '@angular/material/menu';

export function setOverlayPanelClass(matMenu: MatMenu, defaultClass: string, scopeClass: string) {
  if (matMenu instanceof MatMenu) {
    if (scopeClass) {
      // @WARINING: Do not use whitespaces in overlayPanelClass, causes error
      matMenu.overlayPanelClass = defaultClass + scopeClass;
    } else {
      matMenu.overlayPanelClass = defaultClass;
    }
  }
}
