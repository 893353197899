<div class="wt-screener">
  <!-- screener tabs currently disabled -->
  <!-- <div class="wt-flex-row">
    <ul class="wt-list-inline" #tabsContainer>
      <li class="wt-dashboard-tabs__item">
        {{ 'SCREENER.FUND_SCREENER.FUND_SCREENER' | translate }}
      </li>
      <li>

      </li>
    </ul>
  </div> -->
  <div class="wt-flex-row wt-screener__content">
    <wt-fund-screener></wt-fund-screener>
  </div>
</div>
