import { Injectable } from '@angular/core';
import { InfrontUtil } from '@infront/sdk';
import { Observable, from, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { dashboardRaster } from '../dashboard/dashboard.service';
import { PortfolioHeaderWindowName, sideWindowDefaultHeight } from '../dashboard/template';
import { portfolioOrdersWindows } from '../dashboard/templates/portfolio-orders-template';
import { CalculatedFields, portfolioPositionsWindows } from '../dashboard/templates/portfolio-positions-template';
import { readonlyWindowParams } from '../dashboard/templates/templates.model';
import { DashboardType } from '../state-model/dashboard.model';
import { GridLineHeight } from '../state-model/grid.model';
import { PortfolioHeaderWidget } from '../state-model/widget.model';
import { DashboardWindow, PortfolioHeaderWindow, PortfolioOrdersWindow, PortfolioPositionsWindow } from '../state-model/window.model';
import { structuresAreEqual } from '../util/equality';
import { OrderCategory } from '../widgets/portfolio-orders/portfolio-orders.model';
import { TradingClassification } from '../widgets/portfolio-positions/portfolio-positions.model';
import { PositionsSummaryService } from '../widgets/positions-summary/positions-summary.service';
import { StoreService } from './store.service';
import { TradingOrdersService } from './trading-orders.service';
import { TradingPositionsService } from './trading-positions.service';
import { TradingTradesService } from './trading-trades.service';
import { UserSettingsService } from './user-settings.service';

const TRADES_WINDOW_MAX_HEIGHT = 29; // gridster rows

@Injectable({
  providedIn: 'root',
})
export class PortfolioDashboardService {
  private rowHeightRatio = GridLineHeight / dashboardRaster;

  constructor(private storeService: StoreService, private tradingPositionsService: TradingPositionsService, private userSettingsService: UserSettingsService, private positionsSummaryService: PositionsSummaryService, private tradingOrdersService: TradingOrdersService, private tradingTradesService: TradingTradesService) {
  }

  private portfolioPositionsWindows$(windows: DashboardWindow[]): Observable<DashboardWindow[]> {
    return this.tradingPositionsService.portfolioByClassification$('dashboard').pipe(
      distinctUntilChanged((prev, next) => {
        return structuresAreEqual(prev, next);
      }),
      switchMap((portfolioByClassification) => {
        if (!portfolioByClassification) {
          return of([]);
        }
        const tradingSelectedPortfolioId = this.userSettingsService.getValue('tradingSelectedPortfolioId');
        const windowYpositions: number[] = [1];
        let totalWindowsRows = 1; // start after header window
        const classifications = Object.keys(portfolioByClassification) as TradingClassification[];
        const positionWindows = windows.filter((w) => {
          if (w.name !== 'PortfolioPositionsWindow') {
            return false;
          }
          const window = w as PortfolioPositionsWindow;
          const windowClassification = window.tradingClassification;
          // seems we loose a few things when getting windows back from storage?
          // but we don't need to store props we already know we always need for portfolio windows, we can just make sure they always get reattached
          const filterPassed = classifications.includes(windowClassification);

          if (filterPassed) {
            window.rows = this.portfolioWindowCalcGridRows(portfolioByClassification[windowClassification]?.length, windowClassification === 'Unmapped' ? 2 : 3);
            totalWindowsRows += window.rows;
            windowYpositions.push(totalWindowsRows);
            window.label = portfolioPositionsWindows[windowClassification]?.label;
          }

          return filterPassed;
        });

        const retWindows = positionWindows.map((window, index) => {
          return {
            ...window,
            ...readonlyWindowParams,
            y: windowYpositions[index],
          };
        });
        retWindows.reverse();

        const headerWindow = windows.find((w) => w.name === 'PortfolioHeaderWindow');
        const mainWindows = headerWindow ? [headerWindow, ...retWindows] : retWindows;
        const sideWindowsBeforeAdjust = windows.filter((w) =>
          ['PositionsSummaryWindow', 'PositionsExposureWindow', 'PositionsEventsWindow'].includes(w.name)).map(w => ({ ...w, rows: sideWindowDefaultHeight }));
        return this.positionsSummaryService.numberOfCashPositions$.pipe(distinctUntilChanged(), switchMap(numberOfCashPositions => {
          const sideWindows = this.portfolioSummaryWindowsAdjustPositions(sideWindowsBeforeAdjust, numberOfCashPositions);
          const allWindows = [...mainWindows, ...sideWindows].map(w => ({ ...w, renderId: tradingSelectedPortfolioId ?? '' }));
          return from([[], allWindows]).pipe(map(windows => {
            return windows;
          }));
        }));
      })
    );
  }

  private portfolioOrdersWindows$(windows: DashboardWindow[]): Observable<DashboardWindow[]> {
    //this.logger.log('addPortfolioOrdersWindows', { windows });
    return this.tradingOrdersService.ordersByCategory$().pipe(
      distinctUntilChanged((prev, next) => {
        return structuresAreEqual(prev, next);
      }),
      switchMap((ordersByCategory) => {
        const windowYpositions: number[] = [1];
        let totalWindowsRows = 1; // start after header window
        const availableCategories = Object.keys(ordersByCategory);
        // In any case we need to display Active window, even with no data!
        const orderCategories = (availableCategories?.length ? availableCategories : ['Active']) as OrderCategory[];
        const windowsInView = windows.filter((inWindow) => {
          // skip header since header it statically placed
          if (inWindow.name === 'PortfolioHeaderWindow') {
            return false;
          }
          const window = inWindow as PortfolioOrdersWindow;
          const windowOrderCategory = window.orderCategory;
          // seems we loose a few things when getting windows back from storage?
          // but we don't need to store props we already know we always need for portfolio windows, we can just make sure they always get reattached
          const filterPassed = orderCategories.includes(windowOrderCategory);
          if (filterPassed) {
            window.rows = this.portfolioWindowCalcGridRows(ordersByCategory[windowOrderCategory]?.length, 2);
            totalWindowsRows += window.rows;
            windowYpositions.push(totalWindowsRows);
            window.label = portfolioOrdersWindows[windowOrderCategory]?.label;
          }
          if ((window as DashboardWindow).name !== PortfolioHeaderWindowName) {
            window.renderId = InfrontUtil.makeUUID();
          }
          return filterPassed;
        });

        const retWindows = windowsInView.map((window, index) => {
          return {
            ...window,
            ...readonlyWindowParams,
            y: windowYpositions[index],
          };
        });
        retWindows.reverse();

        const headerWindow = windows.find((w) => w.name === 'PortfolioHeaderWindow');
        const returnWindows = headerWindow ? [headerWindow, ...retWindows] : retWindows;
        return from([[], returnWindows]); // emit empty array first to reset dashboard window between any rerendering when switching portfolios otherwise gridster window collisions might occur
      })
    );
  }

  private portfolioTradesWindows$(windows: DashboardWindow[]): Observable<DashboardWindow[]> {
    return this.tradingTradesService.trades$.pipe(
      distinctUntilChanged((prev, next) => {
        return structuresAreEqual(prev, next);
      }),
      switchMap((trades) => {
        // trades tab only needs to calculate height of the PortfolioTradesWindow, the rest is static
        const tradingSelectedPortfolioId = this.userSettingsService.getValue('tradingSelectedPortfolioId');
        const retWindows = windows.map((window) => {
          if (window.name !== 'PortfolioTradesWindow') {
            return window;
          }
          const rows = this.portfolioWindowCalcGridRows(trades.length, 2);
          window.rows = rows > TRADES_WINDOW_MAX_HEIGHT ? TRADES_WINDOW_MAX_HEIGHT : rows;
          return window;
        });
        const returnWindows = retWindows.map(w => ({ ...w, renderId: `TRADES~${tradingSelectedPortfolioId ?? ''}~${window.name}` }));
        return from([[], returnWindows as DashboardWindow[]]);
      })
    );
  }

  windows$ = this.storeService.currentWindowsAndDashboardType$.pipe(
    filter((result) => result.type === DashboardType.portfolio),
    switchMap((result) => {
      const { windows } = result;
      const portfolioHeaderWindow = windows.find((w) => w.name === PortfolioHeaderWindowName);
      if (!portfolioHeaderWindow) {
        return of(windows);
      }
      return this.storeService.selectedWidgetInWindow$(portfolioHeaderWindow).pipe(
        switchMap((widget: PortfolioHeaderWidget) => {
          const windowsFilteredByTab = windows.filter((w) => w.tag === widget.settings.selectedTab || w.name === PortfolioHeaderWindowName);
          if (widget.settings.selectedTab === 'ORDERS') {
            return this.portfolioOrdersWindows$(windowsFilteredByTab as (PortfolioHeaderWindow | PortfolioOrdersWindow)[]);
          }
          if (widget.settings.selectedTab === 'POSITIONS') {
            return this.portfolioPositionsWindows$(windowsFilteredByTab);
          }
          if (widget.settings.selectedTab === 'TRADES') {
            return this.portfolioTradesWindows$(windowsFilteredByTab);
          }
          return of([]);
        }),

        map((filteredWindows: DashboardWindow[]) => {
          const addNewWindowBefore = windows.filter(w => w.layerIndex === 2 /* add window */);
          const addNewWindowAfter = filteredWindows.filter(w => w.layerIndex === 2 /* add window */);
          if (addNewWindowBefore.length && !addNewWindowAfter.length) {
            return [...filteredWindows, ...addNewWindowBefore];
          }
          return filteredWindows;
        }));
    }));


  // headerRows: approximate size needed expressed in gridster rows for the headers height
  private portfolioWindowCalcGridRows(items: number, headerRows = 1) {
    if (!items) {
      return 12; // empty window height
    }

    // TODO: This logic needs to be updated to get more precised rows calculation
    // const rowsRequested = Math.ceil(headerRows + (items + 1) * this.rowHeightRatio);
    const rowsRequested = Math.ceil(headerRows + items);

    return rowsRequested;
  }

  private portfolioSummaryWindowsAdjustPositions(inWindows: DashboardWindow[], numberOfCashPositions: number): DashboardWindow[] {
    let currentY = 0;
    const headerRows = 1;
    const retWindows = inWindows.map((window, index) => {
      if (index === 0) {
        currentY = window.y;
      } else {
        window.y = currentY;
      }
      const getter = CalculatedFields[window.label as string]?.labelListGetter;
      const count = getter ? getter({}).length : window.rows;
      const heightSource = window.id === 'cash' ? numberOfCashPositions : count;
      window.rows = Math.ceil(heightSource * 1) + headerRows;
      currentY += window.rows;
      return window;
    });
    return retWindows;
  }
}
