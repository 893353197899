import { Injectable } from '@angular/core';
import { CountryFlagMap, FlagSize } from '@infront/wtk/utils/country-flags';
import { LogService } from '@vwd/ngx-logging';

import { getCountryFileNameForFlag } from '../../util/country';

const ASSET_PATH: Readonly<string> = 'assets/flags';
const IMAGE_FORMAT: Readonly<string> = 'png';
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const FALLBACK_FLAG: Readonly<string> = CountryFlagMap.GLOBAL;

@Injectable()
export class CountryFlagService {
  private readonly logger = this.logService.openLogger('services/error-handler');
  private imageCache: { [fileName: string]: string } = {};

  constructor(private readonly logService: LogService) { }

  getCountryFlag(country: string | number | undefined, size: FlagSize, allowFallbackFlag = true, fallbackFlag = FALLBACK_FLAG): HTMLImageElement | undefined {
    const fileName: string | undefined = getCountryFileNameForFlag(country, allowFallbackFlag, fallbackFlag);
    if (!fileName) {
      // no valid flag must return undefined!
      return;
    }

    const image: HTMLImageElement = new Image(size, size);
    image.src = `${ASSET_PATH}/${size}/${fileName}.${IMAGE_FORMAT}`;

    // the cache for a specific image may take some calls to getCountryFlag before it exists
    // but then will be used as image.src until browser reload!
    const key = this.getImageCacheKey(fileName, size);
    this.cacheBase64FromImageUrl(key, image.src);
    if (this.imageCache[key]) {
      image.src = this.imageCache[key];
    }
    // this.logger.debug("imageCache", this.imageCache);

    return image;
  }

  private getImageCacheKey(fileName: string, size: FlagSize): string {
    return `${fileName}~${size}`;
  }

  // adapted from https://stackoverflow.com/a/16566198/8919178
  private cacheBase64FromImageUrl(fileName: string, url: string): void {
    if (this.imageCache[fileName]) {
      // cache item exists, no need to create it again!
      return;
    }

    const img: HTMLImageElement = new Image();
    // img.setAttribute('crossOrigin', 'anonymous');

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0);

      if (!this.imageCache[fileName]) {
        this.imageCache[fileName] = canvas.toDataURL("image/png");
      }
    };

    img.src = url;
  }

}
