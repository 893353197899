import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'textMarker',
})
export class TextMarkerPipe implements PipeTransform {
  transform(val: string, args: string): SafeHtml {
    if (!args) {
      return val;
    }
    const regex = new RegExp(args, 'gi');
    const match = regex.exec(val);
    if (!match) {
      return val;
    }
    return this.sanitizer.bypassSecurityTrustHtml(val.replace(regex, `<mark><B>${match[0]}</B></mark>`)); // NOSONAR seems to be safe, although we have no control over the user-input
  }

  constructor(private sanitizer: DomSanitizer) { }
}
