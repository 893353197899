<div *ngIf="vm$ | async as vm" class="wt-fund-screener-inputs">
  <form [formGroup]="formGroup">
    <!-- first row -->
    <div class="wt-fund-screener-inputs__row">

      <!-- fullName -->
      <div class="wt-fund-screener-inputs__column--double">
        <div class="wt-fund-screener-inputs__form-field--auto-complete">
          <label class="wt-fund-screener-inputs__label">{{'SCREENER.FUND_SCREENER.FORM_LABEL.NAME' | translate}}</label>
          <wt-auto-complete
            formControlName="fullName"
            [minKeystrokes]="3"
            [mode]="'freeText'"
            [typeDelay]="500"
            [disableClear]="true">
          </wt-auto-complete>
        </div>
      </div>

      <!-- reset all -->
      <div class="wt-fund-screener-inputs__column">
        <div class="wt-fund-screener-inputs__reset-button-container">
            <div class="wt-fund-screener-inputs__reset-button"
            (click)="onResetAll()">{{'SCREENER.FUND_SCREENER.FORM_LABEL.RESET_ALL' | translate}}
            <mat-icon class="wt-fund-screener-inputs__reset-button-icon" fontIcon="refresh"></mat-icon></div>
        </div>
      </div>

      <!-- ISIN -->
      <div class="wt-fund-screener-inputs__column">
        <div class="wt-fund-screener-inputs__form-field--auto-complete">
          <label class="wt-fund-screener-inputs__label">{{'SCREENER.FUND_SCREENER.FORM_LABEL.ISIN' | translate}}</label>
          <wt-auto-complete
            formControlName="ISIN"
            (searchString)="onAutoCompleteSearch($event, [InfrontSDK.SymbolField.ISIN])"
            [searchResultItems]="vm.autoCompleteSearchResult[InfrontSDK.SymbolField.ISIN]">
          </wt-auto-complete>
        </div>
      </div>

      <!-- feeds -->
      <div class="wt-fund-screener-inputs__column">
        <mat-form-field appearance="fill" floatLabel="never" class="wt-fund-screener-inputs__form-field">
          <label class="wt-fund-screener-inputs__label">{{'SCREENER.FUND_SCREENER.FORM_LABEL.SOURCE' |
            translate}}</label>
          <mat-select disableOptionCentering class="wt-fund-screener-inputs__category-select" formControlName="feed"
            [compareWith]="feedCompareFn" [panelClass]="'wt-fund-screener-inputs__source-dropdown'">
            <mat-option *ngFor="let item of vm.feeds" [value]="item" class="wt-fund-screener-inputs__option">
              {{ item.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- second row -->
    <div class="wt-fund-screener-inputs__row">

      <!-- fundType -->
      <div class="wt-fund-screener-inputs__column">

        <mat-form-field appearance="fill" floatLabel="never" class="wt-fund-screener-inputs__form-field">
          <label class="wt-fund-screener-inputs__label">{{'SCREENER.FUND_SCREENER.FORM_LABEL.TYPE' | translate}}</label>
          <mat-select disableOptionCentering class="wt-fund-screener-inputs__category-select"
            formControlName="fundType" #fundType>
            <mat-option *ngFor="let item of vm.fundTypes" [value]="item" class="wt-fund-screener-inputs__option">
              {{ item }}
            </mat-option>
          </mat-select>
          <mat-icon (click)="onClearField('fundType', $event)" [hidden]="fundType?.value === ''" class="wt-fund-screener-inputs__clear" fontIcon="close"></ mat-icon>
        </mat-form-field>
      </div>

      <!-- regionExposure -->
      <div class="wt-fund-screener-inputs__column">
        <mat-form-field appearance="fill" floatLabel="never" class="wt-fund-screener-inputs__form-field">
          <label class="wt-fund-screener-inputs__label">{{'SCREENER.FUND_SCREENER.FORM_LABEL.REGIONS_INVESTED' |
            translate}}</label>
          <mat-select disableOptionCentering #regionExposure
          [ngClass]="regionExposure?.value === '' ? 'wt-fund-screener-inputs__category-select' : 'wt-fund-screener-inputs__category-select wt-fund-screener-inputs__short-select-value'"
            formControlName="regionExposure" [panelClass]="'wt-fund-screener-inputs__category-dropdown'" multiple>
            <mat-option *ngFor="let item of vm.regions" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
          <mat-icon (click)="onClearField('regionExposure', $event)" [hidden]="!regionExposure?.value?.length" class="wt-fund-screener-inputs__clear" fontIcon="close"></ mat-icon>
        </mat-form-field>
      </div>

      <!-- primarySegment -->
      <div class="wt-fund-screener-inputs__column">
        <div class="wt-fund-screener-inputs__form-field--auto-complete">
          <label class="wt-fund-screener-inputs__label">{{'SCREENER.FUND_SCREENER.FORM_LABEL.SEGMENT' |
            translate}}</label>
          <wt-combo-box
            formControlName="primarySegment"
            [options]="vm.segments">
          </wt-combo-box>
        </div>
      </div>

      <!-- portfolioManager -->
      <div class="wt-fund-screener-inputs__column">
        <div class="wt-fund-screener-inputs__form-field--auto-complete">
          <label class="wt-fund-screener-inputs__label">{{'SCREENER.FUND_SCREENER.FORM_LABEL.PORTFOLIO_MANAGER' |
            translate}}</label>
          <wt-auto-complete
            formControlName="portfolioManager"
            (searchString)="onAutoCompleteSearch($event, [InfrontSDK.SymbolField.FundPortfolioManager])"
            [searchResultItems]="vm.autoCompleteSearchResult[InfrontSDK.SymbolField.FundPortfolioManager]">
          </wt-auto-complete>
        </div>
      </div>

      <!-- issuer -->
      <div class="wt-fund-screener-inputs__column">
        <div class="wt-fund-screener-inputs__form-field--auto-complete">
          <label class="wt-fund-screener-inputs__label">{{'SCREENER.FUND_SCREENER.FORM_LABEL.ISSUER' |
            translate}}</label>
          <wt-auto-complete
            formControlName="issuer"
            (searchString)="onAutoCompleteSearch($event, [InfrontSDK.SymbolField.Issuer])"
            [searchResultItems]="vm.autoCompleteSearchResult[InfrontSDK.SymbolField.Issuer]">
          </wt-auto-complete>
        </div>
      </div>
    </div>

    <!-- third row -->
    <div class="wt-fund-screener-inputs__row">
      <div class="wt-fund-screener-inputs__column">
        <div class="wt-flex-row wt-fund-screener-inputs__performance">
          <!-- performancePeriods -->
          <div class="wt-flex-columns wt-flex-small-6">
            <mat-form-field appearance="fill" floatLabel="never" class="wt-fund-screener-inputs__form-field">
              <label class="wt-fund-screener-inputs__label">{{'SCREENER.FUND_SCREENER.FORM_LABEL.PERFORMANCE' |
                translate}}</label>
              <mat-select disableOptionCentering class="wt-fund-screener-inputs__category-select"
                formControlName="performancePeriod" [panelClass]="'wt-fund-screener-inputs__performance-dropdown'">
                <mat-option *ngFor="let item of performancePeriods" [value]="item"
                  class="wt-fund-screener-inputs__option">
                  {{ item }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- performanceMin -->
          <div class="wt-flex-columns wt-flex-small-3">
            <mat-form-field appearance="fill" floatLabel="never" class="wt-fund-screener-inputs__form-field">
              <label class="wt-fund-screener-inputs__label">{{'SCREENER.FUND_SCREENER.FORM_LABEL.MIN' | translate}}
                %</label>
              <input class="" matInput numberOnly formControlName="performanceMin" maxlength="4" />
            </mat-form-field>
          </div>
          <!-- performanceMax -->
          <div class="wt-flex-columns wt-flex-small-3">
            <mat-form-field appearance="fill" floatLabel="never" class="wt-fund-screener-inputs__form-field">
              <label class="wt-fund-screener-inputs__label">{{'SCREENER.FUND_SCREENER.FORM_LABEL.MAX' | translate}}
                %</label>
              <input class="" matInput numberOnly formControlName="performanceMax" maxlength="4" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- riskLevel -->
      <div class="wt-fund-screener-inputs__column">
        <mat-form-field appearance="fill" floatLabel="never" class="wt-fund-screener-inputs__form-field">
          <label class="wt-fund-screener-inputs__label">{{'SCREENER.FUND_SCREENER.FORM_LABEL.RISK_LEVEL' |
            translate}}</label>
          <mat-select disableOptionCentering #riskLevel class="wt-fund-screener-inputs__category-select"
            formControlName="riskLevel" [panelClass]="'wt-fund-screener-inputs__category-dropdown'" multiple>
            <mat-option *ngFor="let item of ['1','2','3','4','5','6','7']" [value]="item"
              class="wt-fund-screener-inputs__option">
              {{ item }}
            </mat-option>
          </mat-select>
          <mat-icon (click)="onClearField('riskLevel', $event)" [hidden]="!riskLevel?.value?.length" class="wt-fund-screener-inputs__clear" fontIcon="close"></ mat-icon>
        </mat-form-field>
      </div>

      <!-- topHolding -->
      <div class="wt-fund-screener-inputs__column">
        <div class="wt-fund-screener-inputs__form-field--auto-complete">
          <label class="wt-fund-screener-inputs__label">{{'SCREENER.FUND_SCREENER.FORM_LABEL.HOLDINGS' |
            translate}}</label>
          <wt-auto-complete formControlName="topHolding"
            (searchString)="onAutoCompleteSearch($event, [InfrontSDK.SymbolField.FundTopHolding])"
            [searchResultItems]="vm.autoCompleteSearchResult[InfrontSDK.SymbolField.FundTopHolding]">
          </wt-auto-complete>
        </div>
      </div>

      <!-- fundStyle -->
      <div class="wt-fund-screener-inputs__column">
        <ng-container *ngIf="!vm.isInfrontFeed">
          <mat-form-field appearance="fill" floatLabel="never" class="wt-fund-screener-inputs__form-field">
            <label class="wt-fund-screener-inputs__label">{{'SCREENER.FUND_SCREENER.FORM_LABEL.FUND_STYLE' | translate}}
              (Morningstar)</label>
            <mat-select disableOptionCentering class="wt-fund-screener-inputs__category-select"
              formControlName="fundStyle" #fundStyle>
              <mat-option *ngFor="let item of vm.fundStyles" [value]="item" class="wt-fund-screener-inputs__option">
                {{ item }}
              </mat-option>
            </mat-select>
            <mat-icon (click)="onClearField('fundStyle', $event)" [hidden]="fundStyle?.value === ''" class="wt-fund-screener-inputs__clear" fontIcon="close"></ mat-icon>
          </mat-form-field>
        </ng-container>
      </div>

      <!-- fundStarRating -->
      <div class="wt-fund-screener-inputs__column">
        <ng-container *ngIf="!vm.isInfrontFeed">
          <mat-form-field appearance="fill" floatLabel="never" class="wt-fund-screener-inputs__form-field">
            <label class="wt-fund-screener-inputs__label">{{'SCREENER.FUND_SCREENER.FORM_LABEL.RATING' | translate}}
              (Morningstar)</label>
            <mat-select disableOptionCentering class="wt-fund-screener-inputs__category-select"
              formControlName="fundStarRating" #fundStarRating [panelClass]="'wt-fund-screener-inputs__category-dropdown'" multiple>
              <mat-option *ngFor="let item of [1,2,3,4,5];" [value]="item" class="wt-fund-screener-inputs__option">
                <span>{{ item }}<mat-icon style="font-size:14px; margin:0px 0 1px 2px"
                    fontIcon="star"></mat-icon></span>
              </mat-option>

              <mat-select-trigger>
                <div class="wt-fund-screener-inputs__selected-ratings">
                  <div *ngFor="let selectedRating of (fundStarRating?.value); let last = last"
                    class="selected-option">
                    <span>{{ selectedRating }}<mat-icon class="wt-fund-screener-inputs__selected-ratings-icon"
                        fontIcon="star"></mat-icon>{{ !last ? ',&nbsp;' : '' }}</span>
                  </div>
                </div>
              </mat-select-trigger>
            </mat-select>
            <mat-icon (click)="onClearField('fundStarRating', $event)" [hidden]="!fundStarRating?.value?.length" class="wt-fund-screener-inputs__clear" fontIcon="close"></ mat-icon>
          </mat-form-field>
        </ng-container>
      </div>
    </div>
  </form>
</div>
