import { isDevMode } from '@angular/core';
import { Grid } from '../../state-model/grid.model';
import { HistoryWidgetDefaults } from '../../state-model/widget.defaults';
import { HistoryWidget, Widget } from '../../state-model/widget.model';
import { DashboardWindow } from '../../state-model/window.model';
import { dummyInstrument, readonlyWindowParams } from './templates.model';
import { validateTemplate } from './validation';

// DASHBOARD TEMPLATE: FUND
const firstColumnWidth = 15;
const secondColumnWidth = 20;
const thirdColumnWidth = 15;

const headerHeight = 1;

// col 1
const instrumentOverviewHeight = 13;

// col 2
const exposureHeight = 17;
const chartHeight = 13;

// col 3
const historyheight = 13;

export const overviewDashboardWindows: DashboardWindow[] = [
  // first column
  {
    id: 'col1-overview',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'InstrumentOverview',
    cols: firstColumnWidth,
    rows: instrumentOverviewHeight,
    y: headerHeight,
    x: 0,
    ...readonlyWindowParams,
  },
  {
    id: 'col1-topHoldings',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'Exposure',
    cols: firstColumnWidth,
    rows: exposureHeight,
    y: headerHeight + instrumentOverviewHeight,
    x: 0,
    ...readonlyWindowParams,
  },
  // second column (to 1280px)
  {
    id: 'col2-chart',
    linkChannel: 'None',
    name: 'ChartWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'Chart',
    cols: secondColumnWidth,
    rows: chartHeight,
    y: headerHeight,
    x: firstColumnWidth,
    ...readonlyWindowParams,
  },
  {
    id: 'col2-topRegions',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'Exposure',
    cols: secondColumnWidth / 2,
    rows: exposureHeight,
    y: headerHeight + chartHeight,
    x: firstColumnWidth,
    ...readonlyWindowParams,
  },
  {
    id: 'col2-topSectors',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'Exposure',
    cols: secondColumnWidth / 2,
    rows: exposureHeight,
    y: headerHeight + chartHeight,
    x: firstColumnWidth + secondColumnWidth / 2,
    ...readonlyWindowParams,
  },
  // third column
  {
    id: 'col3-history',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'History',
    cols: thirdColumnWidth,
    rows: historyheight,
    y: headerHeight,
    x: firstColumnWidth + secondColumnWidth,
    ...readonlyWindowParams,
  },
  {
    id: 'col3-topCurrencies',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'Exposure',
    cols: thirdColumnWidth,
    rows: exposureHeight,
    y: headerHeight + historyheight,
    x: firstColumnWidth + secondColumnWidth,
    ...readonlyWindowParams,
  },

];

export const overviewDashboardWidgets: Widget[] = [
  // Instrument Dashboard
  {
    id: 'w-overview',
    dashboardId: '0',
    name: 'InstrumentOverview',
    settings: { selectedPeriodIndex: 4 },
    windowId: 'col1-overview',
  },
  {
    id: 'w-topHoldings',
    dashboardId: '0',
    name: 'Exposure',
    settings: { selectedGrid: 'topHoldings', showTabs: false },
    windowId: 'col1-topHoldings',
  },
  {
    id: 'w-topRegions',
    dashboardId: '0',
    name: 'Exposure',
    settings: { selectedGrid: 'topRegions', showTabs: false },
    windowId: 'col2-topRegions',
  },
  {
    id: 'w-topSectors',
    dashboardId: '0',
    name: 'Exposure',
    settings: { selectedGrid: 'topSectors', showTabs: false },
    windowId: 'col2-topSectors',
  },
  {
    id: 'w-history',
    dashboardId: '0',
    name: 'History',
    settings: HistoryWidgetDefaults.settings,
    gridColumnSize: 'sizeToFit',
    windowId: 'col3-history',
  } as HistoryWidget,
  {
    id: 'w-topCurrencies',
    dashboardId: '0',
    name: 'Exposure',
    settings: { selectedGrid: 'topCurrencies', showTabs: false },
    windowId: 'col3-topCurrencies',
  },
  // Chart window
  {
    id: 'w-chart',
    dashboardId: '0',
    name: 'Chart',
    settings: {
      chartOptions: {
        defaultPeriod: '1Y',
        // bottomToolbar: {},
      },
    },
    windowId: 'col2-chart',
  },
];

export const overviewDashboardGrids: Grid[] = [
  // sub for ExposureWidget, widgetId 2
  {
    id: 'g-topHoldings',
    dashboardId: '0',
    name: 'topHoldings',
    parentId: 'w-topHoldings',
  },
  // sub for ExposureWidget, widgetId 4
  {
    id: 'g-topRegions',
    dashboardId: '0',
    name: 'topRegions',
    parentId: 'w-topRegions',
  },
  // sub for ExposureWidget, widgetId 6
  {
    id: 'g-topSectors',
    dashboardId: '0',
    name: 'topSectors',
    parentId: 'w-topSectors',
  },
  // sub for HistoryWidget, widgetId 10
  {
    id: 'g-historyTrades',
    dashboardId: '0',
    name: 'historyTrades',
    parentId: 'w-history',
  },
  // sub for ExposureWidget, widgetId 8
  {
    id: 'g-topCurrencies',
    dashboardId: '0',
    name: 'topCurrencies',
    parentId: 'w-topCurrencies',
  },

];

if (isDevMode()) {
  validateTemplate('forex-template', overviewDashboardWindows, overviewDashboardWidgets, overviewDashboardGrids);
}
