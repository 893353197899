<ng-container *ngIf="localeId$ | async"></ng-container>

<div class="wt-dashboard__header">
  <div class="wt-flex-row wt-flex-collapse">
    <div class="wt-flex-column">
      <wt-dashboard-tabs *ngIf="(reloadAction | async) === false"></wt-dashboard-tabs>
    </div>

    <div class="wt-flex-column wt-flex-shrink">
      <wt-sidebar *ngIf="(reloadAction | async) === false"></wt-sidebar>
    </div>
  </div>
</div>

<ng-container *ngIf="dashboardGuardMessage$ | async as dashboardGuardMessage">
  <ng-container *ngIf="!dashboardGuardMessage.result; else dashboardGuardMessagePlaceholder">
    <ng-container *ngIf="gridsterConfig$ | async as config">
      <div class="gridster-container"
        *ngIf="currentDashboard$ | async as currentDashboard"
        [ngClass]="{ 'gridster-container--sidebar-open': sidebarIsOpen$ | async }"
        data-testid="dashboard-gidster-container">
        <gridster [options]="config" *ngIf="gridItems$ | async as gridItems" #gridster class="gridster">
          <gridster-item
            [item]="item.gridItem"
            *ngFor="let item of gridItems; let i = index; trackBy: windowCompareFn"
            class="gridster-item"
            [ngClass]="{ 'gridster-container--locked': item.gridItem.name === 'ChartWindow' && currentDashboard.locked && item.layerIndex !== 2 }"
            #gridsterItem>
            <!-- window.layerIndex === 2 is used for adding new window dialog seamless in the gridster dashboard -->
            <div *ngIf="item.layerIndex === 2" class="wt-dashboard-buttons">
              <button type="button" class="wt-button wt-button--cancel" (click)="onCancel(item.window)">
                {{ "GLOBAL.BUTTON.CANCEL" | translate }}
              </button>

              <button *ngIf="currentDashboard.locked" type="button"
                class="wt-button wt-button--outlined wt-button--disabled" disabled>
                {{ "DASHBOARD.ADD_WINDOW.LOCKED_DASHBOARD" | translate }}
                <mat-icon class="wt-button-locked-icon" fontIcon="lock"></mat-icon>
              </button>

              <button *ngIf="!(currentDashboard$ | async)?.locked" type="button" class="wt-button wt-button--primary"
                (click)=" onAddToWorkspace(item)" data-testid="dashboard-add-to-dashboard">
                {{ "DASHBOARD.ADD_TO_DASHBOARD" | translate }}
              </button>
            </div>

            <section class="wt-dashboard-window-wrapper"
              [ngClass]="{ 'wt-dashboard-window-modal' : item.layerIndex === 2 }">
              <div *ngIf="item.layerIndex === 2"
                class="wt-dashboard-window wt-dashboard-window-type--frozen"
                [ngClass]="currentDashboard.locked ? 'wt-locked' : 'draggable'" draggable
                gridster-item-content></div>

              <wt-dashboard-window *ngIf="(reloadAction | async) === false"
                [window]="item.window"
                [dashboard]="currentDashboard"></wt-dashboard-window>
            </section>
          </gridster-item>
        </gridster>
      </div>

      <div class="wt-dashboard-background-shade" [@opacityAnimation] *ngIf="config.allowMultiLayer"></div>
    </ng-container>
  </ng-container>

  <ng-template #dashboardGuardMessagePlaceholder>
    <div class="wt-flex-row wt-flex-align-center wt-flex-align-middle">
      <div class="wt-dashboard__guard-message">
        {{ dashboardGuardMessage.result }}
      </div>
    </div>
  </ng-template>
</ng-container>