import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PerformanceBarModule } from '../../shared/performance-bar/performance-bar.module';
import { ChartWrapperModule } from '../../wrappers/chart-wrapper/chart-wrapper.module';
import { StaticColumnsGridModule } from '../../wrappers/grid-wrappers/static-columns-grid/static-columns-grid.module';
import { PerformanceComponent } from './performance.component';

@NgModule({
  declarations: [PerformanceComponent],
  imports: [CommonModule, PerformanceBarModule, ChartWrapperModule, StaticColumnsGridModule],
})
export class PerformanceModule {}
