import { Injectable, inject } from '@angular/core';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { SdkService } from '../../services/sdk.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { Column } from '../grid/columns.model';
import { ProgressService, trackProgress } from '../progress';
import { ColumnCategories, MorningStarOnlyColumns } from './fund-screener.columns';
import { FieldValuesObject, FundSearchState, InfrontMutualFunds, MorningstarFundStyles, SearchLimit } from './screener.model';

@Injectable({
  providedIn: 'root',
})
export class FundScreenerService {
  private readonly sdkService = inject(SdkService);
  private readonly userSettingsService = inject(UserSettingsService);

  private readonly searchStateAction = new BehaviorSubject<FundSearchState>({ selectedColumnCategoryIndex: 0, fields: {} });
  readonly searchState$ = this.searchStateAction.asObservable();

  updateSearchState(searchState: FundSearchState) {
    this.searchStateAction.next(searchState);
  }

  updateSearchFields(fields: FieldValuesObject) {

    const searchState = { fields, selectedColumnCategoryIndex: this.searchStateAction.getValue().selectedColumnCategoryIndex };
    this.searchStateAction.next(searchState);
    this.userSettingsService.setValue('fundScreenerSearchState', { ...this.userSettingsService.getValue('fundScreenerSearchState'), ...searchState });
  }

  updateSelectedColumnCategoryIndex(selectedColumnCategoryIndex: number) {
    this.searchStateAction.next({ ...this.searchStateAction.getValue(), selectedColumnCategoryIndex });
  }

  updateSelectedSortOrder(columns: Column[]) {
    const sortedColumn = columns.find(column => column.sort);
    if (sortedColumn) {
      const searchState = { ...this.searchStateAction.getValue(), resultSort: { colId: sortedColumn.colId, sort: sortedColumn.sort === 'asc' ? 'asc' as const : 'desc' as const } };
      this.userSettingsService.setValue('fundScreenerSearchState', searchState);
      return;
    }
    if (this.searchStateAction.getValue().resultSort) {
      const searchState = { ...this.searchStateAction.getValue(), resultSort: undefined };
      this.userSettingsService.setValue('fundScreenerSearchState', searchState);
    }
  }

  readonly feeds$ = this.sdkService.getObject$(InfrontSDK.feedList, { feedInfo: true } as Partial<InfrontSDK.FeedListOptions>).pipe(
    map((feeds: InfrontSDK.FeedInfo[]) => {
      const fundFeeds = feeds.filter(f => (f.additionalInfo?.MorningStarFunds || f.additionalInfo?.InfrontMutualFunds) && f.access !== 'No access');
      return fundFeeds;
    }));

  // generically used by all autocompletes in the fund screener instead of individual searches per field
  freeTextSearch$(
    searchString: string,
    searchFields: (InfrontSDK.BasicField | InfrontSDK.SearchField)[],
    resultFields?: (InfrontSDK.BasicField | InfrontSDK.SearchField | InfrontSDK.SearchResultField)[] | undefined) // use if different from first searchField
    : Observable<string[]> {
    const opts = {
      limit: -1, // no limit
      parameters: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        Feed: this.searchStateAction.getValue().fields.Feed, // we limit all freetextsearch by Feed only but no other parameters
        SearchFreeText: searchString,
        SearchFreeTextFields: searchFields,
        SearchItemType: 1 as unknown as InfrontSDK.ItemType,
      },

      fields: resultFields ?? [searchFields[0]],
    } as unknown as Partial<InfrontSDK.FieldSearchOptions<InfrontSDK.SymbolField>>;

    return this.sdkService.getObject$(InfrontSDK.fieldSearch, opts).pipe(
      map((result: string[]) => {
        const filteredResult = result.filter(item => (item.toLocaleLowerCase()).includes(searchString.toLocaleLowerCase()));  // search server filtering not good, we need to filter the result again
        return filteredResult;
      })
    );
  }

  readonly selectedColumns$ = this.searchStateAction.pipe(map((searchState => this.selectedColumnsBySearchState(searchState))));

  readonly screenerResult$ = (progress: ProgressService) => this.searchStateAction.pipe(switchMap((searchState) => {
    if (!searchState?.fields) {
      return of([]);
    }

    const fields = { ...searchState.fields };

    // SearchRanges when retrieved from settings can be in a wrong empty string format
    fields.SearchRangeLowerLimit = (fields.SearchRangeLowerLimit as unknown as string === '') ? undefined : fields.SearchRangeLowerLimit;
    fields.SearchRangeUpperLimit = (fields.SearchRangeUpperLimit as unknown as string === '') ? undefined : fields.SearchRangeUpperLimit;

    // search range without any limit should not be applied
    if (fields.SearchRange && (fields.SearchRangeLowerLimit == undefined && fields.SearchRangeUpperLimit == undefined)) {
      fields.SearchRange = undefined;
    }
    // search limit without any range should not be applied
    if (!fields.SearchRange && (fields.SearchRangeLowerLimit != undefined || fields.SearchRangeUpperLimit != undefined)) {
      fields.SearchRangeLowerLimit = undefined;
      fields.SearchRangeUpperLimit = undefined;
    }

    fields.SearchRangeLowerLimit = fields.SearchRangeLowerLimit != undefined ? +fields.SearchRangeLowerLimit : undefined;
    fields.SearchRangeUpperLimit = fields.SearchRangeUpperLimit != undefined ? +fields.SearchRangeUpperLimit : undefined;

    if (searchState.fields.Feed === InfrontMutualFunds) {
      fields.FundInvestmentStyle = undefined;
      fields.FundStarRating = undefined;
    }

    // remove empty fields
    const filteredFields = Object.keys(fields).reduce((acc, key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const item = fields[key];
      if (item != undefined && item !== '' && JSON.stringify(item) !== "[]") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        acc[key] = item;
      }
      return acc;
    }, {});

    if (Object.values(filteredFields).length < 2) {
      return of([]);
    }

    const opts = {
      limit: SearchLimit + 1, // + 1 to be able to check if we have more results than the threshold
      parameters: this.buildParams(filteredFields),
      fields: this.selectedColumnsBySearchState(searchState).filter(column => column.field).map((column) => column.field),
    } as unknown as Partial<InfrontSDK.FieldSearchOptions<InfrontSDK.SymbolField>>;

    return this.sdkService.getObject$(InfrontSDK.fieldSearch, opts).pipe(
      trackProgress({ label: 'screenerResult$', progress }),
      map((result => {
        const filteredResult = (result as unknown as { FullName: string }[]).filter((item) => item.FullName);
        const resultWithIndex = (filteredResult as object[]).map((item, index) => ({
          ...item,
          index: `${index}~${InfrontUtil.makeUUID()}`
        }));
        return resultWithIndex;
      })));
  }));

  readonly isInfrontFeed$ = this.searchStateAction.pipe(map((searchState => searchState.fields?.Feed === InfrontMutualFunds)));

  // available as hardcoded for performance
  readonly fundStyles$ = this.isInfrontFeed$.pipe(map((isInfrontFeed => isInfrontFeed ? [] : MorningstarFundStyles)));

  sourceByField$(symbolField: InfrontSDK.BasicField | InfrontSDK.SearchField): Observable<string[]> {
    return this.searchStateAction.pipe(
      map((searchState) => searchState.fields?.Feed),
      distinctUntilChanged(),
      switchMap((Feed) => {
        if (!Feed) {
          return of([]);
        }
        const opts = {
          parameters: { Feed }, // limit by feed only
          fields: [symbolField],
        } as unknown as Partial<InfrontSDK.FieldSearchOptions<InfrontSDK.SymbolField>>;

        return this.sdkService.getObject$(InfrontSDK.fieldSearch, opts).pipe(
          map((result) => {
            return result;
          }));
      })
    );
  }

  private buildParams(fields: InfrontSDK.SearchParameters<InfrontSDK.SearchField>): InfrontSDK.SearchParameters<InfrontSDK.SearchField> {

    if (fields.SearchFreeText) {
      // FullName is the only field we allow textsearch on for the screeners end result for performance reasons. Multiple field searches has been too slow in testing.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      fields.SearchFreeTextFields = [InfrontSDK.SymbolField.FullName] as any; // wrongly typed in SDK?
    }
    if (fields.FundTopHolding) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      fields.FundTopHolding = [fields.FundTopHolding] as any;
    }
    fields.SearchItemType = 1 as unknown as InfrontSDK.ItemType;
    return fields;
  }

  private selectedColumnsBySearchState(searchState: FundSearchState) {
    const isInfrontFeed = searchState.fields?.Feed === InfrontMutualFunds;
    const columnsByCategory = Object.values(ColumnCategories)[searchState.selectedColumnCategoryIndex];
    const selectedColumns = columnsByCategory.filter(column => isInfrontFeed ? !MorningStarOnlyColumns.includes(column.colId) : true);
    if (searchState.resultSort?.colId) {
      const sortedColumnIndex = selectedColumns.findIndex(column => column.colId === searchState.resultSort!.colId);
      if (sortedColumnIndex) {
        selectedColumns[sortedColumnIndex] = {
          ...selectedColumns[sortedColumnIndex], sort: searchState.resultSort.sort
        };
      }
    }
    return selectedColumns;
  }
}
