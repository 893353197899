<div class="wt-settings-menu-content">
  <div class="wt-flex-row wt-flex-align-middle">
    <div class="wt-flex-column">
      <h2>{{ 'SETTINGS_MENU_COMPONENT.TITLE' | translate }}</h2>
    </div>

    <div class="wt-flex-column wt-flex-shrink wt-flex-collapse">
      <button type="button" class="wt-button wt-settings-menu-content-close-button" (click)="closeOverlay()">
        <mat-icon fontIcon="close"></mat-icon>
      </button>
    </div>
  </div>

  <!-- LOCALIZATION -->
  <div class="wt-settings-menu-content__group">
    <div class="wt-settings-menu-content__group-header">
      <div class="wt-flex-row">
        <div class="wt-flex-column">
          {{ 'SETTINGS_MENU_COMPONENT.LOCALIZATION.TITLE' | translate }}
        </div>
      </div>
    </div>

    <div class="wt-settings-menu-content__group-fields">
      <div class="wt-flex-row wt-flex-align-middle wt-settings-menu-content__group-fields-item">
        <div class="wt-flex-column">
          <label class="wt-settings-menu-content__group-label">
            {{ 'SETTINGS_MENU_COMPONENT.LOCALIZATION.SELECT_LOCALE' | translate }}
          </label>
        </div>

        <div class="wt-flex-column">
          <mat-form-field class="wt-settings-menu-content__group-dropdown" floatLabel="never">
            <mat-select class="wt-dialog__select" panelClass="wt-dialog__select-panel" [(value)]="selectedLocale"
              disableOptionCentering
              (selectionChange)="changeLocale($event.value)">
              <mat-option *ngFor="let locale of localeOptions" [value]="locale.value">
                {{ ('SETTINGS_MENU_COMPONENT.LOCALIZATION.LOCALES.' + locale.viewValue) | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <wt-general-settings [userSettingsService]="userSettingsService"></wt-general-settings>
</div>

<ng-container *ngIf="backdropClick$ | async"></ng-container>
<ng-container *ngIf="localeId$ | async"></ng-container>