import { ChangeDetectionStrategy, Component, Input, OnDestroy, inject } from '@angular/core';
import { ReplaySubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { StoreService } from '../../services/store.service';
import { ContextMenuItem } from '../../shared/models/context-menu.model';
import { Dashboard } from '../../state-model/dashboard.model';

@Component({
  selector: 'wt-other-dashboards-context-menu',
  templateUrl: './other-dashboards-context-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherDashboardsContextMenuComponent implements OnDestroy {
  private readonly storeService: StoreService = inject(StoreService);

  private readonly dashboardsAction = new ReplaySubject<(Dashboard & { hasSpace: boolean })[]>(1);
  @Input() set dashboards(dashboards: (Dashboard & { hasSpace: boolean })[]) {
    this.dashboardsAction.next(dashboards);
  }

  private selectedDashboardAction = new ReplaySubject<Dashboard>(1);
  @Input() set selectedDashboard(selectedDashboard: Dashboard) {
    this.selectedDashboardAction.next(selectedDashboard);
  }

  readonly menuItems$ = combineLatest([this.dashboardsAction, this.selectedDashboardAction]).pipe(map(([dashboards, selectedDashboard]) =>
    dashboards.filter(db => !db.hasSpace).map(db => ({
      closeOnClick: true,
      id: 'newEmptyDashboard',
      title: db.name,
      context: db,
      className: db.id === selectedDashboard.id ? 'wt-dashboard-tabs__item--selected' : undefined,
    }) as ContextMenuItem)
  ));

  ngOnDestroy(): void {
    this.dashboardsAction.complete();
    this.selectedDashboardAction.complete();
  }

  onItemClick(item: ContextMenuItem): void {
    this.storeService.selectDashboard(item.context as Dashboard);
  }
}


