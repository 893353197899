import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfrontSDK } from '@infront/sdk';
import { LogService } from '@vwd/ngx-logging';
import { combineLatest, NEVER, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { DashboardService } from '../../../dashboard/dashboard.service';
import { InstrumentDashboardService } from '../../../dashboard/instrument-dashboard.service';
import { AlertService } from '../../../services/alert.service';
import { ConfirmDialogService } from '../../../services/confirm-dialog.service';
import { SdkRequestsService } from '../../../services/sdk-requests.service';
import { SdkService } from '../../../services/sdk.service';
import { SecureMatDialogService } from '../../../services/secure-mat-dialog.service';
import { StoreService } from '../../../services/store.service';
import { TradableService } from '../../../services/tradable.service';
import { TradingOrderEntryService } from '../../../services/trading-order-entry.service';
import { TradingService } from '../../../services/trading.service';
import { WatchlistService } from '../../../services/watchlist.service';
import { ColumnPickerDialogComponent } from '../../../shared/column-picker-dialog/column-picker-dialog.component';
import { Column } from '../../../shared/grid/columns.model';
import { ContextMenuClickHandler, ContextMenuContext, ContextMenuItem, ContextMenuItemKeys } from '../../../shared/models/context-menu.model';
import { TradingOrderInfoService } from '../../../shared/trading-order-info/trading-order-info.service';
import { TradingSymbolMappingInfoService } from '../../../shared/trading-symbol-mapping-info/trading-symbol-mapping-info.service';
import { DashboardType } from '../../../state-model/dashboard.model';
import { GridColumnsMap } from '../../../state-model/grid.model';
import { ChainsWidget, ChartMiniWidget, CompanyInformationWidget, ListsWidget, MarketOverviewWidget, OrderbookWidget, PerformanceWidget, UserlistWidget, WatchlistWidget, Widget } from '../../../state-model/widget.model';
import { CalendarWindow, DashboardWindow, isInstrumentSettings, NewsWindow, WatchlistWindow } from '../../../state-model/window.model';
import { fullSelectedColumnsByGrid, getColumnSettingsFromColDef, selectedColumnsByGridName } from '../../../util/grid';
import { translator } from '../../../util/locale';
import { filterUndefined } from '../../../util/rxjs';
import { callIfFunction, isSameObject } from '../../../util/utils';
import { ListsService } from '../../../widgets/lists/lists.service';
import { AdditionalSymbolFields } from '../../../widgets/lists/observe-symbols.service';
import { createWidgetGridRef } from '../../../wrappers/grid-wrappers/gridref';
import { WidgetMenuMap, WindowItems, WindowItemTypeOptions, WindowMenuBottomMap, WindowMenuMap, WindowSymbolMenuMap } from './context-menu.model';
import {
  addAlertItem,
  addToWatchlistItem,
  addWindowItem,
  buySellItem,
  calendarTypeItem,
  cloneWindowItem,
  deleteOrderItem,
  instrumentDashboardItem,
  modifyOrderItem,
  newsTypeItem,
  orderInfoItem,
  removeFromWatchlistItem,
  symbolMappingInfoItem
} from './window-menu-items';
import { addListAlert } from './window-menu-items/add-alert';
import { removeFromUserlistItem } from './window-menu-items/remove-from-userlist';
import { showChainInfo } from './window-menu-items/show-chain-info';

@Injectable({
  providedIn: 'root',
})
export class ContextMenuService {
  private readonly dashboardService = inject(DashboardService);
  private readonly instrumentDashboardService = inject(InstrumentDashboardService);
  private readonly storeService = inject(StoreService);
  private readonly sdkService = inject(SdkService);
  private readonly sdkRequestsService = inject(SdkRequestsService);
  private readonly watchlistService = inject(WatchlistService);
  private readonly tradingService = inject(TradingService);
  private readonly tradableService = inject(TradableService);
  private readonly secureMatDialogService = inject(SecureMatDialogService);
  private readonly alertService = inject(AlertService);
  private readonly tradingOrderEntryService = inject(TradingOrderEntryService);
  private readonly tradingOrderInfoService = inject(TradingOrderInfoService);
  private readonly tradingSymbolMappingInfoService = inject(TradingSymbolMappingInfoService);
  private readonly confirmDialogService = inject(ConfirmDialogService);
  private readonly dialogService = inject(MatDialog);
  private readonly listsService = inject(ListsService);

  private readonly xlat = translator();
  private readonly logger = inject(LogService).openLogger('service/context-menu');
  private itemUpdate: ContextMenuClickHandler = (params) => {
    if (!params.widget || !params.item) {
      return;
    }
    const settings = { ...params.widget?.settings, [params.item.id]: !params.item.checked };
    this.storeService.updateWidget(params.widget, { settings });
  };

  private windowItems: WindowItems = {
    instrumentPage: (window: DashboardWindow) => instrumentDashboardItem(window, this.instrumentDashboardService),
    addWindows: (window: DashboardWindow, options: WindowItemTypeOptions) => addWindowItem(window, options, this.dashboardService),
    buySell: (window: DashboardWindow, options: WindowItemTypeOptions) => buySellItem(window, options, this.tradingOrderEntryService),
    addToWatchlist: (window: DashboardWindow, options: WindowItemTypeOptions) => addToWatchlistItem(window, options, this.watchlistService, this.xlat),
    removeFromWatchlist: (window: WatchlistWindow, options: WindowItemTypeOptions) => removeFromWatchlistItem(window, options, this.watchlistService, this.logger),
    removeFromUserlist: (window: WatchlistWindow, options: WindowItemTypeOptions) => removeFromUserlistItem(window, options, this.storeService),
    addAlert: addAlertItem(this.alertService),
    newsType: (window: NewsWindow) => newsTypeItem(window, this.storeService),
    calendarType: (window: CalendarWindow) => calendarTypeItem(window, this.storeService),
    addListAlert: addListAlert(this.alertService, this.watchlistService),
    symbolMappingInfo: () => symbolMappingInfoItem(this.tradingSymbolMappingInfoService),
  };

  private windowBottomItems: WindowItems = {
    showChainInfo: (window: DashboardWindow, options: WindowItemTypeOptions) => showChainInfo(window, options, this.storeService, this.listsService, this.dialogService),
    cloneWindow: (window: DashboardWindow, options: WindowItemTypeOptions) => cloneWindowItem(window, options, this.dashboardService),
  };

  private widgetItems: { [key in ContextMenuItemKeys]?: (widget: Widget) => ContextMenuItem } = {
    showSymbolHeader: (widget: CompanyInformationWidget) => ({
      id: 'showSymbolHeader',
      title: 'CONTEXT_MENU_COMPONENT.COMPANY_INFORMATION.SHOW_SYMBOL_HEADER',
      icon: 'vertical_align_top',
      type: 'switch',
      checked: widget.settings.showSymbolHeader,
      onClick: this.itemUpdate,
    }),
    showTopBar: (widget: OrderbookWidget) => ({
      id: 'showTopBar',
      title: 'CONTEXT_MENU_COMPONENT.ORDERBOOK.SHOW_TOP_BAR',
      icon: 'vertical_align_top',
      type: 'switch',
      checked: widget.settings.showTopBar,
      onClick: this.itemUpdate,
    }),
    // showCompactView: (widget: OrderbookWidget) => ({
    //   id: 'showCompactView',
    //   title: 'CONTEXT_MENU_COMPONENT.ORDERBOOK.SHOW_COMPACT_VIEW',
    //   icon: 'compress',
    //   type: 'switch',
    //   checked: widget.settings.showCompactView,
    //   onClick: this.itemUpdate,
    // }),
    yellowTopLevel: (widget: OrderbookWidget) => ({
      id: 'yellowTopLevel',
      title: 'CONTEXT_MENU_COMPONENT.ORDERBOOK.YELLOW_TOP_LEVEL',
      icon: 'maximize',
      type: 'switch',
      checked: widget.settings.yellowTopLevel,
      onClick: this.itemUpdate,
    }),
    boldTopLevel: (widget: OrderbookWidget) => ({
      id: 'boldTopLevel',
      title: 'CONTEXT_MENU_COMPONENT.ORDERBOOK.BOLD_TOP_LEVEL',
      icon: 'format_bold',
      type: 'switch',
      checked: widget.settings.boldTopLevel,
      onClick: this.itemUpdate,
    }),
    showOrderAmount: (widget: OrderbookWidget) => ({
      id: 'showOrderAmount',
      title: 'CONTEXT_MENU_COMPONENT.ORDERBOOK.SHOW_ORDER_AMOUNT',
      icon: 'tag',
      type: 'switch',
      checked: widget.settings.showOrderAmount,
      onClick: this.itemUpdate,
    }),
    showDividendsLine: (widget: PerformanceWidget) => ({
      id: 'showDividendsLine',
      title: 'CONTEXT_MENU_COMPONENT.PERFORMANCE.SHOW_DIVIDEND_LINE',
      icon: 'auto_graph', // alternatives: insights, line_axis, ssid_chart, timeline
      type: 'switch',
      checked: widget.settings.showDividendsLine,
      onClick: this.itemUpdate,
    }),
    showChart: (widget: MarketOverviewWidget) => ({
      id: 'showChart',
      title: 'CONTEXT_MENU_COMPONENT.MARKET_OVERVIEW.SHOW_CHART',
      icon: 'show_chart',
      type: 'switch',
      checked: widget.settings.showChart,
      onClick: this.itemUpdate,
    }),
    flexChart: (widget: MarketOverviewWidget) => ({
      id: 'flexChart',
      title: 'CONTEXT_MENU_COMPONENT.MARKET_OVERVIEW.FLEX_CHART',
      icon: 'height',
      type: 'switch',
      checked: widget.settings.flexChart,
      onClick: this.itemUpdate,
    }),
    showTabs: (widget: MarketOverviewWidget) => ({
      id: 'showTabs',
      title: 'CONTEXT_MENU_COMPONENT.MARKET_OVERVIEW.SHOW_TABS',
      icon: 'tab',
      type: 'switch',
      checked: widget.settings.showTabs,
      onClick: this.itemUpdate,
    }),
    showNoAccessInstruments: (widget: MarketOverviewWidget) => ({
      id: 'showNoAccessInstruments',
      title: 'CONTEXT_MENU_COMPONENT.MARKET_OVERVIEW.SHOW_NO_ACCESS',
      icon: 'block',
      type: 'switch',
      checked: widget.settings.showNoAccessInstruments,
      onClick: this.itemUpdate,
      iconClassName: 'wt-truncate',
    }),
    showChartTypeMenu: (widget: ChartMiniWidget) => ({
      id: 'showChartTypeMenu',
      title: 'CONTEXT_MENU_COMPONENT.CHART.SHOW_CHART_TYPE_MENU',
      icon: 'area_chart',
      type: 'switch',
      checked: widget.settings.showChartTypeMenu,
      onClick: this.itemUpdate,
    }),
    showResolutionMenu: (widget: ChartMiniWidget) => ({
      id: 'showResolutionMenu',
      title: 'CONTEXT_MENU_COMPONENT.CHART.SHOW_RESOLUTION_MENU',
      icon: 'timeline',
      type: 'switch',
      checked: widget.settings.showResolutionMenu,
      onClick: this.itemUpdate,
    }),
    showCompareMenu: (widget: ChartMiniWidget) => ({
      id: 'showCompareMenu',
      title: 'CONTEXT_MENU_COMPONENT.CHART.SHOW_COMPARE_MENU',
      icon: 'ssid_chart',
      type: 'switch',
      checked: widget.settings.showCompareMenu,
      onClick: this.itemUpdate,
    }),
    showSettingsMenu: (widget: ChartMiniWidget) => ({
      id: 'showSettingsMenu',
      title: 'CONTEXT_MENU_COMPONENT.CHART.SHOW_SETTINGS_MENU',
      icon: 'settings_applications',
      type: 'switch',
      checked: widget.settings.showSettingsMenu,
      onClick: this.itemUpdate,
    }),
    columnPicker: (widget: Widget) => ({
      id: 'columnPicker',
      title: 'CONTEXT_MENU_COMPONENT.GRID.COLUMN_PICKER',
      icon: 'view_column',
      isSVGIcon: true,
      closeOnClick: true,
      onClick: () => {
        // TODO: can we find a cleaner way of getting the GridRef here? What if widget is not from a StoreService?
        const gridRef = createWidgetGridRef(this.storeService, widget);
        return gridRef.grid$
          .pipe(
            filterUndefined(),
            take(1),
            switchMap((grid) => {
              const { dialogRef, subscriptions } = (this.secureMatDialogService
                .open(
                  'selectedColumns.colId',
                  'result.colId',
                  {
                    header: 'CONTEXT_MENU_COMPONENT.COLUMN_PICKER.SECURE_DIALOG.HEADER',
                    message: 'CONTEXT_MENU_COMPONENT.COLUMN_PICKER.SECURE_DIALOG.PROMPT',
                    cancelButtonLabel: 'CONTEXT_MENU_COMPONENT.COLUMN_PICKER.SECURE_DIALOG.CANCEL',
                    okButtonLabel: 'CONTEXT_MENU_COMPONENT.COLUMN_PICKER.SECURE_DIALOG.OK',
                    icon: 'view_column',
                    isSVGIcon: true,
                  },
                  ColumnPickerDialogComponent,
                  {
                    data: {
                      selectedColumns: fullSelectedColumnsByGrid(grid),
                      columnCategories: GridColumnsMap[grid.name].categories ?? [{ columns: GridColumnsMap[grid.name].all }],
                    },
                    height: '450px',
                    maxHeight: '450px',
                  }
                )
              );

              return dialogRef.afterClosed().pipe(
                take<Column[]>(1),
                finalize(() => Object.values(subscriptions).forEach((sub) => sub?.unsubscribe())), // NOSONAR
                filter((selectedColumns) => !!selectedColumns),
                map((selectedColumns) => ({ selectedColumns, grid })),
              );
            })
          )
          .subscribe({
            next: ({ selectedColumns, grid }) => {
              const settings = { ...grid.settings, selectedColumns: getColumnSettingsFromColDef(selectedColumns) };
              gridRef.onColumnsChanged({ ...grid, settings });
            },
            complete: () => gridRef.destroy(),
          });
      },
    }),
    resetColumnsSingleGrid: (widget: Widget) => ({
      id: 'resetColumnsSingleGrid',
      title: 'CONTEXT_MENU_COMPONENT.GRID.RESET_COLUMNS',
      icon: 'restart_alt',
      closeOnClick: true,
      onClick: () => {
        const gridRef = createWidgetGridRef(this.storeService, widget);
        return gridRef.grid$
          .pipe(
            filterUndefined(),
            take(1),
            switchMap(grid => {
              return this.confirmDialogService.confirmDialog$(
                {
                  header: 'CONFIRMATION_PROMPT.GRID.RESET_COLUMNS',
                  message: 'CONFIRMATION_PROMPT.GRID.RESET_COLUMNS_CONFIRMATION',
                  disablePromptSettingKey: 'disableSinglePortfolioGridRestoreSettingsPrompt',
                  icon: 'restart_alt'
                }
              ).pipe(
                tap(result => {
                  if (result) {
                    const defaultColumns = selectedColumnsByGridName(grid.name) as Column[];
                    const settings = { ...grid.settings, selectedColumns: getColumnSettingsFromColDef(defaultColumns) };
                    gridRef.onColumnsChanged({ ...grid, settings });
                  }
                })
              );
            })).subscribe();
      },
    }),
    resetColumnsInDashboard: () => ({
      id: 'resetColumnsInDashboard',
      title: 'CONTEXT_MENU_COMPONENT.GRID.RESET_ALL_COLUMNS_IN_DASHBOARD',
      icon: 'restart_alt',
      closeOnClick: true,
      onClick: () => this.confirmDialogService.confirmDialog$(
        {
          header: 'CONFIRMATION_PROMPT.GRID.RESET_ALL_COLUMNS_IN_DASHBOARD',
          message: 'CONFIRMATION_PROMPT.GRID.RESET_ALL_COLUMNS_IN_DASHBOARD_CONFIRMATION',
          disablePromptSettingKey: 'disableAllPortfolioGridsRestoreSettingsPrompt',
          icon: 'restart_alt'
        }
      ).pipe(
        switchMap(result => {
          if (result) {
            return this.storeService.currentGrids$.pipe(take(1),
              map(grids => {
                grids.filter(grid => !!grid.settings).forEach(grid => { // NOSONAR
                  const defaultColumns = selectedColumnsByGridName(grid.name) as Column[];
                  const settings = { ...grid.settings, selectedColumns: getColumnSettingsFromColDef(defaultColumns) };
                  this.storeService.updateGrid(grid, { settings });
                });
              }));
          }
          return of(undefined);
        }),
        take(1)
      ).subscribe()
    }),
    showCheckboxes: (widget: WatchlistWidget | UserlistWidget | ListsWidget) => ({
      id: 'showCheckboxes',
      title: 'CONTEXT_MENU_COMPONENT.GRID.SHOW_CHECKBOXES',
      icon: 'check_box',
      type: 'switch',
      checked: widget.settings.showCheckboxes,
      onClick: this.itemUpdate,
    }),
    toggleAdminMode: (widget: ChainsWidget | UserlistWidget) => ({
      id: 'toggleAdminMode',
      title: 'Admin mode',
      checked: widget.settings?.adminMode ?? true,
      type: 'switch',
      icon: 'manage_accounts',
      onClick: () => {
        const settings = {
          ...widget.settings,
          adminMode: !widget.settings?.adminMode,
        };

        this.storeService.updateWidget(widget, { settings });
      },
    }),
  };

  windowAndWidgetItems$ = (context: ContextMenuContext): Observable<ContextMenuItem[]> => {
    return combineLatest([this.window$(context).pipe(tap(t => {
      t;
    })), this.tradingService.tradingConnected$, this.watchlistService.watchlistsByProviders$]).pipe(
      tap(([window, _, watchlistsByProviders]) => {
        window;
      }),
      switchMap(([window, _, watchlistsByProviders]) =>
        combineLatest([this.storeService.selectedWidgetInWindow$(window), of(window), of(watchlistsByProviders)])),

      distinctUntilChanged(isSameObject),
      tap(([widget, window, watchlistsByProviders]) => {
        this.logger.debug('windowAndWidgetItems$', { widget, window, watchlistsByProviders });
        widget;
        window;
      }),
      switchMap(([widget, window, watchlistsByProviders]) => {
        widget;
        window;
        const obs = (
          isInstrumentSettings(window.settings)
            ? this.sdkRequestsService.snapshotSymbolData$({ symbolEntity: widget, fields: [InfrontSDK.SymbolField.SymbolClassification] })
            : of(undefined) as unknown as Observable<{ SymbolClassification: InfrontSDK.SymbolClassification | undefined; } & AdditionalSymbolFields>
        );
        return obs.pipe(
          switchMap((symbolData) => {
            const options: WindowItemTypeOptions = { classification: symbolData?.SymbolClassification, watchlistByProvider: watchlistsByProviders };
            const windowItems = (WindowMenuMap[window.name] ?? []).map(
              (itemId) => callIfFunction(this.windowItems[itemId], [window, options])
            ) as ContextMenuItem[];
            const widgetItems = (WidgetMenuMap(widget)[widget?.name] ?? []).map((itemId) => {
              return this.widgetItems[itemId]!(widget);
            });
            const windowBottomItems = (WindowMenuBottomMap[window.name] ?? []).map(
              (itemId) => callIfFunction(this.windowBottomItems[itemId], [window, options])
            ) as ContextMenuItem[];
            return this.filterItems$([...windowItems, ...widgetItems, ...windowBottomItems], { ...context, symbol: symbolData?.symbol });
          })
        );
      })
    );
  };

  symbolItems$ = (context: ContextMenuContext): Observable<ContextMenuItem[]> => {
    return combineLatest([this.window$(context), this.watchlistService.watchlistsByProviders$]).pipe(
      distinctUntilChanged(isSameObject),
      switchMap(([window, watchlistByProvider]) => {
        return this.sdkService.getObject$<InfrontSDK.SymbolDataOptions>(InfrontSDK.symbolData, { id: context.instrument, content: { Basic: true } } as Partial<InfrontSDK.SymbolDataOptions>).pipe(
          switchMap((symbolData) => {
            const classification = symbolData.get(InfrontSDK.SymbolField.SymbolClassification);
            const instrument = context.instrument ?? (isInstrumentSettings(window.settings) ? window.settings?.instrument : undefined);
            const options: WindowItemTypeOptions = { classification, watchlistByProvider, instrument };
            const symbolItems = (WindowSymbolMenuMap[window.name] ?? []).map(
              (itemId) => callIfFunction(this.windowItems[itemId], [window, options])
            ) as ContextMenuItem[];
            return this.filterItems$(symbolItems, { ...context, symbol: symbolData });
          })
        );
      })
    );
  };

  tradingOrderItems$ = (context: ContextMenuContext): Observable<ContextMenuItem[]> => {
    const order = context.tradingOrderGetter?.();
    if (!order) {
      return of([]);
    }

    const orderMenuMapKey = [InfrontSDK.Trading.OrderState.Executed, InfrontSDK.Trading.OrderState.Deleted].includes(order.OrderStatus) ? 'nonActive' : 'active';
    const itemsMap = {
      active: [
        modifyOrderItem(this.tradingOrderEntryService),
        deleteOrderItem(this.tradingOrderEntryService),
        orderInfoItem(this.tradingOrderInfoService),
      ],
      nonActive: [orderInfoItem(this.tradingOrderInfoService)]
    };
    const items = itemsMap[orderMenuMapKey] ?? [];
    return of(items);
  };

  tradingPositionUnmappedItems$ = (context: ContextMenuContext): Observable<ContextMenuItem[]> => {
    // mapped trading-position items are handled in `symbolItems$` as defined in context-menu.model!
    const position = context.tradingPositionGetter?.();
    if (!position) {
      return of([]);
    }

    return of([symbolMappingInfoItem(this.tradingSymbolMappingInfoService)]);
  };

  filterItems$(items: ContextMenuItem[], context: ContextMenuContext): Observable<ContextMenuItem[]> {
    if (items.find(item => item?.id === 'columnPicker') && context.dashboard?.locked && context.dashboard.type !== DashboardType.portfolio) {
      items = items.filter(item => item?.id !== 'columnPicker');
    }
    if (items.find(item => item?.id === 'toggleAdminMode') && context.dashboard?.locked) {
      items = items.filter(item => item?.id !== 'toggleAdminMode');
    }
    if (items.find(item => item?.id === 'buySell')) {
      const { symbol, instrument } = context;
      const classification = context.symbol ? context.symbol?.get(InfrontSDK.SymbolField.SymbolClassification) : undefined;
      const params = { symbol, instrument, classification };
      return this.tradableService.isTradable$({ ...params }).pipe(map(isTradable => items.filter(item => item?.id !== 'buySell' || isTradable)));
    }
    return of(items);
  }

  private window$(context: ContextMenuContext): Observable<DashboardWindow> {
    if ('widget' in context && context.widget) {
      return this.storeService.windowByWidget$(context.widget);
    }
    if ('window' in context && context.window?.id) {
      return this.storeService.window$(context.window);
    }
    if ('id' in context) {
      return this.storeService.window$(context as DashboardWindow);
    }
    if ('window' in context && context.window) {
      return of(context.window);
    }
    return NEVER;
  }

  window(context: ContextMenuContext): DashboardWindow | undefined {
    if ('widget' in context && context.widget) {
      return this.storeService.windowByWidget(context.widget);
    }
    if ('window' in context && context.window) {
      return this.storeService.window(context.window);
    }
    return this.storeService.window(context as DashboardWindow);
  }

  widget(context: ContextMenuContext): Widget | undefined {
    if ('widget' in context && context.widget) {
      return this.storeService.widget(context.widget);
    }
    return undefined;
  }
}
