<wt-selected-link-channel *ngIf="!isActive" [dashboard]="dashboard" [window]="window" (click)="activate()">
</wt-selected-link-channel>

<wt-selected-link-channel *ngIf="isActive" #matMenuTrigger [dashboard]="dashboard" [window]="window" [matMenuTriggerFor]="menu">
</wt-selected-link-channel>
<mat-menu #menu="matMenu" class="menu-no-padding link-channel" xPosition="before">
  <button class="link-channel__item"
    mat-menu-item
    *ngFor="let linkChannel of linkChannelOptions()"
    (click)="onLinkChannelClick(linkChannel, window); onLinkChannelHover(undefined)"
    (mouseenter)="onLinkChannelHover(linkChannel)"
    (mouseleave)="onLinkChannelHover(undefined)">
    <span class=" link-channel__item-label">{{ linkChannel }}</span>
    <ng-container [ngSwitch]="linkChannel">
      <mat-icon *ngSwitchCase="'Channel 1'" class="link-channel__item-icon link-channel--channel1-icon"
        fontIcon="link"></mat-icon>
      <mat-icon *ngSwitchCase="'Channel 2'" class="link-channel__item-icon link-channel--channel2-icon"
        fontIcon="link"></mat-icon>
      <mat-icon *ngSwitchCase="'Channel 3'" class="link-channel__item-icon link-channel--channel3-icon"
        fontIcon="link"></mat-icon>
      <mat-icon *ngSwitchCase="'Channel 4'" class="link-channel__item-icon link-channel--channel4-icon"
        fontIcon="link"></mat-icon>
      <mat-icon *ngSwitchCase="'All'" class="link-channel__item-icon link-channel--all-icon" fontIcon="link"></mat-icon>
      <mat-icon *ngSwitchCase="'Unlink'" class="link-channel__item-icon link-channel--unlink-icon"
        fontIcon="link_off"></mat-icon>
    </ng-container>
  </button>
</mat-menu>
