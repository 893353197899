import { neutralLocale, normalizeLocale } from '@vwd/ngx-i18n';

export const WILDCARD_LOCALE_ID = '*';
export const EN_LOCALE_ID = 'en';

const LOCALE_COMPAT_CACHE = new Map<string, string[]>();

/**
 * Given an IETF language tag, returns a list of language tags to probe
 * when looking for translations, taking fallbacks into account.
 *
 * Currently has basic support for always falling back to
 * `*-<region>`, `en-<region>, `<language>`, `*`, `en`.
 *
 * If no region is present in the input language tag, no region scoped
 * tags are returned either.
 *
 * Does *not* support things like scripts (`sr-Latn`), macroregions ("Europe"),
 * 3-letter or numeric language codes ("ger"/"deu"), nor any extension
 * tags. Just plain 2-letter ISO code + 2-letter country code.
 *
 * @param localeId The IETF language tag to get the full probing list for.
 * @returns The list of (normalized) IETF language codes to probe, in order
 * of precedence, including the original {@localeId} (normalized), and
 * wildcard entries ("*-<region>", "*").
 */
export function getCompatibleLocales(localeId: string): readonly string[] {
  if (!localeId) {
    localeId = EN_LOCALE_ID;
  }

  const cache = LOCALE_COMPAT_CACHE;

  const match = cache.get(localeId);
  if (match) {
    return match;
  }

  const normalizedId = normalizeLocale(localeId);
  const neutralId = neutralLocale(normalizedId);
  const regionId = normalizedId.substring(neutralId.length + 1);

  const fallbacks = regionId
    ? expandWithRegion(neutralId, regionId)
    : expandNoRegion(normalizedId);

  cache
    .set(normalizedId, fallbacks)
    .set(localeId, fallbacks);

  return fallbacks;
}

function expandNoRegion(localeId: string): string[] {
  if (localeId === EN_LOCALE_ID) {
    return [EN_LOCALE_ID, WILDCARD_LOCALE_ID];
  }
  return [localeId, WILDCARD_LOCALE_ID, EN_LOCALE_ID];
}

function expandWithRegion(localeId: string, regionId: string): string[] {
  if (localeId === EN_LOCALE_ID) {
    return [
      EN_LOCALE_ID + '-' + regionId,
      WILDCARD_LOCALE_ID + '-' + regionId,
      EN_LOCALE_ID,
      WILDCARD_LOCALE_ID,
    ];
  }
  return [
    localeId + '-' + regionId,
    WILDCARD_LOCALE_ID + '-' + regionId,
    EN_LOCALE_ID + '-' + regionId,
    localeId,
    WILDCARD_LOCALE_ID,
    EN_LOCALE_ID,
  ];
}
