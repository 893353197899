<div class="wt-news">
  <div class="wt-flex-row wt-flex-align-middle">
    <div class="wt-flex-column wt-flex-shrink">
      <input
        type="text"
        class="wt-news__form-field"
        value="{{ widget.settings.textFilter }}"
        #textFilterInput
        placeholder="{{ 'GLOBAL.SEARCH' | translate }}" />
    </div>

    <div class="wt-flex-column wt-flex-shrink">
      <section class="wt-news__filter-boxes">
        <mat-checkbox class="wt-news__filter-boxes__checkbox" (change)="onShowNews($event)"
          [checked]="widget.settings.showNews">News</mat-checkbox>
        <mat-checkbox class="wt-news__filter-boxes__checkbox" (change)="onShowFlashNews($event)"
          [checked]="widget.settings.showFlashNews">Flash News</mat-checkbox>
        <mat-checkbox class="wt-news__filter-boxes__checkbox" (change)="onShowResearchNews($event)"
          [checked]="widget.settings.showResearchNews">Research</mat-checkbox>
      </section>
    </div>
  </div>

  <div *ngIf="vm$ | async as vm" class="wt-flex-row wt-news__content">
    <div class="wt-flex-column wt-flex-collapse" [ngSwitch]="vm.newsType">
      <!-- NOTE we need to keep those three grids separate, else the stored settings get confused! -->
      <wt-static-columns-grid
        *ngSwitchCase="'Instrument'"
        [flushTransactions]="false"
        [data]="vm.headlines"
        [columns]="vm.columns"
        [gridSettings]="{ sizeColumnsToFit: true }"
        [gridOptions]="vm.options"
        [context]="widget"
        (gridApi)="onGridApi($event)"
        (rowClickedEvent)="openNewsStory($event.data)"
        (showMore)="onShowMore()"></wt-static-columns-grid>
      <wt-static-columns-grid
        *ngSwitchCase="'Watchlist'"
        [flushTransactions]="false"
        [data]="vm.headlines"
        [columns]="vm.columns"
        [gridSettings]="{ sizeColumnsToFit: true }"
        [gridOptions]="vm.options"
        [context]="widget"
        (gridApi)="onGridApi($event)"
        (rowClickedEvent)="openNewsStory($event.data)"
        (showMore)="onShowMore()"></wt-static-columns-grid>
      <wt-static-columns-grid
        *ngSwitchCase="'Country'"
        [flushTransactions]="false"
        [data]="vm.headlines"
        [columns]="vm.columns"
        [gridSettings]="{ sizeColumnsToFit: true }"
        [gridOptions]="vm.options"
        [context]="widget"
        (gridApi)="onGridApi($event)"
        (rowClickedEvent)="openNewsStory($event.data)"
        (showMore)="onShowMore()"></wt-static-columns-grid>
      <ng-container *ngSwitchCase="'Portfolio'">
        <wt-trading-login *ngIf="(tradingConnected$ | async) !== true"
          class="wt-flex wt-full-height wt-flex-align-middle"></wt-trading-login>
        <wt-static-columns-grid
          *ngIf="tradingConnected$ | async"
          [flushTransactions]="false"
          [data]="vm.headlines"
          [columns]="vm.columns"
          [gridSettings]="{ sizeColumnsToFit: true }"
          [gridOptions]="vm.options"
          [context]="widget"
          (gridApi)="onGridApi($event)"
          (rowClickedEvent)="openNewsStory($event.data)"
          (showMore)="onShowMore()"></wt-static-columns-grid>
      </ng-container>

    </div>
  </div>
</div>
