import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { DirectivesModule } from '../directives/directives.module';
import { CountryFlagModule } from '../shared/country-flag/country-flag.module';
import {
  CompactSearchContainerComponent,
} from './compact-search/compact-search-container/compact-search-container.component';
import { CompactSearchComponent } from './compact-search/compact-search.component';
import { SearchComponent } from './search.component';
import { SearchItemSymbolComponent } from './components/search-item/search-item-symbol/search-item-symbol.component';
import { SearchItemMarketComponent } from './components/search-item/search-item-market/search-item-market.component';
import { SearchItemWindowComponent } from './components/search-item/search-item-window/search-item-window.component';

@NgModule({
  declarations: [SearchComponent, CompactSearchComponent, CompactSearchContainerComponent, SearchItemSymbolComponent, SearchItemMarketComponent, SearchItemWindowComponent],
  imports: [
    CommonModule,
    DirectivesModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatTabsModule,
    CountryFlagModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [SearchComponent, CompactSearchComponent, CompactSearchContainerComponent, SearchItemSymbolComponent, SearchItemMarketComponent, SearchItemWindowComponent],
})
export class SearchModule { }
