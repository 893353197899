import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CircleScoreComponent } from './circle-score.component';

@NgModule({
  declarations: [CircleScoreComponent],
  imports: [CommonModule],
  exports: [CircleScoreComponent],
})
export class CircleScoreModule { }
