
import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, Renderer2, ViewChild, inject } from '@angular/core';
import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import { ReplaySubject } from 'rxjs';

import { UserSettingsService } from '../../../services/user-settings.service';
import { HIGHLIGHT_UP_DOWN_CHANGES_DEFAULT } from '../../models/settings.model';

const FlashClasses = ['wt-cell-flash--positive-start', 'wt-cell-flash--negative-start', 'wt-cell-flash--end'];


@Component({
  selector: 'wt-cell-flash',
  template: `<span #cellSpan class="wt-cell-flash">{{valueAction | async}}</span>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellFlashComponent implements ICellRenderer, OnDestroy { // implements AfterViewInit
  private readonly renderer: Renderer2 = inject(Renderer2);
  private readonly userSettingsService: UserSettingsService = inject(UserSettingsService);

  @ViewChild('cellSpan') cellSpanElement: ElementRef;

  readonly valueAction = new ReplaySubject<string>(1);
  private lastValue: number | undefined;

  agInit(inParams: ICellRendererParams): void {
    this.setValueFormatted(inParams);
  }

  ngOnDestroy(): void {
    this.valueAction.complete();
  }

  private setValueFormatted(inParams: ICellRendererParams): void {
    const valueFormatted = (inParams as { valueFormatted: string | undefined }).valueFormatted;
    this.valueAction.next(valueFormatted ?? '-');
  }

  private removeStartAddEndTimer: NodeJS.Timeout | undefined;

  private addColorClass(delta: number): void {
    const deltaUp = (delta > 0);
    const deltaClass = deltaUp ? '--positive' : '--negative';
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parentCellElement = this.cellSpanElement.nativeElement.parentElement.parentElement;
    if (!parentCellElement) {
      return;
    }
    for (const c of FlashClasses) {
      this.renderer.removeClass(parentCellElement, c);
    }

    setTimeout(() => {
      this.renderer.addClass(parentCellElement, `wt-cell-flash${deltaClass}-start`);
    }, 1);

    if (this.removeStartAddEndTimer) {
      clearTimeout(this.removeStartAddEndTimer);
    }
    this.removeStartAddEndTimer = setTimeout(() => {
      this.removeStartAddEndTimer = undefined;
      if (!parentCellElement) { //  element can have been destroyed before timeout callback (e.g. when grid is refreshed)
        return;
      }
      this.renderer.removeClass(parentCellElement, `wt-cell-flash${deltaClass}-start`);
      this.renderer.addClass(parentCellElement, `wt-cell-flash--end`);
    }, 400);
  }

  refresh(params: ICellRendererParams): boolean {
    if (params.value === this.lastValue) {
      return false;
    }
    this.setValueFormatted(params);
    if (typeof params.value === 'number' && typeof this.lastValue === 'number') {
      const delta = params.value - this.lastValue;

      // this place is called quite often, but only takes about 0.003ms on average!
      const shouldFlash = this.userSettingsService.getValue('highlightUpDownChanges') ?? HIGHLIGHT_UP_DOWN_CHANGES_DEFAULT;
      if (shouldFlash) {
        this.addColorClass(delta);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.lastValue = params.value;
    return true;
  }
}
