import { Infront, InfrontUtil } from '@infront/sdk';
import { WidgetStorage } from '@infront/wtk/utils/storage';

type ToolkitSaveFunction = (state: ToolkitStorageData) => void;
export interface ToolkitStorageData {
  [id: string]: { [key: string]: unknown };
}

export class ToolkitStorage implements WidgetStorage {
  private toolkitStorageCache: ToolkitStorageData;

  private remoteSave: ToolkitSaveFunction;
  readonly SAVE_DEBOUNCE_TIME = 1000;
  readonly GLOBAL_KEY = 'global';

  constructor(
    initialCache: ToolkitStorageData = {},
    remoteSave: ToolkitSaveFunction,
    private widgetFoundInState: (widgetId: string) => boolean,
  ) {
    this.toolkitStorageCache = initialCache;
    // console.debug('Initial toolkit cache', InfrontUtil.deepCopy(this.toolkitStorageCache), 'storage: ', this); // debug
    // I see that toolkit is very aggressive with calls against this interface
    // So to avoid spamming the remote server we cache the changes and only uploads changes a few times.
    this.remoteSave = InfrontUtil.debounce(remoteSave, this.SAVE_DEBOUNCE_TIME);
  }

  store = (widgetId: string, key: string, value: unknown, storageType?: Infront.StorageTypes): void => {
    this.setKey(this.createKey(widgetId, storageType), key, InfrontUtil.deepCopy(value));
    this.remoteSave(InfrontUtil.deepCopy(this.toolkitStorageCache) as ToolkitStorageData);
  };

  get = (widgetId: string, key: string, storageType?: Infront.StorageTypes): unknown => {
    return this.toolkitStorageCache[this.createKey(widgetId, storageType)]?.[key];
  };

  clear = (widgetId: string, key: string, storageType?: Infront.StorageTypes): void => {
    this.toolkitStorageCache[this.createKey(widgetId, storageType)][key] = {};
    this.remoteSave(InfrontUtil.deepCopy(this.toolkitStorageCache) as ToolkitStorageData);
    delete this.toolkitStorageCache[this.createKey(widgetId, storageType)][key];
  };

  removeWidget = (widgetId: string, storageType?: Infront.StorageTypes): void => {
    // Switching dashboards will "remove" a Toolkit-widget in such way that its "destroy" method is
    // called. This will trigger the ToolkitStorage "removeWidget" method!
    // But in case of switching dashboards we must never remove the storage data!
    // Storage data is only to be removed after a widget has been closed and removed from State!
    if (!this.widgetFoundInState(widgetId)) {
      this.toolkitStorageCache[this.createKey(widgetId, storageType)] = {};
      this.remoteSave(InfrontUtil.deepCopy(this.toolkitStorageCache) as ToolkitStorageData);
      delete this.toolkitStorageCache[this.createKey(widgetId, storageType)];
    }
  };

  storeGlobal = (key: string, value: unknown): void => {
    this.setKey(this.GLOBAL_KEY, key, InfrontUtil.deepCopy(value));
    this.remoteSave(InfrontUtil.deepCopy(this.toolkitStorageCache) as ToolkitStorageData);
  };

  getGlobal = (key: string): unknown => {
    return this.toolkitStorageCache[this.GLOBAL_KEY]?.[key];
  };

  clearGlobal = (key: string): void => {
    this.toolkitStorageCache[this.GLOBAL_KEY][key] = {};
    this.remoteSave(InfrontUtil.deepCopy(this.toolkitStorageCache) as ToolkitStorageData);
    delete this.toolkitStorageCache[this.GLOBAL_KEY][key];
  };

  keyHasFilledObject = (key: string): boolean => {
    const data = this.getGlobal(key);
    return data != undefined && (!InfrontUtil.isEmptyObject(data as object) || !(Array.isArray(data) ? !!data.length : false));
  };

  private createKey(key: string, storageType?: Infront.StorageTypes) {
    return storageType ? `${storageType}~${key}` : key;
  }

  private setKey(parentKey: string, key: string, value: unknown) {
    this.toolkitStorageCache[parentKey] = this.toolkitStorageCache[parentKey] ?? {};
    this.toolkitStorageCache[parentKey][key] = InfrontUtil.deepCopy(value);
  }

  updateStorage(newCache: ToolkitStorageData): void {
    for (const [key, value] of Object.entries(newCache)) {
      this.toolkitStorageCache[key] = value;
    }
    for (const key of Object.keys(this.toolkitStorageCache)) {
      if (key !== this.GLOBAL_KEY && !newCache[key]) {
        delete this.toolkitStorageCache[key];
      }
    }
  }
}
