import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Column, ColumnCategory } from '../grid/columns.model';
import { InfrontUtil } from '@infront/sdk';

export interface ColumnPickerDialogData {
  selectedColumns: Column[];
  columnCategories: ColumnCategory[];
}

@Component({
  selector: 'wt-column-picker-dialog',
  templateUrl: './column-picker-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnPickerDialogComponent {
  readonly data = inject(MAT_DIALOG_DATA) as ColumnPickerDialogData;

  result: Column[] = InfrontUtil.deepCopy(this.data.selectedColumns) as Column[];

  onColumnsSelected(event: Column[]): void {
    this.result = event;
  }
}
