import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';

import { DirectivesModule } from '../../../directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { CountryFlagModule } from '../../country-flag/country-flag.module';
import { AlertLogItemComponent } from './alert-log-item.component';

@NgModule({
  declarations: [AlertLogItemComponent],
  imports: [CommonModule, MatIconModule, MatBadgeModule, CountryFlagModule, DirectivesModule, MatTooltipModule, PipesModule, I18nPipesModule],
  exports: [AlertLogItemComponent],
})
export class AlertLogItemModule {}
