import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TradingSymbolMappingInfoComponent, TradingSymbolMappingInfoData } from './trading-symbol-mapping-info.component';

@Injectable({
  providedIn: 'root',
})
export class TradingSymbolMappingInfoService {

  constructor(private readonly dialog: MatDialog) {
  }

  showSymbolMappingInfo(data: TradingSymbolMappingInfoData): void {
    this.dialog.open(TradingSymbolMappingInfoComponent, {
      width: '340px',
      data: { fullNameDebug: data?.fullNameDebug, instrument: data?.instrument },
    });
  }

}