import { InfrontSDK } from '@infront/sdk';

import { WatchlistService } from '../../../../services/watchlist.service';
import { ContextMenuItem } from '../../../../shared/models/context-menu.model';
import { DashboardWindow, isInstrumentSettings } from '../../../../state-model/window.model';
import { WindowItemTypeOptions } from '../context-menu.model';
import { isSameInstrument } from '../../../../util/symbol';

export const addToWatchlistItem = (window: DashboardWindow, options: WindowItemTypeOptions, watchlistService: WatchlistService, xlat: (key: string, args?: unknown) => string): ContextMenuItem => {
  const instrument = options.instrument;
  const watchlistsByProviders = options.watchlistByProvider;

  if (!watchlistsByProviders) {
    return {
      id: 'addToWatchlist',
      title: 'CONTEXT_MENU_COMPONENT.WATCHLIST.ADD_TO_WATCHLIST',
      icon: 'playlist_add'
    };
  }

  const convertWatchlistToMenuItem = (watchlist: InfrontSDK.Watchlist): ContextMenuItem => {
    const windowInstrument = isInstrumentSettings(window.settings) ? window.settings.instrument : undefined;
    const selectedInstrument = instrument ?? windowInstrument;
    const checked = selectedInstrument ? !!watchlist.items.find((item) => isSameInstrument(item, selectedInstrument)) : false;
    const icon = watchlist.provider ? 'domain' : 'table_rows';
    const isSVGIcon = !watchlist.provider;
    return {
      id: watchlist.id,
      title: watchlist.title,
      checked,
      type: 'checkbox',
      icon,
      isSVGIcon,
      disabled: watchlistService.shouldDisableWriteAccessForWatchlist$(watchlist),
      onClick: (params) => {
        if (!params.instrument || !params.item) {
          return;
        }
        watchlistService.updateWatchlist(params.item.title, params.instrument, watchlist.provider, !params.item.checked);
      },
    };
  };

  const convertProviderGroupToMenuItem = (provider: string, watchlists: InfrontSDK.Watchlist[]): ContextMenuItem => {
    const providerName: string = watchlists[0]?.providerName ?? `${xlat('GLOBAL.WATCHLIST.PROVIDER')} ${provider}`;
    return {
      id: 'addToWatchlist',
      title: providerName,
      icon: 'domain',
      subItems: watchlists.map((watchlist) => convertWatchlistToMenuItem(watchlist)),
    };
  };

  const userListItems = [...watchlistsByProviders[0] ?? []].map(convertWatchlistToMenuItem);
  const providerListItems = Object.entries(watchlistsByProviders)
    .filter(([provider]) => provider !== '0')
    .map(([provider, watchlists]) => convertProviderGroupToMenuItem(provider, watchlists));

  return {
    id: 'addToWatchlist',
    title: 'CONTEXT_MENU_COMPONENT.WATCHLIST.ADD_TO_WATCHLIST',
    icon: 'playlist_add',
    subItems: [...providerListItems, ...userListItems],
  };
};
