import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ResourceService } from '@vwd/ngx-i18n/translate';

@Component({
  selector: 'wt-fund-screener',
  templateUrl: './fund-screener.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundScreenerComponent {
  private readonly translate = inject(ResourceService);
}
