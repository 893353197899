import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { PricePipesModule } from '@vwd/ngx-price-pipes';

import { PerformancePeriodsComponent } from './performance-periods.component';

@NgModule({
  declarations: [PerformancePeriodsComponent],
  imports: [
    CommonModule,
    PricePipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [PerformancePeriodsComponent]
})
export class PerformancePeriodsModule { }
