
import { Infront } from '@infront/sdk';


import { UI, WidgetBase, WidgetOptions } from '@infront/wtk/src/InfrontUI';
import { SvelteWidgetBase, SvelteWidgetOptions } from "@infront/wtk/utils/svelte";
import { Constructor } from '../../util/types';


export type WTKWidget = WidgetBase | SvelteWidgetBase;
export type WTKWidgetOptions = WidgetOptions | SvelteWidgetOptions;

export type WTKWidgetConstructorWithoutModel<Widget extends WTKWidget = WTKWidget> = (
  Constructor<
    Widget,
    [HTMLElement, UI, WTKWidgetOptions]
  >
);

export type WTKWidgetConstructorWithModel<Widget extends WTKWidget = WTKWidget> = (
  Constructor<
    Widget,
    [HTMLElement, Infront.Model, UI, WTKWidgetOptions]
  >
);

export type WTKWidgetConstructor<Widget extends WTKWidget = WTKWidget> =
  WTKWidgetConstructorWithoutModel<Widget> | WTKWidgetConstructorWithModel<Widget>;

// options are unfortunately private, maybe todo for Toolkit?
// export type WTKWidgetConstructorWithOptions<Widget extends WTKWidget = WTKWidget> = { widgetConstructor: WTKWidgetConstructor<Widget>; widgetOptions: Widget['options']; };
export type WTKWidgetConstructorWithOptions<
  Widget extends WTKWidget = WTKWidget,
  Options extends WTKWidgetOptions = WTKWidgetOptions
> = { widgetConstructor: WTKWidgetConstructor<Widget>, options: Options | undefined };

export const WTK_CSS_ENTRY_CLASS = 'infront-toolkit';

export const DEFAULT_WTK_WIDGET_OPTIONS_DEBOUNCE_TIME = 100; // miliseconds
