import { MarketOverviewTemplate } from '../market-overview.model';

export const TemplateRomania: MarketOverviewTemplate[] = [
  {
    tab: 'Indices',
    instrumentList: [
      { feed: 7800, ticker: 'BET' },
      { feed: 7800, ticker: 'BET-BK' },
      { feed: 7800, ticker: 'BET-FI' },
      { feed: 7800, ticker: 'BET-NG' },
      { feed: 7800, ticker: 'BET-TR' },
      { feed: 7800, ticker: 'BET-TRN' },
      { feed: 7800, ticker: 'BET-XT' },
      { feed: 7800, ticker: 'BET-XT-TR' },
      { feed: 7800, ticker: 'BET-XT-TRN' },
      { feed: 7800, ticker: 'BETAeRO' },
      { feed: 2088, ticker: 'SPEURO' },
      { feed: 2161, ticker: 'SX5E' },
      { feed: 2098, ticker: 'DAX' },
      { feed: 2089, ticker: 'N100' },
      { feed: 2089, ticker: 'PX1' },
      { feed: 2164, ticker: 'IB' },
      { feed: 2097, ticker: 'FTSEMIB' },
      { feed: 20, ticker: 'DJI' },
      { feed: 2087, ticker: 'COMP' },
      { feed: 2087, ticker: 'NDX' },
      { feed: 2088, ticker: 'SP500' },
      { feed: 21, ticker: 'NQFUT1' },
      { feed: 21, ticker: 'ESFUT1' },
      { feed: 6330, ticker: 'VIX' },
      { feed: 6150, ticker: 'N225' },
      { feed: 2198, ticker: 'HSI' },
      { feed: 2088, ticker: 'SPASIA50' },
    ],
  },
  {
    tab: 'Forex',
    instrumentList: [
      { feed: 12, ticker: 'EURRON' },
      { feed: 12, ticker: 'USDRON' },
      { feed: 12, ticker: 'EURUSD' },
      { feed: 12, ticker: 'USDEUR' },
      { feed: 12, ticker: 'USDCHF' },
      { feed: 12, ticker: 'USDJPY' },
      { feed: 12, ticker: 'USDRUB' },
      { feed: 12, ticker: 'AUDUSD' },
    ],
  },
  {
    tab: 'FixedIncome',
    instrumentList: [
      { feed: 2227, ticker: 'TMBMKDE-05Y' },
      { feed: 2227, ticker: 'TMBMKDE-10Y' },
      { feed: 2206, ticker: 'EDFUT1' },
      { feed: 2280, ticker: 'IFUT1' },
      { feed: 2158, ticker: 'BMDE-10Y' },
      { feed: 2158, ticker: 'BMDE-30Y' },
      { feed: 2158, ticker: 'BMUS-10Y' },
      { feed: 2158, ticker: 'BMUS-30Y' },
      { feed: 2158, ticker: 'BMGB-10Y' },
    ],
  },
  {
    tab: 'Commodities',
    instrumentList: [
      { feed: 2229, ticker: 'BRT' },
      { feed: 2229, ticker: 'WTI' },
      { feed: 2148, ticker: 'AH3M' },
      { feed: 2148, ticker: 'CA3M' },
      { feed: 2148, ticker: 'ZS3M' },
      { feed: 2159, ticker: 'SLVSPOT' },
      { feed: 2159, ticker: 'GLDSPOT' },
    ],
  }
];
