export const menuItems = [
  {
    closeOnClick: true,
    id: 'Settings',
    title: 'MAIN_CONTEXT_MENU_COMPONENT.ITEMS.SETTINGS',
    icon: 'settings',
  },
  // todo: put reset optional dialogs here for now according to Lasse, should be moved inside settings dialog later
  {
    closeOnClick: true,
    id: 'ResetOptionalDialogs',
    title: 'MAIN_CONTEXT_MENU_COMPONENT.ITEMS.RESET_OPTIONAL_DIALOGS',
    icon: 'refresh',
  },
  {
    closeOnClick: true,
    id: 'MyProfile',
    title: 'MAIN_CONTEXT_MENU_COMPONENT.ITEMS.MY_PROFILE',
    icon: 'account_box',
  },
  {
    closeOnClick: true,
    id: 'Support',
    title: 'MAIN_CONTEXT_MENU_COMPONENT.ITEMS.SUPPORT',
    icon: 'call',
  },
  {
    closeOnClick: true,
    id: 'InstallPwa',
    title: 'MAIN_CONTEXT_MENU_COMPONENT.ITEMS.INSTALL_PWA',
    icon: 'install_desktop',
  },
  //Log out from trading
  {
    closeOnClick: true,
    id: 'LogoutFromTrading',
    title: 'MAIN_CONTEXT_MENU_COMPONENT.ITEMS.LOGOUT_FROM_TRADING',
    icon: 'logout',
  },
  {
    closeOnClick: true,
    id: 'Logout',
    title: 'MAIN_CONTEXT_MENU_COMPONENT.ITEMS.LOGOUT',
    icon: 'logout',
  },
] as const;

export type MainContextItem = typeof menuItems[number]['id'];
