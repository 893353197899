import { InfrontSDK } from '@infront/sdk';

import { PeriodDropdownItem } from '../../shared/dropdown/dropdown-period/dropdown-period.model';
import { Column } from '../../shared/grid/columns.model';
import { DefaultPeriods } from '../../shared/period/default-periods';
import { CalendarType } from '../../state-model/window.model';

export interface CalendarEvent extends InfrontSDK.CalendarEvent {
  feedShortName: string;
  feedLongName: string;
}

export interface CalendarData {
  calendarEvents: CalendarEvent[];
  columns: Column[];
}

export interface Vm extends CalendarData {
  calendarType?: CalendarType;
}

// dropdownLabels have been deactivated on Lasse's request. Will keep them if required again.
export const PeriodItems: PeriodDropdownItem[] = [
  {
    period: DefaultPeriods.MINUS1D,
    itemLabel: 'PERIODS.ITEM_LABELS.MINUS1D', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.MINUS1D', translateDropdownLabel: true,
  },
  {
    period: DefaultPeriods.MINUS1D_PLUS1W,
    itemLabel: 'PERIODS.ITEM_LABELS.MINUS1D_PLUS1W', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.MINUS1D_PLUS1W', translateDropdownLabel: true,
  },
  {
    period: DefaultPeriods.MINUS1M, // last month
    itemLabel: 'PERIODS.ITEM_LABELS.MINUS1M', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.MINUS1M', translateDropdownLabel: true,
  },
  {
    period: DefaultPeriods.MINUS1W, // last week
    itemLabel: 'PERIODS.ITEM_LABELS.MINUS1W', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.MINUS1W', translateDropdownLabel: true,
  },
  {
    period: DefaultPeriods.MINUS1W_PLUS1M, // last week + next month
    itemLabel: 'PERIODS.ITEM_LABELS.MINUS1W_PLUS1M', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.MINUS1W_PLUS1M', translateDropdownLabel: true
  },
  {
    period: DefaultPeriods.PLUS1W, // next week
    itemLabel: 'PERIODS.ITEM_LABELS.PLUS1W', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.PLUS1W', translateDropdownLabel: true
  },
  {
    period: DefaultPeriods.PLUS1M, //  next month
    itemLabel: 'PERIODS.ITEM_LABELS.PLUS1M', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.PLUS1M', translateDropdownLabel: true
  },
  {
    period: DefaultPeriods.PLUS3M, //  next 3 months
    itemLabel: 'PERIODS.ITEM_LABELS.PLUS3M', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.PLUS3M', translateDropdownLabel: true
  },
  {
    period: DefaultPeriods.PLUS6M, //  next 3 months
    itemLabel: 'PERIODS.ITEM_LABELS.PLUS6M', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.PLUS6M', translateDropdownLabel: true
  },
  {
    period: DefaultPeriods.PLUS1Y, //  next 3 months
    itemLabel: 'PERIODS.ITEM_LABELS.PLUS1Y', translateItemLabel: true,
    // dropdownLabel: 'PERIODS.DROPDOWN_LABELS.PLUS1Y', translateDropdownLabel: true
  },
];

export const TodayClassModifier = 'ag-cell-value--today';
export const RightAlignClassModifier = 'ag-right-aligned-cell';
export const OutdatedClassModifier = 'ag-cell-value--outdated';
