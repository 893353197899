import { EnvironmentType } from './env-type';
import { sharedEnv } from './shared';

export const environment: EnvironmentType = {
  production: true,
  development: false,
  storybook: false,
  ...sharedEnv,
  profileUrl: 'https://secure.infrontfinance.com/MyProfile',
};
