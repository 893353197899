<span class="wt-dashboard-tabs-arrow-right-icon">
  <mat-icon (click)="$event.stopPropagation()" [matMenuTriggerFor]="panel.menu"
    fontIcon="keyboard_double_arrow_right">
  </mat-icon>

  <wt-menu-panel
    #panel
    [items]="(menuItems$ | async) || undefined"
    (itemClick)="onItemClick($event)"
  >
  </wt-menu-panel>
</span>

