import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';

import { WatchlistService } from '../../../services/watchlist.service';
import { Dashboard } from '../../../state-model/dashboard.model';
import { Widget } from '../../../state-model/widget.model';
import { WatchlistWindow } from '../../../state-model/window.model';

@Component({
  selector: 'wt-watchlist-header',
  templateUrl: './watchlist-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WatchlistHeaderComponent {
  @Input() dashboard: Dashboard;
  @Input() window: WatchlistWindow;
  @Input() widget: Widget;

  constructor(private watchlistService: WatchlistService) { }

  onWatchlistSelected(selectedWatchlist: InfrontSDK.Watchlist | undefined): void {
    this.watchlistService.onSelectedWatchlistChange({ window: this.window }, selectedWatchlist);
  }
}
