import { Logger, LogService } from '@vwd/ngx-logging';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, map, retry, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import type { DashboardEntity } from '../../internal/api/dashboards';
import { DashboardsService } from '../../internal/api/dashboards';
import { LastValueSubject } from '../../internal/utils/last-value-subject';
import type { DashboardChildrenLoadState } from '../../models';
import type { FolderType, HttpDashboardFolderModel, HttpDashboardModel } from './http-dashboard.models';
import { createCompanyFolderModel, createGlobalFolderModel, createSharedFolderModel, createSubTenantFolderModel, createUserFolderModel, parentId } from './http-dashboard.templates';
import { storeToModel } from './store.models';

const DASHBOARD_ENTITY_FIELDS = Object.freeze([
  'id',
  'name',
  'parent_id',
  'type',
  'role',
  'data_id',
  'attributes',
  'user_id',
  'realm',
  'sub_tenant_id',
  'can_edit',
  'can_add_children',
  'can_move',
  'can_delete',
  'can_share',
  'is_shared',
  'is_data_draft',
  'data_create_timestamp',
]) as Array<keyof DashboardEntity>;

// special arrays used as signal values to indicate dashboard loading state
interface LoadState {
  readonly state: DashboardChildrenLoadState;
  readonly items: readonly HttpDashboardModel[]
  readonly syncError?: Error;
}
const ONDEMAND_CHILDREN_STATE: LoadState = { state: 'on-demand', items: [] };
const LOADING_CHILDREN_STATE: LoadState = { state: 'loading', items: [] };

/**
 * A loader for a specific Dashbaords API (top-level) folder;
 * the {@link models$} property will contain the top-level
 * folder itself, and the child nodes.
 *
 * The loader receives all parameters needed to load the
 * folder ("role"), and also receives the display name,
 * which you can use for localization & lazily loaded subtenant
 * names.
 *
 * For subtenants, we also track {@link isOwn}, so
 * we can handle some specifics for subtenant folders.
 *
 * The {@link HttpDashboardProvider} manages these instances,
 * and will create & destroy them when needed (e.g., when
 * you sign out, the folders list is cleared, and when you
 * sign in again, the list will be repopulated).
 *
 * It is also the {@link HttpDashboardProvider} that will
 * signal the loader to refresh the folder, and whether
 * some mutations have happened. Right now, if such a CRUD
 * action happens, the provider will send a notification to
 * replace the specific model, but the loader will not
 * update its {@link models$} observable.
 */
export class DashboardFolderLoader {
  public readonly id: string;
  private readonly ngUnsubscribe = new Subject<void>();
  private readonly modelsSubject = new LastValueSubject<HttpDashboardModel[]>();
  private readonly loadSubject: BehaviorSubject<boolean>;
  private readonly logger: Logger;
  private loadingState: DashboardChildrenLoadState;

  public get models$(): Observable<readonly HttpDashboardModel[]> {
    return this.modelsSubject as unknown as Observable<readonly HttpDashboardModel[]>
  }

  public static getLoaderId(
    role: FolderType,
    realm: string | undefined,
    subTenantId: string | undefined,
    userId: string | undefined,
  ): string {
    return parentId(role, realm, subTenantId, userId);
  }

  constructor(
    logService: LogService,
    private readonly api: DashboardsService,
    public readonly role: FolderType,
    public readonly realm: string | undefined,
    public readonly subTenantId: string | undefined,
    public readonly userId: string | undefined,
    canAddChildren: boolean,
    shouldLoadChildren: boolean,
    folderName$: Observable<string>,
    public readonly isOwnRealm: boolean,
    public readonly isOwnSubTenant: boolean,
  ) {
    this.id = DashboardFolderLoader.getLoaderId(role, realm, subTenantId, userId);
    this.logger = logService.openLogger('dashboards/services/http/dashboards/loader');

    this.loadSubject = new BehaviorSubject<boolean>(shouldLoadChildren);

    this.loadingState = shouldLoadChildren ? 'loading' : 'on-demand';

    let lastLoaded: readonly HttpDashboardModel[];

    combineLatest([
      folderName$,
      this.loadSubject.pipe(
        switchMap(loadChildren => {
          if (loadChildren) {
            if (this.loadingState === 'on-demand') {
              return this.load().pipe(
                map((items) => ({ state: 'loaded', items } as LoadState)),
                startWith(LOADING_CHILDREN_STATE),
              );
            } else {
              return this.load().pipe(map((items) => ({ state: 'loaded', items }) as LoadState));
            }
          } else {
            return of(ONDEMAND_CHILDREN_STATE);
          }
        }),
        // If a load fails, but we have already loaded "stuff", keep it;
        // error has already been logged.
        tap((loaded) => lastLoaded = loaded.items),
        catchError((syncError: Error) => of({ state: 'error', items: lastLoaded ?? [], syncError } as LoadState)),
      )
    ]).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(([folderName, result]) => {
      this.loadingState = result.state;
      this.modelsSubject.next([
        ensureChildrenLoadState(
          ensureCanAddDeleteChildren(role, canAddChildren, this.createFolderModel(folderName)),
          this.loadingState,
          result.syncError
        ),
        ...result.items,
      ]);
    });
  }

  private createFolderModel(folderName: string): HttpDashboardFolderModel {
    switch (this.role) {
      case 'GLOBAL':
        return createGlobalFolderModel(folderName);
      case 'COMPANY':
        return createCompanyFolderModel(this.realm!, folderName, this.isOwnRealm);
      case 'SUBTENANT':
        return createSubTenantFolderModel(this.realm!, this.subTenantId!, folderName, this.isOwnRealm, this.isOwnSubTenant);
      case 'USER':
        return createUserFolderModel(this.realm!, this.userId!, folderName);
      case 'SHARED':
        return createSharedFolderModel(this.realm!, this.userId!, folderName);
      default:
        throw new Error(`Unknown folder type ${this.role}.`);
    }
  }

  private load(): Observable<HttpDashboardModel[]> {
    const { role, realm, subTenantId: subTenant } = this;
    this.logger.log(`load(${role}, ${realm}, ${subTenant})`);
    // TODO: use localStorage for failed loads?
    return this.api.dashboardsGet(undefined, role, subTenant, realm, DASHBOARD_ENTITY_FIELDS).pipe(
      map(result => {
        return result._data?.map(row => row.dashboard)
          .filter(dashboard => !!dashboard)
          .map(dashboard => storeToModel(role, dashboard, this.logger))
          ?? [];
      }),
      tap({
        next: data => this.logger.log(`loadFolderFromStore(${role}, ${realm}, ${subTenant}) received data`, data),
        error: err => this.logger.fail(`Error loading dashboards of level ${role}`, err)
      }),
      retry({ count: 3, delay: 1_000 }),
      tap({ error: err => this.logger.fail(`Error loading dashboards of level ${role} after retrying.`, err) }),
    );
  }

  public loadChildren(): void {
    if (!this.loadSubject.value) {
      this.loadSubject.next(true);
    }
  }

  public refresh(): void {
    if (this.loadingState !== 'on-demand') {
      this.loadSubject.next(this.loadSubject.value);
    }
  }

  public created(model: HttpDashboardModel): void {
    this.modelsSubject.value?.push(model);
  }

  public updated(model: HttpDashboardModel): void {
    const index = this.modelsSubject.value?.findIndex(m => m.id === model.id) ?? -1;
    if (index !== -1) {
      this.modelsSubject.value![index] = model;
    }
  }

  public deleted(model: HttpDashboardModel): void {
    const index = this.modelsSubject.value?.findIndex(m => m.id === model.id) ?? -1;
    if (index !== -1) {
      this.modelsSubject.value!.splice(index, 1);
    }
  }

  public destroy(): void {
    this.modelsSubject.complete();
    this.loadSubject.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

function ensureCanAddDeleteChildren(role: FolderType, canAddChildren: boolean, folder: HttpDashboardFolderModel): HttpDashboardFolderModel {
  // SHARED is a "special" folder, that allows deleting, but not adding.
  // Otherwise, canAddChildren implies canDeleteChildren (mostly — specific
  // folders can deny this, but you can see this via their `canDelete` value).
  const canDeleteChildren = role === 'SHARED' ? true : canAddChildren;
  if (canAddChildren !== folder.security.canAddChildren
    || canDeleteChildren !== folder.security.canDeleteChildren) {
    return {
      ...folder,
      security: {
        ...folder.security,
        canAddChildren: canAddChildren,
        canDeleteChildren: canAddChildren
      }
    };
  } else {
    return folder;
  }
}

function ensureChildrenLoadState(folder: HttpDashboardFolderModel, childrenLoadState: DashboardChildrenLoadState, syncError?: Error): HttpDashboardFolderModel {
  if (folder.childrenLoadState !== childrenLoadState || syncError !== folder.syncError) {
    return { ...folder, childrenLoadState, syncError };
  }
  return folder;
}
