<div class="wt-full-height wt-trading-tab">
  <wt-trading-login></wt-trading-login>
  <ng-container *ngIf="tradingConnected$ | async">
    <!-- Portfolio Dropdown / Selector -->
    <div class="wt-flex-row wt-trading-tab__wt-dropdown-portfolio-container">
      <div class="wt-flex-column">
        <wt-dropdown-portfolio
          [selectedPortfolio]="selectedPortfolio$ | async"
          [hasPortfolioIcon]="false"
          [hasPortfolioLabel]="true"></wt-dropdown-portfolio>
      </div>
    </div>

    <!-- Portfolio Single Value -->
    <div class="wt-flex-row wt-trading-tab__portfolio-single-value-list-container">
      <div *ngFor="let portfolioSingleValueWidgetList of portfolioSingleValueWidgetLists"
        class="wt-flex-column wt-flex-justify--space-evenly wt-trading-tab__portfolio-single-value-container">
        <wt-portfolio-single-value
          *ngFor="let portfolioSingleValueWidgetArgs of portfolioSingleValueWidgetList"
          class="wt-trading-tab__portfolio-single-value-widget"
          [portfolioValue]="portfolioSingleValueWidgetArgs.portfolioValue"
          [showLabel]="true"
          [showCurrency]="true">
        </wt-portfolio-single-value>
      </div>
    </div>

    <div class="wt-flex wt-flex-dir-column wt-trading-tab__orderstack-positions-container" #container>
      <!-- Order Stack -->
      <div *ngIf="showOrderStackWidget$ | async as showOrderStackWidget"
        class="wt-flex-row wt-trading-tab__order-stack-container"
        #orderStackContainer>
        <div class="wt-flex wt-flex-dir-column wt-flex-column">
          <div (click)="toggleShowWidget('tradingTabShowOrderStackWidget')"
            class="wt-flex wt-trading-tab__order-stack-label">
            <span>{{ 'SIDEBAR.TAB.TRADING.ORDERS' | translate }}</span>
            <span
              class="mat-expansion-indicator wt-trading-tab__order-stack-toggle"
              [ngClass]="{ 'wt-trading-tab__order-stack-toggle--expanded': showOrderStackWidget.show }"></span>
          </div>

          <ng-container *ngIf="showOrderStackWidget.show">
            <div class="wt-trading-tab__order-stack-scroll-wrapper" [hidden]="!orderStackWidgetHasContent">
              <wt-wtk-widget-wrapper
                *ngIf="orderStackWidget$ | async as orderStackWidget"
                class="wt-flex wt-flex-dir-column"
                [hidden]="!orderStackWidgetHasContent"
                [widget]="orderStackWidget"
                (widgetStateChange)="orderStackWidgetStateChange.next($event)">
              </wt-wtk-widget-wrapper>
            </div>


            <div *ngIf="!orderStackWidgetHasContent"
              class="wt-flex wt-truncate wt-trading-tab__order-stack-placeholder">
              <mat-icon class="wt-trading-tab__order-stack-placeholder-icon material-icons-outlined"
                inline="true"
                fontIcon="info"></mat-icon>
              <span class="wt-trading-tab__order-stack-placeholder-text">
                {{'SIDEBAR.TAB.TRADING.NO_ORDERS_PLACEHOLDER' | translate }}
              </span>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- Positions -->
      <div *ngIf="showPositionsWidget$ | async as showPositionsWidget"
        class="wt-flex-row wt-trading-tab__positions-container"
        #positionsContainer>
        <div class="wt-flex wt-flex-dir-column wt-flex-column">
          <div (click)="toggleShowWidget('tradingTabShowPositionsWidget')"
            class="wt-flex wt-trading-tab__positions-label">
            <span>{{ 'SIDEBAR.TAB.TRADING.POSITIONS' | translate }}</span>
            <span
              class="mat-expansion-indicator wt-trading-tab__positions-toggle"
              [ngClass]="{ 'wt-trading-tab__positions-toggle--expanded': showPositionsWidget.show }"></span>
          </div>

          <ng-container *ngIf="showPositionsWidget.show">
            <div class="wt-trading-tab__positions-scroll-wrapper" [hidden]="!positionsWidgetHasContent">
              <wt-wtk-widget-wrapper
                *ngIf="positionsWidget$ | async as positionsWidget"
                [ngClass]="{ 'wt-trading-tab__positions--no-scroll': !positionsWidgetHasContent }"
                [hidden]="!positionsWidgetHasContent"
                [widget]="positionsWidget"
                [reinitObservable$]="reinitPositionsWidget$"
                (widgetStateChange)="positionsWidgetStateChangeAction.next($event)"
                #positionsWidget>
              </wt-wtk-widget-wrapper>
            </div>

            <div *ngIf="!positionsWidgetHasContent"
              class="wt-flex wt-truncate wt-trading-tab__positions-placeholder">
              <mat-icon class="wt-trading-tab__positions-placeholder-icon material-icons-outlined"
                inline="true"
                fontIcon="info"></mat-icon>
              <span class="wt-trading-tab__positions-placeholder-text">
                {{ 'SIDEBAR.TAB.TRADING.NO_POSITIONS_PLACEHOLDER' | translate }}
              </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>
