import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { tap } from 'rxjs/operators';
import { isSameInstrument } from '../../../util/symbol';
import { PickedInstrumentsService } from './picked-instruments.service';

@Component({
  selector: 'wt-mat-checkbox',
  template: `
    <mat-checkbox name="temp" [(ngModel)]="isChecked" (click)="onCheckboxClick($event)"></mat-checkbox>
    <ng-container *ngIf="changeListener$ | async" ></ng-container>
  `,
})
export class MatCheckboxComponent {
  private params: ICellRendererParams;
  private _isChecked = false;
  get isChecked(): boolean {
    const ticker = this.params.data.Ticker as string;
    const feed = this.params.data.Feed as number;
    const item = this.pickedInstrumentsService.instruments().find((inst) => isSameInstrument(inst, { ticker, feed }));
    this._isChecked = !!item;
    return this._isChecked;
  }
  set isChecked(value: boolean) {
    this._isChecked = value;
    const ticker = this.params.data.Ticker as string;
    const feed = this.params.data.Feed as number;
    if (!(ticker && feed)) {
      return;
    }
    value ? this.pickedInstrumentsService.add({ ticker, feed }) : this.pickedInstrumentsService.remove({ ticker, feed });
  }

  changeListener$ = this.pickedInstrumentsService.instruments$.pipe(tap((instruments) => {
    const ticker = this.params.data.Ticker as string;
    const feed = this.params.data.Feed as number;
    this._isChecked = !!instruments.find((inst) => isSameInstrument(inst, { ticker, feed }));
  }));

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  onCheckboxClick(event: MouseEvent) {
    // stop event propagation from reaching ag-Grid row selection
    event.stopPropagation();
  }

  constructor(private pickedInstrumentsService: PickedInstrumentsService) { }
}
