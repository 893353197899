import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LogService } from '@vwd/ngx-logging';


const tokenMatch = /\?_iwttoken=[a-zA-Z0-9_.-]+(&__act=redir.7.Support)*/;

const WHITELIST: RegExp[] = [
  new RegExp(`^https://secure.infrontfinance.com/MyProfile${tokenMatch.source}$`),
  new RegExp(`^https://myprofile-verification.infrontservices.com${tokenMatch.source}$`),
];

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  private readonly logger = inject(LogService).openLogger('pipes/safe');
  private readonly sanitizer = inject(DomSanitizer);

  transform(url: string): SafeResourceUrl {
    if (!WHITELIST.some((allowedRegExp) => allowedRegExp.exec(url))) {
      this.logger.error('safe-pipe: url is not allowed!', url);
      return this.sanitizer.bypassSecurityTrustResourceUrl('');
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(url); // NOSONAR safe due to WHITELIST
  }
}
