import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatListOption } from '@angular/material/list';

import { Column, ColumnCategory, SelectableColumn } from '../grid/columns.model';

@Component({
  selector: 'wt-column-picker',
  templateUrl: './column-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnPickerComponent implements OnInit {
  @Output() columnsSelected = new EventEmitter<Column[]>();

  @Input() columnCategories: ColumnCategory[];
  @Input() selectedColumns: Column[] = [];

  // go through all columns in each category and set seleced property based on selectedColumns list
  getColumnCategories = (selectedColumns: Column[]): ColumnCategory[] =>
    this.columnCategories.map((category) => {
      const columns = category.columns.map((column) => {
        const selectedColumnNames = selectedColumns.filter((sc) => sc.colId).map((sc) => sc.colId);
        return { ...column, selected: selectedColumnNames.includes(column.colId) };
      });
      return { name: category.name, columns };
    });

  onSelectionChanged(option: MatListOption): void {
    this.selectedColumns = option.selected
      ? ([...this.selectedColumns, option.value] as Column[])
      : this.selectedColumns.filter((col) => col.colId !== (option.value as Column).colId);
    this.columnCategories = this.getColumnCategories(this.selectedColumns);
    this.columnsSelected.emit(this.selectedColumns);
  }

  onPickedColumnsChanged(columns: SelectableColumn[]): void {
    this.selectedColumns = columns;
    this.columnCategories = this.getColumnCategories(this.selectedColumns);
    this.columnsSelected.emit(this.selectedColumns);
  }

  columnCategoryCompareFn(index: number, item: ColumnCategory): string {
    return item.name ?? '';
  }

  ngOnInit(): void {
    this.columnCategories = this.getColumnCategories(this.selectedColumns);
  }
}
