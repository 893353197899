import { NgModule } from '@angular/core';

import { AddDashboardDirective } from '../directives/dashboard.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { AddDashboardWindowDirective, UpdateDashboardWindowDirective } from './dashboard-window-controller.directive';
import { FocusOnShowDirective } from './focus-on-show.directive';
import { LetDirective } from './let.directive';
import { NumberOnlyDirective } from './number-only.directive';
import { OutdatedPriceDirective } from './outdated-price.directive';
import { OutdatedPriceService } from './outdated-price.service';
import { TodayStyleDirective } from './today-style.directive';

@NgModule({
  imports: [],
  declarations: [
    ClickOutsideDirective,
    LetDirective,
    FocusOnShowDirective,
    AddDashboardWindowDirective,
    UpdateDashboardWindowDirective,
    AddDashboardDirective,
    OutdatedPriceDirective,
    NumberOnlyDirective,
    TodayStyleDirective,
  ],
  exports: [
    ClickOutsideDirective,
    LetDirective,
    FocusOnShowDirective,
    AddDashboardWindowDirective,
    UpdateDashboardWindowDirective,
    AddDashboardDirective,
    OutdatedPriceDirective,
    NumberOnlyDirective,
    TodayStyleDirective,
  ],
  providers: [OutdatedPriceService],
})
export class DirectivesModule { }
