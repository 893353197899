import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { CalendarComponent } from './calendar.component';
import { DropdownPeriodModule } from './../../shared/dropdown/dropdown-period/dropdown-period.module';
import { StaticColumnsGridModule } from '../../wrappers/grid-wrappers/static-columns-grid/static-columns-grid.module';
import { TradingLoginModule } from '../../shared/trading-login/trading-login.module';


@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    MatIconModule,
    StaticColumnsGridModule,
    DropdownPeriodModule,
    TradingLoginModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  declarations: [CalendarComponent],
})
export class CalendarModule { }
