import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ColumnPickedListModule } from '../column-picked-list/column-picked-list.module';
import { MultiSelectExpansionModule } from '../multi-select-expansion/multi-select-expansion.module';
import { MultiSelectModule } from '../multi-select/multi-select.module';
import { ColumnPickerComponent } from './column-picker.component';

@NgModule({
  declarations: [ColumnPickerComponent],
  imports: [CommonModule, MultiSelectExpansionModule, MultiSelectModule, ColumnPickedListModule],
  exports: [ColumnPickerComponent],
})
export class ColumnPickerModule {}
