import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LogService } from '@vwd/ngx-logging';

import { environment } from '../../environments/environment';
import { NotificationService } from './notification.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private readonly logger = this.logService.openLogger('services/error-handler');

  constructor(private readonly logService: LogService, private injector: Injector) { }

  handleError(error: Error | HttpErrorResponse) {
    this.logger.error(error);
    if (environment.development) {
      const notifier = this.injector.get(NotificationService);
      const message = error?.message ? error.message : error?.toString();
      const stack = this.isError(error) ? error?.stack?.toString() : '';
      notifier.showError(message + (stack ?? ''));
    }
  }

  private isError = (variableToCheck: unknown): variableToCheck is Error => (variableToCheck as Error).stack !== undefined;
}
