import { InfrontSDK } from '@infront/sdk';
import { WatchlistItem } from '../dropdown/dropdown-watchlist/dropdown-watchlist.model';

export const SearchLimit = 500;

export interface FundSearchState {
  fields: InfrontSDK.SearchParameters<InfrontSDK.SearchField>;
  selectedColumnCategoryIndex: number
  resultSort?: { colId: string; sort: 'asc' | 'desc' };
}

export type FieldValuesObject = {
  [key: string]: (string | string[] | number | number[] | undefined)
};

export const PerformancePeriodsMap: { [key: string]: InfrontSDK.SearchRange } = { YTD: InfrontSDK.SearchRange.YTD, '1 Year': InfrontSDK.SearchRange.OneYear, '3 Years': InfrontSDK.SearchRange.ThreeYears, '5 Years': InfrontSDK.SearchRange.FiveYears };

export const InfrontMutualFunds = 2412;

export type FeedInfoShort = Pick<InfrontSDK.FeedInfo, 'feed' | 'description' | 'feedCode'>;

export interface FundFormGroup {
  ISIN?: string;
  fullName?: string;
  feed?: Pick<InfrontSDK.FeedInfo, 'feed' | 'description'>;
  regionExposure?: string[];
  portfolioManager?: string;
  issuer?: string;
  fundType?: string;
  topHolding?: string;
  primarySegment?: string;
  fundStyle?: string;
  riskLevel?: string[];
  fundStarRating?: number[];
  performancePeriod?: string;
  performanceMin: number;
  performanceMax: number;
}

export const MorningstarFundStyles = ['Large Blend', 'Large Growth', 'Large Value', 'Mid Blend', 'Mid Growth', 'Mid Value', 'Small Blend', 'Small Growth', 'Small Value'];


export type WatchlistItemForScreener = Omit<WatchlistItem, 'isInEdit'>;
