<ng-container *ngIf="visibility$ | async as visibility">
  <div class="wt-search__compact">
    <div>
      <ng-container *ngIf="groupSymbols; then groupedItemsTemplate; else singleItemsTemplate"></ng-container>
    </div>

    <ng-template #singleItemsTemplate>
      <div matAutocompleteOrigin class="wt-search__compact-container" #origin="matAutocompleteOrigin"
        *ngIf="visibility.visible">
        <form [formGroup]="searchForm" class="wt-search__compact-form position-relative">
          <mat-icon *ngIf="icon" class="wt-search-custom-icon" (click)="iconClicked.emit()"
            fontIcon="{{ icon }}"></mat-icon>
          <input
            type="text"
            class="wt-search__compact-form-input"
            [ngClass]="{ 'wt-search__compact-form-input--has-custom-icon': !!icon }"
            aria-label="Instrument"
            (focus)="onEnter()"
            (keyup.enter)="onEnter()"
            autocomplete="wt-compact-search"
            formControlName="userInput"
            [(ngModel)]="searchQuery"
            [placeholder]="placeholder"
            [matAutocomplete]="auto"
            [matAutocompleteConnectedTo]="origin"
            focusOnShow
            [focusOnShowEnabled]="focusOnShowEnabled"
            (click)="$event.stopPropagation()"
            #searchElm />

          <mat-autocomplete
            #autoComplete
            #auto="matAutocomplete"
            (closed)="closeDropdown()"
            [panelWidth]="panelWidth">
            <ng-container *ngIf="searchResults$ | async as searchResults">
              <div class="wt-search__compact-autocomplete" *ngIf="searchResults.length > 0" #optionElm>
                <mat-option class="wt-search__compact-autocomplete-item" *ngFor="let result of searchResults"
                  (click)="onSelectedSingleOption(result)"
                  (onSelectionChange)="onSelectionChange(result)"
                  [value]="result.Ticker"
                  [ngSwitch]="result.itemType">
                  <wt-search-item-symbol
                    *ngSwitchCase="SearchResultItemType.Symbol"
                    [isCompact]="true"
                    [showFlag]="showFlag"
                    [hasTickerClickEvent]="false"
                    [hasFeedClickEvent]="false"
                    [searchItemSymbol]="{
                      ticker: result.Ticker,
                      feed: result.Feed,
                      isin: result.ISIN,
                      feedExchange: result.FeedExchange,
                      feedDescription: result.FeedDesc,
                      fullName: result.FullName,
                      isoCountry: result.Country,
                      tradable: result.IsTradable,
                      access: result.access || result.FeedAccessStr,
                      currency: result.Currency,
                      symbolClassification: result.SymbolClassification || SymbolClassification.Unknown,
                      feedIsHidden: result.FeedIsHidden
                    }"></wt-search-item-symbol>
                </mat-option>
              </div>
              <!-- We have to keep at least on option object here or else mat-autocomplete will trigger a close if we don't have any search results -->
              <mat-option *ngIf="!closeDropdownOnEmpty" class="wt-hidden"></mat-option>
            </ng-container>
          </mat-autocomplete>
        </form>
      </div>
    </ng-template>

    <ng-template #groupedItemsTemplate>
      <div matAutocompleteOrigin class="" #origin="matAutocompleteOrigin" *ngIf="visibility.visible">
        <form [formGroup]="searchForm" class="wt-search__compact-form position-relative">
          <mat-icon *ngIf="icon" class="wt-search-custom-icon" (click)="iconClicked.emit()"
            fontIcon="{{ icon }}"></mat-icon>
          <input
            type="text"
            aria-label="Instrument"
            (focus)="onEnter()"
            (keyup.enter)="onEnter()"
            formControlName="userInput"
            autocomplete="wt-compact-search"
            formControlName="userInput"
            [(ngModel)]="searchQuery"
            class="wt-search__compact-form-input"
            [ngClass]="{ 'wt-search__compact-form-input--has-custom-icon': !!icon }"
            [matAutocomplete]="auto"
            [matAutocompleteConnectedTo]="origin"
            focusOnShow
            [focusOnShowEnabled]="focusOnShowEnabled"
            (click)="$event.stopPropagation()"
            #searchElm />

          <mat-autocomplete #autoComplete #auto="matAutocomplete">
            <div #expandElm>
              <div class="wt-flex wt-flex-column" *ngFor="let item of searchResults$ | async">
                <div [ngStyle]="{ display: item.symbols.length ? 'block' : 'none' }">
                  <div class="header p-2 wt-flex-row">
                    <div>
                      {{ item.title }}
                    </div>

                    <div class="cursor-pointer align-items-center"
                      (click)="expandOneAndCollapseRest(item, searchResultsAction.getValue())">
                      <div [ngClass]="item.showMore ? 'chevron-up' : 'chevron-down'"></div>
                    </div>
                  </div>
                </div>

                <div class="wt-search__compact-autocomplete" #optionElm>
                  <mat-option class="wt-search__compact-autocomplete-item"
                    *ngFor="let result of item.symbols; let i = index" [value]="result.Ticker"
                    (click)="onSelectedOption(result)">
                    <div [ngStyle]="{ display: !item.collapse ? 'block' : 'none' }">
                      <ng-container *ngIf="item.showMore ? i < 10 : i < 3" [ngSwitch]="result.itemType">
                        <wt-search-item-symbol
                          *ngSwitchCase="SearchResultItemType.Symbol"
                          [isCompact]="true"
                          [showFlag]="showFlag"
                          [hasTickerClickEvent]="false"
                          [hasFeedClickEvent]="false"
                          [searchItemSymbol]="{
                            ticker: result.Ticker,
                            feed: result.Feed,
                            isin: result.ISIN,
                            feedExchange: result.FeedExchange,
                            feedDescription: result.FeedDesc,
                            fullName: result.FullName,
                            isoCountry: result.Country,
                            tradable: result.IsTradable,
                            access: result.access || result.FeedAccessStr,
                            currency: result.Currency,
                            symbolClassification: result.SymbolClassification || SymbolClassification.Unknown,
                            feedIsHidden: result.FeedIsHidden
                          }"></wt-search-item-symbol>
                      </ng-container>
                    </div>
                  </mat-option>
                </div>
              </div>
            </div>
          </mat-autocomplete>
        </form>
      </div>
    </ng-template>
  </div>
</ng-container>