import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { StoreService } from '../../services/store.service';
import { OrderbookWidget, Widget } from '../../state-model/widget.model';

@Injectable({
  providedIn: 'any', // with lazy loading any will create the service as a local singleton in the lazy module
})
export class OrderbookService {
  constructor(private storeService: StoreService) { }

  settings$ = (widget: Widget): Observable<OrderbookWidget['settings']> =>
    // todo: improve widget typing to not have to cast as OrderbookWidget
    this.storeService.widget$(widget).pipe(map((widget) => (widget as OrderbookWidget).settings));

}
