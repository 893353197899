<div class="widget-title-bar__title gridster-item-content"
  [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false">
  <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
    <ng-container [ngSwitch]="window.settings.newsType">
      <ng-container *ngSwitchCase="'Instrument'">
        <div class="wt-flex-column wt-flex-shrink">
          <mat-icon
            class="widget-title-bar-icon widget-title-bar-icon--clickable widget-title-bar-icon--search gridster-item-content"
            (click)="onSearchClick()"
            fontIcon="search">
          </mat-icon>
        </div>

        <div class="wt-flex-column">
          <ng-container *ngIf="symbolInfo$ | async as symbolInfo; else selectInstrument">
            <button
              class="wt-button widget-title-bar-label widget-title-bar-label--clickable gridster-item-content"
              (click)="onSearchClick()">
              {{ symbolInfo.preDisplayTicker }}
              {{ "DASHBOARD_WINDOW.HEADER.WINDOW_LABEL." + (this.window.name || "NA")| translate }}
            </button>
            <span *ngIf="symbolInfo.feedIsHidden; else clickableFeed" class="widget-title-bar-feed">
              {{ symbolInfo.feedExchange }}
            </span>

            <ng-template #clickableFeed>
              <button class="wt-button widget-title-bar-feed widget-title-bar-feed--clickable"
                (click)="onSpawnMarketWindow($event)">
                {{ symbolInfo.feedExchange }}
              </button>
            </ng-template>
          </ng-container>

          <ng-template #selectInstrument>
            <button class="wt-button" (click)="onSearchClick()">
              {{ "DASHBOARD_WINDOW.HEADER.NEWS_HEADER.SELECT_INSTRUMENT" | translate }}</button>
          </ng-template>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'Watchlist'">
        <div class="wt-flex-column">
          <wt-dropdown-watchlist
            [dropdownLabel]="
            ((selectedWatchlist$ | async)?.title || ('DASHBOARD_WINDOW.HEADER.NEWS_HEADER.NO_WATCHLIST' | translate))
            + ' - '
            + (('DASHBOARD_WINDOW.HEADER.WINDOW_LABEL.' + (window.name || 'NA')) | translate)"
            [selectedWatchlistId]="window.settings.selectedWatchlist"
            (selectedWatchlistChange)="onWatchlistSelected($event)"></wt-dropdown-watchlist>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'Portfolio'">
        <div class="wt-flex-column">
          <wt-dropdown-portfolio
            [selectedPortfolio]="selectedPortfolio$ | async"
            [matDropdownIcon]="undefined"></wt-dropdown-portfolio>
          {{ (('DASHBOARD_WINDOW.HEADER.WINDOW_LABEL.' + (window.name || 'NA')) | translate) }}
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'Country'">
        <div class="wt-flex-column">
          <wt-market-overview-dropdown
            [type]="'News'"
            [title]="'DASHBOARD_WINDOW.HEADER.NEWS_HEADER.COUNTRY_NEWS' | translate"
            [menuClass]="'wt-news-header'"
            [preSelectedFeeds]="window.settings.feeds"
            (multiSourceSelected)="onMultiSourceSelected($event)"></wt-market-overview-dropdown>
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <div class="wt-flex-column wt-flex-shrink">
          <mat-icon fontIcon="error_outlined"></mat-icon>
        </div>

        <div class="wt-flex-column">
          <span>
            {{ "DASHBOARD_WINDOW.HEADER.NEWS_HEADER.INVALID_SOURCE" | translate }}
            - {{ "DASHBOARD_WINDOW.HEADER.WINDOW_LABEL." + (this.window.name || "NA") | translate }}
          </span>
        </div>
      </ng-container>
    </ng-container>

    <div class="wt-flex-column wt-flex-shrink">
      <div class="widget-title-bar__buttons gridster-item-content">
        <ul class="wt-list-inline">
          <li class="wt-list-inline__item gridster-item-content">
            <wt-link-channel-menu *ngIf="activeInstrument" [window]="window"
              [dashboard]="dashboard"></wt-link-channel-menu>
          </li>
          <li class="wt-list-inline__item gridster-item-content">
            <wt-context-menu class="gridster-item-content" [context]="{ widget, dashboard }"></wt-context-menu>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
