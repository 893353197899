import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

import { TimeSeriesItem } from '../../../wrappers/grid-wrappers/grid-wrappers.model';
import { TimeSeriesService } from '../time-series/time-series.service';

@Component({
  selector: 'wt-time-series-request',
  templateUrl: './../time-series/time-series.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSeriesRequestComponent {
  private width = 120;
  private height = 45;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('canvas', { static: true }) canvas: any;
  constructor(private timeSeriesService: TimeSeriesService) { }

  agInit(params: ICellRendererParams): void {
    const timeSeriesMetadata = params.data as TimeSeriesItem;
    this.timeSeriesService.initTimeSeries(timeSeriesMetadata, this.canvas.nativeElement as HTMLCanvasElement, this.width, this.height);
  }
}
