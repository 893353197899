import { Observable, combineLatest, map } from 'rxjs';
import { TenantSettingsService } from '../services/tenant-settings.service';
import { inject } from '@angular/core';
import { translator$ } from './locale';

export function injectWindowTitleComposer(prefix$: Observable<string | undefined>) {
  const appTitle$ = translator$()('GLOBAL.APP_TITLE');

  return combineLatest([
    appTitle$,
    prefix$,
  ]).pipe(
    map(([appTitle, prefix]) => composeWindowTitle(prefix, appTitle)),
  );
}

function composeWindowTitle(prefix: string | undefined, appTitle: string) {
  return prefix ? `${prefix} - ${appTitle}` : appTitle;
}

export function injectWindowTitle$(): Observable<string> {
  const branding$ = inject(TenantSettingsService).getValue$('branding');
  return injectWindowTitleComposer(branding$.pipe(map((branding) => branding?.titlePrefix)));
}

export function injectSupportMenuTitle$(): Observable<string> {
  const branding$ = inject(TenantSettingsService).getValue$('branding');
  const defaultName$ = translator$()('MAIN_CONTEXT_MENU_COMPONENT.ITEMS.SUPPORT');

  return combineLatest([branding$, defaultName$]).pipe(
    map(([branding, defaultName]) => branding?.supportMenuTitle || defaultName), // NOSONAR use ||, as we have to catch empty string, too!
  );
}
