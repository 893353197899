import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Widget } from '../../state-model/widget.model';
import { DashboardWindow } from '../../state-model/window.model';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
  <div class="wt-dashboard-window__not-found">
    Cannot instantiate {{ window.name }}-{{ widget.name }}.
  </div>`
})
export class NotFoundComponent {
  @Input() widget: Widget;
  @Input() window: DashboardWindow;
}
