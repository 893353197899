import { ColumnRegistry } from '../../../shared/grid/column-registry';
import { ColumnSetting } from '../../../shared/grid/columns.model';
import { Grid } from '../../../state-model/grid.model';
import { ListsColumnDefs } from '../../../widgets/lists/lists.columns';
import { SidebarTab } from '../sidebar.model';
import { Sidebar, WatchlistSubTab } from './sidebar-content.model';

export const DefaultSidebarListColumns: ColumnSetting[] = [
  { colId: ListsColumnDefs.countryFlagTicker.colId, width: 130 },
  { colId: ListsColumnDefs.lastValid.colId, width: 75 },
  { colId: ListsColumnDefs.listsPctChange.colId, width: 65 },
  { colId: ListsColumnDefs.time.colId, width: 76 },
];

export const SidebarWatchlistDefaultColumns: ColumnSetting[] = [
  { ...ColumnRegistry.countryFlagTicker, width: 130, cellClass: 'wt-grid--clickable' },
  { ...ListsColumnDefs.lastValid, width: 75 },
  { ...ListsColumnDefs.listsPctChange, width: 65 },
  { ...ListsColumnDefs.time, width: 76 },
];

export const SidebarWatchlistPerformanceColumns: ColumnSetting[] = [
  { ...ColumnRegistry.countryFlagTicker, width: 130, cellClass: 'wt-grid--clickable' },
  { ...ListsColumnDefs.pctChange1M, width: 75 },
  { ...ListsColumnDefs.pctChange3M, width: 65 },
  { ...ListsColumnDefs.pctChangeYTD, width: 65 },
];

export const WatchlistDefaultGrid = {
  id: 'sidebarWatchlistDefault',
  name: 'sidebarWatchlistDefault',
} as unknown as Grid;

export const WatchlistPerformanceGrid = {
  id: 'sidebarWatchlistPerformance',
  name: 'sidebarWatchlistPerformance',
} as unknown as Grid;

export const DEFAULT_SIDEBAR: Readonly<Sidebar> = {
  isOpen: false,
  selectedTab: SidebarTab.GLOBAL,
};

export const WatchlistSubTabConfigList: Readonly<{ value: WatchlistSubTab; translationKey: string; }[]> = [
  { value: WatchlistSubTab.DEFAULT, translationKey: 'SIDEBAR.TAB.WATCHLIST.DEFAULT' },
  { value: WatchlistSubTab.PERFORMANCE, translationKey: 'SIDEBAR.TAB.WATCHLIST.PERFORMANCE' },
];

export const WatchlistsSubTabs: WatchlistSubTab[] = Object.values(WatchlistSubTab)
  .filter((ctx: string | WatchlistSubTab) => isNaN(+ctx))
  .map((ctx: string) => WatchlistSubTab[ctx] as WatchlistSubTab);
