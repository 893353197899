import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'wt-iframe-overlay-content',
  templateUrl: './iframe-overlay-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IframeOverlayContentComponent {
  @Input() backdropClick$: Observable<MouseEvent>;
  @Input() closeOverlay: () => void;
  @Input() title: string;
  @Input() url: string;
}
