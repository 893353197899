<div cdkDropList class="" (cdkDropListDropped)="drop($event)">
  <div class="picked-box" *ngFor="let column of columns" cdkDrag cdkDragLockAxis="y">
    <div cdkDragHandle class="up-down-drag">
      <span class="column-name">{{ column.headerName | longHeaderName : column.colId }}</span>
    </div>

    <mat-icon class="delete-icon mat-icon-small" (click)="onDelete(column)" *ngIf="columns.length > 1"
      fontIcon="close"></mat-icon>

    <mat-icon class="lock-icon" *ngIf="columns.length === 1" fontIcon="lock"></mat-icon>
  </div>
</div>