import { map } from 'rxjs';
import { UserSettingsGridColumn } from '../../services/storage.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { Column } from '../../shared/grid/columns.model';
import { Grid, GridColumnsMap } from '../../state-model/grid.model';
import { structuresAreEqual } from '../../util/equality';
import { isEmptyObject } from '../../util/object';
import { GridRef, createGridRef } from './gridref';

export function createUserSettingsGridRef(userSettings: UserSettingsService, grid: Grid): GridRef {
  const gridId = grid.id;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const definedColumns: readonly Column[] = grid.settings?.selectedColumns
    ?? GridColumnsMap[gridId]?.defaultSelected
    ?? GridColumnsMap[gridId]?.all
    ?? [];
  return createGridRef(userSettings.getValue$('gridSettings').pipe(
    map((userGridSettings) => {
      return {
        ...grid,
        settings: {
          ...grid.settings,
          selectedColumns: mergeFromUserSettings(definedColumns, userGridSettings?.[gridId]),
        }
      } as Grid;
    }),
  ), (_, update) => {
    const currentUserStorage = userSettings.getValue('gridSettings');
    const currentSettings = currentUserStorage?.[gridId];
    const newSettings = mapToUserSettings(definedColumns ?? [], update.settings?.selectedColumns);

    if (!structuresAreEqual(newSettings, currentSettings)) {
      const newUserStorage = { ...currentUserStorage };

      if (newSettings?.length) {
        newUserStorage[gridId] = newSettings;
      } else {
        delete newUserStorage[gridId];
      }

      if (isEmptyObject(newUserStorage)) {
        userSettings.setValue('gridSettings', undefined);
      } else {
        userSettings.setValue('gridSettings', newUserStorage);
      }
    }
  });
}


function mergeFromUserSettings(definedColumns: readonly Column[], selectedColumns: readonly UserSettingsGridColumn[]): readonly Column[] {
  if (!selectedColumns?.length) {
    return definedColumns;
  }

  return selectedColumns.map(col => {
    const defn = definedColumns.find(c => c.colId === col.colId);
    if (!defn) {
      return undefined;
    }

    const merged = { ...defn };

    if (defn.sortable !== false && col.sort) {
      merged.sort = col.sort;
    }
    if (defn.resizable !== false && col.width) {
      merged.width = col.width;
    }

    return merged;
  }).filter(c => !!c) as Column[];
}

function mapToUserSettings(definedColumns: readonly Column[], selectedColumns: readonly UserSettingsGridColumn[] | undefined): readonly UserSettingsGridColumn[] | undefined {
  if (!selectedColumns?.length) {
    return selectedColumns;
  }

  return selectedColumns.map(c => {
    const defn = definedColumns.find(d => d.colId === c.colId);
    if (!defn) {
      return undefined;
    }

    const unmerged: Column = { colId: c.colId };

    if (defn.sortable !== false && c.sort) {
      unmerged.sort = c.sort;
    }
    if (defn.resizable !== false && c.width) {
      unmerged.width = c.width;
    }

    return unmerged;
  }).filter(c => !!c) as UserSettingsGridColumn[];
}
