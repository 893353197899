
import { WatchlistService } from '../../../../services/watchlist.service';
import { ContextMenuItem } from '../../../../shared/models/context-menu.model';
import { WatchlistWindow } from '../../../../state-model/window.model';
import { WindowItemTypeOptions } from '../context-menu.model';
import { Logger } from '@vwd/ngx-logging';


export const removeFromWatchlistItem = (window: WatchlistWindow, options: WindowItemTypeOptions, watchlistService: WatchlistService, logger: Logger): ContextMenuItem => {

  const watchlists = options.watchlistByProvider;
  const selectedWatchlistId = window.settings?.selectedWatchlist;
  const selectedWatchlist = Object.values(watchlists || {})
    .flatMap((lists) => lists)
    .find((wl) => wl.id === selectedWatchlistId);

  return {
    id: 'removeFromWatchlist',
    title: 'CONTEXT_MENU_COMPONENT.WATCHLIST.REMOVE_FROM_WATCHLIST',
    icon: 'delete',
    isSVGIcon: true,
    disabled: watchlistService.shouldDisableWriteAccessForWatchlist$(selectedWatchlist),
    onClick: (params) => {
      logger.info('remove-from-watchlist', selectedWatchlist, params);
      if (!params.instrument || !selectedWatchlistId) {
        logger.error('remove-from-watchlist - instrument or selected watchlist not found', params.instrument, selectedWatchlistId);
        return;
      }
      if (!selectedWatchlist) {
        logger.error('remove-from-watchlist - selected watchlist not found', selectedWatchlistId, watchlists);
        return;
      }

      watchlistService.updateWatchlist(selectedWatchlist.title, params.instrument, selectedWatchlist.provider, false);
    },
  };
};
