import { Injectable, inject } from '@angular/core';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { PeriodService } from '../../services/period.service';
import { SdkRequestsService } from '../../services/sdk-requests.service';
import { SdkService } from '../../services/sdk.service';
import { StoreService } from '../../services/store.service';
import { TradingPositionsService } from '../../services/trading-positions.service';
import { TradingService } from '../../services/trading.service';
import { Column } from '../../shared/grid/columns.model';
import { Period } from '../../shared/period/period.model';
import { ProgressService, trackProgress } from '../../shared/progress';
import { CalendarWidget } from '../../state-model/widget.model';
import { FeedFilterItem } from '../../typings/models/feed-filterable';
import { CalendarType, isCountrySearchableWindow } from './../../state-model/window.model';
import { multiInstrumentColumns, singleInstrumentColumns } from './calendar.columns';
import { CalendarEvent } from './calendar.model';

const MorningstarCalendarCorporateActions = 5172;
const SnPCalendarCompany = 5171;
const InfrontNordicCalendar = 1047;

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  private readonly sdkService: SdkService = inject(SdkService);
  private readonly sdkRequestsService: SdkRequestsService = inject(SdkRequestsService);
  private readonly storeService: StoreService = inject(StoreService);
  private readonly tradingPositionsService: TradingPositionsService = inject(TradingPositionsService);
  private readonly tradingService: TradingService = inject(TradingService);

  private calendarEventCache: { [countryId: string]: { from: number; to: number; calendarEvents: InfrontSDK.CalendarEvent[]; } } = {}; // @WIP, caches CalendarEvents by request

  private readonly feedFilterItemsAction = new BehaviorSubject<FeedFilterItem[]>([]);
  readonly feedFilterItems$ = this.feedFilterItemsAction.asObservable();

  private readonly watchlistSource$ = (widget: CalendarWidget): Observable<InfrontSDK.FinancialCalendarOptions['source']> =>
    this.sdkRequestsService.selectedWatchlist$(widget).pipe(
      map((wl) => wl?.items ?? [])
    );

  private readonly windowInstrumentSource$ = (widget: CalendarWidget): Observable<InfrontSDK.FinancialCalendarOptions['source']> =>
    this.sdkRequestsService.windowInstrument$(widget, { filterInvalid: false });

  private readonly countrySource$ = (widget: CalendarWidget): Observable<InfrontSDK.FinancialCalendarOptions['source']> => this.storeService.windowByWidget$(widget).pipe(
    map((window) => isCountrySearchableWindow(window) && window.settings.countries ? window.settings.countries : [])
  );

  private readonly portfolioSource$: Observable<InfrontSDK.FinancialCalendarOptions['source']> = this.tradingService.tradingConnected$.pipe(
    switchMap((connected) => connected ? this.tradingPositionsService.portfolioInstruments$ : of([]))
  );

  /**
   * Defines the allowed CalendarFeeds (SnP, Infront, Morningstar)
   * Logic defined in IWT-1081, but still not 100% clear
   */
  private readonly allowedFeeds$ = this.sdkRequestsService.feedList$.pipe(map((userfeeds: number[]) => {
    const calendarFeeds: number[] = [];
    const distinctCalendarFeeds: { [calendarFeed: number]: boolean; } = {};

    for (const userfeed of userfeeds) {
      if (userfeed === MorningstarCalendarCorporateActions && !distinctCalendarFeeds[MorningstarCalendarCorporateActions]) {
        calendarFeeds.push(MorningstarCalendarCorporateActions);
        distinctCalendarFeeds[MorningstarCalendarCorporateActions] = true;
        if (calendarFeeds.length === 3) {
          break;
        }
      }
      if (userfeed === SnPCalendarCompany && !distinctCalendarFeeds[SnPCalendarCompany]) {
        calendarFeeds.push(SnPCalendarCompany);
        distinctCalendarFeeds[SnPCalendarCompany] = true;
        if (calendarFeeds.length === 3) {
          break;
        }
      }
      if (userfeed === InfrontNordicCalendar && !distinctCalendarFeeds[InfrontNordicCalendar]) {
        calendarFeeds.push(InfrontNordicCalendar);
        distinctCalendarFeeds[InfrontNordicCalendar] = true;
        if (calendarFeeds.length === 3) {
          break;
        }
      }
    }

    return calendarFeeds;
  }), shareReplay(1));


  /* Logic from IWT-1081, with only allowing MorningStar and either SnP or Infront CalendarFeeds
  private allowedFeeds$ = this.sdkRequestsService.feedList$.pipe(map((userfeeds: number[]) => {
    const calendarFeeds: number[] = [];
    const distinctCalendarFeeds: { [calendarFeed: number]: number; } = {}; // value as the index
    const iterations = (userfeeds || []).length - 1;

    for (const userfeed of userfeeds) {
      const userfeed = userfeeds[i];

      if (userfeed === MorningstarCalendarCorporateActions && distinctCalendarFeeds[MorningstarCalendarCorporateActions] == undefined) {
        calendarFeeds.push(userfeed);
        distinctCalendarFeeds[userfeed] = calendarFeeds.length - 1;
        if (distinctCalendarFeeds[SnPCalendarCompany] != undefined) {
          break;
        }
      }

      if (userfeed === SnPCalendarCompany && distinctCalendarFeeds[SnPCalendarCompany] == undefined) {
        const infrontNordicCalendarIndex = distinctCalendarFeeds[InfrontNordicCalendar];
        if (infrontNordicCalendarIndex != undefined) {
          calendarFeeds.splice(infrontNordicCalendarIndex, 1);
        }
        calendarFeeds.push(SnPCalendarCompany);
        distinctCalendarFeeds[SnPCalendarCompany] = calendarFeeds.length - 1;
        if (distinctCalendarFeeds[MorningstarCalendarCorporateActions] != undefined) {
          // early return
          break;
        }
      }
      if (userfeed === InfrontNordicCalendar && distinctCalendarFeeds[SnPCalendarCompany] == undefined) {
        calendarFeeds.push(InfrontNordicCalendar);
        distinctCalendarFeeds[InfrontNordicCalendar] = calendarFeeds.length - 1;
      }
    }

    return calendarFeeds;
  }), shareReplay(1));
  */

  private readonly calendarEventsBySource$ = (widget: CalendarWidget, calendarType: CalendarType, selectedPeriod: Period | undefined, progress: ProgressService): Observable<InfrontSDK.CalendarEvent[]> => {
    if (!selectedPeriod) {
      return of([]);
    }

    let source$: Observable<InfrontSDK.FinancialCalendarOptions['source']>;
    switch (calendarType) {
      case 'Instrument':
        source$ = this.windowInstrumentSource$(widget);
        break;
      case 'Watchlist':
        source$ = this.watchlistSource$(widget);
        break;
      case 'Country':
        source$ = this.countrySource$(widget);
        break;
      case 'Portfolio':
        source$ = this.portfolioSource$;
        break;
    }

    return source$.pipe(
      switchMap((source) => {
        // IWT-1256 early empty return, to avoid sending financialCalender search with empty source,
        // like in instrument-window >> calendar widget, with no watchlist-sources!
        if (!source || (Array.isArray(source) && !source?.length)) {
          return of([]);
        }

        const { from, to } = PeriodService.getPeriodDates(selectedPeriod) || {};

        // ignore default by InfrontSDK.financialCalendar
        if (!from || !to) {
          return of([]);
        }

        const opts: Omit<InfrontSDK.FinancialCalendarOptions, 'onData'> = {
          source,
          from,
          to,
          limit: 100,
        };

        return combineLatest([this.sdkService.getArray$(InfrontSDK.financialCalendar, opts, InfrontUtil.InitialUpdate.None), this.allowedFeeds$]).pipe(
          trackProgress({ label: 'calEvents$', progress }),
          map(([calendarEvents, allowedFeeds]) => {
            let filteredEvents: InfrontSDK.CalendarEvent[] = [];

            if (allowedFeeds.length) {
              calendarEvents.forEach((event) => {
                if (allowedFeeds.includes(event.feed)) {
                  filteredEvents.push(event);
                }
              });
            } else {
              filteredEvents = calendarEvents;
            }

            return filteredEvents;
          }),
        );
      })
    );
  };

  readonly calendarEvents$ = (widget: CalendarWidget, calendarType: CalendarType, selectedPeriod: Period | undefined, progress: ProgressService): Observable<CalendarEvent[]> =>
    this.calendarEventsBySource$(widget, calendarType, selectedPeriod, progress).pipe(
      switchMap((events) =>
        this.sdkService
          .getArray$(InfrontSDK.feedInfo, {
            infoType: InfrontSDK.FeedInfoType.MetaData,
            feed: events.map((item) => item.feed),
          })
          .pipe(
            trackProgress({ label: 'calendarEvents$', progress }),
            map((feedMetadataList) => {
              const eventsWithFeedInfo = events.map((event, i) => ({
                feedShortName: (feedMetadataList[i] as InfrontSDK.FeedInfo).feedCode,
                feedLongName: (feedMetadataList[i] as InfrontSDK.FeedInfo).description,
                symbols: [event.symbolId],
                ...event,
                index: `${i}~${InfrontUtil.makeUUID()}`,
              }));
              this.feedFilterItemsAction.next(this.uniqueEvents(eventsWithFeedInfo));
              return eventsWithFeedInfo;
            })
          )
      )
    );

  readonly columns$ = (calendarType: CalendarType): Observable<Column[]> => {
    let columnsByCalendarType: Column[];

    // switch for easier extension
    switch (calendarType) {
      case 'Instrument':
        columnsByCalendarType = InfrontUtil.deepCopy(singleInstrumentColumns) as Column[];
        break;
      default:
        columnsByCalendarType = InfrontUtil.deepCopy(multiInstrumentColumns) as Column[];
        break;
    }

    return of(columnsByCalendarType);
  };

  private readonly uniqueEvents = (events: CalendarEvent[]): FeedFilterItem[] => {
    const filtered = [...new Set(events.map((event) => event.feed))].map((feed) => ({
      feed,
      shortName: events.find((item) => item.feed === feed)?.feedShortName ?? '',
      longName: events.find((item) => item.feed === feed)?.feedLongName ?? '',
      active: true,
    }));
    return filtered;
  };

}
