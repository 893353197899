import { Injectable, inject } from '@angular/core';
import { DashboardModel, DashboardType, WidgetDataProvider, WidgetProviderLoadHistoryOptions, WidgetProviderSaveOptions, WidgetStructureModel, WidgetsHistoryModel } from '@infront/ngx-dashboards-fx';
import { InfrontSDK } from '@infront/sdk';
import { LogService } from '@vwd/ngx-logging';
import { EMPTY, Observable, of } from 'rxjs';
import { Instrument } from '../../state-model/window.model';
import { createInstrumentDashboardWidgets } from './instrument-dashboard-template';

@Injectable({ providedIn: 'root' })
export class InstrumentWidgetDataProvider extends WidgetDataProvider {

  private logger = inject(LogService).openLogger('services/dashboards/provider/instrument-widgets');

  loadHistory(dashboard: DashboardModel): Observable<readonly WidgetsHistoryModel[]> {
    // no history for instrument dashboards
    return of([]);
  }

  loadByDashboard(dashboard: DashboardModel): Observable<WidgetStructureModel> {
    if (dashboard.type === DashboardType.FOLDER) {
      this.logger.error(`Dashboard ${dashboard.id} is a folder.`, dashboard);
      throw new Error('Do not call load widgets for a FOLDER');
    }
    const { instrument, classification } = dashboard.attributes;
    if (!instrument || !classification) {
      this.logger.error(`Dashboard ${dashboard.id} does not have instrument or classification set.`, dashboard);
      return of({ widgets: [] });
    }

    const widgets = createInstrumentDashboardWidgets(
      dashboard.id,
      instrument as unknown as Instrument,
      classification as InfrontSDK.SymbolClassification
    );

    return of(widgets);
  }

  loadByHistory(entry: WidgetProviderLoadHistoryOptions): Observable<WidgetStructureModel> {
    throw new Error('Not supported.');
  }

  save(saveOptions: WidgetProviderSaveOptions): Observable<void> {
    this.logger.error('Save is not supported.');
    return EMPTY;
  }

  revertDraft(dashboard: DashboardModel): Observable<void> {
    this.logger.error('Revert draft is not supported.');
    return EMPTY;
  }

}
