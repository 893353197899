<div
  class="wt-performance-bar"
  *ngIf="
    !hidden && settings.thickness != undefined && settings.markerThickness != undefined && downPercent != undefined && markerPosition != undefined
  ">
  <div *ngIf="settings.direction === 'Horizontal'">
    <div *ngIf="settings?.showLabels && ohlc" class="wt-performance-bar__labels">
      <div class="wt-performance-bar__labels-low">
        <span class="wt-performance-bar__labels-low-label">{{ "PERFORMANCE_BAR_COMPONENT.LOW" | translate }}</span>
        {{ ohlc.low | price: { decimals } }}
      </div>

      <div class="wt-performance-bar__labels-high">
        <span class="wt-performance-bar__labels-high-label">{{ "PERFORMANCE_BAR_COMPONENT.HIGH" | translate }}</span>
        {{ ohlc.high | price: { decimals } }}
      </div>
    </div>

    <div class="wt-performance-bar__horizontal-container">
      <div
        class="wt-performance-bar__horizontal"
        [ngStyle]="{ height: settings.thickness - 4 + 'px' }"
        [ngClass]="{ 'wt-performance-bar__horizontal--disabled': disabled }">
        <div *ngIf="!disabled" class="down-horizontal" [ngStyle]="{ width: downPercent + '%' }"></div>
      </div>

      <div *ngIf="!hideMarker"
        class="current-marker-horizontal"
        [ngStyle]="{
          height: settings.markerHeight + 'px',
          right: 100 - markerPosition + '%',
          'min-width': settings.markerThickness + 'px'
        }"></div>
    </div>
  </div>

  <ng-container *ngIf="settings.direction === 'Vertical'">
    <div class="wt-performance-bar__vertical-container">
      <div
        class="wt-performance-bar__vertical"
        [ngStyle]="{ width: settings.thickness - 4 + 'px' }"
        [ngClass]="{ 'wt-performance-bar__vertical--disabled': disabled }">
        <div *ngIf="!disabled" class="up-vertical" [ngStyle]="{ height: 100 - downPercent + '%' }"></div>
      </div>
      <div *ngIf="!hideMarker"
        class="current-marker-vertical"
        [ngStyle]="{
          width: settings.thickness + 'px',
          top: '-' + markerPosition + '%',
          height: settings.markerThickness + 'px'
        }"></div>
    </div>
  </ng-container>
</div>