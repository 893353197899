import { Component, Input } from '@angular/core';

import { AlertLogWindow } from '../../../state-model/window.model';
import { Dashboard } from '../../../state-model/dashboard.model';

@Component({
  selector: 'wt-alert-log-header',
  templateUrl: './alert-log-header.component.html',
})
export class AlertLogHeaderComponent {
  @Input() dashboard: Dashboard;
  @Input() window: AlertLogWindow;
}
