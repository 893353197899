<ng-container *ngIf="rawWatchlists$ | async"></ng-container>

<div class="wt-search">
  <input
    class="wt-search__input"
    [ngModel]="searchInput$ | async"
    (ngModelChange)="searchInputAction.next($event)"
    [placeholder]="'SEARCH.INPUT_PLACEHOLDER' | translate"
    (focus)="showDropdownAction.next(true)"
    (click)="showDropdownAction.next(true)"
    autocomplete="off"
    type="search"
    id="search"
    #searchElm />
</div>

<!-- This needs to be it's own component -->
<div (clickOutside)="closeDropdown()" [ignoreElements]="[searchElm]" *ngIf="showDropdown$ | async">
  <ng-container *ngIf="resultGroups$ | async as resultGroups">
    <div *ngIf="resultGroups?.length" class="wt-search__dropdown">
      <div class="wt-search__dropdown-scroller" [ngSwitch]="searchDataSource">
        <ng-container *ngFor="let resultGroup of resultGroups">
          <ng-container *ngIf="resultGroup.items.length">
            <!-- filter out categories without items so we are able to use css nth-child selectors -->
            <div [@listAnimation]="resultGroup.items.length">
              <div class="wt-flex-row wt-flex-align-justify wt-flex-collapse wt-search__dropdown-header"
                [ngClass]="{ 'wt-search__dropdown-header--collapsed': resultGroup.collapse }">
                <div class="wt-flex-column wt-flex-shrink wt-search__dropdown-header-type">
                  {{ resultGroup.title | translate }}
                </div>

                <div
                  *ngIf="resultGroup.items.length > ToggleShowMoreSearchResultsThreshold && searchDataSource === SearchDataSource.SEARCH"
                  class="wt-flex-column wt-flex-shrink cursor-pointer font-bold"
                  (click)="toggleShowMoreResult({ resultGroup, resultGroups})">
                  {{
                  !resultGroup.showMore
                  ? ("SEARCH.SHOW_MORE" | translate: { count: resultGroup.items.length })
                  : ("SEARCH.SHOW_LESS" | translate)
                  }}
                </div>
              </div>

              <ng-container *ngFor="let resultItem of resultGroup.items; let i = index">
                <ng-container
                  *ngIf="!resultGroup.collapse && (resultGroup.showMore ? i < MaxFullSearchResultGroupItemLimit : i < ToggleShowMoreSearchResultsThreshold)"
                  [ngSwitch]="resultItem.itemType">
                  <!-- START Symbol -->
                  <wt-search-item-symbol
                    *ngSwitchCase="SearchResultItemType.Symbol"
                    class="wt-search__item-symbol"
                    [ngClass]="{ 'wt-search__item--expanded': resultItem.expanded }"
                    [searchItemSymbol]="{
                      ticker: resultItem.Ticker,
                      feed: resultItem.Feed,
                      isin: resultItem.ISIN,
                      feedExchange: resultItem.FeedExchange,
                      feedDescription: resultItem.FeedDesc,
                      fullName: resultItem.FullName,
                      isoCountry: resultItem.Country,
                      tradable: resultItem.IsTradable,
                      access: resultItem.FeedAccessStr || resultItem.access,
                      currency: resultItem.Currency,
                      symbolClassification: resultItem.SymbolClassification || SymbolClassification.Unknown,
                      feedIsHidden: resultItem.FeedIsHidden
                    }"
                    [searchDataSource]="searchDataSource"
                    (closeDropdown)="closeDropdown()"
                    (click)="onToggleItemExpand(resultItem)"
                    (tickerClick)="addSymbolItemToHistory(resultItem)"
                    (feedClick)="addMarketItemToHistory(resultItem)"></wt-search-item-symbol>
                  <!-- END Symbol -->

                  <!-- START Market -->
                  <wt-search-item-market
                    *ngSwitchCase="SearchResultItemType.Market"
                    class="wt-search__item-market"
                    [ngClass]="{ 'wt-search__item--expanded': resultItem.expanded }"
                    [searchItemMarket]="{
                      Country: resultItem.Country,
                      ExchangeCode: resultItem.ExchangeCode,
                      Feed: resultItem.Feed,
                      Description: resultItem.Description,
                      Access: resultItem.Access
                    }"
                    [searchDataSource]="searchDataSource"
                    (closeDropdown)="closeDropdown()"
                    (tickerClick)="addMarketItemToHistory(resultItem)"
                    (feedClick)="addMarketItemToHistory(resultItem)"
                    addDashboardWindow
                    (dashboardWindowOnProceedCallback)="closeDropdown(); addSymbolItemToHistory(resultItem)"
                    [dashboardWindowToAdd]="{ name: 'MarketWindow', settings: { feed: resultItem.Feed } }"></wt-search-item-market>
                  <!-- END Market -->

                  <!-- START Window -->
                  <wt-search-item-window
                    *ngSwitchCase="SearchResultItemType.Window"
                    class="wt-search__item-widget"
                    [searchItemWindow]="{
                      windowName: resultItem.windowName,
                      translation: resultItem.translation,
                      description: resultItem.description,
                      icon: resultItem.icon
                    }"
                    addDashboardWindow
                    (dashboardWindowOnProceedCallback)="closeDropdown();"
                    [dashboardWindowToAdd]="{ name: resultItem.windowName }"></wt-search-item-window>
                  <!-- END Window -->

                  <!-- EXPAND Functionality -->
                  <div *ngIf="resultItem.expanded" class="wt-search__dropdown-item-expand">
                    <div *ngIf="resultItem?.Ticker" class="wt-search__dropdown-item-expand-row">
                      <!-- only symbol -->
                      <div class="wt-search__dropdown-item-expand-row__trading-actions">
                        <ng-container *ngIf="isTradable">
                          <div
                            class="wt-search__dropdown-item-expand-row__item  wt-search__dropdown-item-expand-row__trading-actions__buy-sell"
                            (click)="openOrderEntry(resultItem)">
                            <mat-icon class="mat-icon-small" fontIcon="add_shopping_cart"></mat-icon>
                            <span>{{ "SEARCH.EXPANDED_ITEM.BUY_SELL" | translate }}</span>
                          </div>
                        </ng-container>
                        <div
                          class="wt-search__dropdown-item-expand-row__item  wt-search__dropdown-item-expand-row__trading-actions__add-to-watchlist"
                          [ngClass]="{
                              'wt-search__dropdown-item-expand-row__trading-actions__add-to-watchlist--show': resultItem?.expandedTab === 'watchlist'
                            }"
                          (click)="resultItem.expandedTab = resultItem.expandedTab === 'windows' ? 'watchlist' : 'windows'">
                          <mat-icon class="mat-icon-small"
                            [ngClass]="{ 'mat-icon--selected': resultItem?.expandedTab === 'watchlist' }"
                            fontIcon="playlist_add">
                          </mat-icon>
                          <span>{{ "SEARCH.EXPANDED_ITEM.ADD_TO_WATCHLIST" | translate }}</span>
                        </div>
                        <div
                          class="wt-search__dropdown-item-expand-row__item  wt-search__dropdown-item-expand-row__trading-actions__add-alert"
                          (click)="addAlert(resultItem)">
                          <mat-icon class="mat-icon-small" svgIcon="notification_add"></mat-icon>

                          <span>{{ "SEARCH.EXPANDED_ITEM.ADD_ALERT" | translate }}</span>
                        </div>
                      </div>
                    </div>

                    <!-- EXPAND Watchlists -->
                    <ng-container *ngIf="resultItem?.expandedTab === 'watchlist'">
                      <ng-container *ngIf="watchlists$ | async as wls">
                        <div
                          *ngFor="let wl of wls"
                          class="wt-search__dropdown-item-expand-row wt-search__dropdown-item-expand-row__watchlist-item"
                          [hidden]="wl.hide | async">
                          <div class="wt-flex-column" (click)="onClickToggleWatchlistInstrument(wl)">
                            <div class="wt-search__dropdown-item-expand-row__watchlist-item__content">
                              <mat-checkbox
                                class="wt-search__dropdown-item-expand-row__watchlist-item__content__checkbox"
                                [disableRipple]="false"
                                [checked]="wl.hasInstrument"
                                onclick="return false">
                                {{ wl.providerName ? (wl.providerName + ' - ' + (wl.title || ('GLOBAL.WATCHLIST.UNKNOWN'
                                | translate))) : (wl.title || ('GLOBAL.WATCHLIST.UNKNOWN' | translate)) }}
                              </mat-checkbox>
                            </div>
                            <div class="wt-search__dropdown-item-expand-row__watchlist-item__content">
                              <span class="wt-search__dropdown-item-expand-row__watchlist-item__content--instruments">
                                {{ (wl.items.length === 1 ? 'SEARCH.WATCHLIST_INSTRUMENT' :
                                'SEARCH.WATCHLIST_INSTRUMENTS') | translate: { count: wl.items.length } }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                    <!-- END EXPAND Watchlists -->

                    <!-- EXPAND Spawnable Windows -->
                    <ng-container *ngIf="resultItem?.expandedTab === 'windows'">
                      <!-- MarketWindow -->
                      <div
                        *ngIf="resultItem?.Feed && !resultItem?.Ticker"
                        class="wt-search__dropdown-item-expand-row"
                        addDashboardWindow
                        (dashboardWindowOnProceedCallback)="closeDropdown(); addMarketItemToHistory(resultItem)"
                        [dashboardWindowToAdd]="{
                            name: 'MarketWindow',
                            settings: { feed: resultItem.Feed }
                          }">
                        <!-- only symbol -->
                        <div class="wt-search__dropdown-item-expand-row--history">
                          <mat-icon class="mat-icon-small" fontIcon="widgets"></mat-icon>
                          <span>{{ "SEARCH.EXPANDED_ITEM.MARKET" | translate }}</span>
                        </div>
                      </div>
                      <!-- END MarketWindow -->

                      <!-- Open Instrument Dashboard -->
                      <div *ngIf="resultItem?.Ticker" class="wt-search__dropdown-item-expand-row">
                        <!-- only symbol -->
                        <div
                          class="wt-search__dropdown-item-expand-row--instrument-dashboard"
                          addDashboard
                          (dashboardOnProceedCallback)="closeDropdown(); addSymbolItemToHistory(resultItem)"
                          [addDashboardInstrument]="{ ticker: resultItem?.Ticker, feed: resultItem.Feed }">
                          <mat-icon class="mat-icon-small" fontIcon="launch"></mat-icon>
                          <span>{{ "SEARCH.EXPANDED_ITEM.OPEN_INSTRUMENT_DASHBOARD" | translate }}</span>
                        </div>
                      </div>
                      <!-- END Open Instrument Dashboard -->

                      <!-- InstrumentWindow -->
                      <div
                        *ngIf="resultItem?.Ticker"
                        class="wt-search__dropdown-item-expand-row"
                        addDashboardWindow
                        (dashboardWindowOnProceedCallback)="closeDropdown(); addSymbolItemToHistory(resultItem)"
                        [dashboardWindowToAdd]="{
                            name: 'InstrumentWindow',
                            settings: { instrument: { ticker: resultItem?.Ticker, feed: resultItem.Feed } }
                          }">
                        <!-- only symbol -->
                        <div class="wt-search__dropdown-item-expand-row--instrument-overview">
                          <mat-icon class="mat-icon-small" fontIcon="widgets"></mat-icon>
                          <span>{{ "SEARCH.EXPANDED_ITEM.INSTRUMENT_OVERVIEW" | translate }}</span>
                        </div>
                      </div>
                      <!-- END InstrumentWindow -->

                      <!-- FocusWindow -->
                      <div
                        *ngIf="resultItem?.Ticker"
                        class="wt-search__dropdown-item-expand-row"
                        addDashboardWindow
                        (dashboardWindowOnProceedCallback)="closeDropdown(); addSymbolItemToHistory(resultItem)"
                        [dashboardWindowToAdd]="{
                            name: 'FocusWindow',
                            settings: { instrument: { ticker: resultItem.Ticker, feed: resultItem?.Feed } }
                          }">
                        <!-- only symbol -->
                        <div class="wt-search__dropdown-item-expand-row--focus">
                          <mat-icon class="mat-icon-small" fontIcon="widgets"></mat-icon>
                          <span>{{ "SEARCH.EXPANDED_ITEM.FOCUS" | translate }}</span>
                        </div>
                      </div>
                      <!-- END FocusWindow -->

                      <!-- ChartWindow -->
                      <div
                        *ngIf="resultItem?.Ticker"
                        class="wt-search__dropdown-item-expand-row"
                        addDashboardWindow
                        (dashboardWindowOnProceedCallback)="closeDropdown(); addSymbolItemToHistory(resultItem)"
                        [dashboardWindowToAdd]="{
                            name: 'ChartWindow',
                            settings: { instrument: { ticker: resultItem.Ticker, feed: resultItem?.Feed } }
                          }">
                        <!-- only symbol -->
                        <div class="wt-search__dropdown-item-expand-row--chart">
                          <mat-icon class="mat-icon-small" fontIcon="widgets"></mat-icon>
                          <span>{{ "SEARCH.EXPANDED_ITEM.CHART" | translate }}</span>
                        </div>
                      </div>
                      <!-- END ChartWindow -->

                      <!-- ChartMiniWindow -->
                      <div
                        *ngIf="resultItem?.Ticker"
                        class="wt-search__dropdown-item-expand-row"
                        addDashboardWindow
                        (dashboardWindowOnProceedCallback)="closeDropdown(); addSymbolItemToHistory(resultItem)"
                        [dashboardWindowToAdd]="{
                            name: 'ChartMiniWindow',
                            settings: { instrument: { ticker: resultItem.Ticker, feed: resultItem?.Feed } }
                          }">
                        <!-- only symbol -->
                        <div class="wt-search__dropdown-item-expand-row--chart-mini">
                          <mat-icon class="mat-icon-small" fontIcon="widgets"></mat-icon>
                          <span>{{ "SEARCH.EXPANDED_ITEM.CHART_MINI" | translate }}</span>
                        </div>
                      </div>
                      <!-- END ChartMiniWindow -->

                      <!-- OrderbookWindow -->
                      <div
                        *ngIf="resultItem?.Ticker && canAddWindowForClassification('Orderbook', resultItem?.SymbolClassification)"
                        class="wt-search__dropdown-item-expand-row"
                        addDashboardWindow
                        (dashboardWindowOnProceedCallback)="closeDropdown(); addSymbolItemToHistory(resultItem)"
                        [dashboardWindowToAdd]="{
                            name: 'OrderbookWindow',
                            settings: { instrument: { ticker: resultItem.Ticker, feed: resultItem?.Feed } }
                          }">
                        <!-- only symbol && !fund -->
                        <div class="wt-search__dropdown-item-expand-row--orderbook">
                          <mat-icon class="mat-icon-small" fontIcon="widgets"></mat-icon>
                          <span>{{ "SEARCH.EXPANDED_ITEM.ORDERBOOK" | translate }}</span>
                        </div>
                      </div>
                      <!-- END OrderbookWindow -->

                      <!-- InstrumentWindow - TradesWidget -->
                      <div
                        *ngIf="resultItem?.Ticker && canAddWindowForClassification('Trades', resultItem?.SymbolClassification)"
                        class="wt-search__dropdown-item-expand-row"
                        addDashboardWindow
                        (dashboardWindowOnProceedCallback)="closeDropdown(); addSymbolItemToHistory(resultItem)"
                        [dashboardWindowToAdd]="{
                            name: 'InstrumentWindow',
                            selectedWidgetName: 'Trades',
                            settings: { instrument: { ticker: resultItem.Ticker, feed: resultItem?.Feed } }
                          }">
                        <!-- only symbol -->
                        <div class="wt-search__dropdown-item-expand-row--trades">
                          <mat-icon class="mat-icon-small" fontIcon="widgets"></mat-icon>
                          <span>{{ "SEARCH.EXPANDED_ITEM.TRADES" | translate }}</span>
                        </div>
                      </div>
                      <!-- END InstrumentWindow - TradesWidget -->

                      <!-- NewsAndCalendarWindow -->
                      <div
                        *ngIf="resultItem?.Ticker && canAddWindowForClassification('News', resultItem?.SymbolClassification)"
                        class="wt-search__dropdown-item-expand-row"
                        addDashboardWindow
                        (dashboardWindowOnProceedCallback)="closeDropdown(); addSymbolItemToHistory(resultItem)"
                        [dashboardWindowToAdd]="{
                            name: 'NewsWindow',
                            settings: {
                              newsType: 'Instrument',
                              instrument: { ticker: resultItem.Ticker, feed: resultItem?.Feed }
                            }
                          }">
                        <!-- only symbol, stock and etf -->
                        <div class="wt-search__dropdown-item-expand-row--news">
                          <mat-icon class="mat-icon-small" fontIcon="widgets"></mat-icon>
                          <span>{{ "SEARCH.EXPANDED_ITEM.NEWS" | translate }}</span>
                        </div>
                      </div>
                      <!-- END NewsAndCalendarWindow -->

                      <!-- InstrumentWindow - HistoryWidget -->
                      <div
                        *ngIf="resultItem?.Ticker"
                        class="wt-search__dropdown-item-expand-row"
                        addDashboardWindow
                        (dashboardWindowOnProceedCallback)="closeDropdown(); addSymbolItemToHistory(resultItem)"
                        [dashboardWindowToAdd]="{
                            name: 'InstrumentWindow',
                            selectedWidgetName: 'History',
                            settings: { instrument: { ticker: resultItem.Ticker, feed: resultItem?.Feed } }
                          }">
                        <!-- only symbol -->
                        <div class="wt-search__dropdown-item-expand-row--history">
                          <mat-icon class="mat-icon-small" fontIcon="widgets"></mat-icon>
                          <span>{{ "SEARCH.EXPANDED_ITEM.HISTORY" | translate }}</span>
                        </div>
                      </div>
                      <!-- END InstrumentWindow - HistoryWidget -->
                    </ng-container>
                    <!-- END EXPAND Spawnable Windows -->
                  </div>
                  <!-- END EXPAND Functionality -->
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="wt-search__dropdown-hide-submarkets text-disabled"
        *ngIf="searchDataSource === SearchDataSource.SEARCH">
        <mat-checkbox [disableRipple]="true" class="wt-search__dropdown-hide-submarkets-checkbox"
          [ngModel]="hideSubmarkets$ | async"
          (ngModelChange)="userSettings.setValue('searchHideSubmarkets', $event)">
          {{ "SEARCH.HIDE_SUBMARKETS" | translate }}
        </mat-checkbox>
      </div>
    </div>
  </ng-container>
</div>