<form class="wt-dialog" method="dialog" [formGroup]="form">
  <div class="wt-flex-row wt-flex-align-middle wt-flex-collapse wt-dialog__title">
    <div class="wt-flex-column wt-flex-shrink wt-dialog-icon">
      <mat-icon [fontIcon]="icon"></mat-icon>
    </div>

    <div class="wt-flex-column">
      <h1 class="wt-h1">{{ 'DASHBOARD.NEW_DASHBOARD_FOLDER_PROMPT.TITLE' | translate }}</h1>
    </div>
  </div>

  <div mat-dialog-content>
    <mat-form-field floatLabel="never">
      <input class="wt-dialog-input" matInput formControlName="name" name="name" [maxlength]="MAX_DASHBOARD_NAME_LENGTH"
        required />
      <mat-error *ngIf="name.errors?.required">{{ 'DASHBOARD.DASHBOARD_VALIDATION_MESSAGES.NAME_REQUIRED' | translate
        }}</mat-error>
      <mat-error *ngIf="name.errors?.maxlength">{{ 'DASHBOARD.DASHBOARD_VALIDATION_MESSAGES.NAME_TOO_LONG' | translate:{
        maxLength: name.errors?.maxlength.requiredLength } }}</mat-error>
      <mat-error *ngIf="name.errors?.forbiddenName">{{
        'DASHBOARD.DASHBOARD_VALIDATION_MESSAGES.NAME_CONTAINS_INVALID_CHARS' | translate }}</mat-error>
      <mat-error *ngIf="name.errors?.duplicateName">{{ 'DASHBOARD.DASHBOARD_VALIDATION_MESSAGES.NAME_ALREADY_TAKEN' |
        translate }}</mat-error>
    </mat-form-field>
  </div>

  <div class="wt-action-buttons">
    <div class="wt-flex-row wt-flex-collapse wt-flex-align-right">
      <div class="wt-flex-column wt-flex-shrink">
        <button class="wt-button wt-button--cancel" type="button" (click)="onCancel()">{{
          'DASHBOARD.NEW_DASHBOARD_FOLDER_PROMPT.CANCEL' | translate }}</button>
      </div>

      <div class="wt-flex-column wt-flex-shrink">
        <button class="wt-button wt-button--primary" type="submit"
          [mat-dialog-close]="name.value" [disabled]="!form.valid">{{ 'DASHBOARD.NEW_DASHBOARD_FOLDER_PROMPT.OK' |
          translate
          }}</button>
      </div>
    </div>
  </div>
</form>