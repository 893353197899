import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
@Component({
  selector: 'wt-star-rating',
  template: `
    <span>
      <ng-container *ngFor="let item of [].constructor(value ?? 0)">
        <mat-icon style="font-size:14px;" fontIcon="star"></mat-icon>
      </ng-container>
      <ng-container *ngFor="let item of [].constructor(5 - (value ?? 0))">
        <mat-icon style="font-size:14px;opacity:0.2" fontIcon="star"></mat-icon>
      </ng-container>
    </span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarRatingComponent implements ICellRendererAngularComp {
  @Input() value: number | undefined;

  constructor() { }

  agInit(params: ICellRendererParams): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.value = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.value = params.value;
    return true;
  }
}
