<h3>{{ 'ADMIN_DIALOG.DEFAULT_DASHBOARDS.TITLE' | translate }}</h3>

<div class="wt-flex-row">
  <div class="wt-flex-column wt-flex-large-5">
    <div class="wt-default-dashboards-panel__list"
      cdkDropList
      cdkDropListOrientation="vertical"
      [cdkDropListSortingDisabled]="!canEdit"
      (cdkDropListDropped)="drop($event)">
      <ul>
        <li *ngFor="let dashboard of dashboards$ | async"
          class="wt-default-dashboards-panel__item"
          cdkDrag
          [cdkDragData]="dashboard"
          cdkDragLogAxis="y">
          <span cdkDragHandle>
            <mat-icon class="wt-default-dashboards-panel__item-icon"
              [fontIcon]="iconFor(dashboard)"></mat-icon>
            <span class="wt-default-dashboards-panel__item-label">
              {{ dashboard.displayName }}
            </span>
          </span>

          <mat-icon *ngIf="canEdit" class="wt-default-dashboards-panel__item-remove-button" fontIcon="close"
            (click)="removeDashboard(dashboard)"></mat-icon>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="wt-default-dashboards-panel__actions" *ngIf="canEdit">
  <button class="wt-button  wt-default-dashboards-panel__actions-button" [matMenuTriggerFor]="panel.menu">
    <span class="wt-default-dashboards-panel__actions-button-label">
      {{ 'ADMIN_DIALOG.DEFAULT_DASHBOARDS.ADD_DEFAULT_DASHBOARD' | translate }}
    </span>

    <mat-icon class="wt-default-dashboards-panel__actions-button-icon" fontIcon="add"></mat-icon>
  </button>
</div>

<wt-menu-panel
  #panel
  [items]="(menuItems$ | async) ?? []"
  (itemClick)="onItemClick($event)">
</wt-menu-panel>
