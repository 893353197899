export type DropdownEntry<T extends object> = DropdownItem<T> | DropdownFolder<T>;

export type DropdownItem<T extends object> = (T
  & {
    itemLabel?: string;
    translateItemLabel?: boolean;
    dropdownLabel?: string;
    translateDropdownLabel?: boolean;
  }
);

export type DropdownFolder<T extends object> = {
  // subItems: (DropdownEntry<T>)[]; // @TODO add circular type, thus enabling subfolders
  subItems: DropdownItem<T>[];

  folderLabel?: string;
  translateFolderLabel?: boolean;
};

export function isDropdownFolder<T extends object>(item: DropdownItem<T> | DropdownFolder<T> | undefined): item is DropdownFolder<T> {
  return !!item && 'subItems' in item;
}

export function isDropdownItem<T extends object>(item: DropdownItem<T> | DropdownFolder<T> | undefined): item is DropdownItem<T> {
  return !!item && !('subItems' in item);
}