<div class="wt-dialog wt-admin-dialog" method="dialog">
  <div class="wt-flex-row wt-flex-align-middle wt-flex-collapse wt-dialog__title">
    <div class="wt-flex-column wt-flex-shrink wt-dialog-icon">
      <mat-icon fontIcon="admin_panel_settings"></mat-icon>
    </div>

    <div class="wt-flex-column">
      <h1 class="wt-h1">{{ 'ADMIN_DIALOG.TITLE' | translate }}</h1>
    </div>

    <div class="wt-flex-column wt-flex-shrink wt-flex-collapse">
      <button type="button" class="wt-button wt-button--icon wt-dialog-close-button" mat-dialog-close title="{{ 'ADMIN_DIALOG.CLOSE' | translate
        }}">
        <mat-icon fontIcon="close"></mat-icon>
      </button>
    </div>
  </div>

  <div mat-dialog-content>
    <wt-realm-admin-panel></wt-realm-admin-panel>
  </div>
</div>