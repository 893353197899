import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { PortfolioSingleValueComponent } from './portfolio-single-value.component';
import { WTKWidgetWrapperModule } from '../../../wrappers/wtk-widget-wrapper/wtk-widget-wrapper.module';


@NgModule({
  declarations: [PortfolioSingleValueComponent],
  exports: [PortfolioSingleValueComponent],
  imports: [
    CommonModule,
    WTKWidgetWrapperModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ]
})
export class PortfolioSingleValueModule { }
