<div class="wt-sidebar-instrument-lists-panel">
  <h3>{{ 'ADMIN_DIALOG.SIDEBAR.LISTS_TITLE' | translate }}</h3>

  <div class="wt-admin-dialog__details-tab-content">
    <div class="wt-sidebar-instrument-lists-panel__list">
      <div *ngFor="let item of (items$ | async) ?? []; index as index; trackBy: trackById"
        class="wt-sidebar-instrument-lists-panel__item">

        <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
          <div class=" wt-flex-column wt-flex-shrink">
            <h3>{{ 'ADMIN_DIALOG.SIDEBAR.LIST_X' | translate:{ number: index + 1 } }}</h3>
          </div>

          <div class="wt-flex-column">
            <div *ngIf="canEdit" class="wt-sidebar-instrument-lists-panel__item-delete">
              <button type="button" class="wt-button wt-button--icon" (click)="removeList(index, item)" title="{{
                'ADMIN_DIALOG.SIDEBAR.REMOVE_LIST' | translate }}">
                <mat-icon fontIcon="delete"></mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="wt-sidebar-instrument-lists-panel__item-name-edit">
          <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
            <div class="wt-flex-column" *ngFor="let language of languages">
              <label for="admin-sidebar-list-{{ index }}-name-{{ language.code }}">
                {{ language.label }}:
              </label>

              <input
                id="admin-sidebar-list-{{ index }}-name-{{ language.code }}"
                type="text"
                class="wt-dialog-input"
                matInput
                [(ngModel)]="item.name[language.code]"
                [disabled]="!canEdit"
                [required]="language.code === 'en'"
                (ngModelChange)="updateList(index, item)">
            </div>
          </div>

          <wt-sidebar-instrument-list-editor
            class="wt-sidebar-instrument-lists-panel__list-editor"
            [(instruments)]="item.symbols"
            [(columns)]="item.columns"
            [canEdit]="canEdit"
            (instrumentsChange)="updateList(index, item)"
            (columnsChange)="updateList(index, item)"></wt-sidebar-instrument-list-editor>
        </div>
      </div>
    </div>
  </div>

  <div class="wt-sidebar-instrument-lists-panel__add-item" *ngIf="canEdit">
    <div class="wt-flex-row" (click)="addListModal($event)">
      <div class="wt-flex-column">
        <button type="button" class="wt-button wt-button--icon">
          <mat-icon class="" fontIcon="add"></mat-icon> {{
          'ADMIN_DIALOG.SIDEBAR.ADD_LIST_HEADER' | translate }}</button>
      </div>
    </div>
  </div>
</div>