import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';


import { UI } from '@infront/wtk/src/InfrontUI';
import { KeycloakAuthService } from '@vwd/keycloak-auth-angular';
import { Observable, combineLatest, from, merge } from 'rxjs';
import { filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { PwaInstallService } from '../../pwa';
import { PromptKeys, UserSettingsStorageData } from '../../services/storage.service';
import { ToolkitService } from '../../services/toolkit.service';
import { TradingService } from '../../services/trading.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { ContextMenuItem } from '../../shared/models/context-menu.model';
import { injectSupportMenuTitle$ } from '../../util/branding';
import { IframeOverlayService } from '../iframe-overlay/iframe-overlay.service';
import { ResetOptionalDialogComponent } from '../reset-optional-dialog/reset-optional-dialog.component';
import { SettingsMenuService } from '../settings-menu/settings-menu.service';
import { SupportDialogComponent } from '../support-dialog/support-dialog.component';
import { MainContextItem, menuItems } from './main-context-menu.config';
@Component({
  selector: 'wt-main-context-menu',
  templateUrl: './main-context-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainContextMenuComponent {
  private menuItems = menuItems as unknown as ContextMenuItem[];

  constructor(
    private readonly keycloak: KeycloakAuthService,
    private readonly userSettingsService: UserSettingsService,
    private readonly tradingService: TradingService,
    private readonly iframeOverlayService: IframeOverlayService,
    private readonly settingsMenuService: SettingsMenuService,
    private readonly toolkitService: ToolkitService,
    private readonly pwaService: PwaInstallService,
    public dialog: MatDialog,
  ) { }

  menuItems$ = combineLatest([
    this.tradingService.tradingConnected$,
    this.pwaService.canInstall$,
    injectSupportMenuTitle$(),
  ]).pipe(
    map(([isConnected, canInstallPwa, supportMenuTitle]) => this.menuItems.filter(item => {
      return (isConnected || !item.id.includes('LogoutFromTrading')) &&
        (canInstallPwa || item.id !== 'InstallPwa');
    }).map(item => {
      if (item.id === 'Support') {
        return { ...item, title: supportMenuTitle, translateTitle: false };
      }
      return item;
    })
    ),
  );

  private logout$ = () => this.tradingService.tradingLogout$.pipe(startWith(undefined), switchMap(() => from(this.keycloak.logout({ redirectUri: window.location.origin })
    .then((success) => {
      console.log("logout success ", success);
    })
    .catch((error) => {
      console.log("logout error ", error);
    }))));

  resetOptionals = () => this.dialog.open(ResetOptionalDialogComponent).afterClosed().pipe(
    filter(result => !!result), tap(() =>
      PromptKeys.forEach(key => this.userSettingsService.setValue(key as keyof UserSettingsStorageData, false))
    ));

  onItemSelected(item: ContextMenuItem): void {
    const menuActionMap: { [key in MainContextItem]: () => Observable<unknown> } =
    {
      'Settings': this.settingsMenuService.openSettings$,
      'LogoutFromTrading': () => this.tradingService.tradingLogout$,
      'Logout': this.logout$,
      'ResetOptionalDialogs': this.resetOptionals,
      'MyProfile': this.iframeOverlayService.myProfile$,
      'Support': () => this.dialog.open(SupportDialogComponent).afterClosed(),
      'InstallPwa': () => from(this.pwaService.promptForPwaInstall()),
    };
    menuActionMap[item.id as MainContextItem]().pipe(take(1)).subscribe();
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  logoutListener$: Observable<UI | void> = merge(this.keycloak.onAuthLogout, this.toolkitService.disconnect$).pipe(switchMap(() => {
    return this.tradingService.tradingLogout$;
  }));

}

