import { TradingOrderInfoService } from '../../../../shared/trading-order-info/trading-order-info.service';
import { ContextMenuItem } from '../../../../shared/models/context-menu.model';

export const orderInfoItem: (tradingOrderInfoService: TradingOrderInfoService) => ContextMenuItem = (tradingOrderInfoService) => {
  return {
    id: 'orderInfo',
    title: 'CONTEXT_MENU_COMPONENT.TRADING.ORDER_INFO',
    icon: 'info',
    closeOnClick: true,
    onClick: (params) => {
      const tradingOrderMeta = params?.tradingOrderGetter?.();
      tradingOrderInfoService.showOrderInfo(tradingOrderMeta?.Metadata);
    },
  };
};
