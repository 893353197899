<div class="wt-fund-screener">
  <div class="wt-flex-row wt-fund-screener__title">
    <div class="wt-flex-column wt-flex-shrink">
      <mat-icon class="wt-fund-screener__title-icon" fontIcon="manage_search"></ mat-icon>
    </div>
    <div class="wt-flex-column wt-fund-screener__title-text">
      {{ 'SCREENER.FUND_SCREENER.FUND_SCREENER' | translate }}
    </div>
  </div>
  <div class="wt-flex-row">
    <wt-fund-screener-inputs>
    </wt-fund-screener-inputs>
  </div>
  <div class="wt-flex-row wt-fund-screener__result">
    <wt-fund-screener-result>
    </wt-fund-screener-result>

  </div>
</div>
