import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';

import { MatMenuTrigger } from '@angular/material/menu';
import { Dashboard } from '../../../state-model/dashboard.model';
import { DashboardWindow, LinkChannel, LinkChannels } from '../../../state-model/window.model';
import { LinkChannelService } from './link-channel.service';

@Component({
  selector: 'wt-link-channel-menu',
  templateUrl: './link-channel-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkChannelMenuComponent {
  @Input() dashboard: Dashboard;
  @Input() window: DashboardWindow;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  isActive = false;

  constructor(private linkChannelService: LinkChannelService) { }

  activate(): void {
    this.isActive = true;
    setTimeout(() => {
      this.trigger.openMenu();
    }, 0);
  }
  onLinkChannelClick(linkChannel: LinkChannel, window: DashboardWindow): void {
    if (this.window.linkChannel === linkChannel) {
      return;
    }
    this.linkChannelService.setLinkChannel$(linkChannel, window).pipe(take(1)).subscribe();
  }

  onLinkChannelHover(linkChannel: LinkChannel | undefined): void {
    this.linkChannelService.setLinkChannelHover(linkChannel);
  }

  linkChannelOptions(): readonly LinkChannel[] {
    const hideItemCount = this.window.linkChannel ? 1 : 2;
    return LinkChannels.slice(0, LinkChannels.length - hideItemCount);
  }
}
