import { MatDialog } from '@angular/material/dialog';
import { map, switchMap, take } from 'rxjs';
import { StoreService } from '../../../../services/store.service';
import { ChainInfoDialogComponent } from '../../../../shared/chain-info-dialog/chain-info-dialog.component';
import { ContextMenuItem } from '../../../../shared/models/context-menu.model';
import { DashboardWindow } from '../../../../state-model/window.model';
import { filterUndefined } from '../../../../util/rxjs';
import { ListsService } from '../../../../widgets/lists/lists.service';
import { WindowItemTypeOptions } from '../context-menu.model';

export const showChainInfo = (
  window: DashboardWindow,
  options: WindowItemTypeOptions,
  storeService: StoreService,
  listService: ListsService,
  dialogService: MatDialog
): ContextMenuItem => {
  return {
    id: 'showChainInfo',
    title: 'CONTEXT_MENU_COMPONENT.MARKET.SHOW_CHAIN_INFO',
    icon: 'info_outlined',
    isSVGIcon: false,
    closeOnClick: true,
    onClick: (params) => {
      if (!params.window) {
        return;
      }

      storeService.selectedWidgetInWindow$(window).pipe(
        switchMap(widget => listService.selectedSource$(widget)),
        map(source => source.sourceType === 'Chain' ? source.chain : undefined),
        take(1), // don't listen to changes
        filterUndefined(),
      ).subscribe(chain => {
        dialogService.open(ChainInfoDialogComponent, {
          data: chain,
          minWidth: '200px',
          maxWidth: '50vw',
        });
      });
    },
  };
};
