import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';

import { timeOrDateOutdated } from '../../../shared/grid/column-registry';
import { AlertEvent } from '../alerts-list.model';

@Component({
  selector: 'wt-alert-log-item',
  templateUrl: './alert-log-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AlertLogItemComponent {
  @Input() alert: AlertEvent;
  @Output() triggerClose = new EventEmitter<void>();

  AlertType = InfrontSDK.AlertType;

  setIntraDayClass = (date?: Date) => {
    const params = {
      value: date,
      isTodayClassList: ['wt-alerts-list__item-todays-alert'],
      isNotTodayClassList: ['wt-alerts-list__item-faded'],
    };

    return timeOrDateOutdated(params);
  };
}
