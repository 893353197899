import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InfrontUtil } from '@infront/sdk';
import { Observable } from 'rxjs';

import { Instrument } from '../../../state-model/window.model';
import { SymbolInfo } from './instrument-dock-item.model';
import { InstrumentDockItemService } from './instrument-dock-item.service';

@Component({
  selector: 'wt-instrument-dock-item',
  templateUrl: './instrument-dock-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstrumentDockItemComponent implements OnInit {
  @Output() tickerClicked = new EventEmitter<Instrument>();
  @Input() instrument: Instrument;

  symbolInfo$: Observable<SymbolInfo>;
  uuid = InfrontUtil.makeUUID();

  constructor(private itemService: InstrumentDockItemService) { }

  ngOnInit(): void {
    this.symbolInfo$ = this.itemService.symbolInfo$(this.instrument, this.uuid);
  }

  onTickerClicked(symbolInfo: SymbolInfo): void {
    this.tickerClicked.emit({ feed: symbolInfo.feed, ticker: symbolInfo.ticker });
  }

  pctChangeDecimals(pctChange: number): number {
    // rules defined here: IWT-1405
    if (Math.abs(pctChange) >= 1000) {
      return 0;
    }
    return Math.abs(pctChange) >= 100 ? 1 : 2;
  }
}
