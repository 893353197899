import { Column } from '../shared/grid/columns.model';
import { deepFreeze } from '../util/object';
import { ExclusivePick } from '../util/types';
import {
  ExposureTopCurrenciesColumns,
  ExposureTopHoldingsColumns,
  ExposureTopRegionsColumns,
  ExposureTopSectorColumns,
} from '../widgets/exposure/exposure.columns';
import {
  HistoryDividendsColumns,
  HistorySplitsColumns,
  HistoryTradesColumns,
  SelectedHistoryDividendsColumns,
  SelectedHistorySplitsColumns,
  SelectedHistoryTradesColumns,
} from '../widgets/history/history.columns';
import { ListsCategories, ListsColumns, SelectedListColumns } from '../widgets/lists/lists.columns';
import { MarketOverviewColumns } from '../widgets/market-overview/market-overview.columns';
import { PortfolioTradesColumns } from '../widgets/portfolio-trades/portfolio-trades.columns';
import { SelectedTradesColumns, TradesColumns } from '../widgets/trades/trades.columns';
import { SelectedUserListColumns } from '../widgets/userlist/userlist.columns';
import { SelectedWatchlistColumns, WatchlistCategories, WatchlistColumns } from '../widgets/watchlist/watchlist.model';
import { ConstituentsColumns, ConstituentsColumnsWide } from './../widgets/constituents/constituents.columns';
import { portfolioOrdersGrid, portfolioOrdersGridColumnsMap } from './grids/portfolio-orders-grid.model';
import { portfolioPositionsGrid, portfolioPositionsGridColumnsMap } from './grids/portfolio-positions-grid.model';
import { sidebarGridColumnsMap } from './grids/sidebar-grid.model';

export const GridLineHeight = 31; // FIXME @Dan what number should we use here?

export const isSelectedColumnsSetting = (settings: Record<string, unknown>): settings is { selectedColumns: Column[] } => {
  return (settings as { selectedColumns: Column[] }).selectedColumns != undefined;
};

export type GridColumnSize = 'sizeToFit' | 'autoSize';
export type ColumnSetting = ExclusivePick<Column, 'colId' | 'width' | 'sort'>;
export interface Grid {
  dashboardId?: string;
  id: string;
  parentId?: string;
  name: keyof typeof GridColumnsMap;
  settings?: {
    selectedColumns?: Column[];
  };
}

/** Helper type used for updating grids via the StoreService */
export type PartialGrid = Omit<Partial<Grid>, 'id' | 'dashboardId'>;

// helper method to set type restrictions on defaultSelected and categories
// to only allow subsets of columns supplied in "all" and only the column settings columns ('colId' | 'width' | 'sort').
const asGridColumnMap = <T extends Record<keyof T, string>>(
  gcMap: {
    [K in keyof T]: {
      all: (Column & { colId: T[K] })[];
      defaultSelected?: (ColumnSetting & { colId: T[K] & unknown })[];
      categories?: { name: string; columns: (Column & { colId: T[K] & unknown })[] }[];
    };
  }
) => deepFreeze(gcMap);

// GridColumnsMap is the hub of all grids that wants user changes persisted in backend
// they need to register here

// key - use same name as widget if only one grid in the widget
// all - all columns available for the grid, include category as property if they should be categorized and available in the date picker
// defaultSelected - all Columns preselected as default for the grid

export const GridColumnsMap = asGridColumnMap({
  lists: { all: ListsColumns, defaultSelected: SelectedListColumns, categories: ListsCategories },
  trades: { all: TradesColumns, defaultSelected: SelectedTradesColumns },
  historyTrades: { all: HistoryTradesColumns, defaultSelected: SelectedHistoryTradesColumns },
  historyDividends: { all: HistoryDividendsColumns, defaultSelected: SelectedHistoryDividendsColumns },
  historySplits: { all: HistorySplitsColumns, defaultSelected: SelectedHistorySplitsColumns },
  topHoldings: { all: ExposureTopHoldingsColumns },
  topRegions: { all: ExposureTopRegionsColumns },
  topSectors: { all: ExposureTopSectorColumns },
  topCurrencies: { all: ExposureTopCurrenciesColumns },
  constituents: { all: ConstituentsColumns },
  constituentsWide: { all: ConstituentsColumnsWide },
  watchlist: { all: WatchlistColumns, defaultSelected: SelectedWatchlistColumns, categories: WatchlistCategories },
  marketOverview: { all: MarketOverviewColumns },
  ...portfolioPositionsGridColumnsMap,
  ...portfolioOrdersGridColumnsMap,
  portfolioTrades: { all: PortfolioTradesColumns },
  ...sidebarGridColumnsMap,
  userlist: { all: ListsColumns, defaultSelected: SelectedUserListColumns, categories: ListsCategories },
  chains: { all: ListsColumns, defaultSelected: SelectedUserListColumns, categories: ListsCategories },
});

export const WidgetGridsMap = deepFreeze({
  // all widgets with one to many relations to grids need to be declared here, one to one widgets to grid should not be declared here
  exposure: ['topHoldings', 'topRegions', 'topSectors', 'topCurrencies'],
  history: ['historyTrades', 'historyDividends', 'historySplits'],
  constituents: ['constituents', 'constituentsWide'],
  portfolioPositionsGrid,
  portfolioOrdersGrid,
} as const);

export type MultiGridWidgetName = keyof typeof WidgetGridsMap;

export type SubGrid = typeof WidgetGridsMap[MultiGridWidgetName][number];
