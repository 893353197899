<div class="wt-dialog wt-support-dialog" method="dialog" *ngIf="(branding$ | async) as branding">
  <div class="wt-flex-row wt-flex-align-middle wt-flex-collapse wt-dialog__title">
    <div class="wt-flex-column">
      <h1 class="wt-h1">{{ branding.supportPageTitle || ('SUPPORT_DIALOG.TITLE' | translate) }}</h1>
    </div>
    <div class="wt-flex-column wt-flex-shrink wt-flex-collapse wt-flex-align-right">
      <span class="wt-dialog__close-icon" title="{{ 'SUPPORT_DIALOG.CLOSE_BUTTON' | translate }}">
        <mat-icon class="mat-icon" fontIcon="close" [mat-dialog-close]="false"></mat-icon>
      </span>
    </div>
  </div>
  <div class="wt-dialog__message">
    <p>
      {{ 'SUPPORT_DIALOG.EMAIL' | translate }}: {{ branding.email }}
    </p>
    <p>
      {{ 'SUPPORT_DIALOG.PHONE' | translate }}: {{ branding.phone }}
    </p>
    <p>
      {{ 'SUPPORT_DIALOG.WEBSITE' | translate }}:
      <a *ngIf="branding.website"
        [href]="makeHref(branding.website)"
        target="_blank"
        rel="referrer noopener">{{ branding.website }}</a>
    </p>

    <h3>{{ 'SUPPORT_DIALOG.ENCOUNTERING_ISSUES.TITLE' | translate }}</h3>
    <p>
      {{ 'SUPPORT_DIALOG.ENCOUNTERING_ISSUES.LINE1' | translate }}
    </p>
    <p>
      {{ 'SUPPORT_DIALOG.ENCOUNTERING_ISSUES.LINE2' | translate }}
    </p>
  </div>
</div>