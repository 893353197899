import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Infront, InfrontSDK } from '@infront/sdk';
import { Observable } from 'rxjs';

import { DashboardService } from '../../../dashboard/dashboard.service';
import { CompactSearchContainerService } from '../../../search/compact-search/compact-search-container/compact-search-container.service';
import { StoreService } from '../../../services/store.service';
import { Dashboard } from '../../../state-model/dashboard.model';
import { Widget } from '../../../state-model/widget.model';
import {
  ChartMiniWindow,
  ChartWindow,
  FocusMiniWindow,
  FocusWindow,
  InstrumentWindow,
  OrderbookWindow,
  isInstrumentSettings,
} from '../../../state-model/window.model';
import { InstrumentHeaderService } from './instrument-header.service';

@Component({
  selector: 'wt-instrument-header',
  templateUrl: './instrument-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstrumentHeaderComponent implements OnInit {
  @Input() dashboard: Dashboard;
  @Input() window: InstrumentWindow | ChartWindow | ChartMiniWindow | OrderbookWindow | FocusWindow | FocusMiniWindow;
  @Input() widget: Widget;
  @Input() classification: InfrontSDK.SymbolClassification;

  searchVisible = false;

  symbolInfo$: Observable<{
    feedExchange: string;
    preDisplayTicker: string;
    feedIsHidden: boolean;
  }>;

  constructor(
    private readonly instrumentHeaderService: InstrumentHeaderService,
    private readonly compactSearchContainerService: CompactSearchContainerService,
    private readonly storeService: StoreService,
    private readonly dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
    this.symbolInfo$ = this.instrumentHeaderService.symbolInfo$(this.widget);
  }

  onSearchClick(): void {
    this.compactSearchContainerService.onSearchClick(this.window);
  }

  onSelectedOption(id: string, instrument: Infront.Instrument): void {
    this.storeService.updateWindow(this.window, {
      settings: { ...this.window.settings, ...{ instrument: { feed: instrument.feed, ticker: instrument.ticker } } },
    });
  }

  onSpawnMarketWindow(event: MouseEvent): void {
    event.stopPropagation();
    const feed = isInstrumentSettings(this.window.settings) ? this.window.settings.instrument.feed : undefined;
    if (feed) {
      // todo: check if we should allow market windows without selected feeds
      this.dashboardService.addWindow('MarketWindow', { feed });
    }
  }
}
