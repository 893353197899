import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';

@Component({
  selector: 'wt-circle-score',
  templateUrl: './circle-score.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleScoreComponent {

  private _score = 0;
  @Input() set score(value: number) {
    this._score = value;
    this.color = this.getColor(value);
  }
  get score(): number {
    return this._score;
  }

  @Input() classification: InfrontSDK.SymbolClassification;

  esgClassifications = [InfrontSDK.SymbolClassification.Fund, InfrontSDK.SymbolClassification.Stock, InfrontSDK.SymbolClassification.ETF, InfrontSDK.SymbolClassification.Bond];

  color = '';

  constructor() { }

  private colorLimitClassMap = [
    { limit: 70, color: 'green' },
    { limit: 50, color: 'orange' },
    { limit: 30, color: 'yellow' },
    { limit: 0, color: 'red' }
  ] as const;

  private getColor(val: number | undefined): string {
    const colorLimit = this.colorLimitClassMap.find(({ limit }) => !!val && val >= limit);
    return colorLimit?.color ?? '';
  }
}
