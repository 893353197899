<ng-container *ngIf="localeId$ | async"></ng-container>
<div class="wt-instrument-dock">
  <div class="wt-flex-row wt-flex-align-middle wt-instrument-dock__list">
    <ng-container *ngFor="let item of itemList; let i = index">
      <section class="wt-flex-column wt-flex-shrink wt-instrument-dock__list-item" *ngIf="item && item.ticker">
        <wt-instrument-dock-item *ngIf="(reloadAction | async) === false"
          [instrument]="item"
          (tickerClicked)="onTickerClicked($event)"></wt-instrument-dock-item>
      </section>
    </ng-container>

    <div class="wt-flex-column wt-instrument-dock-add-item" (click)="showConfig = !showConfig" cdkOverlayOrigin
      #arrayConfig="cdkOverlayOrigin">
      <button type="button" class="wt-button wt-instrument-dock-empty" *ngIf="!itemList?.length">
        {{ "INSTRUMENT_DOCK_COMPONENT.ADD_INSTRUMENT" | translate }}
      </button>

      <mat-icon class="wt-instrument-dock-settings" *ngIf="itemList?.length" fontIcon="settings"></mat-icon>
    </div>
  </div>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="arrayConfig"
    [cdkConnectedOverlayOpen]="showConfig"
    [cdkConnectedOverlayPositions]="overlayPositions"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    (backdropClick)="closeDropdown()">
    <div class="wt-instrument-dock-overlay">
      <div class="wt-instrument-dock__search"
        *ngFor="let item of [].constructor(maxItemCount); let i = index">
        <wt-search-compact
          class="wt-instrument-dock__search-compact-inline"
          [ngClass]="{ 'wt-instrument-dock__search-compact-inline--active': !!(itemList[i] && itemList[i]!.ticker) }"
          [searchConfig]="{
            feedScoreFactorItems: DefaultFeedScoreFactorItems,
            searchType: { symbol: true },
            history: false
          }"
          [placeholder]="('INSTRUMENT_DOCK_COMPONENT.SEARCH_INSTRUMENT' | translate)"
          [panelWidth]="220"
          [selectedInstrument]="itemList[i]?.ticker ? itemList[i]!.ticker : ''"
          [icon]="itemList[i]?.ticker ? 'delete' : undefined"
          [showFlag]="false"
          [clearInputOnClose]="false"
          [focusOnShowEnabled]="false"
          [closeDropdownOnEmpty]="true"
          (itemClicked)="onAddItem($event, i)"
          (iconClicked)="onDeleteItem(i)"></wt-search-compact>
      </div>
    </div>
  </ng-template>
</div>