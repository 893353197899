import { ChangeDetectionStrategy, Component } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { ICellRendererParams } from 'ag-grid-community';

import { getCountryForSymbol, getCountryName } from '../../../util/country';
import { TableSymbolData, isSymbolData } from '../../../util/symbol';

@Component({
  selector: 'wt-positions-exposure-bar-cell',
  template: `
  <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
    <div class="wt-flex-column wt-flex-shrink" *ngIf="country">
      <wt-country-flag
        *ngIf="!noFlag"
        [ngClass]="'wt-distribution-bar-cell__flag' + (!!ticker || !!pct ? '' : '--center')"
        [country]="country"
        [size]="16"
        [title]="countryName"></wt-country-flag>
    </div>

    <div class="wt-flex-column" *ngIf="pct != undefined">
      <div *ngIf="pct != undefined"
        class="wt-distribution-bar-cell__bar"
        [ngClass]="{ 'wt-distribution-bar-cell__symbol-link': !!hasSymbol }"
        title="{{ title }}" [style.width.%]="pct"
      >
        <span class="wt-distribution-bar-cell__bar-label">{{ name }}</span>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PositionsExposureBarCellComponent {
  name: string;
  title: string;
  pct: number;
  hasSymbol: boolean;
  ticker: string | undefined;
  noFlag: boolean;
  country: string | number;
  countryName: string;

  agInit(params: ICellRendererParams): void {
    this.name = (params.data.name as string | undefined)
      ?? (params.data.symbol as InfrontSDK.SymbolData)?.get(InfrontSDK.SymbolField.PreDisplayFullName);
    this.pct = params.data.percentage as number;
    this.hasSymbol = params.data.symbol != undefined;
    this.noFlag = !!(
      (params.data?.symbol as TableSymbolData)?.noFlag // first priority
      ?? (params.data as TableSymbolData)?.noFlag // second priority
    );

    if (params.context !== 'sectors' && params.context !== 'assetTypes') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (params?.value && typeof params.value === 'string' && params.value.toUpperCase() === 'CASH') {
        this.country = 'CASH';
        this.countryName = 'Cash';
        return;
      }
    }

    if (params.context === 'holdings') {
      this.title = (params.data.symbol as InfrontSDK.SymbolData)?.get(InfrontSDK.SymbolField.PreDisplayFullName);
    } else {
      type Items = { symbolId: { ticker: string } }[];
      const tickerList = (params.data.items as Items)?.map((item) => item.symbolId?.ticker);
      const tickerListString = tickerList.filter((item: string) => item != undefined && item != '').join(', ');
      this.title = tickerListString;
    }

    // NOTE: this logic may need some adjustment in the future!
    if (isSymbolData(params.data?.symbol as InfrontSDK.SymbolData)) {
      // if params.data.symbol is valid SymbolData, it has priority for getting the country!
      // use case: fund has symbol data in params.data, but we need the country for the fund-company
      // so we add the company's instruments symbolData as params.data.symbol, too.
      this.setData(params.data.symbol as InfrontSDK.SymbolData, params.data?.symbol.translation as string);
    } else if (isSymbolData(params.data as InfrontSDK.SymbolData)) {
      // try params.data as SymbolData
      this.setData(params.data as InfrontSDK.SymbolData, params.data?.translation as string);
    } else {
      this.country = (params.data.country as string | number) ?? '';
    }

    this.countryName = getCountryName(this.country as string);
  }

  setData(data: InfrontSDK.SymbolData, _translation?: string): void {
    this.country = getCountryForSymbol(data) ?? '';
  }
}
