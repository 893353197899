<form class="wt-admin-dialog__form" method="dialog" [formGroup]="form" (change)="submit()">
  <h3>{{ 'ADMIN_DIALOG.BRANDING.SUPPORT_PAGE' | translate }}</h3>

  <div class="wt-admin-dialog__details-tab-content">
    <div class="wt-flex-row">
      <div class="wt-flex-column wt-flex-small-2 wt-text-right">
        <label [for]="supportMenuTitleCtl.id">{{ 'ADMIN_DIALOG.BRANDING.SUPPORT_MENU_TITLE' | translate }}</label>
      </div>
      <div class="wt-flex-column wt-flex-shrink">
        <input #supportMenuTitleCtl
          class="wt-dialog-input"
          type="text"
          [maxLength]="MAX_MENU_ITEM_LENGTH"
          matInput
          formControlName="supportMenuTitle"
          name="supportMenuTitle" />
        <mat-error *ngIf="supportMenuTitle.errors?.maxLength">
          {{ 'ADMIN_DIALOG.BRANDING.VALIDATION_MESSAGES.SUPPORT_MENU_TITLE_MAX_LENGTH' | translate: { maxLength:
          supportMenuTitle.errors?.maxLength.maxLength } }}
        </mat-error>
      </div>
    </div>

    <div class="wt-flex-row">
      <div class="wt-flex-column wt-flex-small-2 wt-text-right">
        <label [for]="supportPageTitleCtl.id">{{ 'ADMIN_DIALOG.BRANDING.SUPPORT_PAGE_TITLE' | translate }}</label>
      </div>
      <div class="wt-flex-column wt-flex-shrink">
        <input #supportPageTitleCtl
          class="wt-dialog-input"
          type="text"
          [maxLength]="MAX_DIALOG_TITLE_LENGTH"
          matInput
          formControlName="supportPageTitle"
          name="supportPageTitle" />
        <mat-error *ngIf="supportPageTitle.errors?.maxLength">
          {{ 'ADMIN_DIALOG.BRANDING.VALIDATION_MESSAGES.SUPPORT_PAGE_TITLE_MAX_LENGTH' | translate:{ maxLength:
          supportPageTitle.errors?.maxLength.maxLength } }}
        </mat-error>
      </div>
    </div>

    <div class="wt-flex-row">
      <div class="wt-flex-column wt-flex-small-2 wt-text-right">
        <label [for]="emailCtl.id">{{ 'ADMIN_DIALOG.BRANDING.EMAIL' | translate }}</label>
      </div>
      <div class="wt-flex-column wt-flex-shrink">
        <input #emailCtl
          class="wt-dialog-input"
          type="email"
          [maxLength]="MAX_EMAIL_LENGTH"
          matInput
          formControlName="email"
          name="email" />
        <mat-error *ngIf="email.errors?.maxLength">
          {{ 'ADMIN_DIALOG.BRANDING.VALIDATION_MESSAGES.EMAIL_MAX_LENGTH' | translate:{ maxLength:
          email.errors?.maxLength.maxLength } }}
        </mat-error>
        <mat-error *ngIf="email.errors?.email">
          {{ 'ADMIN_DIALOG.BRANDING.VALIDATION_MESSAGES.EMAIL_PATTERN' | translate }}
        </mat-error>
      </div>
    </div>

    <div class="wt-flex-row">
      <div class="wt-flex-column wt-flex-small-2 wt-text-right">
        <label [for]="phoneCtl.id">{{ 'ADMIN_DIALOG.BRANDING.PHONE' | translate }}</label>
      </div>
      <div class="wt-flex-column wt-flex-shrink">
        <input #phoneCtl
          class="wt-dialog-input"
          type="tel"
          [maxLength]="MAX_PHONE_LENGTH"
          matInput
          formControlName="phone"
          name="phone" />
        <mat-error *ngIf="phone.errors?.maxLength">
          {{ 'ADMIN_DIALOG.BRANDING.VALIDATION_MESSAGES.PHONE_MAX_LENGTH' | translate:{ maxLength:
          phone.errors?.maxLength.maxLength } }}
        </mat-error>
      </div>
    </div>

    <div class="wt-flex-row">
      <div class="wt-flex-column wt-flex-small-2 wt-text-right">
        <label [for]="websiteCtl.id">{{ 'ADMIN_DIALOG.BRANDING.WEBSITE' | translate }}</label>
      </div>
      <div class="wt-flex-column wt-flex-shrink">
        <input #websiteCtl
          class="wt-dialog-input"
          type="url"
          [maxLength]="MAX_WEBSITE_LENGTH"
          matInput
          formControlName="website"
          name="website" />
        <mat-error *ngIf="website.errors?.maxLength">
          {{ 'ADMIN_DIALOG.BRANDING.VALIDATION_MESSAGES.WEBSITE_MAX_LENGTH' | translate:{ maxLength:
          website.errors?.maxLength.maxLength } }}
        </mat-error>
      </div>
    </div>

    <div class="wt-flex-row">
      <div class="wt-flex-column">
        <h3>{{ 'ADMIN_DIALOG.BRANDING.BROWSER_TAB_NAME' | translate }}</h3>
      </div>
    </div>

    <div class="wt-flex-row">
      <div class="wt-flex-column">
        <p>{{ 'ADMIN_DIALOG.BRANDING.BROWSER_TAB_NAME_INTRO' | translate }}</p>
      </div>
    </div>


    <div class="wt-flex-row">
      <div class="wt-flex-column wt-flex-small-2 wt-text-right">
        <label [for]="titlePrefixCtl.id">{{ 'ADMIN_DIALOG.BRANDING.TITLE_PREFIX' | translate }}</label>
      </div>
      <div class="wt-flex-column wt-flex-shrink">
        <input #titlePrefixCtl
          class="wt-dialog-input"
          type="text"
          [maxLength]="MAX_TITLE_PREFIX_LENGTH"
          matInput
          formControlName="titlePrefix"
          name="titlePrefix" />
        <mat-error *ngIf="titlePrefix.errors?.maxLength">
          {{ 'ADMIN_DIALOG.BRANDING.VALIDATION_MESSAGES.TITLE_PREFIX_MAX_LENGTH' | translate: { maxLength:
          website.errors?.titlePrefix.maxLength } }}
        </mat-error>
      </div>
    </div>

    <div class="wt-flex-row">
      <div class="wt-flex-column">
        <p>{{ 'ADMIN_DIALOG.BRANDING.TAB_NAME' | translate }}: {{ tabTitlePreview$ | async }}</p>
      </div>
    </div>

    <div class="wt-flex-row">
      <div class="wt-flex-column">
        <span class="wt-admin-dialog__tab-preview">
          <img class="wt-admin-dialog__tab-preview-icon" src="favicon.ico" alt="">
          <span class="wt-admin-dialog__tab-preview-label">{{ tabTitlePreview$ | async }}</span>
          <mat-icon class="wt-admin-dialog__tab-preview-close" fontIcon="close"></mat-icon>
        </span>
      </div>
    </div>
  </div>
</form>