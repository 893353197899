<ng-container *ngIf="watchlistSelectedSubTabs$ | async as wlSelectedSubTabs">
  <ng-container *ngIf="deleteDeprecatedWatchlistSelectedSubTabs$ | async"></ng-container> <!-- self subscription -->
  <div class="wt-full-height wt-truncate wt-watchlist-tab">
    <div class="wt-flex-row wt-flex-collapse">
      <div class="wt-flex-column">
        <div class="wt-flex-row wt-flex-align-center wt-flex-align-justify">
          <div class="wt-flex-column wt-flex-collapse">
            <h3 class="wt-h3"> <!-- @FIXME Dmitri, has no effect in terms of styling -->
              <wt-dropdown-watchlist
                [selectedWatchlistId]="(selectedWatchlistId$ | async) ?? undefined"
                (selectedWatchlistChange)="onWatchlistSelected($event)"></wt-dropdown-watchlist>
            </h3>
          </div>

          <div class="wt-flex-column wt-flex-shrink">
            <div class="wt-button__group">
              <ng-container *ngIf="{ id: selectedWatchlistId$ | async } as selectedWatchlistIdObj">
                <button *ngFor="let watchlistSubTabConfig of watchlistSubTabConfigList"
                  type="button"
                  class="wt-button wt-button__group-item"
                  [ngClass]="{ 'wt-button__group-item--selected': selectedWatchlistIdObj.id ? wlSelectedSubTabs[selectedWatchlistIdObj.id] === watchlistSubTabConfig.value : false }"
                  (click)="selectWatchlistSubTab(watchlistSubTabConfig.value)">
                  {{ watchlistSubTabConfig.translationKey | translate}}
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="watchlistSymbolData$ | async as watchlistSymbolData"
      class="wt-flex-row wt-flex-collapse wt-flex-dir-column wt-watchlist-tab__grids">
      <wt-symbols-grid
        *ngFor="let grid of watchlistGrids"
        class="wt-flex-column"
        [symbols]="watchlistSymbolData"
        [gridRef]="grid.gridRef"
        [gridSettings]="gridSettings"
        [context]="{ forceOpenInstrumentDashboard: true }"
        (cellClickedEvent)="onGridCellClick($event)"
        [hidden]="selectedWatchlistId ? wlSelectedSubTabs[selectedWatchlistId] !== grid.subTab : true">
      </wt-symbols-grid>
    </div>
  </div>
</ng-container>