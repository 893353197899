import { WidgetGridsMap } from '../../state-model/grid.model';

export const RepresentedOrderCategories = ['Active', 'Algo', 'StopLoss', 'Inactive', 'Deleted', 'ExecutedOrDeletedExecuted'] as const;
export const OrderCategories = [...RepresentedOrderCategories, 'Other', 'Unmapped'] as const;

export type OrderCategory = typeof OrderCategories[number];

export type PortfolioGridType = typeof WidgetGridsMap['portfolioOrdersGrid'][number];

// export const PortfolioPositionsTabs = ['Default', 'Performance'] as const;
