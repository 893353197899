export const replaceAll = (match: string, replaceWith: string, source: string): string => {
  let lastSource = '';
  while (lastSource != source) {
    lastSource = source;
    source = source.replace(match, replaceWith);
  }
  return source;
};

export const removeByStartAndEndChar = (source: string, replaceWith: string, startChar: string, endChar: string, startOffset: number = 1): string => {
  const indexOfStart = source.indexOf(startChar, 0);
  const indexOfEnd = source.indexOf(endChar, indexOfStart + 1);
  if (indexOfStart === -1 || indexOfEnd === -1 || startOffset > indexOfEnd + 1) {
    return source;
  }
  const match = source.substring(indexOfStart + startOffset, indexOfEnd + 1);
  source = replaceAll(match, replaceWith, source);
  return source;
};

export const getByStartAndEndChar = (source: string, startChar: string, endChar: string, startOffset: number = 1, endOffset: number = 0): string => {
  const indexOfStart = source.indexOf(startChar, 0);
  const indexOfEnd = source.indexOf(endChar, indexOfStart + 1);
  if (indexOfStart === -1 || indexOfEnd === -1 || startOffset > indexOfEnd + 1) {
    return '';
  }
  const match = source.substring(indexOfStart + startOffset, indexOfEnd + endOffset);
  return match;
};

export const removeFirstWord = (str: string): string => {
  const indexOfSpace = str.indexOf(' ');
  if (indexOfSpace === -1) {
    return str;
  }
  return str.substring(indexOfSpace + 1);
};

export const toLowerCaseFirstLetter = (word: string) => {
  if (!word?.length) {
    return word;
  }
  return word.charAt(0).toLowerCase() + word.slice(1);
};

export const isString = (value: unknown): boolean => typeof value === 'string' || value instanceof String;

export const replaceAt = (input: string, search: string, replace: string, start: number, end: number): string => {
  return input.slice(0, start) + input.slice(start, end).replace(search, replace) + input.slice(end);
};

/*
  * Removes the first occurrence of the search string and everything before it.
  * @param mainString The string to search in.
  * @param search The string to search for.
  * @returns The string after the first occurrence of the search string.
  */

export const removeBeforeAndIncluding = (mainString: string, search: string): string => {
  const index = mainString.indexOf(search);
  if (index !== -1) {
    return mainString.slice(index + search.length);
  }
  return mainString;
};

export function convertCamelCaseToConstantCase(inString: string): string {
  return inString.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase();
}


export function addCSV(value: string | number): string {
  return value ? `, ${value}` : '';
}

