import { InfrontSDK } from '@infront/sdk';

import { ListsCategories, ListsColumnDefs, SelectedListColumns, WatchListColumnDefs } from './../lists/lists.columns';

export interface WatchlistAction {
  watchlist: InfrontSDK.Watchlist;
  action: 'save' | 'delete';
}

export const isDummySDKWatchlist: (watchlists: InfrontSDK.Watchlist[] | undefined) => boolean = (watchlists) =>
  !!watchlists?.length && watchlists[0].title === 'My List' && watchlists[0].items.length === 0;

const watchlistTickerDef = { ...ListsColumnDefs.ticker, width: 94 };
const watchlistColumnDefs = { ...ListsColumnDefs, ...WatchListColumnDefs, watchlistTickerDef };

export const WatchlistColumns = Object.values(watchlistColumnDefs);

// todo: we might want to set category as property on colDef if overwriting categories becomes a common thing
const watchlistGeneralColumns = [watchlistTickerDef, ...ListsCategories[0].columns.slice(1)];
const watchlistGeneralCategory = {
  name: 'General',
  columns: watchlistGeneralColumns,
};
const watchListCategory = {
  name: 'Watchlist',
  columns: [WatchListColumnDefs.dateAdded],
};

export const WatchlistCategories = [watchlistGeneralCategory, ...ListsCategories.slice(1), watchListCategory];

export const SelectedWatchlistColumns = [{ colId: watchlistTickerDef.colId }, ...SelectedListColumns.slice(1)];

export type WatchlistByProvider = { [provider: number]: InfrontSDK.Watchlist[]; };
