import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, forwardRef, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject, Subject, debounceTime, takeUntil, tap } from 'rxjs';

// custom auto-complete designed to work ONLY with reactive forms
@Component({
  selector: 'wt-auto-complete',
  templateUrl: './auto-complete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteComponent),
      multi: true
    }
  ]
})
export class AutoCompleteComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  @ViewChild('matAutocompleteInput') matAutocompleteInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
  @Input() minKeystrokes = 3;
  @Input() mode: 'freeText' | 'autoComplete' = 'autoComplete';
  @Input() typeDelay = 0;
  @Input() placeholder = '';
  @Input() searchResultItems: string[] = [];
  @Input() options?: string[];
  @Input() value = '';
  @Input() disableClear = false;

  @Output() searchString = new EventEmitter<string>();
  @Output() itemSelected = new EventEmitter<string>();
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly ngUnsubscribe = new Subject<void>();
  private readonly inputAction = new Subject<string>();

  private readonly showClear = new BehaviorSubject<{ show: boolean }>({ show: false });
  showClear$ = this.showClear.asObservable();



  ngOnInit(): void {
    this.inputAction.asObservable().pipe(debounceTime(this.typeDelay), tap((inputValue: string) => {
      if (this.mode === 'freeText') {
        this.setValue();
      }
      if (this.mode === 'autoComplete') {
        this.searchString.emit(inputValue);
      }
    }), takeUntil(this.ngUnsubscribe)).subscribe();
  }

  onClear() {
    this.showClear.next({ show: false });
    this.clearValues();
  }

  ngAfterViewInit(): void {
    // set initial value after matAutocompleteInput is available)
    if (this.value && this.matAutocompleteInput?.nativeElement) {
      this.showClear.next({ show: !!this.value.length });
      this.matAutocompleteInput.nativeElement.value = this.value;
    }
  }

  ngOnDestroy(): void {
    this.inputAction.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onItemSelected(item: string) {
    this.value = item;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.onChange(this.value);
    this.itemSelected.emit(item);
  }



  onInput(event: Event) {
    const inputValue = this.matAutocompleteInput.nativeElement.value;
    if (inputValue.length === 0) {
      setTimeout(() => {
        this.clearValues();
        if (this.mode === 'autoComplete') {
          this.autocompleteTrigger.closePanel();
        }
      }, 0); // trigger angular change detection, otherwise an annoying delay when deleting the last char
    }
    this.showClear.next({ show: !!inputValue.length });
    if (inputValue.length >= this.minKeystrokes) {
      this.inputAction.next(inputValue);
    }
  }

  onKeydown(event: KeyboardEvent) {
    if (['Enter', 'Tab'].includes(event.key)) {
      event.preventDefault();
      event.stopPropagation();
      if (this.mode === 'freeText') {
        this.setValue();
      }
      this.matAutocompleteInput.nativeElement.blur();
      return;
    }
  }

  setValue() {
    // if freetext mode we accept any text string
    if (this.mode === 'freeText') {
      this.value = this.matAutocompleteInput.nativeElement.value;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.onChange(this.value);
      return;
    }
    // if not freetext mode we only accept values from the dropdown, if we have any and if min char is met
    if (this.searchResultItems?.length && this.matAutocompleteInput.nativeElement.value?.length >= this.minKeystrokes) {
      this.onItemSelected(this.searchResultItems[0]);
      this.value = this.searchResultItems[0];
      this.writeValue(this.value);
      this.autocompleteTrigger.closePanel();
      return;
    }
    // otherwise we clear the value
    this.clearValues();
    this.autocompleteTrigger.closePanel();
  }

  clearValues() {
    this.value = '';
    this.writeValue('');
    if (this.mode === 'autoComplete') {
      this.searchResultItems = [];
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.onChange('');
  }

  // ControlValueAccessor methods - needed for the control to work with autoupdating Angular rective forms

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: any = () => { };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onTouch: any = () => { };

  writeValue(value: string): void {
    this.value = value;
    if (this.matAutocompleteInput?.nativeElement && value !== this.matAutocompleteInput.nativeElement.value) {
      this.matAutocompleteInput.nativeElement.value = value;
    }
  }

  registerOnChange(fn: unknown): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: unknown): void {
    this.onTouch = fn;
  }
}
