import { NEVER, Observable, UnaryFunction, combineLatest, filter, map, of, switchMap, take } from 'rxjs';
import { isArray } from '../internal/utils/objects';
import { DashboardFolderRef } from '../state-refs/dashboard-ref';

export const untilChildrenLoaded = <T extends DashboardFolderRef | ReadonlyArray<DashboardFolderRef>>(): UnaryFunction<Observable<T | undefined>, Observable<T>> =>
  switchMap(mapUntilLoaded);

function mapUntilLoaded<T extends DashboardFolderRef | ReadonlyArray<DashboardFolderRef>>(ref: T | undefined): Observable<T> {
  if (!ref) {
    return NEVER;
  } else if (isArray(ref)) {
    if (!ref.length) {
      return of(ref);
    }
    const obs = combineLatest(ref.map(mapUntilLoaded));
    return obs as unknown as Observable<T>;
  } else if (ref.childrenLoadState === 'loading') {
    return ref.childrenLoadState$.pipe(filter(state => state !== 'loading'), map(() => ref), take(1));
  } else if (ref.childrenLoadState === 'on-demand') {
    ref.loadChildren();
    return ref.childrenLoadState$.pipe(filter(state => state !== 'loading' && state !== 'on-demand'), map(() => ref), take(1));
  }
  // leave loaded & error as they are
  return of(ref);
}
