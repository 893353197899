<div class="wt-dialog wt-column-picker-dialog">
  <div class="wt-flex-row wt-flex-align-middle wt-flex-collapse wt-dialog__title">
    <div class="wt-flex-column wt-flex-shrink wt-dialog-icon wt-column-picker-dialog__icon">
      <mat-icon svgIcon="view_column"></mat-icon>
    </div>

    <div class="wt-flex-column">
      <h1 class="wt-h1">Column Picker</h1>
    </div>
  </div>

  <div mat-dialog-content>
    <wt-column-picker
      [selectedColumns]="data.selectedColumns"
      [columnCategories]="data.columnCategories"
      (columnsSelected)="onColumnsSelected($event)"></wt-column-picker>
  </div>

  <div class="wt-action-buttons">
    <div class="wt-flex-row wt-flex-collapse wt-flex-align-right">
      <div class="wt-flex-column wt-flex-shrink">
        <button class="wt-button wt-button--cancel" type="button" mat-dialog-close>Cancel</button>
      </div>

      <div class="wt-flex-column wt-flex-shrink">
        <button class="wt-button wt-button--primary" type="button" [mat-dialog-close]="result"
          cdkFocusInitial>OK</button>
      </div>
    </div>
  </div>
</div>