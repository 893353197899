import { InfrontUtil } from '@infront/sdk';

export const isSameObject = <T>(firstObject: T, secondObject: T): boolean => JSON.stringify(firstObject) === JSON.stringify(secondObject);

export const callIfFunction = <T, R>(value: T, args: unknown[]): T | R =>
  typeof value === 'function' ? (value as (...args: unknown[]) => R)(...args) : value;

export const getObjectArrayMaxByKey = (items: Record<string, unknown>[], key: string): number =>
  Math.max(...items.map((i) => i[key] as number));

export const getObjectArraySumByKey = (items: Record<string, unknown>[], key: string): number => items.reduce((a, b) => +a + +(b[key] as string), 0);

export const getArraySum = (items: number[]): number => items.reduce((a, b) => +a + +b, 0);

export const newUniqueName = (defaultName: string, nameList: string[] = []): string => {
  let ordinalString = '';
  let i = 0;
  while (nameList.includes(`${defaultName} ${ordinalString}`.trim())) {
    i++;
    ordinalString = i.toString();
  }
  return `${defaultName} ${ordinalString}`.trim();
};

export const stringArrayToObject = (arr: string[]): { [key: string]: string } =>
  arr.reduce((acc, cur, i) => {
    acc[i] = cur;
    return acc;
  }, {});

export const newId = (): string => {
  return InfrontUtil.makeUUID();
};

/**
 * A debounce that merges sequential, partial updates into a partial object.
 *
 * @param debounceCallback The callback to be executed after the debounce timeout
 * @param debounceTime The debounce timeout in milliseconds
 * @param deepCopyDebounceUpdates Whether or not the debounce update partial should also be deep copied when merging.
 * @returns The debounce function which accepts an to be merged update partial.
 * Calling it will set the timeout, while calling it again while within the debounceTime will reset the timeout and merge the partials.
 * At the end debounceCallback will be executed with the merge partial as a parameter.
 */
export const debounceMerge = <SourceObject extends object = object>(
  debounceCallback: (mergedObj: Partial<SourceObject>) => void,
  debounceTime: number,
  deepCopyDebounceUpdates = true,
): ((partialObj: Partial<SourceObject>) => void) => {
  let timeout: ReturnType<typeof setTimeout> | undefined;
  let mergedDebounceUpdatePartial: Partial<SourceObject> = {}; // Keeps track of all debounceUpdatePartial(s) by merging them

  return (debounceUpdatePartial: Partial<SourceObject>) => {
    if (timeout) {
      clearTimeout(timeout); // Another update call, reset timeout
    }

    mergedDebounceUpdatePartial = InfrontUtil.mergeRecursive(mergedDebounceUpdatePartial, debounceUpdatePartial, deepCopyDebounceUpdates) as Partial<SourceObject>;

    timeout = setTimeout(() => {
      timeout = undefined;
      debounceCallback(mergedDebounceUpdatePartial);
      mergedDebounceUpdatePartial = {};
    }, debounceTime);
  };
};

/**
 * debounceMerge superset
 *
 * A debounce that merges sequential, partial updates into an object.
 * In addition to debounceMerge it will merge the partial a source object at the end of the timeout.
 *
 * @param sourceObjRef The reference of the source object
 * @param debounceCallback The callback to be executed after the debounce timeout
 * @param debounceTime The debounce timeout in milliseconds
 * @param deepCopyDebounceUpdates Whether or not the debounce update partial should also be deep copied when merging.
 * @param deepCopySourceMerge  Whether or not the source should be deep copied before merging with the merged partial.
 * @returns The debounce function which accepts an to be merged update partial.
 * Calling it will set the timeout, while calling it again while within the debounceTime will reset the timeout and merge the partials.
 * In contrast to debounceMerge the debounce callback will be provided a merge of the source object and the merged partial.
 */
export const debounceMergeWithSource = <SourceObject extends object = object>(
  sourceObjRef: SourceObject,
  debounceCallback: (mergedObj: SourceObject) => void,
  debounceTime: number,
  deepCopyDebounceUpdates = true,
  deepCopySourceMerge = true,
): ((partialObj: Partial<SourceObject>) => void) => {
  let timeout: ReturnType<typeof setTimeout> | undefined;
  let mergedDebounceUpdatePartial: Partial<SourceObject> = {}; // Keeps track of all debounceUpdatePartial(s) by merging them

  return (debounceUpdatePartial: Partial<SourceObject>) => {
    if (timeout) {
      clearTimeout(timeout); // Another update call, reset timeout
    }

    mergedDebounceUpdatePartial = InfrontUtil.mergeRecursive(mergedDebounceUpdatePartial, debounceUpdatePartial, deepCopyDebounceUpdates) as Partial<SourceObject>;

    timeout = setTimeout(() => {
      timeout = undefined;
      const finalMergeObj: SourceObject = (deepCopySourceMerge
        ? InfrontUtil.mergeRecursiveCopy(sourceObjRef, mergedDebounceUpdatePartial)
        : InfrontUtil.mergeRecursive(sourceObjRef, mergedDebounceUpdatePartial)) as SourceObject;
      debounceCallback(finalMergeObj);
      mergedDebounceUpdatePartial = {};
    }, debounceTime);
  };
};
