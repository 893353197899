import { Injectable } from '@angular/core';
import { DefaultHttpDashboardConfigurationProvider, HttpDashboardConfiguration, HttpDashboardConfigurationProvider } from '@infront/ngx-dashboards-fx';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DashboardRealmsService } from './dashboard-realms.service';
import { injectRealmDisplayNameFactory } from '../../shared/realms';

@Injectable()
export class WTHttpDashboardConfigurationProvider implements HttpDashboardConfigurationProvider {
  private readonly getRealmDisplayName = injectRealmDisplayNameFactory();

  constructor(
    private readonly defaultsProvider: DefaultHttpDashboardConfigurationProvider,
    private readonly dashboardRealmsService: DashboardRealmsService,
  ) {
  }

  load(): Observable<HttpDashboardConfiguration> {
    return combineLatest([this.defaultsProvider.load(), this.dashboardRealmsService.realms$]).pipe(
      map(([defaults, realms]) => ({
        ...defaults,
        availableFolders: ['GLOBAL', 'COMPANY', 'USER'],
        additionalRealms: realms.map((realmInfo) => realmInfo.realm),
        getDisplayName: (folder) => {
          if (folder.role === 'COMPANY') {
            return this.getRealmDisplayName(folder.realm);
          }
          return defaults.getDisplayName(folder);
        }
      })),
    );
  }

}
