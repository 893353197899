import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CompanyDataComponent } from './company-data.component';

@NgModule({
  declarations: [CompanyDataComponent],
  imports: [
    CommonModule,
  ],
  exports: [],
  providers: [],
})
export class CompanyDataModule { }
