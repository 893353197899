import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';

@Directive({
  selector: '[focusOnShow]',
})
export class FocusOnShowDirective implements AfterViewInit {
  @Input() focusOnShowEnabled = true;

  constructor(private el: ElementRef<HTMLInputElement>) {
    if (!el?.nativeElement?.['focus']) {
      throw new Error('Element does not accept focus.');
    }
  }

  ngAfterViewInit(): void {
    if (!this.focusOnShowEnabled) {
      return;
    }

    const input: HTMLInputElement = this.el?.nativeElement;

    setTimeout(() => {
      if (input) {
        input.focus();
        input.select();
      }
    }, 0);
  }
}
