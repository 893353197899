import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChartWrapperComponent } from './chart-wrapper.component';
import { HighchartsService } from './highcharts.service';


@NgModule({
  declarations: [ChartWrapperComponent],
  imports: [CommonModule],
  exports: [ChartWrapperComponent],
  providers: [HighchartsService],
})
export class ChartWrapperModule {}
