import { ChangeDetectionStrategy, Component, OnDestroy, inject } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, tap, throttleTime } from 'rxjs/operators';
import { PreferencesMap } from '@vwd/microfrontend-core';

import { AlertService } from '../../services/alert.service';
import { StoreService } from '../../services/store.service';
import { StorageService, UserSettingsStorageData } from '../../services/storage.service';
import { DashboardType } from '../../state-model/dashboard.model';
import { DashboardService } from './../../dashboard/dashboard.service';
import { alertListAnimation } from './alerts-list.animations';
import { AlertEvent } from './alerts-list.model';

const AlertTabs = ['alertLog', 'myAlerts'] as const;
type AlertTab = typeof AlertTabs[number];

@Component({
  selector: 'wt-alerts-list',
  templateUrl: './alerts-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [alertListAnimation],
})
export class AlertsListComponent implements OnDestroy {
  private readonly storageService: StorageService = inject(StorageService);
  private readonly alertService: AlertService = inject(AlertService);
  private readonly storeService: StoreService = inject(StoreService);
  private readonly dashboardService: DashboardService = inject(DashboardService);

  private readonly selectedTab = new BehaviorSubject<AlertTab>('alertLog');
  notificationsButtonReady = false;
  readonly selectedTab$ = this.selectedTab.asObservable();

  readonly myAlerts$ = this.alertService.myAlerts$;

  readonly alertLogMarkAsViewed = new BehaviorSubject<string[]>([]);
  readonly alertLog$ = this.alertService.alertLog$;

  readonly numberOfAlerts$ = combineLatest([this.alertLog$, this.alertLogMarkAsViewed]).pipe(
    map(([alertLog, alertLogMarkAsViewed]) => {
      const newAlerts = alertLog.filter((alert) => !alertLogMarkAsViewed?.includes(alert.key));
      if (!newAlerts?.length) {
        return undefined;
      }
      return newAlerts.length;
    }),
    tap(() => (this.notificationsButtonReady = true))
  );

  readonly showSpawn$ = combineLatest([this.storeService.selectedDashboard$, this.selectedTab$]).pipe(
    map(([selectedDashboard, selectedTab]) => {
      return selectedDashboard?.type !== DashboardType.instrument && selectedTab === 'alertLog'; // todo: doublecheck condition rule
    })
  );

  readonly showAlertsListAction = new BehaviorSubject<{ show: boolean }>({ show: false });

  readonly showAlertsList$ = this.showAlertsListAction.asObservable().pipe(throttleTime(100));

  readonly setSelectedTab = (tab: AlertTab) => this.selectedTab.next(tab);

  readonly notificationsIconClick = (show: boolean) => {
    if (!this.notificationsButtonReady) {
      return;
    }
    this.showAlertsListAction.next({ show });
  };

  private readonly userSettingsStorage = this.storageService.getUserSettingsStorage() as PreferencesMap<UserSettingsStorageData>;
  private readonly userSettingsWatchDisposable = this.userSettingsStorage.watch('alertLogMarkAsViewed', (alertLogMarkAsViewed) => {
    this.alertLogMarkAsViewed.next(alertLogMarkAsViewed);
  });

  ngOnDestroy(): void {
    this.selectedTab.complete();
    this.alertLogMarkAsViewed.complete();
    this.showAlertsListAction.complete();
    this.userSettingsWatchDisposable.dispose();
  }

  readonly spawn = () => {
    this.dashboardService.addWindow('AlertLogWindow');
    this.showAlertsListAction.next({ show: false });
  };

  readonly markAsSeen = (alertLog: AlertEvent[]) => this.alertService.markAsSeen(alertLog);
}
