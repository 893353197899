import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take, tap } from 'rxjs';
import { WatchlistService } from '../../../../services/watchlist.service';
@Component({
  selector: 'wt-new-watchlist-dialog',
  templateUrl: './new-watchlist-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NewWatchlistDialogComponent {
  providerReadAccess: { provider: number | undefined, providerName: string }[] = [
    { provider: undefined, providerName: 'User' },
  ];

  constructor(
    private watchlistService: WatchlistService,
    public dialogRef: MatDialogRef<NewWatchlistDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      input: { title: string, provider: number },
    },
  ) {
    this.fetchProviderAccess();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  fetchProviderAccess() {
    this.watchlistService.watchlistProviderAccess$.pipe(
      tap((data) => {
        const readAccess = Object.entries(data.write).map(([provider, data]) => ({ provider: parseInt(provider), providerName: data.name }));
        this.providerReadAccess = [...this.providerReadAccess, ...readAccess];
      }),
      take(1)
    ).subscribe();
  }
}
