import { Directive, OnDestroy, OnChanges, ElementRef, Renderer2, Input, SimpleChanges, inject } from '@angular/core';
import { OutdatedPriceService } from './outdated-price.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const OUTDATED_CLASS = 'wt-outdated-value';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[outdatedPriceTimestamp]'
})
export class OutdatedPriceDirective implements OnDestroy, OnChanges {
  private readonly element: ElementRef = inject(ElementRef);
  private readonly renderer: Renderer2 = inject(Renderer2);
  private readonly outdatedPriceService: OutdatedPriceService = inject(OutdatedPriceService);

  @Input() outdatedPriceTimestamp: Date | undefined;

  private currentStartDate: Date;
  private readonly ngUnsubscribe = new Subject<void>();

  constructor() {
    this.outdatedPriceService.dateLineExceeded()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.renderer.addClass(this.element.nativeElement, OUTDATED_CLASS);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.outdatedPriceTimestamp == undefined) {
      return;
    }

    if (changes.outdatedPriceTimestamp.currentValue) {
      if (typeof changes.outdatedPriceTimestamp.currentValue === 'object') {
        this.outdatedPriceTimestamp = changes.outdatedPriceTimestamp.currentValue as Date;
      } else {
        this.outdatedPriceTimestamp = new Date(changes.outdatedPriceTimestamp.currentValue as Date);
      }

      if (
        this.outdatedPriceTimestamp &&
        (typeof this.outdatedPriceTimestamp.getTime === 'function') &&
        !isNaN(this.outdatedPriceTimestamp.getTime())
      ) {
        this.currentStartDate = new Date();
        this.currentStartDate.setHours(0);
        this.currentStartDate.setMinutes(0);
        this.currentStartDate.setSeconds(0);

        if (this.outdatedPriceTimestamp.getTime() < this.currentStartDate.getTime()) {
          this.renderer.addClass(this.element.nativeElement, OUTDATED_CLASS);
        } else {
          this.renderer.removeClass(this.element.nativeElement, OUTDATED_CLASS);
        }
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
