import { PortfolioOrdersWidget } from '../../state-model/widget.model';
import { DashboardWindow, PortfolioOrdersWindow } from '../../state-model/window.model';
import { OrderCategory } from '../../widgets/portfolio-orders/portfolio-orders.model';
import { PORTFOLIO_DASHBOARD_ID } from '../providers/portfolio-dashboards';
import { readonlyWindowParams } from './templates.model';

const headerHeight = 1;

// col 1
const ordersWidth = 38;
const ordersHeight = 12;

export const portfolioOrdersBaseWindow = {
  linkChannel: 'Channel 1',
  name: 'PortfolioOrdersWindow',
  settings: {},
  dashboardId: PORTFOLIO_DASHBOARD_ID,
  selectedWidgetName: 'PortfolioOrders',
  cols: ordersWidth,
  rows: ordersHeight,
  x: 0,
  y: headerHeight,
  ...readonlyWindowParams,
} as DashboardWindow;

export const portfolioOrdersWindows: { [key in OrderCategory]: Partial<PortfolioOrdersWindow> } = {
  Active: {
    label: 'Active',
  },
  Algo: {
    label: 'Algo',
  },
  StopLoss: {
    label: 'Stop-Limit', // stop loss is wrongly named from backend, there is only stop limit and stop market as stop orders which can be for both buy and sell orders while stop loss as a term is only for sell
  },
  Inactive: {
    label: 'Inactive',
  },
  Deleted: {
    label: 'Deleted',
  },
  ExecutedOrDeletedExecuted: {
    label: 'Executed',
  },
  Other: {
    label: 'Other',
  },
  Unmapped: {
    label: 'Unmapped',
  },
};

export const portfolioOrdersWidgets: { [key in OrderCategory]: Partial<PortfolioOrdersWidget> } = {
  Active: {
    settings: { selectedGrid: 'portfolioOrdersActive' },
  },
  Algo: {
    settings: { selectedGrid: 'portfolioOrdersAlgo' },
  },
  StopLoss: {
    settings: { selectedGrid: 'portfolioOrdersStopLoss' },
  },
  Inactive: {
    settings: { selectedGrid: 'portfolioOrdersInactive' },
  },
  Deleted: {
    settings: { selectedGrid: 'portfolioOrdersDeleted' },
  },
  ExecutedOrDeletedExecuted: {
    settings: { selectedGrid: 'portfolioOrdersExecuted' },
  },
  Other: {
    settings: { selectedGrid: 'portfolioOrdersOther' },
  },
  Unmapped: {
    settings: { selectedGrid: 'portfolioOrdersUnmapped' },
  },
};

export const portfolioOrdersGrids: { [key in OrderCategory]: { name: string }[] } = {
  Active: [{ name: 'portfolioOrdersActive' }],
  Algo: [{ name: 'portfolioOrdersAlgo' }],
  StopLoss: [{ name: 'portfolioOrdersStopLoss' }],
  Inactive: [{ name: 'portfolioOrdersInactive' }],
  Deleted: [{ name: 'portfolioOrdersDeleted' }],
  ExecutedOrDeletedExecuted: [{ name: 'portfolioOrdersExecuted' }],
  Other: [{ name: 'portfolioOrdersOther' }],
  Unmapped: [{ name: 'portfolioOrdersUnmapped' }],
};
