<ng-container *ngIf="data$ | async as data">
  <div class="instrument-focus">
    <div class="instrument-focus__time" *ngIf="data.time != undefined">
      <div [outdatedPriceTimestamp]="data.time">{{ data.time | date: 'timeOrDate' }}</div>
    </div>

    <div class="instrument-focus__group">
      <div class="instrument-focus__last-traded" [outdatedPriceTimestamp]="data?.time">
        {{ $any(data.lastTradedAt) | price: { decimals: data.decimals } }}
      </div>

      <wt-performance-bar
        class="instrument-focus__performance-bar"
        [ohlc]="{
          open: data.open,
          high: data.high,
          low: data.low,
          close: data.close
        }"
        [time]="data.time"
        [hideOutdated]="false"
        [hideMarkerOnDisabled]="true"
        [forceDisabled]="data.hasIntradaySupport === false"
        [settings]="{ direction: 'Vertical', thickness: 7, markerThickness: 1 }">
      </wt-performance-bar>

      <div
        class="instrument-focus__change"
        [ngClass]="{ 'up-indicator': (data.change || 0) > 0, 'down-indicator': (data.change || 0) < 0, 'unchanged-indicator': !data.change }">
        <div>{{ $any(data.change) | priceChange: { decimals: data.decimals } }}</div>
        <div>{{ $any(data.changePercent) | pctChange }}</div>
      </div>
    </div>
  </div>
</ng-container>