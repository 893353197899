import { NgZone } from '@angular/core';
import { filter, identity, MonoTypeOperatorFunction, Observable, of, OperatorFunction, pipe, Subscribable, Subscriber, UnaryFunction } from 'rxjs';

export function mapToVoid<T>(): OperatorFunction<T, void> {
  return identity as UnaryFunction<Observable<T>, Observable<void>>;
}

export function fromSubscribable<T>(subscribable: Subscribable<T>): Observable<T> {
  return new Observable((subscriber: Subscriber<T>) => subscribable.subscribe(subscriber));
}

export function runInZone<T>(ngZone: NgZone): MonoTypeOperatorFunction<T> {
  return (observable: Observable<T>) =>
    new Observable<T>(subscriber =>
      observable.subscribe({
        next(value: T) {
          ngZone.run(() => subscriber.next(value));
        },
        error(err: any) {
          ngZone.run(() => subscriber.error(err));
        },
        complete() {
          ngZone.run(() => subscriber.complete());
        },
      })
    );
}

export const OBSERVABLE_FALSE = of(false);
export const OBSERVABLE_TRUE = of(true);

export const filterUndefined = <T>(): UnaryFunction<Observable<T | undefined>, Observable<T>> =>
  pipe(
    filter((value: T | undefined) => value != undefined),
  ) as unknown as UnaryFunction<Observable<T | undefined>, Observable<T>>;
