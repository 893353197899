<!-- @WIP implementation still work in progress, not all item types fully supported yet -->
<ng-container *ngIf="item">
  <ng-container [ngSwitch]="item.type">

    <div *ngSwitchCase="MatDropdownItemType.SELECTABLE"
      class="wt-mat-dropdown-item">
      <div class="wt-flex-row wt-flex-align-middle">
        <ng-container *ngIf="columns; else singleColumn">
          <div *ngFor="let col of columns"
            class="wt-flex-column wt-flex-small-{{ col.size }}">
            <div class="wt-truncate" [title]="item.translateItemLabel ? (item.itemLabel | translate) : item.itemLabel">
              {{ item.translateItemLabel ? (item.itemLabel | translate) : item.itemLabel }} <!-- DRY? -->
            </div>
          </div>
        </ng-container>

        <ng-template #singleColumn>
          <div class="wt-flex-column wt-flex-collapse">
            <mat-checkbox
              (ngModelChange)="onItemSelected(item)"
              [ngModel]="item.selected">
              {{ item.translateItemLabel ? (item.itemLabel | translate) : item.itemLabel }} <!-- DRY? -->
            </mat-checkbox>
          </div>
        </ng-template>
      </div>
    </div>

    <mat-expansion-panel *ngSwitchCase="MatDropdownItemType.FOLDER"
      [expanded]="$any(item).opened"
      (opened)="$any(item).opened = true"
      (closed)="$any(item).opened = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ item.translateItemLabel ? (item.itemLabel | translate) : item.itemLabel }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <wt-mat-dropdown-item *ngFor="let subItem of $any(item).subItems"
        [item]="subItem"
        [columns]="columns"
        (itemSelected)="itemSelected.emit($event)">
      </wt-mat-dropdown-item>
    </mat-expansion-panel>

    <mat-expansion-panel *ngSwitchCase="MatDropdownItemType.SELECTABLE_FOLDER"
      [expanded]="$any(item).opened"
      (opened)="$any(item).opened = true"
      (closed)="$any(item).opened = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-checkbox
            (ngModelChange)="onItemSelected(item)"
            [ngModel]="item.selected">
          </mat-checkbox>
          {{ item.translateItemLabel ? (item.itemLabel | translate) : item.itemLabel }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <wt-mat-dropdown-item *ngFor="let subItem of $any(item).subItems"
        [item]="subItem"
        [columns]="columns"
        (itemSelected)="itemSelected.emit($event)">
      </wt-mat-dropdown-item>
    </mat-expansion-panel>

  </ng-container>
</ng-container>