import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, HostListener, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { BehaviorSubject, merge, NEVER, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, scan, switchMap, tap, throttleTime } from 'rxjs/operators';

import {
  FullSearchWindowResultItem,
  getDefaultSearchConfig,
  SearchResultItemType,
  SdkMarketSearchResultItem,
  SdkSearchResultItem,
  SdkSymbolSearchResultItem,
  SearchConfig,
  SearchDataSource,
} from '../search.model';
import { SdkSearchService } from '../services/sdk-search.service';
import {
  CompactSearchResultItemGroup,
  CompactSearchResultItemGroups,
  CompactSearchResultItemGroupsMap,
  DefaultSearchRequestDebounceTime,
} from './compact-search.model';

@Component({
  selector: 'wt-search-compact',
  templateUrl: './compact-search.component.html',
  //TODO: [IWT-455] Resolve animation in compact search component
  animations: [
    trigger('searchVisible', [
      state('true', style({ opacity: '1', background: 'red', top: '-12px', left: '1px', width: '172px' })),
      state('false', style({ opacity: '0', background: '#003d57', top: '-37px', left: '20px', width: '0px' })),
      transition('true <=> false', animate('130ms ease-out')),
    ]),
  ],
})
export class CompactSearchComponent implements OnInit, OnDestroy {
  private readonly sdkSearchService: SdkSearchService = inject(SdkSearchService);
  private readonly fb: UntypedFormBuilder = inject(UntypedFormBuilder);

  @Output() closed = new EventEmitter<void>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() itemClicked = new EventEmitter<any>();
  @Output() iconClicked = new EventEmitter<void>();
  @Output() visibilityChanged = new EventEmitter<{ visible: boolean }>();
  @Input() searchConfig: SearchConfig = getDefaultSearchConfig();
  @Input() visibilityChangeRequest$: Observable<{ visible: boolean } | undefined> = of(undefined);
  @Input() clearInputOnClose = true;
  @Input() searchQuery: string | undefined;
  @Input() showFlag: boolean;
  @Input() groupSymbols: boolean;
  @Input() showAnimations: boolean;
  @Input() displayWidth: ((value: unknown) => string) | null;
  @Input() panelWidth: string | number;
  @Input() placeholder: string;
  @Input() icon: string | undefined;
  @Input() focusOnShowEnabled = true;
  @Input() closeDropdownOnEmpty = false;

  @Input() set selectedInstrument(ticker: string) {
    this.searchQuery = ticker;
  }

  @ViewChild('searchElm') searchElm: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  @ViewChild('optionElm', { static: false }) optionElm: ElementRef;
  @ViewChild('expandElm', { static: false }) expandElm: ElementRef;

  @HostListener('document:click', ['$event']) onDocumentClick() {
    if (this.visibility?.visible) {
      this.closeDropdown();
    }
  }

  searchForm: UntypedFormGroup;
  readonly updateField = new Subject<string>();
  searchResultItemGroups = InfrontUtil.deepCopy(CompactSearchResultItemGroups) as CompactSearchResultItemGroup[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly searchResultsAction: BehaviorSubject<any[]> = new BehaviorSubject([]);
  searchResults$ = this.searchResultsAction.asObservable();
  skipSearch = false;

  visibility$: Observable<{ visible: boolean }>;
  private visibility: { visible: boolean };

  private userInput$ = (): Observable<CompactSearchResultItemGroup[] | (SdkSearchResultItem | FullSearchWindowResultItem)[]> => {
    return merge(this.searchForm.get('userInput')?.valueChanges ?? NEVER, this.updateField.asObservable()).pipe(
      debounceTime(DefaultSearchRequestDebounceTime),
      switchMap((value: string) => {
        if (this.skipSearch) {
          return of({ results: [], source: SearchDataSource.NONE });
        }
        this.searchResultItemGroups = InfrontUtil.deepCopy(CompactSearchResultItemGroups) as CompactSearchResultItemGroup[];
        return this.sdkSearchService.search$(value, this.searchConfig, this);
      }),
      map(({ results, source }) => (this.groupSymbols ? this.mapSearchResultItemsToGroups(results, this.searchResultItemGroups) : results)),
      delay(100),
      tap(() => {
        if (this.autocomplete?.panelOpen !== true) {
          this.autocomplete.openPanel();
        }
      })
    );
  };

  readonly SymbolClassification = InfrontSDK.SymbolClassification;
  readonly SearchResultItemType = SearchResultItemType;

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      userInput: this.searchQuery,
    });

    this.searchResults$ = this.userInput$().pipe(
      distinctUntilChanged(),
      tap((t) => this.searchResultsAction.next(t))
    );

    this.visibility$ = this.visibilityChangeRequest$.pipe(
      throttleTime(100),
      scan((visibility, visibilityInParam) => (visibilityInParam ? visibilityInParam : { visible: !visibility.visible }), { visible: false }),
      tap((visibility) => {
        this.visibility = visibility;
        if (!visibility.visible) {
          this.clearInput();
        }
        this.visibilityChanged.emit(visibility);
      })
    );
  }

  ngOnDestroy(): void {
    this.updateField.complete();
    this.searchResultsAction.complete();
  }

  private mapSearchResultItemsToGroups(searchResultItems: (SdkSearchResultItem | FullSearchWindowResultItem)[], searchResultItemGroups: CompactSearchResultItemGroup[]) {
    const duplicateResultsMap: {
      symbols: { [ticker: string]: SdkSymbolSearchResultItem };
      markets: { [feed: number]: SdkMarketSearchResultItem };
    } = {
      symbols: {},
      markets: {},
    };

    searchResultItems.forEach((searchResultItem) => {
      if ('Ticker' in searchResultItem) {
        if (duplicateResultsMap.symbols[searchResultItem.Ticker]) {
          return; // skip if already exists
        }
        searchResultItemGroups[CompactSearchResultItemGroupsMap[searchResultItem.SymbolClassification]]?.items.push(searchResultItem);
        duplicateResultsMap.symbols[searchResultItem.Ticker] = searchResultItem;
      } else if ('Feed' in searchResultItem) {
        if (duplicateResultsMap.markets[searchResultItem.Feed]) {
          return; // skip if already exists
        }
        searchResultItemGroups[CompactSearchResultItemGroupsMap['Markets']].items.push(searchResultItem);
        duplicateResultsMap.markets[searchResultItem.Feed] = searchResultItem;
      }
    });

    return searchResultItemGroups;
  }

  onEnter(): void {
    this.skipSearch = false;
    this.updateField.next((this.searchForm.get('userInput')?.value as string) ?? '');
  }

  private clearInput() {
    if (this.clearInputOnClose) {
      this.searchForm.patchValue({
        userInput: '',
      });
    } else {
      this.skipSearch = true;
    }

    this.searchElm?.nativeElement?.blur();
  }

  closeDropdown() {
    this.closed.next();
  }

  onSelectedOption(item: SdkSearchResultItem) {
    this.sdkSearchService.addItemToSearchHistory(item, this.searchConfig.history, this);
    this.itemClicked.next(item);
    this.closed.next();
  }

  onSelectedSingleOption(value: SdkSearchResultItem): void {
    this.onSelectedOption(value);
    this.clearInput();
  }

  onSelectionChange(item: SdkSearchResultItem) {
    this.itemClicked.next(item);
    this.sdkSearchService.addItemToSearchHistory(item, this.searchConfig.history, this);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expandOneAndCollapseRest(item: any, results: any[]) {
    results.forEach((res) => res !== item && (res.showMore = false));
    item.showMore = !item.showMore;
    item.collapse = !item.showMore;
    const restOfResults = results.filter((result) => !result.showMore);
    restOfResults.length === results.length
      ? restOfResults.forEach((result) => (result.collapse = false))
      : restOfResults.forEach((result) => (result.collapse = true));
  }
}
