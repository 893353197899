import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { InfrontSDK } from '@infront/sdk';

import {
  AlertType,
  getReccuranceOption,
  getTriggerOption,
  reccuranceOptions,
  triggerOptions,
  triggerOptionsForInstrument,
  triggerOptionsForWatchlist,
} from '../alert-dialog.model';

@Component({
  selector: 'wt-alert-form',
  templateUrl: './alert-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertFormComponent implements OnInit {
  @Input() alert?: InfrontSDK.ServerAlert;
  @Input() alertType: AlertType;

  @Output() cancelClick = new EventEmitter<void>();
  @Output() submitClick = new EventEmitter<UntypedFormGroup>();

  alertForm: UntypedFormGroup;
  triggerOptions: typeof triggerOptions[number][];
  reccuranceOptions: typeof reccuranceOptions[number][];

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {

    this.triggerOptions = this.alertType === 'instrument' ? triggerOptionsForInstrument : triggerOptionsForWatchlist;
    this.reccuranceOptions = this.alertType === 'instrument' ? reccuranceOptions : [reccuranceOptions[1]];

    const instrumentGroup = this.fb.group({
      alertMeIf: [getTriggerOption(this.alert?.rule?.mdValue?.mdField, this.alert?.rule?.operator) ?? triggerOptions[0]],
      recurrence: [getReccuranceOption(this.alert?.triggerType) ?? reccuranceOptions[0]],
      rangeStart: [this.alert?.rule?.left?.double ?? 0],
      rangeEnd: [this.alert?.rule?.right?.double ?? 0],
      limit: [this.alert?.rule?.double ?? 0],
      comment: [this.alert?.comment ?? ''],
    });

    const listGroup = this.fb.group({
      alertMeIf: [getTriggerOption(this.alert?.rule?.mdValue?.mdField, this.alert?.rule?.operator) ?? triggerOptions[6]],
      recurrence: [getReccuranceOption(this.alert?.triggerType) ?? reccuranceOptions[1]],
      limit: [this.alert?.rule?.double ?? 0],
      comment: [this.alert?.comment ?? ''],
    });

    this.alertForm = this.alertType === 'instrument' ? instrumentGroup : listGroup;
  }

  compareFn(opt1: typeof triggerOptions[number], opt2: typeof triggerOptions[number]): boolean {
    return opt1 && opt2 ? opt1.label === opt2.label : opt1 === opt2;
  }
}
