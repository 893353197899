<div class="wt-main-menu">

  <mat-icon
    aria-hidden="false"
    aria-label="Main menu"
    fontIcon="menu"
    #mainMenu="cdkOverlayOrigin"
    cdkOverlayOrigin
    (click)="showMainMenu = !showMainMenu"
    data-testid="main-menu-icon"></mat-icon>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="mainMenu"
    [cdkConnectedOverlayOpen]="showMainMenu"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    (backdropClick)="closeDropdown()">
    <div class="wt-main-menu-overlay" [ngClass]="{ 'wt-main-menu-overlay--hidden': !!hiddenMainMenu }">
      <div class="wt-main-menu__markets">
        <wt-market-overview-dropdown
          [type]="'Standalone'"
          [menuClass]="'wt-main-menu__market-overview-dropdown'"
          [menuBackdropClass]="'wt-main-menu__market-overview-dropdown__backdrop'"
          [useCheckboxes]="false"
          (sourceSelected)="onSourceSelected($event)"
          (menuOpened)="hiddenMainMenu = true"
          (menuClosed)="closeDropdown()">
          <div class="wt-main-menu__markets-group" data-testid="main-menu-MARKETS">
            <mat-icon class="wt-main-menu__markets-icon" fontIcon="language"></mat-icon>
            <span class="wt-main-menu__markets-label">{{ "MAIN_MENU_COMPONENT.MARKETS" | translate }}</span>
          </div>
        </wt-market-overview-dropdown>
        <div class="wt-main-menu__fund-screener">
          <div class="wt-main-menu__markets-group" (click)="openFundScreener()">
            <mat-icon class="wt-main-menu__fund-screener-icon" fontIcon="manage_search"></mat-icon>
            <span class="wt-main-menu__markets-label">{{ "MAIN_MENU_COMPONENT.FUND_SCREENER" | translate }}</span>
          </div>
        </div>
      </div>

      <div class="wt-main-menu__item-list-wrapper">
        <div class="wt-main-menu__item-list" *ngFor="let rowConfig of mainMenuConfig; let last = last">
          <div
            class="wt-main-menu__item wt-main-menu__item--clickable"
            *ngFor="let config of rowConfig"
            addDashboardWindow
            (dashboardWindowOnProceedCallback)="closeDropdown()"
            [dashboardWindowToAdd]="config.windowToAdd"
            [attr.data-testid]="config.dataTestId">
            {{ config.label | translate }}
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

