<div class="wt-search__compact-wrapper">
  <wt-search-compact
    (itemClicked)="onSelectedOption(window, $event)"
    (closed)="onClose()"
    (visibilityChanged)="onVisibilityChanged($event)"
    [searchQuery]="''"
    [showFlag]="false"
    [visibilityChangeRequest$]="visibilityChangeRequest$"
    [placeholder]="placeholder"
    [panelWidth]="220"
    [searchConfig]="searchConfig"></wt-search-compact>
</div>
