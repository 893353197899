import { ColumnRegistry } from '../../shared/grid/column-registry';
import { Column } from '../../shared/grid/columns.model';

export const ConstituentsColumns: Column[] = [
  { ...ColumnRegistry.ticker, sort: 'asc' },
  { ...ColumnRegistry.lastValid, width: 76 },
  { ...ColumnRegistry.listsPctChange, width: 76 },
  { ...ColumnRegistry.listsTurnover, width: 76 },
  { ...ColumnRegistry.time, width: 76 },
];

// IWT-1073 more columns for the wider widget used in INDEX instrument-dashboard
export const ConstituentsColumnsWide: Column[] = [
  { ...ColumnRegistry.ticker, sort: 'asc', width: 70 },
  { ...ColumnRegistry.fullName, width: 133 },
  { ...ColumnRegistry.listsChange, width: 70 },
  { ...ColumnRegistry.listsPctChange, width: 70 },
  { ...ColumnRegistry.listsTurnover, width: 70 },
  { ...ColumnRegistry.shareCap, width: 70 },
  { ...ColumnRegistry.time, width: 70 },
];
