import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GridModule } from '../../../shared/grid/grid.module';
import { SymbolsGridComponent } from './symbols-grid.component';

@NgModule({
  declarations: [SymbolsGridComponent],
  imports: [CommonModule, GridModule],
  exports: [SymbolsGridComponent],
})
export class SymbolsGridModule {}
