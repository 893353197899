import { Observable, UnaryFunction, combineLatest, map, of, switchMap } from 'rxjs';
import { DashboardRef } from '../state-refs';

export function allModelChanges<T extends DashboardRef>()
  : UnaryFunction<Observable<T[]>, Observable<T[]>>
  | UnaryFunction<Observable<readonly T[]>, Observable<readonly T[]>> {
  return switchMap((dashboards) => {
    if (dashboards.length === 0) {
      return of([]);
    }
    return combineLatest(dashboards.map((db) => db.model$.pipe(map(() => db))));
  });
}
