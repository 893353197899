import { InstrumentDashboardService } from '../../../../dashboard/instrument-dashboard.service';
import { ContextMenuItem } from '../../../../shared/models/context-menu.model';
import { DashboardWindow } from '../../../../state-model/window.model';

export const instrumentDashboardItem = (window: DashboardWindow, instrumentDashboardService: InstrumentDashboardService): ContextMenuItem => ({
  id: 'instrumentPage',
  title: 'CONTEXT_MENU_COMPONENT.OPEN_INSTRUMENT_DASHBOARD',
  icon: 'open_in_new',
  iconClassName: 'open-in-new',
  onClick: (params) => {
    if (!params.instrument || !window) {
      return;
    }
    instrumentDashboardService.addInstrumentDashboardAsync(params.instrument);
  },
});
