import { LogLevel } from '@vwd/ngx-logging';

const hostEnv = environmentFromHost(location.hostname);

// TODO: configure component levels as required!
export const sharedEnv = {
  ...hostEnv,
  keycloakApiUrl: hostEnv.gateway + '/api/idm/', // FIXME: should we use /api/... for localhost?
  dashboardApiUrl: hostEnv.gateway + '/api/dashboard',
  logging: {
    // environment.local.ts
    // environment.ts
    localhost: {
      default: LogLevel.info,
      'services/*': LogLevel.debug,
      'services/progress': LogLevel.warning,
      'services/sdk': LogLevel.warning,
      'services/symbol-list': LogLevel.warning,
      'widgets/lists/service': LogLevel.debug,
      // 'angular/routing': LogLevel.none, // set to trace to enable tracing
    },
    test: {
      default: LogLevel.info,
      'services/*': LogLevel.warning,
      'services/progress': LogLevel.warning,
      'services/sdk': LogLevel.warning,
      'services/symbol-list': LogLevel.warning,
      'widgets/lists/service': LogLevel.debug,
      // 'angular/routing': LogLevel.none, // set to trace to enable tracing
    },
    uat: {
      default: LogLevel.warning,
      // make debug buttons work on production (for now)
      'layout/header': LogLevel.info,
    },
    // environment.prod.ts
    production: {
      default: LogLevel.warning,
    },

    staging: {
      default: LogLevel.warning,
    },

  },
};

interface HostEnvironment {
  realm: string | { default: string };
  allowRealmViaUrl: boolean;
  envName: 'localhost' | 'test' | 'uat' | 'staging' | 'production';
  gateway: string;
  keycloakUrl: string;
}

function environmentFromHost(hostname: string): HostEnvironment {
  if (hostname === 'wt.infrontfinance.com') {
    return {
      realm: 'infront',
      allowRealmViaUrl: false,
      envName: 'production',
      gateway: 'https://infront.cloud.eu.infrontservices.com',
      keycloakUrl: 'https://infront.cloud.eu.infrontservices.com/auth',
    };
  }

  // [<realm>.]<product>[-<env>].<region>.infrontservices.com
  const hostnamePattern = /^(?:([a-z0-9]+)\.)?webtrader(?:-(test|uat|staging))?\.(?:[a-z]+)\.infrontservices\.com$/;

  const match = hostnamePattern.exec(hostname);
  if (match) {
    const [, realm, env, region] = match;
    const cloudInstance = !env || env === 'staging' ? 'cloud' : 'cloud-uat'; // we don't support cloud-test, only cloud-uat
    const cloudRegion = region || 'eu';
    return {
      realm: realm || 'infront',
      allowRealmViaUrl: false,
      envName: (env || 'production') as 'test' | 'uat' | 'staging' | 'production',
      gateway: `https://${realm}.${cloudInstance}.${cloudRegion}.infrontservices.com`,
      keycloakUrl: `https://${realm}.${cloudInstance}.${cloudRegion}.infrontservices.com/auth`,
    };
  }

  return {
    realm: 'infront', // localhost and Azure test URLs allow ?realm=
    allowRealmViaUrl: true,
    envName: hostname === 'localhost' ? hostname : 'test',
    gateway: 'https://gateway.cloud-uat.eu.infrontservices.com',
    keycloakUrl: 'https://idm.cloud-uat.eu.infrontservices.com/auth',
  };
}
