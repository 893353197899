import { WidgetDataModel, WidgetModel, WidgetStructureModel } from '@infront/ngx-dashboards-fx';
import { Logger } from '@vwd/ngx-logging';

import { State } from '../services/state.service';
import { Dashboard } from '../state-model/dashboard.model';
import { Grid } from '../state-model/grid.model';
import { Widget, WidgetName } from '../state-model/widget.model';
import { WindowDefaults, WindowDefaultsMap } from '../state-model/window.defaults';
import {
  DashboardWindow,
  LinkChannel,
  PortfolioOrdersWindow,
  PortfolioPositionsWindow,
  WindowName,
  WindowSettings,
} from '../state-model/window.model';
import { OrderCategory } from '../widgets/portfolio-orders/portfolio-orders.model';
import { TradingClassification } from '../widgets/portfolio-positions/portfolio-positions.model';
import { omit, pick } from './object';

/*** Converters between WT5 objects and Dashboard-Framework objects */

export const convertFromWidgetModels = (dashboardId: string, modelList: readonly WidgetModel[], logger: Logger): Partial<State> => {
  const windows: DashboardWindow[] = [];
  const widgets: Widget[] = [];
  const grids: Grid[] = [];
  modelList?.forEach((model) => {
    const singlePartialState = convertFromWidgetModel(dashboardId, model, logger);
    windows.push(...(singlePartialState.windows ?? []));
    widgets.push(...(singlePartialState.widgets ?? []));
    grids.push(...(singlePartialState.grids ?? []));
  });
  return { windows, widgets, grids } as Partial<State>;
};

export const convertFromWidgetModel = (dashboardId: string, model: WidgetModel, logger: Logger): Partial<State> => {
  // TODO: fix missing & empty?!

  const defaults = pick((WindowDefaultsMap[model.type] ?? WindowDefaultsMap.NotFoundWindow) as WindowDefaults<DashboardWindow>,
    'maxItemRows',
    'minItemRows',
    'maxItemCols',
    'minItemCols',
    'canSetLinkedInstrument'
  );

  const windows: DashboardWindow[] = [
    {
      ...defaults,
      name: model.type as WindowName,
      id: model.id,
      x: model.location.x,
      y: model.location.y,
      cols: model.location.width,
      rows: model.location.height,
      dashboardId: dashboardId,
      settings: model.data.settings as WindowSettings,
      resizeEnabled: (model.data.resizeEnabled ?? true) as boolean,
      dragEnabled: (model.data.dragEnabled ?? true) as boolean,
      label: (model.data.label ?? '') as string,
      selectedWidgetName: model.data.selectedWidgetName as WidgetName,
      linkChannel: model.data.linkChannel as LinkChannel,
      tag: (model.data.tag ?? '') as string,
      created: (model.data.created ?? 0) as number,
      // TODO: do we need to spread more props from model.data?
    },
  ];

  if (defaults.maxItemCols && model.location.width > defaults.maxItemCols
    || defaults.minItemCols && model.location.width < defaults.minItemCols
    || defaults.maxItemRows && model.location.height > defaults.maxItemRows
    || defaults.minItemRows && model.location.height < defaults.minItemRows) {
    logger.warn('Widget size out of bounds', {
      id: model.id,
      type: model.type,
      cols: model.location.width,
      rows: model.location.height,
      minItemCols: defaults.minItemCols,
      maxItemCols: defaults.maxItemCols,
      minItemRows: defaults.minItemRows,
      maxItemRows: defaults.maxItemRows,
    });
  }

  // special cases
  if (model.data.tradingClassification) {
    (windows[0] as PortfolioPositionsWindow).tradingClassification = model.data.tradingClassification as TradingClassification;
  }
  if (model.data.orderCategory) {
    (windows[0] as PortfolioOrdersWindow).orderCategory = model.data.orderCategory as OrderCategory;
  }

  const widgets = (model.data.widgets as unknown as Widget[] ?? [])
    .map(widget => ({ ...widget, dashboardId, windowId: model.id }));
  const grids = (model.data.grids as unknown as Grid[] ?? [])
    .map(grid => ({ ...grid, dashboardId }));

  return { windows, widgets, grids } as Partial<State>;
};

export type WidgetState = Pick<State, 'windows' | 'widgets' | 'grids'>;

export const convertToWidgetStructureModel = (state: WidgetState, dashboard?: Dashboard): WidgetStructureModel => {
  return { widgets: convertToWidgetModels(state, dashboard) };
};

export const convertToWidgetModels = (state: WidgetState | undefined, dashboard?: Dashboard): WidgetModel[] => {
  if (!state?.windows?.length) {
    return [];
  }
  return state.windows
    .filter((window) => !dashboard || window.dashboardId === dashboard.id)
    .map((window) => {
      // collect all properties that we can not be mapped to WidgetModel in windowData
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { name, id, x, y, cols, rows, ...windowData } = window;
      // WidgetLocationModel can be easily mapped
      const location = {
        x: window.x,
        y: window.y,
        width: window.cols,
        height: window.rows,
      };

      // WidgetModel.data expects JSON, so for now we need to fake widgets and grids to be using plain JSON!
      const widgets = state.widgets?.filter((widget) => widget.windowId === window.id && widget.dashboardId === window.dashboardId) ?? [];
      const widgetIds = widgets.map((w) => w.id);
      const grids = state.grids?.filter((grid) => widgetIds.includes(grid.parentId as string) && grid.dashboardId === window.dashboardId) ?? [];

      // convert the WT5 DashboardWindow (and widgets + grids) to a WidgetModel
      return {
        type: window.name,
        id: window.id,
        serializationVersion: 1,
        data: {
          ...omit(windowData, 'dashboardId', 'minItemRows', 'maxItemRows', 'minItemCols', 'maxItemCols', 'canSetLinkedInstrument'),
          // Please never create a property "widgets" or "grids" in a WT5 DashboardWindow!
          widgets: widgets.map(widget => omit(widget, 'dashboardId', 'windowId')),
          grids: grids.map(grid => omit(grid, 'dashboardId')),
        } as unknown as WidgetDataModel,
        location,
        groups: {}, // FIXME not sure what to do here
        // theme?: string, // not required in WT5
      } as WidgetModel;
    });
};
