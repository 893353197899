<div class="wt-fund-screener-result" *ngIf="vm$ | async as vm">
  <div class="wt-fund-screener-result__header">

    <ng-container *ngIf="inProgress$ | async as inProgress">

      <div class="wt-flex-row" [hidden]="!inProgress.progress">
        <div class="wt-flex-column wt-fund-screener-result__progress">
          <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </div>
      </div>

      <div class="wt-flex-row wt-flex-align-justify" [hidden]="inProgress.progress">
        <div class="wt-flex-column wt-fund-screener-result__status">
          {{statusMessage}}
        </div>

        <div class="wt-flex-column wt-flex-shrink wt-fund-screener-result__status" [hidden]="!vm.instruments.length">
          <button class="wt-button wt-fund-screener-result__add-to-watchlist-button"
            [matMenuTriggerFor]="addToWatchlistPanel.menu">
            <mat-icon class="wt-fund-screener-result__add-to-watchlist-button-icon" svgIcon="visibility"></mat-icon>
            {{ 'SCREENER.FUND_SCREENER.ADD_TO_WATCHLIST' | translate }} {{ '(' + vm.instruments.length + ')'}}
          </button>
        </div>

        <wt-menu-panel class="wt-fund-screener-result__add-to-watchlist-panel"
          #addToWatchlistPanel
          [items]="vm.watchlistsMenuItems"
          (itemClick)="addToWatchlistAction.next($event)">
          addToWatchlistAction
        </wt-menu-panel>

        <ng-container *ngIf="addToWatchlist$ | async"></ng-container>
      </div>

    </ng-container>
  </div>
  <!-- tabs deactivated for now -->
  <div class="wt-flex-row">
    <div class="wt-flex-column wt-fund-screener-result__result">
      <!-- <mat-tab-group (selectedTabChange)="onSelectedTabChange($event.index)" [selectedIndex]="selectedTabIndex"
          animationDuration="0ms">
          <mat-tab *ngFor="let tab of tabs">
            <ng-template matTabLabel>{{ "" + tab | translate }}</ng-template>
          </mat-tab>
        </mat-tab-group>-->
    </div>
  </div>

  <div class="wt-flex-row">
    <wt-static-columns-grid
      class="wt-fund-screener-result__grid"
      [data]="vm.result"
      [columns]="vm.selectedColumns"
      (columnsChanged)="onColumnsChanged($event)"
      [gridSettings]="{ rowContextMenu: true, matCheckbox: true}"
      [context]="{ window: {name: 'FundScreenerWindow', dashboardId: vm.currentDashboard?.id} }"></wt-static-columns-grid>
    <!-- context is sending extra data so that the generic context menu can be used to generate the standard context menu items for symbols -->
  </div>
</div>