import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { StoreService } from '../../../services/store.service';
import { Widget } from '../../../state-model/widget.model';
import {
  DashboardWindow,
  LinkChannel,
  NonLinkableChannels,
  PartialDashboardWindow,
  isInstrumentSettings
} from '../../../state-model/window.model';
import { getFeedFromData, getTickerFromData } from '../../../util/symbol';

@Injectable({
  providedIn: 'root',
})
export class LinkChannelService {
  private readonly storeService: StoreService = inject(StoreService);

  private readonly hoverAction = new BehaviorSubject<{ linkChannel: LinkChannel | undefined }>({ linkChannel: undefined });
  readonly linkChannelHover$ = this.hoverAction.asObservable();

  setLinkChannelHover(linkChannel: LinkChannel | undefined): void {
    this.hoverAction.next({ linkChannel });
  }

  setLinkChannel$ = (linkChannel: LinkChannel, window: DashboardWindow): Observable<DashboardWindow[]> =>
    this.storeService.currentWindows$.pipe(
      take(1),
      tap((currentWindows) => {
        let updateWindow: PartialDashboardWindow = {
          linkChannel,
        };
        const findWindow = currentWindows.find((existingWindow) => {
          const isLinkChannelInUse =
            isInstrumentSettings(existingWindow.settings) &&
            !NonLinkableChannels.includes(linkChannel) &&
            existingWindow.linkChannel === linkChannel &&
            existingWindow.id !== window.id &&
            existingWindow.dashboardId !== window.dashboardId;
          return isLinkChannelInUse;
        });
        if (!!findWindow && isInstrumentSettings(findWindow?.settings) && isInstrumentSettings(window.settings)) {
          updateWindow = { ...updateWindow, settings: { ...window.settings, instrument: findWindow.settings.instrument } };
        }
        this.storeService.updateWindow(window, updateWindow);
      })
    );

  setLinkedInstrument$ = (widget: Widget, symbolData: unknown): Observable<DashboardWindow[]> =>
    this.storeService.windowByWidget$(widget).pipe(
      // setting linked-instrument from a grid only works if its window has set canSetLinkChannel to true!
      filter((window) => !!window.canSetLinkedInstrument),
      switchMap((window) =>
        this.storeService.currentWindows$.pipe(
          filter(() => !NonLinkableChannels.includes(window.linkChannel)),
          map((windows) => windows.filter((w) => (
            // do not change our own main instrument when emitting from our table instrument!
            w.id !== window.id
            && isInstrumentSettings(w.settings)
            && this.onSameChannel(w, window)
          ))),
          tap((windows) => {
            const instrument = {
              feed: getFeedFromData(symbolData),
              ticker: getTickerFromData(symbolData),
            };
            windows.forEach((w) => {
              const updateWindow = { settings: { ...w.settings, instrument } };
              this.storeService.updateWindowAsSideEffect(w, updateWindow);
            });
          })
        )
      )
    );

  getLinkChannel$ = (widget: Widget): Observable<LinkChannel | undefined> =>
    this.storeService
      .windowByWidget$(widget)
      .pipe(map((window) => (!NonLinkableChannels.includes(window.linkChannel) ? window.linkChannel : undefined)));


  private onSameChannel = (window1: DashboardWindow, window2: DashboardWindow): boolean => {
    if (!window1 || !window2) {
      return false;
    }
    if (NonLinkableChannels.includes(window1.linkChannel) || NonLinkableChannels.includes(window2.linkChannel)) {
      return false;
    }
    if (window1.linkChannel === window2.linkChannel) {
      return true;
    }
    if (window1.linkChannel === 'All' || window2.linkChannel === 'All') {
      return true;
    }
    return false;
  };
}
