<form class="wt-dialog" method="dialog">
  <div class="wt-flex-row wt-flex-align-middle wt-flex-collapse wt-dialog__title">
    <div class="wt-flex-column wt-flex-shrink wt-dialog-icon">
      <mat-icon svgIcon="{{data.icon}}"></mat-icon>
    </div>

    <div class="wt-flex-column">
      <h1 class="wt-h1">{{ data.title }}</h1>
    </div>
  </div>

  <div mat-dialog-content>
    <mat-form-field floatLabel="never">
      <input class="wt-dialog-input" matInput [(ngModel)]="data.input" name="name" required minlength="4" maxlength="30"
        #name="ngModel" />
    </mat-form-field>
  </div>

  <div class="wt-action-buttons">
    <div class="wt-flex-row wt-flex-collapse wt-flex-align-right">
      <div class="wt-flex-column wt-flex-shrink">
        <button class="wt-button wt-button--cancel" type="button" (click)="onCancel()">
          {{ data.closeLabel }}
        </button>
      </div>

      <div class="wt-flex-column wt-flex-shrink">
        <button class="wt-button wt-button--primary" type="submit" [mat-dialog-close]="data.input">
          {{ data.submitLabel }}
        </button>
      </div>
    </div>
  </div>
</form>