import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Widget } from '../../../state-model/widget.model';
import { DashboardWindow } from '../../../state-model/window.model';
import { Dashboard } from '../../../state-model/dashboard.model';

@Component({
  selector: 'wt-title-and-context-menu-only-header',
  templateUrl: './title-and-context-menu-only-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleAndContextMenuOnlyHeaderComponent {
  @Input() dashboard: Dashboard;
  @Input() window: DashboardWindow;
  @Input() widget: Widget;
}
