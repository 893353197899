import { ValueGetterParams } from 'ag-grid-community';

import { InfrontSDK } from '@infront/sdk';
import { ColumnRegistry } from '../shared/grid/column-registry';
import type { Column, ColumnSetting } from '../shared/grid/columns.model';
import { Grid, GridColumnsMap, SubGrid, WidgetGridsMap } from '../state-model/grid.model';
import type { Widget } from '../state-model/widget.model';
import { convertArrayToObject } from '../util/object';
import { newId } from '../util/utils';
import { toLowerCaseFirstLetter } from './string';

export const newGridsByWidgets = (widgets: Widget[]) => {
  let grids: Grid[] = [];
  for (const widget of widgets) {
    if (
      Object.keys(GridColumnsMap)
        .map((itm) => itm.toLowerCase())
        .includes(widget.name.toLowerCase())
    ) {
      grids = [...grids, newGrid(widget.dashboardId, widget.id, newId(), toLowerCaseFirstLetter(widget.name) as keyof typeof GridColumnsMap)];
      continue;
    }
    if (
      Object.keys(WidgetGridsMap)
        .map((itm) => itm.toLowerCase())
        .includes(widget.name.toLowerCase())
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      grids = [...grids, ...WidgetGridsMap[widget.name.toLowerCase()].map((name: SubGrid) => newGrid(widget.dashboardId, widget.id, newId(), name))];
    }
  }
  return grids;
};

export const selectedColumnsByGridName = (name: keyof typeof GridColumnsMap) => {
  if (Object.keys(GridColumnsMap).includes(name)) {
    return GridColumnsMap[name].defaultSelected;
  }
  throw new Error(`Definition missing for grid named:${name} in GridColumnsMap (grid.model.ts)`);
};

const newGrid = (dashboardId: string, widgetId: string, id: string, name: keyof typeof GridColumnsMap): Grid => ({ dashboardId, parentId: widgetId, id, name });

// only store user configurable column data in settings: colId (list of colIds determines column order), sort and width.
export const getColumnSettingsFromColDef = (columns: Column[]): ColumnSetting[] => {
  const colSettings = columns.map((col) => {
    const colSetting: ColumnSetting = { colId: col.colId };
    ['width', 'sort'].forEach((key) => {
      if (col[key] != undefined) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        colSetting[key] = col[key];
      }
    });
    return colSetting;
  });
  return colSettings;
};

export const fullSelectedColumnsByGrid = (grid: Grid): Column[] => {
  const selCols = grid.settings?.selectedColumns ?? GridColumnsMap[grid.name]?.defaultSelected ?? GridColumnsMap[grid.name]?.all;
  const gridMap = GridColumnsMap[grid.name]?.all;
  const colMap = gridMap ? convertArrayToObject(gridMap, 'colId') : undefined;

  if (!selCols) {
    return gridMap ?? [];
  }
  const columns = selCols.map((col) => {
    if (colMap) {
      // do not take sort order from column defaults
      col = { ...(colMap[col.colId] ?? ColumnRegistry[col.colId]), sort: undefined, ...col };
    }
    return col;
  });
  return columns;
};


export type SharpeField = typeof InfrontSDK.SymbolField.FundSharpeRatio1Y | typeof InfrontSDK.SymbolField.FundSharpeRatio3Y | typeof InfrontSDK.SymbolField.FundSharpeRatio5Y;

export const sharpeValueGetter = (sharpeField: SharpeField) => (params: ValueGetterParams) => {
  const symbol = params.data.symbol as InfrontSDK.SymbolData;
  const sharpes = symbol?.get(InfrontSDK.SymbolField.FundSharpes);
  const currency = symbol?.get(InfrontSDK.SymbolField.Currency);

  if (currency && sharpes?.length) {
    return sharpeFromSharpes(sharpeField, sharpes, currency);
  }
  return;
};

export const sharpeFromSharpes = (sharpeField: SharpeField, sharpes: InfrontSDK.SymbolFieldTypeBase[typeof InfrontSDK.SymbolField.FundSharpes], currency: string) => {

  const sharpeTimeMap = {
    [InfrontSDK.SymbolField.FundSharpeRatio1Y]: 'SharpeYear1',
    [InfrontSDK.SymbolField.FundSharpeRatio3Y]: 'SharpeYear3',
    [InfrontSDK.SymbolField.FundSharpeRatio5Y]: 'SharpeYear5',
  };
  if (currency && sharpes?.length) {
    const sharpesByCurrency = sharpes.find((sharpe: { Currency: string; }) => sharpe?.Currency === currency);
    if (sharpesByCurrency) {
      return (sharpesByCurrency[sharpeField] ?? sharpesByCurrency[sharpeTimeMap[sharpeField]]) as number;
    }
  }
  return;
};
