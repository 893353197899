import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { StaticColumnsGridModule } from '../../wrappers/grid-wrappers/static-columns-grid/static-columns-grid.module';
import { SymbolsGridModule } from '../../wrappers/grid-wrappers/symbols-grid/symbols-grid.module';
import { PositionsExposureComponent } from './positions-exposure.component';



@NgModule({
  declarations: [PositionsExposureComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    MatButtonModule,
    CommonModule,
    I18nPipesModule,
    PipesModule,
    DirectivesModule,
    MatTabsModule,
    MatTooltipModule,
    MatButtonToggleModule,
    SymbolsGridModule,
    StaticColumnsGridModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ]
})
export class PositionsExposureModule { }
