import { ColumnRegistry } from '../../shared/grid/column-registry';
import { Column } from '../../shared/grid/columns.model';
import { ColumnSetting } from '../../state-model/grid.model';

// TradesColumns = cols for all market trades, not to be confused with the cols for user trades in the portfolio: PortfolioTradesColumns
export const TradesColumns: Column[] = [
  { ...ColumnRegistry.dateTimeTime, width: 60 },
  ColumnRegistry.dateTimeDate,
  { ...ColumnRegistry.price, width: 70 },
  { ...ColumnRegistry.priceArrow, width: 40 },
  { ...ColumnRegistry.volume, width: 63 },
  { ...ColumnRegistry.type, width: 61 },
  { ...ColumnRegistry.hitterTaker, width: 45 },
  { ...ColumnRegistry.esmaTypes, width: 95 },
  { ...ColumnRegistry.buyer, width: 74 },
  { ...ColumnRegistry.seller, width: 74 },
  ColumnRegistry.bid,
  ColumnRegistry.ask,
  { ...ColumnRegistry.spread, width: 74 },
  { ...ColumnRegistry.market, width: 74 },
  { ...ColumnRegistry.value, width: 74 },
];

export const SelectedTradesColumns: ColumnSetting[] = [
  { colId: ColumnRegistry.dateTimeTime.colId, sort: 'desc' },
  { colId: ColumnRegistry.price.colId },
  { colId: ColumnRegistry.priceArrow.colId },
  { colId: ColumnRegistry.volume.colId },
  { colId: ColumnRegistry.type.colId },
  { colId: ColumnRegistry.esmaTypes.colId },
  { colId: ColumnRegistry.hitterTaker.colId },
];
