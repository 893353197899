import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DashboardRef } from '@infront/ngx-dashboards-fx';

export type RenameChainsTitleDialogResult = string; // NOSONAR

@Component({
  templateUrl: './rename-chains-title-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  ]
})
export class RenameChainsTitleDialogComponent {
  readonly dialogRef = inject(MatDialogRef<typeof this, RenameChainsTitleDialogResult>);

  readonly form: FormGroup;
  readonly dashboard: DashboardRef;

  get name() { return this.form.get('name')!; }


  MAX_CHAINS_NAME_LENGTH = 100;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { name: string }
  ) {
    this.form = new FormGroup({
      name: new FormControl(data.name, [
        Validators.required
      ]),
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
