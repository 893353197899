import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { CountryFlagModule } from '../../shared/country-flag/country-flag.module';
import { DropdownPortfolioModule } from '../../shared/dropdown/dropdown-portfolio/dropdown-portfolio.module';
import { PortfolioHeaderComponent } from './portfolio-header.component';

@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    MatButtonModule,
    CommonModule,
    MatIconModule,
    I18nPipesModule,
    CountryFlagModule,
    MatTabsModule,
    DropdownPortfolioModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  declarations: [PortfolioHeaderComponent],
})
export class PortfolioHeaderModule {}
