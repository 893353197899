import { Injectable } from '@angular/core';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { SdkService } from '../../../services/sdk.service';
import { WatchlistService } from '../../../services/watchlist.service';
import { SymbolDataItem } from '../../../shared/models/symbol-data.model';
import { Instrument } from '../../../state-model/window.model';

@Injectable()
export class SidebarContentService {
  constructor(private sdkService: SdkService, private watchlistService: WatchlistService) { }

  watchlistSymbolData$ = (watchlistId: string) => {
    return this.watchlistService.getWatchlistById$(watchlistId).pipe(
      map((wl) => wl?.items || []),
      filter((symbolIds) => !!symbolIds),
      switchMap((symbolIds) => {
        return this.symbolData$(symbolIds);
      })
    );
  };

  symbolData$(symbolIds: Instrument[]): Observable<SymbolDataItem[]> {
    if (!symbolIds?.length) {
      return of([] as SymbolDataItem[]);
    }

    const symbolDataOpts: Partial<InfrontSDK.SymbolDataOptions<InfrontSDK.SymbolId[]>> = {
      id: symbolIds,
      subscribe: true,
      content: {
        Basic: true,
        HistoricalPerformance: true,
      },
    };

    return this.sdkService
      .getArray$(InfrontSDK.symbolData, symbolDataOpts)
      .pipe(map((symbolData) => symbolData?.map((data, index) => ({ ...data, index: `${index}~${InfrontUtil.makeUUID()}`, isError: false }))));
  }
}
