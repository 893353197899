/**
 * Dashboard
 * Service that persists dashboard configurations from different applications. The content of the dashboards can also be stored. An user/group/admin permissioning helps to manage dashboards on different access levels.
 *
 * OpenAPI spec version: 1.1.0
 * Contact: swt-backend@vwd365.onmicrosoft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Dashboard } from '../model/dashboard';
import { DashboardPost } from '../model/dashboardPost';
import { DashboardPut } from '../model/dashboardPut';
import { Dashboards } from '../model/dashboards';
import { ModelDelete } from '../model/modelDelete';
import { Post } from '../model/post';
import { ProblemResponse } from '../model/problemResponse';
import { Put } from '../model/put';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class DashboardsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * get multiple dashboards or dashboard-tree (recursive) with one dashboard-role (USER, SHARED, SUBTENANT, COMPANY, GLOBAL)
     * Returns one or more dashboards
     * @param id the id
     * @param role dashboard role
     * @param sub_tenant_id parameter sub_tenant_id is for (SENIOR_)TENANT_ADMIN / (SENIOR_)VWD_ADMIN to get SUBTENANT dashboards from a special sub_tenant
     * @param realm parameter realm is for VWD_ADMIN / SENIOR_VWD_ADMIN to get COMPANY dashboards from a special tenant
     * @param fields 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardsGet(id?: Array<string>, role?: string, sub_tenant_id?: string, realm?: string, fields?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Dashboards>;
    public dashboardsGet(id?: Array<string>, role?: string, sub_tenant_id?: string, realm?: string, fields?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Dashboards>>;
    public dashboardsGet(id?: Array<string>, role?: string, sub_tenant_id?: string, realm?: string, fields?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Dashboards>>;
    public dashboardsGet(id?: Array<string>, role?: string, sub_tenant_id?: string, realm?: string, fields?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id) {
            id.forEach((element) => {
                queryParameters = queryParameters.append('id', <any>element);
            })
        }
        if (role !== undefined && role !== null) {
            queryParameters = queryParameters.set('role', <any>role);
        }
        if (sub_tenant_id !== undefined && sub_tenant_id !== null) {
            queryParameters = queryParameters.set('sub_tenant_id', <any>sub_tenant_id);
        }
        if (realm !== undefined && realm !== null) {
            queryParameters = queryParameters.set('realm', <any>realm);
        }
        if (fields) {
            fields.forEach((element) => {
                queryParameters = queryParameters.append('fields', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Dashboards>('get',`${this.basePath}/dashboards`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete a dashboard
     * 
     * @param id the id
     * @param recursive delete the dashboard and all its children
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardsIdDelete(id: string, recursive?: boolean, observe?: 'body', reportProgress?: boolean): Observable<ModelDelete>;
    public dashboardsIdDelete(id: string, recursive?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelDelete>>;
    public dashboardsIdDelete(id: string, recursive?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelDelete>>;
    public dashboardsIdDelete(id: string, recursive?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dashboardsIdDelete.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recursive !== undefined && recursive !== null) {
            queryParameters = queryParameters.set('recursive', <any>recursive);
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ModelDelete>('delete',`${this.basePath}/dashboards/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get one dashboard by id
     * 
     * @param id the id
     * @param fields 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardsIdGet(id: string, fields?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Dashboard>;
    public dashboardsIdGet(id: string, fields?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Dashboard>>;
    public dashboardsIdGet(id: string, fields?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Dashboard>>;
    public dashboardsIdGet(id: string, fields?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dashboardsIdGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fields) {
            fields.forEach((element) => {
                queryParameters = queryParameters.append('fields', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Dashboard>('get',`${this.basePath}/dashboards/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update a dashboard
     * 
     * @param body dashboard that needs to be updated
     * @param id the id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardsIdPut(body: DashboardPut, id: string, observe?: 'body', reportProgress?: boolean): Observable<Put>;
    public dashboardsIdPut(body: DashboardPut, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Put>>;
    public dashboardsIdPut(body: DashboardPut, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Put>>;
    public dashboardsIdPut(body: DashboardPut, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling dashboardsIdPut.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dashboardsIdPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Put>('put',`${this.basePath}/dashboards/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add a new dashboard
     * * stores a new dashboard * id is optional. a uuid can be given in the body  and for type: NODE or TEMPLATE (not for FOLDER) * stores an empty dashboard_data * stores id from empty dashboard_data in dashboard 
     * @param body dashboard-object that needs to be added
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardsPost(body: DashboardPost, observe?: 'body', reportProgress?: boolean): Observable<Post>;
    public dashboardsPost(body: DashboardPost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Post>>;
    public dashboardsPost(body: DashboardPost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Post>>;
    public dashboardsPost(body: DashboardPost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling dashboardsPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Post>('post',`${this.basePath}/dashboards`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
