<div class="widget-title-bar__title gridster-item-content"
  [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false">
  <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
    <div class="wt-flex-column">
      <span class="widget-title-bar-label">
        {{ "DASHBOARD_WINDOW.HEADER.WIDGET_LABEL." + window.label | translate }}
      </span>
    </div>

    <div class="wt-flex-column wt-flex-shrink">
      <!-- Widget Title Bar Right (__buttons) -->
      <div class="widget-title-bar__buttons gridster-item-content">
        <wt-context-menu class="gridster-item-content" [context]="{ widget, dashboard }"></wt-context-menu>
      </div>
    </div>
  </div>
</div>