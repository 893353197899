
  <div class="widget-title-bar__title gridster-item-content"
    [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false">
    <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle"  *ngIf="vm$ | async as vm">
      <div class="wt-flex-column wt-flex-shrink">
        <mat-icon class="mat-icon" fontIcon="list"></mat-icon>
      </div>
      <!-- name -->
      <div *ngIf="canEditName"
        class="wt-flex-column wt-flex-shrink wt-userlist__name  wt-userlist__name" (click)="onEdit()" (mouseenter)="isPreEditMode = true" (mouseleave)="isPreEditMode = false" (blur)="isPreEditMode = false">
        {{vm.name}}
      </div>
      <div *ngIf="!canEditName"
        class="wt-flex-column wt-flex-shrink wt-userlist__name">
        {{vm.name}}
      </div>
      <!-- edit icon -->
      <div *ngIf="canEditName"
        class="wt-flex-column wt-flex-shrink wt-userlist__edit" (click)="onEdit()" (mouseenter)="isPreEditMode = true" (mouseleave)="isPreEditMode = false" (blur)="isPreEditMode = false">
        <mat-icon class="mat-icon wt-userlist__edit-icon" [class.wt-userlist__edit-icon--mouseover] = "!!isPreEditMode" fontIcon="edit" ></mat-icon>
      </div>
      <div class="wt-flex-column"></div>


      <div class="wt-flex-column wt-flex-shrink">
        <!-- Widget Title Bar Right (__buttons) -->
        <div class="widget-title-bar__buttons gridster-item-content">
          <ul class="wt-list-inline">
            <li>
              <wt-link-channel-menu [window]="window" [dashboard]="dashboard"
                class="link-channel-menu gridster-item-content"></wt-link-channel-menu>
            </li>
            <li>
              <wt-context-menu class="gridster-item-content" [context]="{ widget: vm.widget, dashboard }"></wt-context-menu>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
