<mat-menu #menu data-testid="menu-panel" class="wt-menu-panel">
  <ul class="wt-list">
    <ng-container *ngFor="let item of items; trackBy: menuItemCompareFn">
      <!-- Regular list items -->
      <li *ngIf="!item.subItems" class="wt-dropdown__item" mat-menu-item>
        <div class="wt-flex-row wt-flex-collapse">
          <div class="wt-flex-column">
            <button type="button" class="wt-button wt-button--icon wt-button--expanded"
              (click)="onItemClick(item, $event)"
              [disabled]="item.disabled | async"
              [ngClass]="item.className ?? ''"
              data-testid="menu-panel-item"
              title="{{ item.title ? (item.translateTitle === false ? item.title : (item.title | translate)) : undefined }}">

              <mat-icon *ngIf="item.icon && !item.isSVGIcon" class="wt-dropdown-icon"
                [ngClass]="item.iconClassName ?? ''"
                fontIcon="{{ item.icon }}"></mat-icon>

              <mat-icon *ngIf="item.icon && item.isSVGIcon" class="wt-dropdown-icon"
                svgIcon="{{ item.icon }}"></mat-icon>

              <span class="wt-dropdown-label">{{ (item.translateTitle === false ? item.title : (item.title |
                translate)) }}</span>
            </button>
          </div>

          <div class="wt-flex-column wt-flex-shrink" *ngIf="item.type">
            <ng-container [ngSwitch]="item.type">
              <mat-checkbox
                *ngSwitchCase="'checkbox'"
                [checked]="!!item.checked"
                (click)="onItemClick(item, $event)"
                name="checkbox-{{ scopeId }}"
                class="context-menu-right-align">
              </mat-checkbox>

              <mat-slide-toggle
                *ngSwitchCase="'switch'"
                [checked]="!!item.checked"
                (click)="onItemClick(item, $event)"
                name="slide-toggle-{{ scopeId }}"
                class="context-menu-slider">
              </mat-slide-toggle>

              <mat-radio-button
                *ngSwitchCase="'radio'"
                [checked]="!!item.checked"
                (click)="onItemClick(item, $event)"
                name="radio-button-{{ scopeId }}"
                class="context-menu-right-align">
              </mat-radio-button>
            </ng-container>
          </div>

          <div *ngIf="!!item.actions?.length" class="wt-flex-column wt-flex-shrink">
            <div class="wt-dropdown__actions-panel" (click)="$event.stopPropagation()">
              <button *ngFor="let action of item.actions; trackBy: menuItemCompareFn"
                type="button"
                class="wt-button wt-button--icon"
                (click)="onItemClick(action, $event)"
                [title]="(action.translateTitle === false ? action.title : (action.title | translate))">

                <mat-icon *ngIf="action.icon && !action.isSVGIcon"
                  class="wt-dropdown-icon wt-dropdown__actions-panel-icon"
                  fontIcon="{{ action.icon }}"></mat-icon>

                <mat-icon *ngIf="action.icon && action.isSVGIcon"
                  class="wt-dropdown-icon wt-dropdown__actions-panel-icon"
                  svgIcon="{{ action.icon }}"></mat-icon>
              </button>
            </div>
          </div>
        </div>
      </li>

      <!-- Lists -->
      <li *ngIf="item.subItems" class="wt-dropdown__item" mat-menu-item [matMenuTriggerFor]="innerPanel.menu">
        <div class="wt-flex-row wt-flex-collapse">
          <div class="wt-flex-column">
            <button type="button" class="wt-button wt-button--icon wt-button--expanded">
              <mat-icon
                *ngIf="item.icon"
                class="wt-dropdown-icon"
                [ngClass]="item.iconClassName ?? ''"
                fontIcon="{{ item.icon }}"></mat-icon>

              <span>{{ (item.translateTitle === false ? item.title : (item.title | translate)) }}</span>
            </button>
          </div>

          <div class="wt-flex-column wt-flex-shrink">
            <div *ngIf="!!item.actions?.length"
              class="wt-dropdown__actions-panel wt-dropdown__actions-panel--parent" (click)="$event.stopPropagation()">

              <button *ngFor="let action of item.actions; trackBy: menuItemCompareFn"
                class="wt-button wt-button--icon wt-menu-panel-icon"
                (click)="onItemClick(action, $event)"
                [title]="(action.translateTitle === false ? action.title : (action.title | translate))">

                <mat-icon *ngIf="action.icon && !action.isSVGIcon"
                  class="wt-dropdown-icon wt-dropdown__actions-panel-icon"
                  fontIcon="{{ action.icon }}"></mat-icon>

                <mat-icon *ngIf="action.icon && action.isSVGIcon"
                  class="wt-dropdown-icon wt-dropdown__actions-panel-icon"
                  svgIcon="{{ action.icon }}"></mat-icon>
              </button>
            </div>
          </div>
        </div>
      </li>

      <wt-menu-panel
        #innerPanel
        [items]="item.subItems"
        [context]="context"
        [overlayPanelClass]="'wt-overlay-submenu'"
        (itemClick)="itemClick.emit($event)">
      </wt-menu-panel>
    </ng-container>
  </ul>
</mat-menu>