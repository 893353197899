<div class="wt-flex-row wt-flex-align-middle">
  <div class="wt-flex-column wt-flex-shrink">
    <wt-main-menu></wt-main-menu>
  </div>

  <div class="wt-flex-column wt-flex-shrink">
    <wt-app-search></wt-app-search>
  </div>

  <div class="wt-flex-column wt-flex-shrink">
    <wt-trading-order-entry></wt-trading-order-entry>
  </div>

  <div class="wt-flex-column" *ngIf="folderEditingEnabled$ | async; else folderEditingNotEnabled">
    <div class="wt-flex-row wt-flex-align-center">
      <div class="wt-flex-column wt-flex-shrink wt-header__admin-mode-warning" (dblclick)="disableFolderEditing()">
        <mat-icon fontIcon="manage_accounts"></mat-icon>
        {{ 'HEADER.ADMIN_MODE_ENABLED' | translate }}
      </div>
    </div>
  </div>

  <ng-template #folderEditingNotEnabled>
    <div class="wt-flex-column">
      <div class="wt-flex-row wt-flex-align-center">
        <div class="wt-flex-column wt-flex-shrink">
          <wt-instrument-dock></wt-instrument-dock>
        </div>
      </div>
    </div>
  </ng-template>

  <div *ngIf="showAdmin" class="wt-flex-column wt-flex-shrink">
    <wt-admin-panel></wt-admin-panel>
  </div>

  <div *ngIf="showAdminDialogOption" class="wt-flex-column wt-flex-shrink">
    <mat-icon fontIcon="admin_panel_settings"
      [title]="'ADMIN_PANEL_COMPONENT.ITEMS.SHOW_ADMIN_DIALOG' | translate"
      (click)="openAdminDialog()"></mat-icon>
  </div>

  <div class="wt-flex-column wt-flex-shrink wt-flex-collapse">
    <wt-alerts-list></wt-alerts-list>
  </div>

  <div class="wt-flex-column wt-flex-shrink">
    <wt-main-context-menu></wt-main-context-menu>
  </div>

  <div *ngIf="false" class="wt-flex-column">
    <div class="wt-flex-row">
      <div class="wt-flex-column">
        <a class="link" routerLink="/dashboard">Dashboard</a>
      </div>

      <div class="wt-flex-column">
        <ng-container *ngIf="stateService.debugHistory$ | async as states">
          <ng-container *ngIf="stateService.debugIndex$ | async as index">
            Debug: index{{ index }} states{{ states.length }} type:
            {{ states[index].stateType }}
          </ng-container>
        </ng-container>
      </div>

      <div class="wt-flex-column">
        <button class="temp__topbar-btn" (click)="loadDashboards()">Load dashboards</button>
      </div>

      <div class="wt-flex-column">
        <button class="temp__topbar-btn" (click)="mockupsAsState()">
          Overwrite state
          <br />
          with mockups
        </button>
      </div>

      <div class="wt-flex-column">
        <button class="temp__topbar-btn" (click)="templateAsState()">
          Overwrite state
          <br />
          with templates
        </button>
      </div>
    </div>
  </div>
</div>
