import { InfrontSDK } from '@infront/sdk';

import { Column } from '../../shared/grid/columns.model';
import { TimePeriod } from '../../widgets/ranking/ranking.model';

//todo: delete
export interface TimeSeriesSymbol extends InfrontSDK.SymbolData {
  timeSeries: number[];
  [InfrontSDK.SymbolField.Ticker]: string;
  [InfrontSDK.SymbolField.PreLastTradedAt]: number;
}

export interface TimeSeriesItem {
  [InfrontSDK.SymbolField.Ticker]: string;
  [InfrontSDK.SymbolField.Feed]: number;
  [InfrontSDK.SymbolField.PreLastTradedAt]: number;
  timePeriod: TimePeriod;
}

export interface AvailableColumns {
  [key: string]: Readonly<Column>;
}

// Working as of 11.10.2023 with font-size: 13px and no top-/bottom-padding, will need update when changed!
export const GridHeightsPx = {
  header: 26,
  row: 28,
} as const;
