import { Injectable } from '@angular/core';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { SdkRequestsService } from '../../services/sdk-requests.service';
import { Instrument } from '../../state-model/window.model';
import { objectKeysToLowerCaseFirstLetter } from '../../util/object';
import { ResolvedSymbolValues } from '../../widgets/lists/observe-symbols.service';
import { InstrumentFocusData, StreamingFields } from './instrument-focus.model';

@Injectable({
  providedIn: 'any',
})
export class InstrumentFocusService {
  data$ = (instrument: Instrument): Observable<InstrumentFocusData> =>
    this.sdkRequestsService.snapshotSymbolData$({
      symbolEntity: instrument,
      fields: [
        InfrontSDK.SymbolField.SymbolClassification,
        InfrontSDK.SymbolField.Ticker,
        InfrontSDK.SymbolField.Feed,
      ]
    }).pipe(
      switchMap((preData) => {
        const streamingFields$ = this.sdkRequestsService.streamingSymbolData$({ symbolEntity: instrument, fields: StreamingFields });
        const metaData$ = this.sdkRequestsService.metaDataByFeed$(preData.Feed);
        return combineLatest([streamingFields$, metaData$]).pipe(
          map(([resolvedStreamingFields, metaData]) => {
            const extraData = this.extraData(resolvedStreamingFields, metaData);
            const dataUpdate = objectKeysToLowerCaseFirstLetter(resolvedStreamingFields);
            return {
              ...dataUpdate,
              ...extraData,
            } as InstrumentFocusData;
          })
        );
      }),
    );

  constructor(private readonly sdkRequestsService: SdkRequestsService) { }

  // extra derived data from Symbol Fields going out to the template
  private extraData(resolvedFields: ResolvedSymbolValues, metaData: InfrontSDK.FeedInfo) {
    const time = resolvedFields.PreDisplayTime;
    return {
      close: resolvedFields.Last,
      lastTradedAt: resolvedFields.PreLastTradedAt,
      time: InfrontUtil.isDate(time) ? new Date(time) : undefined,
      hasIntradaySupport: metaData?.minDelaySecs !== 86400,
    };
  }

}
