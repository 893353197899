import { InfrontSDK } from '@infront/sdk';

export interface SearchItemSymbol {
  ticker: string;
  feed: number;
  feedDescription: string;
  fullName: string;
  access: string;
  tradable: string;

  icon?: string;
  isoCountry?: string;
  feedExchange?: string;
  description?: string;
  isin?: string;
  currency?: string;
  symbolClassification?: InfrontSDK.SymbolClassification;
  feedIsHidden?: boolean;
}

export const SymbolNameByClassification: { [Classificaiton in InfrontSDK.SymbolClassification]?: keyof SearchItemSymbol; } = {
  [InfrontSDK.SymbolClassification.Fund]: 'isin',
};

export const DefaultSymbolName: keyof SearchItemSymbol = 'ticker';
