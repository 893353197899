import { Injectable, inject } from '@angular/core';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { GuardJSONCompatible, MFEAuth, PreferencesMap, RootPreferencesMap } from '@vwd/microfrontend-core';
import { Observable, distinctUntilChanged, from, map, tap } from 'rxjs';
import { structuresAreEqual } from '../util/equality';
import { TenantSettingsStorageData, TenantSettingsStorageKeys } from './storage.service';

@Injectable({ providedIn: 'root' })
export class TenantSettingsService {
  private rootPreferences = inject(RootPreferencesMap) as RootPreferencesMap<TenantSettingsStorageData>;
  private tenantSettingsStorage = this.rootPreferences.tenant as PreferencesMap<TenantSettingsStorageData>;
  // private readonly logger = inject(LogService).openLogger('services/tenant-settings');

  private tenantSettingAction = new LastValueSubject<TenantSettingsStorageData>();
  private auth = inject(MFEAuth);

  get realm(): string { return this.auth.realm!; }

  canEdit(realm?: string): boolean {
    if (!realm || realm === this.realm) {
      return !this.tenantSettingsStorage.readonly;
    }
    if (realm === '*') {
      return this.auth.clientResourceAccess.roles.includes('SENIOR_VWD_ADMIN');
    }
    return !this.rootPreferences.forLevel('TENANT', { realm }).readonly;
  }

  // wrap set to keep set and get available from the same service/wrapper
  setValue<K extends keyof TenantSettingsStorageData>(key: K, value: GuardJSONCompatible<TenantSettingsStorageData[K]> | undefined, realm?: string): Observable<void> {
    if (!realm || realm === this.realm) {
      if (value == undefined) {
        return from(this.tenantSettingsStorage.delete(key));
      } else {
        return from(this.tenantSettingsStorage.set(key, value));
      }
    } else {
      return from(this.rootPreferences.forLevelAsync('TENANT', { realm }).then((map) => {
        if (value == undefined) {
          return map.delete(key);
        } else {
          return map.set(key, value as GuardJSONCompatible<TenantSettingsStorageData[K]>);
        }
      }));
    }
  }

  // clients should not have to set up watch and dispose, get and future change detection through watch will be combined in one observable
  getValue$<K extends keyof TenantSettingsStorageData>(key: K, realm?: string): Observable<TenantSettingsStorageData[K]> {
    if (!realm || realm === this.realm) {
      return this.tenantSettingAction.pipe(
        map((userStorage) => userStorage[key]),
        distinctUntilChanged((prev, next) => structuresAreEqual(prev, next))
      );
    }

    return new Observable<TenantSettingsStorageData[K]>(subscribe => {
      const map = this.rootPreferences.forLevel('TENANT', { realm });
      let value = map.get(key);

      subscribe.next(value);

      const disposable = map.watch(key, (newValue) => {
        if (!subscribe.closed && !structuresAreEqual(value, newValue)) {
          value = newValue;
          queueMicrotask(() => {
            if (!subscribe.closed) {
              subscribe.next(value);
            }
          });
        }
      });

      return () => disposable.dispose();
    });
  }

  // all snapshots need to go to storage
  private getAllCurrentValues(): TenantSettingsStorageData {
    const values = {} as TenantSettingsStorageData;
    for (const key of TenantSettingsStorageKeys) {
      values[key as string] = this.tenantSettingsStorage.get(key);
    }
    return Object.freeze(values);
  }

  constructor() {
    from(this.tenantSettingsStorage.ready())
      .pipe(
        tap(() => {
          this.buildSettingsAndEmit();

          this.tenantSettingsStorage.watch(() => {
            queueMicrotask(() => this.buildSettingsAndEmit());
          });

        })
      )
      .subscribe();
  }

  private buildSettingsAndEmit(): void {
    const newSettings = this.getAllCurrentValues();
    if (!structuresAreEqual(newSettings, this.tenantSettingAction.value)) {
      this.tenantSettingAction.next(newSettings);
    }
  }
}
