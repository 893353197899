import { Injectable, inject } from '@angular/core';
import { InfrontUtil } from '@infront/sdk';
import { FormattingService } from '@vwd/ngx-i18n';
import { ResourceService } from '@vwd/ngx-i18n/translate';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';
import { InstrumentDashboardService } from '../dashboard/instrument-dashboard.service';
import { Instrument } from '../state-model/window.model';
import { LOCALE_ID$ } from '../util/locale';
import { instrumentsAreEqual } from '../util/sdk';
import { ObjectKeysAsValueProperty } from '../util/types';
import { CountryFlagService } from './../shared/country-flag/country-flag.service';
import { TradableService } from './tradable.service';
import { TradingOrderEntryService } from './trading-order-entry.service';
import { na } from '../shared/grid/grid.model';

export enum Colorization {
  None = 0,
  HighlightNegatives,
  HighlightPositives,
  HighlightNegativesAndPositives,
}

export interface FieldColumnInstrument {
  currency: string;
  feed: number;
  ticker: string;
  isin: string;
}

export interface FieldColumnParams {
  columnNames: string[];
  instruments?: Instrument[]; // Used for isTradable check required fields
  tradableInstruments?: Instrument[]; // Unless provided, will be filled with instruments after isTradable checking
}

// object should be Infront.Field instead but isn't compatible with current definitions!
// Makes sure that Object key + Object value property 'name' share same value
export type ToolkitColumnDefs<Cols extends object = object> = ObjectKeysAsValueProperty<Cols, 'name'>;

/** @WIP Not in use yet */
@Injectable({
  providedIn: 'root'
})
export class ToolkitColumnService {

  private readonly localeId$ = inject(LOCALE_ID$);

  constructor(
    private formattingService: FormattingService,
    private resourceService: ResourceService,
    private instrumentDashboardService: InstrumentDashboardService,
    private tradingOrderEntryService: TradingOrderEntryService,
    private countryFlagService: CountryFlagService,
    private tradableService: TradableService
  ) { }

  // @TODO adjust type by defining type for getFieldColumnDefinitions -> ToolkitColumnDefs<Infront.Field>
  columns$ = (params$: Observable<FieldColumnParams>): Observable<unknown[]> => combineLatest([
    params$.pipe(switchMap((params) => this.resolveIsTradable$(params))),
    this.localeId$,
  ]).pipe(
    map(([params, _newLocale]) => {
      const { columnNames } = params;
      const colDefs = this.getFieldColumnDefinitions(params);
      const columns: unknown[] = [];
      columnNames.forEach((colName) => {
        if (colDefs[colName]) {
          columns.push(colDefs[colName]);
        }
      });
      return columns;
    })
  );

  private resolveIsTradable$(params: FieldColumnParams): Observable<FieldColumnParams> {
    const { instruments, tradableInstruments } = params;
    if (instruments?.length && !tradableInstruments) {
      const isTradableInstruments$ = this.tradableService.areTradable(instruments.map((instrument) => ({ instrument })));
      return isTradableInstruments$.pipe(
        map((isTradableInstruments) => {
          params.tradableInstruments = [];
          isTradableInstruments.forEach((isTradableInstrument) => {
            if (isTradableInstrument.isTradable) {
              params.tradableInstruments?.push(isTradableInstrument.instrument);
            }
          });
          return params;
        })
      );
    }
    return of(params);
  }

  private getFieldColumnDefinitions(params: FieldColumnParams): ToolkitColumnDefs {
    const colDefs = {
      VOLUME: {
        name: 'VOLUME',
        heading: this.resourceService.getIfExists('TOOLKIT.COLUMN.HEADING.VOLUME') as string,
        allowZero: true,
        translate: (_rowId: number, value: number) => this.formatValueToHTML(value, 0, Colorization.HighlightNegatives)
      },
      AVG_PRICE: {
        name: 'AVG_PRICE',
        heading: this.resourceService.getIfExists('TOOLKIT.COLUMN.HEADING.AVG_PRICE') as string,
        allowZero: true,
        translate: (_rowId: number, value: number) => this.formatValueToHTML(value, 2, Colorization.HighlightNegatives)
      },
      BASE_MARKET_VALUE: {
        name: 'BASE_MARKET_VALUE',
        heading: this.resourceService.getIfExists('TOOLKIT.COLUMN.HEADING.BASE_MARKET_VALUE') as string,
        allowZero: true,
      },
      BASE_RESULT: {
        name: 'BASE_RESULT',
        heading: this.resourceService.getIfExists('TOOLKIT.COLUMN.HEADING.BASE_RESULT') as string,
        allowZero: true,
        translate: (_rowId: number, value: number) => this.formatValueToHTML(value, 2, Colorization.HighlightNegativesAndPositives)
      },
      COLLATERAL: {
        name: 'COLLATERAL',
        allowZero: true,
      },
      // type: 'computed'
      FLAG_WITH_TICKER_AND_PCT_CHANGE: {
        name: 'FLAG_WITH_TICKER_AND_PCT_CHANGE',
        hover: 'FULL_NAME',
        type: 'computed',
        computeFields: ['COUNTRY_FLAG', 'DISPLAY_TICKER', 'TICKER', 'PCT_CHANGE'],
        compute: (_rowId: number, args: number) => args,
        translate: (_rowId: number, [countryFlag, displayTicker, symbolTicker, pctChange]: [string, string, string, number]) => {
          const displayTickerSpan = this.wrapValueInSpan(
            displayTicker ?? symbolTicker,
            undefined,
            'cell-w-positions__header-ticker-instrument'
          );
          const pctChangeSpan = this.formatPercentToHTML(
            pctChange,
            2,
            Colorization.HighlightNegativesAndPositives,
            'cell-w-positions__header-instrument-pct-change'
          );
          const displayTickerAndPctChangeInSpan = `<span>${displayTickerSpan}${pctChangeSpan}</span>`;
          const countryFlagOuterHTML = this.countryFlagService.getCountryFlag(countryFlag, 16)?.outerHTML ?? '';
          const content = `${countryFlagOuterHTML}${displayTickerAndPctChangeInSpan}`;
          return `<span class="wt-flex wt-wtk-column__flag-ticker-pct-change">${content}</span>`;
        },
        onClick: (instrument: FieldColumnInstrument) => this.instrumentDashboardService.addInstrumentDashboardAsync(instrument)
      },
      INVESTED_AND_BASE_WITH_CURRENCY: {
        name: 'INVESTED_AND_BASE_WITH_CURRENCY',
        heading: this.resourceService.getIfExists('TOOLKIT.COLUMN.HEADING.INVESTED') as string,
        type: 'computed',
        allowZero: true,
        computeFields: ['BASE_INVESTED', 'INVESTED', 'BASE_CURRENCY', 'CURRENCY'],
        compute: (_rowId: number, args: number) => args,
        translate: (_rowId: number, [baseInvested, invested, baseCurrency, currency]: [number, number, string, string]) => {
          if (baseCurrency !== currency) {
            return `${this.getFormattedValue(invested, 2, ' ' + currency)} / ${this.getFormattedValue(baseInvested, 2, ' ' + baseCurrency)}`;
          }
          return this.getFormattedValue(invested, 2, ` ${currency}`); // whitespace required
        }
      },
      MARKET_VALUE_AND_BASE_WITH_CURRENCY: {
        name: 'MARKET_VALUE_AND_BASE_WITH_CURRENCY',
        heading: this.resourceService.getIfExists('TOOLKIT.COLUMN.HEADING.MARKET_VALUE') as string,
        type: 'computed',
        allowZero: true,
        computeFields: ['BASE_MARKET_VALUE', 'MARKET_VALUE', 'BASE_CURRENCY', 'CURRENCY'],
        compute: (_rowId: number, args: number) => args,
        translate: (_rowId: number, [baseMarketValue, marketValue, baseCurrency, currency]: [number, number, string, string]) => {
          let outerHTML = `${this.getFormattedValue(marketValue, 2, ' ' + currency)}`;
          if (baseCurrency !== currency) {
            outerHTML += ` / ${this.getFormattedValue(baseMarketValue, 2, ' ' + baseCurrency)}`;
          }
          return outerHTML; // whitespace required
        }
      },
      RESULT_AND_BASE_WITH_CURRENCY: {
        name: 'RESULT_AND_BASE_WITH_CURRENCY',
        heading: this.resourceService.getIfExists('TOOLKIT.COLUMN.HEADING.RESULT') as string,
        type: 'computed',
        allowZero: true,
        computeFields: ['BASE_RESULT', 'RESULT', 'BASE_CURRENCY', 'CURRENCY'],
        compute: (_rowId: number, args: number) => args,
        translate: (_rowId: number, [baseResult, result, baseCurrency, currency]: [number, number, string, string]) => {
          let outerHTML = `${this.formatValueToSpan(result, 2, Colorization.HighlightNegativesAndPositives)} ${currency}`;
          if (baseCurrency !== currency) {
            outerHTML += ` / ${this.formatValueToSpan(baseResult, 2, Colorization.HighlightNegativesAndPositives)} ${baseCurrency}`;
          }
          return outerHTML;
        }
      },
      RESULT_PCT_AND_BASE_WITH_CURRENCY: {
        name: 'RESULT_PCT_AND_BASE_WITH_CURRENCY',
        heading: this.resourceService.getIfExists('TOOLKIT.COLUMN.HEADING.RESULT_PCT') as string,
        type: 'computed',
        allowZero: true,
        computeFields: ['BASE_RESULT_PCT', 'RESULT_PCT', 'BASE_CURRENCY', 'CURRENCY'],
        compute: (_rowId: number, args: number) => args,
        translate: (_rowId: number, [baseResultPct, resultPct, baseCurrency, currency]: [number, number, string, string]) => {
          let outerHTML = `${this.formatValueToSpan(resultPct, 2, Colorization.HighlightNegativesAndPositives)} ${currency}`;
          if (baseCurrency !== currency) {
            outerHTML += ` / ${this.formatValueToSpan(baseResultPct, 2, Colorization.HighlightNegativesAndPositives)} ${baseCurrency}`;
          }
          return outerHTML;
        }
      },
      // Type: 'custom''
      BUY_SELL_BUTTON: {
        name: 'BUY_SELL_BUTTON',
        heading: '',
        sortable: false,
        type: 'custom',
        className: 'wtm-positions-buy-sell wt-positions-buy-sell',
        onClick: (instrument: FieldColumnInstrument) => this.tradingOrderEntryService.openOrderEntry({ instrument }),
        content: (instrument: FieldColumnInstrument) => {
          const tradableInstruments = params?.tradableInstruments;
          if (tradableInstruments?.some((tradableInstrument) => instrumentsAreEqual(instrument, tradableInstrument, false))) {
            return `<button type="button" class="wt-button wt-button--primary wt-full-width wt-button-buy-sell">${this.resourceService.getIfExists('TOOLKIT.COLUMN.CONTENT.BUY_SELL') as string}</button>`;
          }
          return '<div class="wt-position-non-tradable"><div>';
        },
      }
    } as const;

    return colDefs as ToolkitColumnDefs<typeof colDefs>;
  }

  // Util methods taken from IWT4, slightly adjusted
  getFormattedValue(value: unknown, decimals: number, suffix: string = ''): string {
    if (InfrontUtil.isNumber(value)) {
      const decimalsStr = decimals != undefined ? `1.${decimals}` : `1.0-5`;
      return this.formattingService.formatNumber(value, decimalsStr) + suffix;
    }

    return na;
  }

  getColorizationClass(value: unknown, colorization: Colorization, decimals: number | undefined = undefined): string {
    let className = '';

    if (InfrontUtil.isNumber(value)) {
      const decimalsStr = decimals != undefined ? `1.${decimals}` : `1.0-5`;
      // we need formattedValue as English number-string to check in next step if value was zero when rounded!
      const formattedValue = this.formattingService.formatNumber(value, decimalsStr, 'en').replace(/,/g, '');
      if (+formattedValue === 0) { // rounded value was roughly zero!
        return className;
      } else if (value < 0 && [Colorization.HighlightNegatives, Colorization.HighlightNegativesAndPositives].includes(colorization)) {
        className = 'cell-status-negative';
      } else if (value > 0 && [Colorization.HighlightPositives, Colorization.HighlightNegativesAndPositives].includes(colorization)) {
        className = 'cell-status-positive';
      }
    }

    return className;
  }

  formatValueToHTML(value: unknown, decimals: number, colorization: Colorization = Colorization.None): string {
    return `<div class="${this.getColorizationClass(value, colorization, decimals)}">${this.getFormattedValue(value, decimals)}</div>`;
  }

  formatPercentToHTML(value: unknown, decimals: number, colorization: Colorization = Colorization.None, style?: string): string {
    return `<span class="${this.getColorizationClass(value, colorization)} ${style ?? ''}">${this.getFormattedValue(value, decimals, '%')}</span>`;
  }

  formatValueToSpan(value: unknown, decimals: number, colorization: Colorization = Colorization.None): string {
    return `<span class="${this.getColorizationClass(value, colorization)}">${this.getFormattedValue(value, decimals)}</span>`;
  }

  wrapValueInSpan(value: unknown, decimals: number | undefined = undefined, style?: string): string {
    if (decimals != undefined) {
      value = this.getFormattedValue(value, decimals);
    }
    return `<span class="${style ?? ''}">${value as string}</span>`;
  }

}
