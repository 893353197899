import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, Output, inject } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { Subject, merge, of } from 'rxjs';
import { share, switchMap, take, tap } from 'rxjs/operators';

import { alertDeleteAnimation } from '../alerts-list.animations';
import { ServerAlert, triggerTypeMap } from '../alerts-list.model';
import { AlertService } from './../../../services/alert.service';

@Component({
  selector: 'wt-my-alerts',
  templateUrl: './my-alerts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [alertDeleteAnimation],
})
export class MyAlertsComponent implements OnDestroy {
  private alertService: AlertService = inject(AlertService);

  @Output() triggerClose = new EventEmitter<void>();
  @Output() refreshAlerts = new EventEmitter<void>();

  readonly AlertType = InfrontSDK.AlertType;

  readonly refresh = new Subject<void>();
  readonly triggerTypeMap = triggerTypeMap;
  listVisibility: { show: boolean };

  readonly myAlerts$ = merge(of(undefined), this.refresh)
    .pipe(switchMap(() => this.alertService.myAlerts$))
    .pipe(
      share(),
      tap((alerts) => {
        this.listVisibility = { show: !!alerts.length };
      })
    );

  ngOnDestroy(): void {
    this.refresh.complete();
  }

  readonly onDelete = (alert: ServerAlert, event: MouseEvent) => {
    alert.isDeleting = true;
    this.alertService
      .deleteAlert(alert)
      .pipe(
        tap(() => this.refresh.next()),
        take(1)
      )
      .subscribe();
    event.stopPropagation();
  };

  readonly alertIdentifier = (_index: number, item: ServerAlert) => item.id;

  readonly addAlert = () => {
    this.alertService.openAlertDialog({ alertType: 'instrument' });
    this.triggerClose.next();
  };

  readonly editAlert = (alert: InfrontSDK.ServerAlert) => {
    this.alertService.openAlertDialog({ alertType: alert.type === 'Instrument' ? 'instrument' : 'watchlist', alert });
    this.triggerClose.next();
  };
}
