import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { PricePipesModule } from '@vwd/ngx-price-pipes';

import { SymbolsGridModule } from '../../wrappers/grid-wrappers/symbols-grid/symbols-grid.module';
import { ExposureComponent } from './exposure.component';

@NgModule({
  declarations: [ExposureComponent],
  imports: [
    CommonModule,
    SymbolsGridModule,
    MatButtonToggleModule,
    PricePipesModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
})
export class ExposureModule { }
