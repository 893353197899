import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { PipesModule } from '../../pipes/pipes.module';
import { IframeOverlayContentComponent } from './iframe-overlay-content.component';

@NgModule({
  declarations: [IframeOverlayContentComponent],
  imports: [
    CommonModule,
    OverlayModule,
    MatIconModule,
    PipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [IframeOverlayContentComponent],
})
export class IframeOverlayModule { }
