<div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
  <div class="wt-flex-column" [ngClass]="{ 'wt-flex-shrink': !!ticker }">
    <wt-country-flag
      *ngIf="!noFlag"
      [ngClass]="'wt-country-flag-cell-image' + (!!ticker ? '' : '--center')"
      [country]="country"
      [size]="size"
      [title]="countryName"></wt-country-flag>
  </div>

  <div class="wt-flex-column wt-truncate"
    *ngIf="!!ticker"
    [ngClass]="{ 'wt-grid--clickable': !noClickable }"
    [title]="fullName">{{ ticker }}</div>
</div>