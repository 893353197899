<div class="wt-dropdown">
  <ng-container *ngIf="type === 'News'">
    <div class="wt-dropdown__wrapper" [matMenuTriggerFor]="afterMenu"
      (menuOpened)="onMenuOpened(inputElm)" (menuClosed)="onMenuClosed()">

      <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
        <div class="wt-flex-column wt-flex-shrink">
          <mat-icon class="widget-title-bar-icon" svgIcon="flag"></mat-icon>
        </div>

        <div class="wt-flex-column">
          <span class="wt-dropdown-label">
            {{ (singleSourceTitle || $any(title)) | alternativeValue }} ({{ checkedAmount$ | async }})
          </span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="type !== 'News'">
    <div class="" [matMenuTriggerFor]="afterMenu" (menuOpened)="onMenuOpened(inputElm)"
      (menuClosed)="onMenuClosed()">
      <ng-content></ng-content>
    </div>
  </ng-container>

  <mat-menu
    #afterMenu="matMenu"
    xPosition="after"
    class="wt-market-overview-dropdown {{ menuClass }}"
    [hasBackdrop]="true"
    [backdropClass]="menuBackdropClass">
    <mat-accordion *ngIf="marketOverview$ | async as marketOverview" (click)="$event.stopPropagation()" multi>
      <div class="wt-market-overview-dropdown__header">
        <div class="wt-flex-row wt-flex-align-middle wt-flex-align-justify">
          <div class="wt-flex-column"
            [ngClass]="marketHeaderMetadata.length === 2 ? 'wt-flex-small-8' : 'wt-flex-small-7'">
            <div class="wt-market-overview-dropdown__header-filter">
              <input type="text" class="wt-market-overview-dropdown__header-filter-input" #inputElm
                [placeholder]="'MARKET_OVERVIEW_DROPDOWN.FILTER.COUNTRY_SOURCE' | translate"
                (keyup)="onInputChange($event)" />

              <mat-icon class="wt-market-overview-dropdown__header-filter-icon" fontIcon="search"></mat-icon>
            </div>
          </div>

          <div class="wt-flex-column" *ngFor="let header of marketHeaderMetadata">
            <div [class]="header.className">
              <div class="wt-truncate" title="{{ $any(header.title) | translate }}">
                {{ $any(header.title) | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wt-market-overview-dropdown__list">
        <ng-container *ngFor="let market of marketOverview | keyvalue: dropdownKeyOrder">
          <mat-expansion-panel
            *ngIf="market.value.length"
            [expanded]="marketSatisfiesSearch(market.value)"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-checkbox
                  *ngIf="useCheckboxes"
                  color="black"
                  (click)="$event.stopPropagation()"
                  (change)="checkAll($event.checked, market.key)"
                  [checked]="allSelectedState[market.key]"
                  [indeterminate]="isSomeChecked(market.key)"></mat-checkbox>
                {{ ('SDK.MARKET_NAME.' + market.key) | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="wt-market-overview-dropdown__market {{ menuClass }}"
              *ngFor="let subItem of market.value">
              <div *ngIf="useCheckboxes; then newsRow; else marketRow"></div>

              <ng-template #newsRow>
                <div class="wt-flex-row wt-flex-align-middle" (click)="checkbox.toggle()">
                  <div class="wt-flex-column wt-flex-small-8">
                    <div class="wt-market-overview-dropdown__market-description">
                      <div class="wt-truncate" title="{{ $any(subItem.description) | alternativeValue }}">
                        <mat-checkbox
                          #checkbox
                          color="black"
                          (click)="$event.preventDefault()"
                          (ngModelChange)="onSingleItemChanged(subItem)"
                          [ngModel]="subItem.selected"></mat-checkbox>
                        <span
                          [innerHtml]="$any(subItem.descriptionMarkHtml) || ($any(subItem.description)| alternativeValue)"></span>
                      </div>
                    </div>
                  </div>

                  <div class="wt-flex-column">
                    <div class="wt-market-overview-dropdown__market-source">
                      <div class="wt-truncate" title="{{ $any(subItem.source) | alternativeValue }}"
                        [innerHtml]="$any(subItem.sourceMarkHtml) || ($any(subItem.source) | alternativeValue)">
                      </div>
                    </div>
                  </div>

                  <div class="wt-flex-column">
                    <div class="wt-market-overview-dropdown__market-access">
                      <div class="wt-truncate" title="{{ $any(subItem.access) | alternativeValue }}">
                        {{ $any(subItem.access) | alternativeValue }}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>

              <ng-template #marketRow>
                <div class="wt-flex-row wt-flex-align-middle wt-flex-align-justify" (click)="onRowClick(subItem)">
                  <div class="wt-flex-column wt-flex-small-7">
                    <div class="wt-market-overview-dropdown__market-description">
                      <div class="wt-truncate" title="{{ $any(subItem.description) | alternativeValue }}"
                        [innerHtml]="$any(subItem.descriptionMarkHtml) || ($any(subItem.description) | alternativeValue)">
                      </div>
                    </div>
                  </div>

                  <div class="wt-flex-column">
                    <div class="wt-market-overview-dropdown__market-source">
                      <div class="wt-truncate" title="{{ $any(subItem.source) | alternativeValue }}"
                        [innerHtml]="$any(subItem.sourceMarkHtml) || ($any(subItem.source) | alternativeValue)">
                      </div>
                    </div>
                  </div>

                  <div class="wt-flex-column">
                    <div class="wt-market-overview-dropdown__market-access">
                      <div class="wt-truncate" title="{{ subItem.access | feedAccess: 'veryshort' }}">
                        {{ subItem.access | feedAccess: 'veryshort' }}
                      </div>
                    </div>
                  </div>

                  <div class="wt-flex-column">
                    <div class="wt-market-overview-dropdown__market-trading">
                      <div class="wt-truncate" title="{{ $any(subItem.trading) | alternativeValue }}">
                        {{ $any(subItem.trading) | alternativeValue }}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </div>
    </mat-accordion>
  </mat-menu>
</div>
