<div class="wt-trade-toast">
  <!-- first row -->
  <div class="wt-flex-row wt-align-justify">
    <div class="wt-flex-column">
      <span>
        <span [ngClass]="{'wt-trade-toast--green': tradeResolved.BuyOrSell === 'BUY', 'wt-trade-toast--red' : tradeResolved.BuyOrSell === 'SELL' }">
          {{('TRADE_TOAST_COMPONENT.' + tradeResolved.BuyOrSell) | translate}}
        </span>
         {{tradeResolved.Ticker}}
      </span>
    </div>
    <div class="wt-flex-column wt-flex-shrink">
      <span>
        <span class="wt-trade-toast--dimmed">{{tradeResolved.TradeId}}</span>
        {{tradeResolved.TradeTime}}
      </span>
    </div>
  </div>

  <!-- second row -->
  <div class="wt-flex-row wt-align-justify">
      <div class="wt-flex-column">
        {{description}}
      </div>
      <div class="wt-flex-column wt-flex-shrink wt-trade-toast--dimmed">
        {{filledMessage}}
      </div>
   </div>

  <div class="wt-trade-toast__close">
    <mat-icon (click)="close()" fontIcon="close"></mat-icon>
  </div>
</div>
