import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { CellClickedEvent, GridApi, GridOptions, RowClickedEvent, RowSelectedEvent, SelectionChangedEvent } from 'ag-grid-community';
import { Observable, ReplaySubject, Subject, combineLatest, merge } from 'rxjs';
import { distinctUntilKeyChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';

import { TradableService } from '../../../services/tradable.service';
import { TradingService } from '../../../services/trading.service';
import { UserSettingsService } from '../../../services/user-settings.service';
import { Column, GridSettings } from '../../../shared/grid/columns.model';
import { GridComponent } from '../../../shared/grid/grid.component';
import { GridService } from '../../../shared/grid/grid.service';
import { PickedInstrumentsService } from '../../../shared/grid/mat-checkbox/picked-instruments.service';
import { SymbolDataItem } from '../../../shared/models/symbol-data.model';
import { ColumnSetting, Grid } from '../../../state-model/grid.model';
import { getColumnSettingsFromColDef } from '../../../util/grid';
import { filterUndefined } from '../../../util/rxjs';
import { InstrumentLike, isSameInstrument } from '../../../util/symbol';
import { DataValue } from '../../../widgets/lists/observe-symbols.service';
import { SymbolsToValuesService } from '../../../widgets/lists/symbols-to-values.service';
import { GridRef } from '../gridref';
import { isSameObject } from './../../../util/utils';

@Component({
  selector: 'wt-symbols-grid',
  templateUrl: './symbols-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SymbolsToValuesService, PickedInstrumentsService],
})
export class SymbolsGridComponent implements OnInit, OnDestroy {

  @ViewChild(GridComponent)
  grid: GridComponent;

  // @param gridRef The entity containing grid settings, can be provided via DI or set manually
  @Input() gridRef: GridRef;
  //@param symbols - use if InfrontSDK symbols as data-source
  @Input() set symbols(value: SymbolDataItem[]) {
    // we need to flush the old grid-data, before the incoming new grid-data is set (and bound),
    // else old and new grid-data will be mixed (sic!) for whatever clever reasons ag-grid might have!
    // the official way (as of writing) to flash the grid-data is by setting rowData to empty array.
    this._gridApi?.flushAsyncTransactions();
    this._gridApi?.setGridOption('rowData', []);
    this.symbolsAction.next(value);
  }
  // @param data - use if plain data as data-source
  @Input() set plainData(value: DataValue[] | unknown[]) {
    // we need to flush the old grid-data, before the incoming new grid-data is set (and bound),
    // else old and new grid-data will be mixed (sic!) for whatever clever reasons ag-grid might have!
    // the official way (as of writing) to flash the grid-data is by setting rowData to empty array.
    this._gridApi?.flushAsyncTransactions();
    this._gridApi?.setGridOption('rowData', []);
    this.plainDataAction.next(value);
  }
  // @param data - use if specific items in symbols based on property should just be passed through and not be treated as symbols to be resolved (marketOverview usecase)
  @Input() filterFromSymbols: { key: string; value: unknown };
  @Input() rowBuffer: number;
  @Input() gridSettings: GridSettings;
  @Input() gridOptions: GridOptions;
  @Input() set context(context: unknown) {
    this.contextAction.next(context);
  }
  @Input() suppressHorizontalScroll: boolean;

  @Input() set scrollToRow(scrollToRow: { index: number } | undefined) {
    if (scrollToRow?.index != undefined) {
      this._gridApi.ensureIndexVisible(scrollToRow.index, 'top');
      const rowNode = this._gridApi.getDisplayedRowAtIndex(scrollToRow.index);
      if (rowNode) {
        this._gridApi.flashCells({
          rowNodes: [rowNode]
        });
      }
    }
  }

  get selectedRow() { return this.grid?.selectedRow; }
  get selectedRows() { return this.grid?.selectedRows ?? []; }

  @Output() showMore = new EventEmitter<void>();
  @Output() gridApi = new EventEmitter<GridApi>();
  @Output() rowsChanged = new EventEmitter<unknown[]>();
  @Output() rowClickedEvent = new EventEmitter<RowClickedEvent>();
  @Output() rowSelectedEvent = new EventEmitter<RowSelectedEvent>();
  @Output() cellClickedEvent = new EventEmitter<CellClickedEvent>();
  @Output() columnsChanged = new EventEmitter<Array<Column>>();
  @Output() selectionChanged = new EventEmitter<SelectionChangedEvent>();


  // Services
  private readonly gridService = inject(GridService);
  private readonly symbolsToValuesService = inject(SymbolsToValuesService);
  private readonly tradableService = inject(TradableService);
  private readonly tradingService = inject(TradingService);
  private readonly userSettingsService = inject(UserSettingsService);
  private readonly pickedInstrumentService = inject(PickedInstrumentsService);

  autosizeColumnsAllowed = true;
  private readonly columnsChangedAction = new Subject<Column[]>();
  private readonly symbolsAction = new ReplaySubject<SymbolDataItem[]>(1);
  private readonly plainDataAction = new ReplaySubject<DataValue[]>(1);
  selectedColumns$: Observable<Column[]>;
  columnsChangedListener$: Observable<Column[]>;
  data$: Observable<DataValue[]>;
  streamRequest$: Observable<void>;

  private grid$: Observable<Grid>;
  private staticGrid$: Observable<Grid>;
  private _gridApi: GridApi;

  readonly contextAction = new LastValueSubject<unknown>();
  readonly context$ = combineLatest([
    this.contextAction.pipe(startWith({})),
    this.gridService.requestGridCellRefresh$.pipe(startWith(undefined)),
    this.tradingService.hasTradingFeature$.pipe(startWith(false)),
    this.tradingService.tradingConnected$.pipe(startWith(false)),
    this.tradingService.activePortfolio$.pipe(startWith(undefined)),
  ]).pipe(
    tap(([_context, requestRefresh, hasTradingFeature, tradingConnected, _activePortfolio]) => {
      if (!!requestRefresh || (this.lastHasTradingFeature !== hasTradingFeature) || (this.lastTradingConnected !== tradingConnected)) {
        this.lastHasTradingFeature = hasTradingFeature;
        this.lastTradingConnected = tradingConnected;
        // on trading-connection change re-render all cells and especially their cellClass!
        setTimeout(() => {
          this._gridApi?.refreshCells({ force: true, suppressFlash: true });
        }, 500);
      }
    }),
    map(([context, _requestRefresh, hasTradingFeature, tradingConnected, activePortfolio]) => {
      return {
        ...context as object,
        tradingState: {
          hasTradingFeature,
          isConnected: tradingConnected,
          activePortfolio,
        },
        tradableService: this.tradableService,
        userSettingsService: this.userSettingsService,
      };
    }),
  );

  private lastHasTradingFeature = false;
  private lastTradingConnected = false;

  constructor() {
    this.gridRef = inject(GridRef, { optional: true })!;
  }

  ngOnInit(): void {
    if (this.inputValidation()) {
      throw new Error(this.inputValidation());
    }
    this.grid$ = this.gridRef.grid$.pipe(filterUndefined());

    this.staticGrid$ = this.grid$.pipe(distinctUntilKeyChanged('id'));

    let colsForDistinctCompare: ColumnSetting[];

    this.selectedColumns$ = this.grid$.pipe(
      switchMap((grid) => {
        return this.gridRef.selectedColumns$;
      }),
      filter((newCols) => !isSameObject(getColumnSettingsFromColDef(newCols), colsForDistinctCompare)), // cant get distinctCompare to work on array
      tap((cols: Column[]) => {
        colsForDistinctCompare = getColumnSettingsFromColDef(cols);
      })
    );

    const resolvedSymbols$ = this.grid$.pipe(
      switchMap((_grid) =>
        this.symbolsAction.pipe(
          filterUndefined(),
          switchMap((symbols) =>
            this.filterFromSymbols ? this.valuesFromMixedSource$(symbols) : this.snapshot$(symbols)
          )
        )
      )
    );

    this.columnsChangedListener$ = this.staticGrid$.pipe(
      switchMap((grid) =>
        this.columnsChangedAction.pipe(
          tap((selectedColumns) => {
            const settings = { ...grid.settings, selectedColumns };
            this.gridRef.onColumnsChanged({ ...grid, settings });
          })
        )
      )
    );

    this.data$ = merge(resolvedSymbols$, this.plainDataAction);

    this.streamRequest$ = combineLatest([this.gridApi, this.data$, this.symbolsAction]).pipe(
      filter(([_, data, symbols]) => !!symbols && !!data),
      switchMap(([api]) => this.staticGrid$.pipe(
        tap((grid) => this.symbolsToValuesService.startStream(grid.id, api)),
        switchMap(() => this.symbolsToValuesService.stream$
        ))));
  }

  ngOnDestroy(): void {
    this.columnsChangedAction.complete();
    this.symbolsAction.complete();
    this.plainDataAction.complete();
    this.contextAction.complete();
    if (!this._gridApi?.isDestroyed()) {
      this._gridApi?.destroy();
    }
    (this._gridApi as GridApi | undefined) = undefined;
  }

  private readonly snapshot$ = (symbols: SymbolDataItem[]) => {
    this.symbolsToValuesService.snapshot(this.gridRef, symbols);
    return this.symbolsToValuesService.snapshot$;
  };

  onColumnsChanged(selectedColumns: Column[]): void {
    this.columnsChangedAction.next(selectedColumns);
  }

  onGridApi(api: GridApi): void {
    this._gridApi = api;
    this.gridApi.emit(api);
  }

  readonly pickedInstruments$ = this.pickedInstrumentService.instruments$.pipe(tap((instruments) => {
    // pickedInstruments gets triggered when the user selects a row by checkbox
    // now we have to update the the selection in the grid
    this.setAgGridSelections();
  }));

  private setAgGridSelections() {
    const pickedInstruments = this.pickedInstrumentService.instruments();
    this._gridApi?.forEachNode((rowNode) => {
      const isSelected = pickedInstruments.some((inst) => isSameInstrument(inst, rowNode.data as InstrumentLike));
      rowNode.setSelected(isSelected);
    });
  }

  onSelectionChanged(event: SelectionChangedEvent): void {
    if (event.source === 'rowDataChanged') { // on rowDataChanged we loose our current ag grid selection and have to reapply it
      this.setAgGridSelections();
      return;
    }
    // selectionChanged gets triggered by the grid when the user selects a row by shift and mouse click
    // now we have to update the the selection in pickedInstruments
    const mouseClickSelections = event.api.getSelectedRows() as InstrumentLike[];
    this.pickedInstrumentService.setInstruments(mouseClickSelections);
    this.selectionChanged.emit(event);
  }



  /* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument */
  private valuesFromMixedSource$ = (symbols: SymbolDataItem[] | any[]): Observable<DataValue[] | any[]> => {
    // corner case to pass through some rows without resolving as symbols. (Use case: subHeaders, marketOverviewComponent)
    const passThroughItems = symbols
      .map((item, index) => {
        if (item[this.filterFromSymbols.key] === this.filterFromSymbols.value) {
          return { index, item };
        }
        return undefined;
      })
      .filter((itm) => !!itm);
    const filteredSymbols = symbols.filter((item) => item[this.filterFromSymbols.key] !== this.filterFromSymbols.value);

    return this.snapshot$(filteredSymbols).pipe(
      map((dataValues) => {
        const result = passThroughItems.reduce((acc, item) => {
          acc.splice(item!.index, 0, item!.item);
          return acc;
        }, dataValues);
        return result;
      })
    );
  };
  /* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument */


  // todo: formalize how errors are thrown, its useful to throw helpful errors to the devs in application wide infrastructure like this grid wrapper.
  private inputValidation(): string {
    if (!this.gridRef) {
      return 'symbols-grid: gridRef must be set as an input or as an ambient service on symbols-grid ';
    }
    return '';
  }
}
