import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CellClickedEvent, GridApi, GridOptions, RowClickedEvent, SelectionChangedEvent } from 'ag-grid-community';

import { Column, GridSettings } from '../../../shared/grid/columns.model';

@Component({
  selector: 'wt-static-columns-grid',
  templateUrl: './static-columns-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// a simpler type of grid wrapper that doesnt use settings from the widget for the columns and doesnt allow the user to sort, resize, reorder or use column picker on columns
// for fully or partly dynamic columns use dynamicColumnGrid
export class StaticColumnsGridComponent {

  @Input() flushTransactions = true;

  @Input() set data(value: unknown[]) {
    if (this.flushTransactions) {
      // we need to flush the old grid-data, before the incoming new grid-data is set (and bound),
      // else old and new grid-data will be mixed (sic!) for whatever clever reasons ag-grid might have!
      // the official way (as of writing) to flash the grid-data is by setRowData to empty array.
      this._gridApi?.flushAsyncTransactions();
      this._gridApi?.setGridOption('rowData', []);
    }
    this.gridData = value;
  }
  @Input() suppressHorizontalScroll: boolean;
  @Input() columns: Column[];

  @Input() gridSettings: GridSettings = {};
  @Input() gridOptions: GridOptions;
  @Input() context: unknown;

  @Output() rowClickedEvent = new EventEmitter<RowClickedEvent>();
  @Output() cellClickedEvent = new EventEmitter<CellClickedEvent>();
  @Output() selectionChanged = new EventEmitter<SelectionChangedEvent>();
  @Output() showMore = new EventEmitter<void>();
  @Output() gridApi = new EventEmitter<GridApi>();
  @Output() columnsChanged = new EventEmitter<Column[]>();

  gridData: Array<unknown>;

  private _gridApi: GridApi;

  constructor() { }

  onGridApi(api: GridApi): void {
    this._gridApi = api;
    this.gridApi.emit(api);
  }

  // NOTE: Keep for now, maybe we need some refreshCells in future?
  // private refreshByColumns(columns: string[]): void {
  //   if (!columns) {
  //     return;
  //   }
  //   this._gridApi?.refreshCells({ columns });
  // }
}
