import { Injectable, inject } from '@angular/core';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { InfrontSDK } from '@infront/sdk';
import { LogService } from '@vwd/ngx-logging';
import { BehaviorSubject, EMPTY, NEVER, Subject, combineLatest, iif, of } from 'rxjs';
import { skip, switchMap, take, tap, timeout, withLatestFrom } from 'rxjs/operators';

import { OrderEntryManager, OrderEntryWidget } from '@infront/wtk/widgets/order-entry';
import { OrderDeleteParams, OrderEntryParams } from '../shared/models/trading.model';
import { instrumentsAreEqual } from '../util/sdk';
import { SdkService } from './sdk.service';
import { StoreService } from './store.service';
import { TradableService } from './tradable.service';
import { TradingLoginDialogEvent, TradingService } from './trading.service';

@Injectable({
  providedIn: 'root',
})
export class TradingOrderEntryService {
  private readonly storeService: StoreService = inject(StoreService);
  private readonly tradingService: TradingService = inject(TradingService);
  private readonly sdkService: SdkService = inject(SdkService);
  private readonly tradableService: TradableService = inject(TradableService);

  private readonly logger = inject(LogService).openLogger('services/trading-order-entry');

  private readonly orderEntryRefAction = new BehaviorSubject<OrderEntryWidget | undefined>(undefined);
  readonly orderEntryRef$ = this.orderEntryRefAction.asObservable();

  private readonly tryShowOrderEntryAction = new Subject<boolean>();
  private readonly showOrderEntryAction = new LastValueSubject<boolean>();
  readonly showOrderEntry$ = this.showOrderEntryAction.asObservable();

  private readonly tryParamsAction = new Subject<OrderEntryParams | undefined>();
  // Note below: Doesn't necessarily hold the actual state of the OrderEntryWidget
  // It's only the latest sent param state, the widget itself is capable of manipulating it's state but this isn't reflected
  private readonly paramsAction = new BehaviorSubject<OrderEntryParams | undefined>(undefined);
  readonly params$ = this.paramsAction.asObservable();

  constructor() {
    // Guard params - check if provided instrument is tradable
    this.tryParamsAction.pipe(
      withLatestFrom(this.orderEntryRef$),
      switchMap(([tryParams, orderEntryRef]) => {
        if (!tryParams || !orderEntryRef) {
          return of(tryParams);
        }

        const orderEntryManager = orderEntryRef['orderEntryManager'] as OrderEntryManager;
        const { currentInstrument, priceBinding, volumeBinding, options } = orderEntryManager;
        const { modifyOrderId, modifyPortfolio, instrument, initialVolume: volume, initialPrice: price } = tryParams;
        const hasOrder = modifyOrderId != undefined && modifyPortfolio != undefined;
        const { modifyOrderId: currentOrderId, modifyPortfolio: currentPortfolio } = options;
        const isSameOrder = hasOrder && currentOrderId === modifyOrderId && modifyPortfolio === currentPortfolio;

        // We can update existing WidgetRef if we don't have an order and the instruments are equal
        // or if we have an order and the incoming params order is the same as the current one
        if (!hasOrder ? instrumentsAreEqual(currentInstrument, instrument) : isSameOrder) {
          const oderEntryVolume = orderEntryManager['currentVolume'] as number | undefined;
          const orderEntryPrice = orderEntryManager['currentPrice'] as number | undefined;
          if (volume !== undefined && oderEntryVolume != volume && volumeBinding?.isEnabled()) {
            orderEntryRef.setVolume(volume ?? 0); // nullish fallback
          }
          if (price !== undefined && orderEntryPrice != price && priceBinding?.isEnabled()) {
            orderEntryRef.setPrice(price ?? 0); // nullish fallback
          }
          return EMPTY;
        }

        return of(tryParams);
      }),
      switchMap((tryParams) => {
        // if instrument is set, check tradable
        const instrument = tryParams?.instrument;
        if (instrument) {
          return this.tradableService.isTradable$({ instrument }).pipe(
            take(1),
            switchMap((isTradable) => isTradable ? of(tryParams) : of(undefined))
          );
        }
        return of(tryParams);
      }),
      tap((validParams) => this.paramsAction.next(validParams)), // Guard passed
    ).subscribe();

    // Automatically reset params when OrderEntry is closed
    this.showOrderEntry$.pipe(
      tap((show) => !show && this.paramsAction.next(undefined))
    ).subscribe();

    // Close OrderEntry when TradingConnection is closed/lost and the OrderEntry is currently opened
    combineLatest([this.tradingService.tradingConnected$, this.showOrderEntryAction])
      .pipe(
        tap(([connected, show]) => !connected && show && this.showOrderEntryAction.next(false))
      ).subscribe();

    this.tryShowOrderEntryAction.pipe(
      withLatestFrom(this.tradingService.tradingConnected$),
      switchMap(([tryShow, connected]) =>
        iif(() => tryShow && !connected,
          // Not logged into trading and OrderEntry should be shown, display TradingLoginDialog
          this.tradingService.openTradingLoginDialog$.pipe(
            take(1),
            switchMap(() => this.tradingService.tradingLoginDialogEvent$),
            skip(1),
            take(1),
            // We can only continue when we successfully connect
            switchMap((tradingLoginDialogEvent) => tradingLoginDialogEvent === TradingLoginDialogEvent.CONNECTED ? of(tryShow) : NEVER),
          ),
          // Already logged into trading
          of(tryShow)
        )
      ),
      tap((show) => this.showOrderEntryAction.next(show)),
    ).subscribe();
  }

  setOrderEntryRef(ref: OrderEntryWidget | undefined): void {
    this.orderEntryRefAction.next(ref);
  }

  openOrderEntry(params?: OrderEntryParams): void {
    this.tryParamsAction.next(params ?? this.getDashboardInstrumentAsParams());
    this.tryShowOrderEntryAction.next(true);
  }

  closeOrderEntry(): void {
    this.tryShowOrderEntryAction.next(false); // params are cleared automatically on close
  }

  toggleOrderEntry(params?: OrderEntryParams): void {
    const tryShow = !this.showOrderEntryAction.getValue();

    // params are cleared on close automatically, we only set params when OrderEntry is opened
    if (tryShow) {
      this.tryParamsAction.next(params ?? this.getDashboardInstrumentAsParams());
    }

    this.tryShowOrderEntryAction.next(tryShow);
  }

  isOpen(): boolean {
    return !!this.showOrderEntryAction.getValue();
  }

  getParams(): OrderEntryParams | undefined {
    return this.paramsAction.getValue();
  }

  private getDashboardInstrumentAsParams(): OrderEntryParams | undefined {
    const dashboard = this.storeService.currentDashboard;
    return dashboard ? { instrument: this.storeService.getDashboardInstrument(dashboard) } : undefined;
  }

  // deleteOrder would fit into trading-order.service and trading-order-entry.service.
  // I have decided that it is more related to create/modify order-entry, so placed here.
  deleteOrder(params: OrderDeleteParams): void {
    if (!params?.portfolio || !params?.orderId) {
      this.logger.error('deleteOrderEntry request with invalid portfolio or orderId!', params.portfolio, params.orderId);
      return;
    }
    this.tradingService.tradingConnected$.pipe(
      // force close this one-shoot pipe after 30s to prevent corner-case chances for memory leak!
      timeout(30000),
      take(1),
      withLatestFrom(this.tradingService.connectionState$),
      switchMap(([connected, connectionState]) => {
        if (!connected) {
          this.logger.error('deleteOrder request, but not connected!', connected);
          return EMPTY;
        }
        if (connectionState?.providerId == undefined) {
          this.logger.error('deleteOrder request with invalid providerId!', connectionState, connectionState?.providerId);
          return EMPTY;
        }
        const options: InfrontSDK.Trading.OrderEntryOptions = {
          providerId: connectionState.providerId,
          action: InfrontSDK.Trading.OrderEntryAction.DeleteOrder,
          portfolio: params.portfolio,
          orderId: String(params.orderId),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          onData: (result) => console.log('deleteOrder DONE:', typeof result === 'object' ? { result } : result, { options }),
          onError: (e) => console.error('deleteOrder ERROR:', e),
        };
        this.logger.debug('deleteOrder request with options:', { options });
        return this.sdkService.getObject$(InfrontSDK.Trading.orderEntry, options);
      }),
    ).subscribe();
  }

}
