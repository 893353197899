import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { isToday } from '../../util/date';
import { OhlcValueItem } from '../../shared/models/performance.model';
import { PerformanceBarSettings } from './performance-bar.model';

const DefaultSettings: PerformanceBarSettings = {
  direction: 'Vertical',
  thickness: 14,
  markerHeight: 15,
  markerThickness: 2,
  showLabels: false,
};

@Component({
  selector: 'wt-performance-bar',
  templateUrl: './performance-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceBarComponent implements OnChanges {
  @Input() ohlc: OhlcValueItem;
  @Input() settings: PerformanceBarSettings = DefaultSettings;
  @Input() decimals: number;
  @Input() time: Date | undefined;
  @Input() hideOutdated = false;
  @Input() hideMarkerOnDisabled = false;
  @Input() forceDisabled = false;

  downPercent: number | undefined;
  markerPosition: number | undefined;
  disabled = false;
  hidden = false;
  hideMarker = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ohlc) {
      this.hidden = this.hideOutdated && !isToday(this.time);

      this.downPercent = this.percentage(this.ohlc.high, this.ohlc.low, this.ohlc.open);
      this.markerPosition = this.percentage(this.ohlc.high, this.ohlc.low, this.ohlc.close);

      this.disabled = this.forceDisabled || (this.downPercent == undefined) || (this.markerPosition == undefined);
      this.hideMarker = this.hideMarkerOnDisabled && this.disabled;

      this.downPercent = this.disabled ? 50 : this.downPercent;
      this.markerPosition = this.disabled ? 50 : this.markerPosition;
    }
  }

  private percentage = (high: number, low: number, compareValue: number): number | undefined => {
    if ((high == undefined) || (low == undefined) || (compareValue == undefined)) { return undefined; }

    const span = high - low;
    const partInUnits = compareValue - low;
    const partInPercent = span ? (100 * partInUnits) / span : undefined;
    return partInPercent;
  };
}
