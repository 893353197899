import { MarketOverviewTemplate } from '../market-overview.model';

export const TemplateBelgium: MarketOverviewTemplate[] = [
  {
    tab: 'Indices',
    instrumentList: [
      { feed: 2089, ticker: 'BEL20' },
      { feed: 2094, ticker: 'BELM' },
      { feed: 2094, ticker: 'BELS' },
      { feed: 2088, ticker: 'SPEURO' },
      { feed: 2161, ticker: 'SX5E' },
      { feed: 2206, ticker: 'ESFUT1' },
      { feed: 2089, ticker: 'PX1' },
      { feed: 2089, ticker: 'AEX' },
      { feed: 2098, ticker: 'DAX' },
      { feed: 2302, ticker: 'BUK100P' },
      { feed: 20, ticker: 'DJI' },
      { feed: 2087, ticker: 'COMP' },
      { feed: 2087, ticker: 'NDX' },
      { feed: 21, ticker: 'NQFUT1' },
      { feed: 21, ticker: 'ESFUT1' },
      { feed: 6150, ticker: 'N225' },
      { feed: 2198, ticker: 'HSI' },
      { feed: 2088, ticker: 'SPASIA50' },
      { feed: 2162, ticker: 'RTSI' },
      { feed: 2088, ticker: 'SP500' },
      { feed: 6330, ticker: 'VIX' },
    ],
  },
  {
    tab: 'Forex',
    instrumentList: [
      { feed: 12, ticker: 'EURUSD' },
      { feed: 12, ticker: 'EURGBP' },
      { feed: 12, ticker: 'EURJPY' },
      { feed: 12, ticker: 'EURCHF' },
      { feed: 12, ticker: 'EURRUB' },
      { feed: 12, ticker: 'EURCNY' },
      { feed: 12, ticker: 'EURHKD' },
      { feed: 12, ticker: 'EURBRL' },
      { feed: 12, ticker: 'USDJPY' },
      { feed: 12, ticker: 'GBPUSD' },
      { feed: 12, ticker: 'GBPEUR' },
    ],
  },
  {
    tab: 'FixedIncome',
    instrumentList: [
      { feed: 2227, ticker: 'TMBMKDE-05Y' },
      { feed: 2227, ticker: 'TMBMKDE-10Y' },
      { feed: 2206, ticker: 'EDFUT1' },
      { feed: 2280, ticker: 'IFUT1' },
      { feed: 2158, ticker: 'BMDE-10Y' },
      { feed: 2158, ticker: 'BMDE-30Y' },
      { feed: 2158, ticker: 'BMUS-10Y' },
      { feed: 2158, ticker: 'BMUS-30Y' },
      { feed: 2158, ticker: 'BMGB-10Y' },
    ],
  },
  {
    tab: 'Commodities',
    instrumentList: [
      { feed: 2229, ticker: 'BRT' },
      { feed: 2229, ticker: 'WTI' },
      { feed: 2148, ticker: 'AH3M' },
      { feed: 2148, ticker: 'CA3M' },
      { feed: 2148, ticker: 'ZS3M' },
      { feed: 2159, ticker: 'SLVSPOT' },
      { feed: 2159, ticker: 'GLDSPOT' },
    ],
  }
];
