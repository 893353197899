<div class="wt-feed-filter">
  <div class="wt-feed-filter__sources">Filter sources</div>
  <ng-container *ngIf="widget$ | async as widget">
    <ng-container *ngIf="(feedFilterItems$ | async) as feedFilterItems">
      <div *ngIf="allSelectedObj$ | async as allSelectedObj">
        <mat-checkbox [checked]="allSelectedObj.allSelected"
          [indeterminate]="someSelected(feedFilterItems, allSelectedObj.allSelected)"
          (change)="setAll(widget,$event.checked, feedFilterItems)">
          (Select all)
        </mat-checkbox>
      </div>
      <div *ngFor="let item of feedFilterItems">
        <mat-checkbox
          class="wt-feed-filter__source"
          [(ngModel)]="item.selected"
          (ngModelChange)="onFilterChanged(widget, feedFilterItems, item)"
          [ngClass]="{ 'wt-feed-filter__source--inactive': !item.active }">
          {{ item.longName }} ({{ item.shortName }})
        </mat-checkbox>
      </div>
    </ng-container>
  </ng-container>
</div>
