import { of, take, tap } from 'rxjs';
import { AlertService } from '../../../../services/alert.service';
import { WatchlistService } from '../../../../services/watchlist.service';
import { ContextMenuItem } from '../../../../shared/models/context-menu.model';
import { WatchlistWindow } from '../../../../state-model/window.model';
import { filterUndefined } from '../../../../util/rxjs';

export const addAlertItem = (alertService: AlertService): ContextMenuItem => ({
  id: 'addAlert',
  title: 'CONTEXT_MENU_COMPONENT.ALERT.ADD_ALERT',
  icon: 'add_alert',
  closeOnClick: true,
  isSVGIcon: true,
  onClick: (params) => {
    if (params.instrument) {
      alertService.openAlertDialog({ alertType: 'instrument', instrument: params.instrument });
    }
  },
});

export const addListAlert = (alertService: AlertService, watchlistService: WatchlistService): ContextMenuItem => ({
  id: 'addListAlert',
  title: 'CONTEXT_MENU_COMPONENT.ALERT.ADD_LIST_ALERT',
  icon: 'add_alert',
  closeOnClick: true,
  isSVGIcon: true,
  onClick: (params) => {
    if (params.window?.name === 'WatchlistWindow' && (params.window as WatchlistWindow).settings?.selectedWatchlist) {
      watchlistService.selectedWatchlistInWindow$(of(params.window as WatchlistWindow)).pipe(
        take(1),
        filterUndefined(),
        tap(watchlist => {
          alertService.openAlertDialog({ alertType: 'watchlist', watchlist: watchlist.title });
        })).subscribe();
    }
  },
});
