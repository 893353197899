import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { PricePipesModule } from '@vwd/ngx-price-pipes';

import { LabelListComponent } from './label-list.component';
import { PipesModule } from '../../pipes/pipes.module';
import { BigNumberModule } from '../big-number/big-number.module';

@NgModule({
  declarations: [LabelListComponent],
  imports: [
    CommonModule,
    BigNumberModule,
    PricePipesModule,
    PipesModule,
    I18nPipesModule,
    MatIconModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [LabelListComponent],
})
export class LabelListModule { }
