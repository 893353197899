import { InfrontSDK } from '@infront/sdk';

export type MarketOverviewDropdownType = 'Market' | 'News' | 'Standalone';

const NEWS_DATA_TYPES: Readonly<string[]> = ['NEWS', 'RESEARCH'];
const MARKET_DATA_TYPES: Readonly<string[]> = [
  'BONDS',
  'COMMODITIES',
  'EUROOPTIONS',
  'FOREX',
  'FUNDS',
  'FUTURES',
  'FX_DEPOSIT_RATES',
  'INDICES',
  'MTF',
  'ORDERS',
  'STOCKS',
  'USOPTIONS',
  'WARRANTS',
];

export const FilterMap = (sources: InfrontSDK.FeedInfo[]): { [key in MarketOverviewDropdownType]: InfrontSDK.FeedInfo[] } => ({
  Standalone: sources.filter(
    (feed: InfrontSDK.FeedInfo) => feed && feed.dataTypes.some((dataType) => MARKET_DATA_TYPES.includes(dataType)) && !feed.isHidden
  ),
  Market: sources.filter(
    (feed: InfrontSDK.FeedInfo) => feed && feed.dataTypes.some((dataType) => MARKET_DATA_TYPES.includes(dataType)) && !feed.isHidden
  ),
  News: sources.filter(
    (feed: InfrontSDK.FeedInfo) => feed && feed.dataTypes.some((dataType) => NEWS_DATA_TYPES.includes(dataType)) && !feed.isHidden
  ),
});

export const MarketHeaderMetadataMap: { [key in MarketOverviewDropdownType]: { title: string; className: string }[] } = {
  Standalone: [
    {
      title: 'MARKET_OVERVIEW_DROPDOWN.HEADER.STANDALONE.SHORTCODE',
      className: 'wt-market-overview-dropdown__header-source',
    },
    {
      title: 'MARKET_OVERVIEW_DROPDOWN.HEADER.STANDALONE.ACCESS',
      className: 'wt-market-overview-dropdown__header-access',
    },
    {
      title: 'MARKET_OVERVIEW_DROPDOWN.HEADER.STANDALONE.TRADING',
      className: 'wt-market-overview-dropdown__header-trading',
    },
  ],
  Market: [
    {
      title: 'MARKET_OVERVIEW_DROPDOWN.HEADER.MARKET.SHORTCODE',
      className: 'wt-market-overview-dropdown__header-source',
    },
    {
      title: 'MARKET_OVERVIEW_DROPDOWN.HEADER.MARKET.ACCESS',
      className: 'wt-market-overview-dropdown__header-access',
    },
    {
      title: 'MARKET_OVERVIEW_DROPDOWN.HEADER.MARKET.TRADING',
      className: 'wt-market-overview-dropdown__header-trading',
    },
  ],
  News: [
    {
      title: 'MARKET_OVERVIEW_DROPDOWN.HEADER.NEWS.SHORTCODE',
      className: 'wt-market-overview-dropdown__header-source',
    },
    {
      title: 'MARKET_OVERVIEW_DROPDOWN.HEADER.NEWS.ACCESS',
      className: 'wt-market-overview-dropdown__header-access',
    },
  ],
};
