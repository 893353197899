import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { ColumnPickerModule } from '../column-picker/column-picker.module';
import { ColumnPickerDialogComponent } from './column-picker-dialog.component';

@NgModule({
  declarations: [ColumnPickerDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ColumnPickerModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [ColumnPickerDialogComponent]
})
export class ColumnPickerDialogModule { }
