import { ChangeDetectionStrategy, Component, OnDestroy, inject } from '@angular/core';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

import { StoreService } from '../../../services/store.service';
import { DeselectableFeedsWidgetName, Widget, isDeselectableFeedsWidget } from '../../../state-model/widget.model';
import { structuresAreEqual } from '../../../util/equality';
import { guard } from '../../../util/rxjs';
import { CalendarService } from '../../calendar/calendar.service';
import { FeedFilterItem, ItemWithFeed } from '../../../typings/models/feed-filterable';
import { NewsService } from '../news.service';

@Component({
  selector: 'wt-feed-filter',
  templateUrl: './feed-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedFilterComponent implements OnDestroy, IFilterAngularComp {

  private readonly storeService: StoreService = inject(StoreService);
  private readonly newsService: NewsService = inject(NewsService);
  private readonly calendarService: CalendarService = inject(CalendarService);

  private readonly paramsAction = new LastValueSubject<IFilterParams>();
  readonly params$ = this.paramsAction.asObservable().pipe(
    shareReplay(1),
  );

  readonly widget$ = this.params$.pipe(
    map((params) => params.context as Widget),
    switchMap((widget) => this.storeService.widget$(widget)),
    // distinctUntilChanged((prev, next) => structuresAreEqual(prev, next)),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    guard(isDeselectableFeedsWidget),
    shareReplay(1),
  );

  private readonly deselectedFeedsAction = new LastValueSubject<number[] | undefined>();
  private readonly feedFilterItemsAction = new LastValueSubject<FeedFilterItem[]>();
  readonly feedFilterItems$ = this.feedFilterItemsAction.asObservable().pipe(
    distinctUntilChanged((prev, next) => prev.length === next.length && prev.every((prevFeedItem) =>
      next.some((nextFeedItem) => structuresAreEqual(prevFeedItem, nextFeedItem)))
    ),
    tap((feedFilterItems) => {
      this.allSelectedAction.next(feedFilterItems.every((item) => item.selected === true));
    }),
    shareReplay(1)
  );

  private readonly allSelectedAction = new ReplaySubject<boolean>(1);
  readonly allSelectedObj$ = this.allSelectedAction.asObservable().pipe(
    map((allSelected) => ({ allSelected })),
    shareReplay(1),
  );

  private filterSourceMap: { [key in DeselectableFeedsWidgetName]: Observable<FeedFilterItem[]> } = {
    'News': this.newsService.feedFilterItems$,
    'Calendar': this.calendarService.feedFilterItems$,
  };

  private ngUnsubscribe = new Subject<void>();

  constructor() {
    // trigger filter
    // this.params$.pipe(
    //   takeUntil(this.ngUnsubscribe),
    // ).subscribe();

    // update feedFilter (deselectedFeeds) by window
    this.widget$.pipe(
      distinctUntilChanged((prev, next) => structuresAreEqual(prev.settings.deselectedFeeds, next.settings.deselectedFeeds)),
      tap(widget => {
        this.deselectedFeedsAction.next(widget.settings.deselectedFeeds);
      }),
      switchMap((widget) => this.filterSourceMap[widget.name].pipe(
        tap((feedFilterItems) => {
          const newItems = feedFilterItems.map((item) => ({ ...item, selected: !widget.settings.deselectedFeeds?.includes(item.feed) }));
          if (!structuresAreEqual(newItems, this.feedFilterItemsAction.value)) {
            this.feedFilterItemsAction.next(newItems);
          }
        }),
      )),
      // take(1),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();
  }

  // Do not remove, part of ag-grid
  agInit(params: IFilterParams): void {
    this.paramsAction.next(params);
  }

  ngOnDestroy(): void {
    this.paramsAction.complete();
    this.deselectedFeedsAction.complete();
    this.feedFilterItemsAction.complete();
    this.allSelectedAction.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // Do not remove, part of ag-grid, called by filterChangedCallback
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const data = params.data as ItemWithFeed;
    if (data.feed == undefined) {
      return false;
    }

    const feedFilterItems = this.feedFilterItemsAction.getValue();

    return (feedFilterItems
      ? feedFilterItems.filter((item) => item.selected)
        .map((item) => item.feed)
        .includes(data.feed)
      : false
    );
  }

  onFilterChanged(widget: Widget, items: FeedFilterItem[], item: FeedFilterItem): void {
    this.feedFilterItemsAction.next(items);

    const oldDeselectedFeeds = this.deselectedFeedsAction.value;
    const newDeselectedFeeds =
      items.every(item => item.selected) ? undefined : items.filter(feed => !feed.selected).map(feed => feed.feed);

    if (!structuresAreEqual(oldDeselectedFeeds, newDeselectedFeeds)) {
      this.storeService.updateWidget(widget, { settings: { ...widget.settings, deselectedFeeds: newDeselectedFeeds } });
    }

    this.paramsAction.getValue()?.filterChangedCallback();
  }

  someSelected(feedFilterItems: FeedFilterItem[], allSelected: boolean): boolean {
    if (!feedFilterItems || allSelected) {
      return false;
    }
    return feedFilterItems.some((item) => item.selected);
  }

  setAll(widget: Widget, checked: boolean, feedFilterItems: FeedFilterItem[]): void {
    this.feedFilterItemsAction.next(feedFilterItems.map((item) => ({ ...item, selected: checked })));

    const deselectedFeeds = checked ? undefined : feedFilterItems.map(feed => feed.feed);
    this.deselectedFeedsAction.next(deselectedFeeds);
    this.storeService.updateWidget(widget, { settings: { ...widget.settings, deselectedFeeds } });

    this.paramsAction.getValue()?.filterChangedCallback();
  }

  isFilterActive(): boolean {
    return !!this.deselectedFeedsAction.value;
  }

  getModel() {
    return this.deselectedFeedsAction.value;
  }

  setModel(model: number[]): void {
    if (!structuresAreEqual(model, this.deselectedFeedsAction.value)) {
      this.deselectedFeedsAction.next(model);
    }
  }
}
