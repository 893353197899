import { MarketOverviewTemplate } from '../market-overview.model';

export const TemplateNorway: MarketOverviewTemplate[] = [
  {
    tab: 'Indices',
    instrumentList: [
      { feed: 18177, ticker: 'OBX' },
      { feed: 18177, ticker: 'OSEBX' },
      { feed: 17921, ticker: 'OMXS30' },
      { feed: 100, ticker: 'OMXH25' },
      { feed: 17665, ticker: 'OMXC20' },
      { feed: 18009, ticker: 'OMXN40' },
      { feed: 2161, ticker: 'SX5E' },
      { feed: 2098, ticker: 'DAX' },
      { feed: 2302, ticker: 'BUK100P' },
      { feed: 2089, ticker: 'PX1' },
      { feed: 2162, ticker: 'RTSI' },
      { feed: 20, ticker: 'DJI' },
      { feed: 2087, ticker: 'COMP' },
      { feed: 2087, ticker: 'NDX' },
      { feed: 2088, ticker: 'SP500' },
      { feed: 21, ticker: 'NQFUT1' },
      { feed: 21, ticker: 'ESFUT1' },
      { feed: 6330, ticker: 'VIX' },
      { feed: 6150, ticker: 'N225' },
      { feed: 2198, ticker: 'HSI' },
      { feed: 6460, ticker: 'J200' },
      { feed: 2088, ticker: 'SPASIA50' },
    ],
  },
  {
    tab: 'Forex',
    instrumentList: [
      { feed: 12, ticker: 'USDNOK' },
      { feed: 12, ticker: 'EURNOK' },
      { feed: 12, ticker: 'CHFNOK' },
      { feed: 12, ticker: 'SEKNOK' },
      { feed: 12, ticker: 'GBPNOK' },
      { feed: 12, ticker: 'DKKNOK' },
      { feed: 12, ticker: 'JPYNOK' },
      { feed: 12, ticker: 'EURUSD' },
      { feed: 12, ticker: 'USDJPY' },
      { feed: 2263, ticker: 'I44' }
    ],
  },
  {
    tab: 'FixedIncome',
    instrumentList: [
      { feed: 18186, ticker: 'NIBOR1W' },
      { feed: 18186, ticker: 'NIBOR1M' },
      { feed: 18186, ticker: 'NIBOR3M' },
      { feed: 2158, ticker: 'BMNO-10Y' },
      { feed: 2158, ticker: 'BMDE-10Y' },
      { feed: 2158, ticker: 'BMDE-30Y' },
      { feed: 2158, ticker: 'BMUS-10Y' },
      { feed: 2158, ticker: 'BMUS-30Y' },
      { feed: 2158, ticker: 'BMGB-10Y' },
    ],
  },
  {
    tab: 'Commodities',
    instrumentList: [
      { feed: 2229, ticker: 'BRT' },
      { feed: 2229, ticker: 'WTI' },
      { feed: 2148, ticker: 'AH3M' },
      { feed: 2148, ticker: 'CA3M' },
      { feed: 2264, ticker: 'Spot' },
      { feed: 2159, ticker: 'SLVSPOT' },
      { feed: 2159, ticker: 'GLDSPOT' },
    ],
  }
];
