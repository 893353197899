import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'wt-admin-dialog',
  templateUrl: './admin-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminDialogComponent {

  constructor(
  ) {
  }
}
