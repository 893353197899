export function valueToClass(inValue: number | undefined): string {
  const value = inValue != undefined && !isNaN(inValue) ? +inValue : 0;
  if (value > 0) {
    return 'up-indicator';
  }
  if (value < 0) {
    return 'down-indicator';
  }
  return 'unchanged-indicator';
}
