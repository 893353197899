<form class="wt-dialog" method="dialog">
  <div class="wt-dialog">
    <div class="wt-flex-row wt-flex-align-middle wt-flex-collapse wt-dialog__title">
      <div class="wt-flex-column wt-flex-shrink wt-dialog-icon">
        <mat-icon class="mat-icon" fontIcon="install_desktop"></mat-icon>
      </div>

      <div class="wt-flex-column">
        <h1 class="wt-h1">{{ 'PWA_INSTALL_PROMPT_COMPONENT.TITLE' | translate }}</h1>
      </div>

      <div class="wt-flex-column wt-flex-shrink wt-flex-collapse wt-flex-align-right">
        <span class="wt-dialog__close-icon" title="{{ 'PWA_INSTALL_PROMPT_COMPONENT.CLOSE_BUTTON' | translate }}">
          <mat-icon class="mat-icon" fontIcon="close" [mat-dialog-close]="false"></mat-icon>
        </span>
      </div>
    </div>

    <p class="wt-dialog__message">
      {{ 'PWA_INSTALL_PROMPT_COMPONENT.MESSAGE_1' | translate }}
    </p>
    <p class="wt-dialog__message">
      {{ 'PWA_INSTALL_PROMPT_COMPONENT.MESSAGE_2' | translate }}
    </p>

    <div class="wt-action-buttons">
      <div class="wt-flex-row wt-flex-collapse wt-flex-align-right">
        <div class="wt-flex-column wt-flex-shrink">
          <button class="wt-button wt-button--primary" type="submit" [mat-dialog-close]="true"
            data-testid="confirmation-prompt-install">
            <mat-icon class="mat-icon mat-icon--small" fontIcon="install_desktop"></mat-icon>
            {{ 'PWA_INSTALL_PROMPT_COMPONENT.INSTALL_BUTTON' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
