import { InfrontSDK } from '@infront/sdk';

import { LabelListItem } from '../../shared/label-list/label-list.model';
import { FormatType } from '../../shared/models/formatter.model';
import { valueToClass } from '../../shared/util';
import { PositionsEventsDefaults, PositionsExposureDefaults } from '../../state-model/widget.defaults';
import { PortfolioPositionsWidget, WidgetName } from '../../state-model/widget.model';
import {
  PositionsEventsWindowDefaults,
  PositionsSummaryWindowDefaults,
} from '../../state-model/window.defaults';
import { LinkChannel, WindowName } from '../../state-model/window.model';
import { TradingClassification } from '../../widgets/portfolio-positions/portfolio-positions.model';
import { CurrencyCashPositions } from '../../widgets/positions-summary/positions-summary.service';
import { PORTFOLIO_DASHBOARD_ID } from '../providers/portfolio-dashboards';
import { readonlyWindowParams } from './templates.model';

const headerHeight = 1;

// col 1
const positionsWidth = 38;
const positionsHeight = 12;

//col 2
const positionsSummaryWidth = 12;
const positionsSummaryHeight = 10;

export const portfolioPositionsBaseWindow = {
  linkChannel: 'Channel 1',
  name: 'PortfolioPositionsWindow',
  settings: {}, // todo: should not be setting
  dashboardId: PORTFOLIO_DASHBOARD_ID,
  selectedWidgetName: 'PortfolioPositions',
  cols: positionsWidth,
  rows: positionsHeight,
  x: 0,
  y: headerHeight,
  ...readonlyWindowParams,
};

export const portfolioPositionsWindows = {
  Stock: {
    label: 'Equities',
  },
  ETF: {
    label: 'ETFs',
  },
  Fund: {
    label: 'MutualFunds',
  },
  Derivative: {
    label: 'Derivates',
  },
  Future: {
    label: 'Futures',
  },
  Bond: {
    label: 'Bonds',
  },
  Certificate: {
    label: 'Certificates',
  },
  Other: {
    label: 'Others',
  },
  Unmapped: {
    label: 'Unmapped',
  },
};

// add with PortfolioGridDefaults
export const portfolioPositionsWidgets: { [key in TradingClassification]: Partial<PortfolioPositionsWidget> } = {
  Stock: {
    settings: { selectedGrid: 'portfolioPositionsStockDefault' },
  },
  ETF: {
    settings: { selectedGrid: 'portfolioPositionsETFDefault' },
  },
  Fund: {
    settings: { selectedGrid: 'portfolioPositionsFundDefault' },
  },
  Derivative: {
    settings: { selectedGrid: 'portfolioPositionsDerivativeDefault' },
  },
  Future: {
    settings: { selectedGrid: 'portfolioPositionsFutureDefault' },
  },
  Bond: {
    settings: { selectedGrid: 'portfolioPositionsBondDefault' },
  },
  Certificate: {
    settings: { selectedGrid: 'portfolioPositionsCertificateDefault' },
  },
  Other: {
    settings: { selectedGrid: 'portfolioPositionsOtherDefault' },
  },
  Unmapped: {
    tradingClassification: 'Unmapped',
    settings: { selectedGrid: 'portfolioPositionsUnmappedDefault' },
  },
};

export const portfolioPositionsGrids = {
  Stock: [{ name: 'portfolioPositionsStockDefault' }, { name: 'portfolioPositionsStockPerformance' }],
  ETF: [{ name: 'portfolioPositionsETFDefault' }, { name: 'portfolioPositionsETFPerformance' }],
  Fund: [{ name: 'portfolioPositionsFundDefault' }, { name: 'portfolioPositionsFundPerformance' }],
  Derivative: [{ name: 'portfolioPositionsDerivativeDefault' }, { name: 'portfolioPositionsDerivativePerformance' }],
  Future: [{ name: 'portfolioPositionsFutureDefault' }, { name: 'portfolioPositionsFuturePerformance' }],
  Bond: [{ name: 'portfolioPositionsBondDefault' }, { name: 'portfolioPositionsBondPerformance' }],
  Certificate: [{ name: 'portfolioPositionsCertificateDefault' }, { name: 'portfolioPositionsCertificatePerformance' }],
  Other: [{ name: 'portfolioPositionsOtherDefault' }, { name: 'portfolioPositionsOtherPerformance' }],
  Unmapped: [{ name: 'portfolioPositionsUnmappedDefault' }],
};


// these definitions might be loaded from backend later making it possible for different customers to have different setups
// all string literals need to be casted otherwise we need to cast on the Dashboard window level which is less safe

// todo: Cleanup redundancy between defaults and template base classes for wt5 entities.
const PositionsSummaryWindowBase = {
  name: 'PositionsSummaryWindow' as WindowName,
  dashboardId: PORTFOLIO_DASHBOARD_ID,
  selectedWidgetName: 'PositionsSummary' as WidgetName,
  linkChannel: 'None' as LinkChannel,
  tag: 'POSITIONS',
  cols: positionsSummaryWidth,
  x: positionsWidth,
  y: 0,
  ...readonlyWindowParams,
};

export const CashWindow = {
  ...PositionsSummaryWindowDefaults,
  ...PositionsSummaryWindowBase,
  label: 'Cash',
};

export const ExposureWindow = {
  name: 'PositionsExposureWindow',
  dashboardId: PORTFOLIO_DASHBOARD_ID as string,
  selectedWidgetName: 'PositionsExposure',
  linkChannel: 'None' as LinkChannel,
  cols: positionsSummaryWidth,
  rows: positionsSummaryHeight,
  x: positionsWidth,
  tag: 'POSITIONS',
  label: 'Exposure',
  selectedGrid: 'Holdings',
  ...PositionsEventsWindowDefaults,
  ...readonlyWindowParams,
};

export const BalanceWindow = {
  ...PositionsSummaryWindowDefaults,
  ...PositionsSummaryWindowBase,
  label: 'Balance',
};

export const DevelopmentWindow = {
  ...PositionsSummaryWindowDefaults,
  ...PositionsSummaryWindowBase,
  label: 'Development',
  y: 1
};

export const EventsWindow = {
  name: 'PositionsEventsWindow',
  dashboardId: PORTFOLIO_DASHBOARD_ID as string,
  selectedWidgetName: 'PositionsEvents',
  linkChannel: 'None' as LinkChannel,
  cols: positionsSummaryWidth,
  rows: positionsSummaryHeight,
  x: positionsWidth,
  label: 'Events',
  tag: 'POSITIONS',
  ...PositionsEventsWindowDefaults,
  ...readonlyWindowParams,

};

const PositionsSummaryWidgetBase = {
  name: 'PositionsSummary' as WidgetName,
  settings: {}
};

export const CashWidget = {
  ...PositionsSummaryWidgetBase,
  positionsSummaryKey: 'Cash'

};

export const BalanceWidget = {
  ...PositionsSummaryWidgetBase,
  positionsSummaryKey: 'Balance'
};

export const DevelopmentWidget = {
  ...PositionsSummaryWidgetBase,
  positionsSummaryKey: 'Development'
};

export const ExposureWidget = {
  ...PositionsExposureDefaults
};

export const EventsWidget = {
  ...PositionsEventsDefaults
};

// this could be dynamic in the future (read from a backend source) therefor highly configurable setup, but labelList or something similar needs to be generated on a field by field basis. (Requires a calculated fields registry). For now the whole labellist is defined
export const CalculatedFields: { [key: string]: { fields: string[], labelListGetter?: (data: { [key: string]: unknown }) => LabelListItem[]; } } = {
  Cash: {
    fields: [
      'SumCash',
      CurrencyCashPositions, // this is a field calculated and created inside InvestorApp, not SDK
    ],
    labelListGetter: (data) => {
      if (!data[CurrencyCashPositions] || typeof data[CurrencyCashPositions] !== 'object') { // needed to comfort ts engine
        return [];
      }
      const currencyArray = Object.entries(data[CurrencyCashPositions]);
      return currencyArray.map(([key, value]) => ({ label: { value: key }, content: [{ value: value as unknown, type: 'price' as FormatType, currency: key, decimals: 2 }] }));
    }
  },
  Balance: {
    fields: [
      'TradingPower',
      'Total Cash',
      'Credit Limit',
      InfrontSDK.TradingField.BaseCurrency,
    ],
    labelListGetter: (data) => ([
      { label: { value: `Trading power` }, content: [{ value: data['TradingPower'], type: 'price', currency: data[InfrontSDK.TradingField.BaseCurrency] as string, decimals: 2 }] },
      { label: { value: `Total Cash` }, content: [{ value: data['Total Cash'], type: 'price', currency: data[InfrontSDK.TradingField.BaseCurrency] as string, decimals: 2 }] },
      { label: { value: `Credit Limit` }, content: [{ value: data['Credit Limit'], type: 'price', currency: data[InfrontSDK.TradingField.BaseCurrency] as string, decimals: 2 }] },
    ])
  },
  Development: {
    fields: [
      'Market Value',
      'Portfolio Value',
      InfrontSDK.TradingField.BaseCurrency,
      InfrontSDK.TradingField.BaseProfitLossToday,
      InfrontSDK.TradingField.BaseProfitLossTodayPct,
      InfrontSDK.TradingField.BaseResultPct,
      InfrontSDK.TradingField.BaseResult
    ],
    labelListGetter: (data) => ([
      { label: { value: `Market Value` }, content: [{ value: data['Market Value'], type: 'price', currency: data[InfrontSDK.TradingField.BaseCurrency] as string, decimals: 2 }] },
      { label: { value: `Portfolio value` }, content: [{ value: data['Portfolio Value'], type: 'price', currency: data[InfrontSDK.TradingField.BaseCurrency] as string, decimals: 2 }] },
      {
        label: { value: `Gain/Loss today` },
        content: [
          { value: data['BaseProfitLossToday'], type: 'price', decimals: 2, class: valueToClass(data['BaseProfitLossToday'] as number) },
          { value: '/', class: 'content-item--slash' },
          { value: data['BaseProfitLossTodayPct'], type: 'pctChange', decimals: 2, class: valueToClass(data['BaseProfitLossTodayPct'] as number) }
        ],
      },
      {
        label: { value: `Gain/Loss total` },
        content: [
          { value: data['BaseResult'], type: 'price', decimals: 2, class: valueToClass(data['BaseResult'] as number) },
          { value: '/', class: 'content-item--slash' },
          { value: data['BaseResultPct'], type: 'pctChange', decimals: 2, class: valueToClass(data['BaseResultPct'] as number) }
        ],
      },
    ])
  }
};


// Available calculated values
// [
//   "TradingPower",
//   "BaseCurrency",
//   "PortfolioValue",
//   "BaseMarketValue",
//   "BaseResult",
//   "BaseResultPct",
//   "BaseProfitLossToday",
//   "BaseProfitLossTodayPct",
//   "BaseRealizedToday",
//   "BaseInvested",
//   "BaseCollateral",
//   "SumCash",
//   "Overnight TP",
//   "Total Cash",
//   "Collateral Value",
//   "Collateral and margin",
//   "Credit Limit",
//   "Market Value",
//   "Intraday Credit",
//   "Portfolio Value",
//   "Open Buys",
//   "Open Sells",
//   "Linked Portfolio"
// ]




