/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable, inject } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { LogService } from '@vwd/ngx-logging';
import { Observable, ReplaySubject, Subscriber } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { SymbolDataItem } from '../../shared/models/symbol-data.model';

export type ResolvedSymbolValues = { [key in InfrontSDK.SymbolField]: InfrontSDK.SymbolFieldTypeBase[key] } & AdditionalSymbolFields;

export type DataValue = any; // NOSONAR lack any better internal representation for now
export type AdditionalSymbolFields = { index: string; symbol: InfrontSDK.SymbolData };

export interface FieldsMap {
  ticker: InfrontSDK.SymbolField;
  feed: InfrontSDK.SymbolField;
  [key: string]: InfrontSDK.SymbolField;
}

export interface ObserveParams<T extends InfrontSDK.SymbolField> {
  symbols: InfrontSDK.SymbolData[], // list of instruments to observe changes for
  fields: T[], // list of fields per instrument to observe changes for
  uuid: string, // unique id for the entity that is observing, usually widget or grid, needed to unbind when destroyed
  initialUpdate?: InfrontSDK.InitialUpdate, // default InfrontSDK.InitialUpdate.Always,
}
export interface ObserveParamsForListEmits<T extends InfrontSDK.SymbolField> extends ObserveParams<T> {
  separateObserveField?: T // if we want to observe a single field for performance reasons, updates are still emitted for each
}

@Injectable({
  providedIn: 'root',
})
export class ObserveSymbolsService {

  private readonly observeAction = new ReplaySubject<{ uuid: string }>(1);

  /**
 * Observes a list of symbols but emits per row, useful for row updates on ag grid or when there is only one symbol to observe in the list
 *  whenever a value on a row has a new value pushed from SDK, we emit it together with all the latest row data from our own cache
 */
  observeSymbols$<T extends InfrontSDK.SymbolField>(params: ObserveParams<T>): Observable<({ [K in T]: InfrontSDK.SymbolFieldTypeBase[K] } & AdditionalSymbolFields)> {
    const { uuid } = params;
    this.observeAction.next({ uuid });
    return this.observeAction.pipe(
      filter((item) => item.uuid === params.uuid),
      switchMap(() => this.observeSymbolsInner$<T>(params)));
  }


  /**
   * Observes a list of symbols and emits a complete list, useful for when we have lists that we have to modify before sending to the grid
   * when we can't determine what data to send to the grid until we resolved all symboldata data, such as in ranking or biggest movers
   * Could also be usefull if we ever have symbols list data outside of the ag grid.
   *  whenever any value is updated we emit the whole cached list
   * */
  observeSymbolsEmitList$<T extends InfrontSDK.SymbolField>(
    params: ObserveParamsForListEmits<T>
  ): Observable<({ [K in T]: InfrontSDK.SymbolFieldTypeBase[K] } & AdditionalSymbolFields)[]> {
    const { uuid } = params;
    this.observeAction.next({ uuid });
    return this.observeAction.pipe(
      filter((item) => item.uuid === params.uuid),
      switchMap(() => this.observeSymbolsEmitListInner$<T>(params)));
  }


  private readonly logger = inject(LogService).openLogger('services/observe-symbols');

  // cache needs to be stored on row level
  private cache: { [uuid: string]: { [key: string]: { item: DataValue; } } } = {};
  // observers need to be stored on field level
  private observers: { [uuid: string]: { [key: string]: { unbinder?: () => void } } } = {};

  private rowWithSingleFieldUpdated(symbol: InfrontSDK.SymbolData, observeField: InfrontSDK.SymbolField, cacheItem: DataValue): DataValue {
    const item = { ...cacheItem, ...{ [observeField]: symbol.get(observeField) } };
    return item;
  }

  private newRow<T extends InfrontSDK.SymbolField>(
    symbol: InfrontSDK.SymbolData,
    fields: T[]
  ): { [K in T]: InfrontSDK.SymbolFieldTypeBase[K] } & AdditionalSymbolFields {
    const item = fields.reduce(
      (acc, field) => {
        acc[field] = symbol.get(field);
        return acc;
      },
      { index: (symbol as SymbolDataItem).index, symbol } as any
    );
    return item;
  }

  private readonly addItemToCache = (uuid: string, key: string, item: DataValue) => {
    this.cache[uuid][key] ??= {} as any;
    this.cache[uuid][key].item = item;
  };

  private readonly addUnbinderToObserver = (uuid: string, key: string, unbinder: () => void) => {
    this.observers[uuid][key] ??= {};
    this.observers[uuid][key].unbinder = unbinder;
  };

  private readonly observeSymbolsInner$ = <T extends InfrontSDK.SymbolField>(
    { symbols, fields, uuid, initialUpdate = InfrontSDK.InitialUpdate.None }: ObserveParams<T>
  ): Observable<{ [K in T]: InfrontSDK.SymbolFieldTypeBase[K] } & AdditionalSymbolFields> => {
    return new Observable((obs: Subscriber<{ [K in T]: InfrontSDK.SymbolFieldTypeBase[K] } & AdditionalSymbolFields>) => {
      this.observers[uuid] ??= {};
      for (const symbol of symbols) {
        if (!symbol?.observe) { continue; }
        for (const obsField of fields) {
          const observerKey = symbol.get(InfrontSDK.SymbolField.Ticker) + '#' + obsField;

          if (this.observers[uuid][observerKey]) {
            return;
          }
          const observer = symbol.observe(
            obsField,
            () => {
              if (!this.observers[uuid]) {
                this.logger.debug(`observeSymbols$ - access observers[${uuid}] FAILED! Try unbinding for ${observerKey}.`);
                if (typeof observer === 'function') {
                  observer(); // unbind!
                }
                return;
              }
              const item = this.newRow(symbol, fields);
              obs.next(item);
            },
            undefined,
            initialUpdate
          );
          this.addUnbinderToObserver(uuid, observerKey, observer);
        }
      }
      return () => {
        this.cleanup(uuid);
      };
    });
  };


  private readonly observeSymbolsEmitListInner$ = <T extends InfrontSDK.SymbolField>(
    { symbols: inList, fields, uuid, initialUpdate = InfrontSDK.InitialUpdate.Always, separateObserveField }: ObserveParamsForListEmits<T>
  ): Observable<({ [K in T]: InfrontSDK.SymbolFieldTypeBase[K] } & AdditionalSymbolFields)[]> =>
    new Observable((obs: Subscriber<({ [K in T]: InfrontSDK.SymbolFieldTypeBase[K] } & AdditionalSymbolFields)[]>) => {
      this.cache[uuid] ??= {}; // if we don't have a cache for this uuid we create one
      this.observers[uuid] ??= {};
      const observeFields = separateObserveField ? [separateObserveField] : fields;
      inList.forEach((symbol: SymbolDataItem) => {
        if (!symbol?.observe) { return; }
        observeFields.forEach((obsField) => {
          const observerKey = symbol.get(InfrontSDK.SymbolField.Ticker) + '#' + obsField;
          const cacheKey = symbol.get(InfrontSDK.SymbolField.Ticker);
          if (this.observers[uuid][observerKey]) {
            return;
          }
          const observer = symbol.observe(
            obsField,
            () => {
              if (!this.cache[uuid] || !this.observers[uuid]) {
                this.logger.debug(`observeSymbolsEmitList$ - access cache[${uuid}] or observers[${uuid}] FAILED! Try unbinding for ${observerKey}.`);
                if (typeof observer === 'function') {
                  observer(); // unbind!
                }
                delete this.observers[uuid];
                delete this.cache[uuid];
                return;
              }
              const item =
                this.cache[uuid][cacheKey]?.item && !separateObserveField
                  ? // if the item is already in the cache we do InfrontSDK.get on a single field and use the rest of the fields from the cache
                  this.rowWithSingleFieldUpdated(symbol, obsField, this.cache[uuid][cacheKey].item)
                  : this.newRow(symbol, fields); // if we don't have a cache item we need to do InfrontSDK.get on all fields in the row to create a row in the cache to start with, we also need to refresh the complete row if we use a single observe field for better performance
              this.addItemToCache(uuid, cacheKey, item);
              this.observers[uuid][observerKey] = {};
              const list = Object.values(this.cache[uuid]).map((cacheItem) => cacheItem.item);
              obs.next(list);
            },
            undefined,
            initialUpdate
          );
          this.addUnbinderToObserver(uuid, observerKey, observer);
        });
      });
      return () => {
        this.cleanup(uuid);
      };
    });

  //resolves a set of InfrontSDK symbols to a rowlist of datavalues ASAP without storing anything in the cache, used for first load
  readonly symbolGetValueList$ = <T extends InfrontSDK.SymbolField>(
    symbols: InfrontSDK.SymbolData[],
    fields: T[]
  ): Observable<({ [K in T]: InfrontSDK.SymbolFieldTypeBase[K] } & AdditionalSymbolFields)[]> =>
    new Observable((obs: Subscriber<any[]>) => {
      const data: { [K in T]: InfrontSDK.SymbolFieldTypeBase[K] }[] = [];
      symbols.forEach((symbol: InfrontSDK.SymbolData) => {
        const item = this.newRow(symbol, fields);
        data.push(item);
      });
      obs.next(data);
    });

  //resolves a set of InfrontSDK symbols to a rowlist of datavalues but wait for each field in each row to recieve a callback before returning
  // we should be able to count on a callback for each supplied field in SDK but not working
  symbolGetValueListResolveAll$<T extends InfrontSDK.SymbolField>(
    symbols: InfrontSDK.SymbolData[],
    fields: T[]
  ): Observable<({ [K in T]: InfrontSDK.SymbolFieldTypeBase[K] } & AdditionalSymbolFields)[]> {
    const resolvedSymbols: ({ [K in T]: InfrontSDK.SymbolFieldTypeBase[K] } | Record<string, unknown>)[] = [];
    const callbacksExpected = symbols.length * fields.length;
    let callbacksMade = 0;
    return new Observable((obs: Subscriber<any[]>) => {
      for (const [i, symbol] of Object.entries(symbols)) {
        resolvedSymbols.push({});
        for (const field of fields) {
          symbol.get(field, (value) => {
            resolvedSymbols[i][field] = value;
            callbacksMade++;
            if (callbacksMade >= callbacksExpected) {
              this.logger.debug('symbolGetValueListResolveAll$, resolvedSymbols', resolvedSymbols);
              obs.next(resolvedSymbols);
            } else {
              const fieldsNoCallback = fields.filter((f) => !((f as string) in resolvedSymbols[0]));
              this.logger.debug('symbolGetValueListResolveAll$, unfinished resolvedSymbols', { resolvedSymbols, fieldsNoCallback, callbacksExpected, callbacksMade });
            }
          });
        }
      }
    });
  }


  cleanup(uuid: string): void {
    const observer = this.observers[uuid];
    if (observer) {
      Object.values(observer)
        .forEach((item) => item.unbinder?.());
      delete this.observers[uuid];
    }
    delete this.cache[uuid];
  }
}
