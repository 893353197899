import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';

import { NewDialogComponent } from './new-dialog.component';
import { NewWatchlistDialogComponent } from './new-watchlist-dialog/new-watchlist-dialog.component';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

@NgModule({
  declarations: [NewDialogComponent, NewWatchlistDialogComponent],
  imports: [
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatButtonModule,
    FormsModule,
    MatButtonModule,
    CommonModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [NewDialogComponent],
})
export class NewDialogModule { }
