import { InfrontSDK } from '@infront/sdk';

import { ContextMenuItem, ContextMenuItemKeys } from '../../../shared/models/context-menu.model';
import { HistoryWidget, Widget, WidgetName } from '../../../state-model/widget.model';
import { DashboardWindow, Instrument, WindowName } from '../../../state-model/window.model';

export interface WindowItemTypeOptions {
  classification?: InfrontSDK.SymbolClassification;
  watchlistByProvider?: { [provider: number]: InfrontSDK.Watchlist[]; };
  instrument?: Instrument;
}

export type WindowItemType = ContextMenuItem | ((window: DashboardWindow, options?: WindowItemTypeOptions) => ContextMenuItem);

export type WindowItems = { [key in ContextMenuItemKeys]?: WindowItemType; };

export interface TradingOrderMetaData {
  OrderId: number;
  OrderPortfolio: string;
  OrderStatus: InfrontSDK.Trading.OrderState;
  Metadata: object; // same no-type as in SDK
}

export interface TradingPositionMetaData {
  FullNameDebug: string;
  SymbolMappingDebug: string | undefined;
}

//definitions of reusable context menu items that is used for contextmenu belonging to symbols inside widgets with symbol lists
// menu items on the symbol level can currently reuse the WindowItems definitions but might need dedicated items in the future
export const SymbolItems: (ContextMenuItem | ((window: DashboardWindow, watchlists: InfrontSDK.Watchlist[]) => ContextMenuItem))[] = [];

// menus - identifiable groups and orders of context menu items, making up a full menu or part of a menu
const DefaultSingleInstrumentMenu: ContextMenuItemKeys[] = ['instrumentPage', 'buySell', 'addWindows', 'addToWatchlist', 'addAlert'];
// Window menus + Symbol grid row menus
export const CompanyDataWindowMenu: ContextMenuItemKeys[] = DefaultSingleInstrumentMenu;
export const CompanyInformationWindowMenu: ContextMenuItemKeys[] = DefaultSingleInstrumentMenu;
export const FocusMiniWindowMenu: ContextMenuItemKeys[] = DefaultSingleInstrumentMenu;
export const FocusWindowMenu: ContextMenuItemKeys[] = DefaultSingleInstrumentMenu;
export const InstrumentWindowMenu: ContextMenuItemKeys[] = DefaultSingleInstrumentMenu;
export const TopShareholdersWindowMenu: ContextMenuItemKeys[] = DefaultSingleInstrumentMenu;

// Widget menus
export const ChartMiniMenu: ContextMenuItemKeys[] = ['showChartTypeMenu', 'showResolutionMenu', 'showCompareMenu', 'showSettingsMenu'];
export const CompanyInformationMenu: ContextMenuItemKeys[] = ['showSymbolHeader'];
export const MarketOverviewMenu: ContextMenuItemKeys[] = ['showChart', 'flexChart', 'showTabs', 'showNoAccessInstruments'];
export const OrderbookMenu: ContextMenuItemKeys[] = ['showTopBar', 'yellowTopLevel', 'boldTopLevel', 'showOrderAmount'];
export const PerformanceMenu: ContextMenuItemKeys[] = ['showChart', 'showDividendsLine'];

// Symbol grid row menus
export const PortfolioPositionsGridMenu: ContextMenuItemKeys[] = ['instrumentPage', 'buySell', 'addAlert', 'symbolMappingInfo'];

// Window menus: WindowMenuMap defines which context menu items are used by which window
export const WindowMenuMap: Partial<{ [key in WindowName]: Readonly<ContextMenuItemKeys[]>; }> = {
  CalendarWindow: ['calendarType'],
  ChartMiniWindow: InstrumentWindowMenu,
  ChartWindow: InstrumentWindowMenu,
  CompanyDataWindow: CompanyDataWindowMenu,
  CompanyInformationWindow: CompanyInformationWindowMenu,
  FocusMiniWindow: FocusMiniWindowMenu,
  FocusWindow: FocusWindowMenu,
  InstrumentWindow: InstrumentWindowMenu,
  MarketOverviewWindow: [],
  MarketWindow: [],
  NewsWindow: ['newsType'],
  OrderbookWindow: InstrumentWindowMenu,
  TopShareholdersWindow: TopShareholdersWindowMenu,
  UserlistWindow: [],
  WatchlistWindow: ['addListAlert'],
} as const;

// Window menus bottom items: WindowMenuBottomMap defines which context menu items are used by which window at the bottom of the menu
export const WindowMenuBottomMap: Partial<{ [key in WindowName]: Readonly<ContextMenuItemKeys[]>; }> = {
  CalendarWindow: ['cloneWindow'],
  ChartMiniWindow: ['cloneWindow'],
  ChartWindow: ['cloneWindow'],
  CompanyDataWindow: ['cloneWindow'],
  CompanyInformationWindow: ['cloneWindow'],
  FocusMiniWindow: ['cloneWindow'],
  FocusWindow: ['cloneWindow'],
  InstrumentWindow: ['cloneWindow'],
  MarketOverviewWindow: ['cloneWindow'],
  MarketWindow: ['showChainInfo', 'cloneWindow'],
  NewsWindow: ['cloneWindow'],
  OrderbookWindow: ['cloneWindow'],
  TopShareholdersWindow: ['cloneWindow'],
  UserlistWindow: ['cloneWindow'],
  WatchlistWindow: ['cloneWindow'],
} as const;


// Widget menus: WidgetMenuMap defines which context menu items are used by which widget
export const WidgetMenuMap = (widget: Widget): { [key in WidgetName]?: ContextMenuItemKeys[]; } => ({
  BiggestMovers: [],
  Calendar: [],
  ChartMini: ChartMiniMenu,
  CompanyInformation: CompanyInformationMenu,
  History: (widget as HistoryWidget)?.settings?.selectedGrid === 'historyTrades' ? ['columnPicker'] : [],
  Lists: ['columnPicker', 'showCheckboxes'],
  MarketOverview: MarketOverviewMenu,
  Orderbook: OrderbookMenu,
  Performance: PerformanceMenu,
  PortfolioPositions: ['columnPicker', 'resetColumnsSingleGrid', 'resetColumnsInDashboard'],
  Ranking: [],
  Trades: ['columnPicker'],
  Userlist: ['columnPicker', 'showCheckboxes', 'toggleAdminMode'],
  Watchlist: ['columnPicker', 'showCheckboxes'],
  Chains: ['columnPicker', 'toggleAdminMode'],
});


// Symbol grid row menus: WindowSymbolMenuMap defines per Window which context menu items are used by symbols in each grid row
export const WindowSymbolMenuMap: { [key in WindowName]?: ContextMenuItemKeys[]; } = {
  CalendarWindow: [...InstrumentWindowMenu, 'calendarType'],
  NewsWindow: [...InstrumentWindowMenu, 'newsType'],
  WatchlistWindow: ['instrumentPage', 'buySell', 'addWindows', 'addAlert', 'removeFromWatchlist'],
  UserlistWindow: ['instrumentPage', 'buySell', 'addWindows', 'addAlert', 'removeFromUserlist'],
  InstrumentWindow: InstrumentWindowMenu,
  MarketWindow: InstrumentWindowMenu,
  PortfolioPositionsWindow: PortfolioPositionsGridMenu,
  FundScreenerWindow: [...InstrumentWindowMenu],
  ChainsWindow: [...InstrumentWindowMenu],
};
