<div class="wt-dialog">
  <div class="wt-flex-row wt-flex-align-middle wt-flex-collapse wt-dialog__title">
    <div class="wt-flex-column wt-flex-shrink wt-dialog-icon">
      <mat-icon fontIcon="info"></mat-icon>
    </div>

    <div class="wt-flex-column">
      <h1 class="wt-h1">{{ 'TRADING_ORDER_INFO_COMPONENT.TITLE' | translate }}</h1>
    </div>

    <mat-icon class="wt-flex-column wt-flex-shrink wt-text-right cursor-pointer wt-dialog-icon-close"
      (click)="onCloseDialog()" fontIcon="close"></mat-icon>
  </div>

  <div mat-dialog-content *ngIf="orderInfoData?.length">
    <div *ngFor="let orderInfo of orderInfoData" class="wt-flex-row wt-flex-align-middle wt-flex-collapse">
      <div class="wt-flex-column">{{ orderInfo.key }}</div>
      <div class="wt-flex-column">
        <ng-container [ngSwitch]="orderInfo.type">
          <ng-container *ngSwitchCase="'date'">
            {{ $any(orderInfo.value) | date: 'short' }}
          </ng-container>
          <ng-container *ngSwitchCase="'number'">
            {{ $any(orderInfo.value) | number }}
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ $any(orderInfo.value) | alternativeValue }}
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>