<ng-container *ngIf="watchlists$ | async as watchlists">
  <wt-dropdown class="wt-dropdown-watchlist"
    [matDropdownIcon]="matDropdownIcon"
    [matSubMenuIcon]="matSubMenuIcon"
    [dropdownLabel]="
        dropdownLabel
        ?? (selectedWatchlistTemplateTitle$ | async)
        ?? ('DROPDOWN_WATCHLIST.NO_WATCHLIST' | translate)
      "
    [selectedItem]="(selectedWatchlistDropdownItem$ | async) ?? undefined"
    [isSvgIcon]="true"
    [matMenuScopeClass]="matMenuScopeClass"
    [items]="watchlists.watchlistDropdownItems"
    [preItemTemplate]="createWatchlistTemplate"
    [itemTemplate]="watchlistItemTemplate"
    (itemSelected)="trySelectedWatchlistIdAction.next($event.id)"
    (menuClosed)="onMenuClosed()"
    #dropdown>
    <!-- preItem template -->
    <ng-template #createWatchlistTemplate>
      <div class="wt-flex-row wt-flex-align-justify">
        <div class="wt-flex-column wt-flex-collapse">
          <button class="wt-button wt-button--icon wt-dropdown-watchlist__item-button" type="button"
            (click)="onNewWatchlist($event, watchlists.rawWatchlists)"
            title="{{ 'DROPDOWN_WATCHLIST.CREATE' | translate }}">
            <mat-icon class="wt-dropdown-icon" fontIcon="add"></mat-icon>
            <span class="wt-dropdown-label">{{ 'DROPDOWN_WATCHLIST.CREATE' | translate }}</span>
          </button>
        </div>
      </div>
    </ng-template>

    <!-- item template -->
    <ng-template #watchlistItemTemplate let-watchlistItem let-isSubItem="isSubItem">
      <div class="wt-flex-row wt-flex-align-justify">
        <div class="wt-flex-column wt-flex-collapse">
          <button
            class="wt-button wt-button--icon wt-dropdown-watchlist__item-button wt-dropdown-watchlist__item-button--label"
            type="button"
            (click)="dropdownComponent.itemSelected.emit(watchlistItem)">
            <mat-icon *ngIf="isSubItem" class="wt-dropdown-icon" fontIcon="domain"></mat-icon>
            <mat-icon *ngIf="!isSubItem" class="wt-dropdown-icon" svgIcon="table_rows"></mat-icon>
            <span class="wt-dropdown-label">{{ watchlistItem.title }}</span>
          </button>
        </div>

        <ng-container *ngIf="hasWriteAccess(watchlistItem) | async">
          <div class="wt-flex-column wt-flex-collapse wt-flex-shrink">
            <div class="wt-dropdown__actions-panel">
              <button
                class="wt-button wt-button--icon wt-dropdown-watchlist__item-button wt-dropdown-watchlist__item-button--edit"
                type="button" title="{{ 'DROPDOWN_WATCHLIST.EDIT' | translate }}"
                (click)="onStartEdit($event, watchlistItem)">
                <mat-icon class="wt-dropdown-icon" svgIcon="edit"></mat-icon>
              </button>

              <button
                class="wt-button wt-button--icon wt-dropdown-watchlist__item-button wt-dropdown-watchlist__item-button--delete"
                type="button" title="{{ 'DROPDOWN_WATCHLIST.DELETE' | translate }}"
                (click)="onDeleteWatchlist($event, watchlistItem)">
                <mat-icon class="wt-dropdown-icon" svgIcon="delete"></mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </wt-dropdown>
</ng-container>