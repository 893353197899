import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { AgGridModule } from 'ag-grid-angular';

import { PipesModule } from '../../pipes/pipes.module';
import { DirectivesModule } from './../../directives/directives.module';
import { SidebarComponent } from './sidebar.component';
import { SidebarContentComponent } from './sidebar-content/sidebar-content.component';
import { TradingTabComponent } from './sidebar-content/tabs/trading/trading-tab.component';
import { WatchlistTabComponent } from './sidebar-content/tabs/watchlist/watchlist-tab.component';
import { GlobalTabComponent } from './sidebar-content/tabs/global/global-tab.component';
import { DropdownPortfolioModule } from '../../shared/dropdown/dropdown-portfolio/dropdown-portfolio.module';
import { TradingLoginModule } from '../../shared/trading-login/trading-login.module';
import { PortfolioSingleValueModule } from '../../shared/trading/portfolio-single-value/portfolio-single-value.module';
import { SymbolsGridModule } from '../../wrappers/grid-wrappers/symbols-grid/symbols-grid.module';
import { WTKWidgetWrapperModule } from '../../wrappers/wtk-widget-wrapper/wtk-widget-wrapper.module';
import { DropdownWatchlistModule } from '../../shared/dropdown/dropdown-watchlist/dropdown-watchlist.module';

@NgModule({
  declarations: [
    SidebarComponent,
    SidebarContentComponent,
    TradingTabComponent,
    WatchlistTabComponent,
    GlobalTabComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatBadgeModule,
    PipesModule,
    DirectivesModule,
    AgGridModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
    TradingLoginModule,
    DropdownWatchlistModule,
    DropdownPortfolioModule,
    SymbolsGridModule,
    PortfolioSingleValueModule,
    WTKWidgetWrapperModule,
  ],
  exports: [
    SidebarComponent,
  ],
})
export class SidebarModule { }
