import { InfrontSDK } from '@infront/sdk';
import { SdkSearchResultItem } from '../search.model';

export interface CompactSearchResultItemGroup {
  title: string;
  symbolClassificationList?: InfrontSDK.SymbolClassification[];
  filter?: () => SdkSearchResultItem[];
  items: SdkSearchResultItem[];
  showMore?: boolean;
  collapse?: boolean;
}

export const CompactSearchResultItemGroups: CompactSearchResultItemGroup[] = [
  {
    title: 'Markets',
    showMore: false,
    collapse: false,
    items: [],
  },
  {
    title: 'Equities',
    showMore: false,
    collapse: false,
    symbolClassificationList: [InfrontSDK.SymbolClassification.Stock],
    items: [],
  },
  {
    title: 'Indices',
    showMore: false,
    collapse: false,
    symbolClassificationList: [InfrontSDK.SymbolClassification.Index],
    items: [],
  },
  {
    title: 'Funds',
    showMore: false,
    collapse: false,
    symbolClassificationList: [InfrontSDK.SymbolClassification.Fund],
    items: [],
  },
  {
    title: 'Bonds',
    showMore: false,
    collapse: false,
    symbolClassificationList: [InfrontSDK.SymbolClassification.Bond],
    items: [],
  },
  {
    title: 'Derivatives',
    showMore: false,
    collapse: false,
    symbolClassificationList: [InfrontSDK.SymbolClassification.Derivative],
    items: [],
  },
  {
    title: 'Certificates',
    showMore: false,
    collapse: false,
    symbolClassificationList: [InfrontSDK.SymbolClassification.Certificate],
    items: [],
  },
  {
    title: 'Futures',
    showMore: false,
    collapse: false,
    symbolClassificationList: [InfrontSDK.SymbolClassification.Future],
    items: [],
  },
  {
    title: 'ETFs',
    showMore: false,
    collapse: false,
    symbolClassificationList: [InfrontSDK.SymbolClassification.ETF],
    items: [],
  },
  {
    title: 'Commodities',
    showMore: false,
    collapse: false,
    symbolClassificationList: [InfrontSDK.SymbolClassification.Commodity],
    items: [],
  },
  {
    title: 'Forex',
    showMore: false,
    collapse: false,
    symbolClassificationList: [InfrontSDK.SymbolClassification.Forex],
    items: [],
  },
  {
    title: 'CFDs',
    showMore: false,
    collapse: false,
    symbolClassificationList: [InfrontSDK.SymbolClassification.CFD],
    items: [],
  },
  {
    title: 'Indicators',
    showMore: false,
    collapse: false,
    symbolClassificationList: [InfrontSDK.SymbolClassification.Indicator],
    items: [],
  },
  {
    title: 'Unknown',
    showMore: false,
    collapse: false,
    symbolClassificationList: [InfrontSDK.SymbolClassification.Unknown],
    items: [],
  },
];

export const CompactSearchResultItemGroupsMap: { [groupTitle: string]: number } = CompactSearchResultItemGroups.reduce((map, group, index) => {
  map[group.title] = index;
  group.symbolClassificationList?.forEach((symbolCassification) => {
    map[symbolCassification] = index;
  });
  return map;
}, {});

export const DefaultSearchRequestDebounceTime: Readonly<number> = 250;
