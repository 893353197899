import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { MarketOverviewDropdownModule } from '../../dashboard-window/ui/market-overview-dropdown/market-overview-dropdown.module';
import { SearchModule } from '../../search/search.module';
import { InstrumentDockComponent } from './instrument-dock.component';
import { InstrumentDockItemModule } from './instrument-dock-item/instrument-dock-item.module';

@NgModule({
  declarations: [InstrumentDockComponent],
  imports: [
    CommonModule,
    OverlayModule,
    MatIconModule,
    MarketOverviewDropdownModule,
    SearchModule,
    InstrumentDockItemModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [InstrumentDockComponent],
})
export class InstrumentDockModule {}
