import {
  SidebarWatchlistDefaultColumns,
  SidebarWatchlistPerformanceColumns
} from '../../dashboard/sidebar/sidebar-content/sidebar-content.defaults';

export const sidebarGridColumnsMap = {
  sidebarWatchlistDefault: {
    all: SidebarWatchlistDefaultColumns,
  },
  sidebarWatchlistPerformance: {
    all: SidebarWatchlistPerformanceColumns,
  },
};

// export const sidebarGrid = [] as const;
