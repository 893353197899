import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'linkDisplay' })
export class LinkDisplayPipe implements PipeTransform {
  private linkMap: {[key: string]: string} = {
    1: 'Channel 1',
    2: 'Channel 2',
    3: 'Channel 3',
    4: 'Channel 4',
    All: 'All',
  };
  transform(value: string): string {
    const key = Object.keys(this.linkMap).find((key) => this.linkMap[key] === value);
    return key ? key : '';
  }
}
