import { DashboardService } from '../../../../dashboard/dashboard.service';
import { ContextMenuItem } from '../../../../shared/models/context-menu.model';
import { DashboardWindow } from '../../../../state-model/window.model';
import { WindowItemTypeOptions } from '../context-menu.model';

export const cloneWindowItem = (window: DashboardWindow, options: WindowItemTypeOptions, dashboardService: DashboardService): ContextMenuItem => {
  return {
    id: 'cloneWindow',
    title: 'CONTEXT_MENU_COMPONENT.CLONE_WINDOW',
    icon: 'copy',
    isSVGIcon: true,
    closeOnClick: true,
    onClick: (params) => {
      if (!params.window) {
        return;
      }

      dashboardService.cloneWindow(params.window);
    },
  };
};
