import { DashboardFolderLevel, DashboardType } from '../../models';
import type { DashboardModel } from '../../models';
import type { DashboardRef } from '../../state-refs';

/**
 * Compares two dashboards for sorting purposes. Dashboards with an index are sorted first,
 * followed, alphabetically, by the remaining dashboards.
 */
export function compareDashboardRefs(x: DashboardRef, y: DashboardRef): number {
  return compareDashboards(x.model, y.model);
}

/**
 * Compares two dashboards for sorting purposes. Dashboards with an index are sorted first,
 * followed, alphabetically, by the remaining dashboards.
 */
export function compareDashboards(x: DashboardModel, y: DashboardModel): number {

  if (x.type === DashboardType.FOLDER) {
    if (y.type !== DashboardType.FOLDER) {
      return -1;
    }
  } else if (y.type === DashboardType.FOLDER) {
    return +1;
  }

  if (x.index != null) {
    if (y.index == null) {
      return -1;
    } else {
      const d = x.index - y.index;
      if (d) {
        return d;
      }

      // Sort company folders before subtenant folders; we're working
      // with 1050 level here (mixed other realm & subTenant than own)
      if (x.owner?.realm && y.owner?.realm
        && x.owner.realm !== y.owner.realm
        && (x.level === DashboardFolderLevel.COMPANY || x.level === DashboardFolderLevel.SUBTENANT)
        && (y.level === DashboardFolderLevel.COMPANY || y.level === DashboardFolderLevel.SUBTENANT)) {

        const d2 = x.owner.realm.localeCompare(y.owner.realm);
        if (d2) {
          return d2;
        }
      }
    }
  } else if (y.index != null) {
    return +1;
  }

  // TODO: sort by level (GLOBAL, TENANT, SUBTENANT, SHARED, USER, other)
  // TODO: use `attributes.i18n-names` + current locale
  return x.name.localeCompare(y.name);
}
