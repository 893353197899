import { Injectable } from '@angular/core';
import { DashboardServicesProvider } from '@infront/ngx-dashboards-fx';
import { InstrumentDashboardProvider } from './instrument-dashboard.provider';
import { InstrumentWidgetDataProvider } from './instrument-widget-data.provider';

@Injectable({ providedIn: 'root' })
export class InstrumentDashboardServicesProvider implements DashboardServicesProvider {
  constructor(
    readonly dashboards: InstrumentDashboardProvider,
    readonly widgets: InstrumentWidgetDataProvider,
  ) { }
}
