<ng-container *ngIf="canAddDashboard$ | async as canAddDashboard">
  <span class="wt-dashboard-tabs-add-icon" [class.wt-dashboard-tabs-add-icon--disabled]="!canAddDashboard.enabled">
      <mat-icon (click)="$event.stopPropagation()" #matMenuTrigger [matMenuTriggerFor]="panel.menu"
      fontIcon="add_box" data-testid="new-dashboard-context-menu">
      </mat-icon>
      <wt-menu-panel
        #panel
        [items]="(menuItems$ | async) || undefined"
        scopeId="new-dashboard-menu"
        (itemClick)="onItemClick($event)"
        data-testid="new-dashboard-menu-panel">
      </wt-menu-panel>
  </span>
</ng-container>
