import { InfrontSDK } from '@infront/sdk';

export interface SymbolHeaderData {
  ticker: string;
  feed: number;
  iSIN: string;
  fullName: string;
  symbolClassification: InfrontSDK.SymbolClassification;
  country: string | number;
  currency: string;

  // special cases
  country1: string | number;
  country2: string | number;
  fundRating: number;
  isMorningStarFund: boolean;
  esgTotalScore: number;
}

export const SnapShotFields: InfrontSDK.SymbolField[] = [
  InfrontSDK.SymbolField.Ticker,
  InfrontSDK.SymbolField.ISIN,
  InfrontSDK.SymbolField.Feed,
  InfrontSDK.SymbolField.FullName,
  InfrontSDK.SymbolField.SymbolClassification,
  InfrontSDK.SymbolField.Country,
  InfrontSDK.SymbolField.Currency,
  InfrontSDK.SymbolField.Decimals,
  InfrontSDK.SymbolField.FxCurrency1,
  InfrontSDK.SymbolField.FxCurrency2,
  InfrontSDK.SymbolField.FundStarRating,
  InfrontSDK.SymbolField.CountryOfIncorporation,
  InfrontSDK.SymbolField.Country,
  InfrontSDK.SymbolField.FeedCountry,
  InfrontSDK.SymbolField.EsgTotalScore,
];
