import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class OutdatedPriceService implements OnDestroy {

  private outdateTimestamp: Date;
  private currentTimestamp: Date;
  private readonly dateLineExceeded$ = new Subject<Date>();
  private timer: ReturnType<typeof setTimeout>;

  constructor() {
    this.initCalculationDates();
    this.setTimer();
  }

  dateLineExceeded(): Observable<Date> {
    return this.dateLineExceeded$;
  }

  private initCalculationDates() {
    this.currentTimestamp = new Date();
    this.outdateTimestamp = new Date();
    this.outdateTimestamp.setHours(23);
    this.outdateTimestamp.setMinutes(59);
    this.outdateTimestamp.setSeconds(59);
    this.outdateTimestamp.setTime(this.outdateTimestamp.getTime() + 1000); // cross date line
  }

  private setTimer() {
    const timeout = this.outdateTimestamp.getTime() - this.currentTimestamp.getTime();
    this.timer = setTimeout(() => {
      this.dateLineExceeded$.next(this.outdateTimestamp);
      this.initCalculationDates();
      this.setTimer();
    }, timeout);
    // }, 5000); // For testing
  }

  ngOnDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.dateLineExceeded$.complete();
  }
}
