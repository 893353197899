import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Directive, HostListener, Inject, LOCALE_ID } from '@angular/core';
import { NgControl } from '@angular/forms';

import { replaceAt } from '../util/string';

@Directive({
  selector: '[numberOnly]',
})
export class NumberOnlyDirective {
  private el: NgControl;

  constructor(private ngControl: NgControl, @Inject(LOCALE_ID) private locale: string) {
    this.el = this.ngControl;
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    // any improvement of the regex filter need to be tested and work in full in order to be worth while
    const decimalSymbol = getLocaleNumberSymbol(this.locale, NumberSymbol.CurrencyDecimal);
    value = value.replace(/[^0-9,.-]/g, '');
    value = value.replace(decimalSymbol === '.' ? ',' : '.', decimalSymbol === '.' ? '.' : ',');
    const indexFirstDecimalSymbol = value.indexOf(decimalSymbol);
    if (indexFirstDecimalSymbol !== -1) {
      value = replaceAt(value, decimalSymbol, '', indexFirstDecimalSymbol + 1, value.length);
    }
    if (value.indexOf('-', 1) !== -1) {
      value = replaceAt(value, '-', '', 1, value.length);
    }
    const hyphenAdjust = value.startsWith('-') ? 1 : 0;
    const isFirstZeroRedundant =
      value.length > 1 + hyphenAdjust &&
      value.slice(0 + hyphenAdjust, 1 + hyphenAdjust) == '0' &&
      value.slice(1 + hyphenAdjust, 2 + hyphenAdjust) !== decimalSymbol;
    if (isFirstZeroRedundant) {
      value = value.slice(1);
    }
    if (value.length === 1 && value.slice(0, 1) == decimalSymbol) {
      value = '0.';
    }
    if (value.length === 2 && value.slice(0, 2) == `-${decimalSymbol}`) {
      value = '-0.';
    }
    this.el.control?.patchValue(value.length ? value : undefined);
  }
}
