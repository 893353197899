<div class="wt-trading-order-flyout" *ngIf="instrument">

  <div class="wt-trading-order-flyout__symbol-header">
    <div class="wt-flex-row wt-flex-collapse">
      <div class="wt-flex-column">
        <div class="wt-trading-order-flyout__symbol-header-info">
          <wt-symbol-header-info [instrument]="instrument" [isCompact]="true"
            [linkInstrumentDashboard]="true"></wt-symbol-header-info>
        </div>
      </div>

      <div class="wt-flex-column wt-flex-shrink">
        <div class="wt-trading-order-flyout__focus">
          <wt-instrument-focus [instrument]="instrument"></wt-instrument-focus>
        </div>
      </div>
    </div>
  </div>

  <div class="wt-trading-order-flyout__orderbook">
    <wt-orderbook-view [instrument]=" instrument" [settings]="orderbookSettings" [isCompact]="true"></wt-orderbook-view>
  </div>
</div>