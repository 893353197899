import { ChangeDetectionStrategy, Component, InjectionToken, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { UserSettingsStorageData } from '../../services/storage.service';

export interface ConfirmDialogData {
  header: string,
  message: string,
  icon?: string,
  okButtonLabel?: string,
  cancelButtonLabel?: string,
  noCancelButton?: boolean;
  disablePromptSettingKey?: keyof UserSettingsStorageData; // the setting to disable the prompt
  isSVGIcon?: boolean;
}

export interface ConfirmDialogResult {
  shouldDelete: boolean,
  disableDeletePrompt: boolean,
}

@Component({
  selector: 'wt-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  private readonly dialogRef = inject(MatDialogRef<ConfirmDialogComponent>);
  readonly data = inject(MAT_DIALOG_DATA as InjectionToken<ConfirmDialogData>);

  disablePrompt = false;

  onClose(result: boolean): void {
    const dialogResult = {
      shouldDelete: result,
      disableDeletePrompt: this.disablePrompt,
    } as ConfirmDialogResult;

    this.dialogRef.close(dialogResult);
  }
}
