import { InfrontUtil } from '@infront/sdk';

import { toLowerCaseFirstLetter } from './string';

export interface NestedPropertyWithValueAtEnd<T> {
  key: string;
  prop: NestedPropertyWithValueAtEnd<T> | PropertyWithValue<T>;
}

export interface PropertyWithValue<T> {
  key: string;
  value: T;
}

export function isNestedPropertyWithValueAtEnd<T>(nestedProp: object): nestedProp is NestedPropertyWithValueAtEnd<T> {
  return 'prop' in nestedProp;
}

export function isPropertyWithValue<T>(nestedProp: object): nestedProp is PropertyWithValue<T> {
  return 'value' in nestedProp;
}

export function hasEmptyProperty(source: object, property: string): boolean {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return source[property] == undefined || InfrontUtil.isEmptyObject(source[property]);
}

export function addNestedPropertyIfEmpty<A extends object, B extends object>(
  source: A,
  nestedProp: NestedPropertyWithValueAtEnd<B> | PropertyWithValue<B>,
  forceCreation: boolean = false
): void {
  if (isNestedPropertyWithValueAtEnd(nestedProp)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (forceCreation || hasEmptyProperty(source, nestedProp.key)) {
      source[nestedProp.key] = {};
      return addNestedPropertyIfEmpty(source[nestedProp.key], nestedProp.prop, true);
    }
    return addNestedPropertyIfEmpty(source[nestedProp.key], nestedProp.prop, forceCreation);
  } else if (isPropertyWithValue(nestedProp)) {
    if (forceCreation || hasEmptyProperty(source, nestedProp.key)) {
      source[nestedProp.key] = nestedProp.value;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertArrayToObject = <T extends { [key: string]: any }>(array: readonly T[], key: string): { [key: string]: T } => {
  const obj: { [key: string]: T } = {};
  array.forEach((item) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    obj[item[key]] = item;
  });

  return obj;
};

export function objectKeysToLowerCaseFirstLetter(obj: Record<string, unknown>): Record<string, unknown> {
  for (const entry of Object.entries(obj)) {
    obj[toLowerCaseFirstLetter(entry[0])] = entry[1];
    delete obj[entry[0]];
  }
  return obj;
}

/**
 * Returns the input without the specified properties.
 */
export function omit<T, K extends keyof T>(obj: T, ...keys: readonly K[]): Omit<T, K> {
  let result: T | undefined;
  for (const key of keys) {
    if (obj[key] !== undefined) {
      result ??= { ...obj };
      delete result[key];
    }
  }
  return result ?? obj;
}

/**
 * Returns an object with only the specified properties.
 */
export function pick<T, K extends keyof T>(obj: T, ...keys: readonly K[]): Pick<T, K> {
  if (!obj) {
    return obj;
  }

  const result = {} as Partial<Pick<T, K>>;

  for (const key of keys) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    result[key] = obj[key];
  }

  return result as Pick<T, K>;
}

/**
 * Returns an object with all properties that are `undefined` removed.
 *
 * @param obj
 * @returns An object without any `undefined` properties. Returns the original
 *   {@link obj} if it didn't have any `undefined` properties to begin with.
 */
export function omitUndefinedProperties<T>(obj: T): T {
  if (!obj) {
    return obj;
  }

  let result;

  for (const [key, value] of Object.entries(obj)) {
    if (value === undefined) {
      result ??= { ...obj };
      delete result[key];
    }
  }

  return result ?? obj;
}

/**
 * Checks whether the specified object is an empty object (i.e., `{}`),
 * `null` or `undefined`.
 *
 * @param obj The object to check
 */
export function isEmptyObject(obj: unknown): obj is Record<never, never> | null | undefined {
  if (typeof obj !== 'object') {
    return false;
  } else if (obj == undefined) {
    return true;
  }

  return !Object.keys(obj).length;
}

/**
 * Calls `Object.freeze()` on the specified object and all its properties, recursively.
 *
 * @param object The object to freeze.
 * @returns The original input value, but frozen.
 */
export function deepFreeze<T>(object: T): T {
  if (typeof object !== 'object' || !object) {
    return object;
  }

  Object.freeze(object);

  for (const value of Object.values(object)) {
    deepFreeze(value);
  }

  return object;
}

/**
 * Returns the nested property of an object for a provided property path.
 * Also supports nested arrays by mapping the items.
 *
 * @param obj The object to return the nested property from.
 * @param propPath The string that contains the path (props separated by dots '.') to the desired nested property.
 */
export function getNestedProp(obj: unknown, propPath: string): unknown | undefined {
  const paths = propPath.split('.');
  let value: unknown | undefined = obj;

  if (paths.length) {
    const lastIndex = paths.length - 1;

    for (let i = 0; i < paths.length; i++) {
      if (typeof value !== 'object') {
        if (lastIndex !== i) {
          value = undefined;
        }
        break;
      }
      const path = paths[i];
      if (Array.isArray(value)) {
        const nestedValueArray = [];
        for (const val of value) {
          if (val && path in val) {
            nestedValueArray.push(val?.[path]);
          } else {
            nestedValueArray.push(undefined);
          }
        }
        value = nestedValueArray;
      } else {
        value = (value as object)[path];
      }
    }
  }

  return value;
}
