import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild, inject } from '@angular/core';
import { Infront, InfrontUtil } from '@infront/sdk';
import { ListLayout } from '@infront/wtk/components/list-commons';
import { OrderStackWidget, OrderStackWidgetOptions } from '@infront/wtk/widgets/order-stack';
import { PositionsWidget, PositionsWidgetOptions } from '@infront/wtk/widgets/positions';

import { Observable, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';

import { SortOrder, WidgetState } from '@infront/wtk/src/InfrontUI';
import { ToolkitColumnService } from '../../../../../services/toolkit-column.service';
import { TradingOrderEntryService } from '../../../../../services/trading-order-entry.service';
import { TradingService } from '../../../../../services/trading.service';
import { UserSettingsService } from '../../../../../services/user-settings.service';
import { TRADING_TAB_HIDE_INACTIVE_DELETED_ORDERS_DEFAULT } from '../../../../../shared/models/settings.model';
import { WTKWidgetConstructorWithOptions } from '../../../../../wrappers/wtk-widget-wrapper/wtk-widget-wrapper.model';
import { TradingOrdersService } from './../../../../../services/trading-orders.service';
import { TradingPositionsService } from './../../../../../services/trading-positions.service';
import { DEFAULT_ORDER_STACK_HEIGHT_PX, DEFAULT_POSITIONS_HEIGHT_PX, ORDER_STACK_PLACEHOLDER_HEIGHT_PX, OrderStackWidgetHiddenInactiveDeletedOrders, POSITIONS_PLACEHOLDER_HEIGHT_PX, PositionsWidgetInstrumentTypes, VISIBLE_Y_SCROLLBAR_CSS_CLASS, hasAvgPrice } from './trading-tab.model';

@Component({
  selector: 'wt-trading-tab',
  templateUrl: './trading-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'wt-full-height wt-flex wt-flex-dir-column'
  }
})
export class TradingTabComponent implements OnDestroy {
  private readonly cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly tradingService: TradingService = inject(TradingService);
  private readonly userSettingsService: UserSettingsService = inject(UserSettingsService);
  private readonly toolkitColumnService: ToolkitColumnService = inject(ToolkitColumnService);
  private readonly tradingOrderEntryService: TradingOrderEntryService = inject(TradingOrderEntryService);
  private readonly tradingPositionsService: TradingPositionsService = inject(TradingPositionsService);
  private readonly tradingOrdersService: TradingOrdersService = inject(TradingOrdersService);

  readonly tradingConnected$ = this.tradingService.tradingConnected$;
  readonly selectedPortfolio$ = this.tradingService.selectedPortfolio$;
  // tradingSymbolData$: Observable<{name: string; symbolData: SymbolData[]; }[] | undefined>;

  // WTK Widgets
  // PortfolioSingleValueWidgets
  readonly portfolioSingleValueWidgetLists: { portfolioValue: Infront.PortfolioValue }[][] = [
    [{ portfolioValue: Infront.PortfolioValue.TotalPortfolioValue }, { portfolioValue: Infront.PortfolioValue.TradingPower }],
    [{ portfolioValue: Infront.PortfolioValue.TotalBaseMarketValue }, { portfolioValue: Infront.PortfolioValue.SumCash }],
  ];

  // OrderStackWidget
  readonly orderStackWidget$: Observable<WTKWidgetConstructorWithOptions<OrderStackWidget, OrderStackWidgetOptions>> = (
    of(this.getDefaultOrderStackWidgetOptions()).pipe(
      switchMap((options) => combineLatest([
        this.userSettingsService.getValue$('tradingTabHideInactiveDeletedOrders'),
        this.tradingService.connectionState$,
      ]).pipe(
        map(([hideInactiveDeletedOrders, connectionStateInfo]) => {
          hideInactiveDeletedOrders ??= TRADING_TAB_HIDE_INACTIVE_DELETED_ORDERS_DEFAULT;
          options.includedOrderStates = hideInactiveDeletedOrders ? OrderStackWidgetHiddenInactiveDeletedOrders : OrderStackWidget.includedOrderStates;
          options.computedAvgPrice = !hasAvgPrice(connectionStateInfo.providerId);
          return { widgetConstructor: OrderStackWidget, options };
        })
      )),
    )
  );

  readonly showOrderStackWidget$ = this.userSettingsService.getValue$('tradingTabShowOrderStackWidget').pipe(
    map((show) => ({ show: show === false ? false : true }))
  );
  orderStackWidgetHasContent: boolean;

  // PositionsWidget
  readonly positionsWidget$: Observable<WTKWidgetConstructorWithOptions<PositionsWidget, PositionsWidgetOptions>> = (
    combineLatest([of(this.getDefaultPositionsWidgetOptions()), this.tradingPositionsService.portfolioInstruments$]).pipe(
      switchMap(([options, instruments]) => combineLatest([
        this.toolkitColumnService.columns$(of({ columnNames: this.positionsWidgetOptionsColumns, instruments })),
        this.toolkitColumnService.columns$(of({ columnNames: this.positionsWidgetOptionsDisplayRowColumns, instruments })),
      ]).pipe(
        map(([columns, displayRowColumns]) => {
          console.log('options', JSON.stringify(options));
          options.columns = columns;
          options.displayRowColumns = displayRowColumns;
          return { widgetConstructor: PositionsWidget, options };
        })
      )),
    )
  );

  readonly reinitPositionsWidget$ = this.tradingOrdersService.portfolioOrders$();

  readonly showPositionsWidget$ = this.userSettingsService.getValue$('tradingTabShowPositionsWidget').pipe(
    map((show) => ({ show: show === false ? false : true }))
  );
  positionsWidgetHasContent: boolean;

  private readonly positionsWidgetOptionsDisplayRowColumns = [
    'FLAG_WITH_TICKER_AND_PCT_CHANGE',
    'VOLUME',
    'AVG_PRICE',
    'BASE_MARKET_VALUE',
    'BASE_RESULT',
  ];

  private readonly positionsWidgetOptionsColumns = [
    'INVESTED_AND_BASE_WITH_CURRENCY',
    'MARKET_VALUE_AND_BASE_WITH_CURRENCY',
    'RESULT_AND_BASE_WITH_CURRENCY',
    'RESULT_PCT_AND_BASE_WITH_CURRENCY',
    'COLLATERAL',
    'BUY_SELL_BUTTON',
  ];

  // Template variables
  readonly PortfolioValue = Infront.PortfolioValue;

  // Misc
  private readonly ngUnsubscribe = new Subject<void>();

  // Resize
  // CONTAINER
  private readonly containerAction = new ReplaySubject<HTMLDivElement | undefined>(1);
  private readonly containerResize$: ResizeObserver = new ResizeObserver((entries) =>
    this.containerHeightAction.next(entries[0].contentRect.height)
  );
  private readonly containerHeightAction = new ReplaySubject<number>(1); // The available base height (100%) that's to be distributed
  @ViewChild('container') set container(containerRef: ElementRef<HTMLDivElement> | undefined) {
    this.containerResize$.disconnect(); // Could run into race condition if put into containerSub$
    this.containerAction.next(containerRef?.nativeElement);
  }

  // ORDERSTACK
  @ViewChild('orderStackContainer') set orderStackContainer(orderStackContainerRef: ElementRef<HTMLDivElement> | undefined) {
    this.orderStackContainerAction.next(orderStackContainerRef?.nativeElement);
  }
  private readonly orderStackContainerAction = new LastValueSubject<HTMLDivElement | undefined>();
  private readonly orderStackScrollChildResize$: ResizeObserver = new ResizeObserver((entries) =>
    this.orderStackScrollChildHeight.next(entries[0].contentRect.height)
  );
  readonly orderStackWidgetStateChange = new ReplaySubject<WidgetState>(); // Emit in template
  private readonly orderStackScrollChildHeight = new ReplaySubject<number | undefined>(1); // The true height  (as if there was no scrollbar)
  private readonly orderStackHeight$ = combineLatest([this.orderStackScrollChildHeight, this.showOrderStackWidget$]).pipe(
    map(([scrollChildHeight, showOrderStackWidget]) => DEFAULT_ORDER_STACK_HEIGHT_PX
      + (showOrderStackWidget // expanded/collapsed widget
        ? scrollChildHeight ?? ORDER_STACK_PLACEHOLDER_HEIGHT_PX
        : 0)
    ),
    takeUntil(this.ngUnsubscribe)
  );

  // POSITIONS
  @ViewChild('positionsContainer') set positionsContainer(positionsContainerRef: ElementRef<HTMLDivElement>) {
    this.positionsContainerAction.next(positionsContainerRef?.nativeElement);
  }
  private readonly positionsContainerAction = new LastValueSubject<HTMLDivElement>();
  private readonly positionsScrollChildResize$: ResizeObserver = new ResizeObserver((entries) =>
    this.positionsScrollChildHeight.next(entries[0].contentRect.height)
  );
  readonly positionsWidgetStateChangeAction = new ReplaySubject<WidgetState>(); // Emit in template
  private readonly positionsScrollChildHeight = new ReplaySubject<number | undefined>(1); // The true height (as if there was no scrollbar)
  private readonly positionsHeight$ = combineLatest([this.positionsScrollChildHeight, this.showPositionsWidget$]).pipe(
    map(([scrollChildHeight, showPositionsWidget]) => DEFAULT_POSITIONS_HEIGHT_PX
      + (showPositionsWidget // expanded/collapsed widget
        ? scrollChildHeight ?? POSITIONS_PLACEHOLDER_HEIGHT_PX
        : 0)
    ),
    takeUntil(this.ngUnsubscribe)
  );

  constructor() {
    // Resizing logic for dynamic height IWT-1193
    // Reobserve container
    this.containerAction.pipe(
      tap((container) => (container ? this.containerResize$.observe(container) : undefined)),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();

    // Reobserve orderStackScrollChild
    this.orderStackWidgetStateChange.pipe(
      tap((state) => {
        this.orderStackScrollChildResize$.disconnect();
        const orderStackContainer = this.orderStackContainerAction.getValue();
        if (state && orderStackContainer && [WidgetState.Subscribed, WidgetState.UiBuilt].includes(state)) {
          const scrollChild = orderStackContainer.querySelector('wt-wtk-widget-wrapper');
          if (scrollChild) {
            this.orderStackScrollChildResize$.observe(scrollChild);
          } else {
            this.orderStackScrollChildHeight.next(undefined);
          }
        } else {
          this.orderStackScrollChildHeight.next(undefined);
        }
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe();

    // Reobserve positionsScrollChild
    this.positionsWidgetStateChangeAction.pipe(
      tap((state) => {
        this.positionsScrollChildResize$.disconnect();
        const positionsContainer = this.positionsContainerAction.getValue();
        if (state && positionsContainer && [WidgetState.Subscribed, WidgetState.UiBuilt].includes(state)) {
          const scrollChild = positionsContainer.querySelector('wt-wtk-widget-wrapper');
          if (scrollChild) {
            this.positionsScrollChildResize$.observe(scrollChild);
          } else {
            this.positionsScrollChildHeight.next(undefined);
          }
        } else {
          this.positionsScrollChildHeight.next(undefined);
        }
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe();

    // Height logic
    combineLatest([
      this.containerHeightAction,
      this.orderStackContainerAction,
      this.orderStackHeight$,
      this.positionsContainerAction,
      this.positionsHeight$,
    ]).pipe(
      tap(([containerHeight, orderStackContainer, orderStackHeight, positionsContainer, positionsHeight]) => {
        if (orderStackContainer && positionsContainer) {
          if (containerHeight < (orderStackHeight + positionsHeight)) {
            // There's not enough space
            const orderStackHeightTreshold = containerHeight * 0.6; // 60% of containerHeight in px
            const positionsHeightTreshold = containerHeight * 0.4; // 40% of containerHeight in px
            if (orderStackHeight > orderStackHeightTreshold && positionsHeight > positionsHeightTreshold) {
              orderStackContainer.style.maxHeight = '60%';
              positionsContainer.style.maxHeight = '40%';
              InfrontUtil.addClassName(orderStackContainer, VISIBLE_Y_SCROLLBAR_CSS_CLASS);
              InfrontUtil.addClassName(positionsContainer, VISIBLE_Y_SCROLLBAR_CSS_CLASS);
              return;
            }

            if (orderStackHeight > orderStackHeightTreshold) {
              const remainingHeight = containerHeight - positionsHeight;
              orderStackContainer.style.maxHeight = `${remainingHeight}px`;
              positionsContainer.style.maxHeight = 'unset';
              InfrontUtil.addClassName(orderStackContainer, VISIBLE_Y_SCROLLBAR_CSS_CLASS);
              InfrontUtil.removeClassName(positionsContainer, VISIBLE_Y_SCROLLBAR_CSS_CLASS);
            }
            if (positionsHeight > positionsHeightTreshold) {
              const remainingHeight = containerHeight - orderStackHeight;
              orderStackContainer.style.maxHeight = 'unset';
              positionsContainer.style.maxHeight = `${remainingHeight}px`;
              InfrontUtil.removeClassName(orderStackContainer, VISIBLE_Y_SCROLLBAR_CSS_CLASS);
              InfrontUtil.addClassName(positionsContainer, VISIBLE_Y_SCROLLBAR_CSS_CLASS);
            }
          } else {
            // There's enough space
            orderStackContainer.style.maxHeight = 'unset';
            positionsContainer.style.maxHeight = 'unset';
            InfrontUtil.removeClassName(orderStackContainer, VISIBLE_Y_SCROLLBAR_CSS_CLASS);
            InfrontUtil.removeClassName(positionsContainer, VISIBLE_Y_SCROLLBAR_CSS_CLASS);
          }
        } else if (orderStackContainer) {
          orderStackContainer.style.maxHeight = 'unset';
          if (orderStackHeight > containerHeight) {
            InfrontUtil.addClassName(orderStackContainer, VISIBLE_Y_SCROLLBAR_CSS_CLASS);
          } else {
            InfrontUtil.removeClassName(orderStackContainer, VISIBLE_Y_SCROLLBAR_CSS_CLASS);
          }
        } else if (positionsContainer) {
          positionsContainer.style.maxHeight = 'unset';
          if (positionsHeight > containerHeight) {
            InfrontUtil.addClassName(positionsContainer, VISIBLE_Y_SCROLLBAR_CSS_CLASS);
          } else {
            InfrontUtil.removeClassName(positionsContainer, VISIBLE_Y_SCROLLBAR_CSS_CLASS);
          }
        }
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe();
  }

  private getDefaultOrderStackWidgetOptions(): OrderStackWidgetOptions {
    const options = new OrderStackWidgetOptions();
    options.orderClicked = ((modifyPortfolio: string, modifyOrderId: number) =>
      this.tradingOrderEntryService.openOrderEntry({ modifyPortfolio, modifyOrderId })
    );
    options.hasContentCallback = (hasContent) => {
      this.orderStackWidgetHasContent = hasContent;
      this.cdRef.markForCheck();
    };
    return options;
  }

  private getDefaultPositionsWidgetOptions(): PositionsWidgetOptions {
    const options = new PositionsWidgetOptions();
    options.defaultSortOrder = SortOrder.None;
    options.defaultSortedColumn = 'AVG_PRICE';
    options.sortable = true;
    options.instrumentTypes = PositionsWidgetInstrumentTypes;
    options.enableOrders = true;
    options.enableTrades = true;
    options.hideEmptyList = true; // @FIXME doesn't work, implemented workaround using [hidden] in template
    options.showPortfolio = false;
    options.layout = ListLayout.CARD;
    options.hasContentCallback = (hasContent) => {
      this.positionsWidgetHasContent = hasContent;
      this.cdRef.markForCheck();
    };
    options.onTradeClick = (instrument) => this.tradingOrderEntryService.openOrderEntry({ instrument });
    options.onOrderClick = (positionItem) => this.tradingOrderEntryService.openOrderEntry({
      modifyPortfolio: positionItem?.portfolio as unknown as string, // Typing doesn't exist in toolkit
      modifyOrderId: positionItem?.order_id as unknown as number
    });
    // eslint-disable-next-line no-null/no-null, no-restricted-syntax
    options.widgetTitle = null as unknown as string; // Force removal of widgetTitle
    return options;
  }

  toggleShowWidget(showWidgetUserKey: 'tradingTabShowOrderStackWidget' | 'tradingTabShowPositionsWidget') {
    const currentValue = this.userSettingsService.getValue(showWidgetUserKey);
    this.userSettingsService.setValue(showWidgetUserKey, !currentValue);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.containerAction.complete();
    this.containerHeightAction.complete();
    this.orderStackContainerAction.complete();
    this.orderStackWidgetStateChange.complete();
    this.orderStackScrollChildHeight.complete();
    this.positionsContainerAction.complete();
    this.positionsWidgetStateChangeAction.complete();
    this.positionsScrollChildHeight.complete();
    [this.containerResize$, this.orderStackScrollChildResize$, this.positionsScrollChildResize$].forEach((resize$) => resize$.disconnect());
  }

}
