import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LabelListModule } from '../../shared/label-list/label-list.module';
import { PositionsSummaryComponent } from './positions-summary.component';

@NgModule({
  declarations: [
    PositionsSummaryComponent
  ],
  imports: [
    CommonModule,
    LabelListModule
  ]
})
export class PositionsSummaryModule { }
