import { MarketOverviewTemplate } from '../market-overview.model';

export const TemplateUK: MarketOverviewTemplate[] = [
  {
    tab: 'Indices',
    instrumentList: [
      { feed: 2302, ticker: 'BUK100P' },
      { feed: 2302, ticker: 'BUK250P' },
      { feed: 2302, ticker: 'BUK350P' },
      { feed: 2302, ticker: 'BUKAC' },
      { feed: 2018, ticker: 'UKX' },
      { feed: 2280, ticker: 'ZFUT1' },
      { feed: 2302, ticker: 'BEPACP' },
      { feed: 2088, ticker: 'SPEURO' },
      { feed: 2161, ticker: 'SX5E' },
      { feed: 2206, ticker: 'ESFUT1' },
      { feed: 2098, ticker: 'DAX' },
      { feed: 2089, ticker: 'PX1' },
      { feed: 20, ticker: 'DJI' },
      { feed: 2087, ticker: 'COMP' },
      { feed: 2087, ticker: 'NDX' },
      { feed: 2088, ticker: 'SP500' },
      { feed: 21, ticker: 'NQFUT1' },
      { feed: 21, ticker: 'ESFUT1' },
      { feed: 6150, ticker: 'N225' },
      { feed: 2198, ticker: 'HSI' },
      { feed: 6700, ticker: '000001' },
      { feed: 6330, ticker: 'VIX' },
      { feed: 2274, ticker: 'SV2TX' },
    ],
  },
  {
    tab: 'Forex',
    instrumentList: [
      { feed: 12, ticker: 'EURGBP' },
      { feed: 12, ticker: 'GPBUSD' },
      { feed: 12, ticker: 'GPBEUR' },
      { feed: 12, ticker: 'GPBJPY' },
      { feed: 12, ticker: 'GPBCHF' },
      { feed: 12, ticker: 'GPBAUD' },
      { feed: 12, ticker: 'USDEUR' },
      { feed: 12, ticker: 'USDJPY' },
      { feed: 12, ticker: 'USDRUB' },
    ],
  },
  {
    tab: 'FixedIncome',
    instrumentList: [
      { feed: 2227, ticker: 'TMBMKGB-10Y' },
      { feed: 2227, ticker: 'TMBMKGB-30Y' },
      { feed: 2227, ticker: 'TMUBMUSD10Y' },
      { feed: 2227, ticker: 'TMUBMUSD30Y' },
      { feed: 2280, ticker: 'RFUT1' },
      { feed: 2280, ticker: 'IFUT1' },
      { feed: 2158, ticker: 'BMDE-10Y' },
      { feed: 2158, ticker: 'BMDE-30Y' },
      { feed: 2158, ticker: 'BMUS-10Y' },
      { feed: 2158, ticker: 'BMUS-30Y' },
      { feed: 2158, ticker: 'BMGB-10Y' },
    ],
  },
  {
    tab: 'Commodities',
    instrumentList: [
      { feed: 2229, ticker: 'BRT' },
      { feed: 2229, ticker: 'WTI' },
      { feed: 2148, ticker: 'AH3M' },
      { feed: 2148, ticker: 'CA3M' },
      { feed: 2148, ticker: 'ZS3M' },
      { feed: 2159, ticker: 'SLVSPOT' },
      { feed: 2159, ticker: 'GLDSPOT' },
    ],
  }
];
