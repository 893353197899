import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SymbolHeaderInfoModule } from '../symbol-header-info/symbol-header-info.module';
import { InstrumentFocusModule } from '../instrument-focus/instrument-focus.module';
import { OrderbookViewModule } from '../orderbook-view/orderbook-view.module';
import { TradingOrderFlyoutComponent } from './trading-order-flyout.component';

@NgModule({
  declarations: [TradingOrderFlyoutComponent],
  imports: [
    CommonModule,
    SymbolHeaderInfoModule,
    InstrumentFocusModule,
    OrderbookViewModule,
  ],
  exports: [TradingOrderFlyoutComponent],
})
export class TradingOrderFlyoutModule { }
