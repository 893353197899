import { Injectable, inject } from '@angular/core';
import { DashboardFolderLevel, DashboardFolderModel, DashboardGetChildrenContext, DashboardLinkCreate, DashboardModel, DashboardModelCreate, DashboardModelUpdate, DashboardProvider, DashboardServicesProvider, DashboardType, KnownDashboardFolderIDs, NoopWidgetDataProvider } from '@infront/ngx-dashboards-fx';
import { LogService } from '@vwd/ngx-logging';
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, map, of, tap } from 'rxjs';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { UserSettingsService } from '../../services/user-settings.service';
import { arraysAreEqual, compareArraysBy } from '../../util/equality';
import { deepFreeze } from '../../util/object';
import { isDefined } from '../../util/types';

@Injectable({ providedIn: 'root' })
export class DashboardTabsServicesProvider implements DashboardServicesProvider {
  dashboards = inject(DashboardTabsProvider);
  widgets = inject(NoopWidgetDataProvider);
}

export const DASHBOARD_TABS_FOLDER_ID = '$tabs';

const TABS_FOLDER: DashboardFolderModel = deepFreeze({
  id: DASHBOARD_TABS_FOLDER_ID,
  name: 'Tabs',
  parentId: KnownDashboardFolderIDs.ROOT,
  type: DashboardType.FOLDER,
  level: DashboardFolderLevel.CUSTOM,
  hidden: true,
  isLink: false,
  attributes: {},
  childrenLoadState: 'on-demand',
  security: {
    canAddChildren: false,
    canDelete: false,
    canDeleteChildren: false,
    canEdit: false,
    canMove: false,
  },
});

// FIXME: When we start supporting subtenant folders, we must somehow
//        trigger on-demand loading and wait.

@Injectable({ providedIn: 'root', useFactory: () => inject(DashboardTabsProvider) })
export abstract class DashboardTabsService {
  abstract get tabIds(): readonly string[];
  abstract addTab(dashboardId: string): Observable<void>;
  abstract setTabs(dashboardIds: readonly string[]): Observable<void>;
  abstract removeTab(dashboardId: string): Observable<void>;
}

/**
 * A dashboard provider that provides a single virtual "Tabs" folder with
 * ID `DASHBOARD_TABS_FOLDER_ID`, which contains a sorted list of active
 * dashboards (user & shared). However, the dashboards are not copies,
 * but rather references to the actual dashboards in their own parent
 * folder (similar to recent dashboards in IM).
 */
@Injectable({ providedIn: 'root' })
export class DashboardTabsProvider implements DashboardProvider, DashboardTabsService {
  private readonly userSettings: UserSettingsService = inject(UserSettingsService);
  private readonly logger = inject(LogService).openLogger('services/dashboards/provider/tabs');

  private readonly tabIdsSubject = new LastValueSubject<readonly string[]>();
  private readonly tabsFolder = new BehaviorSubject<DashboardFolderModel>(TABS_FOLDER);
  private readonly pendingDeletes = new Set<string>();

  get tabIds(): readonly string[] { return this.tabIdsSubject.value ?? []; }

  constructor() {
    this.userSettings.getValue$('dashboardTabs').subscribe((setting) => {
      this.logger.log('Emitting tabIds', setting);
      setting ??= [];
      if (!arraysAreEqual(setting, this.tabIdsSubject.value)) {
        this.tabIdsSubject.next(setting);
      }
    });
  }

  addTab(dashboardId: string): Observable<void> {
    // Note that regular user dashboards will be added automatically anyway,
    // so this method is mostly intended for shared dashboards or dashboards
    // in subfolders.
    const currentTabIds = this.tabIds;
    if (!currentTabIds.includes(dashboardId)) {
      this.logger.log('Adding tab ID', dashboardId);
      return this.userSettings.setValue('dashboardTabs', [...this.tabIds, dashboardId]);
    }
    return of(undefined);
  }

  setTabs(dashboardIds: readonly string[]): Observable<void> {
    if (!arraysAreEqual(dashboardIds, this.tabIds)) {
      this.logger.log('Replacing tab IDs', dashboardIds);
      // see removeTab for rationale on this loop
      for (const id of this.tabIds) {
        if (!dashboardIds.includes(id)) {
          this.pendingDeletes.add(id);
        }
      }
      return this.userSettings.setValue('dashboardTabs', dashboardIds);
    }
    return of(undefined);
  }

  removeTab(dashboardId: string): Observable<void> {
    const currentTabIds = this.tabIds;
    const index = currentTabIds.indexOf(dashboardId);
    if (index !== -1) {
      const newTabs = currentTabIds.slice();
      newTabs.splice(index, 1);
      // If we flag a user folder for removal, its actual removal will be
      // async. So it might be we get an update on `models$` in `getChildren(.)`
      // that still contains this dashboard, but we do not want to add the
      // dashboard back again in our handling of missing user dashboards,
      // only for it to be removed again once the delete goes through.
      //
      // So this is mostly to prevent data races and
      // flickering.
      this.pendingDeletes.add(dashboardId);
      this.logger.log('Removing tab ID', dashboardId);
      return this.userSettings.setValue('dashboardTabs', newTabs);
    }
    return of(undefined);
  }

  getDashboards(): Observable<readonly DashboardModel[]> {
    return this.tabsFolder.pipe(
      map((tab) => [tab]),
      tap((data) => this.logger.log('Emitting dashboards', data)),
    );
  }

  create(parent: DashboardFolderModel, data: DashboardModelCreate): Observable<DashboardModel> {
    throw new Error(`Not supported.`);
  }

  createLink(parent: DashboardFolderModel, data: DashboardLinkCreate): Observable<DashboardModel> {
    throw new Error(`Not supported.`);
  }

  update(id: string, changes: DashboardModelUpdate): Observable<DashboardModel> {
    throw new Error(`Not supported.`);
  }

  delete(id: string): Observable<void> {
    throw new Error(`Not supported.`);
  }

  canLinkTo(node: DashboardFolderModel, target: DashboardFolderModel): boolean {
    return false;
  }

  canMoveTo(node: DashboardModel, target: DashboardFolderModel): boolean {
    return false;
  }

  refresh(): void { } // NOSONAR

  getChildren(context: DashboardGetChildrenContext): Observable<readonly DashboardModel[]> {
    this.logger.log('Requesting tabs', context);
    if (context.node.id !== DASHBOARD_TABS_FOLDER_ID) {
      return of([]);
    }

    return combineLatest([
      this.tabIdsSubject,
      context.models$,
    ]).pipe(
      tap((data) => this.logger.log('Received tabIds & models', data)),
      map(([tabIds, models]) => {
        const tabIdsSet = new Set(tabIds);

        const finalTabs = tabIds.map((id) => models.get(id)).filter(isDefined);

        const tabsHaveChanged = finalTabs.length !== tabIdsSet.size;

        if (tabsHaveChanged) {
          this.setTabs(finalTabs.map((db) => db.id)); // should run async
        }

        // clean up the pending deletes
        for (const pendingDelete of this.pendingDeletes) {
          if (!models.has(pendingDelete)) {
            this.pendingDeletes.delete(pendingDelete);
          }
        }

        return finalTabs;
      }),
      tap({
        next: (data) => {
          if (this.tabsFolder.value.childrenLoadState !== 'loaded') {
            this.tabsFolder.next({ ...this.tabsFolder.value, childrenLoadState: 'loaded' });
          }
        }
      }),
      distinctUntilChanged(compareArraysBy((model) => model.id)),
      tap((data) => this.logger.log('Emitting tabs', data)),
    );
  }

}
