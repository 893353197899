<ng-container *ngIf="alert.type === AlertType.Instrument">
  <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
    <div class="wt-flex-column wt-flex-shrink">
      <div class="wt-alerts-list__item-flag">
        <wt-country-flag [country]="alert.countryFlag" [size]="16"></wt-country-flag>
      </div>
    </div>

    <div class="wt-flex-column wt-flex-shrink">
      <button type="button" class="wt-alerts-list__item-ticker"
        addDashboard
        [addDashboardInstrument]="{ feed: alert.feed, ticker: alert.ticker }"
        (click)="triggerClose.next()">
        {{ alert.ticker }}
      </button>
    </div>

    <div class="wt-flex-column">
      <span class="wt-alerts-list__item-faded">{{ alert.feedExchange }}</span>
    </div>

    <div class="wt-flex-column wt-flex-shrink">
      <span [ngClass]="setIntraDayClass(alert.eventTriggered)">
        {{ alert.eventTriggered | date: 'timeOrDate' }}
      </span>
    </div>
  </div>
</ng-container>

<!-- first row watchlist alert -->
<ng-container *ngIf="alert.type === AlertType.List">
  <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
    <div class="wt-flex-column wt-flex-shrink">
      <div class="wt-alerts-list__item-flag">
        <wt-country-flag [country]="alert.countryFlag" [size]="16"></wt-country-flag>
      </div>
    </div>

    <div class="wt-flex-column wt-flex-shrink">
      <button type="button" class="wt-alerts-list__item-ticker"
        addDashboard
        [addDashboardInstrument]="{ feed: alert.feed, ticker: alert.ticker }"
        (click)="triggerClose.next()">
        {{ alert.ticker }}
      </button>
    </div>

    <div class="wt-flex-column wt-flex-shrink">
      <mat-icon class="wt-alerts-list__item-watchlist-icon" svgIcon="visibility"></mat-icon>
    </div>

    <div class="wt-flex-column">
      <span class="wt-alerts-list__item-watchlist-label" [title]="alert.watchlist">
        {{ alert.watchlist }} {{ alert.feedExchange }}</span>
    </div>

    <div class="wt-flex-column wt-flex-shrink">{{ alert.eventTriggered | date: 'timeOrDate' }}</div>
  </div>
</ng-container>

<!-- second row instrument-->
<div *ngIf="alert.type === AlertType.Instrument"
  class="wt-flex-row wt-flex-collapse wt-alerts-list__item-description"
  [ngClass]="!alert.comment ? 'wt-my-alerts-extra-high-row' : 'wt-alert-log-row'">
  <div class="wt-flex-column" [innerHTML]="alert.description | alertDescription: alert"></div>
</div>

<!-- second row watchlist -->
<div *ngIf="alert.type === AlertType.List"
  class="wt-flex-row wt-flex-collapse wt-alerts-list__item-description"
  [ngClass]="!alert.comment ? 'wt-my-alerts-extra-high-row' : 'wt-alerts-log-row'">
  <div class="wt-flex-column" [innerHTML]="'Change ' + (alert.description | alertDescription: alert)"></div>
</div>

<!-- third row -->
<div class="wt-flex-row wt-flex-collapse wt-alerts-list__item-comment" *ngIf="alert.comment">
  <div class="wt-flex-column wt-flex-shrink">
    <mat-icon class="wt-alerts-list__item-comment-icon" fontIcon="chat"></mat-icon>
  </div>

  <div class="wt-flex-column">
    <span class="wt-alerts-list__item-comment-label">{{ alert.comment }}</span>
  </div>
</div>