import { Injectable, OnDestroy } from '@angular/core';
import { InstrumentLike, isSameInstrument } from '../../../util/symbol';

import { BehaviorSubject } from 'rxjs';
// injectible state service to use with mat-checkbox ag grid cell renderer
@Injectable()
export class PickedInstrumentsService implements OnDestroy {
  private readonly instrumentsAction = new BehaviorSubject<InstrumentLike[]>([]);
  readonly instruments$ = this.instrumentsAction.asObservable();

  readonly instruments = () => this.instrumentsAction.getValue();

  ngOnDestroy(): void {
    this.instrumentsAction.complete();
  }

  add(instrument: InstrumentLike): void {
    const instruments = this.instrumentsAction.getValue();
    if (!instruments.find((inst) => isSameInstrument(inst, instrument))) {
      this.instrumentsAction.next([...instruments, instrument]);
    }
  }

  remove(instrument: InstrumentLike): void {
    let instruments = this.instrumentsAction.getValue();
    instruments = instruments.filter((inst) => !isSameInstrument(inst, instrument));
    this.instrumentsAction.next(instruments);
  }

  setInstruments(instruments: InstrumentLike[]): void {
    this.instrumentsAction.next(instruments);
  }

  reset(): void {
    this.instrumentsAction.next([]);
  }
}
