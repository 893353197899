import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';

import { ContextMenuComponent } from './context-menu.component';
import { MenuPanelComponent } from './menu-panel/menu-panel.component';

@NgModule({
  declarations: [ContextMenuComponent, MenuPanelComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatMenuModule,
    MatRadioModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [ContextMenuComponent, MenuPanelComponent]
})
export class ContextMenuModule {}
