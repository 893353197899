import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import { InfrontSDK } from '@infront/sdk';
import { FormattingService } from '@vwd/ngx-i18n';
import { ResourceService } from '@vwd/ngx-i18n/translate';
import { translator } from '../../util/locale';

const TradeToastFields = [InfrontSDK.TradingField.Ticker, InfrontSDK.TradingField.TradeId, InfrontSDK.TradingField.BuyOrSell, InfrontSDK.TradingField.Volume, InfrontSDK.TradingField.VolumeFilled, InfrontSDK.TradingField.TradeTime, InfrontSDK.TradingField.OrderPrice, InfrontSDK.TradingField.SettlementCurrency];

const BuyOrSellPastTenseMap = {
  BUY: 'BOUGHT',
  SELL: 'SOLD',
};

interface TradeResolved {
  [InfrontSDK.TradingField.Ticker]: string;
  [InfrontSDK.TradingField.TradeId]: string;
  [InfrontSDK.TradingField.BuyOrSell]: string;
  [InfrontSDK.TradingField.Volume]: number;
  [InfrontSDK.TradingField.VolumeFilled]: number;
  [InfrontSDK.TradingField.TradeTime]: Date;
  [InfrontSDK.TradingField.OrderPrice]: number;
  [InfrontSDK.TradingField.SettlementCurrency]: string,
}

@Component({
  selector: 'wt-trade-toast',
  templateUrl: './trade-toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TradeToastComponent extends Toast implements OnInit {
  @Input() trade: InfrontSDK.Trading.PortfolioItem;

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage, private readonly format: FormattingService, private translate: ResourceService) {
    super(toastrService, toastPackage);
  }

  private readonly xlat = translator();

  tradeResolved: TradeResolved;

  description = '';
  filledMessage = '';

  ngOnInit() {
    const tradeResolved: TradeResolved = TradeToastFields.reduce((acc, key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      let value = this.trade.get(key);
      if (key === InfrontSDK.TradingField.TradeTime) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const dateTime = this.trade.get(key) ?? new Date();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        value = this.format.formatDateTime(dateTime, 'timeOrShortestDate');
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      acc[key] = value;
      return acc;
    }, {} as TradeResolved);

    const buyOrSell = tradeResolved[InfrontSDK.TradingField.BuyOrSell];
    let buyOrSellPastTense = '';
    if (buyOrSell === "BUY" || buyOrSell === "SELL") {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      buyOrSellPastTense = this.xlat(`TRADE_TOAST_COMPONENT.${BuyOrSellPastTenseMap[buyOrSell]}`);
    }

    const ticker = this.addValue(tradeResolved[InfrontSDK.TradingField.Ticker]);
    const volumeFilled = this.addValue(tradeResolved[InfrontSDK.TradingField.VolumeFilled]);
    const orderPrice = this.addValue(tradeResolved[InfrontSDK.TradingField.OrderPrice]) ? ` @ ${this.addValue(tradeResolved[InfrontSDK.TradingField.OrderPrice])}` : '';
    const settlementCurrency = this.addValue(tradeResolved[InfrontSDK.TradingField.SettlementCurrency]);

    const volume = tradeResolved[InfrontSDK.TradingField.Volume];
    const filledText = this.xlat('TRADE_TOAST_COMPONENT.FILLED');

    this.description = `${buyOrSellPastTense}${volumeFilled}${ticker}${orderPrice}${settlementCurrency}`;
    this.filledMessage = volumeFilled && volume ? `${filledText} ${volumeFilled}/${volume}` : '';
    this.tradeResolved = tradeResolved;
  }

  private addValue(value: string | number): string {
    return value ? ` ${value}` : '';
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }

  close() {
    this.toastrService.remove(this.toastPackage.toastId);
  }
}
