import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { I18nTranslateModule, provideResources } from '@vwd/ngx-i18n/translate';
import { Chain } from '../../widgets/lists/lists.model';

@Component({
  selector: 'wt-chain-info-dialog',
  templateUrl: './chain-info-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    I18nTranslateModule,
  ],
  providers: [
    provideResources({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      load: localeId => require(`./i18n/${localeId}.json`),
      supportedLocales: ['en']
    })
  ]
})
export class ChainInfoDialogComponent {
  readonly chain = inject<Chain>(MAT_DIALOG_DATA);
}
