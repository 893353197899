import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { AddInstrumentsToWidgetComponent } from './add-instruments-to-widget.component';

@NgModule({
  declarations: [AddInstrumentsToWidgetComponent],
  imports: [CommonModule, MatIconModule, I18nTranslateModule.forResourceSet({
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
    load: (locale: string) => require(`./i18n/${locale}.json`),
    supportedLocales: ['en', 'de', 'no'],
  }),],
  exports: [AddInstrumentsToWidgetComponent],
})
export class AddInstrumentsToWidgetModule { }
