import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { PricePipesModule } from '@vwd/ngx-price-pipes';

import { DynamicBarModule } from '../../shared/dynamic-bar/dynamic-bar.module';
import { PerformancePeriodsModule } from '../performance-periods/performance-periods.module';
import { PerformanceBarModule } from '../performance-bar/performance-bar.module';
import { PerformanceOverviewComponent } from './performance-overview.component';

@NgModule({
  declarations: [PerformanceOverviewComponent],
  imports: [
    CommonModule,
    DynamicBarModule,
    PerformancePeriodsModule,
    PerformanceBarModule,
    PricePipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [PerformanceOverviewComponent],
})
export class PerformanceOverviewModule {}
