import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { ClassifiedInstrument, Instrument, Market, WindowName, isClassifiedInstrument, isMarket } from '../state-model/window.model';
import { Observable } from 'rxjs';

enum Window {
  Window = "Window"
}

export const SearchResultItemType = { ...InfrontSDK.SearchResultItemType, ...Window };

export const SearchWindowItemMap: { [Name in WindowName]?: FullSearchWindowResultItem<Name>; } = {
  'CalendarWindow': {
    windowName: 'CalendarWindow',
    itemType: 'Window',
    translation: 'SEARCH.RESULT_WIDGET.CALENDAR.NAME',
    description: 'SEARCH.RESULT_WIDGET.CALENDAR.DESCRIPTION',
    icon: 'widgets',
  },
  'NewsWindow': {
    windowName: 'NewsWindow',
    itemType: 'Window',
    translation: 'SEARCH.RESULT_WIDGET.NEWS.NAME',
    description: 'SEARCH.RESULT_WIDGET.NEWS.DESCRIPTION',
    icon: 'widgets',
  },
  'WatchlistWindow': {
    windowName: 'WatchlistWindow',
    itemType: 'Window',
    translation: 'SEARCH.RESULT_WIDGET.WATCHLIST.NAME',
    description: 'SEARCH.RESULT_WIDGET.WATCHLIST.DESCRIPTION',
    icon: 'widgets',
  },
  'UserlistWindow': {
    windowName: 'UserlistWindow',
    itemType: 'Window',
    translation: 'SEARCH.RESULT_WIDGET.USERLIST.NAME',
    description: 'SEARCH.RESULT_WIDGET.USERLIST.DESCRIPTION',
    icon: 'widgets',
  },
  'ChainsWindow': {
    windowName: 'ChainsWindow',
    itemType: 'Window',
    translation: 'SEARCH.RESULT_WIDGET.CHAINS.NAME',
    description: 'SEARCH.RESULT_WIDGET.CHAINS.DESCRIPTION',
    icon: 'widgets',
  }
};

export const SearchWindowItems: FullSearchWindowResultItem[] = Object.values(SearchWindowItemMap);

export enum SearchDataSource {
  NONE,
  SEARCH, // Data fetched from search
  HISTORY, // Data fetched from history storage
}

export const DefaultFeedScoreFactorItems: FeedScoreFactorItem[] = [
  { feed: 2343, feedCode: 'NDQB', factor: 1.3 },
  { feed: 2344, feedCode: 'NYSB', factor: 1.2 },
  { feed: 2345, feedCode: 'NMKB', factor: 1.2 },
  { feed: 2260, factor: 1.5 }, // IWT-1391 MOFU feed ranked higher
  { feed: 2412, factor: 1.5 }, // IWT-1391 IFMF feed ranked higher
];

export type SymbolResult = InfrontSDK.SearchResultItem | InfrontSDK.SymbolData;


export type SearchResultSymbolWatchlist = InfrontSDK.Watchlist & {
  hasInstrument: boolean,
  hide: Observable<boolean>,
};

// SearchResultItem typings
// @FIXME need to separate item.get() logic and actual interface
export const SdkSearchResultSymbolItemFields = {
  [InfrontSDK.SearchResultField.Feed]: 0,
  [InfrontSDK.SearchResultField.FullName]: '',
  [InfrontSDK.SearchResultField.SymbolType]: 0,
  [InfrontSDK.SearchResultField.ISIN]: '',
  [InfrontSDK.SearchResultField.Ticker]: '',
  [InfrontSDK.SearchResultField.SearchScore]: 0,
  [InfrontSDK.SearchResultField.SymbolClassification]: InfrontSDK.SymbolClassification.Unknown,
  [InfrontSDK.SearchResultField.FeedIsMTFSubMarket]: false,
  [InfrontSDK.SearchResultField.FeedIsMajorMarket]: false,
  [InfrontSDK.SearchResultField.FeedIsHidden]: false,
  [InfrontSDK.SearchResultField.IsTradable]: false,
  [InfrontSDK.SearchResultField.FeedAccess]: '',
  [InfrontSDK.SearchResultField.FeedDelayStr]: '',
  [InfrontSDK.SearchResultField.FeedDesc]: '',
  [InfrontSDK.SearchResultField.FeedExchange]: '',
  [InfrontSDK.SearchResultField.Currency]: '',

  // TODO: Use the native fields for 'FeedAccessStr' and 'Country' provided from the SDK when they are ready...
  ['FeedAccessStr']: ((item: InfrontSDK.SearchResultItem) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${item.get(InfrontSDK.SearchResultField.FeedAccess)} ${item.get(InfrontSDK.SearchResultField.FeedDelayStr)}`;
  }) as unknown as string,

  ['Country']: ((item: InfrontSDK.SearchResultItem) => {
    const countryOfIncorporation = item.get(InfrontSDK.SearchResultField.CountryOfIncorporation) as string;
    const country = item.get(InfrontSDK.SearchResultField.Country) as string;
    const feedCountry = item.get(InfrontSDK.SearchResultField.FeedCountry) as string;
    return countryOfIncorporation || country || feedCountry;
  }) as unknown as string,
  ['access']: ((item: InfrontSDK.SearchResultItem) => {
    /**
     * @TODO implement FeedAccessDesc in InfrontSDK.SearchResultField and replace logic here
     * also needs translation support
     */
    const feedAccess = item.get(InfrontSDK.SearchResultField.FeedAccess) as string;
    const feedDelayStr = item.get(InfrontSDK.SearchResultField.FeedDelayStr) as string;

    switch (feedAccess) {
      case InfrontSDK.FeedAccess.Delayed:
        return feedDelayStr || 'SEARCH.ITEM.ACCESS.DELAYED';
      case InfrontSDK.FeedAccess.NoAccess:
        return 'SEARCH.ITEM.ACCESS.NO_ACCESS';
      case InfrontSDK.FeedAccess.Realtime:
        return 'SEARCH.ITEM.ACCESS.REALTIME';
      default:
        return feedAccess;
    }
  }) as unknown as string,
  ['feed']: ((item: InfrontSDK.SearchResultItem): number => item.get(InfrontSDK.SearchResultField.Feed) as number) as unknown as number,
  ['ticker']: ((item: InfrontSDK.SearchResultItem): string => item.get(InfrontSDK.SearchResultField.Ticker) as string) as unknown as string,
  ['symbolClassification']: ((item: InfrontSDK.SearchResultItem): string =>
    item.get(InfrontSDK.SearchResultField.SymbolClassification) as string) as unknown as string,
};

export const SdkSearchResultMarketItemFields = {
  [InfrontSDK.FeedField.SearchScore]: 0,
  [InfrontSDK.FeedField.Feed]: 0,
  [InfrontSDK.FeedField.IsHidden]: false,
  [InfrontSDK.FeedField.Description]: '',
  [InfrontSDK.FeedField.ExchangeCode]: '',
  [InfrontSDK.FeedField.Access]: '',
  [InfrontSDK.FeedField.Country]: '',
  [InfrontSDK.FeedField.CountryName]: '',
  [InfrontSDK.FeedField.Provider]: '',
  ['feed']: ((item: InfrontSDK.SearchResultItem): number => item.get(InfrontSDK.FeedField.Feed) as number) as unknown as number,
};

export const FeedInfoToSdkSearchResultMarketItemMap = {
  // [InfrontSDK.FeedField.SearchScore]: 0,
  [InfrontSDK.FeedField.Feed]: (item: InfrontSDK.FeedInfo): number => item.feed,
  [InfrontSDK.FeedField.Description]: (item: InfrontSDK.FeedInfo): string => item.description,
  [InfrontSDK.FeedField.ExchangeCode]: (item: InfrontSDK.FeedInfo): string => item.feedCode,
  [InfrontSDK.FeedField.Access]: (item: InfrontSDK.FeedInfo): string => item.access,
  [InfrontSDK.FeedField.Country]: (item: InfrontSDK.FeedInfo): number => item.country,
  [InfrontSDK.FeedField.CountryName]: (item: InfrontSDK.FeedInfo): string => item.countryName,
  [InfrontSDK.FeedField.Provider]: (item: InfrontSDK.FeedInfo): string => item.provider,
  [InfrontSDK.BasicField.FeedAccessDesc]: (item: InfrontSDK.FeedInfo): string => item.access,
  ['ticker']: (item: InfrontSDK.FeedInfo): string => item.feedCode,
  ['feed']: (item: InfrontSDK.FeedInfo): number => item.feed,
};

export type SdkSymbolSearchResultItem = typeof SdkSearchResultSymbolItemFields & SdkSearchResultItemBase<InfrontSDK.SearchResultItemType.Symbol>;
export type SdkMarketSearchResultItem = typeof SdkSearchResultMarketItemFields & SdkSearchResultItemBase<InfrontSDK.SearchResultItemType.Market>;
export type WindowResultItem = FullSearchWindowResultItem; // NOSONAR keep redundant type alias
export type SdkSearchResultItem = SdkSymbolSearchResultItem | SdkMarketSearchResultItem;
export type SearchResultItem = SdkSymbolSearchResultItem | SdkMarketSearchResultItem | WindowResultItem;
type SdkSearchResultItemBase<T extends InfrontSDK.SearchResultItemType> = { itemType: T; };

export function transformSearchResultItemToHistoryItem(item: SdkSearchResultItem): SearchHistoryItem | undefined {
  if (isClassifiedInstrument(item)) {
    return { ticker: item.ticker, feed: item.feed, symbolClassification: item.symbolClassification };
  }
  return isMarket(item) ? { feed: item.feed } : undefined;
}

// ResultItem Typeguards
export function isWindowSearchResultItem(resultItem: SearchResultItem): resultItem is WindowResultItem {
  return resultItem?.itemType === 'Window';
}
export function isSdkSymbolSearchResultItem(resultItem: SearchResultItem): resultItem is SdkSymbolSearchResultItem {
  return resultItem?.itemType === InfrontSDK.SearchResultItemType.Symbol;
}
export function isSdkMarketSearchResultItem(resultItem: SearchResultItem): resultItem is SdkMarketSearchResultItem {
  return resultItem?.itemType === InfrontSDK.SearchResultItemType.Market;
}

export interface FullSearchResultItemProperties {
  expanded: boolean;
  expandedTab?: 'windows' | 'watchlist'; // windows should be default
}

export type FullSearchWindowResultItem<T extends WindowName = WindowName> = {
  windowName: T;
  itemType: 'Window';
  translation: string;
  description: string;
  icon: string;
  searchTerms?: string[];
};
export type FullSearchSymbolSearchResultItem = SdkSymbolSearchResultItem & FullSearchResultItemProperties;
export type FullSearchMarketSearchResultItem = SdkMarketSearchResultItem & FullSearchResultItemProperties;
export type FullSearchResultItem = FullSearchSymbolSearchResultItem | FullSearchMarketSearchResultItem | FullSearchWindowResultItem;

// Generic ResultGroup typings
export type SearchResultGroup = FullSearchResultGroup | CompactSearchResultGroup;
type SearchResultGroupBase<
  T extends InfrontSDK.SearchResultItemType,
  U extends T extends InfrontSDK.SearchResultItemType.Symbol
  ? SdkSymbolSearchResultItem
  : T extends InfrontSDK.SearchResultItemType.Market
  ? SdkMarketSearchResultItem
  : never
> = {
  title: string;
  itemType: T;
  items: U[];
  showMore: boolean;
  collapse: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
} & (T extends InfrontSDK.SearchResultItemType.Symbol ? { symbolClassificationList: InfrontSDK.SymbolClassification[]; } : {});

export type SymbolSearchResultGroup<T extends SdkSymbolSearchResultItem = SdkSymbolSearchResultItem> = SearchResultGroupBase<
  InfrontSDK.SearchResultItemType.Symbol,
  T
>;

export type MarketSearchResultGroup<T extends SdkMarketSearchResultItem = SdkMarketSearchResultItem> = SearchResultGroupBase<
  InfrontSDK.SearchResultItemType.Market,
  T
>;

export type WindowSearchResultGroup = {
  title: string;
  itemType: 'Window';
  items: FullSearchWindowResultItem[];
  showMore: boolean;
  collapse: boolean;
};

// ResultGroup typing implementations
export type CompactSearchResultGroup = SymbolSearchResultGroup | MarketSearchResultGroup;
export type FullSearchResultGroup = FullSearchMarketResultGroup | FullSearchSymbolResultGroup | FullSearchWindowResultGroup;
type FullSearchResultGroupExtendedProps = {
  highestSearchScore: number;
};
export type FullSearchMarketResultGroup = MarketSearchResultGroup<FullSearchMarketSearchResultItem> & FullSearchResultGroupExtendedProps;
export type FullSearchSymbolResultGroup = SymbolSearchResultGroup<FullSearchSymbolSearchResultItem> & FullSearchResultGroupExtendedProps;
export type FullSearchWindowResultGroup = WindowSearchResultGroup & FullSearchResultGroupExtendedProps;

export const ToggleShowMoreSearchResultsThreshold: Readonly<number> = 5;
export const MaxFullSearchResultGroupItemLimit: Readonly<number> = 25;

export function getHistoryFullSearchResultGroup(): FullSearchResultGroup {
  return {
    itemType: InfrontSDK.SearchResultItemType.Symbol,
    symbolClassificationList: [],
    title: 'SEARCH.RESULT_CATEGORIES.HISTORY',
    showMore: true,
    collapse: false,
    items: [],
    highestSearchScore: -1,
  };
}

export function getFullSearchResultGroups(): FullSearchResultGroup[] {
  return [
    {
      itemType: 'Window',
      title: 'SEARCH.RESULT_CATEGORIES.WINDOW',
      showMore: false,
      collapse: false,
      items: [],
      highestSearchScore: -1,
    },
    {
      itemType: InfrontSDK.SearchResultItemType.Market,
      title: 'SEARCH.RESULT_CATEGORIES.MARKETS',
      showMore: false,
      collapse: false,
      items: [],
      highestSearchScore: -1,
    },
    {
      itemType: InfrontSDK.SearchResultItemType.Symbol,
      title: 'SEARCH.RESULT_CATEGORIES.EQUITIES',
      showMore: false,
      collapse: false,
      symbolClassificationList: [InfrontSDK.SymbolClassification.Stock],
      items: [],
      highestSearchScore: -1,
    },
    {
      itemType: InfrontSDK.SearchResultItemType.Symbol,
      title: 'SEARCH.RESULT_CATEGORIES.INDICES',
      showMore: false,
      collapse: false,
      symbolClassificationList: [InfrontSDK.SymbolClassification.Index],
      items: [],
      highestSearchScore: -1,
    },
    {
      itemType: InfrontSDK.SearchResultItemType.Symbol,
      title: 'SEARCH.RESULT_CATEGORIES.FUNDS',
      showMore: false,
      collapse: false,
      symbolClassificationList: [InfrontSDK.SymbolClassification.Fund],
      items: [],
      highestSearchScore: -1,
    },
    {
      itemType: InfrontSDK.SearchResultItemType.Symbol,
      title: 'SEARCH.RESULT_CATEGORIES.BONDS',
      showMore: false,
      collapse: false,
      symbolClassificationList: [InfrontSDK.SymbolClassification.Bond],
      items: [],
      highestSearchScore: -1,
    },
    {
      itemType: InfrontSDK.SearchResultItemType.Symbol,
      title: 'SEARCH.RESULT_CATEGORIES.DERIVATIVES',
      showMore: false,
      collapse: false,
      symbolClassificationList: [InfrontSDK.SymbolClassification.Derivative],
      items: [],
      highestSearchScore: -1,
    },
    {
      itemType: InfrontSDK.SearchResultItemType.Symbol,
      title: 'SEARCH.RESULT_CATEGORIES.CERTIFICATES',
      showMore: false,
      collapse: false,
      symbolClassificationList: [InfrontSDK.SymbolClassification.Certificate],
      items: [],
      highestSearchScore: -1,
    },
    {
      itemType: InfrontSDK.SearchResultItemType.Symbol,
      title: 'SEARCH.RESULT_CATEGORIES.FUTURES',
      showMore: false,
      collapse: false,
      symbolClassificationList: [InfrontSDK.SymbolClassification.Future],
      items: [],
      highestSearchScore: -1,
    },
    {
      itemType: InfrontSDK.SearchResultItemType.Symbol,
      title: 'SEARCH.RESULT_CATEGORIES.ETFS',
      showMore: false,
      collapse: false,
      symbolClassificationList: [InfrontSDK.SymbolClassification.ETF],
      items: [],
      highestSearchScore: -1,
    },
    {
      itemType: InfrontSDK.SearchResultItemType.Symbol,
      title: 'SEARCH.RESULT_CATEGORIES.COMMODITIES',
      showMore: false,
      collapse: false,
      symbolClassificationList: [InfrontSDK.SymbolClassification.Commodity],
      items: [],
      highestSearchScore: -1,
    },
    {
      itemType: InfrontSDK.SearchResultItemType.Symbol,
      title: 'SEARCH.RESULT_CATEGORIES.FOREX',
      showMore: false,
      collapse: false,
      symbolClassificationList: [InfrontSDK.SymbolClassification.Forex],
      items: [],
      highestSearchScore: -1,
    },
    {
      itemType: InfrontSDK.SearchResultItemType.Symbol,
      title: 'SEARCH.RESULT_CATEGORIES.CFDS',
      showMore: false,
      collapse: false,
      symbolClassificationList: [InfrontSDK.SymbolClassification.CFD],
      items: [],
      highestSearchScore: -1,
    },
    {
      itemType: InfrontSDK.SearchResultItemType.Symbol,
      title: 'SEARCH.RESULT_CATEGORIES.INDICATORS',
      showMore: false,
      collapse: false,
      symbolClassificationList: [InfrontSDK.SymbolClassification.Indicator],
      items: [],
      highestSearchScore: -1,
    },
    {
      itemType: InfrontSDK.SearchResultItemType.Symbol,
      title: 'SEARCH.RESULT_CATEGORIES.UNCATEGORIZED',
      showMore: false,
      collapse: false,
      symbolClassificationList: [InfrontSDK.SymbolClassification.Unknown],
      items: [],
      highestSearchScore: -2, // keep at the bottom
    },
  ];
}

export function updateHighestSearchScore(group: FullSearchResultGroup, item: (SdkSearchResultItem | FullSearchWindowResultItem)): void {
  if (isWindowSearchResultItem(item)) {
    group.highestSearchScore = 999999;
  } else if (item.SearchScore > group.highestSearchScore) {
    group.highestSearchScore = item.SearchScore;
  }
}

export function findSearchResultGroup(item: (SdkSearchResultItem | FullSearchWindowResultItem), groups: SearchResultGroup[]): SearchResultGroup | undefined {
  // @FIXME dynamic return type
  return groups.find((group) => {
    if (group.itemType !== item.itemType) {
      return false;
    }
    return group.itemType === InfrontSDK.SearchResultItemType.Symbol
      ? group.symbolClassificationList.includes((item as SdkSymbolSearchResultItem).SymbolClassification)
      : true;
  });
}

export const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter', [style({ opacity: 0 }), stagger('30ms', animate('300ms ease-out', style({ opacity: 1 })))], { optional: true }),
    query(':leave', animate('100ms', style({ opacity: 0 })), { optional: true }),
  ]),
]);

// @TODO refactor
export type SearchDataParams = {
  ticker: string;
  feed: number;
  feedDescription: string;
  fullName: string;
  access: string;
  tradable: string;

  symbolClassification?: InfrontSDK.SymbolClassification;
  itemType?: InfrontSDK.SearchResultItemType;
  feedIsHidden?: boolean;
  icon?: string;
  description?: string;
  isin?: string;
  currency?: string;
  feedExchange?: string;
  isoCountry?: string;
};

// SearchConfig
export type SearchConfig = SearchConfigSymbol | SearchConfigMarket | SearchConfigNews;
export type SearchConfigSymbol = SearchConfigBase<{ [InfrontSDK.SearchType.Symbol]: true; }> & { symbolResultFields?: InfrontSDK.SearchResultField[]; };
export type SearchConfigMarket = SearchConfigBase<{ [InfrontSDK.SearchType.Market]: true; }>;
export type SearchConfigNews = SearchConfigBase<{ [InfrontSDK.SearchType.News]: true; }>;
type SearchConfigBase<ST extends { [SearchType in InfrontSDK.SearchType]?: boolean }> = {
  feedScoreFactorItems?: FeedScoreFactorItem[];
  searchType: ST; // replace with advanced generic SearchRequestOptions?
  limit?: number;
  subscribe?: boolean;
  history?: boolean | string | SearchHistoryConfig<ST>; // true -> global default history, string -> custom storage key, obj -> complex cfg
  onInputCallback?: (value: string, proceed: typeof Function) => unknown;
  // eslint-disable-next-line @typescript-eslint/ban-types
} & (ST extends { [InfrontSDK.SearchType.Symbol]: true; } ? { searchSubTypes?: InfrontSDK.SymbolClassification[]; } : {});

export const DefaultSearchConfig: Readonly<SearchConfig> = {
  feedScoreFactorItems: DefaultFeedScoreFactorItems,
  searchType: {
    [InfrontSDK.SearchType.Symbol]: true,
  },
  history: true, // global shared history with no predefined history instruments
};

export function getDefaultSearchConfig(): SearchConfig {
  return InfrontUtil.deepCopy(DefaultSearchConfig) as SearchConfig;
}

export type SearchHistoryItem = Market | ClassifiedInstrument; // @TODO add (| News)

export interface SearchHistoryData {
  [key: string]: SearchHistoryItem[];
}

// HistoryConfig
export enum HistoryType {
  GLOBAL = 'global', // Global history storage, shared across all widgets/components
  COMPONENT = 'component', // Shared across all instances of the same component
  // INSTANCE = 'instance', // Component/Widget instance based storage (for example scoped via widget uuid)
  CUSTOM = 'customKey', // Provide custom storage key, allows for maximum flexibility
}

export type SearchHistoryConfig<ST extends { [SearchType in InfrontSDK.SearchType]?: boolean }> =
  | GlobalSharedSearchHistoryConfig<ST>
  | ComponentSharedSearchHistoryConfig<ST>
  // | InstanceSharedSearchHistoryConfig<ST>
  | CustomKeySharedSearchHistoryConfig<ST>;
export type GlobalSharedSearchHistoryConfig<ST extends { [SearchType in InfrontSDK.SearchType]?: boolean }> = SearchHistoryConfigBase<
  ST,
  HistoryType.GLOBAL
>;
export type ComponentSharedSearchHistoryConfig<ST extends { [SearchType in InfrontSDK.SearchType]?: boolean }> = SearchHistoryConfigBase<
  ST,
  HistoryType.COMPONENT
>;
// export type InstanceSharedSearchHistoryConfig<ST extends { [SearchType in InfrontSDK.SearchType]?: boolean }> = SearchHistoryConfigBase<
//   ST,
//   HistoryType.INSTANCE
// >;
export type CustomKeySharedSearchHistoryConfig<ST extends { [SearchType in InfrontSDK.SearchType]?: boolean }> = SearchHistoryConfigBase<
  ST,
  HistoryType.CUSTOM
>;

type SearchHistoryConfigBase<ST extends { [SearchType in InfrontSDK.SearchType]?: boolean }, SHT extends HistoryType> = {
  historyType: SHT;
  predefinedItems?: (ST extends { [InfrontSDK.SearchType.Symbol]: true; }
    ? Instrument
    : ST extends { [InfrontSDK.SearchType.Market]: true; }
    ? Market
    : never)[];
  // eslint-disable-next-line @typescript-eslint/ban-types
} & (SHT extends HistoryType.CUSTOM ? { storageKey: string; } : {});

type GetHistoryTypeKey = {
  [P in HistoryType]: P extends HistoryType.COMPONENT
  ? (componentRef: object) => string | undefined
  : // : P extends HistoryType.INSTANCE
  // ? (componentRef: object) => string | undefined
  P extends HistoryType.CUSTOM
  ? (customKey: string) => string
  : string;
};

const StorageHistoryKeyPrefix = 'search~history~';

// @FIXME use defined prefix for all keys to reduce namespace collisions
export const StorageHistoryKeyMap: GetHistoryTypeKey = {
  [HistoryType.GLOBAL]: `${StorageHistoryKeyPrefix}global`,
  [HistoryType.COMPONENT]: (componentRef: object) =>
    'constructor' in componentRef ? `${StorageHistoryKeyPrefix}${componentRef['constructor']['name']}` : undefined,
  // [HistoryType.INSTANCE]: (componentRef: object & ({ widget: Widget } | { uuid: string })) =>
  //   'widget' in componentRef
  //     ? `${StorageHistoryKeyPrefix}${componentRef.widget.id}`
  //     : 'uuid' in componentRef
  //     ? `${StorageHistoryKeyPrefix}${componentRef.uuid}`
  //     : undefined,
  [HistoryType.CUSTOM]: (customKey: string) => customKey,
};

export function getHistoryStorageKey(historyConfig: SearchConfig['history'], componentRef?: unknown): string | undefined {
  let storageKey: string | undefined;
  switch (typeof historyConfig) {
    case 'boolean': // load default global history w/o predefined instruments
      storageKey = StorageHistoryKeyMap[HistoryType.GLOBAL];
      break;
    case 'string': // provided custom key w/o predefined instruments
      storageKey = StorageHistoryKeyMap[HistoryType.CUSTOM](historyConfig);
      break;
    case 'object':
      switch (historyConfig.historyType) {
        case HistoryType.COMPONENT:
          storageKey = StorageHistoryKeyMap[HistoryType.COMPONENT](componentRef as object);
          break;
        // case HistoryType.INSTANCE:
        //   storageKey = StorageHistoryKeyMap[HistoryType.INSTANCE](componentRef as object);
        //   break;
        case HistoryType.GLOBAL:
        default:
          storageKey = StorageHistoryKeyMap[HistoryType.GLOBAL];
          break;
      }
      break;
  }
  return storageKey;
}

/** Either the storageKey: string or the componentRef: object */
export type StorageKeyOrComponentRef = string | object | undefined;

export interface FeedScoreFactorItem {
  feed: number;
  feedCode?: string;
  factor: number;
}

const SearchLimit: Readonly<{ min: number; max: number; }> = {
  min: 1,
  max: 50,
};

export function getSearchLimit(): { min: number; max: number; } {
  return InfrontUtil.deepCopy(SearchLimit) as { min: number; max: number; };
}

export const SearchHistoryItemTypeLimit: Readonly<number> = 50; // Applies in case of Symbol to each SymbolClassification

export const DefaultSymbolSearchResultFields: Readonly<InfrontSDK.SearchResultField[]> = [
  InfrontSDK.SearchResultField.FullName,
  InfrontSDK.SearchResultField.Ticker,
  InfrontSDK.SearchResultField.ISIN,
  InfrontSDK.SearchResultField.Feed,
  InfrontSDK.SearchResultField.SymbolType,
  InfrontSDK.SearchResultField.SearchScore,
  InfrontSDK.SearchResultField.Currency,
  InfrontSDK.SearchResultField.FeedIsMTFSubMarket,
  InfrontSDK.SearchResultField.FeedIsMajorMarket,
  InfrontSDK.SearchResultField.SymbolClassification,
  InfrontSDK.SearchResultField.IsTradable,
  InfrontSDK.SearchResultField.FeedAccess,
  InfrontSDK.SearchResultField.FeedDelayStr,
  InfrontSDK.SearchResultField.FeedDesc,
  InfrontSDK.SearchResultField.FeedExchange,
];

export function getDefaultSymbolSearchResultFields(): InfrontSDK.SearchResultField[] {
  return InfrontUtil.deepCopy(DefaultSymbolSearchResultFields) as InfrontSDK.SearchResultField[];
}

export const MinimumSymbolSearchResultFields: Readonly<InfrontSDK.SearchResultField[]> = [
  InfrontSDK.SearchResultField.Ticker,
  InfrontSDK.SearchResultField.Feed,
  InfrontSDK.SearchResultField.SymbolType,
  InfrontSDK.SearchResultField.SymbolSubType,
  InfrontSDK.SearchResultField.SymbolClassification,
  InfrontSDK.SearchResultField.FeedDesc,
  InfrontSDK.SearchResultField.FeedAccess,
  InfrontSDK.SearchResultField.FeedDelayStr,
];

export function getMinimumSymbolSearchResultFields(): InfrontSDK.SearchResultField[] {
  return InfrontUtil.deepCopy(MinimumSymbolSearchResultFields) as InfrontSDK.SearchResultField[];
}
