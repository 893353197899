<div *ngIf="showLabel"
  class="wt-portfolio-single-value__label"
  [ngClass]="labelClassList">
  <ng-container *ngIf="customLabel">
    {{ customLabel | translate }}
  </ng-container>
  <ng-container *ngIf="!customLabel && PortfolioValueTranslationLabels[portfolioValue] as defaultLabel">
    {{ defaultLabel | translate }}
  </ng-container>
</div>
<wt-wtk-widget-wrapper
  [ngClass]=" widgetClassList"
  [widget]="portfolioSingleValueWidget"
  #wtkWidget></wt-wtk-widget-wrapper>