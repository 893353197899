import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, ElementRef, inject, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfrontUtil } from '@infront/sdk';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { DashboardService } from '@infront/ngx-dashboards-fx';
import { filterDashboard, StoreService } from '../../services/store.service';
import { TradingService } from '../../services/trading.service';
import { createTab } from '../../shared/models/tab.model';
import { Dashboard, DashboardType } from '../../state-model/dashboard.model';
import { filterUndefined } from '../../util/rxjs';
import { InstrumentDashboardService } from '../instrument-dashboard.service';
import { getDashboardIcon, getDashboardIconForLevel } from '../../shared/dashboard-icons';

const TABS_CONTAINER_MARGIN_RIGHT = 220;
const LIST_ITEM_WIDTH_MARGIN = 5;

@Component({
  selector: 'wt-dashboard-tabs',
  templateUrl: './dashboard-tabs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardTabsComponent implements OnDestroy {
  private readonly storeService: StoreService = inject(StoreService);
  private readonly tradingService: TradingService = inject(TradingService);
  private readonly instrumentDashboardService: InstrumentDashboardService = inject(InstrumentDashboardService);
  private readonly dashboardService: DashboardService = inject(DashboardService);
  readonly dialog: MatDialog = inject(MatDialog);

  @ViewChild('tabsContainer') tabsContainer: ElementRef<HTMLElement>;
  @ViewChildren('includeInWidthCalculation') widthItemRefs: QueryList<ElementRef<HTMLElement>>;

  readonly portfolioDashboard$ = this.storeService.portfolioDashboard$;

  private readonly resizeAction = new BehaviorSubject<void>(undefined);
  private readonly offsetLeftAction = new BehaviorSubject<number>(0);

  readonly dynamicDashboards$ = combineLatest([this.storeService.dashboards$, this.resizeAction]).pipe(switchMap(([dashboards]) => {
    const tabs = dashboards.map(db => ({ ...createTab(db) }));
    if (!this.widthItemRefs?.first) {
      return of(tabs);
    }
    const usedWidth = this.widthItemRefs.reduce((acc, item) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/restrict-plus-operands
      return (item.nativeElement?.clientWidth ?? 0) + acc;
    }, 0);

    const parentWidth = this.tabsContainer?.nativeElement.clientWidth - TABS_CONTAINER_MARGIN_RIGHT;

    let savedLengthFromRemovedItems = 0;
    if (usedWidth > parentWidth) { // dashboardTabs are using too much width - mark some as hasSpace = false;

      const hidableTabs = this.widthItemRefs.filter(element => {
        return element.nativeElement?.classList?.contains('cdk-drag'); // tags that can be hidden are the same that can be dragged
      });

      let currentItemWidth = 0;

      // adjust hasSpace from the end of hidableTabs array
      let i = hidableTabs.length - 1;

      while (i >= 0 && i < tabs.length) {
        const element = hidableTabs[i];
        currentItemWidth = element.nativeElement.clientWidth + LIST_ITEM_WIDTH_MARGIN;
        savedLengthFromRemovedItems = savedLengthFromRemovedItems + currentItemWidth;
        const newUsedWidth = usedWidth - savedLengthFromRemovedItems;
        if (newUsedWidth > parentWidth) {
          tabs[i].hasSpace = false;
          i--;
          continue;
        }
        if (tabs[i].hasSpace) {
          savedLengthFromRemovedItems = savedLengthFromRemovedItems - currentItemWidth;
          break;
        }
        tabs[i].hasSpace = true;
        i--;
      }
      this.offsetLeftAction.next(-savedLengthFromRemovedItems);
    } else if (this.offsetLeftAction.value !== 0) {
      // always reset offset if all tabs have space
      this.offsetLeftAction.next(0);
    }
    return of(tabs);
  }));

  readonly vm$ = combineLatest([
    this.tradingService.hasTradingFeature$,
    this.storeService.unfilteredDashboards$,
    this.dynamicDashboards$,
    this.storeService.portfolioDashboard$,
    this.instrumentDashboardService.instrumentDashboard$,
    this.storeService.selectedDashboard$.pipe(filterUndefined()),
    this.offsetLeftAction
  ]).pipe(
    map(([hasTradingFeature, unfilteredDashboards, dashboards, portfolioDashboard, instrumentDashboard, selectedDashboard, offsetLeft]) => ({
      hasTradingFeature,
      unfilteredDashboards,
      dashboards,
      portfolioDashboard,
      instrumentDashboard,
      selectedDashboard,
      offsetLeft
    }))
  );

  readonly DashboardType = DashboardType;

  private readonly resizeObserver: ResizeObserver = new ResizeObserver(() => {
    this.resizeAction.next();
  });

  constructor() {
    this.resizeObserver.observe(document.body);
  }

  ngOnDestroy(): void {
    this.resizeAction.complete();
    this.offsetLeftAction.complete();
  }

  drop(event: CdkDragDrop<string[]>, unfilteredDashboards: Dashboard[]): void {
    // deep copy the dashboards, as due to references the index properties in former "states" would also be changed!
    const dashboardsCopy = InfrontUtil.deepCopy(unfilteredDashboards) as Dashboard[];
    const toBeSortedDashboards: Dashboard[] = [];
    const untouchedDashboards: Dashboard[] = [];

    dashboardsCopy.forEach((dashboard) => {
      if (filterDashboard(dashboard)) {
        toBeSortedDashboards.push(dashboard);
      } else {
        untouchedDashboards.push(dashboard);
      }
    });

    moveItemInArray(toBeSortedDashboards, event.previousIndex, event.currentIndex);
    toBeSortedDashboards.forEach((_d, i, array) => {
      array[i].index = i;
    }); // update index

    const finalDashboardsOrder: Dashboard[] = [...toBeSortedDashboards, ...untouchedDashboards];
    this.storeService.updateDashboards(finalDashboardsOrder);
  }

  onSelect(dashboard: Dashboard): void {
    this.storeService.selectDashboard(dashboard);
  }

  onPinDashboard(dashboard: Dashboard) {
    this.storeService.cloneDashboard({ ...dashboard, type: DashboardType.template }, `${dashboard.name}`);
    // FIXME: for now only delete "Instrument Dashboards"!
    this.storeService.deleteDashboardByType(DashboardType.instrument);
  }

  deleteDashboard(dashboard: Dashboard) {
    this.storeService.deleteDashboard(dashboard);
  }

  iconFor(dashboard: Dashboard): string {
    const dashboardRef = this.dashboardService.getRef(dashboard.id);
    if (dashboardRef) {
      return getDashboardIcon(dashboardRef);
    }
    return getDashboardIconForLevel(dashboard.level);
  }

  trackById(index: number, item: Dashboard): string {
    return item.id;
  }
}
