import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChartComponent } from './chart.component';
import { ChartWrapperModule } from '../../wrappers/chart-wrapper/chart-wrapper.module';

@NgModule({
  declarations: [ChartComponent],
  imports: [CommonModule, ChartWrapperModule],
})
export class ChartModule {}
