<!-- Widget Title Bar (only display title) -->
<ng-container *ngIf="window.dragEnabled === false && window.layerIndex !== 2">
  <div class="widget-title-bar__title gridster-item-content">
    <!-- Title label -->
    <div class="widget-title-bar-label gridster-item-content">
      {{
      "DASHBOARD_WINDOW.HEADER.WIDGET_LABEL." +
      ((window.selectedWidgetName !== "Exposure" ? window.selectedWidgetName : $any(widget.settings)?.selectedGrid)
      || "NA" | widgetLabel: classification) | translate
      }}
    </div>
  </div>
</ng-container>

<!-- Widget Title Bar (display search and buttons) -->
<ng-container *ngIf="window.dragEnabled !== false || window.layerIndex === 2">
  <!-- Widget Title Bar Left (__title)-->
  <div class="widget-title-bar__title gridster-item-content"
    [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false">
    <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
      <div class="wt-flex-column wt-flex-shrink">
        <mat-icon
          class="widget-title-bar-icon widget-title-bar-icon--clickable widget-title-bar-icon--search gridster-item-content"
          fontIcon="search" (click)="onSearchClick()">
        </mat-icon>
      </div>

      <div class="wt-flex-column">
        <button
          class="wt-button widget-title-bar-label widget-title-bar-label--clickable gridster-item-content"
          [title]="(symbolInfo$ | async)?.preDisplayTicker ?? ''" (click)="onSearchClick()">
          {{ (symbolInfo$ | async)?.preDisplayTicker }} {{ "DASHBOARD_WINDOW.HEADER.WINDOW_LABEL." + (window.name ||
          "NA") | translate }}
        </button>

        <ng-container *ngIf="symbolInfo$ | async as symbolInfo">
          <span *ngIf="symbolInfo.feedIsHidden; else clickableFeed" class="widget-title-bar-feed">
            {{ symbolInfo.feedExchange }}
          </span>

          <ng-template #clickableFeed>
            <button class="wt-button widget-title-bar-feed widget-title-bar-feed--clickable"
              (click)="onSpawnMarketWindow($event)">
              {{ symbolInfo.feedExchange }}
            </button>
          </ng-template>
        </ng-container>
      </div>

      <div class="wt-flex-column wt-flex-shrink">
        <!-- Widget Title Bar Right (__buttons)-->
        <div class="widget-title-bar__buttons gridster-item-content">
          <ul class="wt-list-inline">
            <li class="wt-list-inline__item gridster-item-content">
              <wt-link-channel-menu [window]="window" [dashboard]="dashboard"></wt-link-channel-menu>
            </li>
            <li class="wt-list-inline__item gridster-item-content">
              <wt-context-menu class="gridster-item-content" [context]="{ widget, dashboard }"></wt-context-menu>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-container>
