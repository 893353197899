import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { PipesModule } from '../../pipes/pipes.module';
import { SettingsMenuContentComponent } from './settings-menu-content.component';
import { GeneralSettingsModule } from '../general-settings';

@NgModule({
  declarations: [SettingsMenuContentComponent],
  imports: [
    CommonModule,
    GeneralSettingsModule,
    OverlayModule,
    MatIconModule,
    MatSelectModule,
    MatSlideToggleModule,
    PipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [SettingsMenuContentComponent],
})
export class SettingsMenuModule { }
