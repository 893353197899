import { ChangeDetectionStrategy, Component, Input, OnDestroy, inject } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { map, take, takeUntil, tap } from 'rxjs/operators';

import { Subject } from 'rxjs';
import { StoreService } from '../../../services/store.service';
import { TradingService } from '../../../services/trading.service';
import { DashboardType } from '../../../state-model/dashboard.model';
import { UserSettingsService } from './../../../services/user-settings.service';
import { DROPDOWN_PORTFOLIO_CLASS_MOD } from './dropdown-portfolio.model';


@Component({
  selector: 'wt-dropdown-portfolio',
  templateUrl: './dropdown-portfolio.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownPortfolioComponent implements OnDestroy {
  private readonly tradingService: TradingService = inject(TradingService);
  private readonly userSettingsService: UserSettingsService = inject(UserSettingsService);
  private readonly storeService: StoreService = inject(StoreService);

  @Input() matDropdownIcon?: string = 'arrow_drop_down';
  @Input() selectPortfolioDashboardOnClick = true;
  @Input() selectedPortfolio: InfrontSDK.Trading.Portfolio | undefined | null;
  @Input() hasPortfolioLabel = false;
  @Input() hasPortfolioIcon = true;

  readonly availablePortfolios$ = this.tradingService.portfolioList$.pipe(
    tap((portfolioList) => {
      const currentPortfolioId = this.userSettingsService.getValue('tradingSelectedPortfolioId');
      const currentPortfolio = portfolioList?.find((p) => p.name === currentPortfolioId) ?? portfolioList?.[0];
      if (!this.selectedPortfolio) {
        this.selectedPortfolio = currentPortfolio;
      }
      this.setEmitCurrentPortfolio(currentPortfolio);
    })
  );

  readonly hasPortfolioDashboardSelected$ = this.storeService.currentDashboard$.pipe(
    map((dashboard) => ({ selected: dashboard?.type === DashboardType.portfolio }))
  );

  readonly matMenuScopeClass = DROPDOWN_PORTFOLIO_CLASS_MOD;

  private readonly ngUnsubscribe = new Subject<void>();

  onPortfolioSelected(portfolio: InfrontSDK.Trading.Portfolio): void {
    this.setEmitCurrentPortfolio(portfolio);
  }

  private setEmitCurrentPortfolio(portfolio: InfrontSDK.Trading.Portfolio | undefined): void {
    if (portfolio?.name) {
      this.userSettingsService.setValue('tradingSelectedPortfolioId', portfolio.name); // portfolio.name is used as id
      this.selectedPortfolio = portfolio;
    }
  }

  /** Redirects the user to the portfolio dashboard if it hasn't already been selected */
  onPortfolioClick(): void {
    this.storeService.portfolioDashboard$.pipe(
      take(1),
      tap((pfDashboard) => pfDashboard ? this.storeService.selectDashboard(pfDashboard) : undefined),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
