import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, inject, OnDestroy } from '@angular/core';
import { InfrontUtil } from '@infront/sdk';
import { PreferencesMap } from '@vwd/microfrontend-core';

import { BehaviorSubject } from 'rxjs';
import { skip, tap } from 'rxjs/operators';

import { InstrumentDashboardService } from '../../dashboard/instrument-dashboard.service';
import { DefaultFeedScoreFactorItems } from '../../search/search.model';
import { StorageService, UserSettingsStorageData } from '../../services/storage.service';
import { Instrument } from '../../state-model/window.model';
import { LOCALE_ID$ } from '../../util/locale';
import { MAX_ITEM_COUNT, SelectedItem } from './instrument-dock.model';

@Component({
  selector: 'wt-instrument-dock',
  templateUrl: './instrument-dock.component.html',
})
export class InstrumentDockComponent implements OnDestroy {
  private readonly storageService: StorageService = inject(StorageService);
  private readonly instrumentDashboardService: InstrumentDashboardService = inject(InstrumentDashboardService);

  // reloadAction and localeId$ are used for reloading the instrument-dock items on locale change!
  // They need their own reloadAction, as they are not part of dashboard!
  readonly reloadAction = new BehaviorSubject<boolean>(false);
  readonly localeId$ = inject(LOCALE_ID$).pipe(
    skip(1),
    tap((_locale: string) => {
      this.reloadAction.next(true);
      InfrontUtil.setZeroTimeout(() => this.reloadAction.next(false));
    }),
  );

  maxItemCount = MAX_ITEM_COUNT;
  itemList: (Instrument | undefined)[] = [];
  showConfig = false;

  // IWT-799 Dropdown menu - have it open to the left of the cogwheel
  readonly overlayPositions = [new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }, 0, 0)];

  private readonly userSettingsStorage = this.storageService.getUserSettingsStorage() as PreferencesMap<UserSettingsStorageData>;
  private userSettingsWatchDisposable = this.userSettingsStorage.watch('instrumentDockItemList', (result) =>
    this.itemList = Array.isArray(result) ? [...result] : []
  );

  readonly DefaultFeedScoreFactorItems = DefaultFeedScoreFactorItems;

  ngOnDestroy(): void {
    this.userSettingsWatchDisposable?.dispose();
    this.reloadAction?.next(false);
    this.reloadAction?.complete();
  }

  onTickerClicked(instrument: Instrument): void {
    this.instrumentDashboardService.addInstrumentDashboardAsync(instrument);
  }

  onAddItem(selectedItem: SelectedItem, index: number): void {
    const instrument: Instrument = { feed: selectedItem.Feed, ticker: selectedItem.Ticker };
    this.itemList[index] = instrument;
    void this.userSettingsStorage.set('instrumentDockItemList', this.itemList);
  }

  onDeleteItem(index: number): void {
    this.itemList[index] = {} as Instrument;
    // with no valid instrument item, we want an empty itemList to display the "Add Instrument" button!
    if (!this.itemList.filter((i) => i?.ticker).length) {
      this.itemList = [];
    }
    void this.userSettingsStorage.set('instrumentDockItemList', this.itemList);
  }

  closeDropdown(): void {
    this.showConfig = false;
  }
}
