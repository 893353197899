<div class="wt-dropdown">
  <!-- menu wrapper -->
  <div *ngIf="dropdownTemplate" class="wt-dropdown__wrapper" [matMenuTriggerFor]="dropdownTemplate ? null : menu"
    (menuClosed)="dropdownTemplate ? undefined : menuClosed.emit()"
    (menuOpened)="dropdownTemplate ? undefined : menuOpened.emit()">
    <ul class="wt-list-inline">
      <ng-container
        *ngTemplateOutlet="dropdownTemplate; context: { $implicit: { dropdownLabel, menu, menuClosed, menuOpened } }"></ng-container>
    </ul>
  </div>

  <!-- default menu wrapper (using @Input() label and icon) -->
  <div *ngIf="!dropdownTemplate" class="wt-dropdown__wrapper" [matMenuTriggerFor]="dropdownTemplate ? null : menu"
    (menuClosed)="dropdownTemplate ? undefined : menuClosed.emit()"
    (menuOpened)="dropdownTemplate ? undefined : menuOpened.emit()">
    <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
      <div *ngIf="matDropdownIcon" class="wt-flex-column wt-flex-shrink">
        <mat-icon *ngIf="isSvgIcon" class="wt-dropdown-header-icon" [svgIcon]="matDropdownIcon"></mat-icon>
        <mat-icon *ngIf="!isSvgIcon" class="wt-dropdown-header-icon" [fontIcon]="matDropdownIcon"></mat-icon>
      </div>

      <div class="wt-flex-column">
        <span class="wt-dropdown-label">
          {{ (useSelectedItemLabel
          ? (selectedItem != undefined ? (selectedItem.dropdownLabel != undefined ? (selectedItem.translateDropdownLabel
          ? (selectedItem.dropdownLabel | translate) : selectedItem.dropdownLabel) : (selectedItem.itemLabel !=
          undefined ? (selectedItem.translateItemLabel ? (selectedItem.itemLabel | translate) : selectedItem.itemLabel)
          : undefined)) : undefined)
          : (dropdownLabel != undefined ? (translateDropdownLabel ? (dropdownLabel | translate): dropdownLabel) :
          undefined))
          || (translateNoDropdownLabelPlaceholder != undefined ? (noDropdownLabelPlaceholder | translate):
          noDropdownLabelPlaceholder) }}
        </span>
      </div>
    </div>
  </div>

  <mat-menu #menu="matMenu" class="wt-dropdown__menu" #matMenu>
    <!-- item content -->
    <!-- ? Why is watchlist classes here? use generic classes instead-->
    <ul class="wt-list" [ngStyle]="{ 'max-height': height ? height + 'px' : undefined }">
      <!-- pre item -->
      <li *ngIf="preItemTemplate" class="wt-dropdown__item-content wt-dropdown-watchlist__item" mat-menu-item>
        <ng-content *ngTemplateOutlet="preItemTemplate"></ng-content>
      </li>

      <!-- entries -->
      <li class="wt-dropdown__item-content wt-dropdown-watchlist__item" *ngFor="let item of items"
        (click)="!isDropdownFolder(item) && itemSelected.emit(item)" mat-menu-item>

        <!-- item  -->
        <ng-container *ngIf="isDropdownItem(item)">
          <ng-container *ngIf="itemTemplate">
            <ng-container
              *ngTemplateOutlet="itemTemplate; context: { $implicit: item, isSubItem: false }"></ng-container>
          </ng-container>

          <button *ngIf="!itemTemplate" class="mat-menu-item wt-dropdown-watchlist__item-button">
            <span class="wt-dropdown-label">{{(item.itemLabel ? (item.translateItemLabel ? (item.itemLabel | translate)
              : item.itemLabel) : translateNoItemLabelPlaceholder ? (noItemLabelPlaceholder | translate) :
              noItemLabelPlaceholder)}}
            </span>
          </button>
        </ng-container>

        <!-- folder -->
        <ng-container *ngIf="isDropdownFolder(item)">
          <button class="wt-dropdown-watchlist__item-button" type="button" mat-menu-item [matMenuTriggerFor]="subMenu">
            <mat-icon *ngIf="matSubMenuIcon" class="wt-dropdown-icon" [fontIcon]="matSubMenuIcon"></mat-icon>
            <span class="wt-dropdown-label">{{(item.folderLabel ? (item.translateFolderLabel ? (item.folderLabel |
              translate)
              : item.folderLabel) : translateNoItemLabelPlaceholder ? (noItemLabelPlaceholder | translate) :
              noItemLabelPlaceholder)}}
            </span>
          </button>

          <mat-menu #subMenu="matMenu" class="wt-dropdown__menu wt-dropdown__submenu">
            <ul class="wt-list wt-dropdown-watchlist" [ngStyle]="{ 'max-height': height ? height + 'px' : undefined }">
              <li class="wt-dropdown__item-content wt-dropdown-watchlist__item" *ngFor="let subItem of item.subItems"
                mat-menu-item>
                <ng-container *ngIf="itemTemplate">
                  <ng-container
                    *ngTemplateOutlet="itemTemplate; context: { $implicit: subItem, isSubItem: true }"></ng-container>
                </ng-container>

                <button *ngIf="!itemTemplate" class="mat-menu-item wt-dropdown-watchlist__item-button">
                  <span class="wt-dropdown-label"> {{ (subItem.itemLabel ? (subItem.translateItemLabel ?
                    (subItem.itemLabel | translate) : subItem.itemLabel) : translateNoItemLabelPlaceholder ?
                    (noItemLabelPlaceholder | translate) : noItemLabelPlaceholder)}}
                  </span>
                </button>
              </li>
            </ul>
          </mat-menu>
        </ng-container>
      </li>

      <!-- post item -->
      <li *ngIf="postItemTemplate" class="wt-dropdown-watchlist__item" mat-menu-item>
        <ng-content *ngTemplateOutlet="postItemTemplate"></ng-content>
      </li>
    </ul>
  </mat-menu>
</div>