import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AlertDescriptionPipe } from './alert-description.pipe';
import { CastPipe } from './cast.pipe';
import { FeedAccessPipe } from './feed-access.pipe';
import { HighlightPipe } from './highlight.pipe';
import { InBracketsPipe } from './in-brackets.pipe';
import { LongHeaderNamePipe } from './long-header-name.pipe';
import { SafePipe } from './safe.pipe';
import { NumberToColorPipe } from './number-to-color.pipe';

// todo: all pipes unrelated, each pipe their own module?
@NgModule({
  declarations: [
    CastPipe,
    FeedAccessPipe,
    HighlightPipe,
    InBracketsPipe,
    LongHeaderNamePipe,
    AlertDescriptionPipe,
    SafePipe,
    NumberToColorPipe,
  ],
  imports: [CommonModule],
  exports: [
    CastPipe,
    FeedAccessPipe,
    HighlightPipe,
    InBracketsPipe,
    LongHeaderNamePipe,
    AlertDescriptionPipe,
    SafePipe,
    NumberToColorPipe,
  ],
})
export class PipesModule { }
