<div class="wt-dialog">
  <div class="wt-flex-row wt-flex-align-middle wt-dialog__title">
    <div class="wt-flex-column wt-flex-collapse wt-flex-shrink wt-dialog-icon">
      <mat-icon fontIcon="troubleshoot"></mat-icon>
    </div>

    <div class="wt-flex-column">
      <h1 class="wt-h1">{{ 'TRADING_SYMBOL_MAPPING_INFO_COMPONENT.TITLE' | translate }}</h1>
    </div>

    <div class="wt-flex-column wt-flex-shrink wt-flex-collapse wt-dialog-icon-close">
      <mat-icon (click)="onCloseDialog()" fontIcon="close"></mat-icon>
    </div>
  </div>

  <ul class="wt-list" mat-dialog-content *ngIf="symbolMappingInfoData?.length">
    <li *ngFor="let symbolMappingInfo of symbolMappingInfoData" class="wt-list-item">
      {{ symbolMappingInfo }}
    </li>
  </ul>
</div>