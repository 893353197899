import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { InstrumentSearchableWindowType } from '../../../state-model/window.model';

@Injectable({
  providedIn: 'root',
})
export class CompactSearchContainerService {
  private readonly searchClickAction = new Subject<InstrumentSearchableWindowType>();
  readonly searchClick$ = this.searchClickAction.asObservable();

  onSearchClick(window: InstrumentSearchableWindowType): void {
    this.searchClickAction.next(window);
  }
}
