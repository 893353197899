import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicBarComponent } from './dynamic-bar.component';



@NgModule({
  declarations: [DynamicBarComponent],
  imports: [CommonModule],
  exports: [DynamicBarComponent],
})
export class DynamicBarModule { }
