import { KnownDashboardFolderIDs } from '../constants';
import { DashboardFolderLevel, DashboardType } from '../../models';
import { FolderType, HttpDashboardFolderModel } from './http-dashboard.models';


export function parentId(folderType: FolderType, realm: string | undefined, subTenantId: string | undefined, userId: string | undefined): string {
  switch (folderType) {
    case 'GLOBAL':
      return KnownDashboardFolderIDs.GLOBAL;
    case 'COMPANY':
      return `$company:${realm}`;
    case 'SUBTENANT':
      return `$subtenant:${realm}:${subTenantId}`;
    case 'USER':
      return KnownDashboardFolderIDs.PERSONAL;
    case 'SHARED':
      return KnownDashboardFolderIDs.SHARED;
    default:
      throw new Error(`Unknown folder type ${folderType}.`);
  }
}

export function createGlobalFolderModel(displayName: string): HttpDashboardFolderModel {
  return {
    id: KnownDashboardFolderIDs.GLOBAL,
    name: displayName,
    type: DashboardType.FOLDER,
    attributes: {},
    hidden: false,
    isLink: false,
    index: 1000,
    meta: {
      source: 'http',
      role: 'GLOBAL',
      virtual: true,
      raw: undefined,
    },
    security: {
      canAddChildren: true, // TODO: only global admins
      canDelete: false,
      canDeleteChildren: true, // TODO: only global admins
      canEdit: false,
      canMove: false,
    },
    owner: {
      realm: '', // TODO: fill in infront or vwd?
    },
    parentId: KnownDashboardFolderIDs.ROOT,
    childrenLoadState: 'loading',
    level: DashboardFolderLevel.GLOBAL,
  };
}

export function createCompanyFolderModel(realm: string, displayName: string, isOwnRealm: boolean): HttpDashboardFolderModel {
  return {
    id: parentId('COMPANY', realm, undefined, undefined),
    name: displayName,
    type: DashboardType.FOLDER,
    attributes: {},
    hidden: false,
    isLink: false,
    index: isOwnRealm ? 1010 : 1060,
    meta: {
      source: 'http',
      role: 'COMPANY',
      virtual: true,
      raw: undefined,
    },
    security: {
      canAddChildren: true,
      canDelete: false,
      canDeleteChildren: true,
      canEdit: false,
      canMove: false,
    },
    owner: {
      realm: realm,
    },
    parentId: KnownDashboardFolderIDs.ROOT,
    level: DashboardFolderLevel.COMPANY,
    childrenLoadState: 'loading',
  };
}

export function createSubTenantFolderModel(realm: string, subTenantId: string, displayName: string, isOwnRealm: boolean, isOwnSubtenant: boolean): HttpDashboardFolderModel {
  return {
    id: parentId('SUBTENANT', realm, subTenantId, undefined),
    name: displayName,
    type: DashboardType.FOLDER,
    attributes: {},
    hidden: false,
    isLink: false,
    // proper interleaving of COMPANY & SUBTENANT is done by compareDashboards
    index: isOwnSubtenant ? 1011 : isOwnRealm ? 1050 : 1060, // NOSONAR nested ternary accepted
    meta: {
      source: 'http',
      role: 'SUBTENANT',
      virtual: true,
      raw: undefined,
    },
    security: {
      canAddChildren: true,
      canDelete: false,
      canDeleteChildren: true,
      canEdit: false,
      canMove: false,
    },
    owner: {
      realm: realm,
      subTenantId: subTenantId,
    },
    parentId: KnownDashboardFolderIDs.ROOT,
    level: DashboardFolderLevel.SUBTENANT,
    childrenLoadState: isOwnSubtenant ? 'loading' : 'on-demand',
  };
}

export function createSharedFolderModel(realm: string, userId: string, displayName: string): HttpDashboardFolderModel {
  return {
    id: KnownDashboardFolderIDs.SHARED,
    name: displayName,
    type: DashboardType.FOLDER,
    attributes: {},
    hidden: false,
    isLink: false,
    index: 1030,
    meta: {
      source: 'http',
      role: 'SHARED',
      virtual: true,
      raw: undefined,
    },
    security: {
      canAddChildren: false,
      canDelete: false,
      canDeleteChildren: true,
      canEdit: false,
      canMove: false,
    },
    owner: {
      realm: realm,
      userId: userId,
    },
    parentId: KnownDashboardFolderIDs.ROOT,
    level: DashboardFolderLevel.SHARED,
    childrenLoadState: 'loading',
  };
}

export function createUserFolderModel(realm: string, userId: string, displayName: string): HttpDashboardFolderModel {
  return {
    id: KnownDashboardFolderIDs.PERSONAL,
    name: displayName,
    type: DashboardType.FOLDER,
    attributes: {},
    hidden: false,
    isLink: false,
    index: 1040,
    meta: {
      source: 'http',
      role: 'USER',
      virtual: true,
      raw: undefined,
    },
    security: {
      canAddChildren: true,
      canDelete: false,
      canDeleteChildren: true,
      canEdit: false,
      canMove: false,
    },
    owner: {
      realm: realm,
      userId: userId,
    },
    parentId: KnownDashboardFolderIDs.ROOT,
    level: DashboardFolderLevel.PERSONAL,
    childrenLoadState: 'on-demand',
  };
}
