<div [ngClass]="isCompact ? 'wt-search__compact-item' : 'wt-search__item'">

  <ng-container *ngIf="!isCompact; then marketTemplate; else compactMarketTemplate">
  </ng-container>

  <!-- START Search Item Market -->
  <ng-template #marketTemplate>
    <div class="wt-flex-row wt-flex-collapse">
      <div *ngIf="showFlag" class="wt-flex-column wt-flex-shrink">
        <span class="wt-search__item-country-image">
          <wt-country-flag [country]="searchItemMarket.Country" [size]="16"></wt-country-flag>
        </span>
      </div>

      <div class="wt-flex-column wt-flex-shrink" title="{{ searchItemMarket.ExchangeCode }}">
        <span class="wt-search__item-ticker">
          {{ searchItemMarket.ExchangeCode }}
        </span>
      </div>
    </div>

    <div class="wt-flex-row wt-flex-collapse">
      <div class="wt-flex-column wt-flex-shrink wt-truncate" title="{{ searchItemMarket.Description }}">
        <span class="wt-search__item-full-name">{{ searchItemMarket.Description }}</span>
      </div>

      <div class="wt-flex-column wt-truncate wt-search__item-state" title="{{ searchItemMarket.Access }}">
        <span class="wt-search__item-access">{{ searchItemMarket.Access }}</span>
      </div>
    </div>
  </ng-template>
  <!-- END Search Item Market -->

  <!-- START Compact Search Item Market -->
  <!-- NOT IN USE ATM -->
  <ng-template #compactMarketTemplate>
    <div class="wt-flex-row">
      <div class="wt-flex-column wt-truncate">
        <span *ngIf="showFlag" class="wt-search__compact-item-country-image"
          title="{{ searchItemMarket.Country }}">
          <wt-country-flag [country]="searchItemMarket.Country" [size]="16"></wt-country-flag>
        </span>
      </div>
    </div>

    <div class="wt-flex-row">
      <div class="wt-flex-column wt-truncate" title="{{ searchItemMarket.Description }}">
        <span class="wt-search__compact-item-name">{{ searchItemMarket.Description }}</span>
      </div>

      <div class="wt-flex-column wt-truncate wt-flex-shrink wt-search__compact-item-state"
        title="{{ searchItemMarket.Access }}">
        <span class="wt-search__compact-item-state-access">{{ searchItemMarket.Access }}</span>
      </div>
    </div>
  </ng-template>
  <!-- END Compact Search Item Market -->
</div>