import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { PricePipesModule } from '@vwd/ngx-price-pipes';

import { FocusComponent } from './focus.component';
import { PerformanceBarModule } from '../../shared/performance-bar/performance-bar.module';
import { PerformancePeriodsModule } from '../../shared/performance-periods/performance-periods.module';
import { DynamicBarModule } from '../../shared/dynamic-bar/dynamic-bar.module';
import { SymbolStatusModule } from '../../shared/symbol-status/symbol-status.module';
import { DirectivesModule } from '../../directives/directives.module';

@NgModule({
  declarations: [FocusComponent],
  providers: [DatePipe],
  imports: [
    CommonModule,
    MatIconModule,
    PerformanceBarModule,
    PerformancePeriodsModule,
    DynamicBarModule,
    DirectivesModule,
    PricePipesModule,
    SymbolStatusModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [FocusComponent]
})
export class FocusModule { }
