import {
  PortfolioBondDefaultColumns,
  PortfolioBondPerformanceColumns,
  PortfolioCertificateDefaultColumns,
  PortfolioCertificatePerformanceColumns,
  PortfolioDerivativeDefaultColumns,
  PortfolioETFDefaultColumns,
  PortfolioETFPerformanceColumns,
  PortfolioFundDefaultColumns,
  PortfolioFundPerformanceColumns,
  PortfolioFutureDefaultColumns,
  PortfolioFuturePerformanceColumns,
  PortfolioOtherDefaultColumns,
  PortfolioOtherPerformanceColumns,
  PortfolioStockDefaultColumns,
  PortfolioStockPerformanceColumns,
  PortfolioUnmappedDefaultColumns,
  SelectedPortfolioBondDefaultColumns,
  SelectedPortfolioBondPerformanceColumns,
  SelectedPortfolioCertificateDefaultColumns,
  SelectedPortfolioCertificatePerformanceColumns,
  SelectedPortfolioDerivativeDefaultColumns,
  SelectedPortfolioDerivativePerformanceColumns,
  SelectedPortfolioETFDefaultColumns,
  SelectedPortfolioETFPerformanceColumns,
  SelectedPortfolioFundDefaultColumns,
  SelectedPortfolioFundPerformanceColumns,
  SelectedPortfolioFutureDefaultColumns,
  SelectedPortfolioFuturePerformanceColumns,
  SelectedPortfolioOtherDefaultColumns,
  SelectedPortfolioOtherPerformanceColumns,
  SelectedPortfolioStockDefaultColumns,
  SelectedPortfolioStockPerformanceColumns,
  SelectedPortfolioUnmappedDefaultColumns,
  TradingCategories,
} from '../../widgets/portfolio-positions/portfolio-positions.columns';


export const portfolioPositionsGridColumnsMap = {
  portfolioPositionsStockDefault: {
    all: PortfolioStockDefaultColumns,
    defaultSelected: SelectedPortfolioStockDefaultColumns,
    categories: TradingCategories,
  },
  portfolioPositionsStockPerformance: {
    all: PortfolioStockPerformanceColumns,
    defaultSelected: SelectedPortfolioStockPerformanceColumns,
    categories: TradingCategories,
  },
  portfolioPositionsETFDefault: { all: PortfolioETFDefaultColumns, defaultSelected: SelectedPortfolioETFDefaultColumns, categories: TradingCategories },
  portfolioPositionsETFPerformance: {
    all: PortfolioETFPerformanceColumns,
    defaultSelected: SelectedPortfolioETFPerformanceColumns,
    categories: TradingCategories,
  },
  portfolioPositionsFundDefault: { all: PortfolioFundDefaultColumns, defaultSelected: SelectedPortfolioFundDefaultColumns, categories: TradingCategories },
  portfolioPositionsFundPerformance: {
    all: PortfolioFundPerformanceColumns,
    defaultSelected: SelectedPortfolioFundPerformanceColumns,
    categories: TradingCategories,
  },
  portfolioPositionsFutureDefault: {
    all: PortfolioFutureDefaultColumns,
    defaultSelected: SelectedPortfolioFutureDefaultColumns,
    categories: TradingCategories,
  },
  portfolioPositionsFuturePerformance: {
    all: PortfolioFuturePerformanceColumns,
    defaultSelected: SelectedPortfolioFuturePerformanceColumns,
    categories: TradingCategories,
  },
  portfolioPositionsDerivativeDefault: {
    all: PortfolioDerivativeDefaultColumns,
    defaultSelected: SelectedPortfolioDerivativeDefaultColumns,
    categories: TradingCategories,
  },
  portfolioPositionsDerivativePerformance: {
    all: SelectedPortfolioDerivativePerformanceColumns,
    defaultSelected: SelectedPortfolioDerivativePerformanceColumns,
    categories: TradingCategories,
  },
  portfolioPositionsBondDefault: { all: PortfolioBondDefaultColumns, defaultSelected: SelectedPortfolioBondDefaultColumns, categories: TradingCategories },
  portfolioPositionsBondPerformance: {
    all: PortfolioBondPerformanceColumns,
    defaultSelected: SelectedPortfolioBondPerformanceColumns,
    categories: TradingCategories,
  },
  portfolioPositionsCertificateDefault: {
    all: PortfolioCertificateDefaultColumns,
    defaultSelected: SelectedPortfolioCertificateDefaultColumns,
    categories: TradingCategories,
  },
  portfolioPositionsCertificatePerformance: {
    all: PortfolioCertificatePerformanceColumns,
    defaultSelected: SelectedPortfolioCertificatePerformanceColumns,
    categories: TradingCategories,
  },
  portfolioPositionsOtherDefault: {
    all: PortfolioOtherDefaultColumns,
    defaultSelected: SelectedPortfolioOtherDefaultColumns,
    categories: TradingCategories,
  },
  portfolioPositionsOtherPerformance: {
    all: PortfolioOtherPerformanceColumns,
    defaultSelected: SelectedPortfolioOtherPerformanceColumns,
    categories: TradingCategories,
  },
  portfolioPositionsUnmappedDefault: {
    all: PortfolioUnmappedDefaultColumns,
    defaultSelected: SelectedPortfolioUnmappedDefaultColumns,
  },
};

export const portfolioPositionsGrid = [
  'portfolioPositionsStockDefault',
  'portfolioPositionsStockPerformance',
  'portfolioPositionsETFDefault',
  'portfolioPositionsETFPerformance',
  'portfolioPositionsFundDefault',
  'portfolioPositionsFundPerformance',
  'portfolioPositionsFutureDefault',
  'portfolioPositionsFuturePerformance',
  'portfolioPositionsDerivativeDefault',
  'portfolioPositionsDerivativePerformance',
  'portfolioPositionsBondDefault',
  'portfolioPositionsBondPerformance',
  'portfolioPositionsCertificateDefault',
  'portfolioPositionsCertificatePerformance',
  'portfolioPositionsOtherDefault',
  'portfolioPositionsOtherPerformance',
  'portfolioPositionsUnmappedDefault',
] as const;
