import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GridModule } from '../../../shared/grid/grid.module';
import { StaticColumnsGridComponent } from './static-columns-grid.component';

@NgModule({
  declarations: [StaticColumnsGridComponent],
  imports: [CommonModule, GridModule],
  exports: [StaticColumnsGridComponent],
})
export class StaticColumnsGridModule {}
