import { Injectable, inject } from '@angular/core';
import { DashboardFolderLevel, DashboardItemRef, DashboardService, KnownDashboardFolderIDs, isDashboardItemRef } from '@infront/ngx-dashboards-fx';
import { LogService } from '@vwd/ngx-logging';
import { EMPTY, merge, of } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, map, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { DefaultDashboardsService, EMPTY_DEFAULT_DASHBOARD_ID } from '../dashboard/default-dashboards.service';
import { DashboardTabsService } from '../dashboard/providers/dashboard-tabs.provider';
import { arraysAreEqual, structuresAreEqual } from '../util/equality';
import { Dashboard, DashboardType } from './../state-model/dashboard.model';
import { RemoteStorageService } from './remote-storage.service';
import { State, StateService, emptyState, useMockupData } from './state.service';
import { newId } from '../util/utils';

@Injectable({
  providedIn: 'root',
})
export class StoreToRemoteService {

  private readonly logger = inject(LogService).openLogger('services/store-to-remote');
  private readonly stateService = inject(StateService);
  private readonly remoteStorageService = inject(RemoteStorageService);
  private readonly dashboardService = inject(DashboardService);
  private readonly defaultDashboardsService = inject(DefaultDashboardsService);
  private readonly dashboardTabsService = inject(DashboardTabsService);
  private createdInitialDashboards = false;

  private syncStoreToRemote$ = this.stateService.detailedStateChange$.pipe(
    withLatestFrom(
      // Since we're using debounceTime, we need to track our own changes
      of({ lastSyncedState: emptyState }),
    ),
    debounceTime(100), // TODO to be discussed
    concatMap(([detailedStateChange, lastStateHolder]) => {
      const { next } = detailedStateChange;

      // Since we're using debounceTime, we need to track our own changes
      const lastSyncedState = lastStateHolder.lastSyncedState;
      lastStateHolder.lastSyncedState = next;

      // Do no sync if we received data from the back-end.
      if (!next || next.stateType === 'Initial') {
        return of(undefined);
      }

      // Store the current Dashboard data (windows, widgets, grids), but only if changed
      const toBeStoredDashboards = next.dashboards?.filter((dashboard) => this.shouldSaveDashboardData(dashboard)
        && widgetsHaveChanged(dashboard.id, lastSyncedState, next));
      const saveDashboardsData$ = toBeStoredDashboards
        .map((dashboard) => this.remoteStorageService.saveDashboardData(dashboard, next).pipe(catchError(() => EMPTY)));
      return saveDashboardsData$ ? merge(...saveDashboardsData$) : of(undefined);
    })
  );

  private shouldSaveDashboardData(dashboard: Dashboard): boolean {
    // HACK: Do not save widgets for dashboards currently showing an "add widget" popup
    if (this.stateService.state.windows.some(window => window.dashboardId === dashboard.id && window.layerIndex === 2)) {
      return false;
    }
    return true;
  }

  constructor() {
    let isFirstLoad = true;

    this.remoteStorageService.getDashboards().pipe(
      filter(() => !useMockupData),
      map((dashboards) => {
        this.logger.debug("Dashboards loaded", dashboards);
        return ({
          dashboards: dashboards ?? [],
        });
      }),
      this.tryInitInitialDashboard,
      tap((remoteState) => {
        this.stateService.setState({
          dashboards: [...remoteState.dashboards],
          dashboardsReady: true,
        }, 'Initial');

        if (isFirstLoad) {
          isFirstLoad = false;
          this.syncStoreToRemote$.subscribe();
        }
      }),
    ).subscribe();

    this.remoteStorageService.getDashboardsData().pipe(
      filter(() => !useMockupData),
      map((data) => {
        this.logger.debug("Dashboards Data loaded from remote storage", data);
        return ({
          windows: data.windows ?? [],
          widgets: data.widgets ?? [],
          grids: data.grids ?? [],
        });
      }),
      tap((remoteState: State) => {
        return this.stateService.setState({
          windows: [...remoteState.windows],
          widgets: [...remoteState.widgets],
          grids: [...remoteState.grids],
        }, 'Initial');
      }),
    ).subscribe();
  }

  private tryInitInitialDashboard = switchMap((remoteState: Pick<State, 'dashboards'>) => {
    // If we already activated default dashboards in this session, don't try again
    // when a user removes all dashboards.
    if (this.createdInitialDashboards) {
      return of(remoteState);
    }
    // If we have a regular dashboard, we don't need to create the initial dashboard
    if (remoteState.dashboards.some((d) => d.type === DashboardType.dashboard)) {
      this.createdInitialDashboards = true;
      return of(remoteState);
    }

    this.logger.debug('Creating initial dashboard');

    return this.defaultDashboardsService.getDashboards().pipe(
      take(1),
      switchMap((dashboards) => {
        this.createdInitialDashboards = true;
        if (dashboards.length) {
          this.logger.info('Setting up default dashboards.');
          const emptyDashboard = dashboards.find((db) => db.model.id === EMPTY_DEFAULT_DASHBOARD_ID);
          if (emptyDashboard) {
            // If the user already has some user dashboards, we've probably hit the
            // factory reset option. In that case, we do not create the empty default
            // user dashboard, but we do try to return a previously created default
            // dashboard.

            const personalFolder = this.dashboardService.getFolderRef(KnownDashboardFolderIDs.PERSONAL);

            const defaultDashboardName = emptyDashboard.model.name;

            if (personalFolder?.children?.length) {
              const newEmpty = personalFolder.childNodes.find(child =>
                isDashboardItemRef(child) &&
                child.model.name === defaultDashboardName
              ) as DashboardItemRef | undefined;
              if (newEmpty) {
                dashboards = dashboards.map((db) => db.model.id === EMPTY_DEFAULT_DASHBOARD_ID ? newEmpty : db);
              } else {
                dashboards = dashboards.filter((db) => db.model.id !== EMPTY_DEFAULT_DASHBOARD_ID);
              }

              // add the
              this.dashboardTabsService.setTabs(dashboards.map((ref) => ref.model.id));
              return EMPTY; // allow outer stream to react to new dashboards
            }

            // Otherwise, we need to actually create the empty dashboard
            return this.remoteStorageService.addDashboard({
              id: newId(),
              name: defaultDashboardName,
              type: DashboardType.dashboard,
              level: DashboardFolderLevel.PERSONAL,
              canClose: false,
              canDelete: true,
              canRename: true,
              index: 1,
              locked: false,
              readonly: false,
              parentId: KnownDashboardFolderIDs.PERSONAL,
            }, true).pipe(
              tap((newEmpty: DashboardItemRef) => {
                dashboards = dashboards.map((db) => db.model.id === EMPTY_DEFAULT_DASHBOARD_ID ? newEmpty : db);
                this.dashboardTabsService.setTabs(dashboards.map((ref) => ref.model.id));
              }),
              switchMap(() => EMPTY),  // allow outer stream to react to new dashboards
            );
          } else {
            this.dashboardTabsService.setTabs(dashboards.map((ref) => ref.model.id));
            return EMPTY; // allow outer stream to react to new dashboards
          }
        } else {
          this.logger.warn('No default dashboards configured.');
          return of(remoteState);
        }
      }),
      shareReplay(1), // make sure we only run this once
    );
  });

}

function widgetsHaveChanged(dashboardId: string, prev: State, next: State): unknown {
  function thisDashboard(item: { dashboardId?: string }): boolean {
    return item.dashboardId === dashboardId;
  }

  // We should, with a well-behaved state tree, be able to do just a shallow
  // array check, which is pretty fast.
  //
  // However, every state update causes a `deepClone` of the WHOLE state tree
  // (see `StoreService.setState`. So we need to do a deep equality check too.

  return !arraysAreEqual(
    prev.windows?.filter(thisDashboard) ?? [],
    next.windows?.filter(thisDashboard) ?? [],
    structuresAreEqual
  ) || !arraysAreEqual(
    prev.widgets?.filter(thisDashboard) ?? [],
    next.widgets?.filter(thisDashboard) ?? [],
    structuresAreEqual
  ) || !arraysAreEqual(
    prev.grids?.filter(thisDashboard) ?? [],
    next.grids?.filter(thisDashboard) ?? [],
    structuresAreEqual
  );
}
