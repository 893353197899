import { AfterViewInit, Component, inject } from '@angular/core';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { svgIcons } from '../auto/svgConstant';
import { PwaInstallService } from './pwa';
import { InactivityService } from './services/inactivityService';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements AfterViewInit {
  title = 'Infront Web Trader';

  private readonly inactivityService: InactivityService = inject(InactivityService);

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private pwaService: PwaInstallService,
  ) {
    svgIcons.forEach((svgIcon) => {
      // Content of `svgIcons` is created by script `svgToJsConstants.script.js`
      // which is run manually by developers to create TS code
      // which is then checked during review process.
      iconRegistry.addSvgIcon(
        svgIcon.name,
        // see comment above, we consider content of `svgIcons` to be safe against XSS and therefore allow bypassing Angular security!
        sanitizer.bypassSecurityTrustResourceUrl(svgIcon.path)); // NOSONAR
    });
  }

  ngAfterViewInit(): void {
    void this.pwaService.showPromptIfNotInstalled();
  }
}
