import { Injectable, OnDestroy, inject } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { GridApi } from 'ag-grid-community';
import { map, switchMap, tap } from 'rxjs/operators';

import { ReplaySubject, combineLatest } from 'rxjs';
import { SymbolDataItem, SymbolWithTradingSymbol } from '../../shared/models/symbol-data.model';
import { GridRef } from '../../wrappers/grid-wrappers/gridref';
import { ObserveTradingSymbolsService } from '../portfolio-positions/observe-trading-symbols.service';
import { TradingPrefix } from '../portfolio-positions/portfolio-positions.columns';
import { ObserveSymbolsService } from './observe-symbols.service';

// provide at component level
@Injectable()
export class SymbolsToValuesService implements OnDestroy {
  private readonly observeSymbolsService: ObserveSymbolsService = inject(ObserveSymbolsService);
  private readonly observeTradingSymbolsService: ObserveTradingSymbolsService = inject(ObserveTradingSymbolsService);

  private dataAndSelectedFieldsCopy: [SymbolDataItem[], InfrontSDK.SymbolField[]];

  private readonly startStreamAction = new ReplaySubject<{ gridId: string, gridApi: GridApi }>(1);

  startStream(gridId: string, gridApi: GridApi): void {
    this.startStreamAction.next({ gridId, gridApi });
  }

  private readonly snapshotAction = new ReplaySubject<{ gridRef: GridRef, symbolList: SymbolDataItem[] }>(1);

  snapshot(gridRef: GridRef, symbolList: SymbolDataItem[]): void {
    this.snapshotAction.next({ gridRef, symbolList });
  }

  // get a static values list ASAP, this will not be streaming
  readonly snapshot$ = this.snapshotAction.pipe(switchMap(({ gridRef, symbolList }) => {
    return gridRef.selectedFields$.pipe(
      tap((selectedFields) => {
        this.dataAndSelectedFieldsCopy = [symbolList, selectedFields];
      }),
      switchMap((selectedFields) => {
        if (selectedFields.some((field) => field.startsWith(TradingPrefix))) {
          return this.observeTradingSymbolsService.symbolGetValueList$(symbolList as SymbolWithTradingSymbol[], selectedFields);
        }
        return this.observeSymbolsService.symbolGetValueList$(symbolList, selectedFields);
      })
    );
  }));

  readonly stream$ = combineLatest([this.startStreamAction, this.snapshot$]).pipe(switchMap(([params]) => {
    const { gridId, gridApi } = params;
    const [symbols, fields] = this.dataAndSelectedFieldsCopy;
    if (fields.some((field) => field.startsWith(TradingPrefix))) {
      return this.observeTradingSymbolsService
        .observeSymbols$({ symbols: symbols as SymbolWithTradingSymbol[], fields, uuid: gridId, initialUpdate: InfrontSDK.InitialUpdate.None })
        .pipe(
          map((item) => {
            gridApi.applyTransactionAsync({ update: [item] });
          })
        );
    }
    return this.observeSymbolsService.observeSymbols$({ symbols, fields, uuid: gridId, initialUpdate: InfrontSDK.InitialUpdate.None }).pipe(
      map((item) => {
        gridApi.applyTransactionAsync({ update: [item] });
      })
    );
  }));

  ngOnDestroy(): void {
    this.startStreamAction.complete();
    this.snapshotAction.complete();
  }
}
