import { Pipe, PipeTransform } from '@angular/core';

import { LongHeaderNamesMap } from '../shared/grid/column-registry';

@Pipe({ name: 'longHeaderName' })
export class LongHeaderNamePipe implements PipeTransform {
  transform(headerName: string | undefined, colId: string | undefined): string {
    if (!headerName || !colId) {
      return '';
    }
    return LongHeaderNamesMap[colId] ?? headerName;
  }
}
