import { State } from '../services/state.service';
import { DashboardType } from '../state-model/dashboard.model';
import {
  CalendarWidgetDefaults,
  HistoryWidgetDefaults,
  ListsWidgetDefaults,
  NewsWidgetDefaults,
  TradesWidgetDefaults,
} from '../state-model/widget.defaults';
import { NewsWidget, Widget } from '../state-model/widget.model';
import { DashboardWindow } from '../state-model/window.model';

// A Users mock data until a DB is created
export const UserDashboards = [
  {
    id: '0',
    name: 'My Dashboard',
    type: DashboardType.dashboard,
  },
  {
    id: '1',
    name: 'UI dev dashboard',
    type: DashboardType.dashboard,
  },
  {
    id: '2',
    name: 'Test Market and Instrument',
    isLocked: DashboardType.dashboard,
  },
  {
    id: '3',
    name: 'My portfolio',
    type: DashboardType.template,
  },
];
//mock data
export const UserWindows: Array<DashboardWindow> = [
  // Dashboard 0
  {
    id: '0',
    linkChannel: 'Channel 1',
    name: 'InstrumentWindow',
    settings: {
      instrument: { feed: 18177, ticker: 'DNB' },
    },
    dashboardId: '0',
    selectedWidgetName: 'Calendar',
    cols: 90,
    rows: 80,
    y: 0,
    x: 0,
  },

  // {
  //   id: '0',
  //   linkChannel: 'Channel 1',
  //   name: 'InstrumentWindow',
  //   instrument: { feed: 18177, ticker: 'DNB' },
  //   dashboardId: '0',
  //   selectedWidgetName: 'Calendar',
  //   cols: 90,
  //   rows: 80,
  //   y: 0,
  //   x: 0,
  // },

  {
    id: '1',
    linkChannel: 'Channel 2',
    name: 'MarketWindow',
    settings: { feed: 17921 },
    selectedWidgetName: 'Lists',
    dashboardId: '0',
    cols: 60,
    rows: 40,
    y: 50,
    x: 0,
  },

  // {
  //   id: '2',
  //   linkChannel: 'Channel 3',
  //   name: 'WatchlistWindow',
  //   dashboardId: '0',
  //   selectedWidgetName: 'Watchlist',
  //   cols: 60,
  //   rows: 30,
  //   y: 60,
  //   x: 20,
  // },
  // {
  //   id: '3',
  //   linkChannel: 'Channel 4',
  //   name: 'NewsWindow',
  //   instrument: { feed: 18177, ticker: 'DNB' },
  //   selectedWidgetName: 'News',
  //   newsType: 'Country',
  //   dashboardId: '0',
  //   selectedTabIndex: 0,
  //   cols: 80,
  //   rows: 30,
  //   y: 0,
  //   x: 40,
  // },
  // {
  //   id: '4',
  //   name: 'OrderbookWindow',
  //   instrument: { feed: 26, ticker: 'BMW' },
  //   selectedTabIndex: 0,
  //   dashboardId: '0',
  //   linkChannel: 'Channel 4',
  //   cols: 60,
  //   rows: 30,
  //   y: 60,
  //   x: 80,
  // },
  {
    id: '7',
    linkChannel: 'Channel 1',
    name: 'WatchlistWindow',
    dashboardId: '0',
    selectedWidgetName: 'Watchlist',
    settings: {
      selectedWatchlist: undefined,
    },
    cols: 90,
    rows: 80,
    y: 0,
    x: 90,
  },

  // Dashboard 2
  // {
  //   id: '5',
  //   linkChannel: 'Channel 1',
  //   name: 'InstrumentWindow',
  //   instrument: { feed: 18177, ticker: 'DNB' },
  //   dashboardId: '2',
  //   selectedWidgetName: 'Calendar',
  //   cols: 90,
  //   rows: 80,
  //   y: 0,
  //   x: 0,
  // },
  {
    id: '6',
    linkChannel: 'Channel 2',
    name: 'MarketWindow',
    settings: {
      feed: 17921, //15, //26, // 17921, //17921, //17921, //2161, //17921
    },
    selectedWidgetName: 'Lists',
    dashboardId: '2',
    cols: 90,
    rows: 80,
    y: 0,
    x: 90,
  },
  // {
  //   id: '8',
  //   linkChannel: 'Channel 4',
  //   name: 'NewsWindow',
  //   selectedWidgetName: 'News',
  //   newsType: 'Instrument',
  //   dashboardId: '2',
  //   cols: 80,
  //   rows: 30,
  //   y: 90,
  //   x: 0,
  // },
  // Dashboard 1 Chart TEST
  {
    id: '10',
    linkChannel: 'Channel 1',
    name: 'ChartWindow',
    settings: { instrument: { feed: 26, ticker: 'BMW' } },
    dashboardId: '1',
    selectedWidgetName: 'Chart',
    cols: 50,
    rows: 50,
    y: 0,
    x: 0,
  },
  {
    id: '12',
    linkChannel: 'Channel 1',
    name: 'FocusWindow',
    settings: { instrument: { feed: 18177, ticker: 'DNB' } },
    // settings: { instrument: { feed: 18118, ticker: '69087' } },
    dashboardId: '0',
    selectedWidgetName: 'Focus',
    x: 0,
    y: 0,
    rows: 20,
    cols: 30,
  },
];
//mock data
export const UserWidgets: Widget[] = [
  // widget for windows inside Dashboard 0
  {
    id: '0',
    dashboardId: '0',
    name: 'InstrumentOverview',
    settings: { selectedPeriodIndex: 4 },
    windowId: '0',
  },
  {
    id: '1',
    dashboardId: '0',
    name: 'Lists',
    windowId: '1',
    settings: { ...ListsWidgetDefaults.settings, selectedSourceName: undefined }, //Large Cap Stockholm SEK Mid Cap Stockholm SEK
  },
  {
    id: '2',
    dashboardId: '0',
    name: 'News',
    settings: NewsWidgetDefaults.settings,
    windowId: '3',
  },
  {
    id: '3',
    dashboardId: '0',
    name: 'Orderbook',
    settings: { showTopBar: false, showCompactView: true, yellowTopLevel: true },
    windowId: '4',
  },
  {
    id: '4',
    dashboardId: '0',
    name: 'Orderbook',
    settings: { showTopBar: false, showCompactView: true, yellowTopLevel: true },
    windowId: '0',
  },
  {
    id: '5',
    dashboardId: '2',
    name: 'Performance',
    windowId: '0',
    settings: { showChart: true, showDividendsLine: true },
  },
  {
    id: '6',
    dashboardId: '2',
    name: 'Calendar',
    windowId: '0',
    settings: CalendarWidgetDefaults.settings,
    // lifespan: 'window',
  },
  {
    id: '7',
    dashboardId: '0',
    name: 'News',
    windowId: '0',
    settings: NewsWidgetDefaults.settings,
    //lifespan: 'window',
  } as NewsWidget,
  {
    id: '8',
    dashboardId: '2',
    name: 'Trades',
    windowId: '0',
    settings: TradesWidgetDefaults.settings,
  },
  {
    id: '9',
    dashboardId: '2',
    name: 'History',
    windowId: '0',
    settings: HistoryWidgetDefaults.settings,
  },
  {
    id: '10',
    dashboardId: '1',
    name: 'Ranking',
    windowId: '1',
    settings: { selectedSourceName: undefined, selectedTimePeriod: '1 Year' }, //Large Cap Stockholm SEK Mid Cap Stockholm SEK
  },
  {
    id: '11',
    dashboardId: '1',
    name: 'BiggestMovers',
    windowId: '1',
    settings: { selectedSourceName: undefined, selectedTimePeriod: '3 Months' }, //Large Cap Stockholm SEK Mid Cap Stockholm SEK
  },

  //watchlist window widgets
  {
    id: '24',
    dashboardId: '0',
    name: 'Watchlist',
    windowId: '7',
    settings: { ...ListsWidgetDefaults.settings }, //Large Cap Stockholm SEK Mid Cap Stockholm SEK
  },
  {
    id: '25',
    dashboardId: '0',
    name: 'News',
    settings: NewsWidgetDefaults.settings,
    windowId: '7',
  },
  {
    id: '26',
    dashboardId: '0',
    name: 'Calendar',
    windowId: '7',
    settings: CalendarWidgetDefaults.settings,
    // lifespan: 'window',
  },

  // test dashboard
  {
    id: '12',
    dashboardId: '0',
    name: 'InstrumentOverview',
    settings: { selectedPeriodIndex: 4 },
    windowId: '5',
  },
  {
    id: '13',
    dashboardId: '2',
    name: 'Lists',
    windowId: '6',
    settings: { ...ListsWidgetDefaults.settings, selectedSourceName: undefined }, //Large Cap Stockholm SEK Mid Cap Stockholm SEK
  },

  {
    id: '14',
    dashboardId: '2',
    name: 'News',
    settings: NewsWidgetDefaults.settings,
    windowId: '8',
  },
  // {
  //   id: '15',
  //   name: 'Orderbook',
  //   settings: { showTopBar: false, showCompactView: true, yellowTopLevel: true },
  //   windowId: '8',
  // },
  {
    id: '16',
    dashboardId: '2',
    name: 'Orderbook',
    settings: { showTopBar: false, showCompactView: true, yellowTopLevel: true },
    windowId: '5',
  },
  {
    id: '17',
    dashboardId: '2',
    name: 'Performance',
    windowId: '5',
    settings: {},
  },
  {
    id: '18',
    dashboardId: '2',
    name: 'Calendar',
    windowId: '5',
    settings: CalendarWidgetDefaults.settings,
    // lifespan: 'window',
  },
  {
    id: '19',
    dashboardId: '2',
    name: 'News',
    windowId: '5',
    settings: NewsWidgetDefaults.settings,
    //lifespan: 'window',
  } as NewsWidget,
  {
    id: '20',
    dashboardId: '2',
    name: 'Trades',
    windowId: '5',
    settings: TradesWidgetDefaults.settings,
  },
  {
    id: '21',
    dashboardId: '2',
    name: 'History',
    windowId: '5',
    settings: HistoryWidgetDefaults.settings,
  },
  {
    id: '22',
    dashboardId: '2',
    name: 'Ranking',
    windowId: '6',
    settings: { selectedSourceName: undefined, selectedTimePeriod: '1 Year' }, //Large Cap Stockholm SEK Mid Cap Stockholm SEK
  },
  {
    id: '23',
    dashboardId: '2',
    name: 'BiggestMovers',
    windowId: '6',
    settings: { selectedSourceName: undefined, selectedTimePeriod: '3 Months' }, //Large Cap Stockholm SEK Mid Cap Stockholm SEK
  },
  // Dashboard 1 Chart TEST
  {
    id: '30',
    dashboardId: '1',
    name: 'Chart',
    settings: {},
    windowId: '10',
  },
  {
    id: '32',
    dashboardId: '0',
    name: 'Focus',
    settings: {},
    windowId: '12',
  },
];

export const mockupState = {
  dashboards: UserDashboards,
  windows: UserWindows,
  widgets: UserWidgets,
} as State;
