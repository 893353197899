<ng-container *ngIf="showAlertsList$ | async as showAlertsList">
  <div #notificationCenterTrigger>
    <mat-icon matBadge="{{ numberOfAlerts$ | async }}" matBadgeColor="warn"
      (click)="notificationsIconClick(!showAlertsList.show)" fontIcon="notifications">
    </mat-icon>
  </div>

  <ng-container *ngIf="selectedTab$ | async as selectedTab">
    <ng-container *ngIf="showAlertsList.show">
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayHeight]="446"
        [cdkConnectedOverlayOrigin]="notificationCenterTrigger"
        [cdkConnectedOverlayOpen]="showAlertsList.show"
        (overlayOutsideClick)="showAlertsListAction.next({ show: false })">
        <div class="wt-alerts-list">
          <div class="wt-flex-row wt-flex-align-middle">
            <div class="wt-flex-column wt-alerts-list__header">
              <div class="wt-alerts-list__header-tabs">
                <div class="wt-alerts-list__header-tabs-item"
                  [class.wt-alerts-list__header-tabs-item--selected]="selectedTab === 'alertLog'"
                  (click)="setSelectedTab('alertLog')">
                  <span class="wt-alerts-list__header-tabs-item-label">{{ "ALERTS_LIST.ALERT_LOG" | translate }}</span>
                </div>

                <div class="wt-alerts-list__header-tabs-item"
                  [class.wt-alerts-list__header-tabs-item--selected]="selectedTab === 'myAlerts'"
                  (click)="setSelectedTab('myAlerts')">
                  <span class="wt-alerts-list__header-tabs-item-label">{{ "ALERTS_LIST.MY_ALERTS" | translate }}</span>
                </div>
              </div>
            </div>

            <div class="wt-flex-column wt-flex-shrink wt-flex-collapse">
              <mat-icon class="wt-alerts-list__header-tabs-spawn" (click)="spawn()" *ngIf="showSpawn$ | async"
                fontIcon="launch">
              </mat-icon>
            </div>
          </div>

          <div class="wt-flex-row">
            <div class="wt-flex-column wt-flex-collapse">
              <div class="wt-alerts-list__main">
                <div class="wt-alerts-list__logs" [hidden]="selectedTab !== 'alertLog'">
                  <wt-alert-log-list *ngIf="alertLog$ | async as alertLog"
                    [alertLog]="alertLog"
                    (markAsSeen)="markAsSeen($event)"
                    (triggerClose)="showAlertsListAction.next({ show: false })"></wt-alert-log-list>
                </div>

                <div class="wt-alerts-list__alerts" [hidden]="selectedTab !== 'myAlerts'">
                  <ng-container *ngIf="myAlerts$ | async as myAlerts">
                    <wt-my-alerts (triggerClose)="showAlertsListAction.next({ show: false })"></wt-my-alerts>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>