import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { SearchModule } from '../../search/search.module';
import { AddInstrumentsToWidgetModule } from '../../shared/add-instruments-to-widget/add-instruments-to-widget.module';
import { SymbolsGridModule } from '../../wrappers/grid-wrappers/symbols-grid/symbols-grid.module';
import { UserlistComponent } from './userlist.component';


@NgModule({
  imports: [
    CommonModule,
    SymbolsGridModule,
    SearchModule,
    MatIconModule,
    MatButtonModule,
    AddInstrumentsToWidgetModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [],
  declarations: [UserlistComponent],
  providers: [],
})
export class UserlistModule { }
