import { Infront, InfrontSDK } from '@infront/sdk';

import { Instrument } from '../../state-model/window.model';

export interface AlertDialogParams {
  alertType: AlertType;
  instrument?: Instrument;
  alert?: InfrontSDK.ServerAlert;
  watchlist?: string;
}

export type AlertType = 'instrument' | 'watchlist';

export const getTriggerOption = (mdField?: Infront.AlertMarketDataField, operator?: InfrontSDK.AlertOperator) =>
  triggerOptions.find((item) => item.mdField === mdField && item.operator === operator);

export const triggerOptions = [
  {
    key: 0,
    label: 'ALERTS_NEW_EDIT_DIALOG.CHANGE_OUTSIDE_RANGE',
    marketDataField: InfrontSDK.AlertMarketDataField.ChangePct,
    mdField: Infront.AlertMarketDataField.ChangePct,
    operator: InfrontSDK.AlertOperator.Or,
  },
  {
    key: 1,
    label: 'ALERTS_NEW_EDIT_DIALOG.LAST_ABOVE',
    marketDataField: InfrontSDK.AlertMarketDataField.Last,
    mdField: Infront.AlertMarketDataField.Last,
    operator: InfrontSDK.AlertOperator.Gte,
  },
  {
    key: 2,
    label: 'ALERTS_NEW_EDIT_DIALOG.LAST_BELOW',
    marketDataField: InfrontSDK.AlertMarketDataField.Last,
    mdField: Infront.AlertMarketDataField.Last,
    operator: InfrontSDK.AlertOperator.Lte,
  },
  {
    key: 3,
    label: 'ALERTS_NEW_EDIT_DIALOG.LAST_OUTSIDE_RANGE',
    marketDataField: InfrontSDK.AlertMarketDataField.Last,
    mdField: Infront.AlertMarketDataField.Last,
    operator: InfrontSDK.AlertOperator.Or,
  },
  {
    key: 4,
    label: 'ALERTS_NEW_EDIT_DIALOG.CHANGE_ABOVE',
    marketDataField: InfrontSDK.AlertMarketDataField.ChangePct,
    mdField: Infront.AlertMarketDataField.ChangePct,
    operator: InfrontSDK.AlertOperator.Gte,
  },
  {
    key: 5,
    label: 'ALERTS_NEW_EDIT_DIALOG.CHANGE_BELOW',
    marketDataField: InfrontSDK.AlertMarketDataField.ChangePct,
    mdField: Infront.AlertMarketDataField.ChangePct,
    operator: InfrontSDK.AlertOperator.Lte,
  },
  {
    key: 6,
    label: 'ALERTS_NEW_EDIT_DIALOG.ANY_CHANGE_ABOVE',
    marketDataField: InfrontSDK.AlertMarketDataField.ChangePct,
    mdField: Infront.AlertMarketDataField.ChangePct,
    operator: InfrontSDK.AlertOperator.Gte,
  },
  {
    key: 7,
    label: 'ALERTS_NEW_EDIT_DIALOG.ANY_CHANGE_BELOW',
    marketDataField: InfrontSDK.AlertMarketDataField.ChangePct,
    mdField: Infront.AlertMarketDataField.ChangePct,
    operator: InfrontSDK.AlertOperator.Lte,
  },
];

export const triggerOptionsForInstrument = triggerOptions.slice(0, 6);

export const triggerOptionsForWatchlist = triggerOptions.slice(6, 8);

export const getReccuranceOption = (triggerType?: InfrontSDK.AlertTriggerType) => reccuranceOptions.find((item) => item.triggerType === triggerType);

export const reccuranceOptions = [
  { triggerType: InfrontSDK.AlertTriggerType.TriggerOnce, label: 'ALERTS_NEW_EDIT_DIALOG.ONCE' },
  { triggerType: InfrontSDK.AlertTriggerType.TriggerDaily, label: 'ALERTS_NEW_EDIT_DIALOG.DAILY' },
];
