import { Injectable } from '@angular/core';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { LogService } from '@vwd/ngx-logging';
import { NEVER, Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, share, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { SdkService } from '../../services/sdk.service';
import { StoreService } from '../../services/store.service';
import { TradableService } from '../../services/tradable.service';
import { SymbolDataItem } from '../../shared/models/symbol-data.model';
import { ProgressService, trackProgress } from '../../shared/progress';
import { Grid } from '../../state-model/grid.model';
import { ListsWidget, Widget } from '../../state-model/widget.model';
import { Instrument, MarketWindow } from '../../state-model/window.model';
import { cached } from '../../util/cache';
import { convertSymbolsToInstrumentList, sdkSort, useSort } from '../../util/lists.util';
import { isSameObject } from '../../util/utils';
import { GridRef } from '../../wrappers/grid-wrappers/gridref';
import { SdkRequestsService } from './../../services/sdk-requests.service';
import {
  ChainSource,
  ChainSourceListItem,
  FeedSource,
  SelectableSource,
  SourceListItem,
  allInstruments,
  isChainSource,
  isChainSourceCategory,
} from './lists.model';

@Injectable({
  providedIn: 'root',
})
export class ListsService {

  private cachedFeedInfo$ = cached(60_000,
    (feed: number) => {
      this.logger.log(`feedInfo$ fetch ${feed}`);
      return this.sdkService.getArray$(InfrontSDK.feedInfo, {
        infoType: InfrontSDK.FeedInfoType.MetaData,
        feed,
      }).pipe(
        filter((feedInfo: InfrontSDK.FeedInfo[]) => feedInfo.length > 0),
        map((feedInfo: InfrontSDK.FeedInfo[]) => feedInfo[0]),
        tap(result => this.logger.log(`feedInfo$ fetch ${feed} returned`, result)),
      );
    }
  );

  private cachedChainSources$ = cached(60_000,
    (feed: number) => {
      this.logger.log(`chains$ fetch ${feed}`);

      const opts: Partial<InfrontSDK.FeedContentsOptions> = {
        contentType: InfrontSDK.FeedContentType.Chains,
        feed: feed,
        subscribe: false,
      };

      return this.sdkService.getArray$(InfrontSDK.feedContents, opts).pipe(
        map((chains: ChainSourceListItem[]) => {
          return chains.map((chain) => ({ ...chain, sourceType: 'Chain' }) as ChainSourceListItem);
        }),
        tap(result => this.logger.log(`chains$ fetch ${feed} returned`, result)),
      );
    }
  );

  // metadata of a feed
  feedInfo$ = (widget: Widget): Observable<InfrontSDK.FeedInfo> =>
    this.storeService.windowByWidget$(widget).pipe(
      filter((window: MarketWindow) => !!window?.settings?.feed),
      map((window: MarketWindow) => window.settings.feed),
      distinctUntilChanged((prev, next) => prev === next),
      switchMap((feed) => this.cachedFeedInfo$(feed)),
    );

  // chains belonging in feed
  // chains is a subset of sources that can be used, other possible sources are 'All instruments' and 'Indices'
  chains$ = (widget: Widget): Observable<ChainSourceListItem[]> =>
    this.storeService.windowByWidget$(widget).pipe(
      filter((window: MarketWindow) => !!window?.settings?.feed),
      map((window: MarketWindow) => window.settings.feed),
      distinctUntilChanged((prev, next) => prev === next),
      switchMap((feed) => this.cachedChainSources$(feed)),
    );

  selectedSourceNameFromState$ = (widget: Widget): Observable<string | undefined> =>
    this.storeService.widget$(widget).pipe(
      map((widget: ListsWidget) => widget.settings.selectedSourceName),

      distinctUntilChanged((prev: string, next: string) => prev === next)
    );

  // all symbols for a feed. We need to know this already when determining which sources to show
  private symbolsByFeed$ = (widget: Widget, progress?: ProgressService | null): Observable<InfrontSDK.SymbolData[]> =>
    this.feedInfo$(widget).pipe(
      switchMap((feedInfo) => {
        if (!feedInfo.fullFeed) {
          return of([] as InfrontSDK.SymbolData[]);
        }
        return this.sdkService.getArray$(InfrontSDK.feedContents, {
          contentType: InfrontSDK.FeedContentType.SymbolData,
          feed: feedInfo.feed,
          subscribe: false,
        }).pipe(
          trackProgress({ label: 'lists-symbolsByFeed$', optional: true, progress }),
        );
      }),
      share()
    );

  // determine if AllIntrument and Indices should be included in list of sources
  private feedSources$ = (widget: Widget): Observable<FeedSource[]> =>
    this.symbolsByFeed$(widget).pipe(
      withLatestFrom(this.feedInfo$(widget)),
      map(([symbols, feedInfo]) => {
        const hasAllInstruments = symbols.some(
          (symbol: InfrontSDK.SymbolData) => (symbol.get(InfrontSDK.SymbolField.SymbolType) as string) !== 'Index'
        );
        const hasIndices = symbols.some((symbol: InfrontSDK.SymbolData) => (symbol.get(InfrontSDK.SymbolField.SymbolType) as string) === 'Index');
        const feedSources: FeedSource[] = [];
        if (hasAllInstruments) {
          feedSources.push({ label: allInstruments, feed: feedInfo.feed, sourceType: 'Feed' });
        }
        if (hasIndices) {
          feedSources.push({ label: 'Indices', feed: feedInfo.feed, sourceType: 'Feed' });
        }
        return feedSources;
      })
    );

  allSources$ = (widget: Widget): Observable<SourceListItem[]> =>
    combineLatest([this.chains$(widget), this.feedSources$(widget)]).pipe(
      map(([chainSources, feedSources]) => {
        return [...chainSources, ...feedSources];
      })
    );

  selectedSource$ = (widget: Widget): Observable<SelectableSource> => {
    return this.selectedSourceNameFromState$(widget).pipe(
      switchMap((selectedSource: string | undefined) =>
        this.allSources$(widget).pipe(
          switchMap((sources: SourceListItem[]) => {
            // ranking server can add all instruments and will handle it separatly
            const allInstrumentsSelectedButNotAvailable =
              selectedSource === allInstruments && !sources.find((source) => source.label === allInstruments);
            if (allInstrumentsSelectedButNotAvailable) {
              return NEVER;
            }
            const chainSources = sources.filter((item) => item.sourceType === 'Chain') as ChainSourceListItem[];
            const flattenedChainSources = chainSources.reduce((acc, item: ChainSourceListItem) => {
              if (isChainSourceCategory(item)) {
                acc = [...acc, ...item.nodes.map((node) => ({ ...node, sourceType: 'Chain' as const }))];
              } else {
                acc.push(item);
              }
              return acc;
            }, [] as ChainSource[]);

            const chainSourceFromSettings = () => flattenedChainSources.find((source) => selectedSource && source.label === selectedSource);
            const feedSourceFromSettings = () =>
              sources.find(
                (source) => !!selectedSource && [allInstruments, 'Indices'].includes(selectedSource) && source.label === selectedSource
              ) as FeedSource;
            const defaultChainAsFallback = () => flattenedChainSources.find((source) => source.chain.type === 'Default');
            const firstChainAsFallback = () => flattenedChainSources.find((source) => !!source.chain);
            const feedSourceAsFallback = () => sources.find((source) => [allInstruments, 'Indices'].includes(source.label)) as FeedSource;
            const returnVal =
              chainSourceFromSettings() ?? feedSourceFromSettings() ?? defaultChainAsFallback() ?? firstChainAsFallback() ?? feedSourceAsFallback();
            return of(returnVal);
          })
        )
      )
    );
  };

  private symbols$ = (widget: Widget, progress?: ProgressService | null): Observable<Instrument[]> =>
    this.selectedSource$(widget).pipe(
      switchMap((selectedSource) => {
        return (isChainSource(selectedSource)
          ? this.instrumentsFomChain(selectedSource.chain.feed, selectedSource.chain.name)
          : this.instrumentsFromFeed(widget, selectedSource)
        ).pipe(
          trackProgress({ label: 'lists-symbols$', optional: true, progress }),
        );
      }),
    );

  private symbolData$ = ({ widget, gridRef, fields, progress }: { widget: Widget, gridRef: GridRef, fields?: InfrontSDK.SymbolField[], progress?: ProgressService | null }): Observable<InfrontSDK.SymbolData[]> => {
    return this.symbols$(widget).pipe(
      filter((inItems) => !!inItems.length),
      distinctUntilChanged((prev, next) => isSameObject(prev, next)),
      this.sdkRequestsService.symbolsFromIds({ gridRef, fields, progress }),
      this.sdkRequestsService.filterUndefinedResolvedSymbolIds,
    );
  };

  data$ = (widget: Widget, gridRef: GridRef, fields?: InfrontSDK.SymbolField[], progress?: ProgressService | null): Observable<SymbolDataItem[]> => {
    return this.symbolData$({ widget, gridRef: gridRef, fields, progress }).pipe(
      withLatestFrom(this.storeService.widget$(widget), this.selectedSource$(widget)),
      switchMap(([data, _inWidget, selectedSource]) =>
        gridRef.staticGrid$.pipe(
          map((grid) => [data, grid]),
          map(([data, grid]: [InfrontSDK.SymbolData[], Grid]) => (useSort(grid) && selectedSource.sourceType === 'Feed' ? sdkSort(data as SymbolDataItem[]) : data)),
          // get tradableFeeds list for current trading gateway to be added to the symbol-data
          switchMap((data) => combineLatest([of(data), this.tradableService.tradableFeedsForCurrentGateway$]).pipe(
            trackProgress({ label: 'lists-symbolData$', optional: true, progress }),
          )),
          // create the final symbol-data
          map(([data, tradableFeeds]) => data.map((symbol, index) => ({
            index: `${index}~${InfrontUtil.makeUUID()}`,
            ...symbol,
            isTradable: this.tradableService.isSymbolTradable({ symbol, tradableFeeds }),
          } as SymbolDataItem))),
          shareReplay(1),
          //tap((data) => this.logger.log('last out from list service!!!', data)) // debug
        )
      )
    );
  };

  private readonly logger = this.logService.openLogger('widgets/lists/service');

  constructor(
    private readonly logService: LogService,
    private sdkService: SdkService,
    private storeService: StoreService,
    private sdkRequestsService: SdkRequestsService,
    private tradableService: TradableService,
  ) {
  }

  private instrumentsFromFeed(widget: Widget, selectedSource: FeedSource): Observable<Instrument[]> {
    return this.symbolsByFeed$(widget).pipe(
      map((symbols: InfrontSDK.SymbolData[]) =>
        symbols.filter((symbol) =>
          selectedSource.label === 'Indices'
            ? (symbol.get(InfrontSDK.SymbolField.SymbolType) as string) === 'Index'
            : (symbol.get(InfrontSDK.SymbolField.SymbolType) as string) !== 'Index'
        )
      ),
      map((symbols: InfrontSDK.SymbolData[]) => convertSymbolsToInstrumentList(symbols))
      // tap(() => this.logger.log('feed source')) // debug
    );
  }

  instrumentsFomChain(feed: number, chainName: string): Observable<Instrument[]> {
    const opts: Partial<InfrontSDK.FeedContentsOptions> = {
      contentType: InfrontSDK.FeedContentType.ChainContent,
      feed,
      chainName,
      providerId: 0,
      subscribe: false,
    };
    return this.sdkService.getArray$(InfrontSDK.feedContents, opts).pipe(
      map((result) => (result as unknown as InfrontSDK.ChainContent).items.map((item) => ({ feed: item.feed, ticker: item.ticker })))
      // tap(() => this.logger.log('Chain source')) // debug
    );
  }
}
