import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { AgGridModule } from 'ag-grid-angular';

import { ContextMenuModule } from '../../dashboard-window/ui/context-menu/context-menu.module';
import { BigNumberModule } from '../big-number/big-number.module';
import { CountryFlagModule } from '../country-flag/country-flag.module';
import { PerformanceBarModule } from '../performance-bar/performance-bar.module';
import { StarRatingModule } from '../star-rating/star-rating.module';
import { CellFlashComponent } from './cell-flash/cell-flash.component';
import { CountryDisplayNameCellComponent } from './country-flag-cell/country-display-name-cell.component';
import { CountryFlagCellComponent } from './country-flag-cell/country-flag-cell.component';
import { CountryFlagTickerCellComponent } from './country-flag-cell/country-flag-ticker-cell.component';
import { CustomHeaderComponent } from './custom-header/custom-header.component';
import { DistributionBarCellComponent } from './distribution-bar-cell/distribution-bar-cell.component';
import { GridComponent } from './grid.component';
import { MatCheckboxComponent } from './mat-checkbox/mat-checkbox';
import { MaterialIconComponent } from './material-icon/material-icon.component';
import { PerformanceBarCellComponent } from './performance-bar-cell/performance-bar-cell.component';
import { PerformanceBarTooltipComponent } from './performance-bar-tooltip/performance-bar-tooltip.component';
import { PositionsExposureBarCellComponent } from './positions-exposure-bar-cell/positions-exposure-bar-cell.component';
import { PriceArrowComponent } from './price-arrow/price-arrow.component';
import { RankingCellComponent } from './ranking-cell/ranking-cell.component';
import { RowMenuComponent } from './row-menu/row-menu.component';
import { TimeSeriesRequestComponent } from './time-series-request/time-series-request.component';
import { TimeSeriesComponent } from './time-series/time-series.component';

@NgModule({
  declarations: [
    GridComponent,
    CustomHeaderComponent,
    RowMenuComponent,
    TimeSeriesComponent,
    RankingCellComponent,
    TimeSeriesRequestComponent,
    PriceArrowComponent,
    PerformanceBarCellComponent,
    PerformanceBarTooltipComponent,
    CountryFlagCellComponent,
    CountryFlagTickerCellComponent,
    CountryDisplayNameCellComponent,
    DistributionBarCellComponent,
    PositionsExposureBarCellComponent,
    MaterialIconComponent,
    CellFlashComponent,
    MatCheckboxComponent,
  ],
  imports: [
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
    CommonModule,
    AgGridModule,
    MatIconModule,
    ContextMenuModule,
    PerformanceBarModule,
    CountryFlagModule,
    BigNumberModule,
    StarRatingModule,
    MatCheckboxModule,
    FormsModule,
  ],
  exports: [GridComponent],
})
export class GridModule { }
