import { AfterViewChecked, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { BehaviorSubject, Subject, combineLatest, of } from 'rxjs';
import { delay, map, switchMap, takeWhile, tap } from 'rxjs/operators';

import { ContextMenuContext, ContextMenuItem } from '../../../shared/models/context-menu.model';
import { isInstrumentSettings } from '../../../state-model/window.model';
import { ContextMenuService } from './context-menu.service';
import { MenuPanelComponent } from './menu-panel/menu-panel.component';

@Component({
  selector: 'wt-context-menu',
  templateUrl: './context-menu.component.html',
  providers: [ContextMenuService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuComponent implements OnDestroy, AfterViewChecked {

  private readonly ngUnsubscribe = new Subject<void>();

  @Input() context: ContextMenuContext;
  @Output() itemClick = new EventEmitter<ContextMenuItem>();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('panel') menuPanel: MenuPanelComponent;

  constructor(private contextMenuService: ContextMenuService) { }

  activateAction = new Subject<void>();
  private triggerAvailable = new BehaviorSubject<boolean>(false);

  menuItems$ =
    of(undefined).pipe(
      delay(0), // same effect as setTimeout, now it seems angular change detection per row is skipped
      switchMap(() => {
        if (this.context.tradingOrderGetter?.()) {
          return this.contextMenuService.tradingOrderItems$(this.context);
        }
        if (!this.context.instrument && this.context.tradingPositionGetter?.()) {
          return this.contextMenuService.tradingPositionUnmappedItems$(this.context);
        }
        if (this.context.instrument) {
          // together with normal grid rows having instrument data, the mapped trading-positions are also handled here!
          return this.contextMenuService.symbolItems$(this.context);
        }
        return this.contextMenuService.windowAndWidgetItems$(this.context);
      })
    )
    ;

  isActive$ = combineLatest([this.activateAction, this.menuItems$]).pipe(map(() => true));



  private autoOpenTriggered = false;

  autoOpenMenuAfterActivation$ = combineLatest([this.triggerAvailable, this.isActive$]).pipe(
    takeWhile(() => this.autoOpenTriggered === false),
    tap(([triggerAvailable]) => {
      if (triggerAvailable) {
        this.trigger?.openMenu();
        this.autoOpenTriggered = true;
      }
    }),
  );

  // regular menu items trigger notification in context menu service
  onItemClick(item: ContextMenuItem): void {
    const widget = this.contextMenuService.widget(this.context);
    const window = this.contextMenuService.window(this.context);
    const instrument = this.context.instrument ?? (isInstrumentSettings(window?.settings) ? window?.settings.instrument : undefined);
    if (item.onClick) {
      item.onClick({ ...this.context, item, widget, window, instrument });
    }
    if (item.closeOnClick) {
      this.trigger?.closeMenu();
    }
  }

  ngAfterViewChecked(): void {
    if (this.trigger && !this.triggerAvailable.value) {
      this.triggerAvailable.next(true);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
