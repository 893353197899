import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfrontUtil } from '@infront/sdk';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { RenameUserlistDialogComponent } from '../../dashboard-window/headers/userlist-header/rename-userlist-dialog/rename-userlist-dialog.component';
import { SdkRequestsService } from '../../services/sdk-requests.service';
import { SymbolDataItem } from '../../shared/models/symbol-data.model';
import { Instrument } from '../../state-model/window.model';
import { GridRef } from '../../wrappers/grid-wrappers/gridref';

@Injectable({
  providedIn: 'root',
})
export class UserlistService {


  symbols$(instruments: Instrument[], gridRef: GridRef): Observable<SymbolDataItem[]> {
    return of(instruments).pipe(
      this.sdkRequestsService.symbolsFromIds({ gridRef }),
      this.sdkRequestsService.filterUndefinedResolvedSymbolIds,
      map((data: SymbolDataItem[]) => data.map((item, index) => ({ ...item, index: `${index}~${InfrontUtil.makeUUID()}` } as SymbolDataItem))),
    );
  }



  renameDialog$(name: string): Observable<string> {
    return this.dialog.open(RenameUserlistDialogComponent, { maxWidth: '400px', data: { name } }).afterClosed().pipe(take(1),
      map((result: string) => {
        return result;
      }));
  }

  constructor(private sdkRequestsService: SdkRequestsService, private dialog: MatDialog) { }


}
