import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CountryFlagComponent } from './country-flag.component';
import { CountryFlagService } from './country-flag.service';

@NgModule({
  declarations: [CountryFlagComponent],
  imports: [CommonModule, MatIconModule],
  exports: [CountryFlagComponent],
  providers: [CountryFlagService],
})
export class CountryFlagModule { }
