import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { SymbolsGridModule } from '../../wrappers/grid-wrappers/symbols-grid/symbols-grid.module';
import { ChainsComponent } from './chains.component';
import { MatMenuModule } from '@angular/material/menu';
import { ContextMenuModule } from '../../dashboard-window/ui/context-menu/context-menu.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { AddByIdentifierDialogComponent } from './dialogs/add-by-identifier-dialog.component';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { RenameChainDialogComponent } from './dialogs/rename-chain-dialog.component';
import { AddChainFolderDialogComponent } from './dialogs/add-folder-dialog.component';
import { AddChainDialogComponent } from './dialogs/add-chain-dialog.component';
import { MarketOverviewDropdownModule } from '../../dashboard-window/ui/market-overview-dropdown/market-overview-dropdown.module';
import { FeedListMenuModule } from '../../shared/feed-list-menu/feed-list-menu.module';
import { FindInstrumentModule } from '../../shared/find-instrument/find-instrument.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SymbolsGridModule,
    ContextMenuModule,
    MatMenuModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
    FeedListMenuModule,
    FindInstrumentModule,
    MarketOverviewDropdownModule,
  ],
  exports: [],
  declarations: [
    ChainsComponent,
    AddByIdentifierDialogComponent,
    AddChainDialogComponent,
    AddChainFolderDialogComponent,
    RenameChainDialogComponent,
  ],
  providers: [
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  ],
})
export class ChainsModule { }
