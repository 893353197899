<div class="wt-flex-row wt-flex-collapse wt-flex-align-middle wt-instrument-dock__list-item-container"
  *ngIf="symbolInfo$ | async as symbolInfo">
  <div class="wt-flex-column wt-flex-small-10">
    <div class="wt-instrument-dock__list-item-ticker" title="{{ symbolInfo.preDisplayTicker | alternativeValue }}"
      *ngIf="symbolInfo.preDisplayTicker"
      (click)="onTickerClicked(symbolInfo)">
      <wt-country-flag class="wt-instrument-dock__list-item-flag" [country]="symbolInfo.countryFlag"
        [size]="16"></wt-country-flag>
      {{ symbolInfo.preDisplayTicker | alternativeValue }}
    </div>
  </div>

  <div class="wt-flex-column wt-flex-small-2">
    <div class="wt-instrument-dock__list-item-arrow">
      <span [ngClass]="{
          'wt-icon-triangle-up-strong': $any(symbolInfo?.changePercent) > 2,
          'wt-icon-triangle-up': $any(symbolInfo?.changePercent) > 1 && $any(symbolInfo?.changePercent) <= 2,
          'wt-icon-triangle-up-weak': $any(symbolInfo?.changePercent) > 0 && $any(symbolInfo?.changePercent) <= 1,
          'wt-icon-triangle-down-weak': $any(symbolInfo?.changePercent) < 0 && $any(symbolInfo?.changePercent) >= -1,
          'wt-icon-triangle-down': $any(symbolInfo?.changePercent) < -1 && $any(symbolInfo?.changePercent) >= -2,
          'wt-icon-triangle-down-strong': $any(symbolInfo?.changePercent) < -2
        }"></span>
    </div>
  </div>

  <div class="wt-flex-column wt-flex-small-7">
    <ng-container *ngIf="symbolInfo.preDisplayTime">
      <div class="wt-instrument-dock__list-item-last"
        title="{{ symbolInfo.preLastTradedAt | price: { decimals: symbolInfo.decimals } }}"
        [outdatedPriceTimestamp]="symbolInfo.preDisplayTime">
        {{ symbolInfo.preLastTradedAt | price: { decimals: symbolInfo.decimals } }}
      </div>
    </ng-container>
  </div>

  <div class="wt-flex-column wt-flex-small-5">
    <div class="wt-instrument-dock__list-item-change"
      [ngClass]="{
          'up-indicator': $any(symbolInfo?.changePercent) > 0,
          'down-indicator': $any(symbolInfo?.changePercent) < 0,
          'unchanged-indicator': !symbolInfo.changePercent
      }">
      {{ symbolInfo.changePercent | pctChange: { decimals: pctChangeDecimals(symbolInfo.changePercent) } }}
    </div>
  </div>
</div>