import { isDevMode } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { Dashboard } from '../state-model/dashboard.model';
import { Grid } from '../state-model/grid.model';
import { InstrumentHeaderDefaults, PortfolioHeaderDefaults } from '../state-model/widget.defaults';
import { PortfolioHeaderWidget, Widget } from '../state-model/widget.model';
import { InstrumentHeaderWindowDefaults, PortfolioHeaderWindowDefaults } from '../state-model/window.defaults';
import { DashboardWindow, Instrument, PortfolioOrdersWindow, PortfolioPositionsWindow } from '../state-model/window.model';
import { OrderCategory } from './../widgets/portfolio-orders/portfolio-orders.model';
import { TradingClassification } from './../widgets/portfolio-positions/portfolio-positions.model';
import { PORTFOLIO_DASHBOARD_ID } from './providers/portfolio-dashboards';
import { dashboardTemplates } from './templates/';
import {
  portfolioOrdersBaseWindow,
  portfolioOrdersGrids,
  portfolioOrdersWidgets,
  portfolioOrdersWindows,
} from './templates/portfolio-orders-template';
import {
  BalanceWidget,
  BalanceWindow,
  CashWidget,
  CashWindow,
  DevelopmentWidget,
  DevelopmentWindow,
  EventsWidget,
  EventsWindow,
  ExposureWidget,
  ExposureWindow,
  portfolioPositionsBaseWindow,
  portfolioPositionsGrids,
  portfolioPositionsWidgets,
  portfolioPositionsWindows
} from './templates/portfolio-positions-template';
import { DashboardTemplate, readonlyWindowParams } from './templates/templates.model';
import { validateTemplate } from './templates/validation';

export const InstrumentDashboardTabs = ['OVERVIEW', 'CHART'] as const;
export type InstrumentDashboardTab = (typeof InstrumentDashboardTabs)[number];

export const INSTRUMENT_DASHBOARD_OVERVIEW_POSTFIX = '~instrument-dashboard-overview';
export const INSTRUMENT_DASHBOARD_CHART_POSTFIX = '~instrument-dashboard-chart';

const emptyDashboardTemplate = {
  windows: [],
  widgets: [],
  grids: [],
};

const headerHeight = 1;

const portfolioWindowDefaultWidth = 38;
const portfolioWindowDefaultHeight = 12;
export const sideWindowDefaultHeight = 10;

export const InstrumentHeaderWindowName = 'InstrumentHeaderWindow';
export const PortfolioHeaderWindowName = 'PortfolioHeaderWindow';

export const createInstrumentDashboardConfig = (
  dashboardId: string,
  instrument: Instrument,
  classification: InfrontSDK.SymbolClassification = InfrontSDK.SymbolClassification.Unknown
) => {
  const templates: DashboardTemplate | undefined = dashboardTemplates[classification] ?? dashboardTemplates[InfrontSDK.SymbolClassification.Stock];

  if (!templates) {
    return emptyDashboardTemplate;
  }

  // Header
  const headerWindow = {
    id: 'header',
    ...InstrumentHeaderWindowDefaults,
    x: 0,
    y: 0,
    name: InstrumentHeaderWindowName,
    selectedWidgetName: 'InstrumentHeader',
    dashboardId,
  } as DashboardWindow;
  const headerWidget: Widget = {
    id: 'header',
    ...InstrumentHeaderDefaults,
    windowId: headerWindow.id,
    dashboardId: headerWindow.dashboardId,
    settings: { ...InstrumentHeaderDefaults.settings, instrument },
  };

  // Overview
  const overviewWindows = templates.overviewDashboardWindows.map(w => {
    return { ...w, dashboardId, tag: 'OVERVIEW', settings: { ...w.settings, instrument }, minItemCols: w.cols, maxItemCols: w.cols, minItemRows: w.rows, maxItemRows: w.rows };
  });
  const overviewWidgets = templates.overviewDashboardWidgets.map(w => ({ ...w, dashboardId }));
  const overviewGrids = templates.overviewDashboardGrids.map(g => ({ ...g, dashboardId }));

  // Chart
  const chartWindows = templates.chartDashboardWindows.map((w) => {
    return { ...w, dashboardId, tag: 'CHART', settings: { ...w.settings, instrument } };
  });
  const chartWidgets = templates.chartDashboardWidgets.map(w => ({ ...w, dashboardId }));

  const entities = {
    windows: [headerWindow, ...overviewWindows, ...chartWindows],
    widgets: [headerWidget, ...overviewWidgets, ...chartWidgets],
    grids: [...overviewGrids],
  };
  return entities;
};

export const PortfolioDashboardTabs = ['POSITIONS', 'ORDERS', 'TRADES'] as const;

export const createPortfolioEntitiesConfig = (
  classifications: Readonly<TradingClassification[]>,
  orderCategories: Readonly<OrderCategory[]>,
): {
  dashboards: Dashboard[];
  windows: DashboardWindow[];
  widgets: Widget[];
  grids: Grid[];
} => {
  // Portfolio Header
  const headerWindow = {
    id: 'header',
    ...PortfolioHeaderWindowDefaults,
    name: PortfolioHeaderWindowName,
    selectedWidgetName: 'PortfolioHeader',
    dashboardId: PORTFOLIO_DASHBOARD_ID, // todo: change from hardcoded to generated
    x: 0,
    y: 0,
  } as DashboardWindow;
  const headerWidget: PortfolioHeaderWidget = {
    id: 'header',
    dashboardId: PORTFOLIO_DASHBOARD_ID,
    ...PortfolioHeaderDefaults,
    windowId: headerWindow.id,
    settings: { ...PortfolioHeaderDefaults.settings },
  };

  // Portfolio Positions by classification
  const positionsWindows = classifications.map((c) => ({
    ...portfolioPositionsBaseWindow,
    ...{ ...portfolioPositionsWindows[c] },
    ...{ id: c, tradingClassification: c, tag: 'POSITIONS' },
  })) as PortfolioPositionsWindow[];
  const positionsWidgets = classifications.map((c, i) => ({
    ...portfolioPositionsWidgets[c],
    ...{ id: 'w-' + c, name: 'PortfolioPositions', tradingClassification: c, windowId: positionsWindows[i].id },
  })) as Widget[];
  const positionsGrids = classifications.reduce(
    (acc, c, i) => [...acc, ...portfolioPositionsGrids[c].map((grid) => ({ id: grid.name, parentId: positionsWidgets[i].id, ...grid }))],
    []
  ) as Grid[];

  // Portfolio Positions summary
  const positionsSummaryWindows: DashboardWindow[] = [
    { ...DevelopmentWindow, id: 'dev' },
    { ...BalanceWindow, id: 'balance' },
    { ...CashWindow, id: 'cash' },
    { ...ExposureWindow, id: 'exposure' },
    { ...EventsWindow, id: 'event' }
  ] as DashboardWindow[];

  const positionsSummaryWidgets: Widget[] = [
    { ...DevelopmentWidget, id: 'w-dev', windowId: positionsSummaryWindows[0].id, dashboardId: positionsSummaryWindows[0].dashboardId },
    { ...BalanceWidget, id: 'w-balance', windowId: positionsSummaryWindows[1].id, dashboardId: positionsSummaryWindows[1].dashboardId },
    { ...CashWidget, id: 'w-cash', windowId: positionsSummaryWindows[2].id, dashboardId: positionsSummaryWindows[2].dashboardId },
    { ...ExposureWidget, id: 'w-exposure', windowId: positionsSummaryWindows[3].id, dashboardId: positionsSummaryWindows[3].dashboardId },
    { ...EventsWidget, id: 'w-events', windowId: positionsSummaryWindows[4].id, dashboardId: positionsSummaryWindows[4].dashboardId },
  ] as Widget[];

  // Portfolio Orders by order-category
  const ordersWindows = orderCategories.map((c) => ({
    ...portfolioOrdersBaseWindow,
    ...{ ...portfolioOrdersWindows[c] },
    ...{ id: 'orders' + c, orderCategory: c, tag: 'ORDERS' },
  })) as PortfolioOrdersWindow[];
  const ordersWidgets = orderCategories.map((c, i) => ({
    ...portfolioOrdersWidgets[c],
    ...{ id: 'w-orders' + c, name: 'PortfolioOrders', orderCategory: c, windowId: ordersWindows[i].id },
  })) as Widget[];
  const ordersGrids = orderCategories.reduce(
    (acc, c, i) => [...acc, ...portfolioOrdersGrids[c].map((grid) => ({ id: grid.name, parentId: ordersWidgets[i].id, ...grid }))],
    []
  ) as Grid[];

  // Portfolio Trades
  const tradesWindow =
    {
      id: 'trades',
      tag: 'TRADES',
      linkChannel: 'Channel 1',
      name: 'PortfolioTradesWindow',
      settings: {},
      dashboardId: PORTFOLIO_DASHBOARD_ID,
      selectedWidgetName: 'PortfolioTrades',
      cols: portfolioWindowDefaultWidth,
      rows: portfolioWindowDefaultHeight,
      label: 'Trades',
      x: 0,
      y: headerHeight,
      ...readonlyWindowParams,
    } as DashboardWindow;


  const tradesWidget = { id: 'w-trades', name: 'PortfolioTrades', windowId: tradesWindow.id } as Widget;

  const tradesGrids = [{ id: 'g-trades', parentId: tradesWidget.id, name: 'portfolioTrades' }] as Grid[];

  // trades net-trades
  const netTradesWindow = {
    id: 'net-trades',
    tag: 'TRADES',
    linkChannel: 'Channel 1',
    name: 'NetTradesWindow',
    settings: {},
    dashboardId: PORTFOLIO_DASHBOARD_ID,
    selectedWidgetName: 'NetTrades',
    cols: 12,
    rows: 16,
    x: portfolioWindowDefaultWidth,
    y: headerHeight,
    ...readonlyWindowParams
  } as DashboardWindow;

  const netTradesWidget = { id: 'w-net-trades', name: 'NetTrades', windowId: netTradesWindow.id } as Widget;

  function ensureDashboardId<T extends { dashboardId?: string }>(value: T): T {
    value.dashboardId = PORTFOLIO_DASHBOARD_ID;
    return value;
  }

  if (isDevMode()) {
    validateTemplate('portfolio/positions', positionsWindows, positionsWidgets, positionsGrids);
    validateTemplate('portfolio/summary', positionsSummaryWindows, positionsSummaryWidgets);
    validateTemplate('portfolio/orders', ordersWindows, ordersWidgets, ordersGrids);
  }

  return {
    dashboards: [],
    windows: [headerWindow, ...positionsWindows, ...positionsSummaryWindows, ...ordersWindows, tradesWindow, netTradesWindow].map(ensureDashboardId),
    widgets: [headerWidget, ...positionsWidgets, ...positionsSummaryWidgets, ...ordersWidgets, tradesWidget, netTradesWidget].map(ensureDashboardId),
    grids: [...positionsGrids, ...ordersGrids, ...tradesGrids].map(ensureDashboardId),
  };
};

