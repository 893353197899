import { InfrontSDK } from '@infront/sdk';

import { DashboardService } from '../../../../dashboard/dashboard.service';
import { DashboardWindowToAdd } from '../../../../directives/dashboard-window-controller.directive';
import { ContextMenuItem } from '../../../../shared/models/context-menu.model';
import { DashboardWindow, WindowName } from '../../../../state-model/window.model';
import { canAddWindowForClassification } from '../../../../util/symbol';
import { WindowItemTypeOptions } from '../context-menu.model';

export const addWindowItem = (window: DashboardWindow, options: WindowItemTypeOptions, dashboardService: DashboardService): ContextMenuItem => {
  interface AddWindowSubItem {
    name: WindowName;
    title: string;
    windowToAdd?: DashboardWindowToAdd;
  }
  const classification = options?.classification ?? InfrontSDK.SymbolClassification.Unknown;
  const subItems: AddWindowSubItem[] = [
    { name: 'InstrumentWindow', title: 'CONTEXT_MENU_COMPONENT.ADD_WINDOW.INSTRUMENT_OVERVIEW' },
    { name: 'FocusWindow', title: 'CONTEXT_MENU_COMPONENT.ADD_WINDOW.FOCUS' },
    { name: 'ChartWindow', title: 'CONTEXT_MENU_COMPONENT.ADD_WINDOW.CHART' },
    { name: 'ChartMiniWindow', title: 'CONTEXT_MENU_COMPONENT.ADD_WINDOW.CHART_MINI' },
    ...(canAddWindowForClassification('Orderbook', classification)
      ? [{ name: 'OrderbookWindow', title: 'CONTEXT_MENU_COMPONENT.ADD_WINDOW.ORDERBOOK' }]
      : []),
    ...(canAddWindowForClassification('Trades', classification)
      ? [{ name: 'InstrumentWindow', title: 'CONTEXT_MENU_COMPONENT.ADD_WINDOW.TRADES', windowToAdd: { selectedWidgetName: 'Trades' } }]
      : []),
    ...(canAddWindowForClassification('News', classification)
      ? [{ name: 'NewsWindow', title: 'CONTEXT_MENU_COMPONENT.ADD_WINDOW.NEWS', windowToAdd: { settings: { newsType: 'Instrument' } } }]
      : []),
    { name: 'InstrumentWindow', title: 'CONTEXT_MENU_COMPONENT.ADD_WINDOW.HISTORY', windowToAdd: { selectedWidgetName: 'History' } },
  ] as AddWindowSubItem[];
  return {
    id: 'addWindows',
    title: 'CONTEXT_MENU_COMPONENT.ADD_WINDOW.TITLE',
    icon: 'apps',
    subItems: subItems.map((item, index) => {
      return {
        id: `#${index}`,
        title: item.title,
        icon: 'widgets',
        fontSet: '', // no fontSet = filled icons
        closeOnClick: true,
        onClick: (params) => {
          if (!params.instrument || !window) {
            return;
          }
          const windowToAdd: DashboardWindowToAdd = { name: item.name, ...item.windowToAdd };
          windowToAdd.settings = { ...(windowToAdd.settings ?? {}), instrument: params.instrument };
          dashboardService.addWindow(item.name, undefined, windowToAdd);
        },
      };
    }),
  };
};
