import { TradingOrderEntryService } from '../../../../services/trading-order-entry.service';
import { ContextMenuItem } from '../../../../shared/models/context-menu.model';

export const deleteOrderItem: (tradingOrderEntryService: TradingOrderEntryService) => ContextMenuItem = (tradingOrderEntryService) => {
  return {
    id: 'deleteOrder',
    title: 'CONTEXT_MENU_COMPONENT.TRADING.DELETE_ORDER',
    icon: 'delete',
    closeOnClick: true,
    onClick: (params) => {
      const order = params?.tradingOrderGetter?.();
      if (!order?.OrderId || !order?.OrderPortfolio) {
        return;
      }
      tradingOrderEntryService.deleteOrder({ orderId: order.OrderId, portfolio: order.OrderPortfolio });
    },
  };
};
