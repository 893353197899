import { Injectable } from '@angular/core';
import { DashboardServicesProvider } from '../dashboard-services.provider';
import { BuiltInWidgetDataProvider } from './builtin-widget-data.provider';
import { BuiltInDashboardProvider } from './builtin-dashboard.provider';

@Injectable({ providedIn: 'root' })
export class BuiltInDashboardServicesProvider implements DashboardServicesProvider {
  constructor(
    public readonly dashboards: BuiltInDashboardProvider,
    public readonly widgets: BuiltInWidgetDataProvider,
  ) { }
}
