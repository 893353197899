import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'wt-new-window-dialog',
  templateUrl: './new-window-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewWindowDialogComponent {
  constructor(public dialogRef: MatDialogRef<NewWindowDialogComponent>, @Inject(MAT_DIALOG_DATA) public windowTypes: string[]) {
    this.selectedType = windowTypes[0];
  }

  selectedType: string;

  onCancel(): void {
    this.dialogRef.close();
  }
}
