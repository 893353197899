// Window Defaults
import { readonlyWindowParams } from '../dashboard/templates/templates.model';
import { PeriodService } from '../services/period.service';
import { DefaultPeriods } from '../shared/period/default-periods';
import {
  AlertLogWindow,
  CalendarWindow,
  ChainsWindow,
  ChartMiniWindow,
  ChartWindow,
  CompanyDataWindow,
  CompanyInformationWindow,
  DashboardWindow,
  FocusMiniWindow,
  FocusWindow,
  InstrumentHeaderWindow,
  InstrumentWindow,
  MarketOverviewWindow,
  MarketWindow,
  NewsWindow,
  OrderbookWindow,
  PortfolioHeaderWindow,
  PortfolioTradesWindow,
  PositionsEventsWindow,
  PositionsExposureWindow,
  PositionsSummaryWindow,
  TopShareholdersWindow,
  UserlistWindow,
  WatchlistWindow,
  WindowName
} from './window.model';

const defaultInstrument = { feed: 18177, ticker: 'DNB' };

export const TOP_BAR_TOTAL_WIDTH = 50;

export const DashboardWindowDefaults: Readonly<Partial<DashboardWindow>> = {
  cols: 12,
  rows: 12,
  linkChannel: 'None',
};

export type WindowDefaults<TWindow extends DashboardWindow> = Pick<TWindow, 'settings'> &
  Partial<Pick<TWindow,
    'cols'
    | 'rows'
    | 'maxItemRows'
    | 'minItemRows'
    | 'maxItemCols'
    | 'minItemCols'
    | 'canSetLinkedInstrument'
    | 'widgetSettingsOverrides'
  >>;

export const MarketWindowDefaults: Readonly<WindowDefaults<MarketWindow>> = {
  settings: { feed: 18177 },
  cols: 29,
  rows: 16,
  minItemCols: 12,
  minItemRows: 9,
  canSetLinkedInstrument: true,
};

export const MarketOverviewWindowDefaults: Readonly<WindowDefaults<MarketOverviewWindow>> = {
  settings: { template: 'Norway' },
  cols: 12,
  rows: 16,
  minItemCols: 11,
  minItemRows: 14,
  canSetLinkedInstrument: true,
};

export const WatchlistWindowDefaults: Readonly<WindowDefaults<WatchlistWindow>> = {
  settings: { selectedWatchlist: undefined },
  cols: 29,
  rows: 16,
  minItemCols: 14,
  minItemRows: 9,
  canSetLinkedInstrument: true,
};

export const UserlistWindowDefaults: Readonly<WindowDefaults<UserlistWindow>> = {
  settings: {},
  cols: 29,
  rows: 16,
  minItemCols: 14,
  minItemRows: 9,
  canSetLinkedInstrument: true,
};


export const OrderbookWindowDefaults: Readonly<WindowDefaults<OrderbookWindow>> = {
  settings: { instrument: { ...defaultInstrument } },
  cols: 12,
  rows: 8,
  minItemCols: 10,
  minItemRows: 5,
};

export const InstrumentWindowDefaults: Readonly<WindowDefaults<InstrumentWindow>> = {
  settings: { instrument: { ...defaultInstrument } },
  widgetSettingsOverrides: {
    Calendar: {
      selectedPeriod: PeriodService.getStoreablePeriod(DefaultPeriods.MINUS1W_PLUS1M),
    }
  },
  cols: 15,
  rows: 14,
  minItemCols: 10,
  minItemRows: 5,
  canSetLinkedInstrument: true,
};

export const NewsWindowDefaults: Readonly<WindowDefaults<NewsWindow>> = {
  settings: { instrument: { ...defaultInstrument }, newsType: 'Instrument' },
  cols: 16,
  rows: 14,
  minItemCols: 14,
  minItemRows: 6,
};

export const ChartWindowDefaults: Readonly<WindowDefaults<ChartWindow>> = {
  settings: { instrument: { ...defaultInstrument } },
  cols: 24,
  rows: 18,
  minItemCols: 13,
  minItemRows: 10,
};

export const ChartMiniWindowDefaults: Readonly<WindowDefaults<ChartMiniWindow>> = {
  settings: { instrument: { ...defaultInstrument } },
  cols: 13,
  rows: 10,
  minItemCols: 10,
  minItemRows: 9,
};

export const FocusWindowDefaults: Readonly<WindowDefaults<FocusWindow>> = {
  settings: { instrument: { ...defaultInstrument } },
  cols: 8,
  rows: 5,
  minItemCols: 8,
  minItemRows: 5,
};

export const FocusMiniWindowDefaults: Readonly<WindowDefaults<FocusMiniWindow>> = {
  settings: { instrument: { ...defaultInstrument } },
  cols: 8,
  rows: 5,
  minItemCols: 8,
  minItemRows: 5,
};

export const AlertLogWindowDefaults: Readonly<WindowDefaults<AlertLogWindow>> = {
  settings: {},
  cols: 9,
  rows: 14,
};

export const InstrumentHeaderWindowDefaults: WindowDefaults<InstrumentHeaderWindow> = {
  settings: {},
  cols: TOP_BAR_TOTAL_WIDTH,
  rows: 1,
  ...readonlyWindowParams,
};

export const CalendarWindowDefaults: WindowDefaults<CalendarWindow> = {
  settings: {
    calendarType: 'Instrument',
  },
  cols: 16,
  rows: 14,
  minItemCols: 14,
  minItemRows: 6,
};

export const PortfolioHeaderWindowDefaults: WindowDefaults<PortfolioHeaderWindow> = {
  settings: {},
  cols: TOP_BAR_TOTAL_WIDTH,
  rows: 1,
};

export const PositionsSummaryWindowDefaults: WindowDefaults<PositionsSummaryWindow> = {
  settings: {},
};

export const PortfolioPositionsWindowDefaults: WindowDefaults<PositionsSummaryWindow> = {
  settings: {},
};

export const PositionsEventsWindowDefaults: WindowDefaults<PositionsEventsWindow> = {
  settings: {},
};

export const PositionsExposureWindowDefaults: WindowDefaults<PositionsExposureWindow> = {
  settings: {},
};

export const PortfolioTradesWindowDefaults: WindowDefaults<PortfolioTradesWindow> = {
  settings: {},
};

export const CompanyDataWindowDefaults: WindowDefaults<CompanyDataWindow> = {
  settings: {}
};

export const CompanyInformationWindowDefaults: WindowDefaults<CompanyInformationWindow> = {
  settings: {
    instrument: { ...defaultInstrument },
  },
  cols: 16,
  rows: 14,
  minItemCols: 14,
  minItemRows: 6,
};

export const TopShareholdersWindowDefaults: WindowDefaults<TopShareholdersWindow> = {
  settings: {}
};

export const ChainsWindowDefaults: WindowDefaults<ChainsWindow> = {
  settings: {},
  cols: 29,
  rows: 16,
  minItemCols: 14,
  minItemRows: 9,
  canSetLinkedInstrument: true,
};

// Non creatable windows can be added in the array below
// examplatory usage [ 'CalendarWindow' | 'NewsWindow' ] will make those two non user creatable
type NonUserCreatableWindowName = [];
type UserCreatableWindowWrapper<ExcludedWindowName extends Array<WindowName>> = Exclude<WindowName, ExcludedWindowName[number]>;
// Creatable windows (use blacklist above to exclude certain windows)
export type UserCreatableWindowName = UserCreatableWindowWrapper<NonUserCreatableWindowName>;

export const WindowDefaultsMap: { [WindowName in UserCreatableWindowName]: WindowDefaults<DashboardWindow> } = {
  MarketWindow: MarketWindowDefaults,
  MarketOverviewWindow: MarketOverviewWindowDefaults,
  WatchlistWindow: WatchlistWindowDefaults,
  OrderbookWindow: OrderbookWindowDefaults,
  InstrumentWindow: InstrumentWindowDefaults,
  NewsWindow: NewsWindowDefaults,
  ChartWindow: ChartWindowDefaults,
  ChartMiniWindow: ChartMiniWindowDefaults,
  FocusWindow: FocusWindowDefaults,
  FocusMiniWindow: FocusMiniWindowDefaults,
  AlertLogWindow: AlertLogWindowDefaults,
  CalendarWindow: CalendarWindowDefaults,
  PortfolioPositionsWindow: PortfolioPositionsWindowDefaults,
  PortfolioOrdersWindow: { settings: {} },
  PortfolioTradesWindow: PortfolioTradesWindowDefaults,
  InstrumentHeaderWindow: InstrumentHeaderWindowDefaults,
  PortfolioHeaderWindow: PortfolioHeaderWindowDefaults,
  PositionsSummaryWindow: PositionsSummaryWindowDefaults,
  PositionsEventsWindow: PositionsEventsWindowDefaults,
  PositionsExposureWindow: PositionsExposureWindowDefaults,
  NetTradesWindow: { settings: {} },
  UserlistWindow: UserlistWindowDefaults,
  FundScreenerWindow: { settings: {} },
  CompanyDataWindow: CompanyDataWindowDefaults,
  CompanyInformationWindow: CompanyInformationWindowDefaults,
  TopShareholdersWindow: TopShareholdersWindowDefaults,
  NotFoundWindow: { settings: {} },
  ChainsWindow: ChainsWindowDefaults,
};
