<ng-container *ngIf="availablePortfolios$ | async as availablePortfolios">
  <ng-container *ngIf="hasPortfolioDashboardSelected$ | async as hasPortfolioDashboardSelected">
    <wt-dropdown
      class="wt-dropdown-portfolio"
      [matMenuScopeClass]="matMenuScopeClass"
      [dropdownTemplate]="dropdownTemplate"
      [items]="availablePortfolios"
      [itemTemplate]="itemTemplate"
      [height]="400"
      (itemSelected)="onPortfolioSelected($event)"></wt-dropdown>

    <!-- dropdown template -->
    <ng-template #dropdownTemplate let-dropdown>
      <!-- //NOSONAR --><li class="wt-dropdown-portfolio-icon wt-dropdown-portfolio-icon--account-balance"
        *ngIf="hasPortfolioIcon"
        [ngClass]="{ 'wt-dropdown-portfolio-icon--non-clickable': !(selectPortfolioDashboardOnClick && !hasPortfolioDashboardSelected.selected) }"
        (click)="selectPortfolioDashboardOnClick && hasPortfolioDashboardSelected.selected === false ? onPortfolioClick() : undefined">
        <mat-icon class="mat-icon-small" fontIcon="{{ 'account_balance' }}" />
      </li>
      <ng-container *ngIf="hasPortfolioLabel">
        <!-- //NOSONAR --><li class="wt-dropdown-portfolio-label"
          [ngClass]="{ 'wt-dropdown-portfolio-label--clickable': selectPortfolioDashboardOnClick && !hasPortfolioDashboardSelected.selected }"
          (click)="selectPortfolioDashboardOnClick && hasPortfolioDashboardSelected.selected === false ? onPortfolioClick() : undefined">
          {{
          'DROPDOWN_PORTFOLIO.PORTFOLIO' | translate }}
        </li>
        <!-- //NOSONAR --><li class="wt-dropdown-portfolio-separator">I</li>
      </ng-container>
      <!-- //NOSONAR --><li class="wt-dropdown-portfolio-name" [matMenuTriggerFor]="dropdown.menu"
        (menuClosed)="dropdown.menuClosed.emit()"
        (menuOpened)="dropdown.menuOpened.emit()"
        #matMenuTriggerElement>
        {{ selectedPortfolio?.name || ('DROPDOWN_PORTFOLIO.NO_PORTFOLIO' | translate) }}
      </li>
      <!-- //NOSONAR --><li class="wt-dropdown-portfolio-description"
        *ngIf="selectedPortfolio && selectedPortfolio.description"
        [title]="selectedPortfolio.description"
        (click)="matMenuTriggerElement.click()">
        {{ selectedPortfolio.description }}
      </li>
      <!-- //NOSONAR --><li class="wt-dropdown-portfolio-icon wt-dropdown-portfolio-icon--arrow" *ngIf="matDropdownIcon">
        <mat-icon fontIcon="{{ matDropdownIcon }}"
          (click)="matMenuTriggerElement.click()" />
      </li>
    </ng-template>

    <!-- item template -->
    <ng-template let-item #itemTemplate>
      <div
        class="wt-dropdown-portfolio__item"
        [ngClass]="{ 'wt-dropdown-portfolio__item--selected': item.name === selectedPortfolio?.name }"
        (click)="onPortfolioSelected(item)">
        <div class="wt-flex-row">
          <div class="wt-flex-column">
            <span class="wt-dropdown-portfolio__item-name">
              {{ item.name | alternativeValue }}
            </span>
            <span class="wt-dropdown-portfolio__item-description">
              {{ item.description || '' }}
            </span>
          </div>
        </div>
        <div class="wt-flex-row">
          <div class="wt-flex-column">
            <span class="wt-dropdown-portfolio__item-trading-power-label">
              {{ "DROPDOWN_PORTFOLIO.TRADING_POWER" | translate }}
            </span>
          </div>
          <div class="wt-flex-column">
            <span class="wt-dropdown-portfolio__item-trading-power">
              <span class="wt-dropdown-portfolio__item-trading-power-value">
                {{ item.tradingPower | number: "1.0" }}
              </span>
              <span class="wt-dropdown-portfolio__item-trading-power-currency">
                {{ item.tradingPowerCurrency || '' }}
              </span>
            </span>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
