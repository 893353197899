import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WTKWidgetWrapperModule } from '../../wrappers/wtk-widget-wrapper/wtk-widget-wrapper.module';
import { NetTradesComponent } from './net-trades.component';

@NgModule({
  declarations: [NetTradesComponent],
  imports: [
    CommonModule,
    WTKWidgetWrapperModule,
  ],
  exports: [NetTradesComponent],
})
export class NetTradesModule { }
