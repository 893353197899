<div class="wt-search__item">

  <!-- START Search Item Window -->
  <div class="wt-flex-row wt-flex-collapse">
    <div class="wt-flex-column wt-flex-shrink">
      <span class="wt-search__item-icon">
        <mat-icon fontIcon="{{ searchItemWindow.icon }}" class="mat-icon-small"></mat-icon>
      </span>
    </div>

    <div class="wt-flex-column" title="{{ searchItemWindow.translation | translate }}">
      <span class="wt-search__item-name">
        {{ searchItemWindow.translation | translate }}
      </span>
    </div>
  </div>

  <div class="wt-flex-row wt-flex-collapse">
    <div class="wt-flex-column" title="{{ searchItemWindow.description | translate }}">
      <span class="wt-search__item-description">{{ searchItemWindow.description | translate }}</span>
    </div>
  </div>
  <!-- END Search Item Window -->

</div>