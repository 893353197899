import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { I18nTranslateModule, provideResources } from '@vwd/ngx-i18n/translate';
import { Observable, map } from 'rxjs';
import { BrandingData } from '../../services/storage.service';
import { TenantSettingsService } from '../../services/tenant-settings.service';

@Component({
  templateUrl: './support-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    provideResources({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      load: localeId => require(`./i18n/${localeId}.json`),
      supportedLocales: ['en'],
    })
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    I18nTranslateModule,
  ]
})
export class SupportDialogComponent {
  private readonly tenantService = inject(TenantSettingsService);

  branding$: Observable<BrandingData> = this.tenantService.getValue$('branding').pipe(
    map((branding) => (branding ?? {})),
  );

  makeHref(website: string | undefined): string | undefined {
    if (!website) {
      return undefined;
    } else if (!website.startsWith('http:') && !website.startsWith('https:')) {
      return 'https://' + website;
    } else {
      return website;
    }
  }

}
