<div class="wt-dialog" method="dialog">
  <div class="wt-flex-row wt-flex-align-middle wt-flex-collapse wt-dialog__title">
    <div class="wt-flex-column wt-flex-shrink wt-dialog-icon">
      <mat-icon fontIcon="info_outlined"></mat-icon>
    </div>

    <div class="wt-flex-column">
      <h1 class="wt-h1">{{ 'CHAIN_INFO.TITLE' | translate }}</h1>
    </div>
  </div>

  <div mat-dialog-content>
    <p><strong>{{ 'CHAIN_INFO.FEED_NUMBER' | translate }}:</strong> {{ chain.feed }}</p>
    <p><strong>{{ 'CHAIN_INFO.NAME' | translate }}: {{ chain.name }}</strong></p>
    <p>{{ 'CHAIN_INFO.LABEL' | translate }}: {{ chain.label }}</p>
    <p>{{ 'CHAIN_INFO.FULL_PATH' | translate }}: {{ chain.fullPath || chain.description }}</p>
    <p>{{ 'CHAIN_INFO.TYPE' | translate }}: {{ chain.type }}</p>
    <p>{{ 'CHAIN_INFO.PROVIDER_ID' | translate }}: {{ chain.providerId }}</p>
  </div>
</div>