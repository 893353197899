<div class="wt-flex-row wt-flex-align-justify wt-sidebar-instrument-lists-panel__add-instrument" *ngIf="canEdit">
  <div class="wt-flex-column wt-flex-shrink">
    <wt-search-compact
      class="wt-search__compact-inline"
      [searchConfig]="{
        feedScoreFactorItems: DefaultFeedScoreFactorItems,
        searchType: { symbol: true },
        history: false
      }"
      [placeholder]="'ADMIN_DIALOG.SIDEBAR.ADD_INSTRUMENT' | translate"
      [panelWidth]="220"
      [showFlag]="false"
      (itemClicked)="onSelectedOption($event)"></wt-search-compact>
  </div>

  <div class="wt-flex-column wt-flex-shrink wt-sidebar-instrument-lists-panel__column-chooser">
    <button
      type="button"
      class="wt-button wt-button--icon"
      (click)="showColumnPicker()">{{ 'ADMIN_DIALOG.SIDEBAR.CHOOSE_COLUMNS' | translate }}
      <mat-icon svgIcon="view_column"></mat-icon>
    </button>
  </div>

  <div *ngIf="!!symbolsGrid?.selectedRows?.length"
    class="wt-flex-column wt-sidebar-instrument-lists-panel__delete-rows">
    <button
      type="button"
      class="wt-button wt-button--icon"
      (click)="deleteSelectedRows()">
      {{ 'ADMIN_DIALOG.SIDEBAR.DELETE_SELECTED_INSTRUMENTS' | translate }}
      <mat-icon fontIcon="close"></mat-icon>
    </button>
  </div>
</div>

<div class="wt-sidebar-instrument-lists-panel__instrument-list">
  <wt-symbols-grid
    #symbolsGrid
    [symbols]="(gridSymbols$ | async) ?? []"
    [gridRef]="gridRef"
    [context]="{}"
    [gridSettings]="{
      rowDrag: canEdit,
      rowContextMenu: false,
      checkboxSelection: canEdit,
      rowSelection: (canEdit ? 'multiple' : undefined),
      disableSorting: true
    }"
    [gridOptions]="{
      rowMultiSelectWithClick: false,
      suppressRowClickSelection: true,
      alwaysShowHorizontalScroll: false,
      suppressHorizontalScroll: true,
      autoSizeStrategy: { type: 'fitGridWidth' },
      onGridColumnsChanged: onGridColumnsChanged,
      onColumnResized: onGridColumnsChanged
    }"
    (rowsChanged)="onRowsChanged($any($event))"
    (rowSelectedEvent)="onRowSelected($event)"></wt-symbols-grid>
</div>