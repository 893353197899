/**
 * Dashboard
 * Service that persists dashboard configurations from different applications. The content of the dashboards can also be stored. An user/group/admin permissioning helps to manage dashboards on different access levels.
 *
 * OpenAPI spec version: 1.1.0
 * Contact: swt-backend@vwd365.onmicrosoft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DashboardShare } from '../model/dashboardShare';
import { DashboardSharePost } from '../model/dashboardSharePost';
import { DashboardSharePut } from '../model/dashboardSharePut';
import { DashboardShares } from '../model/dashboardShares';
import { ModelDelete } from '../model/modelDelete';
import { Post } from '../model/post';
import { ProblemResponse } from '../model/problemResponse';
import { Put } from '../model/put';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class DashboardSharesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * get all dashboard_shares for one dashboard
     * Returns one or more dashboard_shares shared from the requesting user or shared for the requesting user
     * @param dashboard_id the dashboard_id
     * @param is_accepted the flag is_accepted
     * @param fields 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardSharesGet(dashboard_id?: string, is_accepted?: boolean, fields?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<DashboardShares>;
    public dashboardSharesGet(dashboard_id?: string, is_accepted?: boolean, fields?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DashboardShares>>;
    public dashboardSharesGet(dashboard_id?: string, is_accepted?: boolean, fields?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DashboardShares>>;
    public dashboardSharesGet(dashboard_id?: string, is_accepted?: boolean, fields?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dashboard_id !== undefined && dashboard_id !== null) {
            queryParameters = queryParameters.set('dashboard_id', <any>dashboard_id);
        }
        if (is_accepted !== undefined && is_accepted !== null) {
            queryParameters = queryParameters.set('is_accepted', <any>is_accepted);
        }
        if (fields) {
            fields.forEach((element) => {
                queryParameters = queryParameters.append('fields', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DashboardShares>('get',`${this.basePath}/dashboard-shares`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete a dashboard_share
     * deletes a dashboard_share. This can be done by the owner of a dashboard and the recipient of the share.
     * @param id the id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardSharesIdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<ModelDelete>;
    public dashboardSharesIdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelDelete>>;
    public dashboardSharesIdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelDelete>>;
    public dashboardSharesIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dashboardSharesIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ModelDelete>('delete',`${this.basePath}/dashboard-shares/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get dashboard_share by id
     * 
     * @param id the id
     * @param fields 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardSharesIdGet(id: string, fields?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<DashboardShare>;
    public dashboardSharesIdGet(id: string, fields?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DashboardShare>>;
    public dashboardSharesIdGet(id: string, fields?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DashboardShare>>;
    public dashboardSharesIdGet(id: string, fields?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dashboardSharesIdGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fields) {
            fields.forEach((element) => {
                queryParameters = queryParameters.append('fields', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DashboardShare>('get',`${this.basePath}/dashboard-shares/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update a dashboard_share
     * updates a dashboard_share. This can be done by the owner of a dashboard and the recipient of the share.
     * @param body dashboard that needs to be updated
     * @param id the id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardSharesIdPut(body: DashboardSharePut, id: string, observe?: 'body', reportProgress?: boolean): Observable<Put>;
    public dashboardSharesIdPut(body: DashboardSharePut, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Put>>;
    public dashboardSharesIdPut(body: DashboardSharePut, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Put>>;
    public dashboardSharesIdPut(body: DashboardSharePut, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling dashboardSharesIdPut.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dashboardSharesIdPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Put>('put',`${this.basePath}/dashboard-shares/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add a new dashboard_share
     * stores a new dashboard_share. This can only be done by the owner of a dashboard.
     * @param body dashboard_share-object that needs to be added
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardSharesPost(body: DashboardSharePost, observe?: 'body', reportProgress?: boolean): Observable<Post>;
    public dashboardSharesPost(body: DashboardSharePost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Post>>;
    public dashboardSharesPost(body: DashboardSharePost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Post>>;
    public dashboardSharesPost(body: DashboardSharePost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling dashboardSharesPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Post>('post',`${this.basePath}/dashboard-shares`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
