export type Comparer<T> = (x: T, y: T) => boolean;

/**
 * Compare two values for primitive equality (basically === with NaN handling)
 */
export function valuesAreEqual<T>(x: T, y: T): boolean {
  if (x === y) {
    return true;
  } else if (typeof x === 'number' && typeof y === 'number') { // special case for numbers
    return isNaN(x) && isNaN(y);
  } else {
    return false;
  }
}

/**
 * Compare two JSON-like values for equality
 */
export function structuresAreEqual<T>(x: T, y: T): boolean {
  if (x === y) {
    return true;
  } else if (x == null || y == null || typeof x !== typeof y) {
    return false;
  } else if (typeof x === 'object') {
    // array
    if (Array.isArray(x)) {
      return Array.isArray(y) && arraysAreEqual(x, y, structuresAreEqual);
    } else if (Array.isArray(y)) {
      return false;
    } else {
      return objectsAreEqual(x, y as any, structuresAreEqual);
    }
  } else if (typeof x === 'number') {
    return isNaN(x) && isNaN(y as any);
  }
  return false;
}

/**
 * Compares arrays for structural equality; by default the comparison is one level deep,
 * but you can specify `structuresAreEqual` to extend this.
 */
export function arraysAreEqual<T>(x: ReadonlyArray<T>, y: ReadonlyArray<T>, elementCompare: Comparer<T> = valuesAreEqual): boolean {
  if (x === y) {
    return true;
  } else if (!x || !y || x.length !== y.length) {
    return false;
  }

  for (let i = 0; i < x.length; i++) {
    if (!elementCompare(x[i], y[i])) {
      return false;
    }
  }

  return true;
}

/**
 * Compares iterators for structural equality; by default the comparison is one level deep,
 * but you can specify `structuresAreEqual` to extend this.
 */
export function iteratorsAreEqual<T>(x: Iterator<T>, y: Iterator<T>, elementCompare: Comparer<T> = valuesAreEqual): boolean {
  if (x === y) {
    return true;
  } else if (!x || !y) {
    return false;
  }

  while (true) {
    const xe = x.next();
    const ye = y.next();
    if (xe.done) {
      return !!ye.done;
    } else if (ye.done) {
      return false;
    } else if (!elementCompare(xe.value, ye.value)) {
      return false;
    }
  }
}

/**
 * Compares iterables for structural equality; by default the comparison is one level deep,
 * but you can specify `structuresAreEqual` to extend this.
 */
export function iterablesAreEqual<T>(x: Iterable<T>, y: Iterable<T>, elementCompare: Comparer<T> = valuesAreEqual): boolean {
  if (x === y) {
    return true;
  } else if (!x || !y) {
    return false;
  } else {
    return iteratorsAreEqual(x[Symbol.iterator](), y[Symbol.iterator](), elementCompare);
  }
}

export interface IterableKeys<K> {
  keys(): Iterable<K>;
}

/**
 * Compares two keyed collections to check whether their `keys()` are equal (in order).
 */
export function keysAreEqual<K>(x: IterableKeys<K>, y: IterableKeys<K>): boolean {
  if (x === y) {
    return true;
  } else if (!x || !y) {
    return false;
  } else {
    return iterablesAreEqual(x.keys(), y.keys());
  }
}

/**
 * Compares object literals for equality; by default the comparison is one level deep,
 * but you can specify `structuresAreEqual` to extend this.
 *
 * Special cases `Date`.
 */
export function objectsAreEqual<T extends object>(x: T, y: T, elementCompare: Comparer<any> = valuesAreEqual): boolean {
  if (x === y) {
    return true;
  } else if (!x || !y) {
    return false;
  }

  const protoX = Object.getPrototypeOf(x);

  // Check we're looking at the same type
  if (protoX !== Object.getPrototypeOf(y)) {
    return false;
  }

  // Special case for Date
  if (x instanceof Date) {
    return y instanceof Date && x.valueOf() === y.valueOf();
  } else if (y instanceof Date) {
    return false;
  }

  // Except for Date, only object literals
  if (protoX !== Object.prototype) {
    return false;
  }

  // Check props in x are in y and also equal
  for (const key in x) {
    if (x.hasOwnProperty(key)) {
      if (!y.hasOwnProperty(key) || !elementCompare(x[key], y[key])) {
        return false;
      }
    }
  }

  // check all props in y are in x (if so, they are already equal)
  for (const key in y) {
    if (y.hasOwnProperty(key)) {
      if (!x.hasOwnProperty(key)) {
        return false;
      }
    }
  }

  // no property mismatch, so OK
  return true;
}
