<div class="wt-dialog">
  <div class="wt-flex-row wt-flex-align-middle wt-flex-collapse wt-dialog__title">
    <div class="wt-flex-column wt-flex-shrink wt-dialog-icon">
      <mat-icon fontIcon="delete_outlined"></mat-icon>
    </div>

    <div class="wt-flex-column">
      <h1 class="wt-h1">{{ title }}</h1>
    </div>
  </div>

  <div mat-dialog-content>
    <p>{{ message }}</p>
  </div>

  <div class="wt-action-buttons">
    <div class="wt-flex-row wt-flex-collapse wt-flex-align-right">
      <div class="wt-flex-column wt-flex-shrink">
        <button class="wt-button wt-button--cancel" type="button" (click)="onDismiss($event)">No</button>
      </div>

      <div class="wt-flex-column wt-flex-shrink">
        <button class="wt-button wt-button--primary" type="button" (click)="onConfirm($event)">Yes</button>
      </div>
    </div>
  </div>
</div>