import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { FromPeriod, Period, PeriodDateKeys, PeriodDateType, PeriodDates, StoreablePeriod, ToPeriod, resolvePeriod } from '../shared/period/period.model';

@Injectable({
  providedIn: 'root'
})
export class PeriodService {

  static getPeriodDates(period: Period): PeriodDates | undefined {
    let from: undefined | Date | FromPeriod;
    let to: undefined | Date | ToPeriod;

    if (PeriodDateType.FROM in period) {
      from = period[PeriodDateType.FROM];
    }
    if (PeriodDateType.TO in period) {
      to = period[PeriodDateType.TO];
    }

    if (from instanceof Date) {
      if (to instanceof Date) {
        return { from, to };
      } else if (!to) {
        return { from, to: new Date() };
      }

      let resolvedTo: Date = to.startByFrom ? new Date(from.getTime()) : new Date();
      resolvedTo = resolvePeriod(resolvedTo, to, PeriodDateType.TO);
      return { from, to: resolvedTo };
    } else if (to instanceof Date) {
      if (from instanceof Date) {
        return { from, to };
      } else if (!from) {
        return { from: new Date(), to };
      }

      let resolvedFrom: Date = from.startByTo ? new Date(to.getTime()) : new Date();
      resolvedFrom = resolvePeriod(resolvedFrom, from, PeriodDateType.TO);
      return { from: resolvedFrom, to };
    } else if (from) {
      if (!to) {
        let resolvedFrom: Date = new Date();
        resolvedFrom = resolvePeriod(resolvedFrom, from, PeriodDateType.FROM);
        return { from: resolvedFrom, to: new Date() };
      }
      // resolve both to & from
      if (from.startByTo && to.startByFrom) {
        return undefined;
      }

      if (from.startByTo) {
        let resolvedTo: Date = new Date();
        resolvedTo = resolvePeriod(resolvedTo, to, PeriodDateType.TO);

        let resolvedFrom: Date = new Date(resolvedTo.getTime());
        resolvedFrom = resolvePeriod(resolvedFrom, from, PeriodDateType.FROM);
        return { from: resolvedFrom, to: resolvedTo };
      } else if (to.startByFrom) {
        let resolvedFrom: Date = new Date();
        resolvedFrom = resolvePeriod(resolvedFrom, from, PeriodDateType.FROM);

        let resolvedTo: Date = new Date(resolvedFrom.getTime());
        resolvedTo = resolvePeriod(resolvedTo, to, PeriodDateType.TO);
        return { from: resolvedFrom, to: resolvedTo };
      } else {
        // no dependency between the two
        const resolvedFrom = resolvePeriod(new Date(), from, PeriodDateType.FROM);
        const resolvedTo = resolvePeriod(new Date(), to, PeriodDateType.TO);
        return { from: resolvedFrom, to: resolvedTo };
      }
    } else if (to) {
      let resolvedTo: Date = new Date();
      resolvedTo = resolvePeriod(resolvedTo, to, PeriodDateType.TO);
      return { from: new Date(), to: resolvedTo };
    }

    return undefined;
  }

  static getPeriodDates$(period: Period): Observable<PeriodDates | undefined> {
    return of(PeriodService.getPeriodDates(period));
  }

  static getStoreablePeriod(period: Period): StoreablePeriod {
    return Object.entries(period).reduce<StoreablePeriod>((storeablePeriod, [key, value]) => {
      storeablePeriod[key] = value instanceof Date ? value.getTime() : value;
      return storeablePeriod;
    }, {} as StoreablePeriod);
  }

  static getPeriodFromStorage(storeablePeriod: StoreablePeriod): Period {
    return Object.entries(storeablePeriod).reduce<Period>((period, [key, value]) => {
      period[key] = (PeriodDateKeys as string[]).includes(key) && typeof value === 'number' ? new Date(value) : value;
      return period;
    }, {} as Period);
  }

}
