import { Injectable, isDevMode, NgZone, OnDestroy } from '@angular/core';
import { SubTenants, TenantsService } from '@infront/ngx-keycloak-idm-api';
import { MFEAuth } from '@vwd/microfrontend-core';
import { Logger, LogService } from '@vwd/ngx-logging';
import { BehaviorSubject, combineLatest, defer, iif, NEVER, Observable, of, Subject, throwError, timer } from 'rxjs';
import {
  auditTime,
  catchError,
  distinctUntilChanged,
  map,
  retry,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { DashboardEntity, DashboardPost, DashboardPut, DashboardMetas, DashboardMetasService, DashboardsService } from '../../internal/api/dashboards';
import { arraysAreEqual, keysAreEqual, structuresAreEqual } from '../../internal/utils/equality';
import { deepFreeze, isEmptyObject } from '../../internal/utils/objects';
import { fromSubscribable, runInZone } from '../../internal/utils/observables';
import {
  DashboardAttributes,
  DashboardFolderLevel,
  DashboardFolderModel,
  DashboardLinkCreate,
  DashboardModel,
  DashboardModelCreate,
  DashboardModelUpdate,
  DashboardType,
  isDashboardFolder,
  isDashboardNode
} from '../../models';
import { KnownDashboardFolderIDs } from '../constants';
import { DashboardGetChildrenContext, DashboardProvider } from '../dashboard.provider';
import { DashboardFolderLoader } from './folder-loader';
import { HttpDashboardConfigurationProvider } from './http-dashboard.configuration';
import { assertHttpDashboardFolderModel, assertHttpDashboardModel, FolderType, HttpDashboardFolderModel, isHttpDashboardModel } from './http-dashboard.models';
import { createStoreAttributes, storeToModel } from './store.models';

import type { HttpDashboardModel, HttpDashboardNodeModel } from './http-dashboard.models';

const EMPTY_ENTITLEMENTS: DashboardMetas = {
  dashboard_meta: {
    USER: {
      can_add_children: 'same-user'
    },
  }
};

interface SubTenantInfo {
  readonly displayName: string;
  readonly realm: string;
  readonly subTenantId: string;
}

interface FolderToLoad {
  readonly role: FolderType;
  readonly realm?: string;
  readonly subTenantId?: string;
  readonly userId?: string;
  readonly displayName: string;
}

@Injectable({ providedIn: 'root' })
export class HttpDashboardProvider extends DashboardProvider implements OnDestroy {
  private readonly modelCache = new Map<string, HttpDashboardModel>();
  private readonly cacheUpdated = new BehaviorSubject<void>(undefined);
  private readonly logger: Logger;
  private readonly ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly auth: MFEAuth,
    private readonly api: DashboardsService,
    private readonly metaApi: DashboardMetasService,
    private readonly configurationProvider: HttpDashboardConfigurationProvider,
    private readonly tenantsService: TenantsService,
    private readonly logService: LogService,
    private readonly ngZone: NgZone
  ) {
    super();
    this.logger = logService.openLogger('dashboards/services/http/dashboards');
  }

  private configuration$ = this.configurationProvider.load().pipe(shareReplay(1));
  private entitlement$ = this.configuration$.pipe(
    switchMap(configuration =>
      // If we only want the USER folder, there should be no need
      // for checking entitlements, is there?
      // TODO: check with Customer Account (non-advisor users)
      iif(
        () => configuration.availableFolders.some(f => f !== 'USER'),
        defer(() =>
          this.metaApi.dashboardMetasGet('body').pipe(
            tap({
              error: (error) => this.logger.fail('Error retrieving dashboard folder entitlement.', error),
            }),
            retry(1),
            // return empty entitlements when loading fails
            catchError(error => of(EMPTY_ENTITLEMENTS)),
          )
        ),
        of(EMPTY_ENTITLEMENTS)
      )
    )
  );

  private subTenantsCache = new Map<string, SubTenantInfo[]>;

  private getSubTenantsList$(realm: string, userRealm: string, userSubTenantId: string | undefined): Observable<SubTenantInfo[]> {
    return this.tenantsService.tenantsTenantSubTenantsGet(realm).pipe(
      map((result: SubTenants) => {
        const list = result?.subTenants ? result.subTenants.map(itm => ({ displayName: itm.displayName ?? `Subtenant ${itm.name}`, realm, subTenantId: itm.name ?? '' })) : [];
        if (realm === userRealm && userSubTenantId && !list.some((item) => item.subTenantId === userSubTenantId)) {
          list.unshift({ displayName: `Subtenant ${userSubTenantId}`, realm, subTenantId: userSubTenantId });
        }
        return list;
      }),
      tap((data) => {
        this.subTenantsCache.set(realm, data);
        this.logger.info(`Loaded subtenants for realm "${realm}".`, data);
      }),
      catchError((error) => {
        this.logger.error(`Cannot load subtenants for realm "${realm}".`, error);
        return of(this.subTenantsCache.get(realm) ?? []);
      }),
      shareReplay(1),
    )
  }

  private dashboardLoaders$ = combineLatest([this.configuration$, this.entitlement$, of(new Map<string, DashboardFolderLoader>())]).pipe(
    tap(([configuration, entitlement, loadersRegistry]) => this.logger.debug('Dashboard loaders configuration update.', { configuration, entitlement, loadersRegistry })),
    switchMap(([configuration, entitlement, loadersRegistry]) => {
      const [userRealm, userSubTenantId, userId] = [this.auth.realm!, this.auth.token!.subTenant, this.auth.subject!];
      const realmsToLoad = [userRealm, ...configuration.additionalRealms.filter((realm) => realm !== userRealm)];

      if (configuration.availableFolders.includes('SUBTENANT')) {
        // First, filter out realms for which we do not want to load subtenants
        const subTenantRealms = realmsToLoad.filter((realm) => configuration.isSubTenantFolderEnabled({ role: 'COMPANY', realm }));
        if (subTenantRealms.length) {
          // collect all subTenants we want to load
          return combineLatest(realmsToLoad.map((realm) => this.getSubTenantsList$(realm, userRealm, userSubTenantId)))
            .pipe(
              map((realmSubTenants) => ({
                configuration,
                entitlement,
                loadersRegistry,
                realmsToLoad,
                subTenantsToLoad: realmSubTenants.flat().filter((subTenant) => configuration.isSubTenantFolderEnabled({
                  role: 'SUBTENANT',
                  realm: subTenant.realm,
                  subTenantId: subTenant.subTenantId,
                  subTenantName: subTenant.displayName,
                })),
                userRealm, userSubTenantId, userId
              })),
            );
        }
      }

      return of({
        configuration, entitlement,
        loadersRegistry,
        realmsToLoad,
        subTenantsToLoad: [] as SubTenantInfo[],
        userRealm, userSubTenantId, userId,
      });
    }),
    tap((data) => this.logger.debug('Dashboard loaders data ready.', data)),
    map(({ configuration, entitlement, loadersRegistry, realmsToLoad, subTenantsToLoad, userRealm, userSubTenantId, userId }) => {
      const nodesToLoad: FolderToLoad[] = [];

      for (const role of configuration.availableFolders) {
        // for COMPANY, we need to
        if (role === 'COMPANY') {
          for (const realm of realmsToLoad) {
            nodesToLoad.push({ role, realm, subTenantId: undefined, userId: undefined, displayName: realm });
          }
        } else if (role === 'SUBTENANT') {
          for (const subTenant of subTenantsToLoad) {
            nodesToLoad.push({ role, realm: subTenant.realm, subTenantId: subTenant.subTenantId, userId: undefined, displayName: subTenant.displayName });
          }
        } else if (role === 'GLOBAL') {
          nodesToLoad.push({ role, realm: undefined, subTenantId: undefined, userId, displayName: role });
        } else {
          nodesToLoad.push({ role, realm: userRealm, subTenantId: undefined, userId, displayName: role });
        }
      }

      return { configuration, entitlement, loadersRegistry, nodesToLoad, userRealm, userSubTenantId, userId };
    }),
    tap((data) => this.logger.debug('Dashboard loaders requested', data.nodesToLoad)),
    map(({ configuration, entitlement, loadersRegistry, nodesToLoad, userRealm, userSubTenantId, userId }) => {
      const activeLoaders = new Map<string, DashboardFolderLoader>();
      const currentUserId = userId;

      for (const { role, realm, subTenantId, userId, displayName } of nodesToLoad) {
        const loaderId = DashboardFolderLoader.getLoaderId(role, realm, subTenantId, userId);
        let loader = loadersRegistry.get(loaderId);

        if (!loader) {
          loader = new DashboardFolderLoader(
            this.logService,
            this.api,
            role,
            realm,
            subTenantId,
            userId,
            canAddChildren(entitlement, role, { realm, subTenantId }, { realm: userRealm, subTenantId: userSubTenantId, userId: currentUserId }),
            /* shouldLoadChildren - only own subtenant/realm loaded by default */
            role === 'COMPANY' && realm === userRealm ||
            role === 'SUBTENANT' && realm === userRealm && subTenantId === userSubTenantId ||
            role !== 'COMPANY' && role !== 'SUBTENANT',
            /* displayName */ role === 'SUBTENANT' ? of(displayName) : configuration.getDisplayName({ role, realm } as any),
            /* isOwnRealm */(role === 'COMPANY' || role === 'SUBTENANT') && realm === userRealm,
            /* isOwnSubTenant */ role === 'SUBTENANT' && realm === userRealm && subTenantId === userSubTenantId
          );
          loadersRegistry.set(loaderId, loader);
          this.logger.log(`Dashboard loaders ${loader.id} created.`);
        }

        // Add to list of active loaders
        activeLoaders.set(loaderId, loader);
      }

      // clean up unsubscribed loaders
      for (const [loaderId, loader] of loadersRegistry.entries()) {
        if (!activeLoaders.has(loaderId)) {
          loadersRegistry.delete(loaderId);
          loader.destroy();
          this.logger.log(`Dashboard loaders ${loader.id} destroyed.`);
        }
      }

      return activeLoaders;
    }),
    distinctUntilChanged(keysAreEqual),
    shareReplay(1),
  );

  public getDashboards(): Observable<readonly HttpDashboardModel[]> {
    return this.cacheUpdated.pipe(
      map(() => Array.from(this.modelCache.values())),
      takeUntil(this.ngUnsubscribe),
      switchMap((dashboards) => {
        if (dashboards?.length) {
          this.logger.log('getDashboards(): return existing observable.');
          return of(dashboards);
        }
        if (this.ngUnsubscribe.closed) {
          this.logger.warn('getDashboards(): called after destroy.');
          return of([]);
        }
        return fromSubscribable<boolean>(this.auth.authenticated$).pipe(
          switchMap((authenticated) => {
            if (!authenticated) {
              this.logger.log('getDashboards(): not authenticated.');
              return of([]);
            }
            this.logger.log('getDashboards(): authenticated.');
            return this.loadDashboards$;
          })
        );
      })
    );
  }

  private loadDashboards$ = this.dashboardLoaders$.pipe(
    withLatestFrom(this.configuration$),
    switchMap(([loaders, configuration]) =>
      timer(0, configuration.dashboardRefreshInterval).pipe(
        switchMap((time) => {
          if (time) {
            for (const [, loader] of loaders) {
              loader.refresh();
            }
          }
          return of(loaders);
        }),
        switchMap((loaders) => {
          this.logger.debug('Combining all folders.', loaders);
          if (loaders.size) {
            return combineLatest(Array.from(loaders.values()).map((loader) => loader.models$));
          }
          return of([]);
        }),
        auditTime(10),
        distinctUntilChanged((x, y) => arraysAreEqual(x, y, structuresAreEqual)),
        map((arrayOfArrayOfModels) => arrayOfArrayOfModels.flat()), // Is this necessary? Or should the provider contract state that the `getDashboards()` method should never fail?
        catchError((err) => {
          this.logger.critical('Load failed.', err);
          return of([] as HttpDashboardModel[]);
        }),
        runInZone(this.ngZone),
        switchMap((all) => this.synchedCache(all))
      )
    )
  );

  private synchedCache(models: HttpDashboardModel[]): Observable<HttpDashboardModel[]> {
    this.logger.debug('Syncing cache', models);
    // FIXME: how to handle deletions/updates/deletes that overlapped with load operations?
    const idsSeen = new Set<string>();
    const idsChanged = isDevMode() ? new Set<string>() : undefined;
    let hasChanges = false;

    for (const model of models) {
      idsSeen.add(model.id);
      const existing = this.modelCache.get(model.id);
      if (!existing || !structuresAreEqual(existing, model)) {
        hasChanges = true;
        this.modelCache.set(model.id, model);
        idsChanged?.add(model.id);
        deepFreeze(model);
      }
    }

    for (const id of this.modelCache.keys()) {
      if (!idsSeen.has(id)) {
        hasChanges = true;
        this.modelCache.delete(id);
      }
    }
    if (!hasChanges) {
      this.logger.debug('Cache sync detected no changes.');
      return NEVER;
    }
    this.logger.debug(
      'Cache sync detected changes.',
      idsChanged ? { idsChanged, dashboards: Array.from(idsChanged.values()).map((id) => this.modelCache.get(id)) } : undefined
    );
    return of(Array.from(this.modelCache.values()));
  }


  public create(parent: DashboardFolderModel, createData: DashboardModelCreate): Observable<HttpDashboardModel> {
    return this.createImpl(parent, createData, undefined);
  }

  private createImpl(parent: DashboardFolderModel, createData: DashboardModelCreate, extraAttributes: DashboardAttributes | undefined): Observable<HttpDashboardModel> {
    assertHttpDashboardFolderModel(parent);

    if (parent.meta.role === 'SHARED') {
      throw new TypeError('Parent folder cannot be the SHARED folder.');
    }
    if (!parent.owner) {
      throw new TypeError('Parent folder is missing owner information.');
    }

    const postData = this.createDashboardPost(parent, createData, extraAttributes);

    const result = this.api.dashboardsPost(postData, 'body').pipe(
      tap({
        error: (error) => this.logger.fail(`Error creating dashboard ${createData.name}`, error),
      }),
      retry(1),
      map((response) => {
        this.logger.success(`Dashboard ${createData.name} has been created.`);

        const newStoreData: DashboardEntity = {
          id: response.id,
          name: postData.name,
          type: postData.type!,
          parent_id: postData.parent_id!,
          attributes: postData.attributes ?? '{}',
          is_shared: false,
          is_shared_for_me: false,
          is_data_draft: true,
          data_id: null!,
          can_add_children: createData.type === DashboardType.FOLDER,
          can_delete: true,
          can_edit: true,
          can_move: true,
          can_share: true,
          role: postData.role!,
          realm: postData.realm!,
          sub_tenant_id: postData.sub_tenant_id!,
          user_id: this.auth.subject!,
          data_create_timestamp: response.request_timestamp ?? new Date(),
        };

        const model = storeToModel(postData.role!, newStoreData, this.logger);

        this.modelCache.set(model.id, model);

        this.getLoader(model).pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe(loader => loader?.created(model));

        this.cacheUpdated.next();

        return model;
      }),
      // TODO: handle & normalize errors
      shareReplay(1)
    );

    result.subscribe();

    return result;
  }

  private createDashboardPost(parent: HttpDashboardFolderModel, changes: DashboardModelCreate, extraAttributes: DashboardAttributes | undefined): DashboardPost {
    return {
      id: changes.id,
      name: changes.name,
      type:
        changes.type === DashboardType.FOLDER ? 'FOLDER' :
          changes.type === DashboardType.TEMPLATE ? 'TEMPLATE' : // NOSONAR nested ternary accepted
            'NODE',
      parent_id: parent.meta.virtual ? null! : parent.id,
      attributes: createStoreAttributes(null, changes, extraAttributes),
      role: parent.meta.role === 'SHARED' ? null! : parent.meta.role,
      realm: parent.owner.realm,
      sub_tenant_id: parent.meta.role === 'SUBTENANT' ? parent.owner.subTenantId ?? null! : null!,
    };
  }

  public override createLink(parent: DashboardFolderModel, data: DashboardLinkCreate): Observable<DashboardModel> {
    const target = data.target.model;
    if (!this.canLinkTo(parent, target)) {
      throw new TypeError('Cannot create link: parent and target are not compatible.');
    }

    assertHttpDashboardModel(target);

    const _link = target.meta.linksTo ?? target.id;

    return this.createImpl(parent, data, { _link });
  }

  public override canLinkTo(parent: DashboardFolderModel, target: DashboardModel): boolean {
    if (!isHttpDashboardModel(parent)) {
      return false;
    }
    if (!isHttpDashboardModel(target)) {
      return false;
    }
    if (isDashboardFolder(target)) {
      return false;
    }
    if (target.level === DashboardFolderLevel.SHARED || parent.level === DashboardFolderLevel.SHARED) {
      return false;
    }
    if (parent.level > target.level) {
      return false;
    }
    // GLOBAL dashboards can be added to any folder;
    // the PERSONAL folder can contain a link to any level.
    // But COMPANY and SUBTENANT dashboards can only contain
    // GLOBAL or same security scoped dashboards.
    if (target.level !== DashboardFolderLevel.GLOBAL
      && parent.level !== DashboardFolderLevel.PERSONAL) {
      if (target.owner.realm !== parent.owner.realm) {
        return false;
      }
      if (target.level === DashboardFolderLevel.SUBTENANT
        && target.owner.subTenantId !== parent.owner.subTenantId) {
        return false;
      }
    }

    if (!parent.security.canAddChildren) {
      return false;
    }

    return true;
  }

  public update(id: string, changes: DashboardModelUpdate): Observable<HttpDashboardModel> {
    this.logger.debug(`Updating dashboard ${id}.`, changes);
    const dashboard = this.modelCache.get(id);

    if (!dashboard) {
      return throwError(() => new Error(`Cannot find dashboard ${id} in internal cache.`));
    }
    if (dashboard.meta.role === 'SHARED') {
      throw new TypeError('Parent folder cannot be the SHARED folder.');
    }

    const actualChanges = this.createDashboardPut(dashboard, changes);

    if (isEmptyObject(actualChanges)) {
      return of(dashboard);
    }

    const result = this.api.dashboardsIdPut(actualChanges, id, 'body').pipe(
      tap({
        error: (error) => this.logger.fail(`Error updating dashboard ${id}`, error),
      }),
      retry(1),
      map(() => {
        this.logger.success(`Dashboard ${id} has been updated.`);

        const oldModel = this.modelCache.get(id);
        if (!oldModel) {
          this.logger.warn(`Cannot find dashboard ${id} in internal cache anymore. Has it been deleted?`);
        }

        const newStoreData: DashboardEntity = {
          ...(oldModel?.meta.raw ?? dashboard.meta.raw!),
          ...actualChanges,
        };

        const model = storeToModel(newStoreData.role, newStoreData, this.logger);

        this.modelCache.set(model.id, model);

        this.getLoader(model).pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe(loader => loader?.updated(model));

        this.cacheUpdated.next();

        return model;
      }),
      // TODO: handle & normalize errors
      shareReplay(1)
    );

    result.subscribe();

    return result;
  }

  private createDashboardPut(dashboard: HttpDashboardModel, changes: DashboardModelUpdate): DashboardPut {
    const actualChanges: DashboardPut = {};

    const attributes = createStoreAttributes(dashboard, changes, undefined);

    if (attributes) {
      actualChanges.attributes = attributes;
    }

    if ('name' in changes) {
      if (!changes.name) {
        throw new Error('Name cannot be empty.');
      }
      actualChanges.name = changes.name;
    }

    if ('parentId' in changes) {
      if (changes.parentId != null) {
        const parent = this.modelCache.get(changes.parentId);
        if (!parent) {
          throw new Error(`Unknown parent ID ${changes.parentId}`);
        }
        actualChanges.parent_id = parent.meta.virtual ? null! : parent.id;
      } else {
        actualChanges.parent_id = null!;
      }
    }
    return actualChanges;
  }

  public delete(id: string): Observable<void> {
    this.logger.debug(`Deleting dashboard ${id}.`);

    const cached = this.modelCache.get(id);

    const result = this.api.dashboardsIdDelete(id, /* recursive */ isDashboardFolder(cached) ? false : undefined).pipe(
      tap({
        error: (error) => this.logger.fail(`Error deleting dashboard ${id}`, error),
      }),
      retry(1),
      tap({
        next: () => this.logger.success(`Dashboard ${id} has been deleted.`),
      }),
      // TODO: handle & normalize errors
      shareReplay(1)
    );

    result.subscribe(() => {
      this.modelCache.delete(id);

      if (cached) {
        this.getLoader(cached).pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe(loader => loader?.deleted(cached));
      }

      this.cacheUpdated.next();
    });

    return new Observable<void>((subscribe) => {
      return result.subscribe({
        next: (_) => subscribe.next(),
        error: (error) => subscribe.error(error),
        complete: () => subscribe.complete(),
      });
    });
  }

  public override refresh(): void {
    this.dashboardLoaders$.pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe(loaders => loaders.forEach(loader => loader.refresh()));

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public override canMoveTo(node: DashboardModel, target: DashboardFolderModel): boolean {
    if (!isHttpDashboardModel(node) || !isHttpDashboardModel(target)) {
      return false;
    }

    if (!node.security.canMove
      || !node.owner
      || isDashboardNode(node) && node.sharing.isSharedWithMe
      // TODO: `target` cannot be SHARED_DASHBOARDS_FOLDER_ID -- should be done in RestDashboardProvider?
      //       On the other hand, `canAddChildren` will be `false` for this folder, as will `canMove` for
      //       the shared dashboard entries, so we're probably fine.
      || !target.security.canAddChildren
      || !target.owner
      || target.id === KnownDashboardFolderIDs.ROOT
    ) {
      return false;
    }

    return node.owner.realm === target.owner.realm
      && node.owner.subTenantId === target.owner.subTenantId
      && node.meta.role === target.meta.role
      && node.level === target.level;
  }

  // FIXME: we should probably not make getChildren this implicit, as we can have *many* subtenants
  public override getChildren(context: DashboardGetChildrenContext): Observable<readonly DashboardModel[]> {
    this.logger.log('getChildren()', context);
    if (isHttpDashboardModel(context.node) && context.node.childrenLoadState === 'on-demand') {
      this.getLoader(context.node).pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe(loader => loader?.loadChildren());
    }
    return super.getChildren(context);
  }

  public notifyNotDraft(dashboard: HttpDashboardNodeModel): void {
    this.logger.log('notifyNotDraft()', dashboard);
    // Defensively check the currently cached model (in case we get a stale model), and update its draft status
    const existing = this.modelCache.get(dashboard.id) as HttpDashboardNodeModel | undefined;
    if (existing) {
      const copy: HttpDashboardNodeModel = { ...existing, isDataDraft: false, dataCreatedAt: new Date() };

      this.modelCache.set(copy.id, copy);
      this.getLoader(copy).pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe(loader => loader?.updated(copy));

      this.cacheUpdated.next();
    }
  }

  private getLoader(dashboard: HttpDashboardModel): Observable<DashboardFolderLoader | undefined> {
    return this.dashboardLoaders$.pipe(map((loaders) => loaders.get(DashboardFolderLoader.getLoaderId(dashboard.meta.role, dashboard.owner.realm, dashboard.owner.subTenantId, dashboard.owner.userId))));
  }
}

function canAddChildren(
  entitlement: DashboardMetas,
  role: FolderType,
  folder: { realm?: string; subTenantId?: string; },
  user: { realm: string; subTenantId?: string; userId?: string; }
): boolean {
  if (role === 'SHARED') {
    return false;
  }

  const can_add_children = entitlement?.dashboard_meta?.[role]?.can_add_children;

  switch (can_add_children) {
    case 'any-realm':
      return true;
    case 'same-realm':
      return folder.realm === user.realm;
    case 'same-subtenant':
      return folder.realm === user.realm && folder.subTenantId === user.subTenantId;
    case 'same-user':
      // This is a special case, where you can have rights to add to a folder, but
      // not delete other people's nodes. The individual nodes will tell you.
      return true;
    default:
      return false;
  }
}

