<ng-container *ngIf="symbolInfo$ | async as symbol">
  <div class="wt-symbol-status"
    [hidden]="hideSymbolStatus.includes(displayStatusMap[symbol.DisplaySymbolStatusCode])">
    <label class="wt-symbol-status__label" [ngClass]="symbolStatusLabelClass[symbol.DisplaySymbolStatusCode]"
      title="{{ symbol.PreSymbolStatusText }}">
      {{ ('SYMBOL_STATUS_COMPONENT.LABEL.' + symbol.DisplaySymbolStatus) | translate }}
    </label>
  </div>

  <label [hidden]="showAlternativeValue">{{ alternativeValue }}</label>
</ng-container>