import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SidebarService } from '../../../sidebar.service';
import { GridSettings } from '../../../../../shared/grid/columns.model';
import { GridService } from '../../../../../shared/grid/grid.service';
import { CellClickedEvent } from 'ag-grid-community';
import { GridHeightsPx } from '../../../../../wrappers/grid-wrappers/grid-wrappers.model';

@Component({
  selector: 'wt-global-tab',
  templateUrl: './global-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalTabComponent {
  private readonly sidebarService = inject(SidebarService);
  private readonly gridService = inject(GridService);

  gridSettings: GridSettings = { rowContextMenu: false, sizeColumnsToFit: false };

  grids$ = this.sidebarService.grids$;

  readonly GridHeightsPx = GridHeightsPx;

  onGridCellClick(event: CellClickedEvent): void {
    this.gridService.handleGridCellClick(event);
  }

}
