<!-- DASHBOARD -->
<div class="wt-general-settings-content__group">
  <div class="wt-general-settings-content__group-header">
    <div class="wt-flex-row">
      <div class="wt-flex-column">
        {{ 'GENERAL_SETTINGS_COMPONENT.DASHBOARD.TITLE' | translate }}
      </div>
    </div>
  </div>

  <div class="wt-flex-row">
    <div class="wt-flex-column">
      <div class="wt-general-settings-content__group-fields">
        <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle wt-general-settings-content__group-fields-item">
          <div class="wt-flex-column">
            <label class="wt-general-settings-content__group-label">
              {{ 'GENERAL_SETTINGS_COMPONENT.DASHBOARD.GRID_OPEN_INSTRUMENT_DASHBOARD' | translate }}
            </label>

            <label
              class="wt-general-settings-content__group-label wt-general-settings-content__group-label--description">
              {{ 'GENERAL_SETTINGS_COMPONENT.DASHBOARD.GRID_OPEN_INSTRUMENT_DASHBOARD_DESC' | translate }}
            </label>
          </div>

          <div class="wt-flex-column wt-flex-shrink">
            <mat-slide-toggle class="wt-general-settings-content__group-slider"
              [checked]="enableGridOpenInstrumentDashboard$ | async"
              (change)="changeBooleanSetting('enableGridOpenInstrumentDashboard', $event.checked)"></mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TRADING -->
<div class="wt-general-settings-content__group">
  <div class="wt-general-settings-content__group-header">
    <div class="wt-flex-row">
      <div class="wt-flex-column">{{ 'GENERAL_SETTINGS_COMPONENT.TRADING.TITLE' | translate }}</div>
    </div>
  </div>

  <div class="wt-flex-row">
    <div class="wt-flex-column">
      <div class="wt-general-settings-content__group-fields" *ngIf="orderEntrySettings$ | async as orderEntrySettings">
        <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle wt-general-settings-content__group-fields-item">
          <div class="wt-flex-column">
            <label class="wt-general-settings-content__group-label">
              {{ 'GENERAL_SETTINGS_COMPONENT.TRADING.CONFIRMATION' | translate }}
            </label>
          </div>

          <div class="wt-flex-column wt-flex-shrink">
            <mat-slide-toggle class="wt-general-settings-content__group-slider"
              [checked]="!!orderEntrySettings.confirmation"
              (change)="changeOrderEntrySetting('confirmation', $event.checked)"></mat-slide-toggle>
          </div>
        </div>

        <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle wt-general-settings-content__group-fields-item">
          <div class="wt-flex-column">
            <label class="wt-general-settings-content__group-label">
              {{ 'GENERAL_SETTINGS_COMPONENT.TRADING.AUTO_FILL_PRICE' | translate }}</label>
          </div>

          <div class="wt-flex-column wt-flex-shrink">
            <mat-slide-toggle class="wt-general-settings-content__group-slider"
              [checked]="!!orderEntrySettings.autoFillPrice"
              (change)="changeOrderEntrySetting('autoFillPrice', $event.checked)"></mat-slide-toggle>
          </div>
        </div>

        <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle wt-general-settings-content__group-fields-item">
          <div class="wt-flex-column">
            <label class="wt-general-settings-content__group-label">
              {{ 'GENERAL_SETTINGS_COMPONENT.TRADING.REMEMBER_VOLUME' | translate }}
            </label>
          </div>

          <div class="wt-flex-column wt-flex-shrink">
            <mat-slide-toggle class="wt-general-settings-content__group-slider"
              [checked]="!!orderEntrySettings.rememberVolume"
              (change)="changeOrderEntrySetting('rememberVolume', $event.checked)"></mat-slide-toggle>
          </div>
        </div>

        <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle wt-general-settings-content__group-fields-item">
          <div class="wt-flex-column">
            <label class="wt-general-settings-content__group-label">
              {{ 'GENERAL_SETTINGS_COMPONENT.TRADING.SIDEBAR_HIDE_INACTIVE_DELETED_ORDERS' | translate }}
            </label>

            <label
              class="wt-general-settings-content__group-label wt-general-settings-content__group-label--description">
              {{ 'GENERAL_SETTINGS_COMPONENT.TRADING.SIDEBAR_HIDE_INACTIVE_DELETED_ORDERS_DESC' | translate }}
            </label>
          </div>

          <div class="wt-flex-column wt-flex-shrink">
            <mat-slide-toggle class="wt-general-settings-content__group-slider"
              [checked]="tradingTabHideInactiveDeletedOrders$ | async"
              (change)="changeBooleanSetting('tradingTabHideInactiveDeletedOrders', $event.checked)"></mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- HIGHLIGHTING -->
  <div class="wt-general-settings-content__group">
    <div class="wt-general-settings-content__group-header">
      <div class="wt-flex-row">
        <div class="wt-flex-column">
          {{ 'GENERAL_SETTINGS_COMPONENT.HIGHLIGHTING.TITLE' | translate }}
        </div>
      </div>
    </div>

    <div class="wt-flex-row">
      <div class="wt-flex-column">
        <div class="wt-general-settings-content__group-fields">
          <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle wt-general-settings-content__group-fields-item">
            <div class="wt-flex-column">
              <label class="wt-general-settings-content__group-label">
                {{ 'GENERAL_SETTINGS_COMPONENT.HIGHLIGHTING.UP_DOWN_CHANGES' | translate }}
              </label>

              <label
                class="wt-general-settings-content__group-label wt-general-settings-content__group-label--description">
                {{ 'GENERAL_SETTINGS_COMPONENT.HIGHLIGHTING.UP_DOWN_CHANGES_DESC' | translate }}
              </label>
            </div>

            <div class="wt-flex-column wt-flex-shrink">
              <mat-slide-toggle class="wt-general-settings-content__group-slider"
                [checked]="highlightUpDownChanges$ | async"
                (change)="changeBooleanSetting('highlightUpDownChanges', $event.checked)"></mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>