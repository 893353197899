import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';

import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { AlertLogItemModule } from '../alerts-list/alert-log-item/alert-log-item.module';
import { TradeToastComponent } from './trade-toast.component';

@NgModule({
  declarations: [TradeToastComponent],
  imports: [CommonModule, MatIconModule, MatBadgeModule, AlertLogItemModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),],
  exports: [TradeToastComponent],
})
export class TradeToastModule { }
