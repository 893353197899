import { Injectable, inject } from '@angular/core';
import { Infront, InfrontSDK } from '@infront/sdk';
import { map, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { SdkRequestsService } from '../../../services/sdk-requests.service';
import { ToolkitService } from '../../../services/toolkit.service';
import { Widget } from '../../../state-model/widget.model';
import { Instrument } from '../../../state-model/window.model';
import { SymbolEntity } from '../../../util/sdk';

@Injectable({
  providedIn: 'root',
})
export class CalendarHeaderService {
  private readonly sdkRequestsService: SdkRequestsService = inject(SdkRequestsService);
  private readonly toolkitService: ToolkitService = inject(ToolkitService);

  calendarFeedCountries$ = this.toolkitService.availableFeeds$.pipe(
    map((feeds) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const feedValues = Object.values(feeds || {});
      const countryList: string[] = [];
      const distinctMap: { [country: string]: boolean; } = {};

      feedValues.forEach((feedItem: Infront.FeedInfo) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const country = Infront.CountryIdToCode(feedItem.country);
        if (country && !distinctMap[country]) {
          countryList.push(country);
          distinctMap[country] = true;
        }
      });

      return countryList;
    }),
    shareReplay(1)
  );

  symbolInfo$ = (symbolEntity: SymbolEntity | undefined) => {
    if (!symbolEntity) {
      return of(undefined);
    }
    // @TODO fixme type cast of symbolEntity
    return this.sdkRequestsService.snapshotSymbolData$({
      symbolEntity: symbolEntity as Instrument | Widget,
      fields: [InfrontSDK.SymbolField.FeedExchange, InfrontSDK.SymbolField.PreDisplayTicker, InfrontSDK.SymbolField.FeedIsHidden],
    }).pipe(
      map((symbolData) => ({
        feedExchange: symbolData.FeedExchange,
        preDisplayTicker: symbolData.PreDisplayTicker,
        feedIsHidden: symbolData.FeedIsHidden,
      })),
      shareReplay(1),
    );
  };
}
