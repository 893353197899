import { Observable } from 'rxjs';
import { assertDashboardFolder, assertDashboardItem, assertDashboardNode, assertDashboardTemplate, DashboardChildrenLoadState, DashboardCreate, DashboardFolderModel, DashboardItemModel, DashboardLinkCreate, DashboardModel, DashboardModelUpdate, DashboardNodeModel, DashboardTemplateModel, DashboardType, isDashboardFolder, isDashboardItem, isDashboardNode, isDashboardTemplate } from '../models';
import { DashboardServicesProvider } from '../services/dashboard-services.provider';

/**
 * An object to keep track of a dashboard instance. To access a dashboard's properties,
 * and relations (parent & children),
 *
 * This is the type returned by the {@link DashboardService}.
 *
 * Use the type guards {@link isDashboardFolderRef}, {@link isDashboardNodeRef}, and
 * {@link isDashboardTemplateRef} to gain access to specialized properties.
 */
export interface DashboardRef {
  readonly provider: DashboardServicesProvider;
  readonly model: DashboardModel;
  readonly parent: DashboardFolderRef | undefined;
  readonly displayName: string;

  readonly model$: Observable<DashboardModel>;
  readonly parent$: Observable<DashboardFolderRef | undefined>;
  readonly displayName$: Observable<string>;

  readonly isLink: boolean;
  readonly link: DashboardRef | undefined;
  readonly link$: Observable<DashboardRef | undefined>;

  update(changes: DashboardModelUpdate): Observable<void>;
  delete(): Observable<void>;
}

/**
 * Utility type to map more derived {@link DashboardRef} types;
 * used to work around type soundness problems with `model` and
 * `model$`, that TypeScript cannot reconcile.
 */
export interface DashboardRefNoModel extends Omit<DashboardRef, 'model' | 'model$'> {
  readonly model: unknown;
  readonly model$: Observable<unknown>;
}

/** A {@link DashboardRef} that holds a {@link DashboardNodeModel} */
export interface DashboardNodeRef extends DashboardRefNoModel {
  readonly model: DashboardNodeModel;
  readonly model$: Observable<DashboardNodeModel>;
}

/** A {@link DashboardRef} that holds a {@link DashboardItemModel} */
export interface DashboardItemRef extends DashboardRefNoModel {
  readonly model: DashboardItemModel;
  readonly model$: Observable<DashboardItemModel>;
}

/** A {@link DashboardRef} that holds a {@link DashboardTemplateModel} */
export interface DashboardTemplateRef extends DashboardRefNoModel {
  readonly model: DashboardTemplateModel;
  readonly model$: Observable<DashboardTemplateModel>;
}

/** A {@link DashboardRef} that holds a {@link DashboardFolderModel} */
export interface DashboardFolderRef extends DashboardRef {
  readonly model: DashboardFolderModel;
  readonly model$: Observable<DashboardFolderModel>;

  readonly children: readonly DashboardRef[];
  readonly children$: Observable<readonly DashboardRef[]>;
  readonly childNodes: readonly DashboardNodeRef[];
  readonly childNodes$: Observable<readonly DashboardNodeRef[]>;
  readonly childFolders: readonly DashboardFolderRef[];
  readonly childFolders$: Observable<readonly DashboardFolderRef[]>;

  /**
   * Returns the {@link DashboardFolderRef} itself whenever its model
   * or any of its childrens' models changes.
   */
  readonly monitorHierarchyChanges$: Observable<DashboardFolderRef>;

  /**
   * Indicates the loading state of a folder.
   *
   * Non-top-level folders are generally loaded already, but top-level folders
   * can be also be `loading`, or marked as an `on-demand` loading folder, which
   * means that the client needs to explicitly do a call to {@link loadChildren}.
   */
  readonly childrenLoadState: DashboardChildrenLoadState;

  /**
   * Indicates the loading state of a folder, as it changes over time.
   *
   * Non-top-level folders are generally loaded already, but top-level folders
   * can be also be `loading`, or marked as an `on-demand` loading folder, which
   * means that the client needs to explicitly do a call to {@link loadChildren}.
   */
  readonly childrenLoadState$: Observable<DashboardChildrenLoadState>;

  /** For on-demand loaded folders, request child nodes to be loaded. */
  loadChildren(): Observable<readonly DashboardRef[]>;

  createChild(data: DashboardCreate & { type: DashboardType.FOLDER }): Observable<DashboardFolderRef>;
  createChild(data: DashboardCreate & { type: DashboardType.TEMPLATE }): Observable<DashboardTemplateRef>;
  createChild(data: DashboardCreate & { type: DashboardType.DASHBOARD }): Observable<DashboardItemRef>;
  createChild(data: DashboardCreate): Observable<DashboardRef>;

  acceptsAsChild(other: DashboardRef): boolean;

  createLink(data: DashboardLinkCreate<DashboardTemplateRef>): Observable<DashboardTemplateRef>;
  createLink(data: DashboardLinkCreate<DashboardItemRef>): Observable<DashboardItemRef>;
  createLink(data: DashboardLinkCreate): Observable<DashboardRef>;

  canLinkTo(other: DashboardRef): boolean;
}

export function isDashboardNodeRef(dashboard: DashboardRefNoModel | undefined | null): dashboard is DashboardNodeRef {
  return isDashboardNode(dashboard?.model as DashboardModel | undefined);
}

export function isDashboardItemRef(dashboard: DashboardRef | undefined | null): dashboard is DashboardItemRef {
  return isDashboardItem(dashboard?.model);
}

export function isDashboardTemplateRef(dashboard: DashboardRef | undefined | null): dashboard is DashboardTemplateRef {
  return isDashboardTemplate(dashboard?.model);
}

export function isDashboardFolderRef(dashboard: DashboardRef | undefined | null): dashboard is DashboardFolderRef {
  return isDashboardFolder(dashboard?.model);
}

export function assertDashboardNodeRef(dashboard: DashboardRefNoModel | undefined | null, message?: string): asserts dashboard is DashboardNodeRef {
  assertDashboardNode(dashboard?.model as DashboardModel | undefined, message);
}

export function assertDashboardItemRef(dashboard: DashboardRef | undefined | null, message?: string): asserts dashboard is DashboardItemRef {
  assertDashboardItem(dashboard?.model, message);
}

export function assertDashboardTemplateRef(dashboard: DashboardRef | undefined | null, message?: string): asserts dashboard is DashboardTemplateRef {
  assertDashboardTemplate(dashboard?.model, message);
}

export function assertDashboardFolderRef(dashboard: DashboardRef | undefined | null, message?: string): asserts dashboard is DashboardFolderRef {
  assertDashboardFolder(dashboard?.model, message);
}
