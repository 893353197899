import { TradingSymbolMappingInfoService } from '../../../../shared/trading-symbol-mapping-info/trading-symbol-mapping-info.service';
import { ContextMenuItem } from '../../../../shared/models/context-menu.model';

export const symbolMappingInfoItem: (tradingSymbolMappingInfoService: TradingSymbolMappingInfoService) => ContextMenuItem = (tradingSymbolMappingInfoService) => {
  return {
    id: 'symbolMappingInfo',
    title: 'CONTEXT_MENU_COMPONENT.TRADING.SYMBOL_MAPPING_INFO',
    icon: 'troubleshoot',
    closeOnClick: true,
    onClick: (params) => {
      const tradingPositionMeta = params?.tradingPositionGetter?.();
      tradingSymbolMappingInfoService.showSymbolMappingInfo({
        fullNameDebug: tradingPositionMeta?.FullNameDebug,
        instrument: params?.instrument,
      });
    },
  };
};
