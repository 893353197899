import { Injectable, inject } from '@angular/core';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { BehaviorSubject, Observable, Subscriber, finalize, map, merge, switchMap } from 'rxjs';
import { SdkService } from '../../services/sdk.service';
import { TradingService } from '../../services/trading.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { filterUndefined } from '../../util/rxjs';


export const CurrencyCashPositions = 'CurrencyCashPositions';

@Injectable({
  providedIn: 'root'
})
export class PositionsSummaryService {
  private tradingService: TradingService = inject(TradingService);
  private sdkService: SdkService = inject(SdkService);
  private userSettingsService: UserSettingsService = inject(UserSettingsService);

  private readonly cashPositionsAction = new BehaviorSubject<{ [currency: string]: number } | undefined>(undefined);

  private readonly portfolioData$ = this.tradingService.portfolioData$;

  private initialCashPositionsObserver = () => { };
  // although we get cashPositions as a side effect of calculatedPortfolioValues$ we need an inital value ASAP to calculate height needed for the window holding the cash positions
  private readonly initialCashPositions$ = this.portfolioData$.pipe(switchMap((portfolio: InfrontSDK.Trading.PortfolioData) => {

    return new Observable((obs: Subscriber<{ [currency: string]: number }>) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      this.initialCashPositionsObserver = portfolio.observeValue('SumCash', () => {
        obs.next(this.cashPositions(portfolio));
      });
    });
  }), finalize(() => this.initialCashPositionsObserver()));

  readonly numberOfCashPositions$ = merge(this.initialCashPositions$, this.cashPositionsAction).pipe(filterUndefined(), map(cashPositions => Object.keys(cashPositions).length));

  // there is no typing support for all available calculated portfolio fields in SDK yet, using string
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  calculatedPortfolioValues$(calculatedPortfolioFields: string[], caller?: string) {
    const observers: (() => void)[] = [];
    return this.portfolioData$.pipe(switchMap((portfolio: InfrontSDK.Trading.PortfolioData) => {
      const streamingCalculatedValues: { [field: string]: unknown } = calculatedPortfolioFields.reduce((obj, field) => ({ ...obj, [field]: undefined }), {});
      //caller; // debug
      return new Observable((obs: Subscriber<{ [field: string]: unknown }>) => {
        for (const field of calculatedPortfolioFields) {
          streamingCalculatedValues[field] = undefined;
          const observer = portfolio.observeValue(field, (value: unknown) => {
            // special case for listing cash positions per currency - there does not seem to be a field or dedicated request in SDK for this
            if (field === 'SumCash') {
              streamingCalculatedValues[CurrencyCashPositions] = this.cashPositions(portfolio);
              this.cashPositionsAction.next(this.cashPositions(portfolio));
            }
            if (InfrontUtil.isNumber(value)) {
              value = InfrontUtil.roundToPrecision(value, 2);
            }
            streamingCalculatedValues[field] = value;
            obs.next(streamingCalculatedValues);
          });
          observers.push(observer);
        }
      });
    }), finalize(() => {
      //caller; // debug
      observers.forEach(observer => observer());
    }));
  }

  private cashPositions(portfolio: InfrontSDK.Trading.PortfolioData): { [currency: string]: number } {

    const cashPositions = portfolio.positions((item) => true);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const filtered: InfrontSDK.Trading.PortfolioItem[] = cashPositions.data.filter(item => item.symbolId == undefined && (item.key() as string)?.startsWith('CASH')) as InfrontSDK.Trading.PortfolioItem[];

    const cashPositionsObj = {};

    filtered.forEach(item => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const currency = item.get(InfrontSDK.TradingField.Currency);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const amount = item.get(InfrontSDK.TradingField.Amount);
      if (currency != undefined && amount != undefined) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        cashPositionsObj[currency] = amount;
      }
    });
    return cashPositionsObj;
  }
}
