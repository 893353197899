import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

import { setOverlayPanelClass } from '../../util/mat-menu';
import { DropdownEntry, DropdownItem, isDropdownFolder, isDropdownItem } from './dropdown.model';

const DROPDOWN_CLASS = 'wt-dropdown-menu';

@Component({
  selector: 'wt-dropdown',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent<TItem extends object> implements AfterViewInit {
  @ViewChild('matMenu') matMenu: MatMenu;
  @ViewChild('matMenu', { read: ElementRef }) matMenuElementRef: ElementRef;

  @Input() matMenuScopeClass: string;
  @Input() height: number;
  @Input() items: DropdownEntry<TItem>[];
  @Input() dropdownLabel?: string;
  @Input() translateDropdownLabel?: boolean;
  @Input() matDropdownIcon?: string;
  @Input() matSubMenuIcon?: string;
  @Input() isSvgIcon?: boolean;
  @Input() useSelectedItemLabel?: boolean;
  @Input() noDropdownLabelPlaceholder = 'NA';
  @Input() translateNoDropdownLabelPlaceholder?: boolean;
  @Input() noItemLabelPlaceholder = 'NA';
  @Input() translateNoItemLabelPlaceholder?: boolean;
  @Input() itemLabelProperty: keyof DropdownItem<TItem> = 'itemLabel';

  @Input() selectedItem: DropdownItem<TItem> | undefined;

  @Input() dropdownTemplate?: TemplateRef<unknown>;
  @Input() preItemTemplate?: TemplateRef<unknown>;
  @Input() itemTemplate?: TemplateRef<unknown>;
  @Input() postItemTemplate?: TemplateRef<unknown>;

  @Output() menuClosed = new EventEmitter<void>();
  @Output() menuOpened = new EventEmitter<void>();
  @Output() itemSelected = new EventEmitter<DropdownItem<TItem>>();

  isDropdownFolder = isDropdownFolder;
  isDropdownItem = isDropdownItem;

  ngAfterViewInit(): void {
    setOverlayPanelClass(this.matMenu, DROPDOWN_CLASS, this.matMenuScopeClass);
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

}
