import { Injectable } from '@angular/core';
import { DashboardServicesProvider } from '../dashboard-services.provider';
import { HttpDashboardProvider } from './http-dashboard.provider';
import { HttpWidgetDataProvider } from './http-widget-data.provider';

@Injectable({ providedIn: 'root' })
export class HttpDashboardServicesProvider implements DashboardServicesProvider {
  constructor(
    public readonly dashboards: HttpDashboardProvider,
    public readonly widgets: HttpWidgetDataProvider,
  ) { }
}
