import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { MarketOverviewDropdownModule } from '../../dashboard-window/ui/market-overview-dropdown/market-overview-dropdown.module';
import { DirectivesModule } from '../../directives/directives.module';

import { ScreenerModule } from '../screener/screener.module';
import { MainMenuComponent } from './main-menu.component';

@NgModule({
  declarations: [MainMenuComponent],
  imports: [
    CommonModule,
    OverlayModule,
    MatIconModule,
    MarketOverviewDropdownModule,
    ScreenerModule,
    DirectivesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [MainMenuComponent],
})
export class MainMenuModule { }
