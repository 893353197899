import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';
import { I18nPipesModule } from '@vwd/ngx-i18n/pipes';
import { PricePipesModule } from '@vwd/ngx-price-pipes';

import { InstrumentDockItemComponent } from './instrument-dock-item.component';
import { DirectivesModule } from '../../../directives/directives.module';
import { CountryFlagModule } from '../../../shared/country-flag/country-flag.module';

@NgModule({
  declarations: [InstrumentDockItemComponent],
  providers: [DatePipe],
  imports: [
    CommonModule,
    MatIconModule,
    DirectivesModule,
    CountryFlagModule,
    PricePipesModule,
    I18nPipesModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [InstrumentDockItemComponent]
})
export class InstrumentDockItemModule { }
