import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';

import { AlertLogItemModule } from '../alerts-list/alert-log-item/alert-log-item.module';
import { AlertToastComponent } from './alert-toast.component';

@NgModule({
  declarations: [AlertToastComponent],
  imports: [CommonModule, MatIconModule, MatBadgeModule, AlertLogItemModule],
  exports: [AlertToastComponent],
})
export class AlertToastModule {}
