import { normalizeLocale } from '@vwd/ngx-i18n';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringOrTranslation } from '../models';
import { getCompatibleLocales } from './compatible-locales';

/**
 * Returns a translation from a {@link StringOrTranslation} instance, given the
 * specified language, taking into account any fallback rule when no 1-1 match
 * exists.
 *
 * @param translations The translation object.
 * @param localeId The language to fetch the translation for
 * @returns A translation.
 */
export function translate(translations: StringOrTranslation, localeId: string, defaultValue?: string): string {
  if (typeof translations === 'string') {
    return translations;
  } else if (!translations) {
    // This shouldn't happen, but we can't guarantee the caller uses strict mode.
    return translations;
  }

  // Lookup for the normalized localeId
  let normalizedLocaleId = normalizeLocale(localeId);

  // Lookup for the normalized localeId
  let translation = translations[normalizedLocaleId];
  if (translation !== undefined) {
    return translation;
  }

  // If that fails, test all compatible locales
  const fallbacks = getCompatibleLocales(normalizedLocaleId);

  for (const compatibleLocaleId of fallbacks) {
    for (const prop in translations) {
      if (translations.hasOwnProperty(prop)) {
        const normalizedProp = prop;
        const translation = translations[prop];
        if (compatibleLocaleId === normalizedProp) {
          // Try to add the translation to the object so future lookups are faster;
          // does change the object, though, so we store the translation as a non-enumerable
          // property, so we don't 'see' it from the outside, and it's invisible
          // for things like for-in, JSON serialization, etc. `console.log` does
          // show it, though.
          if (!Object.isFrozen(translations)) {
            try {
              // If `prop` wasn't normalized, normalize it now
              if (compatibleLocaleId !== prop) {
                Object.defineProperty(translations, compatibleLocaleId, { enumerable: false, configurable: true, writable: true, value: translation });
              }
              // If lang wasn't the requested language, store the requested language
              // so we can skip the probing next time.
              if (compatibleLocaleId !== normalizedLocaleId) {
                Object.defineProperty(translations, normalizedLocaleId, { enumerable: false, configurable: true, writable: true, value: translation });
              }
            } catch (e) {
              // Okay, so we can't extend
            }
          }
          return translation;
        }
      }
    }
  }

  if (defaultValue != null) {
    return defaultValue;
  }

  throw new Error(`No translations found for ${localeId}, tried ${fallbacks}`);
}

/**
 * Returns a translation stream from a {@link StringOrTranslation} instance,
 * given the specified language stream, taking into account any fallback rule
 * when no 1-1 match exists.
 *
 * @param translations The translation object.
 * @param localeId$ An {@link Observable} that emits the current language.
 *
 * @returns An {@link Observable} that emits the translation requested every
 * time the {@link localeId$} emits (except when the translation is a fixed
 * string).
 */
export function translate$(translations: StringOrTranslation, localeId$: Observable<string>, defaultValue?: string): Observable<string> {
  if (typeof translations === 'string') {
    return of(translations);
  } else if (!translations) {
    // @ts-ignore  Not really allowed as the function declaration is Observable<string> but this is returning Observable<null>, I guess this is garbage in -> garbage out.
    return of(translations);
  }

  return localeId$.pipe(map(localeId => translate(translations, localeId, defaultValue)));
}
