

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { DirectivesModule } from '../../directives/directives.module';
import { SearchModule } from '../../search/search.module';
import { ComboBoxComponent } from './combo-box.component';

@NgModule({
  declarations: [ComboBoxComponent],
  imports: [CommonModule, SearchModule, DirectivesModule, MatButtonModule, MatMenuModule, MatIconModule, OverlayModule, MatAutocompleteModule, MatInputModule, MatOptionModule, ReactiveFormsModule, FormsModule, ScrollingModule],
  exports: [ComboBoxComponent],
})
export class ComboBoxModule { }
