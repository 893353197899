import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, inject } from '@angular/core';
import { InfrontUtil } from '@infront/sdk';
import { EMPTY, ReplaySubject, merge } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { SdkRequestsService } from '../../services/sdk-requests.service';
import { Widget } from '../../state-model/widget.model';
import { Instrument, } from '../../state-model/window.model';
import { DisplayStatusMap, SymbolStatusLabelClass, fields } from './symbol-status.model';

@Component({
  selector: 'wt-symbol-status',
  templateUrl: './symbol-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SymbolStatusComponent implements OnDestroy {
  private readonly sdkRequestsService: SdkRequestsService = inject(SdkRequestsService);

  @Input() set widget(widget: Widget) {
    this.widgetAction$.next(widget);
  }
  @Input() set instrument(instrument: Instrument | undefined) {
    this.instrumentAction$.next(instrument);
  }
  @Input() hideSymbolStatus: Array<string> = [];
  @Input() alternativeValue: string;

  /** true: symbol-status badge is visible, false: badge is not visible */
  @Output() displayStatus = new EventEmitter<boolean>();

  private readonly widgetAction$ = new ReplaySubject<Widget | undefined>(1);
  private readonly instrumentAction$ = new ReplaySubject<Instrument | undefined>(1);

  private readonly instrument$ = merge(
    this.instrumentAction$,
    this.widgetAction$.pipe(
      switchMap(widget => widget ? this.sdkRequestsService.windowInstrument$(widget) : EMPTY)
    ),
  );

  readonly displayStatusMap = DisplayStatusMap;
  readonly symbolStatusLabelClass = SymbolStatusLabelClass;
  readonly symbolInfo$ = this.instrument$.pipe(
    switchMap((instrument) => instrument ? this.sdkRequestsService.streamingSymbolData$({
      symbolEntity: instrument, fields, uuid: InfrontUtil.makeUUID()
    }) : EMPTY),
    tap((symbol) => {
      this.showAlternativeValue = !this.hideSymbolStatus.includes(this.displayStatusMap[symbol.DisplaySymbolStatusCode]);
      this.displayStatus.next(this.showAlternativeValue);
    }),
  );
  showAlternativeValue: boolean;

  ngOnDestroy(): void {
    [this.widgetAction$, this.instrumentAction$].forEach((action$) => action$.complete());
  }

}
