import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OrderbookComponent } from './orderbook.component';
import { OrderbookViewModule } from '../../shared/orderbook-view/orderbook-view.module';

@NgModule({
  declarations: [OrderbookComponent],
  imports: [
    CommonModule,
    OrderbookViewModule,
  ],
  exports: [],
  providers: [],
})
export class OrderbookModule {
  static readonly components = {
    orderbook: OrderbookComponent,
  };
}
