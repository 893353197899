import { Infront } from '@infront/sdk';

/** Honestly this should be provided by the SDK */
export const PortfolioValueMap: Readonly<{ [Value in Infront.PortfolioValue]?: string }> = {
  [Infront.PortfolioValue.TradingPower]: 'trading_power',
  [Infront.PortfolioValue.BaseCurrency]: 'base_currency',
  [Infront.PortfolioValue.TotalPortfolioValue]: 'portfolio_value',
  [Infront.PortfolioValue.TotalPortfolioValueTGW]: 'portfolio_value_tgw',
  [Infront.PortfolioValue.TotalBaseMarketValue]: 'total_base_market_value',
  [Infront.PortfolioValue.TotalBaseResult]: 'total_base_result',
  [Infront.PortfolioValue.TotalBaseResultPct]: 'total_base_result_pct',
  [Infront.PortfolioValue.TotalBaseProfitLossToday]: 'total_base_pl_today',
  [Infront.PortfolioValue.TotalBaseRealizedToday]: 'total_base_realized_today',
  [Infront.PortfolioValue.TotalBaseProfitLossTodayPct]: 'total_base_pl_today_pct',
  [Infront.PortfolioValue.TotalBaseInvested]: 'total_base_invested',
  [Infront.PortfolioValue.TotalBaseCollateral]: 'total_base_collateral',
  [Infront.PortfolioValue.SumCash]: 'sum_cash'
} as const;

const I18N_FIELD_LABEL_PREFIX = 'FIELD_LABELS';

export const PortfolioValueTranslationLabels: Readonly<{ [Value in Infront.PortfolioValue]?: string }> = {
  [Infront.PortfolioValue.TradingPower]: `${I18N_FIELD_LABEL_PREFIX}.TRADING_POWDER`,
  [Infront.PortfolioValue.BaseCurrency]: `${I18N_FIELD_LABEL_PREFIX}.BASE_CURRENCY`,
  [Infront.PortfolioValue.TotalPortfolioValue]: `${I18N_FIELD_LABEL_PREFIX}.TOTAL_PORTFOLIO_VALUE`,
  [Infront.PortfolioValue.TotalPortfolioValueTGW]: `${I18N_FIELD_LABEL_PREFIX}.TOTAL_PORTFOLIO_VALUE_TGW`,
  [Infront.PortfolioValue.TotalBaseMarketValue]: `${I18N_FIELD_LABEL_PREFIX}.TOTAL_BASE_MARKET_VALUE`,
  [Infront.PortfolioValue.TotalBaseResult]: `${I18N_FIELD_LABEL_PREFIX}.TOTAL_BASE_RESULT`,
  [Infront.PortfolioValue.TotalBaseResultPct]: `${I18N_FIELD_LABEL_PREFIX}.TOTAL_BASE_RESULT_PCT`,
  [Infront.PortfolioValue.TotalBaseProfitLossToday]: `${I18N_FIELD_LABEL_PREFIX}.TOTAL_BASE_PROFIT_LOSS_TODAY`,
  [Infront.PortfolioValue.TotalBaseRealizedToday]: `${I18N_FIELD_LABEL_PREFIX}.TOTAL_BASE_REALIZED_TODAY`,
  [Infront.PortfolioValue.TotalBaseProfitLossTodayPct]: `${I18N_FIELD_LABEL_PREFIX}.TOTAL_BASE_PROFIT_LOSS_TODAY_PCT"`,
  [Infront.PortfolioValue.TotalBaseInvested]: `${I18N_FIELD_LABEL_PREFIX}.TOTAL_BASE_INVESTED`,
  [Infront.PortfolioValue.TotalBaseCollateral]: `${I18N_FIELD_LABEL_PREFIX}.TOTAL_BASE_COLLATERAL`,
  [Infront.PortfolioValue.SumCash]: `${I18N_FIELD_LABEL_PREFIX}.SUM_CASH`,
} as const;
