import { Observable } from 'rxjs';
import { DashboardModel, WidgetsHistoryModel, WidgetStructureModel } from '../models';
import { Injectable } from '@angular/core';

export interface WidgetProviderSaveOptions {
  dashboard: DashboardModel;
  widgets: WidgetStructureModel;
  markAsDraft: boolean;
}

export interface WidgetProviderLoadHistoryOptions {
  dashboard: DashboardModel;
  historyEntry: WidgetsHistoryModel;
}

export abstract class WidgetDataProvider {
  public abstract loadHistory(dashboard: DashboardModel): Observable<readonly WidgetsHistoryModel[]>;

  public abstract loadByDashboard(dashboard: DashboardModel): Observable<WidgetStructureModel>;
  public abstract loadByHistory(options: WidgetProviderLoadHistoryOptions): Observable<WidgetStructureModel>;

  public abstract save(saveOptions: WidgetProviderSaveOptions): Observable<void>;
  public abstract revertDraft(dashboard: DashboardModel): Observable<void>;
}

/**
 * A widget data provider that never returns anything.
 *
 * Can be used by DashboardServicesProvider instances that do not provide
 * dashboards themselves, but are folder providers (like recently visited).
 */
@Injectable({ providedIn: 'root' })
export class NoopWidgetDataProvider implements WidgetDataProvider {

  loadHistory(dashboard: DashboardModel): Observable<readonly WidgetsHistoryModel[]> {
    throw new Error('Not supported.');
  }

  loadByDashboard(dashboard: DashboardModel): Observable<WidgetStructureModel> {
    throw new Error('Not supported.');
  }

  loadByHistory(options: WidgetProviderLoadHistoryOptions): Observable<WidgetStructureModel> {
    throw new Error('Not supported.');
  }

  save(saveOptions: WidgetProviderSaveOptions): Observable<void> {
    throw new Error('Not supported.');
  }

  revertDraft(dashboard: DashboardModel): Observable<void> {
    throw new Error('Not supported.');
  }
}
