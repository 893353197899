<div
  *ngIf="vm$ | async as vm"
  cdkDropList
  cdkDropListOrientation="horizontal"
  class="wt-dashboard-tabs"
  (cdkDropListDropped)="drop($event, vm.unfilteredDashboards)">

  <ul class="wt-list-inline" #tabsContainer>
    <!-- Standard dashboards -->
    <ng-container *ngFor="let dashboard of vm.dashboards; trackBy: trackById">
      <ng-container *ngIf="!dashboard.hidden">
        <li #includeInWidthCalculation
          class="wt-dashboard-tabs__item"
          [ngClass]="{'wt-dashboard-tabs__item--no-space': !dashboard.hasSpace, 'wt-dashboard-tabs__item--selected': dashboard.id === vm.selectedDashboard.id }"
          cdkDrag cdkDragLockAxis="x"
          (click)="onSelect(dashboard)">

          <mat-icon *ngIf="dashboard.type === DashboardType.template"
            class="wt-dashboard-tabs__item-icon mat-icon-small" fontIcon="tune"></mat-icon>

          <mat-icon *ngIf="iconFor(dashboard) as icon"
            class="wt-dashboard-tabs__item-icon mat-icon-small" [fontIcon]="icon"></mat-icon>

          <span class="wt-dashboard-tabs__item-label">{{ dashboard.name | translate }}</span>

          <wt-dashboard-context-menu [dashboard]="dashboard"
            *ngIf="dashboard.type === DashboardType.dashboard || dashboard.type === DashboardType.template"></wt-dashboard-context-menu>
        </li>
      </ng-container>
    </ng-container>

    <!-- "Other dashboards dropdown" button-->
    <li *ngIf="vm.offsetLeft < 0" [style.margin-left.px]="vm.offsetLeft">
      <wt-other-dashboards-context-menu [dashboards]="vm.dashboards"
        [selectedDashboard]="vm.selectedDashboard"></wt-other-dashboards-context-menu>
    </li>

    <!-- "Add new dashboard" button-->
    <li #includeInWidthCalculation>
      <wt-new-dashboard-context-menu></wt-new-dashboard-context-menu>
    </li>

    <!-- Portfolio dashboard -->
    <li cdk-drag-disabled
      *ngIf="!!vm.portfolioDashboard && !!vm.hasTradingFeature"
      #includeInWidthCalculation
      [ngClass]="
        vm.portfolioDashboard.id === vm.selectedDashboard.id ? 'wt-dashboard-tabs__item wt-dashboard-tabs__item--selected' : 'wt-dashboard-tabs__item'
      "
      (click)="onSelect(vm.portfolioDashboard)">
      <mat-icon *ngIf="iconFor(vm.portfolioDashboard) as icon"
        class="wt-dashboard-tabs__item-icon mat-icon-small" [fontIcon]="icon"></mat-icon>

      <span class="wt-dashboard-tabs__item-label">{{ vm.portfolioDashboard.name | translate }}</span>
    </li>

    <!-- Instrument dashboard -->
    <li *ngIf="vm.instrumentDashboard"
      #includeInWidthCalculation
      [ngClass]="vm.instrumentDashboard.id === vm.selectedDashboard.id ? 'wt-dashboard-tabs__item wt-dashboard-tabs__item--selected' : 'wt-dashboard-tabs__item'"
      (click)="onSelect(vm.instrumentDashboard)">

      <mat-icon *ngIf="iconFor(vm.instrumentDashboard) as icon"
        class="wt-dashboard-tabs__item-icon mat-icon-small" [fontIcon]="icon"></mat-icon>

      <span class="wt-dashboard-tabs__item-label">{{ vm.instrumentDashboard.name | translate }}</span>

      <wt-dashboard-context-menu [history]="true" [dashboard]="vm.instrumentDashboard"></wt-dashboard-context-menu>
    </li>
  </ul>
</div>