<div class="wt-auto-complete" *ngIf="showClear$ | async as showClear">
  <input type="text"
      (input)="onInput($event)"
      (keydown)="onKeydown($event)"
      focusOnShow
      [placeholder]="placeholder"
      [matAutocomplete]="auto"
      #matAutocompleteInput
      [ngClass]="!showClear.show ? 'wt-auto-complete-input' : 'wt-auto-complete-input--short wt-auto-complete-input'"
      >
      <mat-icon class="wt-auto-complete-icon" fontIcon="search"></ mat-icon>
      <mat-icon [hidden]="!showClear.show || disableClear" class="wt-auto-complete-icon wt-auto-complete-icon-clear" fontIcon="close" (click)="onClear()"></ mat-icon>

    <mat-autocomplete #auto="matAutocomplete">
        <mat-option class="wt-auto-complete__option" *ngFor="let item of searchResultItems"
          [value]="item" (click)="onItemSelected(item)">
          <div class="wt-flex-row">
            <div class="wt-truncate">{{item}}</div>
          </div>
        </mat-option>
    </mat-autocomplete>
</div>


