<form class="wt-alert-dialog__form" [formGroup]="alertForm" (ngSubmit)="submitClick.next(alertForm)">
  <div class="wt-flex-row wt-flex-collapse">
    <div class="wt-flex-column">
      <mat-form-field appearance="fill" floatLabel="never">
        <label class="wt-alert-dialog__form-label">{{ "ALERTS_NEW_EDIT_DIALOG.ALERT_ME_IF" | translate }}</label>

        <mat-select formControlName="alertMeIf" disableOptionCentering [compareWith]="compareFn">
          <mat-option *ngFor="let triggerOption of triggerOptions" [value]="triggerOption">
            {{ triggerOption.label | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="wt-flex-column wt-alert-dialog__form-values">
      <ng-container *ngIf="[0, 3].includes(alertForm.get('alertMeIf')?.value.key)">
        <div class="wt-flex-row wt-flex-align-middle">
          <div class="wt-flex-column wt-flex-collapse">
            <input class="wt-dialog-input wt-alert-dialog__form-input" numberOnly formControlName="rangeStart" />
          </div>

          <div class="wt-flex-column wt-flex-shrink">{{ "ALERTS_NEW_EDIT_DIALOG.RANGE_TO" | translate }}</div>

          <div class="wt-flex-column wt-flex-collapse">
            <input class="wt-dialog-input wt-alert-dialog__form-input" numberOnly formControlName="rangeEnd" />
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="![0, 3].includes(alertForm.get('alertMeIf')?.value.key)">
        <div class="wt-flex-row wt-flex-collapse">
          <div class="wt-flex-column wt-alert-dialog__form-limit">
            <input class="wt-dialog-input wt-alert-dialog__form-input" numberOnly formControlName="limit" />
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="wt-flex-row wt-flex-collapse">
    <div class="wt-flex-column wt-alert-dialog__form-comment">
      <mat-form-field appearance="fill" floatLabel="never">
        <label class="wt-alert-dialog__form-label">{{ "ALERTS_NEW_EDIT_DIALOG.RECURRANCE" | translate }}</label>

        <mat-select formControlName="recurrence" disableOptionCentering>
          <mat-option *ngFor="let recurrance of reccuranceOptions" [value]="recurrance">
            {{ recurrance.label | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="wt-flex-column wt-alert-dialog__form-comment">
      <label class="wt-alert-dialog__form-label">{{ "ALERTS_NEW_EDIT_DIALOG.COMMENT" | translate }}</label>

      <input class="wt-dialog-input wt-alert-dialog__form-input" formControlName="comment" />
    </div>
  </div>

  <div class="wt-action-buttons">
    <div class="wt-flex-row wt-flex-collapse wt-flex-align-right">
      <div class="wt-flex-column wt-flex-shrink">
        <button type="button" (click)="cancelClick.next()" class="wt-button wt-button--cancel">
          {{ "GLOBAL.CANCEL" | translate }}
        </button>
      </div>

      <div class="wt-flex-column wt-flex-shrink">
        <button type="submit" class="wt-button wt-button--primary">{{ "GLOBAL.OK" | translate }}</button>
      </div>
    </div>
  </div>
</form>