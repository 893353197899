import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { LogService } from '@vwd/ngx-logging';
import { ColumnResizedEvent, GridColumnsChangedEvent, RowSelectedEvent } from 'ag-grid-community';
import { Observable, filter, map, shareReplay, switchMap, take } from 'rxjs';

import { DefaultSidebarListColumns } from '../../dashboard/sidebar/sidebar-content/sidebar-content.defaults';
import { DefaultFeedScoreFactorItems, SdkSymbolSearchResultItem } from '../../search/search.model';
import { SdkRequestsService } from '../../services/sdk-requests.service';
import { ColumnPickerDialogComponent } from '../../shared/column-picker-dialog/column-picker-dialog.component';
import { ColumnRegistry } from '../../shared/grid/column-registry';
import { Column } from '../../shared/grid/columns.model';
import { SymbolDataItem } from '../../shared/models/symbol-data.model';
import { ColumnSetting, Grid, GridColumnsMap } from '../../state-model/grid.model';
import { Instrument } from '../../state-model/window.model';
import { arraysAreEqual, structuresAreEqual } from '../../util/equality';
import { fullSelectedColumnsByGrid, getColumnSettingsFromColDef } from '../../util/grid';
import { filterUndefined } from '../../util/rxjs';
import { getInstrumentsFromSymbolLikes, instrumentsAreEqual } from '../../util/sdk';
import { createSymbolDataFromSymbol } from '../../util/symbol';
import { AdditionalSymbolFields } from '../../widgets/lists/observe-symbols.service';
import { GridRef, createGridRef } from '../../wrappers/grid-wrappers/gridref';
import { SymbolsGridComponent } from '../../wrappers/grid-wrappers/symbols-grid/symbols-grid.component';

@Component({
  selector: 'wt-sidebar-instrument-list-editor',
  templateUrl: './sidebar-instrument-list-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarInstrumentListEditorComponent implements OnDestroy {

  @ViewChild(SymbolsGridComponent)
  grid: SymbolsGridComponent;

  @Input()
  get instruments(): readonly Instrument[] {
    return this.instrumentListSubject.value!;
  }
  set instruments(value: readonly Instrument[]) {
    this.instrumentListSubject.next([...value]);
  }

  @Input()
  get columns(): readonly ColumnSetting[] | undefined {
    return this.columnsSubject.value;
  }
  set columns(value: readonly ColumnSetting[] | undefined) {
    if (!arraysAreEqual(this.columnsSubject.value, value, structuresAreEqual)) {
      this.columnsSubject.next(value?.length ? [...value] : undefined);
    }
  }

  @Input()
  canEdit = false;

  @Output()
  readonly instrumentsChange = new EventEmitter<readonly Instrument[]>();
  @Output()
  readonly columnsChange = new EventEmitter<readonly ColumnSetting[] | undefined>();

  private readonly logger = inject(LogService).openLogger('components/sidebar-instrument-list-editor');
  private readonly sdkRequestService = inject(SdkRequestsService);
  private readonly dialog = inject(MatDialog);

  private readonly instrumentListSubject = new LastValueSubject<Instrument[]>();
  private readonly columnsSubject = new LastValueSubject<ColumnSetting[] | undefined>();

  readonly selectedSymbols$: Observable<InfrontSDK.SymbolData[]>;
  readonly gridSymbols$: Observable<SymbolDataItem[]>;
  readonly gridRef: GridRef;
  readonly DefaultFeedScoreFactorItems = DefaultFeedScoreFactorItems;

  constructor() {
    const gridId = InfrontUtil.makeUUID();
    const gridTemplate = {
      id: gridId,
      name: 'lists',
      settings: {
        selectedColumns: [ColumnRegistry.dragHandle, ...DefaultSidebarListColumns],
      }
    } as Grid;

    this.gridRef = createGridRef(
      this.columnsSubject.pipe(
        map((columns) => ({
          ...gridTemplate,
          settings: { selectedColumns: [ColumnRegistry.dragHandle, ...(columns?.length ? columns : DefaultSidebarListColumns)] }
        })
        )
      ), (grid, update) => {
        const newSelectedColumns = update.settings?.selectedColumns;
        if (newSelectedColumns) {
          const columns = getColumnSettingsFromColDef(newSelectedColumns).filter((col) => col.colId !== 'dragHandle');
          if (!arraysAreEqual(this.columnsSubject.value, columns, structuresAreEqual)) {
            this.columnsSubject.next(columns);
            this.columnsChange.next(columns);
          }
        }
      });

    this.selectedSymbols$ = this.instrumentListSubject.pipe(
      this.sdkRequestService.symbolsFromIds({ gridRef: this.gridRef, maintainOrder: true }),
      shareReplay(1),
    );

    this.gridSymbols$ = this.selectedSymbols$.pipe(
      map((symbolIds) =>
        symbolIds.map(createSymbolDataFromSymbol)
      )
    );
  }

  ngOnDestroy(): void {
    this.instrumentListSubject.complete();
    this.columnsSubject.complete();
  }

  onSelectedOption(symbolId: SdkSymbolSearchResultItem): void {
    this.logger.log('Add symbol', symbolId);

    if (!this.canEdit
      || this.instruments.some((instrument) => instrumentsAreEqual(instrument, symbolId))) {
      return;
    }

    const newList = [...this.instruments, { feed: symbolId.feed, ticker: symbolId.ticker }];
    this.instruments = newList;
    this.instrumentsChange.next(newList);
  }

  onRowsChanged(symbolDataItems: AdditionalSymbolFields[]): void {
    this.logger.log('Reorder symbols', symbolDataItems);

    if (!this.canEdit) {
      return;
    }

    const newList = getInstrumentsFromSymbolLikes(symbolDataItems.map(s => s.symbol));

    this.instruments = newList;
    this.instrumentsChange.next(newList);
  }

  onRowSelected(event: RowSelectedEvent): void { }

  deleteSelectedRows(): void {
    const symbolDataItems = this.grid.selectedRows as AdditionalSymbolFields[];
    this.logger.log('Delete selected', symbolDataItems);

    const selectedRows = getInstrumentsFromSymbolLikes(symbolDataItems.map(s => s.symbol));

    if (!this.canEdit || !selectedRows.length) {
      return;
    }

    const newList = this.instruments.filter((instrument) => !selectedRows.some((symbol) => instrumentsAreEqual(symbol, instrument)));

    this.instruments = newList;
    this.instrumentsChange.next(newList);
  }

  showColumnPicker(): void {
    this.gridRef.grid$.pipe(
      filterUndefined(),
      take(1),
      switchMap((grid) =>
        this.dialog
          .open(ColumnPickerDialogComponent, {
            data: {
              selectedColumns: fullSelectedColumnsByGrid(grid).filter(col => col.colId !== 'dragHandle'),
              columnCategories: GridColumnsMap[grid.name].categories ?? [{ columns: GridColumnsMap[grid.name].all }],
            },
            height: '450px',
            maxHeight: '450px',
          })
          .afterClosed()
          .pipe(
            take<Column[]>(1),
            filter((selectedColumns) => !!selectedColumns),
            map((selectedColumns) => ({ selectedColumns, grid })),
          )
      )
    ).subscribe({
      next: ({ selectedColumns, grid }) => {
        const settings = { ...grid.settings, selectedColumns: [ColumnRegistry.dragHandle, ...getColumnSettingsFromColDef(selectedColumns)] };
        this.gridRef.onColumnsChanged({ ...grid, settings });
        this.logger.info('Changed columns to', settings);
      },
      complete: () => { },
    });
  }

  onGridColumnsChanged(params: GridColumnsChangedEvent | ColumnResizedEvent): void {
    params.api.sizeColumnsToFit();
  }
}
