import { GlobalPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SettingsMenuContentComponent } from './settings-menu-content.component';

@Injectable({
  providedIn: 'root',
})
export class SettingsMenuService {
  openSettings$ = () => of(this.openOverlay());

  // configure the centered overlay for displaying iframes
  private positionStrategy: GlobalPositionStrategy = this.overlay.position()
    .global()
    .centerHorizontally()
    .centerVertically();
  private overlayRef: OverlayRef = this.overlay.create({
    // width: 100, // set in CSS
    // height: 100, // set in cSS
    // backdropClass: 'cdk-overlay-transparent-backdrop', // we need opaque backdrop
    panelClass: 'wt-settings-menu-content-overlay',
    hasBackdrop: true,
    positionStrategy: this.positionStrategy,
  });
  private backdropClick$ = this.overlayRef.backdropClick().pipe(
    tap(() => this.closeOverlay()),
  );

  constructor(
    private readonly overlay: Overlay,
    public dialog: MatDialog
  ) { }

  openOverlay(): void {
    this.closeOverlay();
    const overlayContent = new ComponentPortal(SettingsMenuContentComponent);
    const cmpRef = this.overlayRef.attach(overlayContent);
    cmpRef.instance.backdropClick$ = this.backdropClick$;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    cmpRef.instance.closeOverlay = this.closeOverlay.bind(this);
  }

  closeOverlay(): void {
    this.overlayRef.detach();
  }

}

