import { Observable } from 'rxjs';
import { WidgetSaveOptions } from './widget-data.service';
import { DashboardRef } from '../state-refs';
import { WidgetStructureModel, WidgetsHistoryEntry } from '../models';
import { InjectionToken } from '@angular/core';

export type WidgetSaveHandler = (req: WidgetSaveOptions) => Observable<void>;
export type WidgetSaveInterceptorFn = (req: WidgetSaveOptions, next: WidgetSaveHandler) => Observable<void>;

export type WidgetLoadHandler = (req: DashboardRef) => Observable<WidgetStructureModel>;
export type WidgetLoadInterceptorFn = (req: DashboardRef, next: WidgetLoadHandler) => Observable<WidgetStructureModel>;

export type WidgetLoadHistoryHandler = (req: WidgetsHistoryEntry) => Observable<WidgetStructureModel>;
export type WidgetLoadHistoryInterceptorFn = (req: WidgetsHistoryEntry, next: WidgetLoadHistoryHandler) => Observable<WidgetStructureModel>;

export const WidgetSaveInterceptor = new InjectionToken<WidgetSaveInterceptorFn[]>('WidgetSaveInterceptor');
export const WidgetLoadInterceptor = new InjectionToken<WidgetLoadInterceptorFn[]>('WidgetLoadInterceptor');
export const WidgetLoadHistoryInterceptor = new InjectionToken<WidgetLoadHistoryInterceptorFn[]>('WidgetLoadHistoryInterceptor');
