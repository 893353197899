import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { I18nTranslateModule } from '@vwd/ngx-i18n/translate';

import { TradingOrderFlyoutModule } from '../trading-order-flyout/trading-order-flyout.module';
import { TradingOrderEntryComponent } from './trading-order-entry.component';
import { WTKWidgetWrapperModule } from '../../wrappers/wtk-widget-wrapper/wtk-widget-wrapper.module';

@NgModule({
  declarations: [TradingOrderEntryComponent],
  imports: [
    CommonModule,
    OverlayModule,
    DragDropModule,
    MatIconModule,
    WTKWidgetWrapperModule,
    TradingOrderFlyoutModule,
    I18nTranslateModule.forResourceSet({
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-return */
      load: (locale: string) => require(`./i18n/${locale}.json`),
      supportedLocales: ['en', 'de', 'no'],
    }),
  ],
  exports: [TradingOrderEntryComponent],
})
export class TradingOrderEntryModule { }
