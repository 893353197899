import { Injectable, inject } from '@angular/core';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { JSONValue, PreferencesMap } from '@vwd/microfrontend-core';
import { BehaviorSubject, NEVER, Observable, Subject, combineLatest, of } from 'rxjs';
import { map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { SdkService } from '../../services/sdk.service';
import { SearchStorageData, StorageService } from '../../services/storage.service';
import { ClassifiedInstrument, isInstrument, isMarket } from '../../state-model/window.model';
import { moveItem } from '../../util/array';
import { isClassifiedInstrument } from './../../state-model/window.model';
import { filterUndefinedArray } from './../../util/rxjs';
import {
  FeedInfoToSdkSearchResultMarketItemMap,
  FeedScoreFactorItem,
  FullSearchWindowResultItem,
  SdkMarketSearchResultItem,
  SdkSearchResultItem,
  SdkSearchResultMarketItemFields,
  SdkSearchResultSymbolItemFields,
  SdkSymbolSearchResultItem,
  SearchConfig,
  SearchConfigSymbol,
  SearchDataSource,
  SearchHistoryData,
  SearchHistoryItem,
  SearchHistoryItemTypeLimit,
  SearchWindowItemMap,
  SearchWindowItems,
  StorageKeyOrComponentRef,
  getDefaultSymbolSearchResultFields,
  getHistoryStorageKey,
  getMinimumSymbolSearchResultFields,
  getSearchLimit,
  transformSearchResultItemToHistoryItem,
} from './../search.model';

@Injectable({
  providedIn: 'root',
})
export class SdkSearchService {
  private readonly sdkService: SdkService = inject(SdkService);
  private readonly storageService: StorageService = inject(StorageService);

  private readonly ngUnsubscribe = new Subject<void>();

  private readonly searchHistoryDataAction: BehaviorSubject<SearchHistoryData> = new BehaviorSubject({}); // All data from the 'history' key
  private readonly searchHistoryData$ = this.searchHistoryDataAction.asObservable();
  private searchHistoryDataByKey$: { [historyKey: string]: BehaviorSubject<SearchHistoryItem[]>; } = {}; // Scoped data from 'history' + key

  private readonly searchStorage = this.storageService.getSearchStorage() as PreferencesMap<SearchStorageData>;

  private readonly searchStorageWatchDisposable = this.searchStorage.watch('history', (result) => {
    if (result) {
      // filter falsey
      this.searchHistoryDataAction.next(result);
      if (!Array.isArray(result) && typeof result === 'object') {
        Object.entries(result).forEach(([key, items]) => {
          // History
          if (this.searchHistoryDataByKey$[key]) {
            this.searchHistoryDataByKey$[key].next(items);
          }
        });
      }
    }
  });

  // @FIXME typing componentRef should only be required when searchConfig.history.historyType is equal to COMPONENT or INSTANCE
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  search$ = (
    query: string | undefined,
    searchConfig: SearchConfig,
    componentRef?: object
  ): Observable<{ results: (SdkSearchResultItem | FullSearchWindowResultItem)[]; source: SearchDataSource; }> => {
    if (query?.length) {
      // WINDOW RESULT
      const windowResults = this.getWindowItemSearchResults(query);

      // SEARCH RESULT
      const searchResults$ = this.getSearchResults$(query, searchConfig);

      const search$ = (windowResults.length
        ? searchResults$.pipe(startWith([] as SdkSearchResultItem[]), map((searchResults) => [...windowResults, ...searchResults]))
        : searchResults$
      ).pipe(
        map((results) => ({ results, source: SearchDataSource.SEARCH }))
      );

      return search$;
    } else if (searchConfig.history && !query?.length) {
      // History
      return this.getSearchHistory$(searchConfig, componentRef).pipe(
        map((searchHistory) => ({ results: searchHistory, source: SearchDataSource.HISTORY }))
      );
    }
    return of({ results: [], source: SearchDataSource.NONE });
  };

  private getWindowItemSearchResults(query: string): FullSearchWindowResultItem[] {
    query = query.toLocaleLowerCase();
    return query.length >= 3
      ? SearchWindowItems.filter((item) => {
        const windowItem = SearchWindowItemMap[item.windowName];
        if (!windowItem) {
          return false;
        }
        return (
          windowItem.windowName.toLocaleLowerCase().includes(query)
          || windowItem.searchTerms?.some((term) => term.toLocaleLowerCase().includes(query))
        );
      })
      : [];
  }

  private getSearchHistory$(searchConfig: SearchConfig, componentRef?: unknown): Observable<SdkSearchResultItem[]> {
    const historyConfig = searchConfig.history;
    if (!historyConfig) {
      return NEVER;
    }

    const historyItems: SearchHistoryItem[] = [];
    const storageKey = getHistoryStorageKey(historyConfig, componentRef);

    if (typeof historyConfig === 'object') {
      historyConfig.predefinedItems?.forEach((item) => historyItems.push(item));
    }

    if (!storageKey) {
      return NEVER;
    }

    return this.getFilteredHistoryStorageData$(searchConfig, storageKey).pipe(
      switchMap((historyItems: SearchHistoryItem[]) => {
        if (!historyItems?.length) {
          return NEVER;
        }

        const classifiedHistoryItems: {
          instruments: ClassifiedInstrument[];
          marketFeeds: number[];
        } = {
          instruments: [],
          marketFeeds: [],
        };

        // @FIXME after Toolkit package update needs scoping to searchConfig.searchType + symbolClassification
        historyItems.forEach((item: SearchHistoryItem) => {
          if (isClassifiedInstrument(item)) {
            classifiedHistoryItems.instruments.push(item);
          } else if (isMarket(item)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            classifiedHistoryItems.marketFeeds.push(item.feed);
          }
        });

        return combineLatest([
          this.getStaticSymbol$(classifiedHistoryItems.instruments, searchConfig),
          this.getStaticFeedInfo$(classifiedHistoryItems.marketFeeds, searchConfig),
        ]).pipe(
          map(([symbols, feedInfo]) => {
            if (!symbols?.length && !feedInfo?.length) {
              return [] as SdkSearchResultItem[];
            }

            // Convert SymbolData and FeedInfo to match SdkSearchResultItem interface
            const convertedSymbolData = symbols.map((symbol) =>
              this.setFields(symbol as unknown as InfrontSDK.SearchResultItem, SdkSearchResultSymbolItemFields)
            );
            const convertedFeedInfo = feedInfo.map((feed) =>
              this.setFields(feed as unknown as InfrontSDK.SearchResultItem, FeedInfoToSdkSearchResultMarketItemMap)
            );

            const orderedSearchHistoryItems: SdkSearchResultItem[] = [];

            historyItems.forEach((item) => {
              if (isInstrument(item)) {
                const symbol = (convertedSymbolData as SdkSymbolSearchResultItem[]).find(
                  (symbol) => symbol.ticker === item.ticker && symbol.feed === item.feed
                );
                if (symbol) {
                  orderedSearchHistoryItems.push(symbol);
                }
              } else if (isMarket(item)) {
                const market = (convertedFeedInfo as SdkMarketSearchResultItem[]).find((feedInfo) => feedInfo.feed === item.feed);
                if (market) {
                  orderedSearchHistoryItems.push(market);
                }
              }
            });

            return orderedSearchHistoryItems;
          })
        );
      })
    );
  }

  private getStorageHistoryData$(storageKey: string): Observable<SearchHistoryItem[] | undefined> {
    if (!storageKey) {
      return NEVER;
    }

    if (!this.searchHistoryDataByKey$[storageKey]) {
      this.searchHistoryDataByKey$[storageKey] = new BehaviorSubject([]);
      // Check searchHistoryData if there's already data for the storageKey
      this.searchHistoryData$
        .pipe(
          tap((searchHistoryData) => {
            if (searchHistoryData?.[storageKey]) {
              this.searchHistoryDataByKey$[storageKey]?.next(searchHistoryData[storageKey]);
            }
          }),
          take(1),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe();
    }

    return this.searchHistoryDataByKey$[storageKey];
  }

  private getFilteredHistoryStorageData$(
    searchConfig: SearchConfig,
    storageKeyOrCompRef: StorageKeyOrComponentRef
  ): Observable<SearchHistoryItem[] | undefined> {
    if (typeof storageKeyOrCompRef === 'object') {
      storageKeyOrCompRef = getHistoryStorageKey(searchConfig.history, storageKeyOrCompRef);
    }
    if (storageKeyOrCompRef && typeof storageKeyOrCompRef === 'string') {
      const searchType = searchConfig.searchType;

      return this.getStorageHistoryData$(storageKeyOrCompRef).pipe(
        map((items) =>
          items?.filter((item) => {
            // Instrument
            if (
              searchType[InfrontSDK.SearchType.Symbol] &&
              isClassifiedInstrument(item) &&
              (!(searchConfig as SearchConfigSymbol)?.searchSubTypes ||
                (searchConfig as SearchConfigSymbol).searchSubTypes?.includes(item.symbolClassification))
            ) {
              return true;
            }
            // Market
            if (searchType[InfrontSDK.SearchType.Market] && isMarket(item)) {
              return true;
            }
            // News @TODO implement in future
            // if (searchType[InfrontSDK.SearchType.News] && isNews(item)) {
            //   return true;
            // }
            return false;
          })
        )
      );
    }
    return NEVER;
  }

  getSearchResults$(query: string, searchConfig: SearchConfig): Observable<SdkSearchResultItem[]> {
    const searchLimit = getSearchLimit();
    const searchOpts: Omit<InfrontSDK.SymbolSearchOptions, 'onData'> = {
      limit: searchConfig.limit && InfrontUtil.getValueWithinMinMax(searchConfig.limit, searchLimit.min, searchLimit.max),
      searchType: searchConfig.searchType,
      parameters: { SearchFreeText: query },
      subscribe: searchConfig?.subscribe || false,
    };

    // @REFACTOR due to WTKAPI-437
    // switch (searchConfig.searchType) {
    //   case InfrontSDK.SearchType.Symbol:
    searchOpts.fields = [
      ...((!!searchConfig.searchType?.[InfrontSDK.SearchType.Symbol] && (searchConfig as SearchConfigSymbol).symbolResultFields) ||
        getDefaultSymbolSearchResultFields()),
      ...getMinimumSymbolSearchResultFields(), // SymbolType, SymbolSubType is required in order to retrieve the SymbolClassification
    ];
    // break;
    // case InfrontSDK.SearchType.Market:
    searchOpts.onFeedScoreAdjustment = (factor, feedInfo) => {

      const feedScoreFactorItem: FeedScoreFactorItem | undefined = searchConfig.feedScoreFactorItems?.find((scoreItem) => {
        return scoreItem.feed === feedInfo.feed || (scoreItem.feedCode != undefined ? scoreItem?.feedCode === feedInfo.feedCode : false);
      });

      if (feedScoreFactorItem != undefined) {
        factor *= feedScoreFactorItem.factor;
      }

      return factor;
    };
    // break;
    //   default:
    // }

    return this.sdkService.getArray$(InfrontSDK.symbolSearch, searchOpts).pipe(
      map((results) =>
        results.map((item: InfrontSDK.SearchResultItem) => {
          switch (item.type) {
            case InfrontSDK.SearchResultItemType.Symbol: {
              return this.setFields(item, SdkSearchResultSymbolItemFields);
            }
            case InfrontSDK.SearchResultItemType.Market: {
              return this.setFields(item, SdkSearchResultMarketItemFields);
            }
            default: {
              return undefined;
            }
          }
        })
      ),
      filterUndefinedArray()
    );
  }

  private setFields = (
    searchResultItem: InfrontSDK.SearchResultItem,
    fieldsObj: typeof SdkSearchResultSymbolItemFields | typeof SdkSearchResultMarketItemFields | typeof FeedInfoToSdkSearchResultMarketItemMap
  ): SdkSearchResultItem => {
    const fields = Object.keys(fieldsObj);
    const item = fields.reduce((acc, field) => {
      let value: unknown;
      if (typeof fieldsObj[field] === 'function') {
        // Get computed value
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        value = fieldsObj[field]?.(searchResultItem);
      } else {
        // Get Field value
        value = searchResultItem?.get(field as InfrontSDK.SearchResultField | InfrontSDK.FeedField);
      }
      if (value !== undefined) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        acc[field] = value;
      }
      return acc;
    }, searchResultItem) as unknown as SdkSymbolSearchResultItem | SdkMarketSearchResultItem;

    if (fieldsObj === SdkSearchResultSymbolItemFields) {
      item.itemType = InfrontSDK.SearchResultItemType.Symbol;
    } else if (fieldsObj === SdkSearchResultMarketItemFields || fieldsObj === FeedInfoToSdkSearchResultMarketItemMap) {
      item.itemType = InfrontSDK.SearchResultItemType.Market;
    }

    return item;
  };

  private getStaticFeedInfo$ = (feeds: number[], searchConfig: SearchConfig): Observable<InfrontSDK.FeedInfo[]> => {
    if (!feeds?.length) {
      return of([]);
    }
    const opts: Omit<InfrontSDK.FeedInfoOptions, 'onData'> = {
      feed: feeds,
      infoType: InfrontSDK.FeedInfoType.MetaData,
      subscribe: searchConfig?.subscribe || false,
    };
    return this.sdkService.getArray$(InfrontSDK.feedInfo, opts, undefined, 'sdkSearchService getStaticFeedInfo') as Observable<InfrontSDK.FeedInfo[]>;
  };

  private getStaticSymbol$ = (instruments: ClassifiedInstrument[], searchConfig: SearchConfig): Observable<InfrontSDK.SymbolData[]> => {
    if (!instruments?.length) {
      return of([]);
    }

    const opts: Partial<InfrontSDK.SymbolDataOptions<InfrontSDK.SymbolId[]>> = {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      id: instruments.map((item: ClassifiedInstrument) => ({ ticker: item.ticker, feed: item.feed })),
      fields: [
        ...((!!searchConfig.searchType?.[InfrontSDK.SearchType.Symbol] && (searchConfig as SearchConfigSymbol).symbolResultFields) ||
          getDefaultSymbolSearchResultFields()),
        ...getMinimumSymbolSearchResultFields(), // SymbolType, SymbolSubType is required in order to retrieve the SymbolClassification
      ] as unknown as InfrontSDK.SymbolField[],
      subscribe: searchConfig?.subscribe || false,
    };
    return this.sdkService.getArray$(InfrontSDK.symbolData, opts, undefined, 'sdkSearchService getStaticSymbol$');
  };

  addItemToSearchHistory(item: SdkSearchResultItem, historyConfig: SearchConfig['history'], componentRef?: unknown): void {
    if (!historyConfig) {
      return;
    }

    const storageKey = getHistoryStorageKey(historyConfig, componentRef);

    if (!storageKey) {
      return;
    }

    this.searchHistoryData$
      .pipe(
        tap((historyData) => {
          historyData = InfrontUtil.deepCopy(historyData) as SearchHistoryData;
          /** transform item into storage suitable data */
          const toBeStoredItem = transformSearchResultItemToHistoryItem(item);
          if (!toBeStoredItem) {
            return;
          }

          /** check if entry exists */
          if (!historyData[storageKey]) {
            historyData[storageKey] = [toBeStoredItem];
          } else {
            /** check for duplicates */
            const duplicateItemIndex = this.getDuplicateHistoryItemIndex(historyData[storageKey], item); // use: item ?
            if (duplicateItemIndex == undefined || duplicateItemIndex < 0) {
              historyData[storageKey].unshift(toBeStoredItem);
              this.removeLimitExceedingItemsFromSearchHistoryStorage(item, historyData[storageKey]);
            } else {
              moveItem(historyData[storageKey], duplicateItemIndex, 0); // item already exists within history
            }
          }
          // update storage
          void this.searchStorage.set('history', historyData as SearchHistoryData & JSONValue);
        }),
        take(1),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe();
  }

  private getItemsOfSameType(item: SdkSearchResultItem, searchHistoryItems: SearchHistoryItem[]): SearchHistoryItem[] {
    if (isClassifiedInstrument(item)) {
      return searchHistoryItems.filter(
        (historyItem) => isClassifiedInstrument(historyItem) && historyItem.symbolClassification === item.symbolClassification
      );
    } else if (isMarket(item)) {
      return searchHistoryItems.filter((historyItem) => isMarket(historyItem) && historyItem.feed === item.feed);
    }

    return [];
  }

  /**
   * Removes items that exceed the search history (scoped to the Type/Classification)
   * in case of a Symbol each distinct SymbolClassification has their own limit of 50
   * Markets/Feeds don't have any classifications and have a general limit of 50
   * @param item The item that we take as a type to kick out exceeding items of the same type (Limit: 50)
   * @param searchHistoryItems
   */
  removeLimitExceedingItemsFromSearchHistoryStorage(item: SdkSearchResultItem, searchHistoryItems: SearchHistoryItem[]): void {
    let itemsOfSameType = this.getItemsOfSameType(item, searchHistoryItems);

    if (itemsOfSameType) {
      while (itemsOfSameType.length > SearchHistoryItemTypeLimit) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const itemToKick = itemsOfSameType[itemsOfSameType?.length - 1];
        const itemToKickIndex = searchHistoryItems.findIndex((historyItem) => historyItem === itemToKick);

        if (itemToKickIndex != undefined && itemToKickIndex > -1) {
          const oldLength = searchHistoryItems.length;
          searchHistoryItems.splice(itemToKickIndex, 1);
          // Check if the item was removed
          if (searchHistoryItems.length === oldLength) {
            // The item was not removed, break the loop
            break;
          }
          // Update itemsOfSameType after removal
          itemsOfSameType = this.getItemsOfSameType(item, searchHistoryItems);
        }
      }
    }
  }

  private getDuplicateHistoryItemIndex(searchHistoryItems: SearchHistoryItem[], item: SearchHistoryItem): number | undefined {
    let typeGuardMatch: typeof isClassifiedInstrument | typeof isMarket;
    const matchProperties: string[] = [];
    if (isClassifiedInstrument(item)) {
      matchProperties.push('ticker', 'feed');
      typeGuardMatch = isClassifiedInstrument;
    } else if (isMarket(item)) {
      matchProperties.push('feed');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      typeGuardMatch = isMarket;
    }

    return searchHistoryItems?.findIndex(
      (historyItem) =>
        typeGuardMatch(historyItem) && matchProperties.filter((prop) => historyItem[prop] === item[prop]).length === matchProperties.length
    );
  }

  /*
  destroySearchHistory(searchConfig: SearchConfig, storageKeyOrCompRef: StorageKeyOrComponentRef): void {

    let storageKey: string | undefined;
    if (storageKeyOrCompRef) {
      if (typeof storageKeyOrCompRef === 'string') {
        storageKey = storageKeyOrCompRef;
      } else {
        storageKey = getHistoryStorageKey(searchConfig.history, storageKeyOrCompRef);
      }
    }

    if (!storageKey) {
      return;
    }

    if (typeof searchConfig.history === 'object' && searchConfig.history.historyType === HistoryType.INSTANCE) {
      const searchHistoryStorageData = this.searchStorage.get('history');
      if (!searchHistoryStorageData?.[storageKey]) {
        return;
      }
      delete searchHistoryStorageData[storageKey];
      void this.searchStorage.set('history', searchHistoryStorageData as SearchHistoryData & JSONValue);
      // clean up memory, complete Subject and delete
      if (this.searchHistoryDataByKey$[storageKey]) {
        this.searchHistoryDataByKey$[storageKey].complete();
        delete this.searchHistoryDataByKey$[storageKey];
      }
    }
  }
  */
}
