<div class="wt-circle-score" title="ESG score"
  *ngIf="esgClassifications.includes(classification) && score != undefined">
  <svg width="100%" height="100%" viewBox="0 0 42 42" class="donut" style="height:25px; width:25px; margin-top:3px">
    <!-- center hole -->
    <circle cx="21" cy="21" r="15.91549430918954" fill="transparent"></circle>
    <!-- donut-ring -->
    <circle cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke-width="4"></circle>
    <!-- value-segment -->
    <circle cx="21" cy="21" r="15.91549430918954" fill="transparent" [class]="'wt-circle-score--' + color"
      stroke-width="3.5"
      style.stroke-dasharray="{{score}}, {{100-score}}" stroke-dashoffset="25"></circle>
    <text x="50%" y="54%" class="wt-circle-score__score" fill="#fff" text-anchor="middle" dominant-baseline="middle">
      {{score}}
    </text>
  </svg>
</div>