import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { InfrontUtil } from '@infront/sdk';
import { Subscription, take, tap } from 'rxjs';

import { translator } from '../util/locale';
import { structuresAreEqual } from '../util/equality';
import { ConfirmDialogService } from './confirm-dialog.service';
import { ConfirmDialogData } from '../shared/confirm-dialog/confirm-dialog.component';
import { getNestedProp } from '../util/object';

type SecureMatDialogOpenResult<TComponentInstance, VMatDialogResult = unknown> = {
  dialogRef: MatDialogRef<TComponentInstance, VMatDialogResult>,
  subscriptions: { [Subcription: string]: Subscription | undefined; },
};

@Injectable({
  providedIn: 'root'
})
export class SecureMatDialogService {
  private readonly confirmDialogService = inject(ConfirmDialogService);
  private readonly xlat = translator();
  readonly matDialog = inject(MatDialog);

  open<TComponentInstance, UConfigData extends object = object, VMatDialogResult = unknown>(
    initialCompareDataProp: string, // keyof UConfigData,
    compareComponentProp: string, // keyof MatDialogRef<TComponentInstance, VMatDialogResult>['componentInstance'],
    confirmDialogData: ConfirmDialogData,
    matDialogComponent: ComponentType<TComponentInstance>,
    matDialogConfig: MatDialogConfig<UConfigData> = {},
  ): SecureMatDialogOpenResult<TComponentInstance, VMatDialogResult> {
    // Save the state initially for later deep comparison
    const deepInitialState = (matDialogConfig?.data
      ? InfrontUtil.deepCopy(getNestedProp(matDialogConfig.data, initialCompareDataProp)) as unknown
      : undefined
    );

    matDialogConfig.disableClose = true; // Prevent automatic close handling of MatDialog
    const dialogRef = this.matDialog.open(matDialogComponent, matDialogConfig);

    const result: SecureMatDialogOpenResult<TComponentInstance, VMatDialogResult> = { dialogRef, subscriptions: {} };
    const resultSubs = result.subscriptions;

    const closeDialogOnEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        dialogRef.close();
      }
    };

    document.addEventListener('keydown', closeDialogOnEsc);

    resultSubs.clickOutsideSub = dialogRef.backdropClick().subscribe(() => {
      if (structuresAreEqual(deepInitialState, getNestedProp(dialogRef.componentInstance, compareComponentProp))) {
        dialogRef.close();
        return;
      }

      resultSubs.confirmDialogSub = this.confirmDialogService.confirmDialog$({
        ...confirmDialogData,
        header: confirmDialogData.header ? this.xlat(confirmDialogData.header) : '',
        message: confirmDialogData.message ? this.xlat(confirmDialogData.message) : '',
        okButtonLabel: confirmDialogData.okButtonLabel ? this.xlat(confirmDialogData.okButtonLabel) : '',
        cancelButtonLabel: confirmDialogData.cancelButtonLabel ? this.xlat(confirmDialogData.cancelButtonLabel) : '',
      }).pipe(
        take(1),
        tap(close => close && dialogRef.close()),
      ).subscribe();
    });

    resultSubs.closeSub = dialogRef.afterClosed().subscribe(() => {
      document.removeEventListener('keydown', closeDialogOnEsc);
      resultSubs.confirmDialogSub?.unsubscribe();
      resultSubs.clickOutsideSub?.unsubscribe();
      resultSubs.closeSub?.unsubscribe();
    });

    return result;
  }

}
