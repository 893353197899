<div class="wt-flex-row wt-flex-collapse" *ngIf="realms$ | async as realms">
  <div class="wt-flex-column wt-flex-large-3 wt-admin-dialog__realms">
    <h3>{{ 'ADMIN_DIALOG.REALMS_LIST.TITLE' | translate }}</h3>

    <div class="wt-admin-dialog__realms-list">
      <ul class="wt-list">
        <li class="wt-admin-dialog__realms-list-item" *ngFor="let item of realms"
          [ngClass]="{ 'wt-admin-dialog__realms-list-item--selected': item === selectedRealmInfo }"
          (click)="showRealmInfo(item)" mat-menu-item>
          <div class="wt-flex-row wt-flex-collapse">
            <div class="wt-flex-column">
              {{ item.displayName$ | async }}
            </div>

            <div class="wt-flex-column wt-flex-shrink">
              <mat-icon *ngIf="!item.canDelete" fontIcon="lock"></mat-icon>

              <button *ngIf="item.canDelete" type="button" class="wt-button wt-button--icon"
                (click)="deleteRealm(item)" title="{{ 'ADMIN_DIALOG.REALM_DETAILS.REMOVE' | translate }}">
                <mat-icon fontIcon="close"></mat-icon>
              </button>
            </div>
          </div>
        </li>

        <li *ngIf="canAddRealm" class="wt-admin-dialog__realms-list-item" mat-menu-item (click)="addRealmModal($event)">
          <button type="button" class="wt-button wt-button--icon">
            <mat-icon class="wt-admin-dialog__realms-list-new-item-icon" fontIcon="add"></mat-icon> {{
            'ADMIN_DIALOG.REALMS_LIST.ADD' | translate }}</button>
        </li>
      </ul>
    </div>
  </div>

  <div class="wt-flex-column wt-admin-dialog__details">
    <ng-container *ngIf="selectedRealmInfo as realmInfo">
      <mat-tab-group animationDuration="200ms">
        <mat-tab [label]="'ADMIN_DIALOG.DEFAULT_DASHBOARDS.TAB_TITLE' | translate">
          <wt-default-dashboards-panel [realm]="realmInfo.realm"></wt-default-dashboards-panel>
        </mat-tab>

        <mat-tab [label]="'ADMIN_DIALOG.SIDEBAR.TAB_TITLE' | translate">
          <wt-sidebar-instrument-lists-panel [realm]="realmInfo.realm"></wt-sidebar-instrument-lists-panel>
        </mat-tab>

        <mat-tab [label]="'ADMIN_DIALOG.DEFAULT_SETTINGS.TITLE' | translate">
          <h3>{{ 'ADMIN_DIALOG.DEFAULT_SETTINGS.TITLE' | translate }}</h3>

          <div class="wt-admin-dialog__details-tab-content">
            <wt-general-settings [userSettingsService]="selectedRealmSettingsService"></wt-general-settings>
          </div>
        </mat-tab>

        <mat-tab [label]="'ADMIN_DIALOG.BRANDING.TAB_TITLE' | translate">
          <wt-branding-panel [realm]="realmInfo.realm"></wt-branding-panel>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </div>
</div>
