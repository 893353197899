import { isDevMode } from '@angular/core';
import { Grid } from '../../state-model/grid.model';
import {
  CalendarWidgetDefaults,
  HistoryWidgetDefaults,
  NewsWidgetDefaults,
  TradesWidgetDefaults,
} from '../../state-model/widget.defaults';
import { HistoryWidget, NewsWidget, TradesWidget, Widget } from '../../state-model/widget.model';
import { DashboardWindow } from '../../state-model/window.model';
import { dummyInstrument, readonlyWindowParams } from './templates.model';
import { validateTemplate } from './validation';

// DASHBOARD TEMPLATE: STOCK
const firstColumnWidth = 15;
const secondColumnWidth = 20;
const thirdColumnWidth = 15;

const headerHeight = 1;

// col1
const instrumentOverviewHeight = 13;
const orderbookHeight = 7;
const tradesHeight = 11;

// col2
const chartHeight = 14;
const newsHeight = 17;

// col3
const performanceHeight = 11;
const calendarHeight = 8;
const historyheight = 12;

export const overviewDashboardWindows: DashboardWindow[] = [
  // first column
  {
    id: 'col1-overview',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'InstrumentOverview',
    cols: firstColumnWidth,
    rows: instrumentOverviewHeight,
    y: headerHeight,
    x: 0,
    ...readonlyWindowParams,
  },
  {
    id: 'col1-orderbook',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'Orderbook',
    cols: firstColumnWidth,
    rows: orderbookHeight,
    y: headerHeight + instrumentOverviewHeight,
    x: 0,
    ...readonlyWindowParams,
  },
  {
    id: 'col1-trades',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'Trades',
    cols: firstColumnWidth,
    rows: tradesHeight,
    y: headerHeight + instrumentOverviewHeight + orderbookHeight,
    x: 0,
    ...readonlyWindowParams,
  },
  // second column (to 1280px)
  {
    id: 'col2-chart',
    linkChannel: 'None',
    name: 'ChartWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'Chart',
    cols: secondColumnWidth,
    rows: chartHeight,
    y: headerHeight,
    x: firstColumnWidth,
    ...readonlyWindowParams,
  },
  {
    id: 'col2-news',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'News',
    cols: secondColumnWidth,
    rows: newsHeight,
    y: headerHeight + chartHeight,
    x: firstColumnWidth,
    ...readonlyWindowParams,
  },
  // third column
  {
    id: 'col3-performance',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'Performance',
    cols: thirdColumnWidth,
    rows: performanceHeight,
    y: headerHeight + calendarHeight,
    x: firstColumnWidth + secondColumnWidth,
    ...readonlyWindowParams,
  },
  {
    id: 'col3-calendar',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'Calendar',
    cols: thirdColumnWidth,
    rows: calendarHeight,
    y: headerHeight,
    x: firstColumnWidth + secondColumnWidth,
    ...readonlyWindowParams,
  },

  {
    id: 'col3-history',
    linkChannel: 'None',
    name: 'InstrumentWindow',
    settings: { instrument: { ...dummyInstrument } },
    dashboardId: '0',
    selectedWidgetName: 'History',
    cols: thirdColumnWidth,
    rows: historyheight,
    y: headerHeight + calendarHeight + performanceHeight,
    x: firstColumnWidth + secondColumnWidth,
    ...readonlyWindowParams,
  },
];

export const overviewDashboardWidgets: Widget[] = [
  // Instrument Dashboard
  {
    id: 'w-overview',
    dashboardId: '0',
    name: 'InstrumentOverview',
    settings: { selectedPeriodIndex: 4 },
    windowId: 'col1-overview',
  },
  {
    id: 'w-orderbook',
    dashboardId: '0',
    name: 'Orderbook',
    settings: { showTopBar: false, showCompactView: true, yellowTopLevel: false, showOrderAmount: true, },
    windowId: 'col1-orderbook',
  },
  {
    id: 'w-trades',
    dashboardId: '0',
    name: 'Trades',
    settings: TradesWidgetDefaults.settings,
    gridColumnSize: 'sizeToFit',
    windowId: 'col1-trades',
  } as TradesWidget,
  {
    id: 'w-news',
    dashboardId: '0',
    name: 'News',
    settings: NewsWidgetDefaults.settings,
    //lifespan: 'window',
    windowId: 'col2-news',
  } as NewsWidget,
  {
    id: 'w-performance',
    dashboardId: '0',
    name: 'Performance',
    settings: { showChart: false, showDividendsLine: true },
    windowId: 'col3-performance',
  },
  {
    id: 'w-calendar',
    dashboardId: '0',
    name: 'Calendar',
    settings: CalendarWidgetDefaults.settings,
    // lifespan: 'window',
    windowId: 'col3-calendar',
  },
  {
    id: 'w-history',
    dashboardId: '0',
    name: 'History',
    settings: HistoryWidgetDefaults.settings,
    gridColumnSize: 'sizeToFit',
    windowId: 'col3-history',
  } as HistoryWidget,
  // Chart window
  {
    id: 'w-chart',
    dashboardId: '0',
    name: 'Chart',
    settings: {
      chartOptions: {
        defaultPeriod: '1Y',
        // bottomToolbar: {},
      },
    },
    windowId: 'col2-chart',
  },
];

export const overviewDashboardGrids: Grid[] = [
  // sub for TradesWidget, widgetId 4
  {
    id: 'g-trades',
    dashboardId: '0',
    name: 'trades',
    parentId: 'w-trades',
  },
  // 3 x sub for HistoryWidget, widgetId 12
  {
    id: 'g-history-trades',
    dashboardId: '0',
    name: 'historyTrades',
    parentId: 'w-history',
  },
  {
    id: 'g-splits',
    dashboardId: '0',
    name: 'historySplits',
    parentId: 'w-history',
  },
  {
    id: 'g-dividends',
    dashboardId: '0',
    name: 'historyDividends',
    parentId: 'w-history',
  },
];

if (isDevMode()) {
  validateTemplate('forex-template', overviewDashboardWindows, overviewDashboardWidgets, overviewDashboardGrids);
}
