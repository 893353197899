// AUTO-GENERATED CODE
// Do not edit, your code will be overwritten!

export const svgIcons = [
  { name: 'add_alert', path: 'assets/svg/add_alert.svg' },
  { name: 'add_box', path: 'assets/svg/add_box.svg' },
  { name: 'column', path: 'assets/svg/column.svg' },
  { name: 'copy', path: 'assets/svg/copy.svg' },
  { name: 'delete', path: 'assets/svg/delete.svg' },
  { name: 'edit', path: 'assets/svg/edit.svg' },
  { name: 'flag', path: 'assets/svg/flag.svg' },
  { name: 'manage_search', path: 'assets/svg/manage_search.svg' },
  { name: 'notification_add', path: 'assets/svg/notification_add.svg' },
  { name: 'table_rows', path: 'assets/svg/table_rows.svg' },
  { name: 'view_column', path: 'assets/svg/view_column.svg' },
  { name: 'visibility', path: 'assets/svg/visibility.svg' },
];