import { forwardRef, Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
  useClass: forwardRef(() => DefaultLocaleIdProvider),
})
export abstract class LocaleIdProvider {
  public abstract readonly localeId: string;
  public abstract readonly localeId$: Observable<string>;
}

@Injectable({ providedIn: 'root' })
export class DefaultLocaleIdProvider implements LocaleIdProvider {
  constructor(
    @Inject(LOCALE_ID)
    public readonly localeId: string,
  ) { }

  public localeId$ = of(this.localeId);
}
