/**
 * Dashboard
 * Service that persists dashboard configurations from different applications. The content of the dashboards can also be stored. An user/group/admin permissioning helps to manage dashboards on different access levels.
 *
 * OpenAPI spec version: 1.1.0
 * Contact: swt-backend@vwd365.onmicrosoft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DashboardData } from '../model/dashboardData';
import { DashboardDataPost } from '../model/dashboardDataPost';
import { DashboardDatas } from '../model/dashboardDatas';
import { ModelDelete } from '../model/modelDelete';
import { Post } from '../model/post';
import { ProblemResponse } from '../model/problemResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class DashboardDatasService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * get all dashboard_datas (history) for one dashboard
     * Returns one or more dashboard_datas. History is actually maximal 10 entries long.
     * @param dashboard_id the dashboard_id
     * @param fields 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardDatasGet(dashboard_id: string, fields?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<DashboardDatas>;
    public dashboardDatasGet(dashboard_id: string, fields?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DashboardDatas>>;
    public dashboardDatasGet(dashboard_id: string, fields?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DashboardDatas>>;
    public dashboardDatasGet(dashboard_id: string, fields?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dashboard_id === null || dashboard_id === undefined) {
            throw new Error('Required parameter dashboard_id was null or undefined when calling dashboardDatasGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dashboard_id !== undefined && dashboard_id !== null) {
            queryParameters = queryParameters.set('dashboard_id', <any>dashboard_id);
        }
        if (fields) {
            fields.forEach((element) => {
                queryParameters = queryParameters.append('fields', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DashboardDatas>('get',`${this.basePath}/dashboard-datas`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get dashboard_data by id
     * 
     * @param id the id
     * @param fields 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardDatasIdGet(id: string, fields?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<DashboardData>;
    public dashboardDatasIdGet(id: string, fields?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DashboardData>>;
    public dashboardDatasIdGet(id: string, fields?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DashboardData>>;
    public dashboardDatasIdGet(id: string, fields?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dashboardDatasIdGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fields) {
            fields.forEach((element) => {
                queryParameters = queryParameters.append('fields', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DashboardData>('get',`${this.basePath}/dashboard-datas/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * add a new dashboard_data
     * * stores a new dashboard_data * updates dashboard with new created data_id * if parameter is_draft is true the last entry is replaced, when it&#x27;s status is_draft is true 
     * @param body dashboard_data-object that needs to be added
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardDatasPost(body: DashboardDataPost, observe?: 'body', reportProgress?: boolean): Observable<Post>;
    public dashboardDatasPost(body: DashboardDataPost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Post>>;
    public dashboardDatasPost(body: DashboardDataPost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Post>>;
    public dashboardDatasPost(body: DashboardDataPost, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling dashboardDatasPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Post>('post',`${this.basePath}/dashboard-datas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get actual dashboard_data for a dashboard by dashboard id
     * 
     * @param id the id
     * @param fields 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardsIdDatasCurrentGet(id: string, fields?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<DashboardData>;
    public dashboardsIdDatasCurrentGet(id: string, fields?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DashboardData>>;
    public dashboardsIdDatasCurrentGet(id: string, fields?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DashboardData>>;
    public dashboardsIdDatasCurrentGet(id: string, fields?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dashboardsIdDatasCurrentGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fields) {
            fields.forEach((element) => {
                queryParameters = queryParameters.append('fields', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DashboardData>('get',`${this.basePath}/dashboards/${encodeURIComponent(String(id))}/datas/current`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete last draft and revert dashboard_data to the last non-draft state.
     * 
     * @param id the id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardsIdDatasDraftDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<ModelDelete>;
    public dashboardsIdDatasDraftDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelDelete>>;
    public dashboardsIdDatasDraftDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelDelete>>;
    public dashboardsIdDatasDraftDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dashboardsIdDatasDraftDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ModelDelete>('delete',`${this.basePath}/dashboards/${encodeURIComponent(String(id))}/datas/draft`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
