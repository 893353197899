import { InfrontSDK } from '@infront/sdk';
import { GridOptions } from 'ag-grid-community';

import { Column } from '../../shared/grid/columns.model';
import { NewsType } from '../../state-model/window.model';

// returned items newsHeadlines$ are instances of Infront.HeadlineItem
// we should use that type/class in the future and just extend it with our special properties
// export interface WTHeadlineItem extends Infront.HeadlineItem {
//   feedLongName: string;
//   feedShortName: string;
// }

export interface Vm {
  headlines: NewsHeadline[];
  columns: Column[];
  newsType: NewsType;
  options: GridOptions;
}

export interface NewsHeadline {
  dateTime: Date;
  headline: string;
  cellHeadline: string;
  feedShortName: string;
  feedLongName: string;
  feed?: number;
  id: string;
  isResearchNews?: boolean;
  isFlash?: boolean;
  symbols: InfrontSDK.SymbolId[];
  url: string;
  hasBody: boolean;
}

export const NewsChunkSize = 100;

export interface NewsSource {
  source: InfrontSDK.SymbolId[] | number[];
  sourceType: NewsSourceType;
}

export type NewsSourceType = 'instrument' | 'instruments' | 'watchlist' | 'feed' | 'portfolio';

export const HeadlineIcons: Readonly<{ [headlineType: string]: string }> = {
  url: '<span class="material-icons mat-icon-headline">launch</span>',
} as const;
