import { inject, Injectable } from '@angular/core';
import { InfrontUtil } from '@infront/sdk';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, share, shareReplay, tap, withLatestFrom } from 'rxjs/operators';

import { LogService } from '@vwd/ngx-logging';
import { mockupState } from '../dashboard/mockup';
import { Dashboard } from '../state-model/dashboard.model';
import { Grid } from '../state-model/grid.model';
import { Widget } from '../state-model/widget.model';
import { DashboardWindow } from '../state-model/window.model';

// todo: get initialstate from backend or localStorage
export const emptyState: State = {
  dashboards: [],
  windows: [],
  widgets: [],
  grids: [],
  dashboardsReady: false,
};

export interface State {
  dashboards: Dashboard[];
  windows: DashboardWindow[];
  widgets: Widget[];
  grids: Grid[];
  dashboardsReady: boolean;
  stateType?: StateType;
}

// User: state changes made by user actions
// Trigger: state changes triggered from code, usually as a consequence of user actions
// Hidden: state changes that are user actions but not supposed to be part of the state history for each action, example widget
// settings made while in new Window dialog
// Initial: is the initial state build up by retrieving from WT5 remote storage and Watchlists and an empty start state for conveniance

export type StateType = 'User' | 'Trigger' | 'Hidden' | 'Initial';

export const useMockupData = false; // toggle here to to load mockup data instead of remote data

export const StateCategoryNames = ['dashboards', 'windows', 'widgets', 'grids'] as const;
export type StateItem = Dashboard | DashboardWindow | Widget | Grid;

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private readonly logger = inject(LogService).openLogger('services/state');

  private readonly initialState = useMockupData ? mockupState : emptyState;
  // todo: Array of state works as a simple solution for storing state changes but can use a lot of memory, determine which strategy to use to make this smaller
  private readonly stateHistoryAction = new BehaviorSubject<Array<State>>([{ ...this.initialState, stateType: 'Initial' } as State]); // todo: cheating this is not a full state
  private get stateHistory() {
    return this.stateHistoryAction.getValue();
  }

  private readonly stateIndexAction = new BehaviorSubject<number>(0);
  private get stateIndex() {
    return this.stateIndexAction.getValue();
  }

  private readonly partialStateUpdateAction = new Subject<Partial<State>>();
  readonly partialStateUpdate$ = this.partialStateUpdateAction.asObservable();

  readonly state$ = this.stateIndexAction.pipe(
    withLatestFrom(this.stateHistoryAction),
    map(([index, stateHistory]) => {
      // deepCopy to decouple state from history states!

      return InfrontUtil.deepCopy(stateHistory[index]) as State;
    }),
    tap(state => {
      this.logger.log('state$ updated', state);
    }),
    shareReplay(1)
  );

  get state(): State {
    return this.stateHistory[this.stateIndex]; // we don't use state history right now and deepcopy here is very expensive.

    // return JSON.parse(JSON.stringify(this.stateHistory[this.stateIndex])) as State;
    // deep copy needed, otherwise gridster will overwrite state properties by ref when altering the window data
    // we need to make those changes ourselves so they are written to the correct state in the statehistory array.
  }

  select<K>(mapFn: (state: State) => K): Observable<K> {
    return this.state$.pipe(
      map((state: State) => {
        return mapFn(state);
      })
    );
  }

  selectWhen<K>(mapFn: (state: State) => K, conditionFn: (state: State) => boolean): Observable<K> {
    return this.state$.pipe(
      filter(conditionFn),
      map(mapFn),
    );
  }

  selectLast<K>(mapFn: (state: State) => K): Observable<K> {
    return this.stateHistoryAction.pipe(
      map((stateHistory: State[]) => {
        const i = this.lastUserStateIndex();
        // deepCopy to decouple state from history states!
        return mapFn(InfrontUtil.deepCopy(stateHistory[i]) as State);
      })
    );
  }

  // need a special path for default state
  setState(newState: Partial<State>, stateType: StateType): void {
    const state = {
      ...this.state,
      ...newState,
      stateType,
    } as State;

    if (JSON.stringify(state) === JSON.stringify(this.stateHistory[this.stateIndex])) {
      return;
    }

    // FIXME: deepCopy needs to die

    // deepCopy to decouple new state from history states
    this.partialStateUpdateAction.next(InfrontUtil.deepCopy(newState) as Partial<State>);
    this.logger.log('setState', state);
    const isIndexLast = this.stateIndex + 1 === this.stateHistory.length;
    const newStateHistory = isIndexLast ? [...this.stateHistory, state] : [...this.stateHistory.filter((state, i) => i <= this.stateIndex), state];
    const lastUserStateIndex = this.lastUserStateIndex();
    if (newStateHistory.length > 1) {
      newStateHistory.splice(0, lastUserStateIndex + 1); // remove all states before last user state to save memory
    }

    // if state is changed when index is not on the last history, all items in the history array above the current index gets removed
    this.stateHistoryAction.next(newStateHistory);
    // TODO: maybe we need to deepCopy here, too!
    // this.stateHistoryAction.next(InfrontUtil.deepCopy(newStateHistory) as State[]);
    this.stateIndexAction.next(newStateHistory.length - 1);
  }

  readonly detailedStateChange$ = combineLatest([
    this.select((state) => {
      return state.stateType !== 'Hidden' ? state : undefined;
    }),
    this.selectLast((lastState: State) => {
      return lastState ? lastState : ({} as State);
    }),
  ])
    .pipe(
      withLatestFrom(this.partialStateUpdate$),
      filter(([[next], lastStateChange]) => !!next),
      map(([[next, prev], lastStateChange]) => ({ next: next as State, prev, lastStateChange }))
    )
    .pipe(share());

  private lastUserStateIndex = (): number => {
    //todo: improve algo with something cleaner
    let lastUserStateIndex = this.stateHistory
      .filter((state, index) => index <= this.stateIndex)
      .reduce((acc, state, index) => (!['Trigger', 'Hidden'].includes(state.stateType ?? 'none') ? index : acc), -1);
    while (lastUserStateIndex - 1 > 0 && this.stateHistory[lastUserStateIndex - 1].stateType === 'Hidden') {
      lastUserStateIndex--;
    }
    return lastUserStateIndex === 0 ? 0 : lastUserStateIndex--;
  };



  //debug
  readonly debugHistory$ = this.stateHistoryAction.asObservable();
  readonly debugIndex$ = this.stateIndexAction.asObservable();


  // disabled undo/redo

  // undo = (): void => {
  //   const lastUserStateIndex = this.lastUserStateIndex() - 1;
  //   if (lastUserStateIndex > -1) {
  //     this.stateIndexAction.next(lastUserStateIndex);
  //   }
  // };

  // redo = (): void => {
  //   // move forward to next user state and then move forward all triggered states found
  //   let nextUserStateIndex =
  //     this.stateHistory.filter((state, index) => index > this.stateIndex).findIndex((state) => state.stateType === 'User') + this.stateIndex + 1;
  //   while (nextUserStateIndex + 1 < this.stateHistory.length && this.stateHistory[nextUserStateIndex + 1].stateType === 'Trigger') {
  //     nextUserStateIndex++;
  //   }
  //   this.stateIndexAction.next(nextUserStateIndex);
  // };

  // readonly canUndo$ = this.stateIndexAction.pipe(
  //   map((stateIndex) => this.stateHistory.filter((state, index) => index < stateIndex && state.stateType === 'User').length > 0)
  // );
  // readonly canRedo$ = this.stateIndexAction.pipe(
  //   map((stateIndex) => this.stateHistory.filter((state, index) => index > stateIndex && state.stateType === 'User').length > 0)
  // );
}
