import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ITooltipParams } from 'ag-grid-community';

import { PerformanceItem } from '../../../widgets/performance/performance.model';

@Component({
  selector: 'wt-performance-bar-tooltip',
  template: `
    <div class="wt-performance-bar__tooltip">
      <h3 *ngIf="isHeader && value">{{ value }}</h3>

      <ul class="wt-list" *ngIf="!isHeader && item">
        <li>High {{ item.period }}: {{ item.high }}</li>
        <li>Low {{ item.period }}: {{ item.low }}</li>
        <li>Start {{ item.period }}: {{ item.open }}</li>
        <li>Last {{ item.period }}: {{ item.close }}</li>
      </ul>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PerformanceBarTooltipComponent {
  item: PerformanceItem | undefined;
  value: string;
  isHeader: boolean;

  agInit(params: { color: string } & ITooltipParams): void {
    this.isHeader = !params.node || !('rowIndex' in params.node);
    this.value = (params.value as string) ?? '';
    this.item = !this.isHeader
      ? (params.api.getDisplayedRowAtIndex(params.node!.rowIndex as number)?.data as PerformanceItem)
      : undefined;
  }
}
