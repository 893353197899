import { InfrontSDK } from '@infront/sdk';
import { GridsterItem } from 'angular-gridster2';

import { arrayOfAll } from '../util/array';
import type { ObjectToUnion, PickByType, UnionObjectsToIntersection } from '../util/types';
import type { MarketOverviewTemplateName } from '../widgets/market-overview/market-overview.model';
import type { OrderCategory } from '../widgets/portfolio-orders/portfolio-orders.model';
import type { TradingClassification } from '../widgets/portfolio-positions/portfolio-positions.model';
import type { WidgetName, WidgetNameToType } from './widget.model';


export const WindowNameComponentMap = {
  ChartMini: 'Chart',
  FocusMini: 'Focus',
} as const;

// todo: consider to map multiple sets of widgets per window to centralize rules based on symbolClassifications
export const WindowWidgetsMap: {
  [window in WindowName]: [WidgetName, ...WidgetName[]] // Minimum 1 Widget(-Name) per Window(-Name)
} = {
  InstrumentWindow: ['InstrumentOverview', 'Orderbook', 'Exposure', 'Constituents', 'Performance', 'Calendar', 'News', 'Trades', 'History', 'Esg'],
  MarketWindow: ['Lists', 'Ranking', 'BiggestMovers'],
  MarketOverviewWindow: ['MarketOverview'],
  WatchlistWindow: ['Watchlist', 'News', 'Calendar'],
  NewsWindow: ['News'],
  OrderbookWindow: ['Orderbook'],
  ChartWindow: ['Chart'],
  ChartMiniWindow: ['ChartMini'],
  FocusWindow: ['Focus'],
  FocusMiniWindow: ['FocusMini'],
  AlertLogWindow: ['AlertLog'],
  CalendarWindow: ['Calendar'],
  InstrumentHeaderWindow: ['InstrumentHeader'],
  PortfolioPositionsWindow: ['PortfolioPositions'],
  PortfolioOrdersWindow: ['PortfolioOrders'],
  PortfolioTradesWindow: ['PortfolioTrades'],
  PortfolioHeaderWindow: ['PortfolioHeader'],
  PositionsSummaryWindow: ['PositionsSummary'],
  PositionsEventsWindow: ['PositionsEvents'],
  PositionsExposureWindow: ['PositionsExposure'],
  NetTradesWindow: ['NetTrades'],
  UserlistWindow: ['Userlist'],
  FundScreenerWindow: ['FundScreener'],
  CompanyDataWindow: ['CompanyData'],
  CompanyInformationWindow: ['CompanyInformation'],
  TopShareholdersWindow: ['TopShareholders'],
  NotFoundWindow: ['NotFound'],
  ChainsWindow: ['Chains']
};

// header types
type RemoveWindowSuffix<T extends string> = T extends `${infer Prefix}Window` ? Prefix : never;
type LowercaseFirstLetter<T extends string> = T extends `${infer First}${infer Rest}` ? `${Uncapitalize<First>}${Rest}` : T;

type InferredWindowHeaderType = LowercaseFirstLetter<RemoveWindowSuffix<WindowName>>;

export const GenericHeaders = ['titleAndContextMenu', 'titleWithBottomBorder', 'title', 'none'] as const;

export const DefaultHeaderForLockedDashboards = 'titleWithBottomBorder' as const;

// only add custom window header types here if you want to use a type that does not match a window name (see InferredWindowHeaderType)
export type WindowHeaderType = InferredWindowHeaderType | typeof GenericHeaders[number];

// using a map since these are consts that does not need to go out to the backend
// current priority for selecting header is 1. Header defined in WindowHeadersMap 2. Regular dashboards: Header inferred from WindowName, locked dashboards: DefaultHeaderForLockedDashboards

export const WindowHeadersMap: Partial<{ [key in WindowName]: { default: WindowHeaderType, locked?: WindowHeaderType } }> = {
  OrderbookWindow: { default: 'instrument' },
  ChainsWindow: { default: 'chains' },
  ChartWindow: { default: 'instrument', locked: 'none' },
  ChartMiniWindow: { default: 'instrument', locked: 'none' },
  FocusWindow: { default: 'instrument' },
  FocusMiniWindow: { default: 'instrument' },
  PortfolioPositionsWindow: { default: 'titleAndContextMenu', locked: 'titleAndContextMenu' },
  PortfolioOrdersWindow: { default: 'title' },
  PortfolioTradesWindow: { default: 'title' },
  InstrumentHeaderWindow: { default: 'none', locked: 'none' },
  PortfolioHeaderWindow: { default: 'none', locked: 'none' },
  PositionsSummaryWindow: { default: 'title' },
  PositionsEventsWindow: { default: 'title' },
  PositionsExposureWindow: { default: 'title' },
  CompanyDataWindow: { default: 'instrument' },
  CompanyInformationWindow: { default: 'instrument' },
  TopShareholdersWindow: { default: 'instrument' },
  NotFoundWindow: { default: 'none' },
} as const;



// for use with the store to update part of Window
export type PartialDashboardWindow<T extends DashboardWindow = DashboardWindow> = Omit<Partial<T>, 'id' | 'dashboardId'>;

// add as needed, original gridsterItem is not typesafe with [propName: string]: any;
export type StrictGridsterItem = Pick<GridsterItem,
  'x'
  | 'y'
  | 'rows'
  | 'cols'
  | 'maxItemRows'
  | 'minItemRows'
  | 'maxItemCols'
  | 'minItemCols'
  | 'layerIndex'
  | 'resizeEnabled'
  | 'dragEnabled'
>;

export interface DashboardWindow extends StrictGridsterItem {
  id: string;
  created?: number;
  dashboardId: string;
  name: WindowName;
  // selectedWidgetName is the identifier of which widget is selected in the window
  // todo: should be optional and we auto pick the first
  selectedWidgetName: WidgetName;
  linkChannel: LinkChannel;
  settings?: object;
  widgetSettingsOverrides?: {
    [Name in WidgetName]?: WidgetNameToType<Name>['settings'];
  };
  // label allows to be able to override name in headers
  label?: string;
  tag?: string; // convenience property
  // canSetLinkedInstrument set to true allow the windows grid to set the linked instrument
  canSetLinkedInstrument?: boolean;
}

// fill in the unique properties for each window type as the requirements progress
export interface InstrumentWindow extends DashboardWindow {
  name: 'InstrumentWindow';
  settings: {
    instrument: Instrument;
  };
}

export interface MarketWindow extends DashboardWindow {
  name: 'MarketWindow';
  settings: {
    feed: number;
  };
}

export interface MarketOverviewWindow extends DashboardWindow {
  name: 'MarketOverviewWindow';
  settings: {
    template: MarketOverviewTemplateName;
  };
}

export interface WatchlistWindow extends DashboardWindow {
  name: 'WatchlistWindow';
  settings: {
    selectedWatchlist: string | undefined;
  };
}

export function isWatchlistWindow(window: DashboardWindow): window is WatchlistWindow {
  return window.name === 'WatchlistWindow';
}

export interface NewsWindow extends DashboardWindow {
  name: 'NewsWindow';
  settings: {
    newsType: NewsType;
    instrument?: Instrument; // NewsType: 'Instrument'
    feeds?: number[]; // NewsType: 'Country'
    selectedWatchlist?: string; // NewsType: 'Watchlist'
  };
}

export interface OrderbookWindow extends DashboardWindow {
  name: 'OrderbookWindow';
  settings: {
    instrument: Instrument;
  };
}

export interface ChartWindow extends DashboardWindow {
  name: 'ChartWindow';
  settings: {
    instrument: Instrument;
  };
}

export interface ChartMiniWindow extends DashboardWindow {
  name: 'ChartMiniWindow';
  settings: {
    instrument: Instrument;
  };
}

export interface FocusWindow extends DashboardWindow {
  name: 'FocusWindow';
  settings: {
    instrument: Instrument;
  };
}

export interface FocusMiniWindow extends DashboardWindow {
  name: 'FocusMiniWindow';
  settings: {
    instrument: Instrument;
  };
}

export interface AlertLogWindow extends DashboardWindow {
  name: 'AlertLogWindow';
}

export interface UserlistWindow extends DashboardWindow {
  name: 'UserlistWindow';
}

export interface InstrumentHeaderWindow extends DashboardWindow {
  name: 'InstrumentHeaderWindow';
}

export interface CalendarWindow extends DashboardWindow {
  name: 'CalendarWindow',
  settings: {
    calendarType: CalendarType;
    instrument?: Instrument; // CalendarType: 'Instrument'
    countries?: string[]; // CalendarType: 'Country' ISO-3166
    selectedWatchlist?: string; // CalendarType: 'Watchlist'
  },
}


export interface FundScreenerWindow extends DashboardWindow {
  name: 'FundScreenerWindow';
}

export function isCalendarWindow(window: DashboardWindow): window is CalendarWindow {
  return window.name === 'CalendarWindow';
}

export interface PortfolioHeaderWindow extends DashboardWindow {
  name: 'PortfolioHeaderWindow';
}

export interface PortfolioPositionsWindow extends DashboardWindow {
  name: 'PortfolioPositionsWindow';
  tradingClassification: TradingClassification;
  renderId: string; // PortfolioPositionsWindows have static ids so that changes in one window will be reflected regardless of portfolio but we still need a unique id for each render for the dashboard to know when the window has been switched
}

export interface PositionsSummaryWindow extends DashboardWindow {
  name: 'PositionsSummaryWindow';
  renderId: number;
}

export interface PortfolioOrdersWindow extends DashboardWindow {
  name: 'PortfolioOrdersWindow';
  orderCategory: OrderCategory;
  renderId: string; // PortfolioPositionsWindows have static ids so that changes in one window will be reflected regardless of portfolio but we still need a unique id for each render for the dashboard to know when the window has been switched
}

export interface PortfolioTradesWindow extends DashboardWindow {
  name: 'PortfolioTradesWindow';
  renderId: string;
}

export interface NetTradesWindow extends DashboardWindow {
  name: 'NetTradesWindow';
  renderId: string;
}

export const PortfolioPositionsWindows = ['PortfolioPositionsWindow', 'PositionsSummaryWindow', 'PositionsExposureWindow', 'PositionsEventsWindow', 'NetTradesWindow'];
export const PortfolioOrdersWindows = ['PortfolioOrdersWindow'];
export const PortfolioTradesWindows = ['PortfolioTradesWindow'];
export const PortfolioDashboardWindows = [...PortfolioPositionsWindows, ...PortfolioOrdersWindows, ...PortfolioTradesWindows];
// the combinations with PortfolioHeaderWindow are determined by the Portfolio*Windows defs above
export const PortfolioPositionsAndHeaderWindows = ['PortfolioHeaderWindow', ...PortfolioPositionsWindows];
export const PortfolioOrdersAndHeaderWindows = ['PortfolioHeaderWindow', ...PortfolioOrdersWindows];
export const PortfolioDashboardWindowsWithHeader = ['PortfolioHeaderWindow', ...PortfolioDashboardWindows];
export const PortfolioTradesAndHeaderWindows = ['PortfolioHeaderWindow', ...PortfolioTradesWindows];

export interface PositionsSummaryWindow extends DashboardWindow {
  name: 'PositionsSummaryWindow';
  renderId: number;
}

export interface PositionsEventsWindow extends DashboardWindow {
  name: 'PositionsEventsWindow';
  renderId: number;
}

export interface PositionsExposureWindow extends DashboardWindow {
  name: 'PositionsExposureWindow';
  renderId: number;
}

export interface CompanyDataWindow extends DashboardWindow {
  name: 'CompanyDataWindow';
  settings: {
    instrument?: Instrument;
  };
}

export interface CompanyInformationWindow extends DashboardWindow {
  name: 'CompanyInformationWindow';
  settings: {
    instrument?: Instrument;
  };
}

export function isCompanyInformationWindow(window: DashboardWindow): window is CompanyInformationWindow {
  return window.name === 'CompanyInformationWindow';
}

export interface TopShareholdersWindow extends DashboardWindow {
  name: 'TopShareholdersWindow';
  settings: {
    instrument?: Instrument;
  };
}

export interface NotFoundWindow extends DashboardWindow {
  name: 'NotFoundWindow';
}

export interface ChainsWindow extends DashboardWindow {
  name: 'ChainsWindow';
  settings: {
    feed?: number;
  };
}

export type WindowType = (
  | InstrumentWindow
  | MarketWindow
  | MarketOverviewWindow
  | WatchlistWindow
  | NewsWindow
  | OrderbookWindow
  | ChartWindow
  | ChartMiniWindow
  | FocusWindow
  | FocusMiniWindow
  | AlertLogWindow
  | CalendarWindow
  | InstrumentHeaderWindow
  | PortfolioHeaderWindow
  | PortfolioPositionsWindow
  | PortfolioOrdersWindow
  | PortfolioTradesWindow
  | PositionsSummaryWindow
  | PositionsEventsWindow
  | PositionsExposureWindow
  | NetTradesWindow
  | UserlistWindow
  | FundScreenerWindow
  | CompanyDataWindow
  | CompanyInformationWindow
  | TopShareholdersWindow
  | NotFoundWindow
  | ChainsWindow
);
export type BareWindowType = Partial<Exclude<WindowType, 'name'>> & { name: WindowType['name']; };
export type WindowName = WindowType['name'];
export type WindowSettings = WindowType['settings'];

export const InstrumentResolvableTypes = ['Instrument', 'Watchlist', 'Portfolio', 'Country'] as const;
export const NewsTypes = InstrumentResolvableTypes;
export const CalendarTypes = InstrumentResolvableTypes;

export type InstrumentResolvableType = (typeof InstrumentResolvableTypes)[number];
export type NewsType = (typeof NewsTypes)[number];
export type CalendarType = (typeof CalendarTypes)[number];

export const LinkChannels = ['Channel 1', 'Channel 2', 'Channel 3', 'Channel 4', 'All', 'Unlink', 'None'] as const;

export const NonLinkableChannels: LinkChannel[] = ['Unlink', 'None'];

export type LinkChannel = (typeof LinkChannels)[number];

// InstrumentSearchableWindowType
export interface InstrumentSearchableWindowSettings { instrument?: Instrument }
export interface InstrumentSearchableWindowType extends DashboardWindow { settings: InstrumentSearchableWindowSettings; }
export type InstrumentSearchableWindow = Extract<WindowType, InstrumentSearchableWindowType>;
export type InstrumentSearchableWindowName = InstrumentSearchableWindow['name'];

export const InstrumentSearchableWindowNames: WindowName[] = arrayOfAll<InstrumentSearchableWindowName>()([
  'InstrumentWindow',
  'OrderbookWindow',
  'ChartWindow',
  'ChartMiniWindow',
  'FocusWindow',
  'FocusMiniWindow',
  'NewsWindow',
  'CalendarWindow',
  'CompanyDataWindow',
  'CompanyInformationWindow',
  'TopShareholdersWindow'
]);

export function isInstrumentSearchableWindow(window: DashboardWindow): window is InstrumentSearchableWindowType {
  return InstrumentSearchableWindowNames.includes(window.name);
}

// CommonWatchlistWindowType
export interface CommonWatchlistWindowSettings { selectedWatchlist?: InfrontSDK.Watchlist['id']; }
export interface CommonWatchlistWindowType extends DashboardWindow { settings: CommonWatchlistWindowSettings; }
export type CommonWatchlistWindow = Extract<WindowType, CommonWatchlistWindowType>;
export type CommonWatchlistWindowName = CommonWatchlistWindow['name'];

export const CommonWatchlistWindowNames: WindowName[] = arrayOfAll<CommonWatchlistWindowName>()(
  ['WatchlistWindow', 'NewsWindow', 'CalendarWindow'] // usage as window.name checking guard for WatchlistService
);

export function isCommonWatchlistWindow(window: DashboardWindow): window is CommonWatchlistWindowType {
  return CommonWatchlistWindowNames.includes(window.name);
}

// InstrumentResolvableWindowType
export type InstrumentResolvableWindowSettings = ObjectToUnion<PickByType<UnionObjectsToIntersection<WindowSettings>, InstrumentResolvableType>>;
export interface InstrumentResolvableWindowType extends DashboardWindow { settings: InstrumentResolvableWindowSettings; }
export type InstrumentResolvableWindow = Extract<WindowType, InstrumentResolvableWindowType>;
export type InstrumentResolvableWindowName = InstrumentResolvableWindow['name'];

// NewsTypeWindowType
export type NewsTypeWindowSettings = { newsType: NewsType };
export interface NewsTypeWindowType extends DashboardWindow { settings: NewsTypeWindowSettings; }
export type NewsTypeWindow = Extract<WindowType, NewsTypeWindowType>;
export type NewsTypeWindowName = NewsTypeWindow['name'];

export const NewsTypeWindowNames: WindowName[] = arrayOfAll<NewsTypeWindowName>()(
  ['NewsWindow'] // usage as window.name checking guard for WatchlistService
);

export function isNewsTypeWindow(window: DashboardWindow): window is NewsTypeWindowType {
  return NewsTypeWindowNames.includes(window.name);
}

// CalendarTypeWindowType
export type CalendarTypeWindowSettings = { calendarType: CalendarType };
export interface CalendarTypeWindowType extends DashboardWindow { settings: CalendarTypeWindowSettings; }
export type CalendarTypeWindow = Extract<WindowType, CalendarTypeWindowType>;
export type CalendarTypeWindowName = CalendarTypeWindow['name'];

export const CalendarTypeWindowNames: WindowName[] = arrayOfAll<CalendarTypeWindowName>()(
  ['CalendarWindow'] // usage as window.name checking guard for WatchlistService
);

export function isCalendarTypeWindow(window: DashboardWindow): window is CalendarTypeWindowType {
  return CalendarTypeWindowNames.includes(window.name);
}

// IsoCountrySearchableWindow
export type CountrySearchableWindowSettings = { countries?: string[]; }; // ISO-3166
export interface CountrySearchableWindowType extends DashboardWindow { settings: CountrySearchableWindowSettings; }
export type CountrySearchableWindow = Extract<WindowType, CountrySearchableWindowType>;
export type CountrySearchableWindowName = CountrySearchableWindow['name'];

export const CountrySearchableWindowNames: WindowName[] = arrayOfAll<CountrySearchableWindowName>()(
  ['CalendarWindow'] // usage as window.name checking guard for WatchlistService
);

export function isCountrySearchableWindow(window: DashboardWindow): window is CountrySearchableWindowType {
  return CountrySearchableWindowNames.includes(window.name);
}

export interface Instrument {
  feed: number;
  ticker: string;
}

export type ClassifiedInstrument = Instrument & { symbolClassification: InfrontSDK.SymbolClassification };

export interface Market {
  feed: number;
}

export interface Watchlist {
  title: string;
  instruments: Array<Instrument>;
}

// todo: move this somewhere, see if its needed after Search update
export interface SearchItem {
  FullName: string;
  Ticker: string;
  Feed: number;
}

export type InstrumentSettings = { instrument: Instrument };

export const isInstrumentSettings = (settings: WindowSettings | undefined): settings is InstrumentSettings => {
  return (settings as InstrumentSettings)?.instrument != undefined;
};

export type FeedSettings = { feed: number };

export const isFeedSettings = (settings: WindowSettings | undefined): settings is FeedSettings => {
  return (settings as FeedSettings)?.feed != undefined;
};

export const isWindowSettings = (hasSettings: unknown): hasSettings is { settings: WindowSettings } => {
  return (hasSettings as { settings: WindowSettings })?.settings != undefined;
};

export const isInstrument = (instrument: unknown): instrument is Instrument => {
  return instrument != undefined && (instrument as Instrument).ticker != undefined && (instrument as Instrument).feed != undefined;
};

export const isClassifiedInstrument = (instrument: unknown): instrument is ClassifiedInstrument => {
  return (
    instrument != undefined &&
    (instrument as ClassifiedInstrument).ticker != undefined &&
    (instrument as ClassifiedInstrument).feed != undefined &&
    (instrument as ClassifiedInstrument).symbolClassification != undefined
  );
};

export const isMarket = (market: unknown): market is Market => {
  return (market as Market)?.feed != undefined && !('ticker' in (market as Market));
};
