import { ChangeDetectionStrategy, Component } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { getCountryForSymbol } from '../../../util/country';
import { CountryFlagCellComponent } from './country-flag-cell.component';

@Component({
  selector: 'wt-country-flag-ticker-cell',
  templateUrl: './county-flag-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryFlagTickerCellComponent extends CountryFlagCellComponent {

  setData(data: InfrontSDK.SymbolData, tickerTranslation?: string): void {
    this.country = getCountryForSymbol(data) ?? '';
    this.ticker = tickerTranslation ?? data.get?.(InfrontSDK.SymbolField.PreDisplayTicker);
    this.fullName = data.get?.(InfrontSDK.SymbolField.PreDisplayFullName) ?? this.ticker;
  }

}
