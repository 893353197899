import { animate, keyframes, query, state, style, transition, trigger } from '@angular/animations';

export const alertListAnimation = trigger('alertListAnimation', [
  transition('* <=> *', [
    query(':enter', [style({ opacity: 0 }), animate('300ms ease-out', style({ opacity: 1 }))], {
      optional: true,
    }),
    query(':leave', animate('200ms', style({ opacity: 0 })), { optional: true }),
  ]),
]);

export const alertDeleteAnimation = trigger('alertDeleteAnimation', [
  transition(':leave', [animate('200ms ease-in-out', style({ height: 0, opacity: 0 }))]),
]);

export const alertNewAnimationTime = 1000;
// todo: replace hardcoded color
// https://stackoverflow.com/questions/56563243/how-to-import-a-scss-file-into-a-typescript-file
export const alertNewAnimation = trigger('alertNewAnimation', [
  transition(':enter', [
    animate(
      `${alertNewAnimationTime}ms ease-out`,
      keyframes([style({ backgroundColor: '#a3b2bb', offset: 0.1 }), style({ backgroundColor: '#00374F', offset: 1 })])
    ),
  ]),
]);

export const alertItemAnimation = trigger('alertItemState', [
  state('default', style({ height: '*' })),
  state('deleted', style({ height: '*', opacity: '*' })),
  transition('* => deleted', [
    animate(
      '3000ms ease-out',
      style({
        height: '0',
        opacity: '0',
      })
    ),
  ]),
]);
