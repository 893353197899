import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { FeedListMenuModule } from '../../shared/feed-list-menu/feed-list-menu.module';
import { StaticColumnsGridModule } from '../../wrappers/grid-wrappers/static-columns-grid/static-columns-grid.module';
import { BiggestMoversComponent } from './biggest-movers.component';

@NgModule({
  declarations: [BiggestMoversComponent],
  imports: [CommonModule, MatIconModule, MatMenuModule, MatButtonModule, StaticColumnsGridModule, FeedListMenuModule],
})
export class BiggestMoversModule {}
